export default {
    timestamp: Date.now(),
    period: 720000,
    temperature: [
        35.4, 35.5, 37.6, 38.1, 38.6, 38.9, 39.3, 39.6, 39.9, 40.3, 40.6,
        40.7, 40.6, 40, 40.3, 39.9, 39.6, 39.3, 39, 38.7, 38.4, 38.1, 37.9,
        37.8, 37.6, 37.3, 37.2, 37, 36.8, 36.6, 36.4, 36.3, 36.1, 35.9, 35.8,
        35.6, 35.4, 35.2, 35.1, 34.9, 34.7, 34.6, 34.4, 34.3, 34.1, 33.9,
        33.8, 33.8, 33.6, 33.4, 33.4, 33.3, 33.2, 33.1, 33, 32.9, 32.9, 32.8,
        32.8, 32.7, 32.6, 32.6, 32.5, 32.4, 32.4, 32.4, 32.3, 32.3, 32.3, 32.2,
        32.1, 32.1, 32.1, 32.1, 32, 32, 31.9, 31.9, 31.9, 31.8, 31.8, 31.8, 31.8,
        31.8, 31.7, 31.7, 31.7, 31.7, 31.6, 31.6, 31.6, 31.6, 31.6, 31.6, 31.6,
        31.6, 31.6, 31.6, 31.6, 31.6, 31.6, 32, 32.1, 32.3, 32.5, 32.7, 32.9,
        33.2, 33.4, 33.8, 34, 34.4, 34.7, 35, 35.3, 35.6, 35.8, 36, 36.3, 36.5,
        36.8, 37, 37.4, 37.8, 38.1, 38.4, 38.6, 38.7, 38.8, 38.8, 38.6, 38.4,
        38.2, 37.9, 37.8, 37.5, 37.3, 37.1, 36.9, 36.8, 36.6, 36.4, 36.3, 36.2,
        36.1, 35.9, 35.9, 35.8, 35.7, 35.6, 35.4, 35.3, 35.3, 35.1, 35, 34.9,
        34.8, 34.6, 34.6, 34.4, 34.3, 34.3, 34.2, 34.1, 34, 33.9, 33.9, 33.8,
        33.8, 33.7, 33.6, 33.6, 33.5, 33.4, 33.4, 33.4, 33.3, 33.3, 33.3, 33.2,
        33.1, 33.1, 33.1, 33.1, 33, 32.9, 32.9, 32.9, 32.8, 32.8, 32.8, 32.8,
        32.8, 32.7, 32.6, 32.6, 32.6, 32.6, 32.6, 32.5, 32.4, 32.4, 32.4, 32.4,
        32.4, 32.4, 32, 32.3, 32.3, 32.3, 32.3, 32.2, 32.2, 32.2, 32.2, 32.2,
        32.1, 32.2, 32.2, 32.3, 32.3, 32.4, 32.5, 32.7, 32.8, 33, 33.3, 33.5,
        33.8, 34.1, 34.4, 34.7, 35.1
    ]
};

import { EVENTS } from "../../constants";
import i18n from "../../i18n";
export const VARIABLES = {
    cow: {
        id: "cow",
        belongsTo: "cows",
        type: "payloadVariable",
        default: "name",
        options: { name: { id: "name", parent: "cow", value: "$${cow}" } },
    },
    sensor: {
        id: "sensor",
        belongsTo: "sensor",
        type: "payloadVariable",
        default: "name",
        options: {
            name: { id: "name", parent: "sensor", value: "$${sensor}" },
        },
    },
    time: {
        id: "time",
        type: "timePicker",
        default: "currentTime",
        options: {
            currentTime: {
                id: "currentTime",
                parent: "time",
                value: new Date(),
            },
        },
    },
    count: {
        id: "count",
        type: "multiple",
        default: "one",
        isTextChanger: true,
        options: {
            one: {
                id: "one",
                parent: "count",
                value: "smartNote:variables:one",
            },
            two: {
                id: "two",
                parent: "count",
                value: "smartNote:variables:two",
            },
        },
    },
    bothOrOneOne: {
        id: "bothOrOneOne",
        type: "multiple",
        default: "both",
        isTextChanger: true,
        options: {
            oneOne: {
                id: "oneOne",
                parent: "bothOrOneOne",
                value: "smartNote:variables:one",
            },
            both: {
                id: "both",
                parent: "bothOrOneOne",
                value: "smartNote:variables:both",
            },
        },
    },
    threeCows: {
        id: "threeCows",
        type: "multiple",
        default: "all",
        isTextChanger: true,
        options: {
            all: {
                id: "all",
                parent: "threeCows",
                value: "smartNote:variables:all",
            },
            twoAnother: {
                id: "twoAnother",
                parent: "threeCows",
                value: "smartNote:variables:two",
            },
            oneAndAnother: {
                id: "oneAndAnother",
                parent: "threeCows",
                value: "smartNote:variables:one",
            },
        },
    },
    gender: {
        id: "gender",
        type: "multiple",
        default: "gender",
        options: {
            gender: {
                id: "gender",
                parent: "gender",
                value: "smartNote:variables:GENDER",
                isIncomplete: true,
            },
            male: {
                id: "male",
                parent: "gender",
                value: "smartNote:variables:male",
            },
            female: {
                id: "female",
                parent: "gender",
                value: "smartNote:variables:female",
            },
        },
    },
    calvingCondition: {
        id: "calvingCondition",
        type: "multiple",
        default: "calvingCondition",
        options: {
            calvingCondition: {
                id: "calvingCondition",
                parent: "calvingCondition",
                value: "smartNote:variables:DIFFICULTY",
                isIncomplete: true,
            },
            easy: {
                id: "easy",
                parent: "calvingCondition",
                value: "smartNote:variables:easy",
            },
            assisted: {
                id: "assisted",
                parent: "calvingCondition",
                value: "smartNote:variables:assisted",
            },
            difficult: {
                id: "difficult",
                parent: "calvingCondition",
                value: "smartNote:variables:difficult",
            },
        },
    },
    healthCondition: {
        id: "healthCondition",
        type: "multiple",
        default: "healthy",
        options: {
            healthy: {
                id: "healthy",
                parent: "healthCondition",
                value: "smartNote:variables:healthy",
            },
            unhealthy: {
                id: "unhealthy",
                parent: "healthCondition",
                value: "smartNote:variables:unhealthy",
            },
            dead: {
                id: "dead",
                parent: "healthCondition",
                value: "smartNote:variables:dead",
            },
        },
    },
    healthComplication: {
        id: "healthComplication",
        type: "checkbox",
        default: "noComplication",
        options: {
            noComplication: {
                id: "noComplication",
                parent: "healthComplication",
                value: "smartNote:variables:noComplication",
                isReplaceable: true,
            },
            tissueDamage: {
                id: "tissueDamage",
                parent: "healthComplication",
                value: "smartNote:variables:tissueDamage",
            },
            // vaginitis: { id: "vaginitis", parent: "healthComplication", value: "smartNote:variables:vaginitis" },
            milkFever: {
                id: "milkFever",
                parent: "healthComplication",
                value: "smartNote:variables:milkFever",
            },
            // ketoAcidosis: { id: "ketoAcidosis", parent: "healthComplication", value: "smartNote:variables:ketoAcidosis" },
            inflammation: {
                id: "inflammation",
                parent: "healthComplication",
                value: "smartNote:variables:inflammation",
            },
            downerCow: {
                id: "downerCow",
                parent: "healthComplication",
                value: "smartNote:variables:downerCow",
            },
        },
    },
    fallReason: {
        id: "fallReason",
        type: "multiple",
        default: "fallen",
        isTextChanger: true,
        options: {
            fallen: {
                id: "fallen",
                parent: "fallReason",
                value: "smartNote:variables:fallen",
                isIncomplete: false,
            },
            ejected: {
                id: "ejected",
                parent: "fallReason",
                value: "smartNote:variables:ejected",
            },
        },
    },
    heatFallReason: {
        id: "heatFallReason",
        type: "multiple",
        default: "inseminated",
        isTextChanger: true,
        options: {
            inseminated: {
                id: "inseminated",
                parent: "heatFallReason",
                value: "smartNote:variables:inseminated",
            },
            examined: {
                id: "examined",
                parent: "heatFallReason",
                value: "smartNote:variables:examined",
            },
            dropped: {
                id: "dropped",
                parent: "heatFallReason",
                value: "smartNote:variables:dropped",
            },
        },
    },
    will: {
        id: "will",
        type: "multiple",
        default: "willNot",
        isTextChanger: true,
        options: {
            will: {
                id: "will",
                parent: "will",
                value: "smartNote:variables:will",
            },
            willNot: {
                id: "willNot",
                parent: "will",
                value: "smartNote:variables:willNot",
            },
        },
    },
};
const calvingDetectedConfirm = EVENTS.CALVING_DETECTED + "::confirm";
const calvingDetectedAbort = EVENTS.CALVING_DETECTED + "::abort";
const sensorFallDetectedHeat = EVENTS.SENSOR_FALL_DETECTED + "::heat";
const sensorFallDetectedHealth = EVENTS.SENSOR_FALL_DETECTED + "::health";

export const TEXTS = {
    [calvingDetectedConfirm]: [
        {
            id: "1",
            text:
                "$${LANGUAGE::1} $${VARIABLE::1} $${LANGUAGE::2} $${VARIABLE::2} $${LANGUAGE::3} $${VARIABLE::3}",
            optionType: "count",
            options: {
                one: {
                    id: "2",
                    text:
                        " $${LANGUAGE::4} $${VARIABLE::4} $${LANGUAGE::5} $${VARIABLE::5}",
                },
                two: {
                    id: "2",
                    text: "$${LANGUAGE::6} $${VARIABLE::6}",
                    optionType: "bothOrOneOne",
                    options: {
                        both: {
                            text:
                                "$${LANGUAGE::7} $${VARIABLE::7} $${LANGUAGE::8} $${VARIABLE::8}",
                        },
                        oneOne: {
                            text:
                                "$${LANGUAGE::9} $${VARIABLE::9} $${LANGUAGE::10} $${VARIABLE::10} $${LANGUAGE::11} $${VARIABLE::11} $${LANGUAGE::12} $${VARIABLE::12}",
                        },
                    },
                },
            },
        },
        {
            id: "4",
            text:
                "$${LANGUAGE::13} $${VARIABLE::13} $${LANGUAGE::14} $${VARIABLE::14&EFFECT::plural&AFFECT::14} $${LANGUAGE::15}",
        },
    ],
    [calvingDetectedAbort]: [
        {
            id: "1",
            text:
                "$${LANGUAGE::1} $${VARIABLE::1} $${LANGUAGE::2} $${VARIABLE::2}",
            optionType: "fallReason",
            options: {
                fallen: { id: "2", text: "$${LANGUAGE::3}" },
                ejected: {
                    id: "2",
                    text:
                        " $${LANGUAGE::4} $${VARIABLE::3&EFFECT::plural&AFFECT::4} $${LANGUAGE::5}",
                },
            },
        },
    ],
    [sensorFallDetectedHeat]: [
        {
            id: "1",
            text:
                "$${LANGUAGE::1} $${VARIABLE::1} $${LANGUAGE::2} $${VARIABLE::2&EFFECT::grammer&AFFECT::2}",
            optionType: "heatFallReason",
            options: {
                inseminated: {
                    id: "2",
                    text: " $${LANGUAGE::3} $${VARIABLE::3} $${LANGUAGE::4}",
                },
                examined: {
                    id: "2",
                    text: " $${LANGUAGE::3} $${VARIABLE::3} $${LANGUAGE::4}",
                },
                dropped: {
                    id: "2",
                    text: " $${LANGUAGE::5} $${VARIABLE::3} $${LANGUAGE::4}",
                },
            },
        },
    ],
    [sensorFallDetectedHealth]: [
        {
            id: "1",
            text:
                "$${LANGUAGE::1} $${VARIABLE::1} $${LANGUAGE::2} $${VARIABLE::2}",
            optionType: "will",
            options: {
                will: { id: "2", text: " $${LANGUAGE::3}" },
                willNot: { id: "2", text: " $${LANGUAGE::3}" },
            },
        },
    ],
};

export const LANGUAGE_TEXTS = {
    [calvingDetectedConfirm]: {
        1: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-1",
            ),
        },
        2: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-2",
            ),
        },
        3: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-3",
            ),
        },
        4: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-4",
            ),
        },
        5: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-5",
            ),
        },
        6: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-6",
            ),
        },
        7: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-7",
            ),
        },
        8: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-8",
            ),
        },
        9: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-9",
            ),
        },
        10: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-10",
            ),
        },
        11: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-11",
            ),
        },
        12: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-12",
            ),
        },
        13: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-13",
            ),
        },
        14: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-14",
            ),
            plural: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-14-plural",
            ),
        },
        15: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-confirm-language-15",
            ),
        },
    },
    [calvingDetectedAbort]: {
        1: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-abort-language-1",
            ),
        },
        2: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-abort-language-2",
            ),
        },
        3: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-abort-language-3",
            ),
        },
        4: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-abort-language-4",
            ),
            plural: i18n.t(
                "smartNote:languageTexts:calving-detected-abort-language-4-plural",
            ),
        },
        5: {
            default: i18n.t(
                "smartNote:languageTexts:calving-detected-abort-language-5",
            ),
        },
    },
    [sensorFallDetectedHeat]: {
        1: {
            default: i18n.t(
                "smartNote:languageTexts:sensor-fall-detected-heat-language-1",
            ),
        },
        2: {
            default: i18n.t(
                "smartNote:languageTexts:sensor-fall-detected-heat-language-2",
            ),
            grammer: i18n.t(
                "smartNote:languageTexts:sensor-fall-detected-heat-language-2-grammer",
            ),
        },
        3: {
            default: i18n.t(
                "smartNote:languageTexts:sensor-fall-detected-heat-language-3",
            ),
        },
        4: {
            default: i18n.t(
                "smartNote:languageTexts:sensor-fall-detected-heat-language-4",
            ),
        },
        5: {
            default: i18n.t(
                "smartNote:languageTexts:sensor-fall-detected-heat-language-5",
            ),
        },
    },
    [sensorFallDetectedHealth]: {
        1: {
            default: i18n.t(
                "smartNote:languageTexts:sensor-fall-detected-health-language-1",
            ),
        },
        2: {
            default: i18n.t(
                "smartNote:languageTexts:sensor-fall-detected-health-language-2",
            ),
        },
        3: {
            default: i18n.t(
                "smartNote:languageTexts:sensor-fall-detected-health-language-3",
            ),
        },
    },
};

export const TEXTS_DEFAULTS = {
    [calvingDetectedConfirm]: {
        count: "one",
        bothOrOneOne: "both",
        threeCows: "all",
    },
    [calvingDetectedAbort]: { fallReason: "fallen" },
    [sensorFallDetectedHeat]: { heatFallReason: "inseminated", will: "will" },
    [sensorFallDetectedHealth]: { will: "will" },
};

/* Decoding from smart node */

export const packageFuntions = {};

const calvingDetectedNoteConfirmParseStrings = {
    "cow,time,count,healthCondition,gender,calvingCondition,healthComplication":
        "A",
    "cow,time,count,bothOrOneOne,healthCondition,gender,calvingCondition,healthComplication":
        "B",
    "cow,time,count,bothOrOneOne,healthCondition,gender,healthCondition,gender,calvingCondition,healthComplication":
        "C",
    "cow,time,count,threeCows,healthCondition,gender,calvingCondition,healthComplication":
        "D",
    "cow,time,count,threeCows,healthCondition,gender,healthCondition,gender,calvingCondition,healthComplication":
        "E",
    "cow,time,count,threeCows,healthCondition,gender,healthCondition,gender,healthCondition,gender,calvingCondition,healthComplication":
        "F",
};

const calvingDetectedNoteAbortParseStrings = {
    "cow,fallReason": "A",
    "cow,fallReason,healthComplication": "B",
};

const sensorFallDetectedHeatParseStrings = { "cow,heatFallReason,will": "A" };
const sensorFallDetectedHealthParseStrings = { "cow,will": "A" };

function generateSensorFallDetectedHeatPackage(string, options) {
    switch (sensorFallDetectedHeatParseStrings[string]) {
        case "A":
            return {
                heatFallReason: options[1].heatFallReason,
                willPlace: options[2].will,
            };
        case "unknown":
        default:
            console.log(string);
            throw new Error("Unexpected Smart Note!!");
    }
}
packageFuntions[sensorFallDetectedHeat] = generateSensorFallDetectedHeatPackage;

function generateSensorFallDetectedHealthPackage(string, options) {
    switch (sensorFallDetectedHealthParseStrings[string]) {
        case "A":
            return {
                willPlace: options[1].will,
            };
        case "unknown":
        default:
            console.log(string);
            throw new Error("Unexpected Smart Note!!");
    }
}
packageFuntions[
    sensorFallDetectedHealth
] = generateSensorFallDetectedHealthPackage;

function generateCalvingDetectedAbortPackage(string, options) {
    switch (calvingDetectedNoteAbortParseStrings[string]) {
        case "A":
            return {
                fallReason: options[1].fallReason,
                healthComplication: [],
            };
        case "B":
            return {
                fallReason: options[1].fallReason,
                healthComplication: options[2].healthComplication,
            };
        case "unknown":
        default:
            console.log(string);
            throw new Error("Unexpected Smart Note!!");
    }
}
packageFuntions[calvingDetectedAbort] = generateCalvingDetectedAbortPackage;

function generateCalvingDetectedConfirmPackage(string, options) {
    switch (calvingDetectedNoteConfirmParseStrings[string]) {
        case "A":
            return {
                calvingTime: options[1].time.getTime(),
                calf: [
                    {
                        healthCondition: options[3].healthCondition,
                        gender: options[4].gender,
                    },
                ],
                calvingCondition: options[5].calvingCondition,
                healthComplication: options[6].healthComplication,
            };
        case "B":
            return {
                calvingTime: options[1].time.getTime(),
                calf: [
                    {
                        healthCondition: options[4].healthCondition,
                        gender: options[5].gender,
                    },
                    {
                        healthCondition: options[4].healthCondition,
                        gender: options[5].gender,
                    },
                ],
                calvingCondition: options[6].calvingCondition,
                healthComplication: options[7].healthComplication,
            };
        case "C":
            return {
                calvingTime: options[1].time.getTime(),
                calf: [
                    {
                        healthCondition: options[4].healthCondition,
                        gender: options[5].gender,
                    },
                    {
                        healthCondition: options[6].healthCondition,
                        gender: options[7].gender,
                    },
                ],
                calvingCondition: options[8].calvingCondition,
                healthComplication: options[9].healthComplication,
            };
        case "D":
            return {
                calvingTime: options[1].time.getTime(),
                calf: [
                    {
                        healthCondition: options[4].healthCondition,
                        gender: options[5].gender,
                    },
                    {
                        healthCondition: options[4].healthCondition,
                        gender: options[5].gender,
                    },
                    {
                        healthCondition: options[4].healthCondition,
                        gender: options[5].gender,
                    },
                ],
                calvingCondition: options[6].calvingCondition,
                healthComplication: options[7].healthComplication,
            };
        case "E":
            return {
                calvingTime: options[1].time.getTime(),
                calf: [
                    {
                        healthCondition: options[4].healthCondition,
                        gender: options[5].gender,
                    },
                    {
                        healthCondition: options[4].healthCondition,
                        gender: options[5].gender,
                    },
                    {
                        healthCondition: options[6].healthCondition,
                        gender: options[7].gender,
                    },
                ],
                calvingCondition: options[8].calvingCondition,
                healthComplication: options[9].healthComplication,
            };
        case "F":
            return {
                calvingTime: options[1].time.getTime(),
                calf: [
                    {
                        healthCondition: options[4].healthCondition,
                        gender: options[5].gender,
                    },
                    {
                        healthCondition: options[6].healthCondition,
                        gender: options[7].gender,
                    },
                    {
                        healthCondition: options[8].healthCondition,
                        gender: options[9].gender,
                    },
                ],
                calvingCondition: options[10].calvingCondition,
                healthComplication: options[11].healthComplication,
            };
        case "unknown":
        default:
            console.log(string);
            throw new Error("Unexpected Smart Note!!");
    }
}
packageFuntions[calvingDetectedConfirm] = generateCalvingDetectedConfirmPackage;

/* eslint-disable no-case-declarations */
import {
    COMPLETE_FETCH_EVENTS_VIEW_REQUEST,
    COMPLETE_FETCH_EVENTS_REQUEST,
    COMPLETE_REMOVE_COW
} from "../actions/actionTypes";
import { EVENTS } from "./../../constants/schema";

export default function (state = { sessionIds: [], sensorIds: [], cowIds: [], userIds: [] }, action) {
    switch (action.type) {
    case COMPLETE_FETCH_EVENTS_VIEW_REQUEST:
        const currentSessionIds = Object.keys(action.currentSessions);
        const currentSensorIds = Object.keys(action.currentSensors);
        const realOldSessions = action.data.sessions.filter(s => !currentSessionIds.includes(s.id));
        const realOldSensors = action.data.sensors.filter(s => !currentSensorIds.includes(s.id));
        return {
            ...state,
            sessionIds: [ ...state.sessionIds, ...realOldSessions.map(s => s.id) ],
            sensorIds: [ ...state.sensorIds, ...realOldSensors.map(s => s.id)],
            userIds: [ ...new Set([...state.userIds, ...action.data.users.filter( u => !u.isUserRegistered).map(u => u.id)])],
            toLastSessionId: action.data.sessions.reduce((sessionId, s) => {
                if (sessionId > s.id) { return s.id; }
                else { return sessionId; }
            }, Number.MAX_SAFE_INTEGER)
        };

    case COMPLETE_FETCH_EVENTS_REQUEST:
    case COMPLETE_REMOVE_COW:
        const deadCows = action.data.events.filter(e => e.type == EVENTS.COW_DIED).map(e => e.payload.cow);
        return {
            ...state,
            userIds: [ ...new Set([...state.userIds, ...action.data.users.filter( u => !u.isUserRegistered).map(u => u.id)])],
            cowIds: [
                ...state.cowIds,
                ...deadCows
            ]
        };

    default: return state;
    }
}

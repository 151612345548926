import connectItem from "./Item";
import { sensorsForCow } from "./Sensors";

export { default as cows } from "./Cows";
export { default as events } from "./Events";

export { tagsFor } from "./Tags";

export const cow = connectItem("cows", "cow");
export const event = connectItem("events", "event");
// export const tsens = connectItem("tsenses", "tsens");
export const sensor = connectItem("sensors", "sensor");
export const user = connectItem("users", "user");

export const sensorConnect = sensorsForCow();

export { default as temperature } from "./Temperature";
export { default as data } from "./Data";
export { default as preview } from "./Preview";

export { default as loginForm } from "./LoginForm";
export { default as loginModal } from "./LoginModal";
export { default as demoModal } from "./DemoModal";

export { default as pairingLists } from "./PairingLists";
export { default as pairingList } from "./PairingList";

export { default as automation } from "./Automation";

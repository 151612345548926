import { take, put, spawn, call } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
    CHANGED_USER_CALL_CONFIG,
    START_USER_CALL_CHANGE,
    COMPLETE_USER_CALL_CHANGE,
    FAIL_USER_CALL_CHANGE,
    RESET_USER_CALL,

} from "../actions/actionTypes";

export function* userCall() {
    const { userId, activate } = yield take(CHANGED_USER_CALL_CONFIG);
    yield put({ type: START_USER_CALL_CHANGE, userId });
    yield call(delay, 2000);
    try {

        yield put({ type: COMPLETE_USER_CALL_CHANGE, userId, activate });
        yield call(delay, 2000);
        yield put({ type: RESET_USER_CALL, userId });
    } catch (error) {
        yield put({ type: FAIL_USER_CALL_CHANGE, error, userId });
    } finally {
        yield spawn(userCall);
    }
}

import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { demoModal as connect } from "../../containers";
import "./DemoModal.css";
import Button from "../Button";

export const HIDDEN = "HIDDEN";
export const FORM = "FORM";
export const ERROR = "ERROR";
export const RETRY = "RETRY";

const mailPattern = `(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))`;

export function DemoModal({
    state = HIDDEN,
    lifecycle,
    registerDemoAccount,
    hideDemoModal,
}) {
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [mail, setMail] = useState("");
    const [phone, setPhone] = useState("");

    function validateMail(mail) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(mail).toLowerCase());
    }

    function validatePhone(phone) {
        const re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3,4})(?: *x(\d+))?\s*$/;
        return re.test(String(phone));
    }

    async function handleSubmit(event) {
        if (
            name.length > 0 &&
            surname.length > 0 &&
            validateMail(mail) &&
            validatePhone(phone)
        ) {
            event.preventDefault();
            registerDemoAccount(
                name,
                surname,
                mail,
                phone.replace(/[^0-9]/g, ""),
            );
        }
        return false;
    }

    if (state === HIDDEN) return null;
    else if (state === ERROR)
        return (
            <div className={"demo-modal"}>
                <span className="demo-close" onClick={() => hideDemoModal()}>
                    &times;
                </span>
                <h2>
                    <Trans i18nKey="demoAccountModalErrorTitle">Error</Trans>
                </h2>
                <p>
                    <Trans i18nKey="demoAccountModalErrorDesc">
                        An error occurred while registration. Please try again.
                    </Trans>
                </p>
            </div>
        );
    else
        return (
            <div className={"demo-modal"}>
                <span className="demo-close" onClick={() => hideDemoModal()}>
                    &times;
                </span>
                <h2>
                    <Trans i18nKey="demoAccountModalTitle">
                        Demo Account Info
                    </Trans>
                </h2>
                <p>
                    <Trans i18nKey="demoAccountModalDesc">
                        We need some information for let you to use demo farm
                    </Trans>
                </p>
                <form className="demo-form" onSubmit={handleSubmit}>
                    <input
                        value={name}
                        placeholder={t("demoAccountNamePlaceholder")}
                        type="text"
                        required
                        onChange={(e) => setName(e.target.value)}
                    />
                    <input
                        value={surname}
                        placeholder={t("demoAccountSurnamePlaceholder")}
                        type="text"
                        required
                        onChange={(e) => setSurname(e.target.value)}
                    />
                    <input
                        value={mail}
                        placeholder={t("demoAccountMailPlaceholder")}
                        type="email"
                        required
                        pattern={mailPattern}
                        onChange={(e) => setMail(e.target.value)}
                    />
                    <input
                        value={phone}
                        placeholder={t("demoAccountPhonePlaceholder")}
                        type="tel"
                        required
                        pattern="\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3,4})(?: *x(\d+))?\s*"
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <Button
                        className="demo-farm"
                        text={t("demoAccountAccessFarmButton")}
                        onClicked={handleSubmit.bind(this)}
                    />
                </form>
            </div>
        );
}

export default connect(DemoModal);

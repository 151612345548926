import {
    START_SET_METRIC_RANGE,
    COMPLETE_SET_METRIC_RANGE,
    FAIL_SET_METRIC_RANGE,
    FAIL_CALVING_REPORT
} from "../actions/actionTypes";
import i18n from "../../i18n";

const initState = {
    ui: "unavailable",
    reportError: null,
    calfGender: {
        title: i18n.t("calfGender"),
        info: { emphasise: null, text: ` ${i18n.t("total")}` },
        metrics: {
            female: null,
            male: null,
            unknown: null
        }
    },
    birthHealth: {
        title: i18n.t("HealthAtBirth"),
        metrics: {
            healthy: null,
            unhealthy: null,
            dead: null,
            unknown: null
        }
    },
    calvingExperience: {
        title: i18n.t("calvingExperience"),
        metrics: {
            easy: null,
            asssisted: null,
            difficult: null,
            unknown: null,
        }
    },
    gestationPeriod: {
        title: i18n.t("gestationPeriod"),
        info: { emphasise: null, text: ` ${i18n.t("day")}` },
        metrics: {
            normal: null,
        }
    },
    detectionAccuracy: {
        title: i18n.t("detectionAccuracy"),
        metrics: {
            perfect: null,
            eventual: null,
            false: null,
            missed: null,
        }
    },
    trackingSessions: {
        title: i18n.t("trackingSessions"),
        info: { emphasise: null, text: ` ${i18n.t("total")}` },
        metrics: {
            resolved: null,
            canceled: null,
            interrupted: null,
        }
    },
    feverResults: {
        title: i18n.t("feverResults"),
        info: { emphasise: null, text: null },
        metrics: { fever: null }
    },
    healthScore: {
        title: i18n.t("healthScore"),
        metrics: { healthScore: null }
    }
};

export default function (state = initState, action) {
    switch (action.type) {
    case COMPLETE_SET_METRIC_RANGE:
        return {
            ...state,
            reportError: null,
            ui: "available",
            calfGender: {
                ...state.calfGender,
                info: { ...state.calfGender.info, emphasise: action.calfGender.total },
                metrics: {
                    female: action.calfGender.female,
                    male: action.calfGender.male,
                    unknown: action.calfGender.unknown,
                }
            },
            birthHealth: {
                ...state.birthHealth,
                metrics: {
                    healthy: action.birthHealth.healthy,
                    unhealthy: action.birthHealth.unhealthy,
                    dead: action.birthHealth.dead,
                    unknown: action.birthHealth.unknown,
                }
            },
            calvingExperience: {
                ...state.calvingExperience,
                metrics: {
                    easy: action.calvingExperience.easy,
                    assisted: action.calvingExperience.assisted,
                    difficult: action.calvingExperience.difficult,
                    unknown: action.calvingExperience.unknown,
                }
            },
            gestationPeriod: {
                ...state.gestationPeriod,
                metrics: { normal: action.gestationPeriod.normal },
                info: {
                    ...state.gestationPeriod.info,
                    emphasise: `${Math.ceil(action.gestationPeriod.duration)} \xB1 ${Math.ceil(action.gestationPeriod.deviation)}`,
                    text: ` ${i18n.t("day", { count: action.gestationPeriod.deviation })}`
                }
            },
            detectionAccuracy: {
                ...state.detectionAccuracy,
                metrics: {
                    perfect: action.detectionAccuracy.perfect,
                    eventual: action.detectionAccuracy.eventual,
                    false: action.detectionAccuracy.false,
                    missed: action.detectionAccuracy.missed
                }
            },
            trackingSessions: {
                ...state.trackingSessions,
                info: { ...state.trackingSessions.info, emphasise: action.trackingSessions.total },
                metrics: {
                    resolved: action.trackingSessions.resolved,
                    canceled: action.trackingSessions.canceled,
                    interrupted: action.trackingSessions.interrupted,
                }
            },
            feverResults: {
                ...state.feverResults,
                info: {
                    ...state.feverResults.info,
                    emphasise: timeCal(action.feverResults.avgFeverDuration),
                    text: ` ${action.feverResults.avgFeverDuration > 60 ? i18n.t("hour", { count: parseInt(action.feverResults.avgFeverDuration/60) })
                        : i18n.t("minute", { count: action.feverResults.avgFeverDuration })}`
                },
                metrics: { fever: action.feverResults.fever }
            },
            healthScore: {
                ...state.healthScore,
                metrics: { healthScore: action.healthScore }
            }
        };
    case FAIL_SET_METRIC_RANGE:
        return { ...state, ui: "fail" };
    case START_SET_METRIC_RANGE:
        return { ...state, ui: "pending" };
    case FAIL_CALVING_REPORT:
        if (action.error === "NO_TIMESLOT_ERROR"){
            return { ...state, reportError: "NoTimeslot", ui: "available", };
        }
        return { ...state, ui: "fail" };
    default: return state;
    }
}

function timeCal(time){
    return time > 60 ? parseInt(time/60): time;
}
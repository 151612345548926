import React from "react";
import "./NetworkError.css";
import { useTranslation } from "react-i18next";
import { Detector } from "react-detect-offline";
import { connect } from "react-redux";

import Toast from "../Toast";
import { DONE } from "../../constants/lifecycle";

export function NetworkError({ start_lifecycle }) {
    const { t } = useTranslation();
    function onNetworkChanged(online) {
        if (online && start_lifecycle !== DONE) window.location.reload();
    }
    return (
        <Detector
            onChange={onNetworkChanged}
            polling={{ url: "https://api.wicows.com/alive", enabled: true }}
            render={({ online }) => {
                if (!online) {
                    return (
                        <Toast
                            permanent
                            text={t(
                                "theConnectionSeemsToBeOfflineWeWillKeepTryingToReconnectOrYouCanTryNow",
                            )}
                        />
                    );
                } else {
                    return null;
                }
            }}
        />
    );
}

function mapStateToProps({ start_lifecycle }) {
    return { start_lifecycle };
}

export default connect(mapStateToProps)(NetworkError);

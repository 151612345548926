import { take, put, call, spawn } from "redux-saga/effects";
import {
    CONFIRM_CALVING,
    START_CONFIRM_CALVING_REQUEST,
    COMPLETE_CONFIRM_CALVING_REQUEST,
    FAIL_CONFIRM_CALVING_REQUEST,
} from "../actions/actionTypes";
import { EVENTS } from "../../constants/schema";
import axios from "axios";


export function *confirmCalvingRequest() {
    const { cow } = yield take(CONFIRM_CALVING);
    yield spawn(confirmCalvingRequest);

    yield put({ type: START_CONFIRM_CALVING_REQUEST, cow });

    const calvingConfirmedEvent = {
        event: EVENTS.CALVING_COMPLETED,
        payload: { cow },
    };

    try {
        const { data } = yield call(axios.post, "/v2/events", calvingConfirmedEvent);
        yield put({ type: COMPLETE_CONFIRM_CALVING_REQUEST, cow, data });
    } catch (error) {
        yield put({ type: FAIL_CONFIRM_CALVING_REQUEST, cow, error });
    }
}

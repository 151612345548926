import React from "react";

import "./Lifecycle.css";
import * as LIFECYCLE from "../../constants/lifecycle";
import i18n from "../../i18n";

export default function hasLifecycle(Component) {
    function Lifecycle({
        lifecycle,
        loading = i18n.t("loading"),
        error = i18n.t("error"),
        className = "lifecycle lifecycle-default-box",
        ...props
    }) {
        switch (lifecycle) {
            case LIFECYCLE.INITIAL:
                return (
                    <div className={`${className} ${lifecycle}`}>&nbsp;</div>
                );
            case LIFECYCLE.PENDING:
                return (
                    <div className={`${className} ${lifecycle}`}>{loading}</div>
                );
            case LIFECYCLE.FAILED:
                return (
                    <div className={`${className} ${lifecycle}`}>{error}</div>
                );
            case LIFECYCLE.NONE:
                return null;
            case LIFECYCLE.DONE:
                return <Component {...props} />;
            default:
                throw new Error("Unknown lifecycle constant");
        }
    }
    const displayName = Component.displayName || Component.name || "Component";
    Lifecycle.displayName = `${displayName}Lifecycle`;
    return Lifecycle;
}

import {
    START_UPDATE_INFORMATION_REQUEST,
    COMPLETE_UPDATE_INFORMATION_REQUEST,
    FAIL_UPDATE_INFORMATION_REQUEST,
    RESET_YOUR_PROFILE,
} from "../../actions/actionTypes";

export default function (state = "initial", action) {
    switch (action.type) {
    case START_UPDATE_INFORMATION_REQUEST:
        return "updating";
    case COMPLETE_UPDATE_INFORMATION_REQUEST:
        return "updated";
    case RESET_YOUR_PROFILE:
        return "initial";
    case FAIL_UPDATE_INFORMATION_REQUEST:
        return "failed";
    default: return state;
    }
}

import { connect } from "react-redux";

import { SESSION } from "../constants/schema";
import { COW_UI } from "../constants";
import { editCow, removeCow, setDialog, clearDialog } from "../store/actions";

export function mapStateToProps(
    { sessions, sensors, cowTypes, tags, cowListFilter, priviliges },
    { cow },
) {
    return {
        cow: {
            ...cow,
            tags:
                tags.cow[cow.id].filter((tag) =>
                    Object.values(COW_UI).includes(tag.value),
                ) || [],
            sessions: Object.values(sessions)
                .filter(
                    (session) =>
                        session.cow === cow.id &&
                        tags.session[session.id] &&
                        (tags.session[session.id]
                            .map((tag) => tag.value)
                            .includes(SESSION.ACTIVE) ||
                            tags.session[session.id]
                                .map((tag) => tag.value)
                                .includes(SESSION.PENDING)),
                )
                .reduce(
                    (sessionsWithTags, session) => [
                        ...sessionsWithTags,
                        { ...session, tags: tags.session[session.id] || [] },
                    ],
                    [],
                )
                .map((session) => ({
                    ...session,
                    sensor: {
                        ...sensors[session.sensor],
                        tags: tags.sensor[session.sensor] || [],
                    },
                })),
        },
        cowListUi: cowListFilter.ui,
        cowTypes: Object.values(cowTypes).reduce((acc, ct) => {
            return [...acc, { value: ct.id, label: ct.cowTypeName }];
        }, []),
        cowListEditEnabled: priviliges.COW_LIST_EDIT_ENABLED.value,
    };
}

const mapDispatchToProps = {
    editCow,
    removeCow,
    setDialog,
    clearDialog,
};

export default connect(mapStateToProps, mapDispatchToProps);

export default {
    cows: [

        {
            id: "c2",
            farmNumber: 12,
            name: "Boncuk",
            status: []
        }
    ],
    events: [
        {
            id: "e6",
            timestamp: 1538546938000,
            type: "tsens-installed",
            payload: {
                tsens: "t2",
                cow: "c2",
                user: "u1",
                tags: ["tracked"]
            },
            readState: "unread"
        }
    ],
    users: [
        {
            id: "u1",
            name: "Cengiz Köse"
        }
    ],
    tsenses: [
        {
            id: "t2",
            farmNumber: 800
        }
    ]
};

import React, { useContext, useRef, useEffect } from "react";
import { connect } from "react-redux";
import "./NavBar.css";
import { RouterContext } from "../../containers/Contexts";

import ShortcutButton from "./ShortcutButton";

export function NavBar({ currentFarm, switchFarmUi, otherFarms }) {
    const headerRef = useRef();
    const navigate = useContext(RouterContext);

    useEffect(() => {
        headerRef.current.parentElement.classList.add("disable-transform");
    }, []);
    function onNavButtonClicked() {
        window.scrollTo(0, 0);
        navigate.forward("/mainMenu");
    }

    return (
        <React.Fragment>
            <header ref={headerRef} className="navbar">
                <h1>
                    {currentFarm.name}
                    <span className="trademark"> - wiCow </span>
                </h1>
                <ShortcutButton />
                <i className="burger" onClick={onNavButtonClicked} />
            </header>
        </React.Fragment>
    );
}

function mapStateToProps({ farms, switchFarm }) {
    return {
        switchFarmUi: switchFarm.ui,
        currentFarm: farms.current,
        otherFarms: farms.all.filter((farm) => farm.id !== farms.current.id),
    };
}

export default connect(mapStateToProps)(NavBar);

import { call, put } from "redux-saga/effects";
import { delay } from "redux-saga";
import axios from "axios";
import { UPDATE_RESOURCE } from "../actions/actionTypes";

export function *resourceRequest(resource, action = null) {
    while (true) {
        try {
            const { data } = yield call(axios.get, `/${resource}`);
            if (action) {
                yield put({ ...action, data });
            } else {
                yield put({ type: UPDATE_RESOURCE, resource, data });
            }
            break;
        } catch (e) {
            yield call(delay, 250);
        }
    }
}

import React from "react";
import cn from "classnames";

import DatePicker from "./DatePicker";
import Select from "./Select";
import ValidationMessage from "./ValidationMessage";

const Input = ({
    id,
    type = "input",
    classNames,
    label,
    options = null,
    onChanged,
    onKeyPress,
    inputType = null,
    placeholder = null,
    validationMessage,
    disableFuture,
    upperCase = null,
    defaultValue = null,
    value = null,
    readonly = false,
    onFocused = () => null,
}) => {
    const val = value ? value : defaultValue;
    switch (type) {
        case "date":
            return (
                <div
                    id={id}
                    className={cn(
                        "input-group",
                        {
                            error:
                                validationMessage && validationMessage.required,
                        },
                        classNames,
                    )}>
                    <DatePicker
                        onDateChanged={onChanged}
                        disableFuture={disableFuture}
                        value={val}
                    />
                    {validationMessage ? (
                        <ValidationMessage
                            validationMessage={validationMessage}
                        />
                    ) : (
                        <span className="label">{label}</span>
                    )}
                </div>
            );
        case "select":
            return (
                <div
                    id={id}
                    className={cn(
                        "input-group",
                        {
                            error:
                                validationMessage && validationMessage.required,
                        },
                        classNames,
                    )}>
                    <Select
                        options={options}
                        onChange={onChanged}
                        placeholder={placeholder}
                        defaultValue={val}
                    />
                    {validationMessage ? (
                        <ValidationMessage
                            validationMessage={validationMessage}
                        />
                    ) : (
                        <span className="label">{label}</span>
                    )}
                </div>
            );
        case "checkbox":
            return (
                <div id={id} className={cn("input-group", classNames)}>
                    <input
                        type="checkbox"
                        className={cn({ upperCase })}
                        onChange={({ target }) => onChanged(target.value)}
                        defaultValue={val}
                        onKeyPress={onKeyPress}
                        readOnly={readonly}
                        onFocus={onFocused}
                        value={val}
                        checked={value}
                    />
                    {validationMessage ? (
                        <ValidationMessage
                            validationMessage={validationMessage}
                        />
                    ) : (
                        <span className="label">{label}</span>
                    )}
                </div>
            );
        case "input":
        default:
            return (
                <div id={id} className={cn("input-group", classNames)}>
                    <input
                        type={inputType}
                        className={cn({ upperCase })}
                        onChange={({ target }) => onChanged(target.value)}
                        defaultValue={val}
                        onKeyPress={onKeyPress}
                        readOnly={readonly}
                        onFocus={onFocused}
                        value={val}
                    />
                    {validationMessage ? (
                        <ValidationMessage
                            validationMessage={validationMessage}
                        />
                    ) : (
                        <span className="label">{label}</span>
                    )}
                </div>
            );
    }
};

export default Input;

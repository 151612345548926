import { default as farms } from "./farms.js";
import configs from "./configs.json";
import basestations from "./basestations.json";
import users from "./users.json";
import cowTypes from "./cowTypes.json";
import priviliges from "./priviliges.json";
import metrics from "./metrics.json";
import health from "./health.json";
import wicowMetrics from "./wicowMetrics.json";
export default {
    configs,
    users,
    basestations,
    cowTypes,
    farms,
    priviliges,
    metrics,
    health,
    wicowMetrics,
};

import { useEffect } from "react";

export function useOnClickOutside(ref, handler) {
    useEffect(() => {
        const rootEl = document.querySelector("div#root");
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };

        rootEl.addEventListener("mousedown", listener);
        rootEl.addEventListener("touchstart", listener);

        return () => {
            rootEl.removeEventListener("mousedown", listener);
            rootEl.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
}

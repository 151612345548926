export default function tagSensor (currentTags, eventType) {
    
    if (!currentTags.includes("idle") && eventType === "session-created") {
        throw new Error(`Can't process the ${eventType} event without 'idle'`);
    }

    if (!currentTags.includes("assigned") && eventType === "sensor-activated") {
        throw new Error(`Can't process the ${eventType} event without 'assigned'`);
    }

    if ((!currentTags.includes("pending") && !currentTags.includes("unstable")) &&
        eventType === "stable-data-detected") {
            throw new Error(`Can't process the ${eventType} event without 'pending' or 'unstable'`);
    }

    if (!currentTags.includes("stable") && eventType === "unstable-data-detected") {
        throw new Error(`Can't process the ${eventType} event without 'stable'`);
    }

    if (currentTags.includes("idle") && eventType === "session-completed") {
        throw new Error(`Can't process the ${eventType} event with 'idle'`);
    }

    if ((currentTags.includes("pending") || currentTags.includes("stable") || currentTags.includes("unstable")) &&
        eventType === "sensor-excluded") {
            throw new Error(`Can't process the ${eventType} event with 'pending' or 'stable' or 'unstable'`);
    }

    if (currentTags.includes("network-problem") && eventType === "no-communication") {
        throw new Error(`Can't process the ${eventType} with 'network-problem'`);
    }

    switch(eventType) {
        case "session-created":
            return updateSensorTags(currentTags, ["assigned"], ["idle"]);
        case "sensor-activated":
            return updateSensorTags(currentTags, ["active", "pending"], ["assigned"]);
        case "stable-data-detected":
            return updateSensorTags(currentTags, ["stable"], ["pending", "unstable"]);
        case "unstable-data-detected":
            return updateSensorTags(currentTags, ["unstable"], ["stable"]);
        case "low-battery-detected":
            return updateSensorTags(currentTags, ["low-battery"]);
        case "no-communication":
            if (currentTags.includes("low-battery")) {
                return updateSensorTags([], ["inoperative", "low-battery", "network-problem"]);
            } else {
                return updateSensorTags([], ["network-problem"]);
            }
        case "fault-detected":
            return updateSensorTags([], ["inoperative"]);
        case "sensor-reset":
            return updateSensorTags([], ["idle"]);
        case "session-completed":
            const newTagsCompleted = finishSessionWithSensorWarnings(currentTags);
            if (newTagsCompleted === false) {
                return updateSensorTags([], ["idle"]);
            } else {
                return newTagsCompleted;
            }
        case "session-trashed":
            const newTagsTrashed = finishSessionWithSensorWarnings(currentTags);
            if (newTagsTrashed === false) {
                return updateSensorTags([], ["idle"]);
            } else {
                return newTagsTrashed;
            }
        case "session-canceled":
            const newTagsCanceled = finishSessionWithSensorWarnings(currentTags);
            if (newTagsCanceled === false) {
                return updateSensorTags([], ["idle"]);
            } else {
                return newTagsCanceled;
            }
        case "new-sensor-acquired":
            return updateSensorTags([], ["transfer"]);
        case "sensor-excluded":
            return updateSensorTags([], ["excluded"]);
        case "sensor-transfer-over":
            return updateSensorTags(currentTags, ["idle"], ["transfer"]);
        case "sensor-transfer-started":
            return updateSensorTags(currentTags, ["transfer"], ["idle"]);
        case "improper-event":
        default:
            return null;
    }
}

function finishSessionWithSensorWarnings(currentTags) {
    if (currentTags.includes("network-problem") || currentTags.includes("inoperative") || currentTags.includes("fault")) {
        return updateSensorTags(currentTags, []);
    }
    if (currentTags.includes("low-battery")) {
        return updateSensorTags(["lows-battery"], ["idle"]);
    }
    return false;
}

function updateSensorTags(currentTags, newTags, removableTags = []) {
    const substractedTags = currentTags.filter((tag) => !removableTags.includes(tag));
    const tags = [...new Set([...substractedTags, ...newTags])];
    return tags;
}
import * as ACTION from "../actions/actionTypes";
import { LIFECYCLE } from "../../constants";

const initial = {
    lifecycle: LIFECYCLE.INITIAL,
    input: ""
};

const reducer = ( form = initial, action ) => {
    switch ( action.type ) {
    case ACTION.INPUT_NOTE:
        return { input: action.input, lifecycle: LIFECYCLE.INITIAL };
    case ACTION.SUBMIT_NOTE:
        return { ...form, lifecycle: LIFECYCLE.PENDING };
    case ACTION.COMPLETE_SUBMIT_NOTE_REQUEST:
        return { input: "", lifecycle: LIFECYCLE.DONE };
    case ACTION.FAIL_SUBMIT_NOTE_REQUEST:
        return { ...form, lifecycle: LIFECYCLE.FAILED };
    default: return form;
    }
};
export default reducer;

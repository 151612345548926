import {
    COMPLETE_FETCH_EVENTS_REQUEST,
    COMPLETE_SEND_SMART_NOTE,
    COMPLETE_FETCH_EVENTS_VIEW_REQUEST,
} from "../actions/actionTypes";

const reducer = (linkedEvents = {}, action) => {
    switch (action.type) {
        case COMPLETE_FETCH_EVENTS_REQUEST:
        case COMPLETE_FETCH_EVENTS_VIEW_REQUEST:
        case COMPLETE_SEND_SMART_NOTE:
            return action.data.events
                .filter((e) => e.payload.parentEventPayload)
                .reduce((acc, e) => {
                    return {
                        ...acc,
                        ...{ [e.payload.parentEventPayload.eventId]: e.id },
                    };
                }, linkedEvents);
        default:
            return linkedEvents;
    }
};
export default reducer;

import { put, call, spawn, take } from "redux-saga/effects";
import axios from "axios";

import {
    SKIP_LOGIN_REQUEST,
    START_LOGIN_REQUEST,
    COMPLETE_LOGIN_REQUEST,
    FAIL_LOGIN_REQUEST,
    SET_ACTIVE_USER,
    GOOGLE_LOGIN,
    COMPLETE_REGISTER_DEMO_ACCOUNT,
} from "../actions/actionTypes";

import { setAllItem } from "./tour";

import { loginLink } from "./";

export function* googleLogin() {
    const { googleTokenId } = yield take(GOOGLE_LOGIN);
    console.log({ googleTokenId });
    try {
        const { data } = yield call(axios.post, "/login/google", {
            googleTokenId,
        });
        console.log(data);
        window.localStorage.setItem("longTermToken", data.token);
        window.localStorage.setItem("farms", JSON.stringify(data.farms));
        window.localStorage.setItem(
            "ownerUserId",
            JSON.stringify(data.ownerUserId),
        );
        if (data.farms.length === 0) {
            const demoUser = {
                ...data.user,
                phoneNumber: null,
                apiUserTypesId: 1,
                isFarmAdmin: true,
                tags: ["registered"],
            };
            yield put({ type: COMPLETE_REGISTER_DEMO_ACCOUNT, user: demoUser });
        } else {
            window.location.reload();
        }
    } catch (error) {}
}

export function* login() {
    const currentURL = new URL(window.location);
    const platform = currentURL.searchParams.get("platform");
    let production = "false";
    //detect if it is product build
    if (window.location.hostname == "mobile.wicows.com") production = "true";
    if (platform == "ios") {
        window.location =
            "wicowpro://" +
            window.location.search +
            "&production=" +
            production;
        return;
    } else if (platform == "android") {
        window.location =
            "wicowpro://" +
            window.location.search +
            "&production=" +
            production;
        return;
    }

    const singleUseToken = currentURL.searchParams.get("singleUseToken");
    if (!singleUseToken) {
        yield spawn(checkLTT);
        return;
    }

    yield put({ type: START_LOGIN_REQUEST });
    try {
        const response = yield call(axios.get, "/login", {
            headers: { Authorization: `Bearer ${singleUseToken}` },
        });
        localStorage.setItem("longTermToken", response.data.token);
        localStorage.setItem("farms", JSON.stringify(response.data.farms));
        localStorage.setItem(
            "ownerUserId",
            JSON.stringify(response.data.ownerUserId),
        );

        yield put({
            type: SET_ACTIVE_USER,
            id: response.data.ownerUserId,
        });

        //Send longTermToken to React Native
        yield window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: "longTermToken",
                longTermToken: response.data.token,
            }),
        );

        yield put({
            type: COMPLETE_LOGIN_REQUEST,
            farms: response.data.farms,
        });
    } catch (error) {
        currentURL.searchParams.delete("singleUseToken");
        yield spawn(checkLTT);
    }
}

function* checkLTT() {
    const backup = yield JSON.parse(localStorage.getItem("backup"));
    const tourAccessed = yield JSON.parse(localStorage.getItem("tourAccessed"));
    if (backup && tourAccessed) {
        yield window.localStorage.clear();
        yield setAllItem(backup);
    } else {
        localStorage.setItem("tourAccessed", true);
    }
    const longTermToken = localStorage.getItem("longTermToken");
    const farms = localStorage.getItem("farms");
    if (!longTermToken || !farms) {
        yield put({
            type: FAIL_LOGIN_REQUEST,
            error: "Login Failed",
        });
        yield spawn(loginLink);
        return;
    }

    handleUnauthorized();

    //Send info about skipping login to React Native
    const isProduction =
        window.location.hostname == "mobile.wicows.com" ? true : false;
    yield window.ReactNativeWebView.postMessage(
        JSON.stringify({
            type: "skipLoginRequest",
            longTermToken: longTermToken,
            isProduction,
        }),
    );
    yield put({ type: SKIP_LOGIN_REQUEST });
}

function handleUnauthorized() {
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.response && error.response.status === 401) {
                // remove LTT
                localStorage.removeItem("longTermToken");
                // restart the app
                window.location.reload();
            }
            return Promise.reject(error);
        },
    );
}

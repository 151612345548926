import { combineReducers } from "redux";


import { default as queries } from "./filter";
import { default as sort } from "./sort";
import { default as sortOrder } from "./sortOrder";
import { default as ui } from "./ui";
import { default as search } from "./search";


export default combineReducers({
    queries,
    sort,
    sortOrder,
    ui,
    search
});

export const VARIABLES = {
    calfCount: {
        id: "calfCount",
        default: "one",
        options: {
            one: { id: "one", parent: "calfCount", value: "one", count: 1 },
            two: { id: "two", parent: "calfCount", value: "two", count: 2 },
            three: {
                id: "three",
                parent: "calfCount",
                value: "three",
                count: 3,
            },
        },
    },
    healthCondition: {
        id: "healthCondition",
        default: "healthy",
        options: {
            healthy: {
                id: "healthy",
                parent: "healthCondition",
                value: "healthy",
            },
            unhealthy: {
                id: "unhealthy",
                parent: "healthCondition",
                value: "unhealthy",
            },
            dead: { id: "dead", parent: "healthCondition", value: "dead" },
        },
    },
    gender: {
        id: "gender",
        default: "gender",
        placeholder: "gender",
        options: {
            male: { id: "male", parent: "gender", value: "male" },
            female: { id: "female", parent: "gender", value: "female" },
        },
    },
    calvingCondition: {
        id: "calvingCondition",
        default: "easy",
        placeholder: "difficulty",
        options: {
            easy: { id: "easy", parent: "calvingCondition", value: "easy" },
            assisted: {
                id: "assisted",
                parent: "calvingCondition",
                value: "assisted",
            },
            difficult: {
                id: "difficult",
                parent: "calvingCondition",
                value: "difficult",
            },
        },
    },
    healthComplication: {
        id: "healthComplication",
        default: "noComplication",
        placeholder: "add",
        options: {
            tissueDamage: {
                id: "tissueDamage",
                parent: "healthComplication",
                value: "tissueDamage",
            },
            milkFever: {
                id: "milkFever",
                parent: "healthComplication",
                value: "milkFever",
            },
            inflammation: {
                id: "inflammation",
                parent: "healthComplication",
                value: "inflammation",
            },
            downerCow: {
                id: "downerCow",
                parent: "healthComplication",
                value: "downerCow",
            },
        },
    },
    willPlaced: {
        id: "willPlaced",
        default: "no",
        options: {
            yes: { id: "yes", parent: "willPlaced", value: "yes" },
            no: { id: "no", parent: "willPlaced", value: "no" },
        },
    },
    inseminationReason: {
        id: "inseminationReason",
        default: "inseminated",
        options: {
            inseminated: {
                id: "inseminated",
                parent: "inseminationReason",
                value: "inseminated",
            },
            examined: {
                id: "examined",
                parent: "inseminationReason",
                value: "examined",
            },
            dropped: {
                id: "dropped",
                parent: "inseminationReason",
                value: "dropped",
            },
        },
    },
    feverReason: {
        id: "feverReason",
        default: "unknown",
        options: {
            unknown: { id: "unknown", parent: "feverReason", value: "unknown" },
            vaccine: { id: "vaccine", parent: "feverReason", value: "vaccine" },
            milkfever: {
                id: "milkfever",
                parent: "feverReason",
                value: "milkfever",
            },
        },
    },
    feverTreatment: {
        id: "feverTreatment",
        default: "noTreatment",
        options: {
            noTreatment: {
                id: "noTreatment",
                parent: "treatment",
                value: "noTreatment",
            },
            antibiotic: {
                id: "antibiotic",
                parent: "treatment",
                value: "antibiotic",
            },
            antipyretic: {
                id: "antipyretic",
                parent: "treatment",
                value: "antipyretic",
            },
        },
    },
    isInseminated: {
        id: "isInseminated",
        default: "no",
        options: {
            yes: { id: "yes", parent: "isInseminated", value: "yes" },
            no: { id: "no", parent: "isInseminated", value: "no" },
        },
    },
};

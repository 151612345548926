import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { EVENTS, COW } from "../../../constants/schema";

import "./EventBadges.css";

const EventBadges = ({ cowEventsWithBadges, TsensTemperature }) => {
    const [badgeCoordinates, setBadgeCoordinates] = useState({});
    useEffect(
        () => {
            const newXY = cowEventsWithBadges.reduce((acc, e) => {
                const point = getBadgePoint(TsensTemperature, e);
                if (!point) return acc;
                return {
                    ...acc,
                    [e.id]: point
                };
            }, {});
            setBadgeCoordinates(newXY);
        },
        [cowEventsWithBadges]
    );
    return (
        <svg id="icons"
            xmlns="http://www.w3.org/2000/svg"
        >
            {
                cowEventsWithBadges.map(e => {
                    return (
                        <foreignObject key={e.id} id={e.type} width="32" height="32"
                            x={badgeCoordinates[e.id] ? badgeCoordinates[e.id].x : -40}
                            y={badgeCoordinates[e.id] ? badgeCoordinates[e.id].y -16 : -40}
                        >
                            <div className={cn("badge-item", e.badgeType, e.tags)}></div>
                        </foreignObject>
                    );
                })
            }
        </svg>
    );
};

function mapStateToProps({ sensors }, { cowEvents }) {
    return {
        cowEventsWithBadges: cowEvents ?
            cowEvents.reduce((acc, event) => {
                const badge = badgeTagsMap()[event.type];

                if (!badge) return acc;

                const tags = event.payload.tags[badge.item];

                if (badge.type == "sensor"){
                    badge.type = sensors[event.payload.sensor].type;
                }
                return [
                    ...acc,
                    {
                        id: event.id,
                        type: event.type,
                        timestamp: event.timestamp,
                        badgeType: badge.type,
                        tags: tags.filter(t => badge.allowedTags.includes(t))
                    }
                ];
            }, [])
            : []
    };
}

function getBadgePoint(temperature, event){
    const period = 240000;
    const firstValueIndex = temperature.timestamp.findIndex(t => t);
    const badgeIndex = parseInt(((temperature.timestamp[firstValueIndex] - event.timestamp) / period) + firstValueIndex);
    const svgIconsElement = document.querySelector("svg#icons");
    const y = temperature.data.find((t, index) => index >= badgeIndex && temperature.data[index]);
    if (!y) return;
    const point = domToSvg(badgeIndex, y, svgIconsElement);
    return point;
}

function domToSvg(x, y, svgElement){
    const g = document.querySelector("svg #graph");
    const point = svgElement.createSVGPoint();
    point.x= x;
    point.y= parseInt(y);
    const newPoint = point.matrixTransform(g.getScreenCTM());
    return newPoint.matrixTransform(svgElement.getScreenCTM().inverse());
}

function badgeTagsMap(){
    return {
        [EVENTS.PREGNANCY_DETECTED]: { item: "cow", type: "cow", allowedTags: [COW.PREGNANCY_DETECTED] },
        [EVENTS.CALVING_DUE]: { item: "cow", type: "cow", allowedTags: [COW.DUE] },
        [EVENTS.CALVING_OVERDUE]: { item: "cow", type: "cow", allowedTags: [COW.OVERDUE] },
        [EVENTS.CALVING_DETECTED]: { item: "cow", type: "cow", allowedTags: [COW.CALVING] },
        [EVENTS.SESSION_CREATED]: { item: "sensor", type: "sensor", allowedTags: [] },
        [EVENTS.FEVER_DETECTED]: { item: "cow", type: "temperature", allowedTags: [COW.FEVER] },
        [EVENTS.FEVER_INCREASE_DETECTED]: { item: "cow", type: "temperature", allowedTags: [COW.FEVER] },
        [EVENTS.HEAT_DETECTED]: { item: "cow", type: "estrous", allowedTags: [COW.HEAT] },
        [EVENTS.HEAT_OVER]: { item: "cow", type: "heatOver", allowedTags: [] }
    };
}


export default connect(mapStateToProps)(EventBadges);
import { RESTORE_SNAPSHOT } from "../../../store/actions/actionTypes";
import { COW } from "../../../constants";

import events from "./mockServerEvents";

import { stepNamespace, checkForState, waitForSnapshot, reCapture } from ".";

const step = stepNamespace("cow-box");

const cowSelector = "article.cow";
const cowAttachment = { element: cowSelector, on: "bottom" };

const badgeSelector = (cls) => `${cowSelector} .badge-item.${cls}`;
const badgeAttachment = (cls) => ({
    element: badgeSelector(cls),
    on: "bottom",
});

export default function cowBoxSteps() {
    return [
        {
            ...step("define"),
            attachTo: cowAttachment,
        },
        {
            ...step("red-alert"),
            attachTo: cowAttachment,
            ...waitForSnapshot(),
            when: {
                show() {
                    if (
                        checkForState(
                            ({ tags: { cow } }) =>
                                !cow[2].map((t) => t.value).includes(COW.FEVER),
                        )
                    ) {
                        events.feverDetected(2, 39.4);
                    }
                    events.feverDetected(2, 39.4);
                },
            },
            revert(tour) {
                tour.options.dispatch({ type: RESTORE_SNAPSHOT });
            },
        },
        {
            ...step("alert-badge"),
            attachTo: badgeAttachment("temperature"),
        },
        {
            ...step("calf-badge"),
            attachTo: badgeAttachment("calf"),
            when: {
                hide() {
                    if (
                        checkForState(({ tags: { cow } }) =>
                            cow[2].map((t) => t.value).includes(COW.FEVER),
                        )
                    ) {
                        events.feverOver(2, 37.2);
                    }
                    events.feverOver(2, 37.2);
                },
            },
        },
        {
            ...step("tsens-badge"),
            attachTo: badgeAttachment("Tsens"),
            ...waitForSnapshot(() => events.unstableDataDetected(1)),
            when: {
                "before-show"() {
                    reCapture(this);
                },
                hide() {
                    events.stableDataDetected(2, 1);
                },
            },
        },
    ];
}

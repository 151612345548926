import React, { useRef, useEffect, useState } from "react";

import "./Tooltip.css";
import "../../index.css";

function Tooltip({
    coordinateX,
    coordinateY,
    width,
    height,
    children,
    visible,
    refHandler,
    onTooltipStart,
    onTooltipEnd,
    tooltipPosition,
}) {
    const contentRef = useRef(null);
    const [heightState, setHeightState] = useState(height);
    const [widthState, setWidthState] = useState(width);
    const arrowWidth = 10;
    const arrowLength = 15;
    const strokeWidth = 3;
    useEffect(() => {
        const childrenHeight = contentRef.current
            .querySelector("div")
            .getBoundingClientRect().height;
        const parentWidth = contentRef.current
            .closest("#tooltip")
            .parentNode.getBoundingClientRect().width;
        if (parentWidth !== widthState + strokeWidth && !width) {
            setWidthState(parentWidth - strokeWidth);
        }
        if (childrenHeight !== heightState && !height) {
            if (heightState < arrowLength * 3) {
                setHeightState(arrowLength * 2);
            } else {
                setHeightState(childrenHeight);
            }
        }
    }, [widthState, width, heightState, height]);
    if (!coordinateY) {
        coordinateY = heightState / 2;
    }
    return (
        <svg
            id="tooltip-clicked"
            height={heightState}
            className={visible ? "visible" : null}
            xmlns="http://www.w3.org/2000/svg"
            ref={refHandler}
            onTouchStart={onTooltipStart}
            onTouchEnd={onTooltipEnd}>
            <g className="info">
                <path
                    className="indicator"
                    d={indicatorCoordinates(
                        tooltipPosition,
                        coordinateX,
                        coordinateY,
                        arrowWidth,
                        arrowLength,
                        heightState,
                    )}
                />
                <foreignObject
                    x={contentCoordinates(
                        tooltipPosition,
                        coordinateX,
                        widthState,
                        arrowWidth,
                    )}
                    y="0"
                    width={widthState - arrowWidth}
                    height={heightState}>
                    <div className="content" ref={contentRef}>
                        <div>{children}</div>
                    </div>
                </foreignObject>
            </g>
        </svg>
    );
}

export default Tooltip;

function indicatorCoordinates(
    tooltipPosition,
    coordinateX,
    coordinateY,
    arrowWidth,
    arrowLength,
    heightState,
) {
    switch (tooltipPosition) {
        default:
        case "left":
            return `M${coordinateX - arrowWidth} 0 
                L${coordinateX - arrowWidth} ${coordinateY - arrowLength} 
                L${coordinateX} ${coordinateY} 
                L${coordinateX - arrowWidth} ${coordinateY + arrowLength} 
                L${coordinateX - arrowWidth} ${heightState}`;
        case "right":
            return `M${coordinateX + arrowWidth} 0 
                L${coordinateX + arrowWidth} ${coordinateY - arrowLength} 
                L${coordinateX} ${coordinateY} 
                L${coordinateX + arrowWidth} ${coordinateY + arrowLength} 
                L${coordinateX + arrowWidth} ${heightState}`;
    }
}

function contentCoordinates(
    tooltipPosition,
    coordinateX,
    widthState,
    arrowWidth,
) {
    switch (tooltipPosition) {
        default:
        case "left":
            return coordinateX - widthState;
        case "right":
            return coordinateX + arrowWidth;
    }
}

import { Response } from "miragejs";
import { SESSION } from "../../constants/schema";
import { cowData } from "./mockData";
import { handleEvent } from "./events";
import { getEventSupporters } from "./../utilities";

export default function routes() {
    this.urlPrefix = window._env_.API_HOST;

    this.get("users/farms", (schema) => {
        const farms = schema.db.farms;
        return farms;
    });

    this.put("users/config", () => {
        return new Response(204);
    });

    this.get("users/config", (schema) => {
        return schema.db.configs;
    });

    this.get("users/priviliges", (schema) => {
        return schema.db.priviliges;
    });

    this.get("farms/warnings", () => {
        return { 1: 1 };
    });

    this.put("users/ownerUserId", () => {
        return new Response(200, {}, 1);
    });

    this.get("v2/events", (schema, request) => {
        const events = schema.db.events;
        const cows = [],
            sensors = [],
            sessions = [],
            users = [],
            basestations = [];
        events.map((e) => {
            if (e.payload.cow && !cows.includes(e.payload.cow))
                cows.push(e.payload.cow);
            if (e.payload.sensor && !sensors.includes(e.payload.cow))
                sensors.push(e.payload.sensor);
            if (e.payload.session && !sessions.includes(e.payload.session))
                sessions.push(e.payload.session);
            if (e.payload.user && !users.includes(e.payload.user))
                users.push(e.payload.user);
            if (
                e.payload.basestation &&
                !basestations.includes(e.payload.basestation)
            )
                basestations.push(e.payload.basestation);
        });
        const { db } = schema;
        return {
            events,
            cows: cows.map((c) => db.cows.find(c)),
            sensors: sensors.map((s) => db.sensors.find(s)),
            sessions: sessions.map((s) => db.sessions.find(s)),
            users: users.map((u) => db.users.find(u)),
            basestations: basestations.map((b) => db.basestations.find(b)),
        };
    });

    this.get("v2/events", ({ db }, { queryParams }) => {
        if (queryParams.toLastSessionId) {
            return {
                events: [],
                cows: [],
                sensors: [],
                sessions: [],
                users: [],
                basestations: [],
            };
        }
        return getEventSupporters(db, db.events);
    });

    this.get("cows", ({ db }) => db.cows);
    this.get("cowTypes", ({ db }) => db.cowTypes);
    this.get("users", ({ db }) => db.users);
    this.get("sensors", ({ db }) => {
        return {
            basestations: db.basestations,
            sensors: db.sensors,
        };
    });

    this.get("data/cow/:cowId", ({ db }, { params }) => {
        console.log("STORAGE", window.localStorage.getItem("mockData"));
        const cowSessions = db.sessions.filter((s) => s.cow == params.cowId);
        const response = {
            sessions: cowSessions.reduce((acc, s) => {
                return [...acc, { ...s, ...cowData }];
            }, []),
            cows: [db.cows.find(params.cowId)],
            sensor: db.sensors.find(cowSessions.map((s) => s.id)),
            period: 240000,
        };
        return response;
    });

    this.post("v2/events", ({ db }, { requestBody }) => {
        const event = JSON.parse(requestBody);
        const events = handleEvent(db, event);
        return getEventSupporters(db, events);
    });
}

import React, { useState } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Switch from "../Switch";
import DataGraph, { TemperatureLabel } from "../DataGraph";
import { useMobile } from "../isMobile";
import { turnSwitch, toggleFullscreen } from "../../store/actions";
import SensorLabel from "../SensorLabel";

import { PreviewSlider, Overlay, Left } from "./PreviewSlider";
import "./AutomationPreview.css";

const AutomationPreview = ({
    turnSwitch,
    placePairing: { id, placeName, switchStatus, status, deviceId },
    switchCheck,
    switchValue,
    lastTemperature,
    lastTimestamp,
    lifecyclePending,
    toggleFullscreen,
    preview,
}) => {
    const { isMobileView } = useMobile();
    const [tooltipClickedVisible, setTooltipClickedVisible] = useState(false);
    const { t } = useTranslation();
    function setFullscreen() {
        toggleFullscreen(
            preview && preview.isFullscreen ? !preview.isFullscreen : true,
        );
    }
    const dragRightLimit = -1 / 4.5;
    const dragLeftLimit = 1 / 8;
    return (
        <aside className={cn({ "automation-preview": true })} id={`${id}`} open>
            {isMobileView && preview && !preview.isFullscreen ? (
                <PreviewSlider
                    limitLeft={window.innerWidth}
                    limitRight={-window.innerWidth}
                    dragLeftBorder={window.innerWidth * dragLeftLimit}
                    dragRightBorder={window.innerWidth * dragRightLimit}>
                    <Overlay key="Overlay">
                        <div className={cn("automation-action")} open>
                            <header>
                                <h1>{placeName}</h1>
                                <h4 className="device-id">
                                    <SensorLabel {...{ type: "", deviceId }} />
                                </h4>
                                {lifecyclePending ? (
                                    <h4>{t("loading")}</h4>
                                ) : (
                                    <TemperatureLabel
                                        temperature={lastTemperature}
                                        lastTimestamp={lastTimestamp}
                                    />
                                )}
                            </header>
                            <div className="switch-box">
                                <Switch
                                    onClicked={() =>
                                        turnSwitch(switchCheck, id, deviceId)
                                    }
                                    check={switchStatus}
                                    status={status}
                                    switchValue={switchValue}
                                    useAutomation
                                />
                            </div>
                        </div>
                    </Overlay>
                    <Left key="Left">
                        <div className="graph-preview">
                            <header>
                                <h1>{placeName}</h1>
                                <h4 className="device-id">
                                    <SensorLabel {...{ type: "", deviceId }} />
                                </h4>
                                <span
                                    className={cn("fullscreen-button", {
                                        off: preview && !preview.isFullscreen,
                                    })}
                                    onClick={() => setFullscreen()}
                                />
                            </header>
                            <div>
                                <DataGraph
                                    id={id}
                                    tooltipClickedVisible={
                                        tooltipClickedVisible
                                    }
                                    setTooltipClickedVisible={
                                        setTooltipClickedVisible
                                    }
                                    useAutomation
                                />
                            </div>
                        </div>
                    </Left>
                </PreviewSlider>
            ) : (
                <React.Fragment>
                    <header>
                        <h1>{placeName}</h1>
                        <h4 className="device-id">
                            <SensorLabel {...{ type: "", deviceId }} />
                        </h4>
                        <span
                            className={cn("fullscreen-button", {
                                off: preview && !preview.isFullscreen,
                            })}
                            onClick={() => setFullscreen()}
                        />
                        <Switch
                            onClicked={() =>
                                turnSwitch(switchCheck, id, deviceId)
                            }
                            check={switchStatus}
                            status={status}
                            switchValue={switchValue}
                        />
                    </header>
                    <div className="graph">
                        <DataGraph
                            id={id}
                            tooltipClickedVisible={tooltipClickedVisible}
                            setTooltipClickedVisible={setTooltipClickedVisible}
                            useAutomation
                        />
                    </div>
                </React.Fragment>
            )}
        </aside>
    );
};

function mapStateToProps({ automation, preview }, { id }) {
    return {
        placePairing: automation[id],
        switchCheck: automation[id].switchStatus === "off" ? "on" : "off",
        switchValue: automation[id].switchStatus === "off" ? false : true,
        lastTemperature: automation[id].data
            ? automation[id].data.temperature.find((t) => t)
            : null,
        lastTimestamp: automation[id].data
            ? automation[id].data.timestamp.find((t) => t)
            : null,
        lifecyclePending: automation[id].lifecycle == "pending",
        preview,
    };
}

const mapDispatchToProps = { turnSwitch, toggleFullscreen };

export default connect(mapStateToProps, mapDispatchToProps)(AutomationPreview);

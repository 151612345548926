import React from "react";
import cn from "classnames";
import { Trans } from "react-i18next";
import { connect } from "react-redux";

import { Cow, Sensor } from "../Events/Event/titles";
import Time from "../Time";
import i18n from "../../i18n";
import { COW, TAG_PARAMS } from "../../constants";
import { tagParams } from "../common";

function Line({
    paired,
    cowId,
    sensorId,
    sessionStartTime,
    onClicked,
    isAdded,
    unavailable,
    tag,
}) {
    const suggestion = [];
    if (paired) {
        suggestion.push(<Sensor id={sensorId} />);
        suggestion.push(
            <p>
                <Trans i18nKey="pairedAt">
                    Paired at
                    <Time
                        time={sessionStartTime}
                        options={{
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                        }}
                    />
                </Trans>
            </p>,
        );
    } else {
        if (!unavailable) {
            suggestion.push(<Sensor id={sensorId} />);
            if (sensorId > 0) {
                suggestion.push(<p>{i18n.t("available")}</p>);
            } else {
                suggestion.push(<p>{i18n.t("noAvailableTsens")}</p>);
            }
        }
    }
    return (
        <li
            className={cn({ paired, unavailable, "in-list": isAdded })}
            onClick={() =>
                onClicked(isAdded ? false : true, {
                    cow: cowId,
                    sensor: sensorId,
                })
            }>
            <p>
                <Cow id={cowId} />
            </p>
            {tag ? (
                <div className={cn("tag", tag.value)}>
                    {/* <strong>{i18n.t(tag.value)}</strong> */}
                    <span>
                        {tagParams(
                            tag.value,
                            tag.params[TAG_PARAMS[tag.value]],
                        )}
                    </span>
                </div>
            ) : null}
            <div className="sensor">{suggestion}</div>
        </li>
    );
}

function mapStateToProps({ tags }, { cowId }) {
    return { tag: tags.cow[cowId].find((t) => t.value === COW.PREGNANT) };
}

export default connect(mapStateToProps)(Line);

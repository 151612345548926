import {
    START_CALL_REQUEST,
    COMPLETE_CALL_REQUEST,
    FAIL_CALL_REQUEST,
    SET_CONFIGS,

    START_EMAIL_REQUEST,
    COMPLETE_EMAIL_REQUEST,
    FAIL_EMAIL_REQUEST,

    START_NOTIFICATIONS_REQUEST,
    COMPLETE_NOTIFICATIONS_REQUEST,
    FAIL_NOTIFICATIONS_REQUEST
} from "../../actions/actionTypes";

const initialState = {
    calvingDueIn24Hours: {
        call: { status: false, ui: "available" },
        notification: { status: false, ui: "available" },
        email: { status: false, ui: "available" }
    },
    imminentCalving: {
        call: { status: false, ui: "available" },
        notification: { status: false, ui: "available" },
        email: { status: false, ui: "available" }
    },
    calvingOverdue: {
        call: { status: false, ui: "available" },
        notification: { status: false, ui: "available" },
        email: { status: false, ui: "available" }
    },
    feverHypothermia: {
        call: { status: false, ui: "available" },
        notification: { status: false, ui: "available" },
        email: { status: false, ui: "available" }
    }
};

const configTypes = {
    CALVING_NOTICE_TYPES: "imminentCalving",
    DUE_NOTICE_TYPES: "calvingDueIn24Hours",
    OVERDUE_NOTICE_TYPES: "calvingOverdue",
    FEVER_NOTICE_TYPES: "feverHypothermia",
};

const noticeTypes = ["call", "notification", "email"];

export default function (state = initialState, action) {
    switch (action.type) {
    case SET_CONFIGS:
        // eslint-disable-next-line no-case-declarations
        return action.configs.reduce((acc, c) => {
            if (c.name == "CALVING_NOTICE_TYPES" ||
                c.name == "DUE_NOTICE_TYPES" ||
                c.name == "OVERDUE_NOTICE_TYPES" ||
                c.name == "FEVER_NOTICE_TYPES") {
                const values = c.value.split(",");
                const newValues = noticeTypes.reduce((vs, n) => {
                    if (values.includes(n)) { return { ...vs, [n]: { status: true, ui: acc[configTypes[c.name]][n].ui } }; }
                    else { return { ...vs, [n]: { status: false, ui: acc[configTypes[c.name]][n].ui } }; }
                }, {});
                return { ...acc, [configTypes[c.name]]: newValues };
            }
            return acc;
        }, initialState);
    case START_CALL_REQUEST:
    case START_EMAIL_REQUEST:
    case START_NOTIFICATIONS_REQUEST:
        return { ...state, [action.noticeEventType]: { ...state[action.noticeEventType], [action.noticeType]: { ...state[action.noticeEventType][action.noticeType], ui: "pending" } } };
    case COMPLETE_CALL_REQUEST:
    case COMPLETE_EMAIL_REQUEST:
    case COMPLETE_NOTIFICATIONS_REQUEST:
        return { ...state, [action.noticeEventType]: { ...state[action.noticeEventType], [action.noticeType]: { ui: "done", status: !state[action.noticeEventType][action.noticeType].status } } };
    case FAIL_CALL_REQUEST:
    case FAIL_EMAIL_REQUEST:
    case FAIL_NOTIFICATIONS_REQUEST:
        return { ...state, [action.noticeEventType]: { ...state[action.noticeEventType], [action.noticeType]: { ...state[action.noticeEventType][action.noticeType], ui: "fail" } } };
    default: return state;
    }
}
import {
    UPDATE_RESOURCE,
    COMPLETE_FETCH_EVENTS_VIEW_REQUEST,
} from "../actions/actionTypes";
import {
    SENSOR,
    COW,
    REPEATER,
    defaultTags,
    BASESTATION,
    USER,
} from "../../constants/schema";

export default function (
    tags = {
        cow: {},
        sensor: {},
        session: {},
        basestation: {},
        user: {},
    },
    action,
) {
    if (
        action &&
        action.type != COMPLETE_FETCH_EVENTS_VIEW_REQUEST && // ignore events which are just for view
        action.data &&
        action.data.events &&
        Array.isArray(action.data.events)
    ) {
        const tagSets = [];

        for (const event of action.data.events) {
            if (
                event &&
                event.payload &&
                event.payload.tags &&
                isObject(event.payload.tags)
            ) {
                tagSets.push(
                    ...Object.keys(event.payload.tags).map((key) => {
                        const resource = `${key}s`;
                        const resourceId = key;
                        return {
                            key,
                            id: event.payload[key],
                            tags: event.payload.tags[key].map((tag) => {
                                const item = action.data[resource].find(
                                    (i) => i.id == event.payload[resourceId],
                                );
                                return {
                                    value: tag,
                                    params: event.payload.tags[
                                        resourceId
                                    ].reduce((params, key) => {
                                        switch (key) {
                                            case COW.PREGNANT:
                                                return {
                                                    ...params,
                                                    expectedDelivery:
                                                        item.expectedDelivery,
                                                };
                                            case COW.POST_PARTUM:
                                                return {
                                                    ...params,
                                                    lastCalvingDate:
                                                        item.lastCalvingDate,
                                                };
                                            case COW.ESTROUS:
                                                return {
                                                    ...params,
                                                    lastCalvingDate:
                                                        item.lastCalvingDate,
                                                };
                                            case REPEATER.ONLINE:
                                                return {
                                                    ...params,
                                                    onlineSince:
                                                        event.payload
                                                            .onlineSince,
                                                };
                                            case REPEATER.OFFLINE:
                                                return {
                                                    ...params,
                                                    offlineSince:
                                                        event.payload
                                                            .offlineSince,
                                                };
                                            case USER.CALLED_USER:
                                                if (tag == USER.CALLED_USER)
                                                    return {
                                                        ...params,
                                                        willCalledFrom:
                                                            event.payload
                                                                .willCalledFrom,
                                                    };
                                                return { ...params };
                                            default:
                                                return {
                                                    ...params,
                                                    [key]: event.payload[key],
                                                };
                                        }
                                    }, {}),
                                };
                            }),
                        };
                    }),
                );
            }
        }
        const newTags = { ...tags };
        for (const set of tagSets) {
            if (!newTags[set.key]) {
                newTags[set.key] = {};
            }
            newTags[set.key][set.id] = set.tags;
        }
        return newTags;
    }

    // insert default tags to all resources without tags
    if (action.type === UPDATE_RESOURCE) {
        const [resourceKey, defaults] = {
            sensors: [
                "sensor",
                SENSOR[defaultTags].map((tag) => {
                    return { value: tag, params: {} };
                }),
            ],
            cows: [
                "cow",
                COW[defaultTags].map((tag) => {
                    return { value: tag, params: {} };
                }),
            ],
            basestations: [
                "basestation",
                BASESTATION[defaultTags].map((tag) => {
                    return { value: tag, params: {} };
                }),
            ],
            users: [
                "user",
                USER[defaultTags].map((tag) => {
                    return { value: tag, params: {} };
                }),
            ],
        }[action.resource];

        if (action.resource.slice(0, -1) == resourceKey) {
            Object.keys(tags[resourceKey]).map((resourceId) => {
                tags[resourceKey][resourceId].map((tag) => {
                    switch (tag.value) {
                        case COW.PREGNANT:
                            tag.params.expectedDelivery = action.data.find(
                                (i) => i.id == resourceId,
                            ).expectedDelivery;
                            return tag;
                        default:
                            return tag;
                    }
                });
            });
        }

        if (resourceKey) {
            return {
                ...tags,
                [resourceKey]: {
                    ...action.data.reduce(
                        (ts, resource) => ({ ...ts, [resource.id]: defaults }),
                        {},
                    ),
                    ...tags[resourceKey],
                },
            };
        }
    }

    return tags;
}

function isObject(value) {
    return Object.keys(value) && !Array.isArray(value);
}

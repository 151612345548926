import {
    take, put, call, spawn, fork, select
} from "redux-saga/effects";
import {
    SUBMIT_NOTE,
    START_SUBMIT_NOTE_REQUEST,
    COMPLETE_SUBMIT_NOTE_REQUEST,
    FAIL_SUBMIT_NOTE_REQUEST
} from "../actions/actionTypes";
import { EVENTS } from "../../constants/schema";
import axios from "axios";

export function *submitNote() {
    yield fork(submitNoteRequest);
    yield take(SUBMIT_NOTE);
    yield put({ type: START_SUBMIT_NOTE_REQUEST });
    yield spawn(submitNote);
}

function *submitNoteRequest() {
    yield take(START_SUBMIT_NOTE_REQUEST);
    const note = yield select(({ noteForm: { input } }) => input);
    const noteEvent = {
        event: EVENTS.NOTE_SUBMITTED,
        payload: { note: note }
    };
    try {
        const response = yield call(axios.post, "/v2/events", noteEvent);
        yield put({
            type: COMPLETE_SUBMIT_NOTE_REQUEST,
            event: response.data.events[0]
        });
    } catch (error) {
        yield put({
            type: FAIL_SUBMIT_NOTE_REQUEST,
            error,
            note
        });
    }
}

import { navigateBack } from "../../../store/actions";
import ROUTES from "../../../constants/routes";

import {
    stepNamespace,
    advanceOnClick,
    waitForNavigation,
    media,
    delay,
    reCapture,
} from ".";

const step = stepNamespace("navigation");

export default function navigationSteps() {
    return [
        {
            ...step("menu"),
            beforeShowPromise: delay(500),
            ...advanceOnClick("header.navbar i.burger", { on: "bottom" }),
            revert(tour) {
                tour.options.dispatch(navigateBack());
            },
            when: {
                "before-show"() {
                    reCapture(this);
                },
            },
        },
        {
            ...step("intro"),
            attachTo: { element: "nav.main-menu" },
            ...waitForNavigation(ROUTES.MAIN_MENU),
            beforeShowPromise: delay(800),
            revert(tour) {
                tour.options.dispatch(navigateBack());
            },
            when: {
                "before-show"() {
                    reCapture(this);
                },
            },
            showOn: media("(pointer: coarse)"),
        },
        {
            ...step("intro"),
            attachTo: { element: "nav.main-menu", on: "left" },
            ...waitForNavigation(ROUTES.MAIN_MENU),
            revert(tour) {
                tour.options.dispatch(navigateBack());
            },
            when: {
                "before-show"() {
                    reCapture(this);
                },
            },
            showOn: media("(pointer: fine)"),
        },
        {
            ...step("cow-list"),
            ...advanceOnClick("nav.main-menu li.cow-list"),
        },
    ];
}

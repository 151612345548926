import { put, call, take } from "redux-saga/effects";
import {
    LOGOUT,
    START_LOGOUT_REQUEST,
    COMPLETE_LOGOUT_REQUEST,
    FAIL_LOGOUT_REQUEST,
} from "../actions/actionTypes";
import axios from "axios";

export function* logout() {
    yield take(LOGOUT);
    yield put({ type: START_LOGOUT_REQUEST });
    try {
        yield call(axios.delete, "/login");
        const lang = localStorage.getItem("language");
        window.localStorage.clear();
        localStorage.setItem("language", lang);

        //Send logout info to React Native
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "logout" }));
        yield put({ type: COMPLETE_LOGOUT_REQUEST });
        window.location.href = "/";
    } catch (error) {
        yield put({ type: FAIL_LOGOUT_REQUEST, error });
    }
}

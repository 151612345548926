import {
    SHOW_DEMO_MODAL,
    COMPLETE_REGISTER_DEMO_ACCOUNT,
    FAIL_REGISTER_DEMO_ACCOUNT,
    HIDE_DEMO_MODAL,
    RESET_DEMO_MODAL,
} from "../actions/actionTypes";

import { LIFECYCLE } from "../../constants";
import { HIDDEN, FORM, ERROR, RETRY } from "../../components/DemoModal";

const initial = {
    lifecycle: LIFECYCLE.INITIAL,
    modal: HIDDEN,
};

export default function (state = initial, action) {
    switch (action.type) {
        case SHOW_DEMO_MODAL:
            return { ...state, modal: FORM };
        case HIDE_DEMO_MODAL:
            return { ...state, modal: HIDDEN };
        case COMPLETE_REGISTER_DEMO_ACCOUNT:
            return { lifecycle: LIFECYCLE.DONE };
        case FAIL_REGISTER_DEMO_ACCOUNT:
            return { ...state, lifecycle: LIFECYCLE.INITIAL, modal: ERROR };
        case RESET_DEMO_MODAL:
            return { ...state, lifecycle: LIFECYCLE.INITIAL, modal: RETRY };
        default:
            return state;
    }
}


export default function (pairingLists = {}, action) {
    if (action &&
        action.data &&
        action.data.events &&
        action.data.sessions &&
        Array.isArray(action.data.events)
    ) {
        for (const event of action.data.events) {
            if (event &&
                event.type === "pairing-list-created" &&
                event.timestamp &&
                event.payload &&
                event.payload.pairingList &&
                event.payload.user
            )
            {
                const pairingList = event.payload.pairingList;
                if (!pairingLists.pairingList) pairingLists[pairingList] = {};
                pairingLists[pairingList] = { user: event.payload.user, timestamp: event.timestamp };
            }
        }
        return pairingLists;
    }
    return pairingLists;
}


import React from "react";
import cn from "classnames";

import "./ValidationMessage.css";

export default function ValidationMessage({ validationMessage }) {
    if (!validationMessage) return null;
    return (
        <div className={"validation-wrapper"}>
            <span
                className={cn("validate", {
                    required: validationMessage.required,
                })}>
                {validationMessage.text}
            </span>
        </div>
    );
}

/* eslint-disable no-case-declarations */
import {
    START_FETCH_EVENTS_VIEW_REQUEST,
    COMPLETE_FETCH_EVENTS_VIEW_REQUEST,
    COMPLETE_FETCH_EVENTS_REQUEST,
    FAIL_FETCH_EVENTS_VIEW_REQUEST,
    COMPLETE_SUBMIT_NOTE_REQUEST,
} from "../actions/actionTypes";
import { VIEW_EVENTS } from "../../constants/schema";

export default function (
    state = {
        eventIds: { cow: {}, single: [] },
        earliest: Number.MAX_SAFE_INTEGER,
        latest: Number.MIN_SAFE_INTEGER,
        toLastSessionId: null,
    },
    action,
) {
    if (
        action &&
        !(
            (action.type == COMPLETE_FETCH_EVENTS_REQUEST && !action.isTimer) ||
            action.type == COMPLETE_FETCH_EVENTS_VIEW_REQUEST
        ) &&
        action.data &&
        action.data.events &&
        Array.isArray(action.data.events)
    ) {
        const viewEventValues = Object.values(VIEW_EVENTS);
        const newState = action.data.events.reduce(
            (acc, event) => {
                if (!viewEventValues.includes(event.type)) return acc;
                let cowId = null;
                if (event.payload.parentEventPayload) {
                    cowId = event.payload.parentEventPayload.cow;
                } else {
                    cowId = event.payload.cow;
                }

                if (cowId === undefined) {
                    return {
                        toLastSessionId: action.data.sessions.reduce(
                            (sessionId, s) => {
                                if (sessionId > s.id) {
                                    return s.id;
                                } else {
                                    return sessionId;
                                }
                            },
                            Number.MAX_SAFE_INTEGER,
                        ),
                        earliest: Math.min(event.timestamp, acc.earliest),
                        latest: Math.max(event.timestamp, acc.latest),
                        eventIds: {
                            ...acc.eventIds,
                            single: {
                                ...acc.eventIds.single,
                                [event.id]: [event.id],
                            },
                        },
                    };
                }

                if (!acc.eventIds.cow[cowId]) acc.eventIds.cow[cowId] = [];
                return {
                    toLastSessionId: action.data.sessions.reduce(
                        (sessionId, s) => {
                            if (sessionId > s.id) {
                                return s.id;
                            } else {
                                return sessionId;
                            }
                        },
                        Number.MAX_SAFE_INTEGER,
                    ),
                    earliest: Math.min(event.timestamp, acc.earliest),
                    latest: Math.max(event.timestamp, acc.latest),
                    eventIds: {
                        ...acc.eventIds,
                        cow: {
                            ...acc.eventIds.cow,
                            [cowId]: Array.from(
                                new Set([...acc.eventIds.cow[cowId], event.id]),
                            ),
                        },
                    },
                };
            },
            {
                earliest: Math.min(state.earliest, Number.MAX_SAFE_INTEGER),
                latest: Math.max(state.latest, Number.MIN_SAFE_INTEGER),
                eventIds: state.eventIds,
            },
        );
        return { ...state, ...newState };
    }
    switch (action.type) {
        case START_FETCH_EVENTS_VIEW_REQUEST:
            return state;
        case COMPLETE_FETCH_EVENTS_VIEW_REQUEST:
            const viewEventValues = Object.values(VIEW_EVENTS);
            const newState = action.data.events.reduce(
                (acc, event) => {
                    if (!viewEventValues.includes(event.type)) return acc;
                    let cowId = null;
                    if (event.payload.parentEventPayload) {
                        cowId = event.payload.parentEventPayload.cow;
                    } else {
                        cowId = event.payload.cow;
                    }
                    if (cowId === undefined) {
                        return {
                            toLastSessionId: action.data.sessions.reduce(
                                (sessionId, s) => {
                                    if (sessionId > s.id) {
                                        return s.id;
                                    } else {
                                        return sessionId;
                                    }
                                },
                                Number.MAX_SAFE_INTEGER,
                            ),
                            earliest: Math.min(event.timestamp, acc.earliest),
                            latest: Math.max(event.timestamp, acc.latest),
                            eventIds: {
                                ...acc.eventIds,
                                single: {
                                    ...acc.eventIds.single,
                                    [event.id]: [event.id],
                                },
                            },
                        };
                    }

                    if (!acc.eventIds.cow[cowId]) acc.eventIds.cow[cowId] = [];
                    return {
                        toLastSessionId: action.data.sessions.reduce(
                            (sessionId, s) => {
                                if (sessionId > s.id) {
                                    return s.id;
                                } else {
                                    return sessionId;
                                }
                            },
                            Number.MAX_SAFE_INTEGER,
                        ),
                        earliest: Math.min(event.timestamp, acc.earliest),
                        latest: Math.max(event.timestamp, acc.latest),
                        eventIds: {
                            ...acc.eventIds,
                            cow: {
                                ...acc.eventIds.cow,
                                [cowId]: Array.from(
                                    new Set([
                                        ...acc.eventIds.cow[cowId],
                                        event.id,
                                    ]),
                                ),
                            },
                        },
                    };
                },
                {
                    earliest: Math.min(
                        state.earliest,
                        action.params.since || Number.MAX_SAFE_INTEGER,
                    ),
                    latest: Math.max(
                        state.latest,
                        action.params.until || Number.MIN_SAFE_INTEGER,
                    ),
                    eventIds: state.eventIds,
                },
            );
            return { ...state, ...newState };
        case FAIL_FETCH_EVENTS_VIEW_REQUEST:
            return state;
        case COMPLETE_SUBMIT_NOTE_REQUEST:
            return {
                ...state,
                eventIds: {
                    ...state.eventIds,
                    single: {
                        ...state.eventIds.single,
                        [action.event.id]: [action.event.id],
                    },
                },
            };
        default:
            return state;
    }
}

import React, { useState } from "react";
import cn from "classnames";

import TooltipCommon from "../TooltipCommon";
import i18n from "../../i18n";
import Button from "../Button";

import TimePicker from "./TimePicker";
import { VARIABLES } from "./constants";

export function TimeVariable({ value, date, setTime }) {
    function onSelectedLocal(time) {
        setTime(time);
    }
    return (
        <div>
            <TooltipCommon
                position="bottom"
                visible={false}
                autoClose={false}
                content={
                    <>
                        <TimePicker
                            initialHour={date.getHours()}
                            initialMinute={date.getMinutes()}
                            defaultTime={date}
                            onClicked={onSelectedLocal}
                        />
                    </>
                }>
                <div className={cn("variable", { selected: true })}>
                    {value}
                </div>
            </TooltipCommon>
        </div>
    );
}

export function HealthComplication({
    value,
    label,
    onSelected,
    selected,
    selectedOptions,
    isResult,
}) {
    return (
        <div className="variable-container">
            <h4>{label}</h4>
            <div className="health-complication">
                {!isResult ? (
                    <MultiVariable
                        value={value}
                        selected={selected}
                        options={Object.values(
                            VARIABLES.healthComplication.options,
                        )}
                        onSelected={onSelected}
                        selectedOptions={selectedOptions}
                        isResult={isResult}
                    />
                ) : selectedOptions.length === 0 ? (
                    <div key={value} className={cn("variable")}>
                        {i18n.t("smartNote:variables:none")}
                    </div>
                ) : null}
                {selectedOptions.map((value) => {
                    return (
                        <div key={value} className={cn("variable", "selected")}>
                            {i18n.t(`smartNote:variables:${value}`)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export function MultiVariable({
    options,
    onSelected,
    selected,
    value,
    selectedOptions,
}) {
    const [visible, setVisible] = useState(false);
    function onSelectedLocal(o) {
        setVisible(false);
        onSelected(o);
    }
    return (
        <div>
            <TooltipCommon
                position="bottom"
                visible={visible}
                autoClose={false}
                content={
                    <>
                        {options
                            ? options.map((o) => (
                                  <OptionCheckbox
                                      key={o.id}
                                      option={o}
                                      isChecked={selectedOptions.includes(
                                          o.value,
                                      )}
                                      onChecked={onSelectedLocal}
                                  />
                              ))
                            : null}
                    </>
                }>
                <div className={cn("variable", { selected: selected })}>
                    {i18n.t(`smartNote:variables:${value}`)}
                </div>
            </TooltipCommon>
        </div>
    );
}

export const OptionCheckbox = ({ option, onChecked, isChecked }) => {
    return (
        <div
            className={cn({ "option-checkbox": true, checked: isChecked })}
            onClick={() => onChecked(option)}>
            &nbsp;&nbsp;
            <span>{i18n.t(`smartNote:variables:${option.value}`)}</span>
        </div>
    );
};

export function VariableSingle({
    value,
    label,
    selected,
    options,
    onSelected,
}) {
    return (
        <div className="variable-container">
            <h4>{label}</h4>
            <Variable
                value={value}
                selected={selected}
                options={options}
                onSelected={onSelected}
            />
        </div>
    );
}

export function Variable({ value, selected, options, onSelected }) {
    const [visible, setVisible] = useState(false);
    function onSelectedLocal(o) {
        setVisible(false);
        onSelected(o);
    }
    return (
        <div>
            <TooltipCommon
                position="bottom"
                visible={visible}
                content={
                    <>
                        {options
                            ? options.map((o) => (
                                  <Button
                                      key={o.id}
                                      text={i18n.t(
                                          `smartNote:variables:${o.value}`,
                                      )}
                                      onClicked={() => onSelectedLocal(o)}
                                  />
                              ))
                            : null}
                    </>
                }>
                <div
                    className={cn("variable", {
                        selected: selected,
                        placeholder: !selected,
                    })}>
                    {i18n.t(`smartNote:variables:${value}`)}
                </div>
            </TooltipCommon>
        </div>
    );
}

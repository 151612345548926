import { connect } from "react-redux";

function mapStateToProps({ temperature }, { id }) {
    if (!temperature[id]) {
        return { temperature: Array(2880).fill(null) };
    }

    const deltaTime = Date.now() - temperature[id].timestamp;
    const nullDatapointCount = Math.floor(deltaTime / temperature[id].period);
    const temperatureDatapoints = new Array(nullDatapointCount)
        .fill(null)
        .concat(temperature[id].temperature)
        .slice(0, 2880);
    return {
        temperature: temperatureDatapoints,
        lifecycle: temperature[id].lifecycle,
        lastTimestamp: temperature[id].timestamp,
    };
}

export default connect(mapStateToProps);

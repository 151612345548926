import { EVENTS, COW, SENSOR, SESSION } from "../constants/schema";

export function newCowAcquiredEvent(db, cowProps, userId) {
    const user = db.users.find(userId);

    const cowTags = [COW.UNTRACKED];
    const cow = db.cows.insert({ ...cowProps, tags: cowTags });
    db.events.insert({
        type: EVENTS.NEW_COW_ACQUIRED,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            user: user.id,
            tags: { cow: cowTags },
        },
    });
}

export function pairingListCreatedEvent(db, list, userId) {
    const events = [];
    const user = db.users.find(userId);
    const pairingList = db.pairingLists.insert({});

    const sessions = db.sessions.insert(
        list.map((l) => {
            return { ...l, pairingList: pairingList.id, tags: [] };
        }),
    );

    db.pairingLists.update(pairingList.id, {
        sessions: sessions.map((s) => s.id),
    });

    events.push(
        db.events.insert({
            type: EVENTS.PAIRING_LIST_CREATED,
            timestamp: new Date().getTime(),
            payload: {
                list,
                user: user.id,
                pairingList: pairingList.id,
            },
        }),
    );

    sessions.map((s) => {
        events.push(
            ...sessionCreatedEvent(
                db,
                db.cows.find(s.cow),
                db.sensors.find(s.sensor),
                user,
                s,
                pairingList.id,
            ),
        );
    });
    return events;
}

export function sessionCreatedEvent(
    db,
    cow,
    sensor,
    user,
    session,
    pairingList,
) {
    const events = [];
    if (!sensor.tags.includes(SENSOR.IDLE)) {
        throw new Error("sensor: IDLE required");
    }
    const event = db.events.insert({
        type: EVENTS.SESSION_CREATED,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            sensor: sensor.id,
            user: user.id,
            session: session.id,
            pairingList,
            tags: {
                session: [SESSION.PENDING],
                sensor: [SENSOR.ASSIGNED],
            },
        },
    });
    events.push(event);
    updateTags(db, event);

    if (cow.tags.includes(COW.UNTRACKED)) {
        // means it is cow's first session
        events.push(...firstSessionCreatedEvent(db, cow.id));
    }

    if (!cow.tags.includes(COW.PREGNANT) && sensor.type === "Tsens") {
        // means cow is pregnant
        events.push(...pregnancyDetectedEvent(db, cow.id, session.id));
    }
    return events;
}

export function firstSessionCreatedEvent(db, cowId) {
    const events = [];
    const cow = db.cows.find(cowId);
    if (!cow.tags.includes(COW.UNTRACKED)) {
        throw new Error("cow: UNTRACKED required");
    }
    const event = db.events.insert({
        type: EVENTS.FIRST_SESSION_CREATED,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: {
                cow: [...cow.tags, COW.PENDING].filter(
                    (t) => t != COW.UNTRACKED,
                ),
            },
        },
    });
    events.push(event);
    updateTags(db, event);

    return events;
}

export function pregnancyDetectedEvent(db, cowId, sessionId) {
    const events = [];
    const cow = db.cows.find(cowId);
    const session = db.sessions.find(sessionId);
    if (cow.tags.includes(COW.CALVING)) {
        throw new Error("cow: CALVING is not accepted");
    }
    const event = db.events.insert({
        type: EVENTS.PREGNANCY_DETECTED,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            session: session.id,
            tags: {
                cow: [...cow.tags, COW.PREGNANT],
                session: [...session.tags, SESSION.PREGNANCY],
            },
        },
    });
    events.push(event);
    updateTags(db, event);

    return events;
}

export function sensorActivatedEvent(db, sensorId) {
    const sensor = db.sensors.find(sensorId);
    if (!sensor.tags.includes(SENSOR.ASSIGNED)) {
        throw new Error("sensor: ASSIGNED required");
    }
    const event = db.events.insert({
        type: EVENTS.SENSOR_ACTIVATED,
        timestamp: new Date().getTime(),
        payload: {
            sensor: sensor.id,
            tags: { sensor: [SENSOR.ACTIVE, SENSOR.PENDING] },
        },
    });
    updateTags(db, event);
}

export function stableDataDetectedEvent(db, sessionId, sensorId) {
    const sensor = db.sensors.find(sensorId);
    const session = db.sessions.find(sessionId);
    const cow = db.cows.find(session.cow);
    if (
        !(
            sensor.tags.includes(SENSOR.PENDING) ||
            sensor.tags.includes(SENSOR.UNSTABLE)
        )
    ) {
        throw new Error("sensor: PENDING or UNSTABLE required");
    }
    if (
        !(
            session.tags.includes(SESSION.PENDING) ||
            sensor.tags.includes(SESSION.ACTIVE)
        )
    ) {
        throw new Error("session: PENDING or ACTIVE required");
    }
    const event = db.events.insert({
        type: EVENTS.STABLE_DATA_DETECTED,
        timestamp: new Date().getTime(),
        payload: {
            sensor: sensor.id,
            session: session.id,
            tags: {
                sensor: [SENSOR.ACTIVE, SENSOR.STABLE],
                session: [
                    SESSION.ACTIVE,
                    SESSION.HEALTH,
                    cow.tags.includes(COW.PREGNANT) ? SESSION.PREGNANCY : null,
                ],
            },
        },
    });
    updateTags(db, event);
    if (cow.tags.includes(COW.PENDING)) {
        trackingStartedEvent(db, cow);
    }
}

export function unstableDataDetectedEvent(db, sensorId) {
    const sensor = db.sensors.find(sensorId);
    if (!sensor.tags.includes(SENSOR.STABLE)) {
        throw new Error("sensor: STABLE required");
    }
    const event = db.events.insert({
        type: EVENTS.UNSTABLE_DATA_DETECTED,
        timestamp: new Date().getTime(),
        payload: {
            sensor: sensor.id,
            tags: {
                sensor: [...sensor.tags, SENSOR.UNSTABLE].filter(
                    (t) => t != SENSOR.STABLE,
                ),
            },
        },
    });
    updateTags(db, event);
}

export function trackingStartedEvent(db, cow) {
    if (!cow.tags.includes(COW.PENDING)) {
        throw new Error("cow: PENDING required");
    }
    const event = db.events.insert({
        type: EVENTS.TRACKING_STARTED,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: {
                cow: [...cow.tags, COW.TRACKED].filter(
                    (t) => t != COW.PENDING && t != COW.UNTRACKED,
                ),
            },
        },
    });
    updateTags(db, event);
}

export function feverDetectedEvent(db, cowId, temperature) {
    const cow = db.cows.find(cowId);
    if (cow.tags.includes(COW.HYPOTHERMIA)) {
        throw new Error("cow: FEVER is not accepted since HYPOTHERMIA exist");
    }
    const event = db.events.insert({
        type: EVENTS.FEVER_DETECTED,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            temperature,
            tags: { cow: [...cow.tags, COW.FEVER] },
        },
    });
    updateTags(db, event);
}

export function feverOverEvent(db, cowId, temperature) {
    const cow = db.cows.find(cowId);
    if (!cow.tags.includes(COW.FEVER)) {
        throw new Error(
            `cow: ${EVENTS.FEVER_OVER} is not accepted since FEVER is not exist`,
        );
    }
    const event = db.events.insert({
        type: EVENTS.FEVER_OVER,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            temperature,
            tags: { cow: cow.tags.filter((t) => t != COW.FEVER) },
        },
    });
    updateTags(db, event);
}

export function calvingDetectedEvent(db, cowId) {
    const cow = db.cows.find(cowId);
    if (!(cow.tags.includes(COW.PREGNANT) && cow.tags.includes(COW.TRACKED))) {
        throw new Error("cow: PREGNANT and TRACKED required");
    }
    if (cow.tags.includes(COW.CALVING)) {
        throw new Error("cow: CALVING_DETECTED not accepted, already CALVING");
    }
    const event = db.events.insert({
        type: EVENTS.CALVING_DETECTED,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: {
                cow: [...cow.tags, COW.CALVING].filter((t) => t != COW.DUE),
            },
        },
    });
    updateTags(db, event);
}

export function calvingDueEvent(db, cowId, estimate) {
    const cow = db.cows.find(cowId);
    if (!(cow.tags.includes(COW.PREGNANT) && cow.tags.includes(COW.TRACKED))) {
        throw new Error("cow: PREGNANT and TRACKED required");
    }
    const event = db.events.insert({
        type: EVENTS.CALVING_DUE,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            estimate,
            tags: { cow: [...cow.tags, COW.DUE] },
        },
    });
    updateTags(db, event);
}

export function calvingDueFalseEvent(db, cowId) {
    const cow = db.cows.find(cowId);
    if (!cow.tags.includes(COW.DUE)) throw new Error("cow: DUE required");
    const event = db.events.insert({
        type: EVENTS.CALVING_DUE_FALSE,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: { cow: [...cow.tags].filter((t) => t != COW.DUE) },
        },
    });
    updateTags(db, event);
}

export function sessionCompletedEvent(db, sessionId, sensorId, userId) {
    const events = [];
    const sensor = db.sensors.find(sensorId);
    const session = db.sessions.find(sessionId);
    if (sensor.tags.includes(SENSOR.IDLE)) {
        throw new Error("sensor: IDLE is not accepted");
    }
    if (!session.tags.includes(SENSOR.ACTIVE)) {
        throw new Error("session: ACTIVE required");
    }
    const event = db.events.insert({
        type: EVENTS.SESSION_COMPLETED,
        timestamp: new Date().getTime(),
        payload: {
            user: userId,
            cow: session.cow,
            session: session.id,
            sensor: sensor.id,
            tags: { session: [SESSION.ARCHIVED], sensor: [SENSOR.IDLE] },
        },
    });
    events.push(event);
    updateTags(db, event);
    const cowSessions = db.sessions.filter(
        (s) => !s.tags.includes(SESSION.ARCHIVED) && s.cow == session.cow,
    );
    if (cowSessions.length == 0) {
        events.push(lastSessionCompletedEvent(db, session.cow));
    }
    return events;
}

export function lastSessionCompletedEvent(db, cowId) {
    const cow = db.cows.find(cowId);
    const event = db.events.insert({
        type: EVENTS.LAST_SESSION_COMPLETED,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: {
                cow: [
                    cow.tags.includes(COW.PREGNANT) ? COW.PREGNANT : null,
                    COW.UNTRACKED,
                ],
            },
        },
    });
    updateTags(db, event);
    return event;
}

export function calvingCompletedEvent(db, cowId, userId) {
    const events = [];
    const cow = db.cows.find(cowId);
    const user = db.users.find(userId);
    if (!(cow.tags.includes(COW.PREGNANT) && cow.tags.includes(COW.CALVING))) {
        throw new Error("cow: PREGNANT and CALVING required");
    }
    const event = db.events.insert({
        type: EVENTS.CALVING_COMPLETED,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            user: user.id,
            tags: {
                cow: [...cow.tags].filter(
                    (t) => t != COW.PREGNANT || t != COW.CALVING,
                ),
            },
        },
    });
    updateTags(db, event);
    return events;
}

export function smartNoteSubmittedEvent(db, payload, userId) {
    const events = [];
    //get Tsens session
    const TsensSession = db.sessions.filter(
        (s) =>
            s.cow == payload.parentEventTagItems.cow &&
            db.sensors.find(s.sensor).type == "Tsens",
    )[0];

    //calving-completed
    events.push(...calvingCompletedEvent(db, TsensSession.cow, userId));

    //session-completed
    events.push(
        ...sessionCompletedEvent(
            db,
            TsensSession.id,
            TsensSession.sensor,
            userId,
        ),
    );

    //generate smart note event
    const event = db.events.insert({
        type: EVENTS.SMART_NOTE_SUBMITTED,
        timestamp: new Date().getTime(),
        payload: {
            ...payload,
            user: userId,
            parentEventPayload: payload.parentEventTagItems,
        },
    });
    events.push(event);
    return events;
}

export function newSensorAcquiredEvent(db, sensorProps, userId) {
    const user = db.users.find(userId);

    const sensorTags = [SENSOR.IDLE];
    const sensor = db.sensors.insert({ ...sensorProps, tags: sensorTags });

    db.events.insert({
        type: EVENTS.NEW_SENSOR_ACQUIRED,
        timestamp: new Date().getTime(),
        payload: {
            sensor: sensor.id,
            user: user.id,
            tags: { sensor: [SENSOR.IDLE] },
        },
    });
}

function updateTags(db, event) {
    Object.keys(event.payload.tags).map((key) => {
        db[`${key}s`].update(event.payload[key], {
            tags: event.payload.tags[key],
        });
    });
}

export function getEventSupporters(db, events) {
    const cows = [],
        sensors = [],
        sessions = [],
        users = [],
        basestations = [];
    events.map((e) => {
        if (e.payload.cow && !cows.includes(e.payload.cow))
            cows.push(e.payload.cow);
        if (e.payload.sensor && !sensors.includes(e.payload.cow)) {
            sensors.push(e.payload.sensor);
        }
        if (e.payload.session && !sessions.includes(e.payload.session)) {
            sessions.push(e.payload.session);
        }
        if (e.payload.user && !users.includes(e.payload.user)) {
            users.push(e.payload.user);
        }
        if (
            e.payload.basestation &&
            !basestations.includes(e.payload.basestation)
        ) {
            basestations.push(e.payload.basestation);
        }
    });
    return {
        events,
        cows: cows.map((c) => db.cows.find(c)),
        sensors: sensors.map((s) => db.sensors.find(s)),
        sessions: sessions.map((s) => db.sessions.find(s)),
        users: users.map((u) => db.users.find(u)),
        basestations: basestations.map((b) => db.basestations.find(b)),
    };
}

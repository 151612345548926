import React from "react";
import { ShepherdTour } from "react-shepherd";
import { useDispatch } from "react-redux";

import { steps, tourOptions } from "./tour";
import "./tour.css";

export default function WicowTour({ children }) {
    const dispatch = useDispatch();
    return (
        <ShepherdTour steps={steps} tourOptions={{ ...tourOptions, dispatch }}>
            {children}
        </ShepherdTour>
    );
}

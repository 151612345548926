import React, { useRef, useContext } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { setAutoSorting, setShortcutButton } from "../../store/actions";
import "./DashboardSettings.css";
import Button from "../Button";
import { RouterContext } from "../../containers/Contexts";

export function DashboardSettings({
    allFarms,
    currentFarm,
    setShortcutButton,
    setAutoSorting,
    shortcutButton,
    autoSorting,
}) {
    const { t } = useTranslation();
    const navigate = useContext(RouterContext);
    const dashboardSettingsRef = useRef();

    const sortingButtons = {
        tracking: "tracking",
        priority: "priority",
        farmNumber: "farm-number",
        events: "events",
    };
    return (
        <main className={cn("dashboard-settings")} ref={dashboardSettingsRef}>
            <h1 className="header">{t("dashboardSettings")}</h1>
            <span className="close-button" onClick={navigate.back} />
            <div className="shortcut-buttons">
                <h2 className="shortcut-header">{t("shortcutButton")}</h2>
                <div className="buttons">
                    <Button
                        onClicked={() => setShortcutButton("cowList")}
                        className={cn("cow-list", {
                            selected: shortcutButton === "cowList",
                        })}
                        text={t("cowList")}
                        type="action"
                        behaviour="instant"
                    />
                    <Button
                        onClicked={() => setShortcutButton("switchFarm")}
                        className={cn(
                            "switch-farm",
                            {
                                disabled:
                                    allFarms.filter(
                                        (farm) => farm.id !== currentFarm.id,
                                    ).length === 0,
                            },
                            { selected: shortcutButton === "switchFarm" },
                        )}
                        text={t("switchFarm")}
                        type="action"
                        behaviour="instant"
                        disabled={
                            allFarms.filter(
                                (farm) => farm.id !== currentFarm.id,
                            ).length === 0
                        }
                    />
                    <Button
                        onClicked={() => setShortcutButton("notifications")}
                        className={cn("notifications", {
                            selected: shortcutButton === "notifications",
                        })}
                        text={t("notifications")}
                        type="action"
                        behaviour="instant"
                    />
                </div>
                <span className="shortcut-button-explanation">
                    {t("shortcutButtonExplanation")}
                </span>
            </div>
            <div className="auto-sort-buttons">
                <h2 className="auto-sort-header">{t("autoSorting")}</h2>
                <div className="buttons">
                    {Object.keys(sortingButtons).map((key) => (
                        <Button
                            key={key}
                            onClicked={() => setAutoSorting(key)}
                            className={cn(sortingButtons[key], {
                                selected: key === autoSorting,
                            })}
                            text={t(key)}
                            type="action"
                            behaviour="instant"
                        />
                    ))}
                </div>
                <label className="auto-sorting-explanation">
                    {t("autoSortingExplanation")}
                </label>
                <label className="style-explanation">
                    {autoSorting === "tracking"
                        ? t("trackingExplanation")
                        : autoSorting === "priority"
                        ? t("priorityExplanation")
                        : autoSorting === "farmNumber"
                        ? t("farmNumberExplanation")
                        : autoSorting === "events"
                        ? t("eventsExplanation")
                        : null}
                </label>
            </div>
        </main>
    );
}
function mapStateToProps({ dashboardSettings, farms }) {
    return {
        ui: dashboardSettings.ui,
        currentFarm: farms.current,
        allFarms: farms.all,
        shortcutButton: dashboardSettings.shortcutButton,
        autoSorting: dashboardSettings.autoSortingBy,
    };
}

const mapDispatchToProps = {
    setShortcutButton,
    setAutoSorting,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSettings);

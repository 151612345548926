import "intersection-observer";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import { TourMethods } from "react-shepherd";

import App from "./containers/App";
import WicowTour from "./containers/WicowTour";
import { unregister } from "./registerServiceWorker";
import "./i18n";
import { makeServer } from "./server";
import configureStore from "./store/configureStore";

export const store = configureStore();

const urlParams = new URLSearchParams(window.location.search);
const tour = urlParams.get("tour");
const demo = urlParams.get("demo");
const app = (
    <Provider store={store}>
        {tour === "demoFarm" ? (
            <WicowTour>
                <TourMethods>
                    {(tourContext) => <App tour ShepherdTour={tourContext} />}
                </TourMethods>
            </WicowTour>
        ) : (
            <App />
        )}
    </Provider>
);

export const mirageServer = tour ? makeServer() : demo ? makeServer({ environment: "demo" }): null;

ReactDOM.render(app, document.getElementById("root"));
unregister();

import { EVENTS } from "../../constants";

const realData = [
    [38.265, EVENTS.SENSOR_ACTIVATED],
    [38.265, EVENTS.STABLE_DATA_DETECTED],
    [38.265],
    [38.2625],
    [38.26],
    [38.256],
    [38.2175],
    [38.226666666667],
    [38.225],
    [38.21],
    [38.1725],
    [38.135],
    [38.102],
    [38.105],
    [38.115],
    [38.1175],
    [38.145],
    [38.15],
    [38.2025],
    [38.25],
    [38.2675],
    [38.28],
    [38.2825],
    [38.34],
    [38.4225],
    [38.475],
    [38.465],
    [38.458],
    [38.456666666667],
    [38.45],
    [38.465],
    [38.483333333333],
    [38.4975],
    [38.505],
    [38.5025],
    [38.5125],
    [38.525],
    [38.5575],
    [38.5725],
    [38.55],
    [38.563333333333],
    [38.5625],
    [38.548],
    [38.59],
    [38.59],
    [38.585],
    [38.61],
    [38.65],
    [38.68],
    [38.71],
    [38.7],
    [38.6675],
    [38.6475],
    [38.62],
    [38.595],
    [38.565],
    [38.525],
    [38.484],
    [38.4825],
    [38.495],
    [38.486666666667],
    [38.5025],
    [38.4875],
    [38.465],
    [38.4475],
    [38.445],
    [38.4575],
    [38.48],
    [38.5325],
    [38.565],
    [38.58],
    [38.5975],
    [38.584],
    [38.525],
    [38.4575],
    [38.395],
    [38.3575],
    [38.343333333333],
    [38.35],
    [38.3575],
    [38.3725],
    [38.355],
    [38.3825],
    [38.3575],
    [38.36],
    [38.325],
    [38.325],
    [38.318],
    [38.3525],
    [38.3475],
    [38.366666666667],
    [38.3475],
    [38.35],
    [38.345],
    [38.333333333333],
    [38.3325],
    [38.355],
    [38.42],
    [38.48],
    [38.465],
    [38.4175],
    [38.44],
    [38.478],
    [38.515],
    [38.5575],
    [38.5775],
    [38.6175],
    [38.6525],
    [38.63],
    [38.6175],
    [38.633333333333],
    [38.7],
    [38.7175],
    [38.7275],
    [38.7875],
    [38.7775],
    [38.8],
    [38.852],
    [38.848],
    [38.885],
    [38.85],
    [38.8275],
    [38.82],
    [38.79],
    [38.745],
    [38.6975],
    [38.64],
    [38.5825],
    [38.5525],
    [38.566],
    [38.54],
    [38.5425],
    [38.5625],
    [38.592],
    [38.61],
    [38.626666666667, EVENTS.FEVER_DETECTED],
    [38.6475],
    [38.696666666667],
    [38.7525],
    [38.82],
    [38.8525],
    [38.86],
    [38.8375],
    [38.805],
    [38.8025],
    [38.83],
    [38.8775],
    [38.92],
    [38.965],
    [38.97],
    [39.006666666667],
    [39.005],
    [38.9975],
    [39.0025],
    [38.9875],
    [38.9425],
    [38.91],
    [38.885],
    [38.8875],
    [38.92],
    [38.965],
    [39.02],
    [39.01],
    [39.045],
    [39.066666666667],
    [39.0875],
    [39.093333333333],
    [39.1],
    [39.09],
    [39.0575],
    [39.0475],
    [39.033333333333],
    [39.0625],
    [39.085],
    [39.09],
    [39.09],
    [39.1075],
    [39.13],
    [39.1],
    [39.1325],
    [39.15],
    [39.13],
    [39.13],
    [39.1025],
    [39.08],
    [39.05],
    [39.01],
    [38.95],
    [38.9175],
    [38.875],
    [38.8325],
    [38.7775],
    [38.746],
    [38.7325],
    [38.7025],
    [38.716666666667],
    [38.765],
    [38.82],
    [38.8425],
    [38.8675],
    [38.905],
    [38.9375],
    [38.97],
    [39.0325],
    [39.075],
    [39.1025],
    [39.0925],
    [39.086],
    [39.085],
    [39.1],
    [39.11],
    [39.13],
    [39.1225],
    [39.14],
    [39.1575],
    [39.1675],
    [39.203333333333],
    [39.195],
    [39.2075],
    [39.205],
    [39.225],
    [39.225],
    [39.25],
    [39.2525],
    [39.27],
    [39.2525],
    [39.255],
    [39.255],
    [39.24],
    [39.2125],
    [39.185],
    [39.155],
    [39.1025],
    [39.0775],
    [39.045],
    [39.013333333333],
    [38.975],
    [38.926],
    [38.89],
    [38.8625],
    [38.835],
    [38.795],
    [38.78],
    [38.7475],
    [38.725],
    [38.695],
    [38.68],
    [38.695],
    [38.74],
    [38.785],
    [38.7825],
    [38.78],
    [38.782],
    [38.78],
    [38.7775],
    [38.7975],
    [38.7975],
    [38.79],
    [38.8175],
    [38.822],
    [38.8325],
    [38.83],
    [38.8425],
    [38.85],
    [38.845],
    [38.845],
    [38.873333333333],
    [38.91],
    [38.885],
    [38.855],
    [38.822],
    [38.778],
    [38.76],
    [38.796666666667],
    [38.846666666667],
    [38.922],
    [38.9275],
    [38.8825],
    [38.865],
    [38.8425],
    [38.7825],
    [38.725],
    [38.672],
    [38.6375],
    [38.58],
    [38.5175],
    [38.43],
    [38.3575],
    [38.3275],
    [38.34],
    [38.36],
    [38.365],
    [38.4],
    [38.405],
    [38.4825],
    [38.5825],
    [38.57],
    [38.556],
    [38.5725],
    [38.5925],
    [38.6325],
    [38.5925],
    [38.565],
    [38.5825],
    [38.5725],
    [38.5675],
    [38.6025],
    [38.595],
    [38.5675],
    [38.585],
    [38.595],
    [38.5475],
    [38.534],
    [38.5425],
    [38.54],
    [38.5075],
    [38.485],
    [38.4675],
    [38.47],
    [38.475],
    [38.5],
    [38.51],
    [38.4975],
    [38.47],
    [38.465],
    [38.465],
    [38.5],
    [38.504],
    [38.475],
    [38.51],
    [38.52],
    [38.49],
    [38.4775],
    [38.4925],
    [38.5],
    [38.525],
    [38.5275],
    [38.4925],
    [38.46],
    [38.4225],
    [38.39],
    [38.39],
    [38.422],
    [38.4575],
    [38.4525],
    [38.4225],
    [38.416666666667],
    [38.425],
    [38.375],
    [38.286666666667],
    [38.215],
    [38.23],
    [38.265],
    [38.26],
    [38.235],
    [38.272],
    [38.26],
    [38.216],
    [38.203333333333],
    [38.2125],
    [38.2525],
    [38.2525],
    [38.23],
    [38.23],
    [38.223333333333],
    [38.21],
    [38.235],
    [38.255],
    [38.265],
    [38.2575],
    [38.256666666667],
    [38.245],
    [38.26],
    [38.24],
    [38.23],
    [38.245],
    [38.305],
    [38.355],
    [38.3875],
    [38.3625],
    [38.3625],
    [38.3425],
    [38.3325],
    [38.365],
    [38.413333333333],
    [38.3675],
    [38.3275],
    [38.298],
    [38.313333333333],
    [38.2925],
    [38.26],
    [38.25],
    [38.2275],
    [38.2575],
    [38.3175],
    [38.4],
    [38.385],
    [38.39],
    [38.4025],
    [38.37],
    [38.3525],
    [38.34],
    [38.32],
    [38.3175],
    [38.326666666667],
    [38.3275],
    [38.32],
    [38.295],
    [38.3],
    [38.3125],
    [38.31],
    [38.3275],
    [38.34],
    [38.375],
    [38.372],
    [38.3675],
    [38.375],
    [38.378],
    [38.37],
    [38.346666666667],
    [38.315],
    [38.315],
    [38.335],
    [38.3475],
    [38.3975],
    [38.4525],
    [38.435],
    [38.44],
    [38.5075],
    [38.58],
    [38.52],
    [38.4825],
    [38.508],
    [38.51],
    [38.505],
    [38.49],
    [38.47],
    [38.4625],
    [38.4325],
    [38.46],
    [38.48],
    [38.5075],
    [38.525],
    [38.55],
    [38.5225],
    [38.5325],
    [38.525],
    [38.528],
    [38.5475],
    [38.59],
    [38.6175],
    [38.61],
    [38.5725],
    [38.6],
    [38.6425],
    [38.6925],
    [38.67],
    [38.675],
    [38.655],
    [38.6525],
    [38.62],
    [38.616666666667],
    [38.592],
    [38.5975],
    [38.605],
    [38.6375],
    [38.6425],
    [38.6925],
    [38.696666666667],
    [38.735],
    [38.76],
    [38.8025],
    [38.7975],
    [38.79],
    [38.785],
    [38.7675],
    [38.7775],
    [38.804],
    [38.846666666667],
    [38.8725],
    [38.9],
    [38.915],
    [38.89],
    [38.8825],
    [38.85],
    [38.8325],
    [38.7925],
    [38.7825],
    [38.7825],
    [38.785],
    [38.805],
    [38.8],
    [38.782],
    [38.76],
    [38.75],
    [38.7475],
    [38.7325],
    [38.7475],
    [38.7625],
    [38.725],
    [38.71],
    [38.702],
    [38.6875],
    [38.625],
    [38.6],
    [38.6],
    [38.59],
    [38.592],
    [38.623333333333],
    [38.665],
    [38.725],
    [38.7225],
    [38.75],
    [38.74],
    [38.75],
    [38.7375],
    [38.6975],
    [38.6725],
    [38.6625],
    [38.6775],
    [38.705],
    [38.695],
    [38.73],
    [38.78],
    [38.7825],
    [38.8025],
    [38.8075],
    [38.775],
    [38.8125],
    [38.8275],
    [38.8275],
    [38.81],
    [38.78],
    [38.765],
    [38.74],
    [38.686666666667],
    [38.6],
    [38.512],
    [38.4425],
    [38.3925],
    [38.3675],
    [38.35],
    [38.3725],
    [38.386666666667],
    [38.3825],
    [38.3675],
    [38.3575],
    [38.3575],
    [38.3525],
    [38.3875],
    [38.4075],
    [38.455],
    [38.48],
    [38.55],
    [38.5975],
    [38.6625],
    [38.74],
    [38.78],
    [38.7625],
    [38.75],
    [38.755],
    [38.7775],
    [38.825],
    [38.8225],
    [38.86],
    [38.8775],
    [38.838],
    [38.886],
    [38.8975],
    [38.9075],
    [38.9125],
    [38.94],
    [38.975],
    [39.01],
    [39.054285714286],
    [39.02],
    [38.97],
    [38.95],
    [38.908],
    [38.8875],
    [38.891666666667],
    [38.8925],
    [38.896],
    [38.882],
    [38.865],
    [38.8625],
    [38.8375],
    [38.835],
    [38.825],
    [38.795],
    [38.7425],
    [38.6975],
    [38.665],
    [38.6725],
    [38.67],
    [38.6475],
    [38.5825],
    [38.566],
    [38.5625],
    [38.5275],
    [38.505],
    [38.5],
    [38.485],
    [38.4725],
    [38.483333333333],
    [38.5475],
    [38.585],
    [38.565],
    [38.5275],
    [38.4825],
    [38.45],
    [38.4475],
    [38.488],
    [38.495],
    [38.55],
    [38.535],
    [38.583333333333],
    [38.625],
    [38.6575],
    [38.735],
    [38.76],
    [38.705],
    [38.6625],
    [38.6425],
    [38.615],
    [38.55],
    [38.525],
    [38.52],
    [38.5125],
    [38.5275],
    [38.53],
    [38.553333333333],
    [38.575],
    [38.593333333333],
    [38.5525],
    [38.58],
    [38.585],
    [38.5825],
    [38.5975],
    [38.585],
    [38.606666666667],
    [38.6225],
    [38.62],
    [38.645],
    [38.615],
    [38.595],
    [38.5775],
    [38.5725],
];

export const cowData = {
    temperature: {
        max: 39.27,
        min: 38.102,
        data: mockDataGenerator(realData),
    },
};

export function mockDataGenerator(realData) {
    const t = new Date().getTime();
    let data = [];
    for (let i = 0; i < 720; i++) {
        data.push([t + 240000 * (i + 1), realData[i] ? realData[i][0] : null]);
    }
    data = data.reverse();
    window.localStorage.setItem("mockData", JSON.stringify(data));
    return data;
}

import { connect } from "react-redux";
import { hideDemoModal, registerDemoAccount } from "../store/actions";

function mapStateToProps({ demoModal: { modal, lifecycle } }) {
    return { state: modal, lifecycle };
}

const mapDispatchToProps = { hideDemoModal, registerDemoAccount };

export default connect(mapStateToProps, mapDispatchToProps);

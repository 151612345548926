import { connect } from "react-redux";

export function mapStateToProps({ sessions, tags }, { id: pairingListID }) {
    return {
        list: Object.values(sessions).reduce(
            (list, { id: sessionID, pairingList, cow, sensor }) => {
                return pairingList === pairingListID
                    ? [
                          ...list,
                          {
                              sessionID,
                              cow,
                              sensor,
                              tags: tags.session[sessionID]
                                  ? tags.session[sessionID]
                                  : [],
                          },
                      ]
                    : list;
            },
            [],
        ),
    };
}

export default connect(mapStateToProps);

import {
    UPDATE_RESOURCE,
    START_USER_CALL_CHANGE,
    COMPLETE_USER_CALL_CHANGE,
    FAIL_USER_CALL_CHANGE,
    RESET_USER_CALL,

} from "../../actions/actionTypes";
export default function (state = {}, action) {
    switch (action.type) {
    case UPDATE_RESOURCE:
        if (action.resource == "users") {
            return action.data.reduce( (acc, user) => { return { ...acc, [user.id]: { userId: user.id, ui: "initial", active: true } };
            }, {});
        } else {
            return state;
        }
    case START_USER_CALL_CHANGE:
        return { ...state, [action.userId]: { ...state[action.userId], ui: "pending" } };
    case COMPLETE_USER_CALL_CHANGE:
        return { ...state, [action.userId]: { ...state[action.userId], ui: "done", active: action.activate } };
    case FAIL_USER_CALL_CHANGE:
        return { ...state, [action.userId]: { ...state[action.userId], ui: "failed" } };
    case RESET_USER_CALL:
        return { ...state, [action.userId]: { ...state[action.userId], ui: "initial" } };
    default: return state;
    }
}

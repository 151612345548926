import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";

import Time from "../Time";
import "./MetricRange.css";
import i18n from "../../i18n";

const shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export default function MetricRange({
    trackStarted = new Date().getTime(),
    onChanged,
    loading,
    useForHealthReport = false,
    lastMonthCount = 3,
}) {
    const now = new Date();
    const metricRef = useRef(null);
    const [selected, setSelected] = useState(
        useForHealthReport
            ? now.getUTCMonth().toString()
            : now.getUTCFullYear().toString(),
    );
    const months = lastMonths(now, lastMonthCount);
    const steps = [];
    const trackStartedYear = new Date(trackStarted).getUTCFullYear();
    const currentYear = new Date().getUTCFullYear();
    const startingPoint =
        currentYear - trackStartedYear >= 2
            ? currentYear - 1
            : trackStartedYear;
    if (trackStartedYear !== currentYear) {
        steps.push({
            start: new Date(trackStarted).getTime(),
            end: new Date().getTime(),
            text: i18n.t("all"),
        });
    }
    for (let i = startingPoint; i < currentYear + 1; i++) {
        steps.push({
            start: new Date(`Jan 1, ${i} 00:00:01`).getTime(),
            end: new Date(`Jan 1, ${i + 1} 00:00:01`).getTime(),
            text: i.toString(),
        });
    }
    useEffect(() => {
        metricRef.current.querySelector(".selected").click();
    }, []);
    function onClicked({ target }) {
        setSelected(target.getAttribute("id"));
        const start = parseInt(target.getAttribute("start"));
        const end = parseInt(target.getAttribute("end"));
        onChanged(start, end);
    }
    console.log(selected, steps, months);
    return (
        <ul className={cn("dates", { loading: loading })} ref={metricRef}>
            {steps.map((y) => (
                <li
                    id={y.text}
                    className={cn({ selected: selected === y.text })}
                    onClick={onClicked}
                    key={y.text}
                    start={y.start}
                    end={y.end}>
                    {y.text}
                </li>
            ))}
            {months.map((m) => (
                <li
                    id={m.text}
                    className={cn({ selected: selected === m.text })}
                    key={m.text}
                    onClick={onClicked}
                    start={m.start}
                    end={m.end}>
                    {Time({
                        time: new Date(m.start),
                        options: { month: "short" },
                        useFunc: true,
                    })}
                </li>
            ))}
        </ul>
    );
}

function lastMonths(date, lastMonthCount) {
    const lastMonthsCount = lastMonthCount;
    const result = [];
    for (let i = 0; i < lastMonthsCount; i++) {
        let mStart, yStart, mEnd, yEnd;
        if (date.getUTCMonth() - i < 0) {
            mStart = date.getUTCMonth() - i + 12;
            yStart = date.getUTCFullYear() - 1;
        } else {
            mStart = date.getUTCMonth() - i;
            yStart = date.getUTCFullYear();
        }

        if (mStart + 1 > 11) {
            // months start from index 0
            mEnd = mStart + 1 - 12;
            yEnd = yStart + 1;
        } else {
            mEnd = mStart + 1;
            yEnd = yStart;
        }
        result.push({
            start: new Date(
                `${shortMonths[mStart]} 1, ${yStart} 00:00:01`,
            ).getTime(),
            end: new Date(`${shortMonths[mEnd]} 1, ${yEnd} 00:00:01`).getTime(),
            text: new Date(`${shortMonths[mEnd]} 1, ${yEnd} 00:00:01`)
                .getUTCMonth()
                .toString(),
        });
    }
    return result.reverse();
}

import { EVENTS } from "../../../constants";
import { cow1, cow2, cow3 } from "./mock_data";

export const cowData = (id, tags, init) => {
    const [data, events] = mockDataGenerator(id, tags, init);
    return {
        temperature: { data },
        events,
    };
};

export function mockDataGenerator(id, tags, init) {
    let t = parseInt(new Date().getTime() / 1000, 10) * 1000;

    if (init && parseInt(id) > 0 && parseInt(id) < 4) {
        t = t - 600000;
    }

    let realData = [];

    if (tags.includes("pregnant")) {
        realData = cow1;
    } else if (tags.includes("estrous")) {
        realData = cow2;
    } else if (tags.includes("temperature") || tags.includes("post-partum")) {
        realData = cow3;
    } else {
        realData = [];
    }

    const data = [];
    const events = [];
    for (let i = 0; i < realData.length; i++) {
        if (realData[i] && realData[i][1]) {
            events.push([t + 60000 * (i + 1), realData[i][1], realData[i][0]]);
        }
        data.push([t + 60000 * (i + 1), realData[i] ? realData[i][0] : null]);
    }
    data.reverse();

    return [data, events];
}

import { EVENTS } from "../../constants";
import {
    pairingListCreatedEvent, smartNoteSubmittedEvent
} from "../utilities";

export function handleEvent(db, event){
    switch (event.event){
    case EVENTS.PAIRING_LIST_CREATED:
        return pairingListCreatedEvent(db, event.payload.list, 1);
    case EVENTS.SMART_NOTE_SUBMITTED:
        return smartNoteSubmittedEvent(db, event.payload, "1");
    default:
        throw new Error("Unknown event!");
    }
}
import React from "react";
import { useTranslation } from "react-i18next";

import connect from "../../containers/SubmitNote";
import { PENDING } from "../../constants/lifecycle";
import "./NoteForm.css";

function NoteForm({ lifecycle, input, inputNote, submitNote }) {
    const { t } = useTranslation();
    function change(domEvent) {
        domEvent.preventDefault();
        inputNote(domEvent.target.value);
    }
    function submit(domEvent) {
        domEvent.preventDefault();
        submitNote();
    }
    const disabled = lifecycle === PENDING;
    return (
        <form onSubmit={submit} className={`note ${lifecycle}`} id="note-form">
            <input
                type="text"
                placeholder={t("note")}
                onChange={change}
                required
                disabled={disabled}
                value={input}
            />
            <input type="submit" disabled={disabled} />
        </form>
    );
}

export default connect(NoteForm);

import { connect } from "react-redux";

import { togglePreview } from "../store/actions";
import { SESSION } from "../constants";

function mapStateToProps({ sessions, tags, preview }) {
    return {
        lists: Array.from(
            Object.keys(tags.session).reduce((lists, id) => {
                if (tags.session[id].length === 0) return lists;
                return tags.session[id]
                    .map((t) => t.value)
                    .includes(SESSION.PENDING)
                    ? lists.add(sessions[id].pairingList)
                    : lists;
            }, new Set()),
        ),
        preview: preview,
    };
}
const mapDispatchToProps = { onToggle: (id) => togglePreview("list", id) };

export default connect(mapStateToProps, mapDispatchToProps);

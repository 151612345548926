import {
    SET_PAIRINGS_FROM_EXTERNAL,
    RESET_PAIRINGS_FROM_EXTERNAL
} from "../../actions/actionTypes";

const initialState = [];

export default function ( state = initialState, action) {
    switch (action.type) {
    case SET_PAIRINGS_FROM_EXTERNAL: return [ ...action.list ];
    case RESET_PAIRINGS_FROM_EXTERNAL: return [];
    default: return state;
    }
}

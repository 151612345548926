import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { finishSession } from "../../store/actions";
import SensorLabel from "../SensorLabel";
import { COW } from "../../constants";
import Button from "../Button";

export function Session({
    type,
    sensor,
    onFinish,
    cow,
    sensorTags,
    setToast,
    pairingEnabled,
}) {
    const { t } = useTranslation();
    function onFinishLocal(isTsens, cowTagValues) {
        if (pairingEnabled === false) {
            setToast(true);
            return;
        }
        if (isTsens && cowTagValues.includes(COW.CALVING)) {
            window.alert(t("PleaseFinishCowSensorPairUsingConfirmation"));
            return;
        }
        const sure = window.confirm(t("areYouSureToFinishCowSensorPair"));
        if (sure) onFinish();
    }
    switch (type) {
        case "button":
            return (
                <Button
                    onClicked={() =>
                        onFinishLocal(sensor.type == "Tsens", cow.tagValues)
                    }
                    className={sensor.type}
                    text={
                        sensor.type == "Tsens"
                            ? t("unpairTsens")
                            : t("unpairEartag")
                    }
                    type="action"
                    behaviour="instant"
                />
            );
        case "list":
            return (
                <li
                    className={cn("item", sensor.type)}
                    onClick={() =>
                        onFinishLocal(sensor.type == "Tsens", cow.tagValues)
                    }>
                    {sensor.type == "Tsens"
                        ? t("unpairTsens")
                        : t("unpairEartag")}
                </li>
            );
        case "label":
        default:
            return (
                <h4 className={`session ${sensorTags}`}>
                    <SensorLabel
                        {...sensor}
                        onClick={() =>
                            onFinishLocal(sensor.type == "Tsens", cow.tagValues)
                        }
                    />
                </h4>
            );
    }
}

function mapStateToProps({ sensors, cows, tags, priviliges }, { session }) {
    return {
        sensor: sensors[session.sensor],
        cow: {
            ...cows[session.cow],
            tagValues: tags.cow[session.cow].map((t) => t.value),
        },
        sensorTags: tags.sensor[session.sensor].map((s) => s.value).join(" "),
        pairingEnabled: priviliges.PAIRING_ENABLED.value,
    };
}

function mapDispatchToProps(dispatch, { session }) {
    return {
        onFinish() {
            dispatch(finishSession(session.id));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Session);

import i18n from "../../../i18n";
const currentLanguage = i18n.language;

const cowNames = {
    en: ["Maria", "Bella", "Brownie", "Guinness", "Penny", "Dottie"],
    de: ["Diane", "Janka", "Julia", "Laura", "Mia", "Kristina"],
    tr: ["Sarıkız", "Karakız", "Nazlı", "Çiğdem", "Bahar", "Emine"],
    it: ["Velvet", "Susan", "Rosalia", "Sophia", "Tina", "Carolina"],
    da: ["Laura", "Clara", "Anna", "Frida", "Karla", "Olivia"],
    pt: ["Maria", "Bella", "Brownie", "Guinness", "Penny", "Dottie"],
};
const names = cowNames[currentLanguage] || [
    "Maria",
    "Bella",
    "Brownie",
    "Guinness",
    "Penny",
    "Dottie",
];

export const cows = [
    {
        identityNumber: "TR010000001",
        farmNumber: "0001",
        lastBreedingDate: 1572598800,
        lactationCount: "0",
        name: names[0],
        birthdate: 1509526800,
        breed: "Holstein",
        lastCalvingDate: 0,
        expectedDelivery: 1596790800,
        isPregnant: true,
    },
    {
        identityNumber: "TR010000002",
        farmNumber: "0002",
        lastBreedingDate: 1572598800,
        lactationCount: "0",
        name: names[1],
        birthdate: 1509699600,
        breed: "Holstein",
        lastCalvingDate: 0,
        expectedDelivery: 1596790800,
        isPregnant: false,
    },
    {
        identityNumber: "TR010000003",
        farmNumber: "0003",
        lastBreedingDate: 1572598800,
        lactationCount: "0",
        name: names[2],
        birthdate: 1509699600,
        breed: "Holstein",
        lastCalvingDate: parseInt(
            (new Date().getTime() - 5 * 24 * 60 * 60 * 1000) / 1000,
            10,
        ),
        expectedDelivery: 1596790800,
        isPregnant: false,
    },
    {
        identityNumber: "TR010000004",
        farmNumber: "0004",
        lastBreedingDate: 0,
        lactationCount: "0",
        name: names[3],
        birthdate: 1509699600,
        breed: "Holstein",
        lastCalvingDate: 0,
        expectedDelivery: 0,
        isPregnant: false,
    },
    {
        identityNumber: "TR010000005",
        farmNumber: "0005",
        lastBreedingDate: 0,
        lactationCount: "0",
        name: names[4],
        birthdate: 1509699600,
        breed: "Holstein",
        lastCalvingDate: 0,
        expectedDelivery: 0,
        isPregnant: false,
    },
    {
        identityNumber: "TR010000006",
        farmNumber: "0006",
        lastBreedingDate: 0,
        lactationCount: "0",
        name: names[5],
        birthdate: 1509699600,
        breed: "Holstein",
        lastCalvingDate: 0,
        expectedDelivery: 0,
        isPregnant: false,
    },
];
export const sensors = [
    {
        id: "1",
        deviceId: "11101201110163",
        type: "Tsens",
        tags: ["idle"],
    },
    {
        id: "2",
        deviceId: "11101109190135",
        type: "Tsens",
        tags: ["idle"],
    },
    {
        id: "3",
        deviceId: "111011091A0246",
        type: "Tsens",
        tags: ["idle"],
    },
    {
        id: "4",
        deviceId: "111011091B0357",
        type: "Tsens",
        tags: ["idle"],
    },
    {
        id: "5",
        deviceId: "111011091C0468",
        type: "Tsens",
        tags: ["idle"],
    },
    {
        id: "6",
        deviceId: "111011091D0579",
        type: "Tsens",
        tags: ["idle"],
    },
    {
        id: "7",
        deviceId: "113112040D0193",
        type: "supervisor",
        tags: ["idle"],
    },
    {
        id: "8",
        deviceId: "113113031D0264",
        type: "repeater",
        tags: ["online"],
    },
];

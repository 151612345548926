import * as EVENTS from "../utilities";
import { sensors, cows } from "./initialItems";

const eventSeeder = (server) => {
    const db = server.schema.db;
    EVENTS.newCowAcquiredEvent(db, cows[0], "1");
    EVENTS.newSensorAcquiredEvent(db, sensors[0], "1");
    EVENTS.newSensorAcquiredEvent(db, sensors[1], "1");
    EVENTS.pairingListCreatedEvent(db, [{ cow: "1", sensor: "1" }], "1");
    EVENTS.newCowAcquiredEvent(db, cows[1], "1");
    EVENTS.sensorActivatedEvent(db, "1");
    EVENTS.stableDataDetectedEvent(db, "1", "1");
    EVENTS.feverDetectedEvent(db, "1", 40);
    EVENTS.calvingDetectedEvent(db, "1");
    EVENTS.smartNoteSubmittedEvent(
        db,
        {
            about: "calving-detected::confirm",
            parentEventTagItems: { cow: "1", eventId: "14" },
            note:
                "$${LANGUAGE::1} $${VARIABLE::1} $${LANGUAGE::2} $${VARIABLE::2} $${LANGUAGE::3} $${VARIABLE::3} $${LANGUAGE::4} $${VARIABLE::4} $${LANGUAGE::5} $${VARIABLE::5}$${LANGUAGE::13} $${VARIABLE::13} $${LANGUAGE::14} $${VARIABLE::14&EFFECT::plural&AFFECT::14} $${LANGUAGE::15}",
            options: {
                1: [{ id: "name", parent: "cow", value: "$${cow}" }],
                2: [
                    {
                        id: "time",
                        type: "timePicker",
                        value: "2019-11-08T11:03:49.146Z",
                        parent: "time",
                        order: 2,
                    },
                ],
                3: [
                    {
                        id: "one",
                        parent: "count",
                        value: "smartNote:variables:one",
                    },
                ],
                4: [
                    {
                        id: "healthy",
                        parent: "healthCondition",
                        value: "smartNote:variables:healthy",
                    },
                ],
                5: [
                    {
                        id: "male",
                        parent: "gender",
                        value: "smartNote:variables:male",
                        order: 5,
                    },
                ],
                6: [
                    {
                        id: "easy",
                        parent: "calvingCondition",
                        value: "smartNote:variables:easy",
                        order: 6,
                    },
                ],
                7: [
                    {
                        id: "noComplication",
                        parent: "healthComplication",
                        value: "smartNote:variables:noComplication",
                        isReplaceable: true,
                        affect: ["14"],
                    },
                ],
            },
            eventVersion: "1",
        },
        "1",
    );

    EVENTS.pairingListCreatedEvent(db, [{ cow: "2", sensor: "1" }], "1");
    EVENTS.sensorActivatedEvent(db, "1");
    EVENTS.stableDataDetectedEvent(db, "2", "1");
};

export default function seeds(server) {
    server.loadFixtures();
    eventSeeder(server);
}

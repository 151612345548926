import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import LoginForm from "../LoginForm";
import "./LoginModal.css";
import { loginModal as connect } from "../../containers";
import { PENDING } from "../../constants/lifecycle";
import DemoModal from "../DemoModal";

export const HIDDEN = "HIDDEN";
export const FORM = "FORM";
export const MESSAGE = "MESSAGE";
export const ERROR = "ERROR";
export const RETRY = "RETRY";

export function LoginModal({
    state = HIDDEN,
    lifecycle,
    errorType,
    showDemoModal,
}) {
    const { t } = useTranslation();
    const disabled = lifecycle === PENDING;
    const email = localStorage.getItem("lastEmail");

    function TipAndFeature() {
        return (
            <React.Fragment>
                <div className="wicow-tip">
                    <span className="wicow-tip-image" />
                    <h2 className="wicow-tip-header">
                        {t("wicowTip#3Header")}
                    </h2>
                    <p className="wicow-tip-explanation">
                        {t("wicowTip#3Explanation")}
                    </p>
                </div>
            </React.Fragment>
        );
    }
    function VersionNews() {
        return (
            <div className="version-news">
                <h2 className="news-header">{t("versionHeader")}</h2>
                <ul className="news-list">
                    <li>
                        <Trans i18nKey="tryOutDemo">
                            Try out{" "}
                            <a
                                className="demo-link"
                                onClick={() => showDemoModal()}>
                                demo
                            </a>
                        </Trans>
                    </li>
                    <li>{t("versionExplanation#1")}</li>
                    <li>{t("versionExplanation#2")}</li>
                </ul>
            </div>
        );
    }

    switch (state) {
        case HIDDEN:
            return null;
        case FORM:
            return (
                <aside className="modal-login">
                    <span className="wiCow-logo" />
                    <div className={`login-form-box ${lifecycle}`}>
                        <p>
                            {errorType
                                ? errorMessages(errorType)
                                : t("enterYourEmailToReceiveYourLoginLink")}
                        </p>
                        <LoginForm disabled={disabled} />
                    </div>
                    <VersionNews />
                    <TipAndFeature />
                    <DemoModal />
                </aside>
            );
        case MESSAGE:
            return (
                <aside className="modal-login">
                    <span className="wiCow-logo" />
                    <div className={`login-form-box ${lifecycle}`}>
                        <p>
                            <Trans
                                i18nKey="pleaseCheckYourEmailForYourLoginLink"
                                email={email}>
                                We just sent an email to
                                <span>{{ email }}</span> that contains a magic
                                link. Open that email and follow the link to log
                                into the wiCow dashboard.
                            </Trans>
                        </p>
                    </div>
                    <VersionNews />
                    <TipAndFeature />
                </aside>
            );
        case ERROR:
            return (
                <aside className="modal-login">
                    <span className="wiCow-logo" />
                    <div className={`login-form-box ${lifecycle}`}>
                        <p>{t("thereWasProblemWithTheServer")}</p>
                        <LoginForm disabled={disabled} />
                    </div>
                    <VersionNews />
                    <TipAndFeature />
                </aside>
            );
        case RETRY:
            return (
                <aside className="modal-login">
                    <span className="wiCow-logo" />
                    <div className={`login-form-box ${lifecycle} retry`}>
                        <p>
                            <Trans
                                i18nKey="pleaseCheckYourEmailForYourLoginLink"
                                email={email}>
                                We just sent an email to
                                <span>{{ email }}</span> that contains a magic
                                link. Open that email and follow the link to log
                                into the wiCow dashboard.
                            </Trans>
                        </p>
                        <LoginForm
                            disabled={disabled}
                            isStateRetry={state === "RETRY"}
                            t={t}
                        />
                    </div>
                    <VersionNews />
                    <TipAndFeature />
                </aside>
            );
        default:
            return null;
    }
}

export default connect(LoginModal);

function errorMessages(errorType) {
    switch (true) {
        default:
        case errorType === 401:
            return (
                <Trans i18nKey="401unauthorized">
                    This email address does not appear in our records. Please
                    ensure that it is the one you used to sign up for wiCow and
                    there are no typos, and then try again. If you believe this
                    to be in error, please let us know at
                    <a href="mailto:developerteam@wicow.io" className="mail">
                        developerteam@wicow.io
                    </a>
                </Trans>
            );
        case errorType === 403:
            return (
                <Trans i18nKey="403forbidden">
                    This email address is not allowed to access the wiCow Pro
                    app. If you believe this to be in error, please let us know
                    at
                    <a href="mailto:developerteam@wicow.io" className="mail">
                        developerteam@wicow.io
                    </a>
                </Trans>
            );
        case errorType === 404:
            return (
                <Trans i18nKey="404notFound">
                    This email address is in our records, but not associated
                    with any active wiCow farm. If you believe this to be in
                    error, please let us know at
                    <a href="mailto:developerteam@wicow.io" className="mail">
                        developerteam@wicow.io
                    </a>
                </Trans>
            );
        case errorType >= 500:
            return (
                <Trans i18nKey="serverError">
                    The wiCow server encountered a problem. Please try again in
                    a short while. If the problem persists, please send us an
                    email at
                    <a href="mailto:developerteam@wicow.io" className="mail">
                        developerteam@wicow.io
                    </a>
                </Trans>
            );
    }
}

import {
    take, put, call, spawn, fork, race
} from "redux-saga/effects";
import { delay } from "redux-saga";
import {
    MARK_READ,
    START_MARK_READ_REQUEST,
    COMPLETE_MARK_READ_REQUEST,
    FAIL_MARK_READ_REQUEST
} from "../actions/actionTypes";
import axios from "axios";

export function *markRead() {
    yield fork(markReadRequest);
    const markActions = [yield take(MARK_READ)];
    while (true) {
        const { anotherMark, timeToSend } = yield race({
            timeToSend: call(delay, 1000),
            anotherMark: take(MARK_READ)
        });
        if (timeToSend) {
            yield put({
                type: START_MARK_READ_REQUEST,
                mark: markActions.map(action => action.id)
            });
            break;
        }
        else if (anotherMark) { markActions.push(anotherMark); }
    }
    yield spawn(markRead);
}

function *markReadRequest() {
    const { mark } = yield take(START_MARK_READ_REQUEST);
    try {
        const response = yield call(axios.put, "/v2/events", { mark });
        yield put({
            type: COMPLETE_MARK_READ_REQUEST,
            data: response.data,
            mark
        });
    } catch (error) {
        yield put({
            type: FAIL_MARK_READ_REQUEST,
            error,
            mark
        });
    }
}

import React, { useReducer, useEffect } from "react";
import "./ListSlider.css";

export function ListSlider({
    children,
    position = "rest",
    limitLeft,
    dragLeftBorder,
    dragRightBorder,
    isUserOwner,
    canSlide,
    ...rest
}) {
    const [state, dispatch] = useReducer(dragReducer, {
        position,
        limitLeft,
        dragLeftBorder,
        dragRightBorder,
        isUserOwner,
        canSlide,
    });

    useEffect(() => dispatch({ type: "set-position", position }), [position]);

    const { Overlay, Left } = children.reduce(
        (out, child) => ({
            ...out,
            [child.key]: {
                ...child,
                props: { ...child.props, state, dispatch },
            },
        }),
        {},
    );
    const render = state.delta > 0 ? [Left, Overlay] : [Overlay];
    return (
        <div data-slider-container {...rest}>
            {render}
        </div>
    );
}

export function Overlay({
    children,
    state,
    dispatch,
    onMoved,
    user,
    autoClose,
    ...rest
}) {
    useEffect(() => {
        if (autoClose) dispatch({ type: "click" });
    }, [autoClose, dispatch]);
    function slideHandler(action) {
        if (action.type === "move") onMoved(user);
        dispatch(action);
    }
    const style =
        state.position === "drag-left"
            ? { transform: `translateX(${state.delta}px)` }
            : {};
    return (
        <div
            data-slider-overlay={state.position}
            style={style}
            onTouchStart={(event) =>
                slideHandler({ type: "start", touch: event.touches[0] })
            }
            onTouchMove={(event) =>
                slideHandler({
                    type: "move",
                    touch: event.touches[0],
                })
            }
            onTouchEnd={(event) => slideHandler({ type: "end" })}
            onClick={(event) => slideHandler({ type: "click" })}
            {...rest}>
            {children}
        </div>
    );
}

function dragReducer(state, action) {
    let delta = 0;
    switch (`${state.position}/${action.type}`) {
        case "rest/start":
            return {
                position: "drag-left",
                limitLeft: state.limitLeft,
                dragLeftBorder: state.dragLeftBorder,
                dragRightBorder: state.dragRightBorder,
                startX: action.touch.screenX,
                canSlide: state.canSlide,
                isUserOwner: state.isUserOwner,
                delta: 0,
                id: action.touch.identifier,
            };

        case "drag-left/move":
            if (state.canSlide)
                delta =
                    action.touch.screenX - state.startX > state.limitLeft
                        ? state.limitLeft
                        : action.touch.screenX - state.startX;

            return {
                ...state,
                delta,
                position: delta > 0 ? "drag-left" : "rest",
            };

        case "drag-left/end":
            return {
                ...state,
                position: state.delta > state.dragLeftBorder ? "left" : "rest",
            };
        case "left/start":
            return {
                ...state,
                startX: action.touch.screenX,
                position: "left",
            };
        case "left/move":
            delta = action.touch.screenX - state.startX;
            return {
                ...state,
                position: delta < state.dragRightBorder ? "rest" : "left",
            };
        case "left/click":
            return { ...state, position: "rest" };

        case "rest/set-position":
            return { ...state, position: action.position };
        default:
            return state;
    }
}

export function Left({ children, state, dispatch, ...rest }) {
    return (
        <div
            data-slider-left
            onTouchStart={(event) =>
                dispatch({
                    type: "start",
                    touch: event.touches[0],
                })
            }
            onTouchMove={(event) =>
                dispatch({
                    type: "move",
                    touch: event.touches[0],
                })
            }
            {...rest}>
            {children}
        </div>
    );
}

import { put, call, take, spawn } from "redux-saga/effects";
import axios from "axios";
import {
    SET_HEALTH_RANGE,
    START_HEALTH_REPORT,
    COMPLETE_HEALTH_REPORT,
    FAIL_HEALTH_REPORT
} from "../actions/actionTypes";


export function *getHealthReport(startDate, endDate){
    yield put({ type: START_HEALTH_REPORT });
    if (!startDate){
        //current month
        const now = new Date();
        now.setUTCDate(1);
        now.setUTCHours(0, 0, 1);
        startDate = now.getTime();
        endDate = new Date().getTime();
    }
    try {
        const { data } = yield call(axios.get, `/tools/report/health?start=${startDate}&end=${endDate}`);
        yield put({
            type: COMPLETE_HEALTH_REPORT,
            cows: data
        });
    } catch (error){
        yield put({ type: FAIL_HEALTH_REPORT });
    }
    const { start, end } = yield take(SET_HEALTH_RANGE);
    yield spawn(getHealthReport, start, end);
}
import React from "react";
import cn from "classnames";
import "./FarmSettings.css";
import { useTranslation } from "react-i18next";

import Button from "../Button";

import { ListSlider, Overlay, Left } from "./ListSlider";

export default function UserLine({
    user,
    ownerUserId,
    farmAdmin,
    removeUser,
    onMoved,
    autoClose,
    isUserOwner,
    redirectCall,
    willCalled,
    canSlide,
    callEnabled,
}) {
    const { t } = useTranslation();
    const disabledCall = willCalled || !user.phoneNumber;
    const disableRemoving = isUserOwner;
    return (
        <ListSlider
            limitLeft={(window.innerWidth * 45) / 100}
            dragLeftBorder={window.innerWidth / 8}
            dragRightBorder={window.innerWidth / -8}
            isUserOwner={isUserOwner}
            farmAdmin={farmAdmin}
            canSlide={canSlide}>
            <Overlay
                key="Overlay"
                onMoved={onMoved}
                user={user}
                autoClose={autoClose}>
                <li
                    className={cn("user", {
                        "will-call": willCalled && callEnabled,
                    })}>
                    <h1
                        className={cn(
                            "name-surname",
                        )}>{`${user.name} ${user.surname}`}</h1>
                    <h3 className="email-phone">
                        {!user.email && !user.phoneNumber
                            ? ""
                            : !user.email
                            ? `${user.phoneNumber}`
                            : !user.phoneNumber
                            ? `${user.email}`
                            : `${user.email} - ${user.phoneNumber}`}
                    </h3>
                    <h3 className="showing-owner-user">
                        {user.id === ownerUserId ? `${t("thisIsYou")}` : null}
                    </h3>
                </li>
            </Overlay>
            <Left key="Left">
                <li className={cn("user-options")}>
                    <Button
                        className={cn("removing", {
                            disabled: disableRemoving,
                        })}
                        onClicked={() => removeUser(user.id)}
                        text={t("remove")}
                        type="action"
                        behaviour="instant"
                        disabled={disableRemoving}
                    />
                    <Button
                        className={cn("call-redirect", {
                            disabled: !callEnabled || willCalled,
                        })}
                        onClicked={() => redirectCall(user.id)}
                        text={t("redirect")}
                        type="action"
                        behaviour="instant"
                        disabled={disabledCall}
                    />
                </li>
            </Left>
        </ListSlider>
    );
}

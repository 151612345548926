import {
    START_COW_TEMPERATURE_REQUEST,
    COMPLETE_COW_TEMPERATURE_REQUEST,
    FAIL_COW_TEMPERATURE_REQUEST
} from "../actions/actionTypes";

import { INITIAL, PENDING, DONE, FAILED } from "../../constants/lifecycle";

export default function (state = {}, action) {
    const data = state[action.cow]
        || { timestamp: 0, temperature: [], lifecycle: INITIAL, period: 60000 };
    switch (action.type) {
    case START_COW_TEMPERATURE_REQUEST:
        return {
            ...state,
            [action.cow]: {
                ...data,
                lifecycle: PENDING
            }
        };
    case COMPLETE_COW_TEMPERATURE_REQUEST:
        return {
            ...state,
            [action.cow]: spliceTemperatureData(data, action.data)
        };
    case FAIL_COW_TEMPERATURE_REQUEST:
        return { ...state, [action.cow]: { ...data, lifecycle: FAILED } };
    default:
        return state;
    }
}


function spliceTemperatureData(oldData, newData) {
    // Temporary defaults for datapoint period and maximum number of datapoints to keep.
    // TODO: Reconcile data with different periods
    // TODO: dynamically adjust max datapoints
    const [defaultPeriod, defaultCutoff] = [60000, 2880];

    const offset = Math.floor((newData.timestamp - oldData.timestamp) / defaultPeriod);

    // default is an array of nulls
    const splicedTemperatureData = new Array(defaultCutoff).fill(null);
    // insert old temperature array at the offset point
    splicedTemperatureData.splice(offset, oldData.temperature.length, ...oldData.temperature);
    // insert new temperature array at the start
    splicedTemperatureData.splice(0, newData.temperature.length, ...newData.temperature);
    // discard the extra data
    splicedTemperatureData.splice(defaultCutoff);

    return { ...newData, temperature: splicedTemperatureData, lifecycle: DONE };
}

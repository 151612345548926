import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources from "./locale";
import { SUPPORTED_LANGUAGES } from "./constants";

let lang = localStorage.getItem("language");
if (!lang) {
    lang = (navigator.language || navigator.userLanguage).split("-")[0];
}
if (!SUPPORTED_LANGUAGES.includes(lang)) {
    lang = "en";
}

i18n.use(initReactI18next).init({
    fallbackLng: "en",
    lng: lang,
    defaultNS: "common",
    resources,
    interpolation: { escapeValue: false },
    joinArrays: "\n",
    wait: true,
});
export default i18n;

import React from "react";
import pt from "prop-types";
import { Trans } from "react-i18next";

function Age({ time }) {
    if (time === 0) return null;
    const now = new Date();
    const date = new Date(time);
    const ageMiliseconds = now - date;
    const days = Math.floor(ageMiliseconds / 24 / 60 / 60 / 1000);
    const months = Math.floor(days / 30.4);
    const years = Math.floor(days / 365.25);

    const [timeframe, count] = (years > 0 && ["age.years", years]) ||
        (months > 0 && ["age.months", months]) || ["age.days", days];

    return (
        <time dateTime={date}>
            <Trans i18nKey={timeframe} count={count}>
                <strong>{{ count }}</strong>
            </Trans>
        </time>
    );
}

Age.propTypes = {
    time: function ({ time }, propName, componentName) {
        if (typeof time !== "number" || time < 1000000000000) {
            return new Error(
                `Invalid prop ${propName} supplied to ` +
                    `${componentName}. Validation failed. ` +
                    "You probably supplied a time in seconds " +
                    "instead of miliseconds.",
            );
        }
    },
    options: pt.oneOf(["year"]),
};
export default Age;

import { RESTORE_SNAPSHOT } from "../../../store/actions/actionTypes";

import events from "./mockServerEvents";

import {
    stepNamespace,
    advanceOnClick,
    waitForSnapshot,
    media,
    reCapture,
} from ".";

const step = stepNamespace("event");

export default function event() {
    return [
        {
            ...step("list"),
            attachTo: { element: "section.events", on: "right" },
            scrollTo: null,
            showOn: media("(pointer: fine)"),
        },
        {
            ...step("list"),
            attachTo: { element: "ol.events", on: "top" },
            scrollTo: null,
            showOn: media("(pointer: coarse)"),
        },
        {
            ...step("stacks"),
            attachTo: { element: "ol.events .cow-events", on: "top" },
        },
        {
            ...step("latest"),
            attachTo: { element: "ol.events li.event", on: "top" },
        },
        {
            ...step("collapsed"),
            attachTo: { element: "ol.events li.event.half-open", on: "top" },
        },
        {
            ...step("expand-stack"),
            ...advanceOnClick("ol.events .cow-events"),
        },
        {
            ...step("collapse-stack"),
            ...advanceOnClick("ol.events .cow-events"),
        },
        {
            ...step("action"),
            attachTo: {
                element: "ol.events li.event.calving-detected",
                on: "top",
            },
            ...waitForSnapshot(() => events.calvingDetected(2)),
            revert(tour) {
                tour.options.dispatch({ type: RESTORE_SNAPSHOT });
            },
            when: {
                "before-show"() {
                    reCapture(this);
                },
            },
        },
        {
            ...step("action-resolved"),
            attachTo: {
                element: "ol.events li.event.calving-detected",
                on: "top",
            },
        },
        {
            ...step("older-events"),
            attachTo: { element: "ol.events li.older", on: "top" },
        },
    ];
}

import * as en from "./en";
import * as de from "./de";
import * as tr from "./tr";
import * as da from "./da";
import * as it from "./it";
import * as pt from "./pt";
import * as es from "./es";
import * as bg from "./bg";
import * as ru from "./ru";
import * as fr from "./fr";

export default {
    en,
    de,
    tr,
    it,
    da,
    pt,
    es,
    bg,
    ru,
    fr,
};

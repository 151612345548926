import { put, take, select, call, spawn } from "redux-saga/effects";
import { delay } from "redux-saga";
import axios from "axios";

import {
    SHOW_LOGIN_FORM,
    SUBMIT_LOGIN_EMAIL,
    START_LOGIN_LINK_REQUEST,
    COMPLETE_LOGIN_LINK_REQUEST,
    RETRY_LOGIN_EMAIL,
    TRY_ANOTHER_LOGIN_EMAIL,
    FAIL_LOGIN_LINK_REQUEST,
} from "../actions/actionTypes";

export function* loginLink() {
    yield put({ type: SHOW_LOGIN_FORM });
    yield take(SUBMIT_LOGIN_EMAIL);
    const email = yield select((state) => state.loginForm.email);
    const platform = yield localStorage.getItem("platform");
    yield put({ type: START_LOGIN_LINK_REQUEST, email });
    try {
        yield call(axios.post, "/login", { email, platform });
        yield window.localStorage.setItem("lastEmail", email);
        yield put({ type: COMPLETE_LOGIN_LINK_REQUEST });
        yield call(delay, 2000);
        yield put({ type: RETRY_LOGIN_EMAIL });
        yield spawn(resendEmail);
        yield spawn(tryAnotherEmail);
    } catch (error) {
        yield put({ type: FAIL_LOGIN_LINK_REQUEST, error });
        yield spawn(loginLink);
    }
}

function* resendEmail() {
    yield take(SUBMIT_LOGIN_EMAIL);
    const email = localStorage.getItem("lastEmail");
    const platform = yield localStorage.getItem("platform");
    yield put({ type: START_LOGIN_LINK_REQUEST, email });
    try {
        yield call(axios.post, "/login", { email, platform });
        yield put({ type: COMPLETE_LOGIN_LINK_REQUEST });
        yield call(delay, 2000);
        yield put({ type: RETRY_LOGIN_EMAIL });
    } catch (error) {
        yield put({ type: FAIL_LOGIN_LINK_REQUEST, error });
    } finally {
        yield spawn(resendEmail);
    }
}

function* tryAnotherEmail() {
    const emptyEmail = "";
    yield take(TRY_ANOTHER_LOGIN_EMAIL);
    yield window.localStorage.setItem("lastEmail", emptyEmail);
    window.location.reload();
}

import React from "react";
import { connect } from "react-redux";
import cn from "classnames";

import { COW, SENSOR } from "../../constants/schema";

import "./Badge.css";

const Badge = ({ badges }) => {
    return (
        <React.Fragment>
            {Object.keys(badges).map((b) => (
                <div key={b} className={cn("badge-item", b, badges[b])} />
            ))}
        </React.Fragment>
    );
};

function mapStateToProps({ tags, sensors }, { cowId, sensorIds }) {
    const cowTags = { [cowId]: tags.cow[cowId].map((t) => t.value) };
    const sensorTags = sensorIds.reduce((acc, object) => {
        return {
            ...acc,
            [object.sensor]: tags.sensor[object.sensor].map((t) => t.value),
        };
    }, {});
    const sessionTags = sensorIds.reduce((acc, object) => {
        return {
            ...acc,
            [object.session]: tags.session[object.session].map((t) => t.value),
        };
    }, {});
    return { badges: generateBadge(cowTags, sensorTags, sensors, sessionTags) };
}

function generateBadge(cowTags, sensorTags, sensors, sessionTags) {
    const sessionTagValues = Object.values(sessionTags)[0] || [];
    const cowBadges = Object.keys(cowTags).reduce((acc, id) => {
        if (cowTags[id].includes(COW.PREGNANT)) {
            if (sessionTagValues.includes("pregnancy")) {
                acc = {
                    ...acc,
                    calf: cowTags[id].filter(
                        (t) =>
                            t === COW.PREGNANT ||
                            t === COW.DUE ||
                            t === COW.CALVING,
                    ),
                };
            } else if (sessionTagValues.includes("active")) {
                acc = {
                    ...acc,
                    temperature: [],
                };
            }
        } else if (cowTags[id].includes(COW.ESTROUS)) {
            if (sessionTagValues.includes("estrous")) {
                acc = {
                    ...acc,
                    estrous: cowTags[id].filter(
                        (t) =>
                            t === COW.ESTROUS ||
                            t === COW.HEAT ||
                            t === "potential",
                    ),
                };
            } else if (sessionTagValues.includes("active")) {
                acc = {
                    ...acc,
                    temperature: [],
                };
            }
        }
        if (
            cowTags[id].includes(COW.FEVER) ||
            cowTags[id].includes(COW.HYPOTHERMIA)
        )
            acc = {
                ...acc,
                temperature: cowTags[id].filter(
                    (t) => t === COW.FEVER || t === COW.HYPOTHERMIA,
                ),
            };
        return acc;
    }, {});
    const sensorBadges = Object.keys(sensorTags).reduce((acc, id) => {
        if (
            sensorTags[id].includes(SENSOR.ASSIGNED) ||
            sensorTags[id].includes(SENSOR.FAULT) ||
            sensorTags[id].includes(SENSOR.PENDING) ||
            sensorTags[id].includes(SENSOR.UNSTABLE) ||
            sensorTags[id].includes(SENSOR.NETWORK_PROBLEM) ||
            sensorTags[id].includes(SENSOR.LOW_BATTERY)
        )
            acc = { ...acc, [sensors[id].type]: sensorTags[id] };
        return acc;
    }, {});
    return { ...cowBadges, ...sensorBadges };
}

export default connect(mapStateToProps)(Badge);

// Events
export const EVENTS = {
    SESSION_CREATED: "session-created",
    FIRST_SESSION_CREATED: "first-session-created",
    LAST_SESSION_COMPLETED: "last-session-completed",
    SESSION_COMPLETED: "session-completed",
    SESSION_REJECTED: "session-rejected",
    SESSION_CANCELED: "session-canceled",
    SESSION_TRASHED: "session-trashed",
    SENSOR_ACTIVATED: "sensor-activated",
    STABLE_DATA_DETECTED: "stable-data-detected",
    UNSTABLE_DATA_DETECTED: "unstable-data-detected",
    LOW_BATTERY_DETECTED: "low-battery-detected",
    NO_COMMUNICATION: "no-communication",
    SENSOR_RESET: "sensor-reset",
    TRACKING_STARTED: "tracking-started",
    FEVER_DETECTED: "fever-detected",
    FEVER_INCREASE_DETECTED: "fever-increase-detected",
    FEVER_OVER: "fever-over",
    HYPOTHERMIA_DETECTED: "hypothermia-detected",
    HYPOTHERMIA_OVER: "hypothermia-over",
    PREGNANCY_DETECTED: "pregnancy-detected",
    PREGNANCY_OVER: "pregnancy-over",
    CALVING_DUE: "calving-due",
    CALVING_OVERDUE: "calving-overdue",
    CALVING_DUE_FALSE: "calving-due-false",
    CALVING_DETECTED: "calving-detected",
    CALVING_COMPLETED: "calving-completed",
    PAIRING_LIST_CREATED: "pairing-list-created",
    PAIRING_LIST_UPDATED: "pairing-list-updated",
    PAIRING_LIST_ARCHIVED: "pairing-list-archived",
    NOTE_SUBMITTED: "note-submitted",
    SMART_NOTE_SUBMITTED: "smart-note-submitted",
    TSENS_INSTALLED: "tsens-installed",
    TSENS_REMOVED: "tsens-removed",
    TSENS_PROBLEM_DETECTED: "tsens-problem-detected",
    TSENS_PROBLEM_OVER: "tsens-problem-over",
    LABOR_DETECTED: "labor-detected",
    LABOR_OVER: "labor-over",
    DELIVERY_DETECTED: "delivery-detected",
    DELIVERY_COMPLETED: "delivery-completed",
    NEW_COW_ACQUIRED: "new-cow-acquired",
    COW_EDITED: "cow-edited",
    COW_DIED: "cow-died",
    ONLINE_REPEATER_DETECTED: "online-repeater-detected",
    OFFLINE_REPEATER_DETECTED: "offline-repeater-detected",
    ONLINE_BASESTATION_DETECTED: "online-basestation-detected",
    OFFLINE_BASESTATION_DETECTED: "offline-basestation-detected",
    NEW_USER_ADDED: "new-user-added",
    USER_DELETED: "user-deleted",
    USER_REGISTERED: "user-registered",
    POTENTIAL_CALVINGS_DETECTED: "potential-calvings-detected",
    CALL_FAILED: "call-failed",
    NEW_SENSOR_ACQUIRED: "new-sensor-acquired",
    CALLED_USER_ACTIVATED: "called-user-activated",
    CALLED_USER_DEACTIVATED: "called-user-deactivated",
    HEAT_DETECTED: "heat-detected",
    HEAT_OVER: "heat-over",
    SENSOR_FALL_DETECTED: "sensor-fall-detected",
    POTENTIAL_POSTPARTUM_COWS_DETECTED: "potential-postpartum-cows-detected",
    POTENTIAL_ESTROUSES_DETECTED: "potential-estrouses-detected",
    POTENTIAL_DRY_COWS_DETECTED: "potential-dry-cows-detected",
    ESTROUS_CYCLE_DETECTED: "estrous-cycle-detected",
    CALVING_CONFIRM_WARNINGS: "calving-confirm-warnings",
    LONG_DUE_DETECTED: "long-due-detected",
    IMMINENT_DUE_DETECTED: "imminent-due-detected",
    SENSOR_TRANSFER_OVER: "sensor-transfer-over",
    SENSOR_TRANSFER_STARTED: "sensor-transfer-started",
    COW_LIST_UPDATED: "cow-list-updated",
    REQUEST_FOR_COW_LIST: "request-for-cow-list",
    DRY_COW_REPORTED: "dry-cow-reported",
    PREGNANCY_TRACKING_STARTED: "pregnancy-tracking-started",
    DRY_COW_CANCELED: "dry-cow-canceled",
    ESTROUS_TRACKING_STARTED: "estrous-tracking-started",
    TEMPERATURE_TRACKING_STARTED: "temperature-tracking-started",
    POTENTIAL_INSEMINATION_REPORTED: "potential-insemination-reported",
};

export const VIEW_EVENTS = {
    SESSION_CREATED: "session-created",
    SESSION_COMPLETED: "session-completed",
    SESSION_CANCELED: "session-canceled",
    FEVER_DETECTED: "fever-detected",
    FEVER_OVER: "fever-over",
    NOTE_SUBMITTED: "note-submitted",
    SMART_NOTE_SUBMITTED: "smart-note-submitted",
    FEVER_INCREASE_DETECTED: "fever-increase-detected",
    CALVING_DUE: "calving-due",
    CALVING_OVERDUE: "calving-overdue",
    CALVING_DUE_FALSE: "calving-due-false",
    CALVING_DETECTED: "calving-detected",
    CALVING_COMPLETED: "calving-completed",
    POTENTIAL_CALVINGS_DETECTED: "potential-calvings-detected",
    CALL_FAILED: "call-failed",
    CALLED_USER_ACTIVATED: "called-user-activated",
    HEAT_DETECTED: "heat-detected",
    HEAT_OVER: "heat-over",
    SENSOR_FALL_DETECTED: "sensor-fall-detected",
    POTENTIAL_POSTPARTUM_COWS_DETECTED: "potential-postpartum-cows-detected",
    POTENTIAL_ESTROUSES_DETECTED: "potential-estrouses-detected",
    POTENTIAL_DRY_COWS_DETECTED: "potential-dry-cows-detected",
};

// Tags
export const defaultTags = Symbol("defaultTags");
export const SENSOR = {
    IDLE: "idle",
    ASSIGNED: "assigned",
    ACTIVE: "active",
    INOPERATIVE: "inoperative",
    PENDING: "pending",
    STABLE: "stable",
    UNSTABLE: "unstable",
    LOW_BATTERY: "low-battery",
    NETWORK_PROBLEM: "network-problem",
    FAULT: "fault",
    EXCLUDED: "excluded",
    TRANSFER: "transfer",
    [defaultTags]: ["idle"],
};
export const COW = {
    UNTRACKED: "untracked",
    PENDING: "pending",
    TRACKED: "tracked",
    FEVER: "fever",
    HYPOTHERMIA: "hypothermia",
    PREGNANT: "pregnant",
    DUE: "due",
    OVERDUE: "overdue",
    CALVING: "calving",
    DEAD: "dead",
    ESTROUS: "estrous",
    HEAT: "heat",
    SENSOR_FALLEN: "sensor-fallen",
    POST_PARTUM: "post-partum",
    ESTROUS_TRACKING: "estrous-tracking",
    TEMPERATURE_TRACKING: "temperature-tracking",
    PREGNANCY_TRACKING: "pregnancy-tracking",
    INSEMINATED: "inseminated",
    DRY: "dry",
    POTENTIAL_INSEMINATION: "potential-insemination",
    [defaultTags]: ["untracked"],
};
export const SESSION = {
    PENDING: "pending",
    ACTIVE: "active",
    ARCHIVED: "archived",
    TRASHED: "trashed",
    HEALTH: "health",
    PREGNANCY: "pregnancy",
    ESTROUS: "estrous",
    [defaultTags]: ["pending"],
};
export const READSTATE = {
    HIDDEN: "hidden",
    UNREAD: "unread",
    READ: "read",
    MARKING_READ: "marking",
    MARKED_READ: "marked",
    FAILED_TO_MARK_READ: "failed",
    [defaultTags]: ["unread"],
};

export const REPEATER = {
    IDLE: "idle",
    ONLINE: "online",
    OFFLINE: "offline",
    [defaultTags]: ["idle"],
};

export const BASESTATION = {
    IDLE: "idle",
    ONLINE: "online",
    OFFLINE: "offline",
    [defaultTags]: ["idle"],
};

export const USER = {
    INVITED: "invited",
    DELETED: "deleted",
    REGISTERED: "registered",
    CALLED_USER: "called-user",
    [defaultTags]: ["registered"],
};

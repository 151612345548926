import React, { useState, useContext } from "react";
import cn from "classnames";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import Time from "../../Time";
import Button from "../../Button";
import NewSmartNotes from "../../NewSmartNote";
import {
    setPotentialList,
    resetPairingsFromExternal,
} from "../../../store/actions";
import { COW, EVENTS } from "../../../constants";
import { RouterContext } from "../../../containers/Contexts";
import ROUTES from "../../../constants/routes";

import { components, User } from "./titles";
import "./Event.css";

const Event = ({
    id,
    linkedEvent,
    isNotConfirmed,
    sensorType,
    eventViewType = "open",
    focused,
    event: { type, payload, timestamp, readState },
    element = "div",
    setPotentialList,
    setPotentialInseminationList,
    resetPairingsFromExternal,
    canConfirm,
    isFeverOverExist,
}) => {
    const { t } = useTranslation();
    const [actionType, setActionType] = useState(null);
    const domElementRegex = /^[a-z](-?[a-z0-9])*$/;
    const navigate = useContext(RouterContext);
    if (!domElementRegex.test(element)) {
        throw new Error("Invalid html tag");
    }

    if (!components[type]) {
        return null;
    }
    const Title = components[type];
    function onConfirmCalving() {
        setActionType("confirm");
    }
    function onAbortCalving() {
        setActionType("abort");
    }

    function openPotentialList(id, type) {
        resetPairingsFromExternal();
        setPotentialList(id, type);
        navigate.forward(ROUTES.POTENTIAL_LIST);
    }

    function openPotentialInseminationList(id, type) {
        resetPairingsFromExternal();
        setPotentialInseminationList(id);
        navigate.forward(ROUTES.POTENTIAL_INSEMINATION_LIST);
    }

    const feedbackDOM = [];
    if (linkedEvent) {
        switch (linkedEvent.payload.about) {
            case `${EVENTS.CALVING_DETECTED}::confirm`:
                feedbackDOM.push(
                    <Trans key={"1"} i18nKey="confirmed">
                        Confirmed at
                        <Time
                            key="time"
                            time={linkedEvent.timestamp}
                            options={{ hour: "2-digit", minute: "numeric" }}
                        />
                        by # <User key="user" id={linkedEvent.payload.user} />
                    </Trans>,
                );
                break;
            case `${EVENTS.CALVING_DETECTED}::abort`:
                feedbackDOM.push(
                    <Trans key={"2"} i18nKey="aborted">
                        Aborted at
                        <Time
                            key="time"
                            time={linkedEvent.timestamp}
                            options={{ hour: "2-digit", minute: "numeric" }}
                        />
                        by #<User key="user" id={linkedEvent.payload.user} />
                    </Trans>,
                );
                break;
            case `${EVENTS.FEVER_OVER}::treatment`:
                feedbackDOM.push(
                    <Trans key={"3"} i18nKey="feverOverTreatment">
                        Aborted at
                        <Time
                            key="time"
                            time={linkedEvent.timestamp}
                            options={{ hour: "2-digit", minute: "numeric" }}
                        />
                        by #<User key="user" id={linkedEvent.payload.user} />
                    </Trans>,
                );
                break;
            case `${EVENTS.HEAT_DETECTED}::confirm`:
                feedbackDOM.push(
                    <Trans key={"4"} i18nKey="heatConfirm">
                        Confirmed at
                        <Time
                            key="time"
                            time={linkedEvent.timestamp}
                            options={{ hour: "2-digit", minute: "numeric" }}
                        />
                        by #<User key="user" id={linkedEvent.payload.user} />
                    </Trans>,
                );
                break;
        }
    } else {
        switch (type) {
            case EVENTS.CALVING_DETECTED:
                feedbackDOM.push(
                    <Button
                        key={"4"}
                        className="action confirm"
                        text={t("confirmCalving")}
                        onClicked={onConfirmCalving}
                        privilige={canConfirm}
                    />,
                );
                feedbackDOM.push(
                    <Button
                        key={"5"}
                        className="action abort"
                        text={t("abortCalving")}
                        onClicked={onAbortCalving}
                        privilige={canConfirm}
                    />,
                );
                break;
            case EVENTS.POTENTIAL_CALVINGS_DETECTED:
                feedbackDOM.push(
                    <Button
                        key={"6"}
                        className="action confirm"
                        text={t("seeCow", { count: payload.cows.length })}
                        onClicked={() => openPotentialList(id, "calving")}
                        privilige={canConfirm}
                    />,
                );
                break;
            case EVENTS.POTENTIAL_POSTPARTUM_COWS_DETECTED:
                feedbackDOM.push(
                    <Button
                        key={"7"}
                        className="action confirm"
                        text={t("seeCow", { count: payload.cows.length })}
                        onClicked={() => openPotentialList(id, "postpartum")}
                        privilige={canConfirm}
                    />,
                );
                break;
            case EVENTS.POTENTIAL_ESTROUSES_DETECTED:
                feedbackDOM.push(
                    <Button
                        key={"8"}
                        className="action confirm"
                        text={t("seeCow", { count: payload.cows.length })}
                        onClicked={() => openPotentialList(id, "estrous")}
                        privilige={canConfirm}
                    />,
                );
                break;
            case EVENTS.POTENTIAL_DRY_COWS_DETECTED:
                feedbackDOM.push(
                    <Button
                        key={"9"}
                        className="action confirm"
                        text={t("seeCow", { count: payload.cows.length })}
                        onClicked={() => openPotentialList(id, "dry")}
                        privilige={canConfirm}
                    />,
                );
                break;
            case EVENTS.SENSOR_FALL_DETECTED:
                if (payload.tags.cow.includes(COW.ESTROUS_TRACKING)) {
                    feedbackDOM.push(
                        <Button
                            key={"10"}
                            className="action confirm"
                            text={t("resolve")}
                            onClicked={() => setActionType("heat")}
                            privilige={canConfirm}
                        />,
                    );
                } else if (
                    payload.tags.cow.includes(COW.TEMPERATURE_TRACKING)
                ) {
                    feedbackDOM.push(
                        <Button
                            key={"11"}
                            className="action confirm"
                            text={t("resolve")}
                            onClicked={() => setActionType("health")}
                            privilige={canConfirm}
                        />,
                    );
                }
                break;
            case EVENTS.FEVER_OVER:
                if (isFeverOverExist === false) {
                    feedbackDOM.push(
                        <Button
                            key={"12"}
                            className="action confirm"
                            text={t("resolve")}
                            onClicked={() => setActionType("treatment")}
                            privilige={canConfirm}
                        />,
                    );
                }
                break;
            case EVENTS.HEAT_DETECTED:
                feedbackDOM.push(
                    <Button
                        key={"13"}
                        className="action confirm"
                        text={t("resolve")}
                        onClicked={() => setActionType("confirm")}
                        privilige={canConfirm}
                    />,
                );
                break;
            case EVENTS.POTENTIAL_INSEMINATION_DETECTED:
                feedbackDOM.push(
                    <Button
                        key={"14"}
                        className="action confirm"
                        text={t("seeCow", { count: payload.cows.length })}
                        onClicked={() => openPotentialInseminationList(id)}
                        privilige={canConfirm}
                    />,
                );
                break;
        }
    }
    let SmartNote = <></>;
    if (!linkedEvent && actionType) {
        SmartNote = NewSmartNotes[`${type}::${actionType}`];
    }
    let SmartNoteResult = null;
    if (type === "smart-note-submitted") {
        if (payload.eventVersion === "2") {
            if (focused) {
                SmartNote = NewSmartNotes[payload.about];
                SmartNoteResult = (
                    <SmartNote
                        eventId={id}
                        about={payload.about}
                        parentEventTagItems={{
                            ...getTagItemIds(payload),
                            eventId: id,
                        }}
                        toogleSmartNote={() => setActionType(null)}
                        defaults={payload.package}
                    />
                );
            }
        }
    }

    switch (eventViewType) {
        case "open":
            return React.createElement(
                element,
                {
                    key: id,
                    className: `event ${type} ${readState} ${cn(
                        { smart: actionType != null && !linkedEvent },
                        id,
                        sensorType,
                    )}`,
                },
                <React.Fragment>
                    <h6>
                        <Title payload={payload} />
                    </h6>
                    {SmartNoteResult}
                    <Time time={timestamp} />
                    {/* <MarkReadButton list='events' id={id}/> */}
                    {!linkedEvent && actionType ? (
                        <SmartNote
                            key={id}
                            eventId={id}
                            about={`${type}::${actionType}`}
                            parentEventTagItems={{
                                ...getTagItemIds(payload),
                                eventId: id,
                            }}
                            toogleSmartNote={() => setActionType(null)}
                        />
                    ) : linkedEvent ? (
                        <span className="confirmed"> {feedbackDOM} </span>
                    ) : isNotConfirmed ? (
                        <React.Fragment>
                            <div className="action-buttons">{feedbackDOM}</div>
                        </React.Fragment>
                    ) : null}
                </React.Fragment>,
            );
        case "halfOpen":
            return React.createElement(
                element,
                {
                    key: id,
                    className: `event ${type} ${readState} ${cn(
                        id,
                        sensorType,
                        "half-open",
                    )}`,
                },
                <React.Fragment>
                    <h6>
                        <Title payload={payload} isHalfOpen />
                    </h6>
                    <Time
                        time={timestamp}
                        options={{
                            day: "numeric",
                            month: "short",
                            hour: "2-digit",
                            minute: "numeric",
                        }}
                    />
                    {/* <MarkReadButton list='events' id={id}/> */}
                </React.Fragment>,
            );
        case "close":
            return React.createElement(
                element,
                {
                    key: id,
                    className: `event ${type} ${readState} ${sensorType} close`,
                },
                null,
            );
        case "dashed":
            return React.createElement(
                element,
                {
                    key: id,
                    className: `event ${readState} ${sensorType} dashed`,
                },
                <h6 />,
            );
    }
};

function mapStateToProps(
    { events, tags, sensors, priviliges, eventLinks },
    { id, linkedEvent },
) {
    function isNotConfirmed() {
        switch (events[id].type) {
            case EVENTS.CALVING_DETECTED:
                return tags.cow[events[id].payload.cow]
                    .map((tag) => tag.value)
                    .includes(COW.CALVING);
            case EVENTS.POTENTIAL_CALVINGS_DETECTED:
            case EVENTS.POTENTIAL_POSTPARTUM_COWS_DETECTED:
            case EVENTS.POTENTIAL_ESTROUSES_DETECTED:
            case EVENTS.POTENTIAL_DRY_COWS_DETECTED:
            case EVENTS.FEVER_OVER:
            case EVENTS.HEAT_DETECTED:
                return true;
            case EVENTS.SENSOR_FALL_DETECTED:
                return tags.cow[events[id].payload.cow]
                    .map((tag) => tag.value)
                    .includes(COW.SENSOR_FALLEN);
            default:
                return false;
        }
    }
    const a = detectLinkedEvent(events[id], events, eventLinks);
    // console.log(a);
    return {
        event: events[id],
        linkedEvent: events[linkedEvent],
        // linkedEvent: detectLinkedEvent(events[id], events, eventLinks),
        isNotConfirmed: isNotConfirmed(),
        sensorType: events[id].payload.sensor
            ? sensors[events[id].payload.sensor].type
            : null,
        canConfirm: priviliges.CONFIRMATION_ENABLED.value,
    };
}

const mapDispatchToProps = {
    setPotentialList,
    resetPairingsFromExternal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Event);

function getTagItemIds(payload) {
    const tagItems = ["cow", "session", "session"];
    return Object.keys(payload).reduce((acc, key) => {
        if (tagItems.includes(key)) return { ...acc, [key]: payload[key] };
        return acc;
    }, {});
}

function detectLinkedEvent(event, events, eventLinks) {
    // debugger;
    if (eventLinks[event.id]) {
        return eventLinks[event.id];
    }
    const cowFeverOverEvents = Object.keys(eventLinks).reduce((acc, id) => {
        if (events[id].type === EVENTS.FEVER_OVER) {
            return { ...acc, [events[id].payload.cow]: id };
        }
        return acc;
    }, {});
    if (event.type === EVENTS.FEVER_OVER) {
        if (cowFeverOverEvents[event.payload.cow] > event.id) {
            return eventLinks[cowFeverOverEvents[event.payload.cow]];
        }
    }
    return false;
}

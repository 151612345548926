import React, { useState, useRef } from "react";
import cn from "classnames";

import "./TooltipCommon.css";

export default function TooltipCommon({
    children,
    content,
    position = "right",
    visible,
    autoClose = true,
}) {
    const [isOpen, seIsOpen] = useState(visible);
    const tooltipRef = useRef(null);
    const contentRef = useRef(null);

    const p = getSvgCoordinates(position);
    const childrenWithNewProps = React.cloneElement(children, {
        onClick: onClicked,
        className: `${children.props.className} parent`,
    });
    function onClickOutside(event) {
        if (autoClose) seIsOpen(false);
        if (tooltipRef.current && !contentRef.current.contains(event.target)) {
            seIsOpen(false);
            document.removeEventListener("click", onClickOutside, true);
        }
    }

    function onClicked(event) {
        event.stopPropagation();
        const { target } = event;
        // const parent = target.closest(".parent");
        const parent = target.closest(".parent");
        const dimensions = parent.getBoundingClientRect();
        const tooltipDimensions = tooltipRef.current
            .querySelector(".tooltip-common")
            .getBoundingClientRect();
        switch (position) {
            default:
            case "bottom":
                tooltipRef.current.style["margin-left"] = `${
                    dimensions.width / 2 - tooltipDimensions.width / 2
                }px`;
                // tooltipRef.current.style.top = `${- ((tooltipDimensions.height - dimensions.height*2-20))}px`;
                break;
            case "right":
                tooltipRef.current.style.top = `${
                    parent.offsetTop -
                    (tooltipDimensions.height - dimensions.height) / 2
                }px`;
                tooltipRef.current.style.left = `${
                    parent.offsetLeft + dimensions.width
                }px`;
                break;
            case "left":
                tooltipRef.current.style.top = `${
                    parent.offsetTop -
                    (tooltipDimensions.height - dimensions.height) / 2
                }px`;
                tooltipRef.current.style.left = `${
                    parent.offsetLeft - dimensions.width * 2
                }px`;
        }
        seIsOpen(!isOpen);
        if (!isOpen === true) {
            document.addEventListener("click", onClickOutside, true);
        }
    }

    return (
        <React.Fragment>
            {childrenWithNewProps}
            <div
                className={cn("tooltip-wrapper", position, { visible: isOpen })}
                ref={tooltipRef}>
                <div className="tooltip-common">
                    <div className="indicator">
                        <div className="start">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="none"
                                viewBox="0 0 10 10">
                                <path d={p.start} />
                            </svg>
                        </div>
                        <div className="middle">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="none"
                                viewBox="0 0 10 10">
                                <path d={p.middle} />
                            </svg>
                        </div>
                        <div className="end">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="none"
                                viewBox="0 0 10 10">
                                <path d={p.end} />
                            </svg>
                        </div>
                    </div>
                    <div className="content" ref={contentRef}>
                        {content}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function getSvgCoordinates(position) {
    const p = { start: "", middle: "", end: "" };
    switch (position) {
        case "right":
            p.start = "M10 0 L10 10";
            p.middle = "M10 0 L0 5 L10 10";
            p.end = "M10 0 L10 10";
            break;
        case "left":
            p.start = "M0 0 L0 10";
            p.middle = "M0 0 L10 5 L0 10";
            p.end = "M0 0 L0 10";
            break;
        case "up":
            p.start = "M0 0 L10 0";
            p.middle = "M0 0 L5 10 L10 0";
            p.end = "M0 0 L10 0";
            break;
        default:
        case "bottom":
            p.start = "M0 10 L10 10";
            p.middle = "M0 10 L5 1 L10 10";
            p.end = "M0 10 L10 10";
            break;
    }
    return p;
}

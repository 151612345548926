import { connect } from "react-redux";

import { togglePreviewAutomation } from "../store/actions";

function mapStateToProps({ preview, automation }, { id }) {
    return { preview, placePairing: automation[id] };
}

const mapDispatchToProps = {
    onToggle: (id) => togglePreviewAutomation("automation", id),
};

export default connect(mapStateToProps, mapDispatchToProps);

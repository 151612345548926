import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import AutosizeInput from "react-input-autosize";

import { SearchResults } from "../SearchResults";
import connect from "../../containers/CowFilter";
import { useDebounce } from "../hooks";

import { components } from "./tagTypes";

import "./CowFilter.css";

const CowFilter = ({
    filterTags,
    queries,
    addQuery,
    dropQuery,
    onSort,
    sortOrder,
    selectedSort,
    setSearchText,
}) => {
    const { t } = useTranslation();
    const [newQuery, setNewQuery] = useState([]);
    const [autoCompleteResults, setAutoCompleteResults] = useState(filterTags);
    const [showMobileTagOptions, setShowMobileTagOptions] = useState(false);

    const [freeSearchText, setFreeSearchText] = useState(null);
    const freeSearchDebounced = useDebounce(freeSearchText, 500);

    const [newInputValue, setNewInputValue] = useState("");

    useEffect(() => {
        if (freeSearchDebounced != null) setSearchText(freeSearchDebounced);
    }, [freeSearchDebounced, setSearchText]);

    function onChanged(event) {
        setNewInputValue(event.target.value);
        const normalized = event.target.value.trim().toLowerCase();
        setFreeSearchText(normalized);
    }

    function onInput(event) {
        setShowMobileTagOptions(false);
        if (event.key == "Backspace") {
            if (event.target.value == "") {
                if (newQuery.length > 0) {
                    setNewQuery([]);
                    return;
                }
                dropQuery(queries.length - 1);
            }
        }
    }

    function onItemSelected(result, event) {
        // setFreeSearchText("");
        if (result.type == "tagParam") {
            const lastQuery = queries[queries.length - 1];
            dropQuery(queries.length - 1);
            addQuery([...lastQuery, result]);
            setNewInputValue("");
            setShowMobileTagOptions(false);
            setAutoCompleteResults(filterTags);
            return;
        }

        if (result.params) {
            setAutoCompleteResults(result.params);
        } else {
            setAutoCompleteResults(filterTags);
            setShowMobileTagOptions(false);
        }
        addQuery([result]);
        setNewInputValue("");
        document.querySelector("div.query.new input").focus();
    }

    function onParamInputChanged(event, tag) {
        switch (tag.kind) {
            case "inRange":
                if (event.target.classList.contains("start"))
                    tag.props = { ...tag.props, start: event.target.value };
                if (event.target.classList.contains("end"))
                    tag.props = { ...tag.props, end: event.target.value };
                updatetagParam(queries, tag);
                return;
            case "higher":
            case "smaller":
                if (event.target.classList.contains("value"))
                    tag.props = { ...tag.props, value: event.target.value };
                updatetagParam(queries, tag);
                return;
        }
    }

    function updatetagParam(queries, tag) {
        const lastQuery = queries[queries.length - 1];
        dropQuery(queries.length - 1);
        const updatedQuery = lastQuery.reduce((acc, q) => {
            if (q.type == "tagParam" && q.parent == tag.parent) {
                return [...acc, tag];
            }
            return [...acc, q];
        }, []);
        addQuery(updatedQuery);
        return true;
    }

    function renderListElement(tag) {
        switch (tag.type) {
            case "tag":
            default:
                return React.createElement(components.tag, tag);
            case "logic":
                return React.createElement(components.logic, tag);
            case "tagParam":
                tag.props.readOnly = true;
                return React.createElement(components[tag.kind], tag);
            case "sort":
                return React.createElement(components.sort, tag);
            case "command":
                return <p>{tag.props.value}.</p>;
        }
    }

    function onRemoveTagClicked(event, tag, isNewQuery, queriesIndex) {
        if (isNewQuery) {
            setNewQuery([]);
            return;
        }
        dropQuery(queriesIndex);
        setAutoCompleteResults(filterTags);
    }

    function onSorted(event, tag) {
        onSort(tag[tag.type], selectedSort, sortOrder);
    }

    const newQueryDOM = newQuery.map((tag) => {
        const tagComponent = getTagComponent(components, tag);
        tag.props.onRemoveClicked = (event) =>
            onRemoveTagClicked(event, tag, true, null);
        tag.props.isFocused = false;
        tag.props.readOnly = true;
        return React.createElement(tagComponent, tag);
    });
    const queriesDOM = queries.map((query, index) => {
        const children = query.map((tag) => {
            tag.props.onRemoveClicked = (event) =>
                onRemoveTagClicked(event, tag, false, index);
            if (tag.sortBy) {
                tag.props.sortOrder = sortOrder;
                tag.props.onSorted = (event) =>
                    onSorted(event, tag, tag.sortBy);
            }
            const tagComponent = getTagComponent(components, tag);
            if (tag.type == "tagParam") {
                tag.props.readOnly = false;
                tag.props.isFocused =
                    query[query.length - 1].type == "tagParam";
                tag.props.onChanged = (event) =>
                    onParamInputChanged(event, tag);
            }
            return React.createElement(tagComponent, tag);
        });
        return React.createElement(
            "div",
            {
                className: cn({
                    query: true,
                    "single-element": children.length == 1,
                }),
            },
            children,
        );
    });
    function onNewInputClicked({ target }) {
        const input = target.querySelector(".new-input");
        if (input) {
            target.querySelector(".new-input input").focus();
        }
    }

    return (
        <React.Fragment>
            <div
                className={cn({
                    "filter-box": true,
                    open: showMobileTagOptions,
                })}
                onClick={onNewInputClicked}
                onTouchStart={onNewInputClicked}>
                {queriesDOM}
                <div className="query new">
                    {newQueryDOM}
                    <AutosizeInput
                        className="new-input"
                        placeholder={cn({
                            [t("filterAndSearch")]:
                                queries.length == 0 && newQuery.length == 0,
                        })}
                        value={newInputValue}
                        onKeyDown={onInput}
                        onChange={onChanged}
                    />
                </div>
                <i
                    className={cn({
                        "filter-icon": true,
                        selected: showMobileTagOptions,
                    })}
                    onClick={(event) => {
                        event.stopPropagation();
                        if (event.target.classList.contains("selected")) {
                            setShowMobileTagOptions(false);
                            setAutoCompleteResults(filterTags);
                            return;
                        }
                        setShowMobileTagOptions(!showMobileTagOptions);
                    }}
                />
            </div>
            {showMobileTagOptions ? (
                <SearchResults
                    classNames={["filter-results"]}
                    results={autoCompleteResults}
                    render={renderListElement}
                    onSelect={onItemSelected}
                    isCategorizedResults={true}
                />
            ) : null}
        </React.Fragment>
    );
};
function getTagComponent(components, tag) {
    let tagComponent;
    if (tag.type == "tagParam") {
        tagComponent = components[tag.kind];
    } else {
        tagComponent = components[tag.type];
    }
    return tagComponent;
}
export default connect(CowFilter);

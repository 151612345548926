import { put, call, take, spawn } from "redux-saga/effects";
import axios from "axios";
import {
    SET_METRIC_RANGE,
    START_SET_METRIC_RANGE,
    COMPLETE_SET_METRIC_RANGE,
    FAIL_SET_METRIC_RANGE,
    FAIL_CALVING_REPORT
} from "../actions/actionTypes";


export function *getMetrics(startDate, endDate){
    yield put({ type: START_SET_METRIC_RANGE });
    if (!startDate){
        const now = new Date();
        now.setUTCMonth(1);
        now.setUTCDate(1);
        now.setUTCHours(0, 0, 1);
        startDate = now.getTime();
        endDate = new Date().getTime();
    }
    try {
        const { data } = yield call(axios.get, `/metrics?start=${startDate}&end=${endDate}`);
        if (data.calfGender.error) {
            yield put({
                type: FAIL_CALVING_REPORT,
                error: data.calfGender.error
            });
        } else {
            yield put({
                type: COMPLETE_SET_METRIC_RANGE,
                ...data
            });
        }
    } catch (error){
        yield put({ type: FAIL_SET_METRIC_RANGE });
    }
    const { start, end } = yield take(SET_METRIC_RANGE);
    yield spawn(getMetrics, start, end);
}
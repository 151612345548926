import { READSTATE } from "../../constants";
import {
    MARK_READ,
    COMPLETE_MARK_READ_REQUEST,
    FAIL_MARK_READ_REQUEST,
    COMPLETE_SUBMIT_NOTE_REQUEST,
} from "../actions/actionTypes";
import { toList } from "./utilities";

const {
    MARKING_READ,
    MARKED_READ,
    FAILED_TO_MARK_READ
} = READSTATE;


export default function (events = {}, action) {
    switch (action.type) {

    case MARK_READ:
        events[action.id].readState = MARKING_READ;
        return { ...events };

    case COMPLETE_MARK_READ_REQUEST:
        for (const mark of action.mark) {
            events[mark].readState = FAILED_TO_MARK_READ;
        }
        for (const mark of action.data.mark) {
            events[mark].readState = MARKED_READ;
        }
        return { ...events };

    case FAIL_MARK_READ_REQUEST:
        for (const mark of action.mark) {
            events[mark].readState = FAILED_TO_MARK_READ;
        }
        return { ...events };

    case COMPLETE_SUBMIT_NOTE_REQUEST:
        return { ...events, [action.event.id]: action.event };

    default:
        if (action &&
            action.data &&
            action.data.events &&
            Array.isArray(action.data.events)
        ){
            return { ...events, ...toList(action.data.events) };
        } else { return events; }
    }
}

import {
    SET_SHORTCUT_BUTTON
} from "../../actions/actionTypes";

export default function (state = null, action) {
    switch (action.type) {
    case SET_SHORTCUT_BUTTON:
        return state = action.button;
    default: return state;
    }
}
import React from "react";
import "./Cow.css";
import cn from "classnames";

import {
    cow as connect,
    sensorConnect,
    tagsFor,
    preview,
} from "../../../containers";
import { COW as TAGS } from "../../../constants";
import CowName from "../../CowName";
import Badge from "../../Badge";

const Cow = ({
    cow: { id, farmNumber, name },
    preview,
    sensors,
    tags = [TAGS.UNTRACKED],
    onToggle,
}) => {
    const previewIsActive =
        preview && preview.kind === "cow" && id === preview.id;
    const cowName = name.length > 8 ? name.substring(0, 7) + "..." : name;
    return (
        <article
            className={cn(
                "cow",
                tags,
                sensors.map((s) => s.type),
                { preview, active: previewIsActive },
            )}
            onClick={onToggle}>
            <h4>
                {farmNumber.length > 4
                    ? String(farmNumber).slice(-4)
                    : farmNumber}
            </h4>
            <h5>
                <CowName>{cowName}</CowName>
            </h5>
            <div className="badge">
                <Badge
                    cowId={id}
                    sensorIds={sensors.map((s) => ({
                        sensor: s.id,
                        session: s.session,
                    }))}
                />
            </div>
        </article>
    );
};
export default preview("cow")(
    sensorConnect(connect(tagsFor("cow", "tags")(Cow))),
);

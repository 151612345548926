import i18n from "../../i18n";
export const columnCount = 12;
export const minV = 2;

export function detectFotmat(metrics) {
    const metricCount = Object.keys(metrics).length;
    switch (metricCount) {
        case 1:
        default:
            return "oneRowOneMetric";
        case 2:
            return "oneRowTwoMetrics";
        case 3:
            const results = compareFormats(
                ["twoRowsThreeMetrics", "threeRowsThreeMetrics"],
                metrics,
            );
            return Object.keys(results).reduce((k, f) =>
                results[f] < results[k] ? f : k,
            );
        case 4:
            return "fourRowsFourMetrics";
    }
}

export const formats = {
    twoRowsThreeMetrics: {
        metricCount: 3,
        rowCount: 2,
        columnCount: 12,
        cellCount: 24,
        vSeperator: { start: 1, end: 13 },
        hSeperator: { start: 1, end: 3 },
        cellWeight(metrics) {
            return (
                Object.values(metrics).reduce((acc, v) => acc + v, 0) /
                this.cellCount
            );
        },
        deviation(metrics) {
            const seperators = this.seperators(metrics);
            const sortedKeys = Object.keys(metrics).sort(
                (a, b) => metrics[b] - metrics[a],
            );
            return sortedKeys.reduce((acc, k, i) => {
                const cellWeight = this.cellWeight(metrics);
                if (i === 0)
                    return (
                        acc +
                        Math.abs(
                            (seperators.v[0] - this.vSeperator.start) *
                                cellWeight *
                                this.rowCount -
                                metrics[k],
                        )
                    );
                if (i === 1 || i === 2)
                    return (
                        acc +
                        Math.abs(
                            (this.vSeperator.end - seperators.v[0]) *
                                cellWeight -
                                metrics[k],
                        )
                    );
                return acc;
            }, 0);
        },
        seperators(metrics) {
            const sortedKeys = Object.keys(metrics).sort(
                (a, b) => metrics[b] - metrics[a],
            );
            const vRate =
                metrics[sortedKeys[0]] /
                (metrics[sortedKeys[1]] + metrics[sortedKeys[2]]);
            let v = Math.ceil((columnCount * vRate) / (vRate + 1)) + 1;
            v = v < minV ? minV : v;
            v = v > columnCount - minV ? columnCount - minV : v;
            return { v: [v] };
        },
    },
    threeRowsThreeMetrics: {
        metricCount: 3,
        rowCount: 3,
        columnCount: 12,
        cellCount: 36,
        vSeperator: { start: 1, end: 13 },
        hSeperator: { start: 1, end: 4 },
        cellWeight(metrics) {
            return (
                Object.values(metrics).reduce((acc, v) => acc + v, 0) /
                this.cellCount
            );
        },
        deviation(metrics) {
            const seperators = this.seperators(metrics);
            const sortedKeys = Object.keys(metrics).sort(
                (a, b) => metrics[b] - metrics[a],
            );
            return sortedKeys.reduce((acc, k, i) => {
                const cellWeight = this.cellWeight(metrics);
                if (i === 0)
                    return (
                        acc +
                        Math.abs(
                            (seperators.h[0] - this.hSeperator.start) *
                                this.columnCount *
                                cellWeight -
                                metrics[k],
                        )
                    );
                if (i === 1)
                    return (
                        acc +
                        Math.abs(
                            (seperators.v[0] - this.vSeperator.start) *
                                cellWeight -
                                metrics[k],
                        )
                    );
                if (i === 2)
                    return (
                        acc +
                        Math.abs(
                            (this.vSeperator.end - seperators.v[0]) *
                                cellWeight -
                                metrics[k],
                        )
                    );
                return acc;
            }, 0);
        },
        seperators(metrics) {
            const sortedKeys = Object.keys(metrics).sort(
                (a, b) => metrics[b] - metrics[a],
            );
            const vRate = metrics[sortedKeys[1]] / metrics[sortedKeys[2]];
            const hRate =
                metrics[sortedKeys[0]] /
                (metrics[sortedKeys[1]] + metrics[sortedKeys[2]]);
            let v = Math.ceil((columnCount * vRate) / (vRate + 1)) + 1;
            let h = Math.ceil((this.rowCount * hRate) / (hRate + 1)) + 1;
            v = v < minV ? minV : v;
            v = v > columnCount - minV ? columnCount - minV : v;

            h = h < minV ? minV : h;
            h = h > 2 ? 3 : h;
            return { v: [v], h: [h] };
        },
    },
    fourRowsFourMetrics: {
        metricCount: 4,
        rowCount: 4,
        columnCount: 12,
        cellCount: 48,
        vSeperator: { start: 1, end: 13 },
        hSeperator: { start: 1, end: 5 },
        cellWeight(metrics) {
            return (
                Object.values(metrics).reduce((acc, v) => acc + v, 0) /
                this.cellCount
            );
        },
        deviation(metrics) {
            const seperators = this.seperators(metrics);
            const sortedKeys = Object.keys(metrics).sort(
                (a, b) => metrics[b] - metrics[a],
            );
            return sortedKeys.reduce((acc, k, i) => {
                const cellWeight = this.cellWeight(metrics);
                if (i === 0)
                    return (
                        acc +
                        Math.abs(
                            (seperators.v[0] - this.vSeperator.start) *
                                cellWeight *
                                this.rowCount -
                                metrics[k],
                        )
                    );
                if (i === 1)
                    return (
                        acc +
                        Math.abs(
                            (seperators.h[0] - this.hSeperator.start) *
                                (this.vSeperator.end - seperators.v[0]) *
                                cellWeight -
                                metrics[k],
                        )
                    );
                if (i === 2)
                    return (
                        acc +
                        Math.abs(
                            (seperators.h[2] - seperators.h[1]) *
                                (this.vSeperator.end - seperators.v[0]) *
                                cellWeight -
                                metrics[k],
                        )
                    );
                if (i === 3)
                    return (
                        acc +
                        Math.abs(
                            (this.hSeperator - seperators.h[1]) *
                                (this.vSeperator.end - seperators.v[0]) *
                                cellWeight -
                                metrics[k],
                        )
                    );
                return acc;
            }, 0);
        },
        seperators(metrics) {
            const sortedKeys = Object.keys(metrics).sort(
                (a, b) => metrics[b] - metrics[a],
            );
            const vRate =
                metrics[sortedKeys[0]] /
                (metrics[sortedKeys[1]] +
                    metrics[sortedKeys[2]] +
                    metrics[sortedKeys[3]]);
            const hRate =
                metrics[sortedKeys[1]] /
                (metrics[sortedKeys[2]] + metrics[sortedKeys[3]]);
            const h2Rate =
                (metrics[sortedKeys[1]] + metrics[sortedKeys[2]]) /
                metrics[sortedKeys[3]];
            let v = Math.ceil((columnCount * vRate) / (vRate + 1)) + 1;
            let h = Math.ceil((this.rowCount * hRate) / (hRate + 1)) + 1;
            let h2 = Math.ceil((this.rowCount * h2Rate) / (h2Rate + 1)) + 1;

            v = v < minV ? minV : v;
            v = v > columnCount - minV ? columnCount - minV : v;
            h = h < minV ? minV : h;
            h = h > 2 ? 3 : h;

            h2 = h2 > 4 ? 4 : h2;
            return { v: [v], h: [h, h2] };
        },
    },
    oneRowTwoMetrics: {
        metricCount: 4,
        rowCount: 1,
        columnCount: 12,
        cellCount: 12,
        vSeperator: { start: 1, end: 13 },
        hSeperator: { start: 1, end: 2 },
        cellWeight(metrics) {
            return (
                Object.values(metrics).reduce((acc, v) => acc + v, 0) /
                this.cellCount
            );
        },
        deviation(metrics) {
            const seperators = this.seperators(metrics);
            const sortedKeys = Object.keys(metrics).sort(
                (a, b) => metrics[b] - metrics[a],
            );
            return sortedKeys.reduce((acc, k, i) => {
                const cellWeight = this.cellWeight(metrics);
                if (i === 0)
                    return (
                        acc +
                        Math.abs(
                            (seperators.v[0] - this.vSeperator.start) *
                                cellWeight *
                                this.rowCount -
                                metrics[k],
                        )
                    );
                if (i === 1)
                    return (
                        acc +
                        Math.abs(
                            (this.hSeperator.end - seperators.v[0]) *
                                cellWeight *
                                this.rowCount -
                                metrics[k],
                        )
                    );
                return acc;
            }, 0);
        },
        seperators(metrics) {
            const sortedKeys = Object.keys(metrics).sort(
                (a, b) => metrics[b] - metrics[a],
            );
            const vRate = metrics[sortedKeys[0]] / metrics[sortedKeys[1]];
            let v = Math.ceil((columnCount * vRate) / (vRate + 1)) + 1;
            v = v < minV ? minV : v;
            v = v > columnCount - minV ? columnCount - minV : v;
            return { v: [v] };
        },
    },
    oneRowOneMetric: {
        metricCount: 1,
        rowCount: 1,
        columnCount: 12,
        cellCount: 12,
        vSeperator: { start: 1, end: 13 },
        hSeperator: { start: 1, end: 1 },
        seperators() {
            return { v: [13] };
        },
    },
};

function compareFormats(formatArr, metrics) {
    return formatArr.reduce((acc, f) => {
        return { ...acc, [f]: formats[f].deviation(metrics) };
    }, {});
}

export const metricTexts = {
    female: i18n.t("female"),
    male: i18n.t("male"),
    unknown: i18n.t("unknown"),
    healthy: i18n.t("healthy"),
    unhealthy: i18n.t("unhealthy"),
    dead: i18n.t("dead"),
    easy: i18n.t("easy"),
    assisted: i18n.t("assisted"),
    difficult: i18n.t("difficult"),
    normal: i18n.t("normal"),
    perfect: i18n.t("perfect"),
    eventual: i18n.t("eventual"),
    false: i18n.t("false"),
    missed: i18n.t("missed"),
    resolved: i18n.t("resolved"),
    canceled: i18n.t("canceled"),
    interrupted: i18n.t("interrupted"),
    fever: i18n.t("fever"),
    healthScore: "",
};

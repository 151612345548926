import { EVENTS, COW, SENSOR, SESSION, USER } from "../../constants/schema";
import { standaloneEvents } from "./standaloneEvents";
import tagSession from "./tags/tagSession";
import tagCow from "./tags/tagCow";
import tagSensor from "./tags/tagSensor";
import tagBasestation from "./tags/tagBasestation";
import { cowData } from "./routes/mockData";
import { handleEvent } from "./routes/events";
function dateDiffFromToday(timestamp) {
    return parseInt(
        (new Date().getTime() - timestamp) / (24 * 60 * 60 * 1000),
        10,
    );
}

function timestampFix(obj) {
    const re = new RegExp(/\d{10}/g);
    Object.keys(obj).forEach((prop) => {
        if (re.test(obj[prop])) {
            obj[prop] = obj[prop] * 1000;
        }
    });
    return obj;
}

function breedTypeTransformator(breed) {
    switch (breed) {
        case "1":
            return "Holstein";
        case "2":
            return "Montafon";
        case "3":
            return "Simental";
        case "4":
            return "Friz";
        case "5":
            return "Jersey";
        case "6":
            return "Angus";
        default:
            return breed;
    }
}

export function newCowAcquiredEvent(db, cowProps, userId, time) {
    const events = [];
    const user = db.users.find(userId);

    cowProps = timestampFix(cowProps);
    cowProps.breed = breedTypeTransformator(cowProps.breed);

    if (!cowProps.lastBreedingDate) cowProps.lastBreedingDate = 0;
    if (!cowProps.lastCalvingDate) cowProps.lastCalvingDate = 0;
    if (!cowProps.lactationCount) cowProps.lactationCount = 0;

    const cowTags = [COW.UNTRACKED];
    const cow = db.cows.insert({ ...cowProps, tags: cowTags });
    events.push(
        db.events.insert({
            type: EVENTS.NEW_COW_ACQUIRED,
            timestamp: time || new Date().getTime(),
            payload: {
                cow: cow.id,
                user: user.id,
                tags: { cow: cowTags },
            },
        }),
    );
    if (cowProps.isPregnant) {
        // Add pregnant
        events.push(pregnancyDetectedEvent(db, cow.id, time + 1000)[0]);

        if (
            cowProps.lastBreedingDate > 0 &&
            dateDiffFromToday(cowProps.lastBreedingDate) >= 220
        ) {
            // Last breeding date is over 220 days
            // Add dry
            events.push(
                processStandaloneEvent(
                    db,
                    {
                        event: "dry-cow-reported",
                        payload: { cow: cow.id },
                        timestamp: time + 2000,
                    },
                    1,
                )[0],
            );
        }
    } else {
        if (
            cowProps.lastCalvingDate > 0 &&
            dateDiffFromToday(cowProps.lastCalvingDate) <= 7
        ) {
            // Last calving date is less than 7 days
            // Add post-partum
            events.push(pregnancyOverEvent(db, cow.id, time + 1000)[0]);
        } else if (cowProps.lastCalvingDate === 0) {
            // Add estrous
            events.push(estrousCycleDetectedEvent(db, cow.id, time + 1000)[0]);
        }
    }
    return events;
}

export function pairingListCreatedEvent(db, list, userId, initCows, time) {
    const events = [];
    const user = db.users.find(userId);
    const pairingList = db.pairingLists.insert({});

    const sessions = db.sessions.insert(
        list.map((l) => {
            return {
                ...l,
                pairingList: pairingList.id,
                tags: [],
            };
        }),
    );

    db.pairingLists.update(pairingList.id, {
        sessions: sessions.map((s) => s.id),
    });
    const event = db.events.insert({
        type: EVENTS.PAIRING_LIST_CREATED,
        timestamp: time || new Date().getTime(),
        payload: {
            list,
            user: user.id,
            pairingList: pairingList.id,
        },
    });
    events.push(event);

    if (initCows) {
        const mockEvents = window.localStorage.getItem("mockEvents") || "[]";
        window.localStorage.setItem(
            "mockEvents",
            JSON.stringify([...JSON.parse(mockEvents), ...events]),
        );
    }
    sessions.map((s) => {
        events.push(
            ...sessionCreatedEvent(
                db,
                db.cows.find(s.cow),
                db.sensors.find(s.sensor),
                user,
                s,
                pairingList.id,
                time + 1000,
            ),
        );
        mockEventFix(db.cows.find(s.cow));
        mockEventsHandler(
            db,
            db.cows.find(s.cow),
            s,
            db.sensors.find(s.sensor),
        );
    });

    return events;
}

function mockEventFix() {
    const mockEvents = JSON.parse(window.localStorage.getItem("mockEvents"));
    const fixedEvents = mockEvents.reduce((acc, e, index) => {
        if (e.event || index === 0) {
            return [...acc, e];
        } else {
            return acc;
        }
    }, []);
    window.localStorage.setItem("mockEvents", JSON.stringify(fixedEvents));
}

export function sessionCreatedEvent(
    db,
    cow,
    sensor,
    user,
    session,
    pairingList,
    time,
) {
    const events = [];
    if (!sensor.tags.includes(SENSOR.IDLE))
        throw new Error("sensor: IDLE required");
    const event = db.events.insert({
        type: EVENTS.SESSION_CREATED,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            sensor: sensor.id,
            user: user.id,
            session: session.id,
            pairingList,
            tags: {
                session: [SESSION.PENDING],
                sensor: [SENSOR.ASSIGNED],
            },
        },
    });
    events.push(event);
    updateTags(db, event);

    if (cow.tags.includes(COW.UNTRACKED)) {
        // means it is cow's first session
        events.push(...firstSessionCreatedEvent(db, cow.id, time + 1000));
    }

    if (cow.tags.includes(COW.PREGNANT) && sensor.type === "Tsens") {
        // means cow is pregnant
        events.push(...pregnancyDetectedEvent(db, cow.id, time + 1000));
        events.push(...pregnancyTrackingStartedEvent(db, cow.id, time + 1000));
    }
    if (cow.tags.includes(COW.ESTROUS) && sensor.type === "Tsens") {
        // estrous tracking
        events.push(...estrousTrackingStartedEvent(db, cow.id, time + 1000));
    }
    if (cow.tags.includes(COW.POST_PARTUM) && sensor.type === "Tsens") {
        // temperature tracking
    }
    return events;
}

function mockEventsHandler(db, cow, session, sensor) {
    // generate and fix mockdata and mock events
    const cData = window.localStorage.getItem(`cow${cow.id}`);
    const mockEvents = window.localStorage.getItem("mockEvents") || "[]";
    if (!cData) {
        // not cData
        const mock = cowData(cow.id, cow.tags, true);
        const cowEvents = mock.events.map((e) => {
            return {
                event: e[1],
                timestamp: e[0],
                payload: {
                    cow: cow.id,
                    session: session.id,
                    sensor: sensor.id,
                    temperature: e[2].toFixed(2),
                },
            };
        });
        window.localStorage.setItem(
            `cow${cow.id}`,
            JSON.stringify(mock.temperature.data),
        );
        window.localStorage.setItem(
            "mockEvents",
            JSON.stringify([...JSON.parse(mockEvents), ...cowEvents]),
        );
    } else {
        // Check cow if session restarted
        const events = JSON.parse(mockEvents);

        const cowSessionRestarted = events.reduce((acc, current, index) => {
            if (
                index > acc &&
                current.payload.list &&
                current.payload.list.find((e) => e.cow === cow.id)
            ) {
                return true;
            } else if (
                current.payload.cow === cow.id &&
                (current.event === EVENTS.SESSION_COMPLETED ||
                    current.event === EVENTS.SESSION_CANCELED)
            ) {
                acc = index;
                return false;
            }
        }, events.length);

        if (cowSessionRestarted) {
            // Regenerate cow data
            window.localStorage.removeItem(`cow${cow.id}`);
            const mock = cowData(cow.id, cow.tags);
            const cowEvents = mock.events.map((e) => {
                return {
                    event: e[1],
                    timestamp: e[0],
                    payload: {
                        cow: cow.id,
                        session: session.id,
                        sensor: sensor.id,
                        temperature: e[2],
                    },
                };
            });
            window.localStorage.setItem(
                `cow${cow.id}`,
                JSON.stringify(mock.temperature.data),
            );
            window.localStorage.setItem(
                "mockEvents",
                JSON.stringify([...JSON.parse(mockEvents), ...cowEvents]),
            );
        }
    }
}

export function firstSessionCreatedEvent(db, cowId, time) {
    const events = [];
    const cow = db.cows.find(cowId);
    if (!cow.tags.includes(COW.UNTRACKED))
        throw new Error("cow: UNTRACKED required");
    const event = db.events.insert({
        type: EVENTS.FIRST_SESSION_CREATED,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: {
                cow: [...cow.tags, COW.PENDING].filter(
                    (t) => t !== COW.UNTRACKED,
                ),
            },
        },
    });
    events.push(event);
    updateTags(db, event);

    return events;
}

export function pregnancyDetectedEvent(db, cowId, time) {
    const events = [];
    const cow = db.cows.find(cowId);
    if (cow.tags.includes(COW.CALVING))
        throw new Error("cow: CALVING is not accepted");
    const newTags = tagCow(cow.tags, EVENTS.PREGNANCY_DETECTED);
    const event = db.events.insert({
        type: EVENTS.PREGNANCY_DETECTED,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: { cow: newTags },
        },
    });
    events.push(event);
    updateTags(db, event);
    return events;
}

export function estrousTrackingStartedEvent(db, cowId, time) {
    const events = [];
    const cow = db.cows.find(cowId);
    const session = db.sessions.findBy({ cow: cow.id });
    const newCowTags = tagCow(cow.tags, EVENTS.ESTROUS_TRACKING_STARTED);
    const newSessionTags = tagSession(
        session.tags,
        EVENTS.ESTROUS_TRACKING_STARTED,
    );

    const event = db.events.insert({
        type: EVENTS.ESTROUS_TRACKING_STARTED,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            session: session.id,
            tags: {
                cow: newCowTags,
                session: newSessionTags,
            },
        },
    });
    events.push(event);
    updateTags(db, event);
    return events;
}

export function pregnancyTrackingStartedEvent(db, cowId, time) {
    const events = [];
    const cow = db.cows.find(cowId);
    const session = db.sessions.findBy({ cow: cow.id });
    const newCowTags = tagCow(cow.tags, EVENTS.PREGNANCY_TRACKING_STARTED);
    const newSessionTags = tagSession(
        session.tags,
        EVENTS.PREGNANCY_TRACKING_STARTED,
    );

    const event = db.events.insert({
        type: EVENTS.PREGNANCY_TRACKING_STARTED,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            session: session.id,
            tags: {
                cow: newCowTags,
                session: newSessionTags,
            },
        },
    });
    events.push(event);
    updateTags(db, event);
    return events;
}

export function temperatureTrackingStartedEvent(db, cowId) {
    const events = [];
    const cow = db.cows.find(cowId);
    const session = db.sessions.findBy({ cow: cow.id });
    const newCowTags = tagCow(cow.tags, EVENTS.TEMPERATURE_TRACKING_STARTED);
    const newSessionTags = tagSession(
        session.tags,
        EVENTS.TEMPERATURE_TRACKING_STARTED,
    );

    const event = db.events.insert({
        type: EVENTS.TEMPERATURE_TRACKING_STARTED,
        timestamp: new Date().getTime(),
        payload: {
            cow: cow.id,
            session: session.id,
            tags: {
                cow: newCowTags,
                session: newSessionTags,
            },
        },
    });
    events.push(event);
    updateTags(db, event);
    return events;
}

export function sensorActivatedEvent(db, sensorId, time) {
    const sensor = db.sensors.find(sensorId);
    if (!sensor.tags.includes(SENSOR.ASSIGNED))
        throw new Error("sensor: ASSIGNED required");
    const event = db.events.insert({
        type: EVENTS.SENSOR_ACTIVATED,
        timestamp: time || new Date().getTime(),
        payload: {
            sensor: sensor.id,
            tags: { sensor: [SENSOR.ACTIVE, SENSOR.PENDING] },
        },
    });
    updateTags(db, event);
}

export function stableDataDetectedEvent(db, sessionId, sensorId, time) {
    const sensor = db.sensors.find(sensorId);
    const session = db.sessions.find(sessionId);
    if (
        !(
            sensor.tags.includes(SENSOR.PENDING) ||
            sensor.tags.includes(SENSOR.UNSTABLE)
        )
    )
        throw new Error("sensor: PENDING or UNSTABLE required");
    if (
        !(
            session.tags.includes(SESSION.PENDING) ||
            sensor.tags.includes(SESSION.ACTIVE)
        )
    )
        throw new Error("session: PENDING or ACTIVE required");
    const event = db.events.insert({
        type: EVENTS.STABLE_DATA_DETECTED,
        timestamp: time || new Date().getTime(),
        payload: {
            sensor: sensor.id,
            session: session.id,
            tags: {
                sensor: [SENSOR.ACTIVE, SENSOR.STABLE],
                session: [...session.tags, SESSION.ACTIVE].filter(
                    (t) => t !== SESSION.PENDING,
                ),
            },
        },
    });
    updateTags(db, event);
    const cow = db.cows.find(session.cow);
    if (cow.tags.includes(COW.PENDING)) {
        trackingStartedEvent(db, cow, time + 1000);
    }
}

export function unstableDataDetectedEvent(db, sensorId, time) {
    const sensor = db.sensors.find(sensorId);
    if (!sensor.tags.includes(SENSOR.STABLE))
        throw new Error("sensor: STABLE required");
    const event = db.events.insert({
        type: EVENTS.UNSTABLE_DATA_DETECTED,
        timestamp: time || new Date().getTime(),
        payload: {
            sensor: sensor.id,
            tags: {
                sensor: [...sensor.tags, SENSOR.UNSTABLE].filter(
                    (t) => t !== SENSOR.STABLE,
                ),
            },
        },
    });
    updateTags(db, event);
}

export function trackingStartedEvent(db, cow, time) {
    if (!cow.tags.includes(COW.PENDING))
        throw new Error("cow: PENDING required");
    const event = db.events.insert({
        type: EVENTS.TRACKING_STARTED,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: {
                cow: [...cow.tags, COW.TRACKED].filter(
                    (t) => t !== COW.PENDING && t !== COW.UNTRACKED,
                ),
            },
        },
    });
    updateTags(db, event);
}

export function feverDetectedEvent(db, cowId, temperature, time) {
    const cow = db.cows.find(cowId);
    if (cow.tags.includes(COW.HYPOTHERMIA))
        throw new Error("cow: FEVER is not accepted since HYPOTHERMIA exist");
    const event = db.events.insert({
        type: EVENTS.FEVER_DETECTED,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            temperature,
            tags: { cow: [...cow.tags, COW.FEVER] },
        },
    });
    updateTags(db, event);
}

export function feverOverEvent(db, cowId, temperature, time) {
    const cow = db.cows.find(cowId);
    if (!cow.tags.includes(COW.FEVER))
        throw new Error(
            `cow: ${EVENTS.FEVER_OVER} is not accepted since FEVER is not exist`,
        );
    const event = db.events.insert({
        type: EVENTS.FEVER_OVER,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            temperature,
            tags: { cow: cow.tags.filter((t) => t !== COW.FEVER) },
        },
    });
    updateTags(db, event);
}

export function calvingDetectedEvent(db, cowId, time) {
    const cow = db.cows.find(cowId);
    if (!(cow.tags.includes(COW.PREGNANT) && cow.tags.includes(COW.TRACKED)))
        throw new Error("cow: PREGNANT and TRACKED required");
    if (cow.tags.includes(COW.CALVING))
        throw new Error("cow: CALVING_DETECTED not accepted, already CALVING");
    const event = db.events.insert({
        type: EVENTS.CALVING_DETECTED,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: {
                cow: [...cow.tags, COW.CALVING].filter((t) => t !== COW.DUE),
            },
        },
    });
    updateTags(db, event);
}

export function calvingDueEvent(db, cowId, estimate, time) {
    const cow = db.cows.find(cowId);
    if (!(cow.tags.includes(COW.PREGNANT) && cow.tags.includes(COW.TRACKED)))
        throw new Error("cow: PREGNANT and TRACKED required");
    const event = db.events.insert({
        type: EVENTS.CALVING_DUE,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            estimate,
            tags: { cow: [...cow.tags, COW.DUE] },
        },
    });
    updateTags(db, event);
}

export function calvingDueFalseEvent(db, cowId, time) {
    const cow = db.cows.find(cowId);
    if (!cow.tags.includes(COW.DUE)) throw new Error("cow: DUE required");
    const event = db.events.insert({
        type: EVENTS.CALVING_DUE_FALSE,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: { cow: [...cow.tags].filter((t) => t !== COW.DUE) },
        },
    });
    updateTags(db, event);
}

export function sessionCompletedEvent(db, sessionId, sensorId, userId, time) {
    const events = [];
    const sensor = db.sensors.find(sensorId);
    const session = db.sessions.find(sessionId);
    const user = db.users.find(userId);
    if (sensor.tags.includes(SENSOR.IDLE))
        throw new Error("sensor: IDLE is not accepted");
    if (!session.tags.includes(SESSION.ACTIVE))
        throw new Error("session: ACTIVE required");
    const event = db.events.insert({
        type: EVENTS.SESSION_COMPLETED,
        timestamp: time || new Date().getTime(),
        payload: {
            user: user.id,
            cow: session.cow,
            session: session.id,
            sensor: sensor.id,
            tags: { session: [SESSION.ARCHIVED], sensor: [SENSOR.IDLE] },
        },
    });
    events.push(event);
    updateTags(db, event);

    const cowSessions = db.sessions.filter(
        (s) => !s.tags.includes(SESSION.ARCHIVED) && s.cow === session.cow,
    );
    if (cowSessions.length === 0) {
        events.push(lastSessionCompletedEvent(db, session.cow, time + 1000));
    }
    disableFutureEvents(session.cow);
    return events;
}

export function lastSessionCompletedEvent(db, cowId, time) {
    const cow = db.cows.find(cowId);
    const event = db.events.insert({
        type: EVENTS.LAST_SESSION_COMPLETED,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: {
                cow: [
                    cow.tags.includes(COW.PREGNANT) ? COW.PREGNANT : null,
                    COW.UNTRACKED,
                ],
            },
        },
    });
    updateTags(db, event);
    return event;
}

export function calvingCompletedEvent(db, cowId, userId, time) {
    const events = [];
    const cow = db.cows.find(cowId);
    const user = db.users.find(userId);
    if (!(cow.tags.includes(COW.PREGNANT) && cow.tags.includes(COW.CALVING)))
        throw new Error("cow: PREGNANT and CALVING required");
    const event = db.events.insert({
        type: EVENTS.CALVING_COMPLETED,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            user: user.id,
            tags: {
                cow: [...cow.tags].filter(
                    (t) => t !== COW.PREGNANT && t !== COW.CALVING,
                ),
            },
        },
    });
    updateTags(db, event);
    events.push(event);
    return events;
}

export function smartNoteSubmittedEvent(db, payload, userId, time) {
    const events = [];
    //get Tsens session
    const TsensSession = db.sessions.filter(
        (s) =>
            s.cow === payload.parentEventTagItems.cow &&
            db.sensors.find(s.sensor).type === "Tsens",
    )[0];

    //calving-completed
    events.push(
        ...calvingCompletedEvent(db, TsensSession.cow, userId, time + 1000),
    );

    //session-completed
    events.push(
        ...sessionCompletedEvent(
            db,
            TsensSession.id,
            TsensSession.sensor,
            userId,
            time + 1000,
        ),
    );

    //generate smart note event
    const event = db.events.insert({
        type: EVENTS.SMART_NOTE_SUBMITTED,
        timestamp: time || new Date().getTime(),
        payload: {
            ...payload,
            user: userId,
            parentEventPayload: payload.parentEventTagItems,
        },
    });
    events.push(event);
    return events;
}

export function newSensorAcquiredEvent(db, sensorProps, userId, time) {
    const user = db.users.find(userId);

    const sensorTags = [SENSOR.IDLE];
    const sensor = db.sensors.insert({ ...sensorProps, tags: sensorTags });

    db.events.insert({
        type: EVENTS.NEW_SENSOR_ACQUIRED,
        timestamp: time || new Date().getTime(),
        payload: {
            sensor: sensor.id,
            user: user.id,
            tags: { sensor: [SENSOR.IDLE] },
        },
    });
}

export function noteSubmittedEvent(db, payload, userId, time) {
    const events = [];
    const user = db.users.find(userId);
    const note = payload.note;

    const event = db.events.insert({
        type: EVENTS.NOTE_SUBMITTED,
        timestamp: time || new Date().getTime(),
        payload: {
            user: user.id,
            note: note,
        },
    });
    events.push(event);
    return events;
}

export function sessionCanceledEvent(db, sessionId, sensorId, userId, time) {
    const events = [];
    const session = db.sessions.find(sessionId);
    const sensor = db.sensors.find(sensorId);
    const user = db.users.find(userId);
    if (!session.tags.includes(SESSION.PENDING))
        throw new Error("session: PENDING required");
    events.push(
        db.events.insert({
            type: EVENTS.SESSION_CANCELED,
            timestamp: time || new Date().getTime(),
            payload: {
                cow: session.cow,
                tags: { sensor: [SENSOR.IDLE], session: [] },
                user: user.id,
                sensor: sensor.id,
                session: session.id,
            },
        }),
    );

    // Check last session
    const cowSessions = db.sessions.filter(
        (s) => !s.tags.length === 0 && s.cow === session.cow,
    );
    if (cowSessions.length === 0) {
        events.push(lastSessionCompletedEvent(db, session.cow));
    }
    disableFutureEvents(session.cow);
    return events;
}

export function cowDiedEvent(db, cowId, time) {
    const events = [];
    const cow = db.cows.find(cowId);

    events.push(
        db.events.insert({
            type: EVENTS.COW_DIED,
            timestamp: time || new Date().getTime(),
            payload: {
                cow: cow.id,
                tags: {
                    cow: [COW.DEAD],
                },
            },
        }),
    );
    tagCow(cow.tags, EVENTS.COW_DIED);
    return events;
}

export function noCommunicationEvent(db, sensorId, time) {
    const sensor = db.sensors.find(sensorId);

    db.events.insert({
        type: EVENTS.NO_COMMUNICATION,
        timestamp: time || new Date().getTime(),
        payload: {
            tags: {
                sensor: [SENSOR.IDLE, SENSOR.NETWORK_PROBLEM],
            },
            deviceId: sensor.deviceId,
        },
    });

    // Detect if session-completed / session-trashed
    // sessionCompletedEvent
}

export function cowEditedEvent(db, cowId, cowProps, userId, time) {
    const events = [];

    cowProps.breed = breedTypeTransformator(cowProps.breed);
    db.cows.update(cowId, { ...cowProps });
    const cow = db.cows.find(cowId);
    const user = db.users.find(userId);

    events.push(
        db.events.insert({
            type: EVENTS.COW_EDITED,
            timestamp: time || new Date().getTime(),
            payload: {
                cow: cow.id,
                user: user.id,
                tags: {
                    cow: [...cow.tags],
                },
            },
        }),
    );

    // Tag update
    if (cowProps.isPregnant && !cow.tags.includes(COW.PREGNANT)) {
        // Add pregnancy
        events.push(pregnancyDetectedEvent(db, cow.id, time + 1000)[0]);

        if (
            !cow.tags.includes(COW.DRY) &&
            cowProps.lastBreedingDate > 0 &&
            dateDiffFromToday(cowProps.lastBreedingDate) >= 220
        ) {
            // Last breeding date is over 220 days
            // Add dry
            events.push(
                processStandaloneEvent(
                    db,
                    {
                        event: "dry-cow-reported",
                        payload: { cow: cow.id },
                        timestamp: time + 2000,
                    },
                    1,
                )[0],
            );
        }
    } else if (cowProps.isPregnant && cow.tags.includes(COW.PREGNANT)) {
        if (
            !cow.tags.includes(COW.DRY) &&
            cowProps.lastBreedingDate > 0 &&
            dateDiffFromToday(cowProps.lastBreedingDate) >= 220
        ) {
            // Add dry
            events.push(
                processStandaloneEvent(
                    db,
                    {
                        event: "dry-cow-reported",
                        payload: { cow: cow.id },
                        timestamp: time + 1000,
                    },
                    1,
                )[0],
            );
        } else if (
            cow.tags.includes(COW.DRY) &&
            cowProps.lastBreedingDate > 0 &&
            dateDiffFromToday(cowProps.lastBreedingDate) < 220
        ) {
            // Remove dry
            events.push(
                processStandaloneEvent(
                    db,
                    {
                        event: "dry-cow-canceled",
                        payload: { cow: cow.id },
                        timestamp: time + 1000,
                    },
                    1,
                )[0],
            );
        }
    } else {
        if (!cowProps.isPregnant && cow.tags.includes(COW.PREGNANT)) {
            // Remove pregnancy
            events.push(pregnancyOverEvent(db, cow.id, time + 1000)[0]);
        }
        if (
            !cow.tags.includes(COW.ESTROUS) &&
            cowProps.lastCalvingDate > 0 &&
            dateDiffFromToday(cowProps.lastCalvingDate) > 7
        ) {
            // Add estrous
            events.push(estrousCycleDetectedEvent(db, cow.id, time + 1000)[0]);
        }
    }

    return events;
}

export function newUserAddedEvent(db, email) {
    // Check if mail is registered

    // Insert new user
    db.users.insert({
        email: email,
        apiUserTypesId: 1, // May change
        isFarmAdmin: true, // May change
        tags: [USER.REGISTERED],
    });

    // Insert event
    db.events.insert({
        type: EVENTS.NEW_USER_ADDED,
        timestamp: new Date().getTime(),
        payload: {
            email: email,
            platform: "",
        },
    });

    // SUT
    // LTT
    // Send mail
}

export function userDeletedEvent(db, userId) {
    db.users.remove(userId);

    db.events.insert({
        type: EVENTS.USER_DELETED,
        timestamp: new Date().getTime(),
        payload: {
            user: userId,
        },
    });
}

export function userRegisteredEvent() {}

export function calledUserActivatedEvent() {}

export function pregnancyOverEvent(db, cowId, time) {
    const events = [];
    const cow = db.cows.find(cowId);
    const newTags = tagCow(cow.tags, EVENTS.PREGNANCY_OVER);
    const event = db.events.insert({
        type: EVENTS.PREGNANCY_OVER,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: {
                cow: newTags,
            },
        },
    });
    events.push(event);
    updateTags(db, event);
    return events;
    // Update last calving date and lactation
}

export function estrousCycleDetectedEvent(db, cowId, time) {
    const events = [];
    const cow = db.cows.find(cowId);
    if (cow.tags.includes(COW.PREGNANT))
        throw new Error("Can't process 'estrous' with 'pregnant'");
    const newTags = tagCow(cow.tags, EVENTS.ESTROUS_CYCLE_DETECTED);
    const event = db.events.insert({
        type: EVENTS.ESTROUS_CYCLE_DETECTED,
        timestamp: time || new Date().getTime(),
        payload: {
            cow: cow.id,
            tags: {
                cow: newTags,
            },
        },
    });
    events.push(event);
    updateTags(db, event);

    // Check if Tsens inserted
    return events;
}

export function potentialCalvingsDetectedEvent(db, cowList, time) {
    db.events.insert({
        type: EVENTS.POTENTIAL_CALVINGS_DETECTED,
        timestamp: time || new Date().getTime(),
        payload: {
            cows: [...cowList],
            tags: [],
        },
    });
}

export function processStandaloneEvent(db, event, userId) {
    const user = db.users.find(userId);
    const events = [];

    // Tags based on event
    const payload = tagMain(db, event);

    const newEvent = db.events.insert({
        type: event.event,
        timestamp: event.timestamp || new Date().getTime(),
        user: user.id,
        payload: payload,
    });
    events.push(newEvent);
    // updateStandaloneTags(db, newEvent);
    updateTags(db, newEvent);
    return events;
}

function updateStandaloneTags(db, type, event) {
    if (type === "cow") {
        const cow = db.cows.find(event.payload.cow);
        return tagCow(cow.tags, event.event);
    }
    if (type === "sensor") {
        const sensor = db.sensors.find(event.payload.sensor);
        return tagSensor(sensor.tags, event.event);
    }
    if (type === "basestation") {
        const basestation = db.basestations.find(event.payload.basestation);
        return tagBasestation(basestation.tags, "EVENT");
    }
    if (type === "session") {
        const session = db.session.find(event.payload.session);
        return tagSession(session.tags, event.event);
    }
}

function tagMain(db, event) {
    const type = Object.keys(standaloneEvents).find((d) =>
        standaloneEvents[d].includes(event.event),
    );
    if (type === "cow") {
        // Tags update
        const tags = updateStandaloneTags(db, "cow", event);
        return {
            cow: event.payload.cow,
            tags: {
                cow: tags,
            },
        };
    } else if (type === "sensor") {
        const tags = updateStandaloneTags(db, "sensor", event);
        return {
            sensor: {
                sensor: event.payload.sensor,
                tags: {
                    sensor: [tags],
                },
            },
        };
    } else if (type === "basestation") {
        const tags = updateStandaloneTags(db, "basestation", event);
        return {
            basestation: event.payload.basestation,
            tags: {
                basestation: [tags],
            },
        };
    }
}

function updateTags(db, event) {
    Object.keys(event.payload.tags).map((key) => {
        db[`${key}s`].update(event.payload[key], {
            tags: event.payload.tags[key],
        });
    });
}

export function getEventSupporters(db, events) {
    const cows = [],
        sensors = [],
        sessions = [],
        users = [],
        basestations = [];
    events.map((e) => {
        if (e.payload.cow && !cows.includes(e.payload.cow))
            cows.push(e.payload.cow);
        if (e.payload.sensor && !sensors.includes(e.payload.cow))
            sensors.push(e.payload.sensor);
        if (e.payload.session && !sessions.includes(e.payload.session))
            sessions.push(e.payload.session);
        if (e.payload.user && !users.includes(e.payload.user))
            users.push(e.payload.user);
        if (
            e.payload.basestation &&
            !basestations.includes(e.payload.basestation)
        )
            basestations.push(e.payload.basestation);
    });
    return {
        events,
        cows: cows.map((c) => db.cows.find(c)),
        sensors: sensors.map((s) => db.sensors.find(s)),
        sessions: sessions.map((s) => db.sessions.find(s)),
        users: users.map((u) => db.users.find(u)),
        basestations: basestations.map((b) => db.basestations.find(b)),
    };
}

export function eventDetector(db, init) {
    if (init) {
        eventDetectorHandler(db, init);
    } else {
        setInterval(() => {
            eventDetectorHandler(db);
        }, 5000);
    }
}

function eventDetectorHandler(db, init) {
    const now = new Date().getTime();
    const lastEvent = db.events
        ? db.events.sort((a, b) => a.timestamp - b.timestamp)[
              db.events.length - 1
          ]
        : { timestamp: 0 };
    const mockEvents = JSON.parse(window.localStorage.getItem("mockEvents"));
    mockEvents
        .filter((e) =>
            init
                ? e.timestamp <= now && e.type !== "pairing-list-created"
                : e.timestamp <= now && e.timestamp > lastEvent.timestamp,
        )
        .map((e) => {
            handleEvent(db, e);
        });
}

export function getMinMax(data) {
    return data.reduce(
        (acc, d) => {
            if (d[1] > acc.max) {
                return {
                    ...acc,
                    max: d[1],
                };
            } else if (d[1] < acc.min) {
                return {
                    ...acc,
                    min: d[1],
                };
            } else {
                return acc;
            }
        },
        {
            min: Number.MAX_SAFE_INTEGER,
            max: Number.MIN_SAFE_INTEGER,
        },
    );
}

export function disableFutureEvents(cow) {
    const SESSION_RELATED_EVENTS = [
        EVENTS.PAIRING_LIST_CREATED,
        EVENTS.SESSION_COMPLETED,
        EVENTS.SESSION_CANCELED,
        EVENTS.SENSOR_ACTIVATED,
        EVENTS.STABLE_DATA_DETECTED,
    ];
    const now = new Date().getTime();
    const mockEvents = JSON.parse(window.localStorage.getItem("mockEvents"));
    const filteredEvents = mockEvents.filter(
        (event) =>
            SESSION_RELATED_EVENTS.includes(event.event || event.type) ||
            (event.payload.cow === cow.id ? event.timestamp <= now : event),
    );
    window.localStorage.setItem("mockEvents", JSON.stringify(filteredEvents));
}

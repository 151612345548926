import { SET_ACTIVE_USER } from "../../actions/actionTypes";

export default function (state = null, action) {
    switch (action.type) {
        case SET_ACTIVE_USER:
            if (state && state.id === action.id) {
                return null;
            }
            return action.id.toString();
        default:
            return state;
    }
}

import { useState, useRef, useMemo, useEffect, useCallback } from "react";

export function useLazyList(list, step = 100) {
    const [renderCount, updateRenderCount] = useState(step);
    const root = useRef(null);
    const renderCountRef = useRef(renderCount);

    renderCountRef.current = renderCount;

    const renderCountCallback = useCallback(
        ([entry]) => {
            if (entry.isIntersecting) {
                observer.unobserve(entry.target);
                updateRenderCount(renderCountRef.current + step);
            }
        },
        [observer, step],
    );
    const observer = useMemo(
        () =>
            new IntersectionObserver(renderCountCallback, {
                root: root.current,
                threshold: 0.9,
            }),
        [renderCountCallback],
    );
    useEffect(() => {
        if (root.current.lastChild) {
            observer.observe(root.current.lastChild);
        }
    }, [observer]);

    useMemo(() => updateRenderCount(step), [step]);
    return [list.slice(0, renderCount), root];
}

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [delay, value]);
    return debouncedValue;
}

export function useMutationObserver(targetNode, config, callback) {
    const [value, setValue] = useState(null);
    const observer = useMemo(
        () =>
            new MutationObserver((mutationList, observer) => {
                const result = callback(mutationList, observer);
                setValue(result);
            }),
        [callback],
    );
    useEffect(() => {
        if (targetNode) {
            observer.observe(targetNode, config);
            return () => {
                observer.disconnect();
            };
        }
    }, [targetNode, config, observer]);
    return value;
}

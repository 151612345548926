import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { TAKE_SNAPSHOT, RESTORE_SNAPSHOT } from "./actions/actionTypes";

import * as reducers from "./reducers";
import {
    startUp,
    postStartup,
    markRead,
    submitNote,
    cowData,
    finishSession,
    updateCowList,
    sendSmartNote,
    language,
    addCow,
    editCow,
    removeCow,
    lastEmail,
    updateInformation,
    registerUserSaga,
    callRequest,
    emailRequest,
    notificationsRequest,
    vacationModeRequest,
    inviteNewUserRequest,
    changeFarmNameRequest,
    removeUserRequest,
    userCall,
    shortcutButtonRequest,
    autoSorting,
    initCommandChannel,
    startTour,
    stopTour,
    redirectCall,
    processRequiredInfo,
    turnSwitch,
    automationData,
    sendNewSmartNote,
    potentialList,
    submitPairingsFromExternal,
    oldCowData,
    startDemo,
    finishDemo,
    registerDemoAccount,
} from "./sagas";

import "../mocks";
import "./axiosDefaults";

const rootReducer = combineReducers({
    ...reducers,
    snapshot(state = null) {
        return state;
    },
});

function snapshotReducer(state, action) {
    switch (action.type) {
        case TAKE_SNAPSHOT:
            return { ...state, snapshot: JSON.parse(JSON.stringify(state)) };
        case RESTORE_SNAPSHOT:
            return state.snapshot ? state.snapshot : state;
        default:
            return rootReducer(state, action);
    }
}

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
    const store = createStore(
        snapshotReducer,
        composeEnhancers(applyMiddleware(sagaMiddleware)),
    );
    sagaMiddleware.run(initCommandChannel);
    sagaMiddleware.run(language);
    sagaMiddleware.run(lastEmail);
    sagaMiddleware.run(startUp);
    sagaMiddleware.run(postStartup);
    sagaMiddleware.run(markRead);
    sagaMiddleware.run(submitNote);
    sagaMiddleware.run(cowData);
    sagaMiddleware.run(finishSession);
    sagaMiddleware.run(updateCowList);
    sagaMiddleware.run(sendSmartNote);
    sagaMiddleware.run(addCow);
    sagaMiddleware.run(editCow);
    sagaMiddleware.run(removeCow);
    sagaMiddleware.run(updateInformation);
    sagaMiddleware.run(callRequest);
    sagaMiddleware.run(emailRequest);
    sagaMiddleware.run(notificationsRequest);
    sagaMiddleware.run(vacationModeRequest);
    sagaMiddleware.run(inviteNewUserRequest);
    sagaMiddleware.run(changeFarmNameRequest);
    sagaMiddleware.run(removeUserRequest);
    sagaMiddleware.run(userCall);
    sagaMiddleware.run(shortcutButtonRequest);
    sagaMiddleware.run(autoSorting);
    sagaMiddleware.run(registerUserSaga);
    sagaMiddleware.run(startTour);
    sagaMiddleware.run(stopTour);
    sagaMiddleware.run(redirectCall);
    sagaMiddleware.run(processRequiredInfo);
    sagaMiddleware.run(turnSwitch);
    sagaMiddleware.run(automationData);
    sagaMiddleware.run(sendNewSmartNote);
    sagaMiddleware.run(potentialList);
    sagaMiddleware.run(submitPairingsFromExternal);
    sagaMiddleware.run(oldCowData);
    sagaMiddleware.run(startDemo);
    sagaMiddleware.run(finishDemo);
    sagaMiddleware.run(registerDemoAccount);
    return store;
};

export default configureStore;

import React from "react";
import { default as RSelect } from "react-select";

import "./Select.css";

const Select = ({ options, onChange, placeholder, defaultValue }) => {
    return (
        <RSelect
            className="custom-select"
            classNamePrefix="custom-select"
            options={options}
            onChange={onChange}
            placeholder={placeholder}
            value={defaultValue}
        />
    );
};

export default Select;

import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { sendNewSmartNote } from "../../store/actions";

import { VARIABLES } from "./constants";
import { HealthComplication, VariableSingle } from "./common";

import "./NewSmartNote.css";

export function AbortCalving({
    id,
    toogleSmartNote,
    sendNewSmartNote,
    about,
    parentEventTagItems,
    defaults,
    isResult,
}) {
    const { t } = useTranslation();
    const smartNoteRef = useRef(null);
    useEffect(() => {
        document.addEventListener("click", onClickOutside, true);
        return () => {
            document.removeEventListener("click", onClickOutside, true);
        };
    });
    function onClickOutside(event) {
        if (
            smartNoteRef.current &&
            !smartNoteRef.current.contains(event.target)
        ) {
            toogleSmartNote(false);
        }
    }
    const [state, setState] = useState(defaults);

    function setHealthComplication(option) {
        if (
            (option.id !== "noComplication" &&
                state.healthComplication.includes("noComplication")) ||
            option.id === "noComplication"
        ) {
            setState({
                ...state,
                healthComplication: state.healthComplication.includes(
                    option.value,
                )
                    ? state.healthComplication.filter((h) => h !== option.value)
                    : [option.value],
            });
            return;
        }
        if (option.id === "noComplication") {
            setState({
                ...state,
                healthComplication: state.healthComplication.includes(
                    option.value,
                )
                    ? state.healthComplication.filter((h) => h !== option.value)
                    : [option.value],
            });
            return;
        }
        setState({
            ...state,
            healthComplication: state.healthComplication.includes(option.value)
                ? state.healthComplication.filter((h) => h !== option.value)
                : [...state.healthComplication, option.value],
        });
    }

    return (
        <div className="smart-note abort-calving" ref={smartNoteRef}>
            <div className={cn("note", { result: isResult })}>
                <VariableSingle
                    value={
                        state.willPlaced
                            ? state.willPlaced
                            : VARIABLES.willPlaced.placeholder
                    }
                    label={`${t("smartNote:headers:willBePlaced")}:`}
                    selected={!!state.willPlaced}
                    options={Object.values(VARIABLES.willPlaced.options)}
                    onSelected={(option) =>
                        setState({
                            ...state,
                            willPlaced: option.value,
                        })
                    }
                />
                <HealthComplication
                    value={VARIABLES.healthComplication.placeholder}
                    label={`${t("smartNote:headers:complication")}:`}
                    onSelected={(option) => setHealthComplication(option)}
                    selected={false}
                    selectedOptions={state.healthComplication}
                    isResult={isResult}
                />
            </div>
            {!isResult ? (
                <i
                    className={cn("envelope", { completed: true })}
                    onClick={() =>
                        true
                            ? sendNewSmartNote(
                                  about,
                                  parentEventTagItems,
                                  state,
                              )
                            : console.log("not completed")
                    }
                />
            ) : null}
        </div>
    );
}

function mapStateToProps(_, { defaults }) {
    const isResult = !!defaults;
    if (!isResult) {
        defaults = {
            willPlaced: VARIABLES.willPlaced.default,
            healthComplication: [],
        };
    }
    return { defaults, isResult };
}

const mapDispatchToProps = { sendNewSmartNote };

export default connect(mapStateToProps, mapDispatchToProps)(AbortCalving);

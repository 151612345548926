import {
    SET_CONFIGS,
    START_VACATION_MODE_REQUEST,
    COMPLETE_VACATION_MODE_REQUEST,
    FAIL_VACATION_MODE_REQUEST
} from "../../actions/actionTypes";

export default function (state = false, action) {
    switch (action.type) {
    case SET_CONFIGS:
        // eslint-disable-next-line no-case-declarations
        const config = action.configs.find(c => c.name == "VACATION_MODE");
        if (config.value === "on") return true;
        return false;
    case START_VACATION_MODE_REQUEST:
        return state;
    case COMPLETE_VACATION_MODE_REQUEST:
        return action.vacationMode;
    case FAIL_VACATION_MODE_REQUEST:
        return state;
    default: return state;
    }
}
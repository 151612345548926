import { navigateBack, forceNavigateTo } from "../../../store/actions";

import { stepNamespace, advanceOnClick, media, delay, buttons } from ".";

const step = stepNamespace("cow-list");

export default function cowListSteps() {
    return [
        {
            ...step("intro"),
            attachTo: { element: "main.cow-list" },
            buttons: [buttons.exit, buttons.next],
            showOn: media("(pointer: coarse)"),
        },
        {
            ...step("intro"),
            attachTo: { element: "main.cow-list", on: "left" },
            buttons: [buttons.exit, buttons.next],
            showOn: media("(pointer: fine)"),
        },
        {
            ...step("filter"),
            attachTo: { element: "main.cow-list div.filter-box", on: "bottom" },
        },
        {
            ...step("add"),
            beforeShowPromise: delay(500),
            ...advanceOnClick("main.cow-list i.add-cow", { on: "bottom" }),
        },
        {
            ...step("new"),
            attachTo: { element: "main.cow-modal #cow-form" },
            when: {
                hide() {
                    this.tour.options.dispatch(forceNavigateTo("/cows"));
                },
            },
            showOn: media("(pointer: coarse)"),
        },
        {
            ...step("new"),
            attachTo: { element: "main.cow-modal #cow-form", on: "left" },
            when: {
                hide() {
                    this.tour.options.dispatch(forceNavigateTo("/cows"));
                },
            },
            showOn: media("(pointer: fine)"),
        },
        {
            ...step("pointer-menu"),
            showOn: media("(pointer: fine)"),
            attachTo: { element: "ul.cow-list li.cow", on: "bottom" },
        },
        {
            ...step("touch-menu"),
            showOn: media("(pointer: coarse)"),
            beforeShowPromise: delay(800),
            attachTo: {
                element: "main.cow-list [data-slider-container]",
                on: "bottom",
            },
            when: {
                show() {
                    document.querySelector(
                        "main.cow-list [data-slider-overlay]",
                    ).dataset.sliderOverlay = "left";
                },
            },
        },
        {
            ...step("extra-info"),
            showOn: media("(pointer: coarse)"),
            attachTo: {
                element: "main.cow-list [data-slider-container]",
                on: "bottom",
            },
            when: {
                show() {
                    document.querySelector(
                        "main.cow-list [data-slider-overlay]",
                    ).dataset.sliderOverlay = "right";
                },
                hide() {
                    document.querySelector(
                        "main.cow-list [data-slider-overlay]",
                    ).dataset.sliderOverlay = "rest";
                },
            },
        },
        {
            ...step("select"),
            beforeShowPromise: delay(500),
            ...advanceOnClick("ul.cow-list li.cow", { on: "bottom" }),
        },
        {
            ...step("send-to-pairing"),
            attachTo: {
                element: "main.cow-list .footer button.action",
                on: "top",
            },
            when: {
                hide() {
                    this.tour.options.dispatch(navigateBack());
                },
            },
        },
    ];
}

import {
    ADD_FILTER,
    DROP_FILTER,
    SELECT_SORT,
    ADD_QUERY,
    DROP_QUERY,
} from "./actionTypes";

export function addFilter(filter) {
    return { type: ADD_FILTER, filter };
}
export function dropFilter(filter) {
    return { type: DROP_FILTER, filter };
}
export function selectSort(sort, order) {
    return { type: SELECT_SORT, sort, order };
}

export function addQuery(query) {
    return { type: ADD_QUERY, query };
}
export function dropQuery(queriesIndex) {
    return { type: DROP_QUERY, queriesIndex };
}

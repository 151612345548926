import { SET_REPEATER_DATA } from "../actions/actionTypes";

export default function (state = { }, action) {
    switch (action.type) {
    case SET_REPEATER_DATA:
        return { ...state, [action.id]: processRepeaterData(action.data) };
    default: return state;
    }
}

function processRepeaterData(data){
    let rssi = null;
    let temperature = null;
    if (data.lastData.rssi){
        rssi = data.lastData.rssi[0][1];
    }
    if (data.lastData.temperature){
        temperature = data.lastData.temperature[0][1];
    }
    let lastPacketTime;
    if (data.lastData.length != 0){
        lastPacketTime = data.lastData[Object.keys(data.lastData)[0]][0][0];
    } else {
        lastPacketTime = 0;
    }
    return {
        rssi,
        temperature,
        lastPacketTime,
        dailyTraffic: data.dailyTraffic
    };
}

import React from "react";
import { useTranslation } from "react-i18next";

import { pairingList as connect } from "../../containers";

import "./PairingList.css";

export function PairingList({ list, id }) {
    const { t } = useTranslation();
    const pendingCount = list.filter(
        (pair) =>
            pair.tags[0] && pair.tags.map((t) => t.value).includes("pending"),
    ).length;
    const currentList = list.filter((pair) => pair.tags[0]).length;
    return (
        <React.Fragment>
            <h5>
                {t("list")} #{id}
            </h5>
            <h4>
                {pendingCount}/{currentList}
            </h4>
        </React.Fragment>
    );
}

export default connect(PairingList);

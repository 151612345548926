import { EVENTS } from "../../../../constants";
export default [
    [39.03, EVENTS.SENSOR_ACTIVATED],
    [39.03],
    [39.03, EVENTS.STABLE_DATA_DETECTED],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.01],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.96],
    [38.95],
    [38.93],
    [38.9],
    [38.9],
    [38.86],
    [38.85],
    [38.85],
    [38.83],
    [38.81],
    [38.78],
    [38.77],
    [38.77],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.68],
    [38.68],
    [38.69],
    [38.69],
    [38.67],
    [38.68],
    [38.67],
    [38.68],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.65],
    [38.65],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.67],
    [38.65],
    [38.65],
    [38.67],
    [38.67],
    [38.67],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.65],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.67],
    [38.65],
    [38.67],
    [38.68],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.7],
    [38.69],
    [38.7],
    [38.68],
    [38.68],
    [38.68],
    [38.69],
    [38.7],
    [38.69],
    [38.69],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.68],
    [38.71],
    [38.68],
    [38.68],
    [38.67],
    [38.68],
    [38.69],
    [38.67],
    [38.7],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.75],
    [38.730000000000004],
    [38.75],
    [38.75],
    [38.76],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.81],
    [38.800000000000004],
    [38.83],
    [38.82],
    [38.83],
    [38.84],
    [38.84],
    [38.85],
    [38.87],
    [38.87],
    [38.89],
    [38.88],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.93],
    [38.93],
    [38.93],
    [38.94],
    [38.96],
    [38.95],
    [38.99],
    [38.99],
    [39.0],
    [38.99],
    [38.980000000000004],
    [39.0],
    [38.97],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.94],
    [38.95],
    [38.95],
    [38.94],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.97],
    [38.99],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.06],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.09],
    [39.09],
    [39.1, EVENTS.FEVER_DETECTED],
    [39.1],
    [39.1],
    [39.12],
    [39.12],
    [39.12],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.13],
    [39.12],
    [39.13],
    [39.1],
    [39.09],
    [39.1],
    [39.09],
    [39.08],
    [39.07],
    [39.08],
    [39.07],
    [39.08],
    [39.09],
    [39.07],
    [39.07],
    [39.07],
    [39.03],
    [39.04],
    [39.02],
    [39.01],
    [39.01],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.99],
    [38.97],
    [38.980000000000004],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.97],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.94],
    [38.94],
    [38.93],
    [38.93],
    [38.93],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.9],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.92],
    [38.9],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.87],
    [38.86],
    [38.85, EVENTS.FEVER_OVER],
    [38.84],
    [38.84],
    [38.85],
    [38.83],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.81],
    [38.81],
    [38.79],
    [38.82],
    [38.84],
    [38.82],
    [38.82],
    [38.84],
    [38.85],
    [38.85],
    [38.87],
    [38.86],
    [38.87],
    [38.88],
    [38.87],
    [38.88],
    [38.88],
    [38.87],
    [38.87],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.89],
    [38.87],
    [38.88],
    [38.85],
    [38.85],
    [38.83],
    [38.85],
    [38.83],
    [38.83],
    [38.82],
    [38.84],
    [38.84],
    [38.82],
    [38.83],
    [38.84],
    [38.85],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.88],
    [38.88],
    [38.87],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.94],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.02],
    [39.01],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.04],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.06],
    [39.07],
    [39.07],
    [39.08],
    [39.1],
    [39.1],
    [39.1],
    [39.11, EVENTS.FEVER_DETECTED],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.15],
    [39.17],
    [39.17],
    [39.14],
    [39.14],
    [39.13],
    [39.12],
    [39.1],
    [39.1],
    [39.08],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.050000000000004],
    [39.01],
    [39.0],
    [38.99],
    [38.96],
    [38.97],
    [38.95],
    [38.94],
    [38.94],
    [38.94],
    [38.94],
    [38.92],
    [38.94],
    [38.93],
    [38.93],
    [38.93],
    [38.94],
    [38.94],
    [38.92],
    [38.94],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.980000000000004],
    [39.0],
    [38.980000000000004],
    [39.0],
    [39.01],
    [39.0],
    [39.01],
    [39.02],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.06],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.06],
    [39.07],
    [39.06],
    [39.04],
    [39.02],
    [39.01],
    [39.0],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.01],
    [39.03],
    [39.02],
    [39.0],
    [39.01],
    [39.01],
    [39.02],
    [39.02],
    [39.0],
    [39.0],
    [39.01],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.01],
    [39.0],
    [38.980000000000004],
    [38.99],
    [38.980000000000004],
    [38.96],
    [38.97],
    [38.96],
    [38.95],
    [38.96],
    [38.95],
    [38.96],
    [38.94],
    [38.93],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.86],
    [38.87],
    [38.88],
    [38.88],
    [38.88],
    [38.88],
    [38.88],
    [38.86],
    [38.88],
    [38.9],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.95],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.03],
    [39.02],
    [39.02],
    [39.01],
    [39.03],
    [39.02],
    [39.02],
    [39.04],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.0],
    [39.01],
    [39.0],
    [38.99],
    [38.99],
    [38.97],
    [38.95],
    [38.94],
    [38.93],
    [38.92],
    [38.92],
    [38.89],
    [38.9],
    [38.88],
    [38.87],
    [38.87],
    [38.86],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.86],
    [38.85],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.87],
    [38.86],
    [38.86],
    [38.86],
    [38.85, EVENTS.FEVER_OVER],
    [38.86],
    [38.86],
    [38.86],
    [38.88],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.89],
    [38.85],
    [38.85],
    [38.83],
    [38.81],
    [38.79],
    [38.78],
    [38.75],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.7],
    [38.68],
    [38.67],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.63],
    [38.64],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.63],
    [38.62],
    [38.62],
    [38.63],
    [38.61],
    [38.63],
    [38.6],
    [38.6],
    [38.57],
    [38.57],
    [38.56],
    [38.57],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.53],
    [38.53],
    [38.52],
    [38.53],
    [38.52],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.49],
    [38.5],
    [38.49],
    [38.5],
    [38.47],
    [38.480000000000004],
    [38.49],
    [38.47],
    [38.480000000000004],
    [38.49],
    [38.46],
    [38.47],
    [38.480000000000004],
    [38.480000000000004],
    [38.47],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.52],
    [38.51],
    [38.51],
    [38.5],
    [38.51],
    [38.5],
    [38.5],
    [38.49],
    [38.5],
    [38.49],
    [38.46],
    [38.47],
    [38.46],
    [38.46],
    [38.46],
    [38.45],
    [38.44],
    [38.42],
    [38.410000000000004],
    [38.42],
    [38.4],
    [38.410000000000004],
    [38.410000000000004],
    [38.42],
    [38.42],
    [38.410000000000004],
    [38.42],
    [38.42],
    [38.42],
    [38.44],
    [38.45],
    [38.44],
    [38.46],
    [38.46],
    [38.47],
    [38.46],
    [38.46],
    [38.480000000000004],
    [38.46],
    [38.46],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.480000000000004],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.52],
    [38.52],
    [38.51],
    [38.53],
    [38.550000000000004],
    [38.54],
    [38.53],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.58],
    [38.6],
    [38.6],
    [38.6],
    [38.62],
    [38.61],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.63],
    [38.63],
    [38.62],
    [38.6],
    [38.62],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.63],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.660000000000004],
    [38.67],
    [38.69],
    [38.69],
    [38.68],
    [38.7],
    [38.7],
    [38.69],
    [38.69],
    [38.67],
    [38.67],
    [38.67],
    [38.65],
    [38.64],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.65],
    [38.64],
    [38.64],
    [38.63],
    [38.62],
    [38.61],
    [38.6],
    [38.6],
    [38.57],
    [38.57],
    [38.57],
    [38.56],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.52],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.52],
    [38.53],
    [38.52],
    [38.5],
    [38.52],
    [38.52],
    [38.53],
    [38.5],
    [38.5],
    [38.52],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.52],
    [38.51],
    [38.53],
    [38.52],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.52],
    [38.52],
    [38.51],
    [38.51],
    [38.51],
    [38.51],
    [38.5],
    [38.5],
    [38.53],
    [38.53],
    [38.51],
    [38.49],
    [38.51],
    [38.5],
    [38.480000000000004],
    [38.49],
    [38.5],
    [38.49],
    [38.49],
    [38.5],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.49],
    [38.5],
    [38.49],
    [38.49],
    [38.49],
    [38.5],
    [38.49],
    [38.49],
    [38.49],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.52],
    [38.5],
    [38.5],
    [38.51],
    [38.5],
    [38.51],
    [38.51],
    [38.51],
    [38.51],
    [38.52],
    [38.51],
    [38.5],
    [38.5],
    [38.51],
    [38.5],
    [38.5],
    [38.5],
    [38.52],
    [38.5],
    [38.5],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.49],
    [38.5],
    [38.53],
    [38.52],
    [38.53],
    [38.53],
    [38.5],
    [38.53],
    [38.53],
    [38.51],
    [38.53],
    [38.54],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.52],
    [38.51],
    [38.5],
    [38.49],
    [38.49],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.480000000000004],
    [38.49],
    [38.47],
    [38.46],
    [38.47],
    [38.43],
    [38.46],
    [38.46],
    [38.46],
    [38.45],
    [38.46],
    [38.480000000000004],
    [38.46],
    [38.47],
    [38.46],
    [38.47],
    [38.46],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.51],
    [38.52],
    [38.51],
    [38.52],
    [38.52],
    [38.52],
    [38.51],
    [38.52],
    [38.52],
    [38.52],
    [38.52],
    [38.52],
    [38.53],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.56],
    [38.57],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.63],
    [38.63],
    [38.63],
    [38.63],
    [38.64],
    [38.65],
    [38.65],
    [38.65],
    [38.64],
    [38.65],
    [38.65],
    [38.67],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.62],
    [38.61],
    [38.61],
    [38.6],
    [38.59],
    [38.59],
    [38.58],
    [38.57],
    [38.57],
    [38.54],
    [38.56],
    [38.54],
    [38.54],
    [38.57],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.56],
    [38.57],
    [38.57],
    [38.58],
    [38.58],
    [38.59],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.62],
    [38.61],
    [38.61],
    [38.6],
    [38.6],
    [38.61],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.63],
    [38.62],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.65],
    [38.65],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.67],
    [38.68],
    [38.68],
    [38.67],
    [38.68],
    [38.68],
    [38.68],
    [38.68],
    [38.7],
    [38.7],
    [38.7],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.75],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.76],
    [38.76],
    [38.76],
    [38.78],
    [38.79],
    [38.82],
    [38.82],
    [38.83],
    [38.85],
    [38.85],
    [38.87],
    [38.87],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.93],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.9],
    [38.92],
    [38.89],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.96],
    [38.95],
    [38.93],
    [38.95],
    [38.93],
    [38.93],
    [38.94],
    [38.93],
    [38.94],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.95],
    [38.96],
    [38.95],
    [38.95],
    [38.980000000000004],
    [38.99],
    [39.0],
    [38.99],
    [39.0],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.06],
    [39.07],
    [39.050000000000004],
    [39.07],
    [39.06],
    [39.07],
    [39.08],
    [39.07],
    [39.08],
    [39.07],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1, EVENTS.FEVER_DETECTED],
    [39.11],
    [39.1],
    [39.13],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.14],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.2],
    [39.2],
    [39.2],
    [39.19],
    [39.19],
    [39.21],
    [39.19],
    [39.18],
    [39.21],
    [39.19],
    [39.19],
    [39.17],
    [39.19],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.15],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.14],
    [39.15],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.18],
    [39.19],
    [39.18],
    [39.18],
    [39.18],
    [39.21],
    [39.2],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.25],
    [39.24],
    [39.25],
    [39.230000000000004],
    [39.24],
    [39.21],
    [39.22],
    [39.21],
    [39.21],
    [39.2],
    [39.2],
    [39.19],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.1],
    [39.11],
    [39.1],
    [39.09],
    [39.1],
    [39.08],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.08],
    [39.08],
    [39.04],
    [39.04],
    [39.03],
    [39.01],
    [39.0],
    [38.980000000000004],
    [38.980000000000004],
    [38.96],
    [38.95],
    [38.92],
    [38.94],
    [38.94],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.89],
    [38.89],
    [38.88],
    [38.87],
    [38.88],
    [38.88],
    [38.87],
    [38.85],
    [38.88],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.87],
    [38.86],
    [38.85],
    [38.85],
    [38.87],
    [38.85],
    [38.85],
    [38.87],
    [38.86],
    [38.86],
    [38.85, EVENTS.FEVER_OVER],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.86],
    [38.84],
    [38.83],
    [38.81],
    [38.79],
    [38.78],
    [38.76],
    [38.77],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.75],
    [38.76],
    [38.76],
    [38.77],
    [38.76],
    [38.78],
    [38.79],
    [38.78],
    [38.79],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.76],
    [38.75],
    [38.75],
    [38.75],
    [38.74],
    [38.76],
    [38.77],
    [38.75],
    [38.75],
    [38.75],
    [38.78],
    [38.81],
    [38.82],
    [38.82],
    [38.83],
    [38.85],
    [38.85],
    [38.87],
    [38.87],
    [38.87],
    [38.89],
    [38.88],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.92],
    [38.94],
    [38.93],
    [38.93],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [38.980000000000004],
    [39.0],
    [39.02],
    [39.01],
    [39.03],
    [39.03],
    [39.06],
    [39.04],
    [39.06],
    [39.07],
    [39.09],
    [39.1, EVENTS.FEVER_DETECTED],
    [39.1],
    [39.12],
    [39.11],
    [39.14],
    [39.12],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.17],
    [39.14],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.14],
    [39.12],
    [39.13],
    [39.11],
    [39.13],
    [39.12],
    [39.11],
    [39.13],
    [39.13],
    [39.12],
    [39.14],
    [39.12],
    [39.13],
    [39.12],
    [39.14],
    [39.13],
    [39.13],
    [39.13],
    [39.12],
    [39.12],
    [39.13],
    [39.1],
    [39.11],
    [39.1],
    [39.11],
    [39.11],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.1],
    [39.09],
    [39.08],
    [39.09],
    [39.09],
    [39.08],
    [39.08],
    [39.07],
    [39.07],
    [39.1],
    [39.07],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.02],
    [38.99],
    [38.99],
    [38.99],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.95],
    [38.95],
    [38.95],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.89],
    [38.89],
    [38.92],
    [38.87],
    [38.85],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.86],
    [38.84],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.88],
    [38.9],
    [38.89],
    [38.88],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.92],
    [38.93],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.9],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.87],
    [38.86],
    [38.86],
    [38.87],
    [38.86],
    [38.88],
    [38.86],
    [38.87],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.89],
    [38.9],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.95],
    [38.93],
    [38.95],
    [38.96],
    [38.94],
    [38.95],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [39.0],
    [39.01],
    [39.01],
    [39.02],
    [39.04],
    [39.04],
    [39.02],
    [39.03],
    [39.02],
    [39.02],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.99],
    [38.97],
    [38.96],
    [38.97],
    [38.95],
    [38.96],
    [38.95],
    [38.95],
    [38.93],
    [38.9],
    [38.92],
    [38.92],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.94],
    [38.93],
    [38.95],
    [38.96],
    [38.93],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.99],
    [38.97],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.96],
    [38.97],
    [38.96],
    [38.97],
    [38.95],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.96],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.95],
    [38.96],
    [38.94],
    [38.93],
    [38.94],
    [38.910000000000004],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.89],
    [38.86],
    [38.85],
    [38.86],
    [38.83, EVENTS.FEVER_OVER],
    [38.84],
    [38.84],
    [38.83],
    [38.82],
    [38.81],
    [38.82],
    [38.81],
    [38.81],
    [38.79],
    [38.81],
    [38.800000000000004],
    [38.800000000000004],
    [38.81],
    [38.84],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.86],
    [38.86],
    [38.87],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.9],
    [38.88],
    [38.9],
    [38.89],
    [38.89],
    [38.87],
    [38.86],
    [38.87],
    [38.85],
    [38.84],
    [38.82],
    [38.84],
    [38.82],
    [38.83],
    [38.82],
    [38.800000000000004],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.78],
    [38.77],
    [38.75],
    [38.77],
    [38.77],
    [38.77],
    [38.75],
    [38.75],
    [38.74],
    [38.74],
    [38.75],
    [38.730000000000004],
    [38.74],
    [38.74],
    [38.74],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.730000000000004],
    [38.72],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.7],
    [38.71],
    [38.71],
    [38.69],
    [38.71],
    [38.7],
    [38.67],
    [38.69],
    [38.69],
    [38.68],
    [38.68],
    [38.69],
    [38.68],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.65],
    [38.65],
    [38.64],
    [38.63],
    [38.63],
    [38.62],
    [38.62],
    [38.62],
    [38.6],
    [38.6],
    [38.6],
    [38.62],
    [38.59],
    [38.6],
    [38.62],
    [38.63],
    [38.62],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.65],
    [38.65],
    [38.64],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.68],
    [38.69],
    [38.69],
    [38.71],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.730000000000004],
    [38.72],
    [38.72],
    [38.74],
    [38.75],
    [38.75],
    [38.76],
    [38.76],
    [38.78],
    [38.77],
    [38.77],
    [38.77],
    [38.77],
    [38.76],
    [38.76],
    [38.77],
    [38.75],
    [38.76],
    [38.75],
    [38.74],
    [38.74],
    [38.730000000000004],
    [38.74],
    [38.730000000000004],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.68],
    [38.7],
    [38.69],
    [38.68],
    [38.67],
    [38.69],
    [38.71],
    [38.7],
    [38.69],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.75],
    [38.76],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.79],
    [38.78],
    [38.79],
    [38.78],
    [38.77],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.79],
    [38.78],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.81],
    [38.79],
    [38.81],
    [38.79],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.83],
    [38.83],
    [38.84],
    [38.84],
    [38.82],
    [38.84],
    [38.83],
    [38.82],
    [38.84],
    [38.83],
    [38.84],
    [38.83],
    [38.84],
    [38.84],
    [38.85],
    [38.84],
    [38.83],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.84],
    [38.83],
    [38.83],
    [38.800000000000004],
    [38.79],
    [38.79],
    [38.78],
    [38.76],
    [38.75],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.7],
    [38.7],
    [38.7],
    [38.69],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.62],
    [38.63],
    [38.6],
    [38.61],
    [38.6],
    [38.6],
    [38.59],
    [38.59],
    [38.57],
    [38.57],
    [38.56],
    [38.56],
    [38.54],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.57],
    [38.56],
    [38.56],
    [38.58],
    [38.57],
    [38.59],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.6],
    [38.62],
    [38.64],
    [38.62],
    [38.64],
    [38.63],
    [38.63],
    [38.64],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.65],
    [38.65],
    [38.67],
    [38.68],
    [38.68],
    [38.69],
    [38.67],
    [38.69],
    [38.69],
    [38.7],
    [38.7],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.74],
    [38.74],
    [38.72],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.77],
    [38.77],
    [38.75],
    [38.76],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.74],
    [38.72],
    [38.71],
    [38.71],
    [38.72],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.7],
    [38.7],
    [38.68],
    [38.63],
    [38.61],
    [38.57],
    [38.58],
    [38.57],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.53],
    [38.5],
    [38.5],
    [38.52],
    [38.52],
    [38.52],
    [38.51],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.52],
    [38.5],
    [38.51],
    [38.5],
    [38.47],
    [38.47],
    [38.47],
    [38.46],
    [38.47],
    [38.46],
    [38.46],
    [38.46],
    [38.45],
    [38.46],
    [38.46],
    [38.480000000000004],
    [38.46],
    [38.47],
    [38.480000000000004],
    [38.49],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.53],
    [38.51],
    [38.53],
    [38.53],
    [38.52],
    [38.53],
    [38.53],
    [38.52],
    [38.52],
    [38.54],
    [38.52],
    [38.52],
    [38.51],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.52],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.51],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.52],
    [38.51],
    [38.51],
    [38.51],
    [38.52],
    [38.53],
    [38.52],
    [38.53],
    [38.52],
    [38.53],
    [38.52],
    [38.51],
    [38.53],
    [38.53],
    [38.51],
    [38.53],
    [38.52],
    [38.53],
    [38.52],
    [38.54],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.56],
    [38.550000000000004],
    [38.57],
    [38.57],
    [38.57],
    [38.56],
    [38.57],
    [38.57],
    [38.57],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.54],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.56],
    [38.54],
    [38.54],
    [38.54],
    [38.54],
    [38.53],
    [38.52],
    [38.53],
    [38.52],
    [38.51],
    [38.52],
    [38.51],
    [38.52],
    [38.52],
    [38.52],
    [38.49],
    [38.5],
    [38.49],
    [38.49],
    [38.5],
    [38.49],
    [38.480000000000004],
    [38.49],
    [38.49],
    [38.49],
    [38.5],
    [38.52],
    [38.52],
    [38.51],
    [38.53],
    [38.53],
    [38.53],
    [38.52],
    [38.53],
    [38.54],
    [38.56],
    [38.56],
    [38.57],
    [38.57],
    [38.56],
    [38.58],
    [38.58],
    [38.58],
    [38.59],
    [38.6],
    [38.61],
    [38.6],
    [38.6],
    [38.62],
    [38.6],
    [38.61],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.62],
    [38.59],
    [38.57],
    [38.57],
    [38.57],
    [38.53],
    [38.53],
    [38.5],
    [38.480000000000004],
    [38.47],
    [38.46],
    [38.47],
    [38.46],
    [38.46],
    [38.46],
    [38.45],
    [38.46],
    [38.47],
    [38.480000000000004],
    [38.480000000000004],
    [38.5],
    [38.49],
    [38.49],
    [38.49],
    [38.51],
    [38.5],
    [38.51],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.57],
    [38.57],
    [38.56],
    [38.57],
    [38.59],
    [38.58],
    [38.6],
    [38.59],
    [38.63],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.67],
    [38.67],
    [38.68],
    [38.67],
    [38.69],
    [38.67],
    [38.69],
    [38.68],
    [38.68],
    [38.7],
    [38.69],
    [38.69],
    [38.69],
    [38.69],
    [38.68],
    [38.69],
    [38.69],
    [38.69],
    [38.7],
    [38.7],
    [38.67],
    [38.67],
    [38.65],
    [38.65],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.63],
    [38.63],
    [38.64],
    [38.63],
    [38.64],
    [38.64],
    [38.62],
    [38.62],
    [38.63],
    [38.61],
    [38.62],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.6],
    [38.6],
    [38.58],
    [38.59],
    [38.58],
    [38.57],
    [38.57],
    [38.56],
    [38.57],
    [38.57],
    [38.56],
    [38.56],
    [38.57],
    [38.57],
    [38.58],
    [38.59],
    [38.6],
    [38.6],
    [38.61],
    [38.62],
    [38.64],
    [38.64],
    [38.64],
    [38.67],
    [38.68],
    [38.67],
    [38.68],
    [38.68],
    [38.69],
    [38.69],
    [38.68],
    [38.69],
    [38.68],
    [38.7],
    [38.7],
    [38.7],
    [38.7],
    [38.7],
    [38.71],
    [38.69],
    [38.7],
    [38.69],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.730000000000004],
    [38.730000000000004],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.77],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.79],
    [38.79],
    [38.82],
    [38.82],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.86],
    [38.87],
    [38.88],
    [38.89],
    [38.9],
    [38.92],
    [38.93],
    [38.93],
    [38.94],
    [38.95],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.96],
    [39.0],
    [39.0],
    [39.01],
    [39.01],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.09],
    [39.09],
    [39.08],
    [39.1],
    [39.1, EVENTS.FEVER_DETECTED],
    [39.1],
    [39.11],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.17],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.13],
    [39.13],
    [39.1],
    [39.1],
    [39.09],
    [39.06],
    [39.050000000000004],
    [39.04],
    [39.02],
    [39.01],
    [39.01],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [38.97],
    [38.980000000000004],
    [38.97],
    [38.97],
    [38.97],
    [38.97],
    [38.96],
    [38.96],
    [38.95],
    [38.94],
    [38.95],
    [38.94],
    [38.96],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.88],
    [38.89],
    [38.86],
    [38.85],
    [38.85, EVENTS.FEVER_OVER],
    [38.85],
    [38.84],
    [38.85],
    [38.84],
    [38.82],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.800000000000004],
    [38.81],
    [38.79],
    [38.79],
    [38.79],
    [38.78],
    [38.77],
    [38.78],
    [38.77],
    [38.78],
    [38.77],
    [38.76],
    [38.76],
    [38.730000000000004],
    [38.72],
    [38.72],
    [38.68],
    [38.660000000000004],
    [38.660000000000004],
    [38.64],
    [38.65],
    [38.64],
    [38.64],
    [38.63],
    [38.64],
    [38.64],
    [38.63],
    [38.64],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.78],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.82],
    [38.83],
    [38.83],
    [38.85],
    [38.86],
    [38.87],
    [38.88],
    [38.88],
    [38.89],
    [38.9],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.93],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [39.0],
    [38.980000000000004],
    [39.0],
    [39.01],
    [39.02],
    [39.02],
    [39.02],
    [39.03],
    [39.03],
    [39.0],
    [39.01],
    [39.01],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [38.96],
    [38.95],
    [38.92],
    [38.9],
    [38.89],
    [38.88],
    [38.87],
    [38.86],
    [38.85],
    [38.85],
    [38.83],
    [38.84],
    [38.83],
    [38.83],
    [38.83],
    [38.82],
    [38.82],
    [38.84],
    [38.83],
    [38.84],
    [38.82],
    [38.84],
    [38.85],
    [38.83],
    [38.84],
    [38.84],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.87],
    [38.86],
    [38.85],
    [38.86],
    [38.87],
    [38.88],
    [38.87],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.84],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.87],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.87],
    [38.85],
    [38.85],
    [38.87],
    [38.88],
    [38.87],
    [38.87],
    [38.87],
    [38.87],
    [38.87],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.88],
    [38.86],
    [38.88],
    [38.88],
    [38.87],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.84],
    [38.82],
    [38.82],
    [38.83],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.78],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.78],
    [38.79],
    [38.78],
    [38.82],
    [38.800000000000004],
    [38.82],
    [38.83],
    [38.83],
    [38.84],
    [38.85],
    [38.87],
    [38.87],
    [38.88],
    [38.87],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.93],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.93],
    [38.95],
    [38.92],
    [38.94],
    [38.94],
    [38.94],
    [38.96],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.01],
    [39.01],
    [39.01],
    [39.0],
    [39.0],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [38.980000000000004],
    [38.99],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.99],
    [38.980000000000004],
    [38.99],
    [38.99],
    [38.96],
    [38.97],
    [38.96],
    [38.95],
    [38.96],
    [38.95],
    [38.96],
    [38.94],
    [38.94],
    [38.96],
    [38.93],
    [38.95],
    [38.94],
    [38.94],
    [38.94],
    [38.94],
    [38.94],
    [38.94],
    [38.93],
    [38.93],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.95],
    [38.94],
    [38.94],
    [38.93],
    [38.94],
    [38.94],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.01],
    [39.02],
    [39.02],
    [39.03],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.1],
    [39.1, EVENTS.FEVER_DETECTED],
    [39.1],
    [39.11],
    [39.12],
    [39.11],
    [39.12],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.18],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.12],
    [39.14],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.12],
    [39.11],
    [39.11],
    [39.11],
    [39.1],
    [39.11],
    [39.12],
    [39.1],
    [39.12],
    [39.11],
    [39.13],
    [39.14],
    [39.14],
    [39.12],
    [39.14],
    [39.14],
    [39.12],
    [39.13],
    [39.13],
    [39.13],
    [39.12],
    [39.11],
    [39.12],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.08],
    [39.08],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.11],
    [39.11],
    [39.1],
    [39.11],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.12],
    [39.1],
    [39.13],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.08],
    [39.07],
    [39.08],
    [39.050000000000004],
    [39.050000000000004],
    [39.02],
    [39.01],
    [39.0],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.96],
    [38.97],
    [38.97],
    [38.95],
    [38.93],
    [38.95],
    [38.94],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.9],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.94],
    [38.92],
    [38.94],
    [38.93],
    [38.94],
    [38.93],
    [38.96],
    [38.96],
    [38.97],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.02],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.1],
    [39.11],
    [39.11],
    [39.11],
    [39.1],
    [39.12],
    [39.11],
    [39.11],
    [39.11],
    [39.1],
    [39.12],
    [39.12],
    [39.11],
    [39.13],
    [39.11],
    [39.12],
    [39.13],
    [39.13],
    [39.12],
    [39.1],
    [39.09],
    [39.09],
    [39.1],
    [39.07],
    [39.09],
    [39.07],
    [39.08],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.03],
    [39.02],
    [39.03],
    [39.0],
    [38.99],
    [38.97],
    [38.96],
    [38.96],
    [38.97],
    [38.93],
    [38.93],
    [38.92],
    [38.94],
    [38.93],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.92],
    [38.96],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.980000000000004],
    [38.96],
    [38.980000000000004],
    [38.96],
    [38.980000000000004],
    [38.99],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.89],
    [38.86],
    [38.86, EVENTS.FEVER_OVER],
    [38.85],
    [38.84],
    [38.800000000000004],
    [38.78],
    [38.77],
    [38.77],
    [38.75],
    [38.74],
    [38.71],
    [38.71],
    [38.71],
    [38.7],
    [38.69],
    [38.69],
    [38.67],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.65],
    [38.67],
    [38.65],
    [38.63],
    [38.61],
    [38.61],
    [38.6],
    [38.59],
    [38.58],
    [38.57],
    [38.54],
    [38.53],
    [38.51],
    [38.51],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.51],
    [38.52],
    [38.52],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.550000000000004],
    [38.56],
    [38.54],
    [38.57],
    [38.57],
    [38.58],
    [38.6],
    [38.6],
    [38.62],
    [38.63],
    [38.63],
    [38.63],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.65],
    [38.65],
    [38.65],
    [38.65],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.71],
    [38.7],
    [38.69],
    [38.71],
    [38.69],
    [38.71],
    [38.7],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.7],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.7],
    [38.7],
    [38.7],
    [38.69],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.64],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.69],
    [38.67],
    [38.68],
    [38.67],
    [38.67],
    [38.69],
    [38.69],
    [38.7],
    [38.71],
    [38.72],
    [38.71],
    [38.730000000000004],
    [38.72],
    [38.75],
    [38.730000000000004],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.72],
    [38.72],
    [38.730000000000004],
    [38.730000000000004],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.72],
    [38.71],
    [38.69],
    [38.7],
    [38.69],
    [38.69],
    [38.7],
    [38.68],
    [38.69],
    [38.67],
    [38.69],
    [38.69],
    [38.7],
    [38.68],
    [38.68],
    [38.68],
    [38.69],
    [38.7],
    [38.69],
    [38.69],
    [38.69],
    [38.67],
    [38.660000000000004],
    [38.64],
    [38.63],
    [38.6],
    [38.6],
    [38.59],
    [38.57],
    [38.58],
    [38.56],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.56],
    [38.57],
    [38.57],
    [38.57],
    [38.58],
    [38.6],
    [38.6],
    [38.59],
    [38.6],
    [38.61],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.62],
    [38.61],
    [38.62],
    [38.62],
    [38.62],
    [38.63],
    [38.64],
    [38.63],
    [38.64],
    [38.64],
    [38.65],
    [38.63],
    [38.62],
    [38.64],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.63],
    [38.62],
    [38.6],
    [38.6],
    [38.59],
    [38.59],
    [38.58],
    [38.57],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.52],
    [38.51],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.480000000000004],
    [38.49],
    [38.46],
    [38.46],
    [38.45],
    [38.46],
    [38.44],
    [38.44],
    [38.44],
    [38.42],
    [38.42],
    [38.45],
    [38.43],
    [38.43],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.410000000000004],
    [38.4],
    [38.4],
    [38.410000000000004],
    [38.4],
    [38.4],
    [38.4],
    [38.42],
    [38.410000000000004],
    [38.4],
    [38.4],
    [38.42],
    [38.4],
    [38.42],
    [38.42],
    [38.42],
    [38.4],
    [38.42],
    [38.43],
    [38.42],
    [38.42],
    [38.42],
    [38.44],
    [38.43],
    [38.44],
    [38.45],
    [38.45],
    [38.45],
    [38.44],
    [38.43],
    [38.45],
    [38.45],
    [38.45],
    [38.43],
    [38.42],
    [38.43],
    [38.42],
    [38.4],
    [38.4],
    [38.39],
    [38.39],
    [38.39],
    [38.38],
    [38.38],
    [38.36],
    [38.36],
    [38.35],
    [38.35],
    [38.33],
    [38.34],
    [38.33],
    [38.33],
    [38.32],
    [38.32],
    [38.32],
    [38.32],
    [38.34],
    [38.34],
    [38.35],
    [38.35],
    [38.35],
    [38.35],
    [38.35],
    [38.34],
    [38.35],
    [38.34],
    [38.35],
    [38.36],
    [38.37],
    [38.35],
    [38.36],
    [38.38],
    [38.38],
    [38.39],
    [38.4],
    [38.39],
    [38.4],
    [38.4],
    [38.4],
    [38.39],
    [38.4],
    [38.39],
    [38.410000000000004],
    [38.39],
    [38.39],
    [38.39],
    [38.410000000000004],
    [38.4],
    [38.39],
    [38.39],
    [38.39],
    [38.4],
    [38.42],
    [38.410000000000004],
    [38.410000000000004],
    [38.42],
    [38.39],
    [38.39],
    [38.410000000000004],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.43],
    [38.44],
    [38.42],
    [38.4],
    [38.4],
    [38.4],
    [38.39],
    [38.4],
    [38.39],
    [38.4],
    [38.4],
    [38.4],
    [38.39],
    [38.42],
    [38.39],
    [38.36],
    [38.39],
    [38.38],
    [38.37],
    [38.35],
    [38.36],
    [38.35],
    [38.36],
    [38.35],
    [38.36],
    [38.36],
    [38.38],
    [38.39],
    [38.39],
    [38.39],
    [38.39],
    [38.42],
    [38.4],
    [38.39],
    [38.4],
    [38.410000000000004],
    [38.4],
    [38.39],
    [38.39],
    [38.4],
    [38.38],
    [38.37],
    [38.38],
    [38.38],
    [38.38],
    [38.37],
    [38.36],
    [38.37],
    [38.37],
    [38.36],
    [38.35],
    [38.35],
    [38.35],
    [38.34],
    [38.35],
    [38.36],
    [38.36],
    [38.38],
    [38.36],
    [38.39],
    [38.39],
    [38.37],
    [38.38],
    [38.39],
    [38.39],
    [38.4],
    [38.4],
    [38.4],
    [38.42],
    [38.42],
    [38.44],
    [38.46],
    [38.46],
    [38.480000000000004],
    [38.49],
    [38.5],
    [38.52],
    [38.53],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.57],
    [38.58],
    [38.58],
    [38.6],
    [38.57],
    [38.6],
    [38.6],
    [38.61],
    [38.62],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.68],
    [38.68],
    [38.67],
    [38.67],
    [38.68],
    [38.68],
    [38.68],
    [38.67],
    [38.7],
    [38.69],
    [38.69],
    [38.68],
    [38.7],
    [38.69],
    [38.7],
    [38.7],
    [38.68],
    [38.71],
    [38.69],
    [38.69],
    [38.69],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.7],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.64],
    [38.61],
    [38.6],
    [38.59],
    [38.58],
    [38.57],
    [38.58],
    [38.56],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.56],
    [38.57],
    [38.57],
    [38.57],
    [38.59],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.6],
    [38.6],
    [38.63],
    [38.62],
    [38.62],
    [38.63],
    [38.64],
    [38.62],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.65],
    [38.67],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.61],
    [38.63],
    [38.61],
    [38.6],
    [38.6],
    [38.58],
    [38.6],
    [38.57],
    [38.57],
    [38.58],
    [38.57],
    [38.57],
    [38.6],
    [38.59],
    [38.59],
    [38.59],
    [38.6],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.7],
    [38.7],
    [38.69],
    [38.71],
    [38.72],
    [38.72],
    [38.75],
    [38.76],
    [38.78],
    [38.77],
    [38.78],
    [38.81],
    [38.82],
    [38.81],
    [38.82],
    [38.82],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.82],
    [38.83],
    [38.82],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.79],
    [38.78],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.81],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.87],
    [38.89],
    [38.89],
    [38.9],
    [38.94],
    [38.92],
    [38.94],
    [38.95],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [39.01],
    [39.0],
    [39.0],
    [39.02],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.07],
    [39.07],
    [39.1],
    [39.09],
    [39.1],
    [39.12],
    [39.1],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.15],
    [39.14],
    [39.14],
    [39.11],
    [39.1],
    [39.1],
    [39.08],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.050000000000004],
    [39.03],
    [39.04],
    [39.04],
    [39.03],
    [39.04],
    [39.02],
    [39.02],
    [39.03],
    [39.03],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.96],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.93],
    [38.94],
    [38.94],
    [38.92],
    [38.92],
    [38.95],
    [38.93],
    [38.93],
    [38.94],
    [38.95],
    [38.95],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.96],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [39.0],
    [38.980000000000004],
    [38.99],
    [38.99],
    [38.99],
    [38.980000000000004],
    [39.0],
    [38.97],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [38.99],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.94],
    [38.93],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.92],
    [38.89],
    [38.92],
    [38.9],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.93],
    [38.93],
    [38.93],
    [38.94],
    [38.96],
    [38.95],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.03],
    [39.03],
    [39.06],
    [39.07],
    [39.07],
    [39.1],
    [39.09],
    [39.11],
    [39.13, EVENTS.FEVER_DETECTED],
    [39.13],
    [39.14],
    [39.14],
    [39.15],
    [39.17],
    [39.160000000000004],
    [39.18],
    [39.17],
    [39.2],
    [39.2],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.21],
    [39.230000000000004],
    [39.22],
    [39.21],
    [39.21],
    [39.19],
    [39.19],
    [39.18],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.15],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.19],
    [39.19],
    [39.19],
    [39.21],
    [39.230000000000004],
    [39.24],
    [39.24],
    [39.26],
    [39.27],
    [39.27],
    [39.28],
    [39.300000000000004],
    [39.28],
    [39.28],
    [39.31],
    [39.32],
    [39.32],
    [39.33],
    [39.32],
    [39.34],
    [39.35],
    [39.35],
    [39.36],
    [39.37],
    [39.38],
    [39.39],
    [39.36],
    [39.39],
    [39.39],
    [39.39],
    [39.39],
    [39.410000000000004],
    [39.39],
    [39.39],
    [39.38],
    [39.37],
    [39.37],
    [39.37],
    [39.35],
    [39.35],
    [39.35],
    [39.35],
    [39.34],
    [39.34],
    [39.34],
    [39.33],
    [39.34],
    [39.34],
    [39.34],
    [39.32],
    [39.33],
    [39.33],
    [39.33],
    [39.32],
    [39.32],
    [39.32],
    [39.32],
    [39.31],
    [39.300000000000004],
    [39.31],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.28],
    [39.28],
    [39.26],
    [39.28],
    [39.28],
    [39.27],
    [39.28],
    [39.28],
    [39.28],
    [39.29],
    [39.28],
    [39.27],
    [39.27],
    [39.27],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.26],
    [39.25],
    [39.25],
    [39.25],
    [39.27],
    [39.26],
    [39.26],
    [39.27],
    [39.26],
    [39.25],
    [39.26],
    [39.27],
    [39.26],
    [39.27],
    [39.25],
    [39.26],
    [39.22],
    [39.24],
    [39.22],
    [39.22],
    [39.21],
    [39.21],
    [39.2],
    [39.19],
    [39.17],
    [39.17],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.15],
    [39.1],
    [39.1],
    [39.09],
    [39.08],
    [39.08],
    [39.07],
    [39.07],
    [39.08],
    [39.09],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.09],
    [39.09],
    [39.09],
    [39.1],
    [39.12],
    [39.12],
    [39.12],
    [39.14],
    [39.13],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.12],
    [39.11],
    [39.1],
    [39.11],
    [39.1],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.09],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.09],
    [39.1],
    [39.09],
    [39.09],
    [39.07],
    [39.08],
    [39.08],
    [39.07],
    [39.09],
    [39.08],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.03],
    [39.04],
    [39.03],
    [39.02],
    [39.02],
    [39.02],
    [39.01],
    [39.0],
    [38.99],
    [38.99],
    [38.97],
    [38.96],
    [38.96],
    [38.93],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.9],
    [38.87],
    [38.87],
    [38.88],
    [38.87],
    [38.88],
    [38.88],
    [38.87],
    [38.88],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.88],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.93],
    [38.92],
    [38.94],
    [38.93],
    [38.94],
    [38.95],
    [38.95],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.87],
    [38.86],
    [38.87],
    [38.87],
    [38.87],
    [38.87],
    [38.86],
    [38.88],
    [38.88],
    [38.89],
    [38.88],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.94],
    [38.95],
    [38.94],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.02],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.06],
    [39.07],
    [39.06],
    [39.06],
    [39.04],
    [39.04],
    [39.03],
    [39.03],
    [39.02],
    [39.01],
    [39.0],
    [39.0],
    [38.97],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.96],
    [38.97],
    [38.99],
    [38.99],
    [39.0],
    [39.02],
    [39.01],
    [39.01],
    [39.04],
    [39.03],
    [39.04],
    [39.04],
    [39.07],
    [39.06],
    [39.08],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.13],
    [39.12],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.18],
    [39.19],
    [39.17],
    [39.2],
    [39.19],
    [39.17],
    [39.17],
    [39.17],
    [39.15],
    [39.15],
    [39.14],
    [39.12],
    [39.13],
    [39.1],
    [39.07],
    [39.08],
    [39.02],
    [39.03],
    [39.03],
    [39.01],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.97],
    [38.95],
    [38.94],
    [38.94],
    [38.92],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.89],
    [38.87],
    [38.86],
    [38.86],
    [38.84],
    [38.83],
    [38.83],
    [38.83],
    [38.81],
    [38.81],
    [38.79],
    [38.81],
    [38.79],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.79],
    [38.82],
    [38.82],
    [38.81],
    [38.83],
    [38.84],
    [38.84],
    [38.86],
    [38.85],
    [38.87],
    [38.87],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.94],
    [38.95],
    [38.94],
    [38.97],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.02],
    [39.01],
    [39.01],
    [39.02],
    [39.02],
    [39.02],
    [39.02],
    [39.02],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [38.99],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.93],
    [38.93],
    [38.92],
    [38.94],
    [38.93],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.9],
    [38.9],
    [38.88],
    [38.88],
    [38.88],
    [38.88],
    [38.88],
    [38.86, EVENTS.FEVER_OVER],
    [38.84],
    [38.83],
    [38.83],
    [38.83],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.800000000000004],
    [38.800000000000004],
    [38.800000000000004],
    [38.800000000000004],
    [38.79],
    [38.82],
    [38.82],
    [38.800000000000004],
    [38.82],
    [38.83],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.86],
    [38.89],
    [38.88],
    [38.87],
    [38.89],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.93],
    [38.92],
    [38.95],
    [38.96],
    [38.95],
    [38.95],
    [38.97],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [39.02],
    [39.02],
    [39.02],
    [39.01],
    [39.02],
    [39.03],
    [39.02],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.02],
    [39.01],
    [39.01],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.94],
    [38.95],
    [38.92],
    [38.92],
    [38.89],
    [38.89],
    [38.86],
    [38.86],
    [38.85],
    [38.84],
    [38.84],
    [38.82],
    [38.81],
    [38.82],
    [38.800000000000004],
    [38.800000000000004],
    [38.800000000000004],
    [38.800000000000004],
    [38.79],
    [38.81],
    [38.800000000000004],
    [38.800000000000004],
    [38.800000000000004],
    [38.800000000000004],
    [38.79],
    [38.800000000000004],
    [38.79],
    [38.79],
    [38.78],
    [38.78],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.79],
    [38.800000000000004],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.78],
    [38.800000000000004],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.79],
    [38.78],
    [38.76],
    [38.77],
    [38.75],
    [38.75],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.71],
    [38.7],
    [38.69],
    [38.69],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.63],
    [38.63],
    [38.61],
    [38.6],
    [38.57],
    [38.550000000000004],
    [38.53],
    [38.5],
    [38.49],
    [38.480000000000004],
    [38.47],
    [38.46],
    [38.44],
    [38.44],
    [38.46],
    [38.44],
    [38.44],
    [38.45],
    [38.44],
    [38.44],
    [38.44],
    [38.43],
    [38.43],
    [38.43],
    [38.43],
    [38.43],
    [38.44],
    [38.44],
    [38.42],
    [38.45],
    [38.45],
    [38.46],
    [38.45],
    [38.46],
    [38.45],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.480000000000004],
    [38.47],
    [38.49],
    [38.480000000000004],
    [38.49],
    [38.49],
    [38.5],
    [38.52],
    [38.5],
    [38.53],
    [38.53],
    [38.52],
    [38.51],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.56],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.52],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.54],
    [38.53],
    [38.54],
    [38.54],
    [38.54],
    [38.54],
    [38.54],
    [38.53],
    [38.54],
    [38.54],
    [38.54],
    [38.53],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.56],
    [38.57],
    [38.550000000000004],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.59],
    [38.57],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.67],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.62],
    [38.62],
    [38.62],
    [38.61],
    [38.61],
    [38.62],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.61],
    [38.61],
    [38.62],
    [38.6],
    [38.62],
    [38.61],
    [38.62],
    [38.61],
    [38.61],
    [38.6],
    [38.61],
    [38.59],
    [38.6],
    [38.58],
    [38.58],
    [38.57],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.5],
    [38.51],
    [38.49],
    [38.480000000000004],
    [38.46],
    [38.46],
    [38.46],
    [38.47],
    [38.49],
    [38.480000000000004],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.49],
    [38.51],
    [38.52],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.550000000000004],
    [38.57],
    [38.56],
    [38.550000000000004],
    [38.56],
    [38.57],
    [38.58],
    [38.57],
    [38.59],
    [38.57],
    [38.58],
    [38.57],
    [38.57],
    [38.58],
    [38.6],
    [38.59],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.6],
    [38.6],
    [38.61],
    [38.6],
    [38.62],
    [38.61],
    [38.62],
    [38.62],
    [38.64],
    [38.64],
    [38.61],
    [38.64],
    [38.660000000000004],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.65],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.7],
    [38.69],
    [38.68],
    [38.69],
    [38.69],
    [38.69],
    [38.69],
    [38.69],
    [38.69],
    [38.69],
    [38.69],
    [38.68],
    [38.69],
    [38.69],
    [38.68],
    [38.67],
    [38.69],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.65],
    [38.65],
    [38.660000000000004],
    [38.64],
    [38.65],
    [38.64],
    [38.63],
    [38.64],
    [38.63],
    [38.61],
    [38.61],
    [38.62],
    [38.6],
    [38.61],
    [38.6],
    [38.57],
    [38.57],
    [38.58],
    [38.57],
    [38.56],
    [38.57],
    [38.550000000000004],
    [38.56],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.52],
    [38.53],
    [38.49],
    [38.49],
    [38.49],
    [38.49],
    [38.46],
    [38.46],
    [38.46],
    [38.44],
    [38.45],
    [38.44],
    [38.42],
    [38.43],
    [38.44],
    [38.46],
    [38.45],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.44],
    [38.45],
    [38.44],
    [38.45],
    [38.43],
    [38.44],
    [38.44],
    [38.43],
    [38.44],
    [38.45],
    [38.45],
    [38.46],
    [38.46],
    [38.46],
    [38.45],
    [38.43],
    [38.46],
    [38.46],
    [38.47],
    [38.480000000000004],
    [38.47],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.52],
    [38.53],
    [38.52],
    [38.53],
    [38.54],
    [38.57],
    [38.57],
    [38.57],
    [38.58],
    [38.59],
    [38.59],
    [38.6],
    [38.61],
    [38.61],
    [38.63],
    [38.64],
    [38.65],
    [38.65],
    [38.64],
    [38.67],
    [38.67],
    [38.69],
    [38.71],
    [38.72],
    [38.72],
    [38.74],
    [38.75],
    [38.75],
    [38.76],
    [38.77],
    [38.77],
    [38.78],
    [38.78],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.84],
    [38.84],
    [38.86],
    [38.86],
    [38.88],
    [38.88],
    [38.86],
    [38.88],
    [38.87],
    [38.88],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.88],
    [38.87],
    [38.89],
    [38.87],
    [38.87],
    [38.87],
    [38.89],
    [38.86],
    [38.85],
    [38.88],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.82],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.77],
    [38.75],
    [38.75],
    [38.78],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.730000000000004],
    [38.730000000000004],
    [38.730000000000004],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.730000000000004],
    [38.72],
    [38.72],
    [38.730000000000004],
    [38.730000000000004],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.75],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.71],
    [38.71],
    [38.7],
    [38.69],
    [38.68],
    [38.68],
    [38.67],
    [38.68],
    [38.68],
    [38.65],
    [38.64],
    [38.63],
    [38.62],
    [38.64],
    [38.61],
    [38.6],
    [38.6],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.63],
    [38.62],
    [38.63],
    [38.65],
    [38.67],
    [38.65],
    [38.67],
    [38.7],
    [38.69],
    [38.7],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.75],
    [38.77],
    [38.78],
    [38.77],
    [38.800000000000004],
    [38.800000000000004],
    [38.81],
    [38.82],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.88],
    [38.88],
    [38.89],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.95],
    [38.94],
    [38.96],
    [38.95],
    [38.96],
    [38.97],
    [38.96],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.97],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.03],
    [39.01],
    [39.0],
    [39.02],
    [39.03],
    [39.02],
    [39.03],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.03],
    [39.02],
    [39.01],
    [39.02],
    [39.01],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.95],
    [38.93],
    [38.94],
    [38.94],
    [38.93],
    [38.95],
    [38.94],
    [38.93],
    [38.94],
    [38.96],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.01],
    [39.02],
    [39.02],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.03],
    [39.0],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.94],
    [38.94],
    [38.93],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.88],
    [38.88],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.87],
    [38.88],
    [38.88],
    [38.87],
    [38.87],
    [38.85],
    [38.86],
    [38.86],
    [38.87],
    [38.87],
    [38.85],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.84],
    [38.84],
    [38.85],
    [38.85],
    [38.84],
    [38.84],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.83],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.77],
    [38.76],
    [38.77],
    [38.75],
    [38.75],
    [38.72],
    [38.72],
    [38.72],
    [38.72],
    [38.72],
    [38.71],
    [38.7],
    [38.71],
    [38.71],
    [38.7],
    [38.69],
    [38.69],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.69],
    [38.71],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.72],
    [38.730000000000004],
    [38.76],
    [38.76],
    [38.76],
    [38.78],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.82],
    [38.78],
    [38.79],
    [38.79],
    [38.81],
    [38.800000000000004],
    [38.82],
    [38.81],
    [38.81],
    [38.83],
    [38.83],
    [38.82],
    [38.82],
    [38.84],
    [38.85],
    [38.85],
    [38.84],
    [38.84],
    [38.85],
    [38.86],
    [38.86],
    [38.87],
    [38.86],
    [38.88],
    [38.88],
    [38.89],
    [38.9],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.95],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.99],
    [38.99],
    [38.99],
    [39.01],
    [39.01],
    [39.01],
    [39.03],
    [39.02],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.01],
    [39.01],
    [39.01],
    [39.0],
    [38.97],
    [38.97],
    [38.97],
    [38.99],
    [38.97],
    [38.96],
    [38.94],
    [38.95],
    [38.93],
    [38.93],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.93],
    [38.93],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.94],
    [38.95],
    [38.94],
    [38.94],
    [38.93],
    [38.93],
    [38.94],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.94],
    [38.93],
    [38.94],
    [38.94],
    [38.93],
    [38.92],
    [38.96],
    [38.94],
    [38.95],
    [38.95],
    [38.94],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.94],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.99],
    [38.980000000000004],
    [38.99],
    [39.0],
    [38.99],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [39.01],
    [39.01],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.04],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.06],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.03],
    [39.01],
    [39.02],
    [38.99],
    [38.980000000000004],
    [38.96],
    [38.97],
    [38.96],
    [38.93],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.89],
    [38.89],
    [38.88],
    [38.85],
    [38.87],
    [38.85],
    [38.86],
    [38.87],
    [38.86],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.95],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.97],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.03],
    [39.050000000000004],
    [39.06],
    [39.08],
    [39.08],
    [39.1],
    [39.1],
    [39.1],
    [39.1, EVENTS.FEVER_DETECTED],
    [39.12],
    [39.12],
    [39.14],
    [39.15],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.19],
    [39.18],
    [39.17],
    [39.17],
    [39.2],
    [39.19],
    [39.19],
    [39.19],
    [39.21],
    [39.19],
    [39.2],
    [39.21],
    [39.19],
    [39.18],
    [39.18],
    [39.17],
    [39.17],
    [39.15],
    [39.14],
    [39.14],
    [39.11],
    [39.1],
    [39.09],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.06],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.02],
    [39.02],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.99],
    [38.96],
    [38.96],
    [38.94],
    [38.92],
    [38.92],
    [38.92],
    [38.9],
    [38.9],
    [38.89],
    [38.88],
    [38.89],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.9],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.92],
    [38.95],
    [38.94],
    [38.96],
    [38.96],
    [38.97],
    [38.99],
    [39.0],
    [39.0],
    [39.01],
    [39.02],
    [39.04],
    [39.02],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.09],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.09],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.08],
    [39.08],
    [39.07],
    [39.09],
    [39.07],
    [39.07],
    [39.06],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.980000000000004],
    [39.0],
    [38.99],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [38.99],
    [38.99],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.95],
    [38.95],
    [38.94],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.89],
    [38.89],
    [38.89],
    [38.87],
    [38.89],
    [38.88],
    [38.87],
    [38.87],
    [38.85],
    [38.86],
    [38.86],
    [38.85, EVENTS.FEVER_OVER],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.82],
    [38.84],
    [38.83],
    [38.83],
    [38.82],
    [38.82],
    [38.81],
    [38.81],
    [38.81],
    [38.800000000000004],
    [38.800000000000004],
    [38.800000000000004],
    [38.79],
    [38.82],
    [38.82],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.84],
    [38.84],
    [38.84],
    [38.85],
    [38.86],
    [38.85],
    [38.87],
    [38.86],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.89],
    [38.89],
    [38.88],
    [38.87],
    [38.88],
    [38.89],
    [38.87],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.93],
    [38.93],
    [38.94],
    [38.93],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.03],
    [39.03],
    [39.02],
    [39.04],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.09],
    [39.09],
    [39.09],
    [39.09],
    [39.11],
    [39.09],
    [39.09],
    [39.1],
    [39.08],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.04],
    [39.02],
    [39.02],
    [39.03],
    [39.03],
    [39.01],
    [39.01],
    [39.02],
    [39.01],
    [39.0],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [39.02],
    [39.0],
    [39.0],
    [39.01],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.07],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.09],
    [39.07],
    [39.07],
    [39.1],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.08],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.03],
    [39.01],
    [39.0],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.94],
    [38.92],
    [38.92],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.85],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.88],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.86],
    [38.86],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.76],
    [38.74],
    [38.74],
    [38.72],
    [38.72],
    [38.71],
    [38.67],
    [38.69],
    [38.67],
    [38.64],
    [38.64],
    [38.62],
    [38.61],
    [38.62],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.61],
    [38.61],
    [38.61],
    [38.61],
    [38.6],
    [38.61],
    [38.61],
    [38.62],
    [38.61],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.64],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.65],
    [38.67],
    [38.64],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.68],
    [38.68],
    [38.68],
    [38.7],
    [38.7],
    [38.69],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.7],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.71],
    [38.72],
    [38.7],
    [38.69],
    [38.68],
    [38.660000000000004],
    [38.65],
    [38.65],
    [38.64],
    [38.61],
    [38.61],
    [38.6],
    [38.58],
    [38.58],
    [38.57],
    [38.58],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.56],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.54],
    [38.54],
    [38.56],
    [38.57],
    [38.550000000000004],
    [38.57],
    [38.57],
    [38.57],
    [38.54],
    [38.56],
    [38.54],
    [38.57],
    [38.56],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.59],
    [38.59],
    [38.58],
    [38.58],
    [38.59],
    [38.57],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.6],
    [38.57],
    [38.57],
    [38.57],
    [38.58],
    [38.57],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.52],
    [38.51],
    [38.52],
    [38.5],
    [38.5],
    [38.49],
    [38.49],
    [38.46],
    [38.44],
    [38.43],
    [38.42],
    [38.43],
    [38.42],
    [38.42],
    [38.4],
    [38.410000000000004],
    [38.39],
    [38.39],
    [38.38],
    [38.38],
    [38.38],
    [38.36],
    [38.35],
    [38.34],
    [38.32],
    [38.33],
    [38.33],
    [38.33],
    [38.33],
    [38.33],
    [38.32],
    [38.33],
    [38.32],
    [38.33],
    [38.31],
    [38.32],
    [38.32],
    [38.31],
    [38.31],
    [38.31],
    [38.31],
    [38.31],
    [38.32],
    [38.32],
    [38.33],
    [38.32],
    [38.35],
    [38.34],
    [38.33],
    [38.32],
    [38.31],
    [38.32],
    [38.300000000000004],
    [38.28],
    [38.29],
    [38.26],
    [38.28],
    [38.28],
    [38.25],
    [38.28],
    [38.28],
    [38.27],
    [38.25],
    [38.25],
    [38.25],
    [38.25],
    [38.27],
    [38.27],
    [38.28],
    [38.27],
    [38.28],
    [38.300000000000004],
    [38.32],
    [38.300000000000004],
    [38.300000000000004],
    [38.32],
    [38.33],
    [38.35],
    [38.38],
    [38.37],
    [38.38],
    [38.38],
    [38.39],
    [38.4],
    [38.4],
    [38.410000000000004],
    [38.42],
    [38.42],
    [38.42],
    [38.43],
    [38.44],
    [38.45],
    [38.46],
    [38.47],
    [38.46],
    [38.480000000000004],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.5],
    [38.52],
    [38.5],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.57],
    [38.57],
    [38.57],
    [38.58],
    [38.59],
    [38.6],
    [38.61],
    [38.6],
    [38.6],
    [38.62],
    [38.6],
    [38.62],
    [38.62],
    [38.63],
    [38.62],
    [38.62],
    [38.61],
    [38.6],
    [38.6],
    [38.61],
    [38.58],
    [38.57],
    [38.58],
    [38.57],
    [38.57],
    [38.57],
    [38.550000000000004],
    [38.550000000000004],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.53],
    [38.52],
    [38.54],
    [38.52],
    [38.53],
    [38.53],
    [38.53],
    [38.52],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.52],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.56],
    [38.57],
    [38.58],
    [38.6],
    [38.61],
    [38.63],
    [38.64],
    [38.65],
    [38.67],
    [38.68],
    [38.69],
    [38.71],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.77],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.800000000000004],
    [38.79],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.800000000000004],
    [38.82],
    [38.81],
    [38.83],
    [38.82],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.86],
    [38.86],
    [38.86],
    [38.87],
    [38.87],
    [38.87],
    [38.87],
    [38.87],
    [38.85],
    [38.84],
    [38.81],
    [38.78],
    [38.78],
    [38.77],
    [38.77],
    [38.75],
    [38.75],
    [38.74],
    [38.71],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.7],
    [38.69],
    [38.69],
    [38.7],
    [38.69],
    [38.68],
    [38.7],
    [38.69],
    [38.69],
    [38.71],
    [38.72],
    [38.71],
    [38.730000000000004],
    [38.72],
    [38.730000000000004],
    [38.75],
    [38.76],
    [38.77],
    [38.75],
    [38.76],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.79],
    [38.82],
    [38.81],
    [38.82],
    [38.82],
    [38.83],
    [38.82],
    [38.84],
    [38.84],
    [38.85],
    [38.82],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.87],
    [38.87],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.88],
    [38.86],
    [38.86],
    [38.85],
    [38.83],
    [38.82],
    [38.82],
    [38.800000000000004],
    [38.81],
    [38.81],
    [38.800000000000004],
    [38.81],
    [38.81],
    [38.81],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.800000000000004],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.77],
    [38.75],
    [38.72],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.69],
    [38.69],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.72],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.77],
    [38.76],
    [38.77],
    [38.78],
    [38.77],
    [38.79],
    [38.79],
    [38.79],
    [38.81],
    [38.81],
    [38.82],
    [38.82],
    [38.82],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.87],
    [38.88],
    [38.88],
    [38.9],
    [38.9],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.95],
    [38.95],
    [38.94],
    [38.96],
    [38.96],
    [38.94],
    [38.96],
    [38.95],
    [38.94],
    [38.93],
    [38.93],
    [38.92],
    [38.93],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.94],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.95],
    [38.95],
    [38.96],
    [38.97],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.06],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.09],
    [39.1],
    [39.11, EVENTS.FEVER_DETECTED],
    [39.13],
    [39.15],
    [39.15],
    [39.15],
    [39.15],
    [39.18],
    [39.17],
    [39.2],
    [39.19],
    [39.19],
    [39.2],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.22],
    [39.230000000000004],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.25],
    [39.26],
    [39.26],
    [39.25],
    [39.27],
    [39.27],
    [39.27],
    [39.26],
    [39.25],
    [39.25],
    [39.24],
    [39.230000000000004],
    [39.25],
    [39.230000000000004],
    [39.21],
    [39.19],
    [39.19],
    [39.17],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.13],
    [39.1],
    [39.09],
    [39.08],
    [39.08],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.07],
    [39.06],
    [39.06],
    [39.06],
    [39.06],
    [39.04],
    [39.06],
    [39.04],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.01],
    [39.03],
    [39.01],
    [39.01],
    [39.01],
    [39.02],
    [39.01],
    [39.0],
    [39.02],
    [39.01],
    [39.01],
    [39.03],
    [39.02],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [38.97],
    [38.97],
    [38.96],
    [38.96],
    [38.93],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.92],
    [38.92],
    [38.95],
    [38.94],
    [38.93],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.01],
    [39.01],
    [39.02],
    [39.02],
    [39.01],
    [39.01],
    [39.03],
    [39.01],
    [39.04],
    [39.04],
    [39.04],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.07],
    [39.07],
    [39.09],
    [39.08],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.13],
    [39.13],
    [39.13],
    [39.12],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.13],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.15],
    [39.13],
    [39.14],
    [39.12],
    [39.1],
    [39.11],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.08],
    [39.09],
    [39.07],
    [39.1],
    [39.1],
    [39.09],
    [39.09],
    [39.11],
    [39.12],
    [39.12],
    [39.11],
    [39.13],
    [39.12],
    [39.14],
    [39.13],
    [39.14],
    [39.11],
    [39.12],
    [39.11],
    [39.1],
    [39.11],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.09],
    [39.09],
    [39.1],
    [39.09],
    [39.08],
    [39.08],
    [39.07],
    [39.06],
    [39.06],
    [39.03],
    [39.03],
    [39.01],
    [39.0],
    [38.99],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.96],
    [38.95],
    [38.93],
    [38.94],
    [38.92],
    [38.92],
    [38.9],
    [38.89],
    [38.88],
    [38.88],
    [38.88],
    [38.88],
    [38.87],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.86],
    [38.86],
    [38.87],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.9],
    [38.89],
    [38.9],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.87],
    [38.88],
    [38.89],
    [38.88],
    [38.89],
    [38.88],
    [38.88],
    [38.88],
    [38.86],
    [38.87],
    [38.87],
    [38.89],
    [38.9],
    [38.89],
    [38.89],
    [38.92],
    [38.92],
    [38.94],
    [38.93],
    [38.94],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.99],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.01],
    [39.02],
    [39.0],
    [39.02],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.04],
    [39.01],
    [39.03],
    [39.02],
    [39.02],
    [39.03],
    [39.02],
    [39.01],
    [39.0],
    [38.980000000000004],
    [38.96],
    [38.97],
    [38.94],
    [38.94],
    [38.95],
    [38.94],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.93],
    [38.92],
    [38.94],
    [38.94],
    [38.94],
    [38.95],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.99],
    [38.97],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.050000000000004],
    [39.050000000000004],
    [39.07],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.09],
    [39.1],
    [39.1],
    [39.12],
    [39.1],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.2],
    [39.2],
    [39.21],
    [39.2],
    [39.21],
    [39.22],
    [39.21],
    [39.22],
    [39.24],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.26],
    [39.25],
    [39.26],
    [39.27],
    [39.27],
    [39.28],
    [39.28],
    [39.26],
    [39.26],
    [39.25],
    [39.25],
    [39.25],
    [39.22],
    [39.22],
    [39.21],
    [39.2],
    [39.2],
    [39.2],
    [39.19],
    [39.2],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.2],
    [39.19],
    [39.2],
    [39.21],
    [39.2],
    [39.2],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.14],
    [39.12],
    [39.14],
    [39.11],
    [39.11],
    [39.11],
    [39.12],
    [39.1],
    [39.1],
    [39.1],
    [39.08],
    [39.09],
    [39.09],
    [39.06],
    [39.06],
    [39.06],
    [39.06],
    [39.06],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.06],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.04],
    [39.03],
    [39.03],
    [39.01],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.01],
    [39.01],
    [39.02],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.01],
    [39.01],
    [39.0],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.0],
    [39.01],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [39.01],
    [39.03],
    [39.01],
    [39.02],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.07],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.04],
    [39.02],
    [39.02],
    [39.02],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [38.96],
    [38.96],
    [38.95],
    [38.92],
    [38.92],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.86],
    [38.86],
    [38.86, EVENTS.FEVER_OVER],
    [38.86],
    [38.85],
    [38.84],
    [38.84],
    [38.82],
    [38.82],
    [38.83],
    [38.81],
    [38.800000000000004],
    [38.81],
    [38.800000000000004],
    [38.800000000000004],
    [38.79],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.77],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.76],
    [38.75],
    [38.74],
    [38.74],
    [38.72],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.7],
    [38.69],
    [38.71],
    [38.7],
    [38.69],
    [38.69],
    [38.71],
    [38.71],
    [38.7],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.69],
    [38.69],
    [38.7],
    [38.69],
    [38.68],
    [38.68],
    [38.67],
    [38.68],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.69],
    [38.71],
    [38.72],
    [38.72],
    [38.71],
    [38.730000000000004],
    [38.72],
    [38.730000000000004],
    [38.74],
    [38.76],
    [38.76],
    [38.75],
    [38.77],
    [38.78],
    [38.78],
    [38.79],
    [38.78],
    [38.79],
    [38.81],
    [38.81],
    [38.79],
    [38.83],
    [38.82],
    [38.82],
    [38.84],
    [38.85],
    [38.86],
    [38.86],
    [38.86],
    [38.87],
    [38.87],
    [38.88],
    [38.87],
    [38.88],
    [38.85],
    [38.84],
    [38.85],
    [38.84],
    [38.83],
    [38.83],
    [38.82],
    [38.82],
    [38.83],
    [38.83],
    [38.82],
    [38.84],
    [38.84],
    [38.85],
    [38.86],
    [38.87],
    [38.87],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.92],
    [38.89],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.94],
    [38.93],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.97],
    [38.99],
    [38.99],
    [39.0],
    [38.99],
    [39.0],
    [38.99],
    [38.99],
    [39.0],
    [39.01],
    [39.02],
    [39.01],
    [39.02],
    [39.06],
    [39.04],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.12],
    [39.11],
    [39.11],
    [39.13],
    [39.12],
    [39.11],
    [39.14],
    [39.13],
    [39.11],
    [39.11],
    [39.1],
    [39.11],
    [39.11],
    [39.1],
    [39.08],
    [39.1],
    [39.07],
    [39.03],
    [39.0],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.88],
    [38.87],
    [38.85],
    [38.83],
    [38.83],
    [38.83],
    [38.82],
    [38.82],
    [38.81],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.76],
    [38.74],
    [38.7],
    [38.71],
    [38.68],
    [38.68],
    [38.660000000000004],
    [38.660000000000004],
    [38.63],
    [38.6],
    [38.6],
    [38.550000000000004],
    [38.550000000000004],
    [38.53],
    [38.52],
    [38.5],
    [38.5],
    [38.49],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.53],
    [38.54],
    [38.53],
    [38.56],
    [38.57],
    [38.56],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.52],
    [38.52],
    [38.5],
    [38.5],
    [38.47],
    [38.480000000000004],
    [38.47],
    [38.47],
    [38.46],
    [38.47],
    [38.47],
    [38.47],
    [38.46],
    [38.46],
    [38.47],
    [38.47],
    [38.480000000000004],
    [38.47],
    [38.480000000000004],
    [38.47],
    [38.46],
    [38.46],
    [38.47],
    [38.47],
    [38.480000000000004],
    [38.47],
    [38.46],
    [38.47],
    [38.480000000000004],
    [38.49],
    [38.49],
    [38.49],
    [38.49],
    [38.5],
    [38.51],
    [38.52],
    [38.5],
    [38.53],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.58],
    [38.58],
    [38.57],
    [38.58],
    [38.59],
    [38.59],
    [38.59],
    [38.57],
    [38.57],
    [38.56],
    [38.54],
    [38.57],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.51],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.47],
    [38.46],
    [38.46],
    [38.44],
    [38.46],
    [38.44],
    [38.45],
    [38.45],
    [38.46],
    [38.46],
    [38.47],
    [38.46],
    [38.47],
    [38.47],
    [38.480000000000004],
    [38.480000000000004],
    [38.46],
    [38.480000000000004],
    [38.480000000000004],
    [38.49],
    [38.49],
    [38.49],
    [38.49],
    [38.480000000000004],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.52],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.52],
    [38.52],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.57],
    [38.58],
    [38.59],
    [38.57],
    [38.59],
    [38.61],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.61],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.6],
    [38.6],
    [38.59],
    [38.58],
    [38.57],
    [38.58],
    [38.57],
    [38.57],
    [38.57],
    [38.56],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.52],
    [38.51],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.480000000000004],
    [38.49],
    [38.46],
    [38.480000000000004],
    [38.47],
    [38.47],
    [38.46],
    [38.45],
    [38.43],
    [38.45],
    [38.43],
    [38.45],
    [38.44],
    [38.44],
    [38.42],
    [38.43],
    [38.45],
    [38.45],
    [38.45],
    [38.46],
    [38.47],
    [38.480000000000004],
    [38.49],
    [38.47],
    [38.5],
    [38.47],
    [38.5],
    [38.51],
    [38.52],
    [38.52],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.56],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.57],
    [38.57],
    [38.56],
    [38.550000000000004],
    [38.54],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.56],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.57],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.54],
    [38.53],
    [38.53],
    [38.54],
    [38.54],
    [38.57],
    [38.57],
    [38.57],
    [38.59],
    [38.59],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.62],
    [38.63],
    [38.64],
    [38.65],
    [38.68],
    [38.67],
    [38.68],
    [38.67],
    [38.69],
    [38.7],
    [38.7],
    [38.71],
    [38.7],
    [38.71],
    [38.72],
    [38.72],
    [38.74],
    [38.75],
    [38.74],
    [38.74],
    [38.75],
    [38.77],
    [38.75],
    [38.77],
    [38.78],
    [38.78],
    [38.81],
    [38.82],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.82],
    [38.84],
    [38.85],
    [38.84],
    [38.84],
    [38.85],
    [38.84],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.83],
    [38.83],
    [38.82],
    [38.83],
    [38.82],
    [38.83],
    [38.82],
    [38.82],
    [38.82],
    [38.81],
    [38.78],
    [38.79],
    [38.77],
    [38.76],
    [38.77],
    [38.75],
    [38.730000000000004],
    [38.75],
    [38.730000000000004],
    [38.72],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.69],
    [38.7],
    [38.69],
    [38.7],
    [38.68],
    [38.71],
    [38.7],
    [38.71],
    [38.71],
    [38.72],
    [38.7],
    [38.71],
    [38.71],
    [38.72],
    [38.75],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.76],
    [38.77],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.81],
    [38.83],
    [38.81],
    [38.85],
    [38.84],
    [38.85],
    [38.87],
    [38.87],
    [38.86],
    [38.9],
    [38.9],
    [38.9],
    [38.92],
    [38.94],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.01],
    [39.02],
    [39.01],
    [39.01],
    [39.02],
    [39.03],
    [39.03],
    [39.02],
    [39.04],
    [39.03],
    [39.04],
    [39.04],
    [39.03],
    [39.04],
    [39.03],
    [39.01],
    [39.0],
    [39.0],
    [39.01],
    [39.01],
    [39.02],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [38.99],
    [38.95],
    [38.95],
    [38.93],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.89],
    [38.9],
    [38.9],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.9],
    [38.9],
    [38.89],
    [38.9],
    [38.89],
    [38.88],
    [38.87],
    [38.86],
    [38.83],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.84],
    [38.84],
    [38.83],
    [38.85],
    [38.83],
    [38.83],
    [38.83],
    [38.82],
    [38.83],
    [38.81],
    [38.82],
    [38.800000000000004],
    [38.81],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.800000000000004],
    [38.79],
    [38.800000000000004],
    [38.800000000000004],
    [38.79],
    [38.81],
    [38.78],
    [38.81],
    [38.83],
    [38.83],
    [38.83],
    [38.85],
    [38.85],
    [38.87],
    [38.86],
    [38.88],
    [38.89],
    [38.910000000000004],
    [38.89],
    [38.910000000000004],
    [38.93],
    [38.93],
    [38.95],
    [38.95],
    [38.95],
    [38.97],
    [38.96],
    [38.97],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.97],
    [38.96],
    [38.95],
    [38.95],
    [38.96],
    [38.95],
    [38.96],
    [38.94],
    [38.96],
    [38.95],
    [38.93],
    [38.93],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.88],
    [38.9],
    [38.87],
    [38.87],
    [38.87],
    [38.86],
    [38.85],
    [38.85],
    [38.82],
    [38.81],
    [38.79],
    [38.79],
    [38.79],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.77],
    [38.77],
    [38.76],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.76],
    [38.77],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.76],
    [38.76],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.79],
    [38.81],
    [38.83],
    [38.82],
    [38.86],
    [38.85],
    [38.85],
    [38.88],
    [38.89],
    [38.9],
    [38.95],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.01],
    [39.01],
    [39.04],
    [39.04],
    [39.06],
    [39.07],
    [39.09],
    [39.1],
    [39.1, EVENTS.FEVER_DETECTED],
    [39.12],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.12],
    [39.13],
    [39.11],
    [39.13],
    [39.12],
    [39.12],
    [39.11],
    [39.14],
    [39.12],
    [39.11],
    [39.11],
    [39.14],
    [39.13],
    [39.14],
    [39.12],
    [39.12],
    [39.12],
    [39.12],
    [39.12],
    [39.12],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.19],
    [39.19],
    [39.2],
    [39.18],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.24],
    [39.24],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.18],
    [39.19],
    [39.19],
    [39.21],
    [39.21],
    [39.22],
    [39.230000000000004],
    [39.24],
    [39.22],
    [39.25],
    [39.25],
    [39.26],
    [39.26],
    [39.26],
    [39.26],
    [39.26],
    [39.26],
    [39.25],
    [39.25],
    [39.26],
    [39.26],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.25],
    [39.230000000000004],
    [39.230000000000004],
    [39.22],
    [39.21],
    [39.230000000000004],
    [39.22],
    [39.22],
    [39.230000000000004],
    [39.21],
    [39.22],
    [39.2],
    [39.21],
    [39.2],
    [39.19],
    [39.2],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.14],
    [39.15],
    [39.17],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.12],
    [39.12],
    [39.14],
    [39.12],
    [39.13],
    [39.14],
    [39.13],
    [39.13],
    [39.15],
    [39.14],
    [39.13],
    [39.12],
    [39.11],
    [39.12],
    [39.1],
    [39.1],
    [39.07],
    [39.08],
    [39.07],
    [39.04],
    [39.04],
    [39.04],
    [39.04],
    [39.03],
    [39.03],
    [39.04],
    [39.01],
    [39.02],
    [39.03],
    [39.02],
    [39.02],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.94],
    [38.94],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.94],
    [38.96],
    [38.980000000000004],
    [38.99],
    [39.01],
    [39.02],
    [39.03],
    [39.01],
    [39.03],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.08],
    [39.06],
    [39.09],
    [39.1],
    [39.1],
    [39.11],
    [39.13],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.19],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.25],
    [39.26],
    [39.25],
    [39.27],
    [39.28],
    [39.28],
    [39.29],
    [39.300000000000004],
    [39.31],
    [39.32],
    [39.32],
    [39.32],
    [39.32],
    [39.32],
    [39.34],
    [39.33],
    [39.32],
    [39.32],
    [39.32],
    [39.300000000000004],
    [39.31],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.28],
    [39.28],
    [39.300000000000004],
    [39.28],
    [39.27],
    [39.25],
    [39.24],
    [39.22],
    [39.21],
    [39.19],
    [39.2],
    [39.17],
    [39.17],
    [39.15],
    [39.14],
    [39.14],
    [39.13],
    [39.11],
    [39.11],
    [39.1],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.03],
    [39.01],
    [39.03],
    [39.02],
    [39.02],
    [39.01],
    [38.980000000000004],
    [38.99],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.97],
    [38.96],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.99],
    [38.99],
    [39.0],
    [38.980000000000004],
    [39.01],
    [39.0],
    [39.01],
    [39.01],
    [39.03],
    [39.01],
    [39.04],
    [39.04],
    [39.03],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.050000000000004],
    [39.06],
    [39.04],
    [39.07],
    [39.07],
    [39.09],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.1],
    [39.08],
    [39.07],
    [39.08],
    [39.08],
    [39.08],
    [39.08],
    [39.07],
    [39.08],
    [39.09],
    [39.08],
    [39.09],
    [39.09],
    [39.1],
    [39.08],
    [39.09],
    [39.1],
    [39.11],
    [39.09],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.12],
    [39.11],
    [39.12],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.01],
    [39.01],
    [39.02],
    [39.02],
    [39.02],
    [39.03],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.09],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.11],
    [39.13],
    [39.12],
    [39.14],
    [39.12],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.1],
    [39.12],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.09],
    [39.09],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.12],
    [39.12],
    [39.11],
    [39.14],
    [39.13],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.19],
    [39.18],
    [39.19],
    [39.19],
    [39.19],
    [39.19],
    [39.19],
    [39.19],
    [39.18],
    [39.18],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.13],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.11],
    [39.13],
    [39.12],
    [39.09],
    [39.09],
    [39.1],
    [39.1],
    [39.09],
    [39.08],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.08],
    [39.06],
    [39.08],
    [39.07],
    [39.08],
    [39.07],
    [39.08],
    [39.08],
    [39.07],
    [39.06],
    [39.06],
    [39.03],
    [39.04],
    [39.03],
    [39.04],
    [39.03],
    [39.04],
    [39.04],
    [39.02],
    [39.03],
    [39.03],
    [39.01],
    [39.03],
    [39.04],
    [39.04],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.08],
    [39.09],
    [39.09],
    [39.09],
    [39.11],
    [39.11],
    [39.13],
    [39.12],
    [39.13],
    [39.13],
    [39.12],
    [39.14],
    [39.1],
    [39.11],
    [39.1],
    [39.09],
    [39.07],
    [39.08],
    [39.07],
    [39.06],
    [39.07],
    [39.06],
    [39.03],
    [39.02],
    [39.0],
    [39.0],
    [38.99],
    [38.99],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.94],
    [38.93],
    [38.93],
    [38.94],
    [38.92],
    [38.92],
    [38.89],
    [38.88],
    [38.88],
    [38.87],
    [38.85],
    [38.87],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85, EVENTS.FEVER_OVER],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.83],
    [38.83],
    [38.83],
    [38.83],
    [38.84],
    [38.82],
    [38.83],
    [38.83],
    [38.84],
    [38.84],
    [38.86],
    [38.85],
    [38.87],
    [38.88],
    [38.87],
    [38.87],
    [38.88],
    [38.88],
    [38.89],
    [38.910000000000004],
    [38.89],
    [38.92],
    [38.92],
    [38.92],
    [38.94],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.97],
    [38.96],
    [38.94],
    [38.95],
    [38.92],
    [38.93],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.9],
    [38.92],
    [38.9],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.94],
    [38.92],
    [38.94],
    [38.94],
    [38.95],
    [38.96],
    [38.96],
    [38.97],
    [39.0],
    [38.99],
    [38.99],
    [39.01],
    [39.01],
    [39.02],
    [39.07],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.06],
    [39.07],
    [39.08],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.12, EVENTS.FEVER_DETECTED],
    [39.11],
    [39.13],
    [39.14],
    [39.13],
    [39.13],
    [39.12],
    [39.14],
    [39.13],
    [39.14],
    [39.13],
    [39.12],
    [39.12],
    [39.12],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.15],
    [39.18],
    [39.15],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.19],
    [39.2],
    [39.19],
    [39.2],
    [39.19],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.13],
    [39.11],
    [39.1],
    [39.1],
    [39.08],
    [39.09],
    [39.07],
    [39.04],
    [39.01],
    [39.0],
    [38.99],
    [38.95],
    [38.93],
    [38.92],
    [38.89],
    [38.89],
    [38.88],
    [38.87],
    [38.86],
    [38.85, EVENTS.FEVER_OVER],
    [38.83],
    [38.85],
    [38.85],
    [38.84],
    [38.84],
    [38.83],
    [38.83],
    [38.82],
    [38.85],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.87],
    [38.87],
    [38.88],
    [38.86],
    [38.87],
    [38.87],
    [38.87],
    [38.89],
    [38.87],
    [38.87],
    [38.88],
    [38.87],
    [38.87],
    [38.87],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.83],
    [38.83],
    [38.82],
    [38.800000000000004],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.75],
    [38.75],
    [38.74],
    [38.74],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.67],
    [38.67],
    [38.65],
    [38.64],
    [38.63],
    [38.63],
    [38.6],
    [38.59],
    [38.58],
    [38.57],
    [38.57],
    [38.57],
    [38.56],
    [38.56],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.54],
    [38.53],
    [38.54],
    [38.54],
    [38.56],
    [38.56],
    [38.56],
    [38.57],
    [38.58],
    [38.6],
    [38.6],
    [38.61],
    [38.6],
    [38.61],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.6],
    [38.62],
    [38.6],
    [38.61],
    [38.61],
    [38.6],
    [38.6],
    [38.59],
    [38.58],
    [38.59],
    [38.57],
    [38.59],
    [38.59],
    [38.58],
    [38.58],
    [38.58],
    [38.59],
    [38.6],
    [38.59],
    [38.57],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.56],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.53],
    [38.53],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.56],
    [38.57],
    [38.57],
    [38.56],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.6],
    [38.6],
    [38.6],
    [38.62],
    [38.62],
    [38.62],
    [38.63],
    [38.64],
    [38.63],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.660000000000004],
    [38.64],
    [38.63],
    [38.64],
    [38.62],
    [38.63],
    [38.62],
    [38.61],
    [38.6],
    [38.59],
    [38.6],
    [38.61],
    [38.6],
    [38.58],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.61],
    [38.62],
    [38.62],
    [38.63],
    [38.62],
    [38.63],
    [38.62],
    [38.61],
    [38.6],
    [38.6],
    [38.59],
    [38.58],
    [38.59],
    [38.57],
    [38.57],
    [38.57],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.53],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.53],
    [38.56],
    [38.54],
    [38.54],
    [38.54],
    [38.53],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.56],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.57],
    [38.56],
    [38.57],
    [38.57],
    [38.56],
    [38.57],
    [38.54],
    [38.53],
    [38.53],
    [38.54],
    [38.52],
    [38.53],
    [38.53],
    [38.52],
    [38.5],
    [38.5],
    [38.5],
    [38.49],
    [38.5],
    [38.480000000000004],
    [38.480000000000004],
    [38.47],
    [38.46],
    [38.47],
    [38.46],
    [38.47],
    [38.47],
    [38.47],
    [38.47],
    [38.47],
    [38.47],
    [38.46],
    [38.46],
    [38.47],
    [38.46],
    [38.45],
    [38.45],
    [38.43],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.410000000000004],
    [38.43],
    [38.42],
    [38.410000000000004],
    [38.410000000000004],
    [38.42],
    [38.42],
    [38.42],
    [38.4],
    [38.410000000000004],
    [38.410000000000004],
    [38.410000000000004],
    [38.42],
    [38.43],
    [38.410000000000004],
    [38.410000000000004],
    [38.410000000000004],
    [38.42],
    [38.410000000000004],
    [38.410000000000004],
    [38.42],
    [38.410000000000004],
    [38.42],
    [38.42],
    [38.43],
    [38.42],
    [38.42],
    [38.42],
    [38.43],
    [38.43],
    [38.43],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.410000000000004],
    [38.42],
    [38.42],
    [38.42],
    [38.410000000000004],
    [38.410000000000004],
    [38.39],
    [38.39],
    [38.410000000000004],
    [38.410000000000004],
    [38.4],
    [38.4],
    [38.410000000000004],
    [38.42],
    [38.43],
    [38.44],
    [38.45],
    [38.45],
    [38.45],
    [38.46],
    [38.46],
    [38.47],
    [38.49],
    [38.49],
    [38.51],
    [38.5],
    [38.53],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.57],
    [38.57],
    [38.58],
    [38.59],
    [38.6],
    [38.61],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.68],
    [38.69],
    [38.7],
    [38.69],
    [38.69],
    [38.69],
    [38.7],
    [38.7],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.7],
    [38.71],
    [38.7],
    [38.71],
    [38.7],
    [38.71],
    [38.7],
    [38.71],
    [38.71],
    [38.7],
    [38.7],
    [38.7],
    [38.71],
    [38.7],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.69],
    [38.7],
    [38.7],
    [38.7],
    [38.7],
    [38.71],
    [38.69],
    [38.68],
    [38.69],
    [38.69],
    [38.69],
    [38.68],
    [38.68],
    [38.68],
    [38.67],
    [38.67],
    [38.68],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.62],
    [38.61],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.6],
    [38.63],
    [38.64],
    [38.65],
    [38.65],
    [38.67],
    [38.65],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.69],
    [38.68],
    [38.7],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.7],
    [38.72],
    [38.71],
    [38.730000000000004],
    [38.730000000000004],
    [38.730000000000004],
    [38.75],
    [38.75],
    [38.77],
    [38.78],
    [38.78],
    [38.81],
    [38.83],
    [38.86],
    [38.85],
    [38.86],
    [38.87],
    [38.89],
    [38.88],
    [38.88],
    [38.88],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.88],
    [38.85],
    [38.84],
    [38.82],
    [38.82],
    [38.79],
    [38.78],
    [38.77],
    [38.76],
    [38.75],
    [38.74],
    [38.77],
    [38.74],
    [38.74],
    [38.74],
    [38.72],
    [38.74],
    [38.71],
    [38.71],
    [38.72],
    [38.7],
    [38.72],
    [38.71],
    [38.71],
    [38.74],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.74],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.72],
    [38.72],
    [38.71],
    [38.72],
    [38.74],
    [38.74],
    [38.75],
    [38.77],
    [38.76],
    [38.78],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.81],
    [38.83],
    [38.83],
    [38.86],
    [38.86],
    [38.87],
    [38.88],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.93],
    [38.93],
    [38.95],
    [38.96],
    [38.980000000000004],
    [38.99],
    [39.01],
    [39.01],
    [39.02],
    [39.03],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.07],
    [39.08],
    [39.1],
    [39.11],
    [39.12, EVENTS.FEVER_DETECTED],
    [39.14],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.19],
    [39.18],
    [39.2],
    [39.2],
    [39.21],
    [39.230000000000004],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.2],
    [39.18],
    [39.18],
    [39.17],
    [39.18],
    [39.18],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.19],
    [39.18],
    [39.17],
    [39.17],
    [39.19],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.18],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.13],
    [39.12],
    [39.11],
    [39.1],
    [39.07],
    [39.07],
    [39.06],
    [39.04],
    [39.01],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [38.96],
    [38.97],
    [38.97],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.97],
    [38.96],
    [38.99],
    [39.01],
    [39.01],
    [39.0],
    [39.03],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.09],
    [39.09],
    [39.08],
    [39.09],
    [39.08],
    [39.09],
    [39.1],
    [39.09],
    [39.1],
    [39.11],
    [39.12],
    [39.12],
    [39.11],
    [39.13],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.13],
    [39.12],
    [39.12],
    [39.13],
    [39.12],
    [39.11],
    [39.11],
    [39.13],
    [39.12],
    [39.12],
    [39.13],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.19],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.18],
    [39.14],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.19],
    [39.2],
    [39.21],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.24],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.27],
    [39.28],
    [39.28],
    [39.29],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.31],
    [39.32],
    [39.32],
    [39.32],
    [39.34],
    [39.35],
    [39.34],
    [39.34],
    [39.34],
    [39.35],
    [39.33],
    [39.35],
    [39.34],
    [39.34],
    [39.34],
    [39.32],
    [39.32],
    [39.31],
    [39.29],
    [39.28],
    [39.26],
    [39.230000000000004],
    [39.21],
    [39.19],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.14],
    [39.11],
    [39.11],
    [39.07],
    [39.08],
    [39.06],
    [39.06],
    [39.03],
    [39.01],
    [39.01],
    [38.980000000000004],
    [38.95],
    [38.94],
    [38.92],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.86],
    [38.87],
    [38.86],
    [38.86],
    [38.84],
    [38.85],
    [38.84, EVENTS.FEVER_OVER],
    [38.85],
    [38.84],
    [38.85],
    [38.83],
    [38.83],
    [38.84],
    [38.83],
    [38.84],
    [38.82],
    [38.84],
    [38.83],
    [38.82],
    [38.83],
    [38.84],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.87],
    [38.85],
    [38.85],
    [38.85],
    [38.83],
    [38.85],
    [38.85],
    [38.83],
    [38.83],
    [38.85],
    [38.84],
    [38.84],
    [38.83],
    [38.82],
    [38.84],
    [38.82],
    [38.83],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.86],
    [38.88],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.93],
    [38.97],
    [38.97],
    [38.97],
    [38.97],
    [38.980000000000004],
    [39.0],
    [39.01],
    [39.01],
    [39.03],
    [39.03],
    [39.03],
    [39.06],
    [39.06],
    [39.07],
    [39.08],
    [39.1],
    [39.11],
    [39.11, EVENTS.FEVER_DETECTED],
    [39.13],
    [39.13],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.2],
    [39.2],
    [39.18],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.24],
    [39.24],
    [39.230000000000004],
    [39.230000000000004],
    [39.24],
    [39.24],
    [39.230000000000004],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.2],
    [39.18],
    [39.19],
    [39.19],
    [39.19],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.11],
    [39.13],
    [39.13],
    [39.12],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.09],
    [39.08],
    [39.09],
    [39.07],
    [39.08],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.09],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.08],
    [39.07],
    [39.09],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.06],
    [39.08],
    [39.06],
    [39.06],
    [39.07],
    [39.050000000000004],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.04],
    [39.03],
    [39.01],
    [39.01],
    [39.0],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.99],
    [38.97],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.01],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.06],
    [39.06],
    [39.06],
    [39.07],
    [39.1],
    [39.08],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.12],
    [39.12],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.15],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.15],
    [39.17],
    [39.18],
    [39.2],
    [39.18],
    [39.19],
    [39.19],
    [39.2],
    [39.19],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.22],
    [39.230000000000004],
    [39.25],
    [39.26],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.26],
    [39.27],
    [39.28],
    [39.28],
    [39.28],
    [39.29],
    [39.28],
    [39.29],
    [39.29],
    [39.300000000000004],
    [39.300000000000004],
    [39.32],
    [39.32],
    [39.32],
    [39.31],
    [39.31],
    [39.29],
    [39.300000000000004],
    [39.29],
    [39.28],
    [39.26],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.230000000000004],
    [39.22],
    [39.22],
    [39.21],
    [39.2],
    [39.19],
    [39.19],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.21],
    [39.19],
    [39.19],
    [39.2],
    [39.21],
    [39.21],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.25],
    [39.24],
    [39.26],
    [39.25],
    [39.27],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.29],
    [39.300000000000004],
    [39.29],
    [39.28],
    [39.28],
    [39.28],
    [39.27],
    [39.27],
    [39.26],
    [39.27],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.26],
    [39.28],
    [39.28],
    [39.26],
    [39.27],
    [39.26],
    [39.25],
    [39.25],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.22],
    [39.2],
    [39.21],
    [39.19],
    [39.18],
    [39.18],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.15],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.15],
    [39.17],
    [39.15],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.13],
    [39.12],
    [39.14],
    [39.12],
    [39.12],
    [39.12],
    [39.11],
    [39.1],
    [39.09],
    [39.08],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.04],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.0],
    [38.99],
    [39.02],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.95],
    [38.93],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.87],
    [38.89],
    [38.88],
    [38.89],
    [38.86],
    [38.85, EVENTS.FEVER_OVER],
    [38.87],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.79],
    [38.78],
    [38.76],
    [38.78],
    [38.75],
    [38.75],
    [38.74],
    [38.72],
    [38.71],
    [38.68],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.63],
    [38.62],
    [38.63],
    [38.64],
    [38.62],
    [38.61],
    [38.63],
    [38.62],
    [38.62],
    [38.63],
    [38.64],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.65],
    [38.67],
    [38.68],
    [38.69],
    [38.7],
    [38.7],
    [38.7],
    [38.71],
    [38.75],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.78],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.81],
    [38.82],
    [38.83],
    [38.83],
    [38.84],
    [38.85],
    [38.84],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.87],
    [38.89],
    [38.88],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.9],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.87],
    [38.86],
    [38.86],
    [38.84],
    [38.84],
    [38.84],
    [38.84],
    [38.83],
    [38.83],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.84],
    [38.84],
    [38.84],
    [38.82],
    [38.84],
    [38.84],
    [38.85],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.89],
    [38.87],
    [38.86],
    [38.87],
    [38.89],
    [38.85],
    [38.86],
    [38.85],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.78],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.76],
    [38.76],
    [38.75],
    [38.77],
    [38.75],
    [38.75],
    [38.74],
    [38.74],
    [38.75],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.71],
    [38.7],
    [38.7],
    [38.69],
    [38.67],
    [38.67],
    [38.67],
    [38.64],
    [38.660000000000004],
    [38.65],
    [38.65],
    [38.63],
    [38.64],
    [38.63],
    [38.63],
    [38.64],
    [38.63],
    [38.63],
    [38.62],
    [38.61],
    [38.61],
    [38.6],
    [38.58],
    [38.6],
    [38.59],
    [38.57],
    [38.57],
    [38.57],
    [38.56],
    [38.57],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.57],
    [38.57],
    [38.57],
    [38.58],
    [38.58],
    [38.57],
    [38.59],
    [38.58],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.58],
    [38.58],
    [38.58],
    [38.58],
    [38.6],
    [38.56],
    [38.57],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.57],
    [38.57],
    [38.59],
    [38.56],
    [38.57],
    [38.57],
    [38.550000000000004],
    [38.56],
    [38.57],
    [38.56],
    [38.56],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.56],
    [38.58],
    [38.57],
    [38.58],
    [38.58],
    [38.57],
    [38.57],
    [38.58],
    [38.58],
    [38.58],
    [38.59],
    [38.58],
    [38.6],
    [38.6],
    [38.61],
    [38.59],
    [38.6],
    [38.61],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.62],
    [38.61],
    [38.61],
    [38.63],
    [38.61],
    [38.62],
    [38.62],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.65],
    [38.65],
    [38.65],
    [38.67],
    [38.67],
    [38.64],
    [38.62],
    [38.62],
    [38.62],
    [38.58],
    [38.57],
    [38.57],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.52],
    [38.52],
    [38.53],
    [38.5],
    [38.5],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.5],
    [38.51],
    [38.51],
    [38.53],
    [38.52],
    [38.53],
    [38.54],
    [38.53],
    [38.57],
    [38.54],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.59],
    [38.58],
    [38.6],
    [38.59],
    [38.6],
    [38.6],
    [38.61],
    [38.62],
    [38.62],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.67],
    [38.68],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.68],
    [38.67],
    [38.68],
    [38.69],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.64],
    [38.67],
    [38.65],
    [38.64],
    [38.65],
    [38.62],
    [38.61],
    [38.6],
    [38.59],
    [38.58],
    [38.58],
    [38.59],
    [38.58],
    [38.58],
    [38.57],
    [38.58],
    [38.57],
    [38.56],
    [38.57],
    [38.57],
    [38.56],
    [38.57],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.57],
    [38.59],
    [38.59],
    [38.57],
    [38.59],
    [38.57],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.56],
    [38.58],
    [38.57],
    [38.57],
    [38.58],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.6],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.6],
    [38.61],
    [38.62],
    [38.63],
    [38.63],
    [38.64],
    [38.63],
    [38.65],
    [38.65],
    [38.65],
    [38.65],
    [38.65],
    [38.65],
    [38.67],
    [38.660000000000004],
    [38.65],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.67],
    [38.69],
    [38.67],
    [38.67],
    [38.68],
    [38.69],
    [38.68],
    [38.7],
    [38.69],
    [38.69],
    [38.69],
    [38.69],
    [38.69],
    [38.69],
    [38.68],
    [38.7],
    [38.7],
    [38.7],
    [38.69],
    [38.7],
    [38.71],
    [38.72],
    [38.71],
    [38.72],
    [38.72],
    [38.74],
    [38.74],
    [38.74],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.730000000000004],
    [38.730000000000004],
    [38.72],
    [38.730000000000004],
    [38.72],
    [38.72],
    [38.72],
    [38.71],
    [38.730000000000004],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.72],
    [38.72],
    [38.72],
    [38.72],
    [38.74],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.71],
    [38.69],
    [38.68],
    [38.67],
    [38.67],
    [38.68],
    [38.69],
    [38.69],
    [38.65],
    [38.660000000000004],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.65],
    [38.65],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.65],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.65],
    [38.67],
    [38.660000000000004],
    [38.68],
    [38.67],
    [38.69],
    [38.71],
    [38.7],
    [38.7],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.74],
    [38.76],
    [38.78],
    [38.78],
    [38.79],
    [38.81],
    [38.82],
    [38.83],
    [38.85],
    [38.85],
    [38.86],
    [38.9],
    [38.88],
    [38.89],
    [38.9],
    [38.92],
    [38.92],
    [38.93],
    [38.95],
    [38.95],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.01],
    [39.02],
    [39.02],
    [39.03],
    [39.04],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.08],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.11],
    [39.12, EVENTS.FEVER_DETECTED],
    [39.12],
    [39.13],
    [39.13],
    [39.14],
    [39.13],
    [39.13],
    [39.1],
    [39.1],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.03],
    [39.0],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.0],
    [39.01],
    [39.01],
    [39.01],
    [39.0],
    [39.0],
    [39.01],
    [39.02],
    [39.01],
    [39.02],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.03],
    [39.02],
    [39.01],
    [39.0],
    [39.01],
    [38.99],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.94],
    [38.94],
    [38.93],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.87],
    [38.87],
    [38.85, EVENTS.FEVER_OVER],
    [38.83],
    [38.83],
    [38.82],
    [38.82],
    [38.78],
    [38.79],
    [38.77],
    [38.77],
    [38.78],
    [38.78],
    [38.77],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.82],
    [38.83],
    [38.83],
    [38.85],
    [38.88],
    [38.89],
    [38.89],
    [38.9],
    [38.92],
    [38.94],
    [38.94],
    [38.96],
    [38.99],
    [39.02],
    [39.04],
    [39.04],
    [39.06],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.06],
    [39.07],
    [39.07],
    [39.08],
    [39.09],
    [39.1],
    [39.09],
    [39.09],
    [39.1],
    [39.1],
    [39.09],
    [39.11],
    [39.11],
    [39.1],
    [39.12],
    [39.12],
    [39.1],
    [39.12, EVENTS.FEVER_DETECTED],
    [39.11],
    [39.12],
    [39.12],
    [39.12],
    [39.13],
    [39.13],
    [39.12],
    [39.12],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.15],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.19],
    [39.19],
    [39.21],
    [39.2],
    [39.21],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.25],
    [39.230000000000004],
    [39.25],
    [39.24],
    [39.25],
    [39.24],
    [39.26],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.27],
    [39.26],
    [39.27],
    [39.26],
    [39.26],
    [39.26],
    [39.26],
    [39.28],
    [39.27],
    [39.26],
    [39.26],
    [39.25],
    [39.27],
    [39.26],
    [39.26],
    [39.26],
    [39.27],
    [39.25],
    [39.28],
    [39.26],
    [39.27],
    [39.28],
    [39.28],
    [39.26],
    [39.28],
    [39.24],
    [39.25],
    [39.25],
    [39.230000000000004],
    [39.22],
    [39.22],
    [39.21],
    [39.19],
    [39.2],
    [39.19],
    [39.19],
    [39.19],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.13],
    [39.13],
    [39.11],
    [39.12],
    [39.12],
    [39.12],
    [39.1],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.08],
    [39.08],
    [39.1],
    [39.08],
    [39.08],
    [39.1],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.13],
    [39.11],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.12],
    [39.09],
    [39.1],
    [39.1],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.06],
    [39.06],
    [39.04],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.02],
    [39.04],
    [39.06],
    [39.04],
    [39.03],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.06],
    [39.04],
    [39.06],
    [39.06],
    [39.06],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.06],
    [39.04],
    [39.04],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.1],
    [39.1],
    [39.11],
    [39.12],
    [39.12],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.17],
    [39.19],
    [39.19],
    [39.19],
    [39.19],
    [39.21],
    [39.2],
    [39.2],
    [39.21],
    [39.2],
    [39.2],
    [39.2],
    [39.19],
    [39.2],
    [39.2],
    [39.2],
    [39.21],
    [39.21],
    [39.19],
    [39.2],
    [39.19],
    [39.18],
    [39.18],
    [39.17],
    [39.18],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.13],
    [39.1],
    [39.07],
    [39.07],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.0],
    [39.01],
    [39.0],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [38.96],
    [38.97],
    [38.97],
    [38.97],
    [38.95],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.01],
    [39.01],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.06],
    [39.07],
    [39.09],
    [39.09],
    [39.08],
    [39.1],
    [39.1],
    [39.12],
    [39.12],
    [39.14],
    [39.13],
    [39.15],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.18],
    [39.2],
    [39.18],
    [39.19],
    [39.21],
    [39.21],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.22],
    [39.21],
    [39.22],
    [39.22],
    [39.22],
    [39.2],
    [39.21],
    [39.17],
    [39.18],
    [39.17],
    [39.15],
    [39.13],
    [39.14],
    [39.12],
    [39.13],
    [39.1],
    [39.1],
    [39.09],
    [39.09],
    [39.1],
    [39.09],
    [39.09],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.1],
    [39.11],
    [39.11],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.15],
    [39.17],
    [39.17],
    [39.18],
    [39.2],
    [39.19],
    [39.19],
    [39.2],
    [39.2],
    [39.2],
    [39.21],
    [39.19],
    [39.21],
    [39.230000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.25],
    [39.25],
    [39.28],
    [39.27],
    [39.28],
    [39.28],
    [39.29],
    [39.28],
    [39.28],
    [39.29],
    [39.29],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.29],
    [39.29],
    [39.28],
    [39.28],
    [39.26],
    [39.28],
    [39.28],
    [39.28],
    [39.26],
    [39.27],
    [39.25],
    [39.25],
    [39.24],
    [39.22],
    [39.21],
    [39.18],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.14],
    [39.15],
    [39.14],
    [39.12],
    [39.11],
    [39.11],
    [39.13],
    [39.09],
    [39.1],
    [39.09],
    [39.09],
    [39.08],
    [39.08],
    [39.06],
    [39.07],
    [39.06],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.01],
    [39.02],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.99],
    [38.99],
    [38.980000000000004],
    [38.96],
    [38.95],
    [38.93],
    [38.92],
    [38.93],
    [38.93],
    [38.94],
    [38.93],
    [38.93],
    [38.93],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.94],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.87],
    [38.88],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.94],
    [38.94],
    [38.95],
    [38.93],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.96],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.06],
    [39.07],
    [39.07],
    [39.08],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.11],
    [39.1],
    [39.13],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.13],
    [39.13],
    [39.14],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.13],
    [39.15],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.17],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.13],
    [39.12],
    [39.12],
    [39.1],
    [39.1],
    [39.08],
    [39.07],
    [39.04],
    [39.04],
    [39.02],
    [39.0],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.96],
    [38.93],
    [38.93],
    [38.93],
    [38.92],
    [38.92],
    [38.9],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.86],
    [38.87],
    [38.85, EVENTS.FEVER_OVER],
    [38.87],
    [38.85],
    [38.82],
    [38.81],
    [38.82],
    [38.81],
    [38.81],
    [38.79],
    [38.81],
    [38.800000000000004],
    [38.82],
    [38.800000000000004],
    [38.83],
    [38.82],
    [38.82],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.88],
    [38.87],
    [38.89],
    [38.88],
    [38.89],
    [38.9],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.94],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.94],
    [38.94],
    [38.94],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.03],
    [39.01],
    [39.03],
    [39.0],
    [39.0],
    [38.97],
    [38.96],
    [38.94],
    [38.92],
    [38.89],
    [38.87],
    [38.84],
    [38.82],
    [38.800000000000004],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.77],
    [38.76],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.75],
    [38.77],
    [38.75],
    [38.730000000000004],
    [38.730000000000004],
    [38.72],
    [38.72],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.71],
    [38.72],
    [38.74],
    [38.730000000000004],
    [38.75],
    [38.74],
    [38.76],
    [38.75],
    [38.76],
    [38.77],
    [38.78],
    [38.78],
    [38.79],
    [38.78],
    [38.79],
    [38.79],
    [38.81],
    [38.81],
    [38.81],
    [38.78],
    [38.78],
    [38.78],
    [38.76],
    [38.75],
    [38.72],
    [38.74],
    [38.72],
    [38.72],
    [38.71],
    [38.7],
    [38.71],
    [38.71],
    [38.69],
    [38.68],
    [38.68],
    [38.67],
    [38.67],
    [38.69],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.7],
    [38.7],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.7],
    [38.7],
    [38.7],
    [38.69],
    [38.7],
    [38.68],
    [38.67],
    [38.68],
    [38.69],
    [38.69],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.67],
    [38.68],
    [38.69],
    [38.69],
    [38.71],
    [38.7],
    [38.71],
    [38.69],
    [38.69],
    [38.7],
    [38.68],
    [38.68],
    [38.71],
    [38.72],
    [38.72],
    [38.72],
    [38.71],
    [38.72],
    [38.71],
    [38.7],
    [38.71],
    [38.68],
    [38.67],
    [38.68],
    [38.68],
    [38.68],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.65],
    [38.64],
    [38.63],
    [38.6],
    [38.6],
    [38.58],
    [38.56],
    [38.57],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.52],
    [38.5],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.47],
    [38.46],
    [38.46],
    [38.44],
    [38.43],
    [38.42],
    [38.42],
    [38.39],
    [38.4],
    [38.39],
    [38.38],
    [38.37],
    [38.39],
    [38.37],
    [38.38],
    [38.38],
    [38.37],
    [38.37],
    [38.37],
    [38.37],
    [38.36],
    [38.38],
    [38.39],
    [38.38],
    [38.39],
    [38.39],
    [38.39],
    [38.42],
    [38.42],
    [38.43],
    [38.45],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.480000000000004],
    [38.480000000000004],
    [38.49],
    [38.47],
    [38.49],
    [38.5],
    [38.480000000000004],
    [38.480000000000004],
    [38.5],
    [38.480000000000004],
    [38.49],
    [38.5],
    [38.480000000000004],
    [38.5],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.52],
    [38.52],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.57],
    [38.58],
    [38.57],
    [38.57],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.61],
    [38.61],
    [38.61],
    [38.61],
    [38.6],
    [38.61],
    [38.61],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.6],
    [38.6],
    [38.62],
    [38.6],
    [38.6],
    [38.59],
    [38.57],
    [38.56],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.51],
    [38.52],
    [38.53],
    [38.52],
    [38.5],
    [38.5],
    [38.49],
    [38.49],
    [38.49],
    [38.49],
    [38.480000000000004],
    [38.47],
    [38.47],
    [38.47],
    [38.480000000000004],
    [38.46],
    [38.47],
    [38.480000000000004],
    [38.480000000000004],
    [38.49],
    [38.480000000000004],
    [38.480000000000004],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.5],
    [38.53],
    [38.52],
    [38.5],
    [38.53],
    [38.52],
    [38.54],
    [38.53],
    [38.53],
    [38.56],
    [38.53],
    [38.54],
    [38.56],
    [38.54],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.53],
    [38.52],
    [38.52],
    [38.51],
    [38.53],
    [38.52],
    [38.5],
    [38.53],
    [38.52],
    [38.53],
    [38.51],
    [38.52],
    [38.53],
    [38.53],
    [38.52],
    [38.52],
    [38.53],
    [38.53],
    [38.52],
    [38.53],
    [38.53],
    [38.51],
    [38.51],
    [38.5],
    [38.52],
    [38.52],
    [38.51],
    [38.5],
    [38.5],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.5],
    [38.5],
    [38.5],
    [38.49],
    [38.480000000000004],
    [38.47],
    [38.480000000000004],
    [38.47],
    [38.47],
    [38.47],
    [38.46],
    [38.45],
    [38.46],
    [38.46],
    [38.47],
    [38.480000000000004],
    [38.49],
    [38.47],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.53],
    [38.52],
    [38.53],
    [38.53],
    [38.51],
    [38.54],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.56],
    [38.550000000000004],
    [38.56],
    [38.54],
    [38.56],
    [38.57],
    [38.57],
    [38.57],
    [38.56],
    [38.54],
    [38.550000000000004],
    [38.57],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.53],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.56],
    [38.54],
    [38.56],
    [38.57],
    [38.56],
    [38.57],
    [38.57],
    [38.57],
    [38.6],
    [38.58],
    [38.6],
    [38.6],
    [38.59],
    [38.61],
    [38.62],
    [38.6],
    [38.59],
    [38.6],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.62],
    [38.6],
    [38.61],
    [38.61],
    [38.62],
    [38.63],
    [38.61],
    [38.63],
    [38.63],
    [38.61],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.63],
    [38.64],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.65],
    [38.67],
    [38.67],
    [38.7],
    [38.7],
    [38.72],
    [38.72],
    [38.75],
    [38.74],
    [38.75],
    [38.77],
    [38.78],
    [38.78],
    [38.81],
    [38.81],
    [38.82],
    [38.82],
    [38.82],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.88],
    [38.87],
    [38.87],
    [38.87],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.89],
    [38.87],
    [38.85],
    [38.83],
    [38.82],
    [38.83],
    [38.81],
    [38.82],
    [38.81],
    [38.79],
    [38.79],
    [38.78],
    [38.78],
    [38.77],
    [38.77],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.77],
    [38.77],
    [38.77],
    [38.79],
    [38.78],
    [38.77],
    [38.78],
    [38.800000000000004],
    [38.800000000000004],
    [38.79],
    [38.81],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.82],
    [38.83],
    [38.84],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.85],
    [38.87],
    [38.85],
    [38.85],
    [38.84],
    [38.87],
    [38.86],
    [38.85],
    [38.86],
    [38.85],
    [38.88],
    [38.86],
    [38.87],
    [38.88],
    [38.88],
    [38.85],
    [38.88],
    [38.89],
    [38.89],
    [38.88],
    [38.9],
    [38.92],
    [38.9],
    [38.9],
    [38.9],
    [38.89],
    [38.89],
    [38.88],
    [38.87],
    [38.87],
    [38.86],
    [38.86],
    [38.85],
    [38.86],
    [38.86],
    [38.88],
    [38.86],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.92],
    [38.93],
    [38.94],
    [38.94],
    [38.96],
    [38.980000000000004],
    [38.99],
    [38.980000000000004],
    [39.0],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.07],
    [39.07],
    [39.07],
    [39.1],
    [39.11],
    [39.11],
    [39.12, EVENTS.FEVER_DETECTED],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.19],
    [39.2],
    [39.21],
    [39.230000000000004],
    [39.22],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.26],
    [39.25],
    [39.27],
    [39.28],
    [39.25],
    [39.26],
    [39.27],
    [39.28],
    [39.27],
    [39.28],
    [39.28],
    [39.29],
    [39.31],
    [39.300000000000004],
    [39.32],
    [39.32],
    [39.32],
    [39.32],
    [39.31],
    [39.32],
    [39.31],
    [39.31],
    [39.32],
    [39.32],
    [39.32],
    [39.29],
    [39.31],
    [39.300000000000004],
    [39.300000000000004],
    [39.29],
    [39.28],
    [39.28],
    [39.25],
    [39.24],
    [39.25],
    [39.24],
    [39.22],
    [39.21],
    [39.2],
    [39.19],
    [39.17],
    [39.18],
    [39.17],
    [39.14],
    [39.13],
    [39.11],
    [39.1],
    [39.09],
    [39.08],
    [39.07],
    [39.03],
    [39.03],
    [39.03],
    [39.01],
    [38.99],
    [38.97],
    [38.96],
    [38.94],
    [38.93],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.9],
    [38.88],
    [38.89],
    [38.87],
    [38.87],
    [38.87],
    [38.89],
    [38.88],
    [38.88],
    [38.87],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.93],
    [38.95],
    [38.94],
    [38.94],
    [38.96],
    [38.97],
    [38.97],
    [39.0],
    [39.0],
    [39.0],
    [39.03],
    [39.050000000000004],
    [39.03],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.1],
    [39.14],
    [39.1],
    [39.13],
    [39.13],
    [39.14],
    [39.17],
    [39.21],
    [39.2],
    [39.2],
    [39.21],
    [39.21],
    [39.2],
    [39.21],
    [39.21],
    [39.2],
    [39.2],
    [39.19],
    [39.19],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.15],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.19],
    [39.19],
    [39.18],
    [39.2],
    [39.2],
    [39.2],
    [39.2],
    [39.21],
    [39.21],
    [39.22],
    [39.2],
    [39.22],
    [39.21],
    [39.230000000000004],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.22],
    [39.22],
    [39.230000000000004],
    [39.22],
    [39.21],
    [39.2],
    [39.21],
    [39.21],
    [39.21],
    [39.19],
    [39.2],
    [39.22],
    [39.21],
    [39.22],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.22],
    [39.22],
    [39.21],
    [39.21],
    [39.2],
    [39.18],
    [39.17],
    [39.17],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.11],
    [39.13],
    [39.11],
    [39.11],
    [39.09],
    [39.09],
    [39.09],
    [39.09],
    [39.08],
    [39.09],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.1],
    [39.07],
    [39.08],
    [39.09],
    [39.09],
    [39.07],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.12],
    [39.14],
    [39.14],
    [39.12],
    [39.13],
    [39.12],
    [39.13],
    [39.13],
    [39.12],
    [39.13],
    [39.12],
    [39.13],
    [39.13],
    [39.1],
    [39.12],
    [39.11],
    [39.1],
    [39.11],
    [39.13],
    [39.12],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.11],
    [39.1],
    [39.11],
    [39.12],
    [39.14],
    [39.15],
    [39.12],
    [39.13],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.08],
    [39.07],
    [39.06],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.03],
    [39.0],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.0],
    [39.0],
    [39.0],
    [38.980000000000004],
    [39.0],
    [39.0],
    [38.99],
    [38.99],
    [39.0],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.01],
    [39.0],
    [39.02],
    [39.01],
    [38.99],
    [38.99],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.96],
    [38.95],
    [38.95],
    [38.95],
    [38.93],
    [38.93],
    [38.95],
    [38.93],
    [38.93],
    [38.93],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.02],
    [39.02],
    [39.03],
    [39.03],
    [39.04],
    [39.04],
    [39.04],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.08],
    [39.09],
    [39.1],
    [39.11],
    [39.11],
    [39.12],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.07],
    [39.07],
    [39.06],
    [39.04],
    [39.04],
    [39.04],
    [39.01],
    [39.01],
    [39.0],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [39.0],
    [39.01],
    [39.01],
    [39.0],
    [39.01],
    [39.02],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.08],
    [39.07],
    [39.09],
    [39.08],
    [39.1],
    [39.11],
    [39.11],
    [39.11],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.17],
    [39.21],
    [39.2],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.21],
    [39.230000000000004],
    [39.22],
    [39.230000000000004],
    [39.24],
    [39.22],
    [39.21],
    [39.21],
    [39.22],
    [39.21],
    [39.2],
    [39.19],
    [39.21],
    [39.19],
    [39.2],
    [39.17],
    [39.18],
    [39.17],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.15],
    [39.14],
    [39.13],
    [39.14],
    [39.15],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.13],
    [39.15],
    [39.15],
    [39.13],
    [39.12],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.13],
    [39.13],
    [39.13],
    [39.13],
    [39.12],
    [39.13],
    [39.11],
    [39.11],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.09],
    [39.09],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.13],
    [39.15],
    [39.14],
    [39.160000000000004],
    [39.15],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.12],
    [39.13],
    [39.12],
    [39.09],
    [39.08],
    [39.09],
    [39.09],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.11],
    [39.12],
    [39.13],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.15],
    [39.15],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.18],
    [39.18],
    [39.18],
    [39.17],
    [39.18],
    [39.19],
    [39.21],
    [39.19],
    [39.21],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.25],
    [39.24],
    [39.24],
    [39.25],
    [39.26],
    [39.28],
    [39.26],
    [39.25],
    [39.27],
    [39.27],
    [39.25],
    [39.25],
    [39.27],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.26],
    [39.26],
    [39.26],
    [39.28],
    [39.26],
    [39.27],
    [39.25],
    [39.26],
    [39.25],
    [39.24],
    [39.230000000000004],
    [39.230000000000004],
    [39.22],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.18],
    [39.21],
    [39.19],
    [39.19],
    [39.21],
    [39.17],
    [39.17],
    [39.15],
    [39.14],
    [39.15],
    [39.14],
    [39.12],
    [39.12],
    [39.12],
    [39.1],
    [39.1],
    [39.09],
    [39.09],
    [39.09],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.04],
    [39.06],
    [39.06],
    [39.03],
    [39.04],
    [39.02],
    [39.03],
    [39.02],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [39.01],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.94],
    [38.95],
    [38.93],
    [38.94],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.88],
    [38.88],
    [38.87],
    [38.86],
    [38.86],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.85, EVENTS.FEVER_OVER],
    [38.85],
    [38.83],
    [38.82],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.87],
    [38.87],
    [38.88],
    [38.88],
    [38.89],
    [38.88],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.94],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.89],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.89],
    [38.88],
    [38.89],
    [38.88],
    [38.88],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.86],
    [38.85],
    [38.87],
    [38.87],
    [38.87],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.83],
    [38.81],
    [38.78],
    [38.78],
    [38.75],
    [38.74],
    [38.71],
    [38.68],
    [38.67],
    [38.65],
    [38.64],
    [38.63],
    [38.62],
    [38.62],
    [38.61],
    [38.6],
    [38.6],
    [38.6],
    [38.58],
    [38.57],
    [38.56],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.57],
    [38.57],
    [38.56],
    [38.57],
    [38.57],
    [38.58],
    [38.57],
    [38.58],
    [38.58],
    [38.58],
    [38.59],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.58],
    [38.59],
    [38.6],
    [38.59],
    [38.59],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.62],
    [38.62],
    [38.61],
    [38.61],
    [38.64],
    [38.61],
    [38.64],
    [38.64],
    [38.63],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.63],
    [38.64],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.63],
    [38.64],
    [38.63],
    [38.6],
    [38.6],
    [38.57],
    [38.57],
    [38.57],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.51],
    [38.51],
    [38.5],
    [38.51],
    [38.51],
    [38.53],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.550000000000004],
    [38.52],
    [38.54],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.51],
    [38.52],
    [38.5],
    [38.51],
    [38.51],
    [38.51],
    [38.52],
    [38.51],
    [38.5],
    [38.51],
    [38.5],
    [38.51],
    [38.51],
    [38.51],
    [38.5],
    [38.5],
    [38.51],
    [38.5],
    [38.5],
    [38.51],
    [38.51],
    [38.51],
    [38.51],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.5],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.5],
    [38.51],
    [38.51],
    [38.53],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.57],
    [38.58],
    [38.58],
    [38.6],
    [38.6],
    [38.57],
    [38.6],
    [38.6],
    [38.6],
    [38.57],
    [38.58],
    [38.57],
    [38.57],
    [38.550000000000004],
    [38.550000000000004],
    [38.52],
    [38.53],
    [38.54],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.52],
    [38.53],
    [38.52],
    [38.52],
    [38.52],
    [38.52],
    [38.52],
    [38.52],
    [38.5],
    [38.5],
    [38.52],
    [38.51],
    [38.5],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.56],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.54],
    [38.53],
    [38.52],
    [38.52],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.480000000000004],
    [38.46],
    [38.46],
    [38.45],
    [38.46],
    [38.45],
    [38.45],
    [38.44],
    [38.44],
    [38.44],
    [38.44],
    [38.45],
    [38.45],
    [38.43],
    [38.45],
    [38.45],
    [38.46],
    [38.45],
    [38.45],
    [38.43],
    [38.43],
    [38.44],
    [38.42],
    [38.43],
    [38.43],
    [38.45],
    [38.45],
    [38.45],
    [38.45],
    [38.44],
    [38.43],
    [38.45],
    [38.44],
    [38.44],
    [38.45],
    [38.45],
    [38.46],
    [38.47],
    [38.49],
    [38.5],
    [38.51],
    [38.52],
    [38.52],
    [38.53],
    [38.54],
    [38.54],
    [38.57],
    [38.57],
    [38.57],
    [38.58],
    [38.58],
    [38.58],
    [38.59],
    [38.6],
    [38.61],
    [38.61],
    [38.6],
    [38.62],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.63],
    [38.65],
    [38.64],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.68],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.65],
    [38.65],
    [38.64],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.660000000000004],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.660000000000004],
    [38.67],
    [38.64],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.69],
    [38.7],
    [38.7],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.730000000000004],
    [38.72],
    [38.72],
    [38.71],
    [38.71],
    [38.74],
    [38.72],
    [38.71],
    [38.74],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.71],
    [38.72],
    [38.72],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.71],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.730000000000004],
    [38.71],
    [38.730000000000004],
    [38.74],
    [38.730000000000004],
    [38.730000000000004],
    [38.74],
    [38.730000000000004],
    [38.75],
    [38.75],
    [38.76],
    [38.77],
    [38.77],
    [38.77],
    [38.78],
    [38.76],
    [38.78],
    [38.78],
    [38.78],
    [38.76],
    [38.75],
    [38.75],
    [38.72],
    [38.71],
    [38.69],
    [38.68],
    [38.67],
    [38.64],
    [38.64],
    [38.64],
    [38.61],
    [38.6],
    [38.58],
    [38.58],
    [38.59],
    [38.59],
    [38.58],
    [38.57],
    [38.57],
    [38.57],
    [38.56],
    [38.550000000000004],
    [38.54],
    [38.52],
    [38.53],
    [38.52],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.57],
    [38.59],
    [38.59],
    [38.6],
    [38.62],
    [38.63],
    [38.64],
    [38.65],
    [38.65],
    [38.67],
    [38.67],
    [38.67],
    [38.7],
    [38.7],
    [38.72],
    [38.72],
    [38.75],
    [38.75],
    [38.75],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.82],
    [38.83],
    [38.85],
    [38.86],
    [38.89],
    [38.88],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.93],
    [38.96],
    [38.95],
    [38.93],
    [38.92],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.87],
    [38.88],
    [38.85],
    [38.85],
    [38.84],
    [38.83],
    [38.82],
    [38.83],
    [38.85],
    [38.83],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.87],
    [38.88],
    [38.89],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.9],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.9],
    [38.89],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.93],
    [38.94],
    [38.94],
    [38.93],
    [38.96],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.01],
    [39.02],
    [39.01],
    [39.03],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.09],
    [39.09],
    [39.09],
    [39.09],
    [39.1],
    [39.1],
    [39.13, EVENTS.FEVER_DETECTED],
    [39.15],
    [39.14],
    [39.15],
    [39.15],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.14],
    [39.17],
    [39.15],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.15],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.11],
    [39.09],
    [39.07],
    [39.08],
    [39.06],
    [39.06],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.01],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [38.980000000000004],
    [38.97],
    [38.97],
    [38.96],
    [38.97],
    [38.95],
    [38.94],
    [38.95],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.87],
    [38.88],
    [38.87],
    [38.86],
    [38.85, EVENTS.FEVER_OVER],
    [38.87],
    [38.84],
    [38.83],
    [38.81],
    [38.78],
    [38.78],
    [38.77],
    [38.77],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.72],
    [38.71],
    [38.71],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.65],
    [38.67],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.64],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.64],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.65],
    [38.65],
    [38.67],
    [38.67],
    [38.68],
    [38.69],
    [38.7],
    [38.7],
    [38.72],
    [38.730000000000004],
    [38.72],
    [38.75],
    [38.78],
    [38.79],
    [38.82],
    [38.83],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.87],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.85],
    [38.86],
    [38.88],
    [38.89],
    [38.87],
    [38.86],
    [38.86],
    [38.89],
    [38.89],
    [38.86],
    [38.89],
    [38.88],
    [38.88],
    [38.86],
    [38.87],
    [38.88],
    [38.87],
    [38.88],
    [38.87],
    [38.86],
    [38.88],
    [38.87],
    [38.89],
    [38.9],
    [38.9],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.94],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [39.01],
    [39.01],
    [39.04],
    [39.02],
    [39.03],
    [39.04],
    [39.04],
    [39.04],
    [39.06],
    [39.06],
    [39.07],
    [39.06],
    [39.07],
    [39.09],
    [39.07],
    [39.1],
    [39.1],
    [39.12],
    [39.1],
    [39.11],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.12],
    [39.1],
    [39.11],
    [39.1],
    [39.11],
    [39.09],
    [39.09],
    [39.09],
    [39.07],
    [39.06],
    [39.04],
    [39.050000000000004],
    [39.03],
    [39.04],
    [39.01],
    [39.03],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.02],
    [39.03],
    [39.02],
    [39.01],
    [39.02],
    [39.03],
    [39.01],
    [39.02],
    [39.03],
    [39.03],
    [39.04],
    [39.02],
    [39.02],
    [39.01],
    [39.03],
    [39.02],
    [39.02],
    [39.01],
    [39.01],
    [39.01],
    [39.03],
    [39.01],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.07],
    [39.07],
    [39.07],
    [39.1],
    [39.12, EVENTS.FEVER_DETECTED],
    [39.13],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.18],
    [39.18],
    [39.19],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.25],
    [39.24],
    [39.25],
    [39.28],
    [39.300000000000004],
    [39.29],
    [39.32],
    [39.31],
    [39.32],
    [39.34],
    [39.35],
    [39.36],
    [39.36],
    [39.38],
    [39.39],
    [39.37],
    [39.410000000000004],
    [39.410000000000004],
    [39.42],
    [39.43],
    [39.43],
    [39.43],
    [39.45],
    [39.46],
    [39.480000000000004],
    [39.480000000000004],
    [39.480000000000004],
    [39.49],
    [39.5],
    [39.5],
    [39.480000000000004],
    [39.47],
    [39.47],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.45],
    [39.44],
    [39.45],
    [39.44],
    [39.46],
    [39.46],
    [39.47],
    [39.47],
    [39.480000000000004],
    [39.46],
    [39.46],
    [39.480000000000004],
    [39.47],
    [39.47],
    [39.480000000000004],
    [39.480000000000004],
    [39.47],
    [39.480000000000004],
    [39.47],
    [39.47],
    [39.47],
    [39.45],
    [39.44],
    [39.44],
    [39.42],
    [39.43],
    [39.42],
    [39.4],
    [39.38],
    [39.38],
    [39.38],
    [39.36],
    [39.35],
    [39.34],
    [39.35],
    [39.34],
    [39.33],
    [39.32],
    [39.32],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.28],
    [39.28],
    [39.26],
    [39.26],
    [39.25],
    [39.25],
    [39.24],
    [39.21],
    [39.21],
    [39.21],
    [39.2],
    [39.19],
    [39.2],
    [39.2],
    [39.19],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.12],
    [39.12],
    [39.12],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.07],
    [39.07],
    [39.06],
    [39.04],
    [39.04],
    [39.04],
    [39.03],
    [39.01],
    [39.0],
    [38.99],
    [38.99],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.96],
    [38.97],
    [38.97],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.01],
    [39.01],
    [39.01],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.07],
    [39.08],
    [39.09],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.09],
    [39.11],
    [39.1],
    [39.12],
    [39.11],
    [39.11],
    [39.11],
    [39.13],
    [39.15],
    [39.15],
    [39.15],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.12],
    [39.11],
    [39.12],
    [39.11],
    [39.11],
    [39.11],
    [39.09],
    [39.1],
    [39.07],
    [39.06],
    [39.02],
    [39.01],
    [38.980000000000004],
    [38.97],
    [38.97],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.99],
    [38.99],
    [39.0],
    [38.99],
    [38.99],
    [39.0],
    [39.02],
    [39.0],
    [39.0],
    [39.02],
    [39.02],
    [39.01],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.06],
    [39.08],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.08],
    [39.07],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.12],
    [39.14],
    [39.12],
    [39.13],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.18],
    [39.19],
    [39.2],
    [39.2],
    [39.19],
    [39.18],
    [39.18],
    [39.17],
    [39.13],
    [39.13],
    [39.13],
    [39.13],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.1],
    [39.12],
    [39.1],
    [39.1],
    [39.12],
    [39.11],
    [39.12],
    [39.14],
    [39.14],
    [39.13],
    [39.12],
    [39.13],
    [39.12],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.15],
    [39.15],
    [39.17],
    [39.15],
    [39.15],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.2],
    [39.2],
    [39.21],
    [39.21],
    [39.22],
    [39.21],
    [39.21],
    [39.22],
    [39.22],
    [39.230000000000004],
    [39.21],
    [39.21],
    [39.18],
    [39.18],
    [39.19],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.14],
    [39.1],
    [39.08],
    [39.07],
    [39.08],
    [39.06],
    [39.07],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.06],
    [39.06],
    [39.06],
    [39.07],
    [39.06],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.02],
    [39.03],
    [39.02],
    [39.03],
    [39.02],
    [39.02],
    [39.02],
    [39.0],
    [39.01],
    [39.01],
    [39.0],
    [39.0],
    [38.99],
    [38.97],
    [38.96],
    [38.97],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.97],
    [38.97],
    [38.97],
    [38.99],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.02],
    [39.01],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.12],
    [39.12],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.09],
    [39.09],
    [39.08],
    [39.08],
    [39.07],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.03],
    [39.01],
    [38.99],
    [38.99],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.92],
    [38.9],
    [38.89],
    [38.88],
    [38.86],
    [38.87],
    [38.87],
    [38.87],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.87],
    [38.88],
    [38.87],
    [38.88],
    [38.87],
    [38.89],
    [38.9],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.9],
    [38.9],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.92],
    [38.95],
    [38.92],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.96],
    [38.95],
    [38.95],
    [38.95],
    [38.94],
    [38.92],
    [38.92],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.88],
    [38.88],
    [38.87],
    [38.87],
    [38.87],
    [38.87],
    [38.87],
    [38.85],
    [38.85, EVENTS.FEVER_OVER],
    [38.84],
    [38.82],
    [38.82],
    [38.82],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.76],
    [38.75],
    [38.74],
    [38.72],
    [38.71],
    [38.7],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.62],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.62],
    [38.62],
    [38.63],
    [38.64],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.660000000000004],
    [38.68],
    [38.68],
    [38.69],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.69],
    [38.67],
    [38.67],
    [38.68],
    [38.69],
    [38.68],
    [38.69],
    [38.7],
    [38.68],
    [38.69],
    [38.7],
    [38.7],
    [38.68],
    [38.68],
    [38.67],
    [38.67],
    [38.64],
    [38.64],
    [38.63],
    [38.63],
    [38.6],
    [38.61],
    [38.63],
    [38.63],
    [38.63],
    [38.64],
    [38.65],
    [38.65],
    [38.660000000000004],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.68],
    [38.7],
    [38.68],
    [38.7],
    [38.71],
    [38.7],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.72],
    [38.730000000000004],
    [38.75],
    [38.75],
    [38.76],
    [38.76],
    [38.76],
    [38.78],
    [38.78],
    [38.79],
    [38.78],
    [38.79],
    [38.78],
    [38.800000000000004],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.78],
    [38.800000000000004],
    [38.79],
    [38.79],
    [38.78],
    [38.78],
    [38.79],
    [38.77],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.78],
    [38.79],
    [38.81],
    [38.81],
    [38.81],
    [38.82],
    [38.79],
    [38.79],
    [38.78],
    [38.78],
    [38.77],
    [38.76],
    [38.75],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.74],
    [38.72],
    [38.72],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.68],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.65],
    [38.67],
    [38.65],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.65],
    [38.63],
    [38.6],
    [38.59],
    [38.57],
    [38.57],
    [38.54],
    [38.53],
    [38.54],
    [38.52],
    [38.51],
    [38.51],
    [38.5],
    [38.51],
    [38.5],
    [38.51],
    [38.52],
    [38.5],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.52],
    [38.52],
    [38.5],
    [38.52],
    [38.51],
    [38.52],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.52],
    [38.52],
    [38.53],
    [38.51],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.550000000000004],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.53],
    [38.57],
    [38.56],
    [38.56],
    [38.56],
    [38.56],
    [38.56],
    [38.57],
    [38.58],
    [38.56],
    [38.57],
    [38.53],
    [38.54],
    [38.53],
    [38.52],
    [38.5],
    [38.51],
    [38.5],
    [38.5],
    [38.49],
    [38.47],
    [38.46],
    [38.44],
    [38.43],
    [38.42],
    [38.42],
    [38.410000000000004],
    [38.39],
    [38.410000000000004],
    [38.4],
    [38.42],
    [38.39],
    [38.4],
    [38.42],
    [38.410000000000004],
    [38.42],
    [38.4],
    [38.410000000000004],
    [38.42],
    [38.410000000000004],
    [38.410000000000004],
    [38.410000000000004],
    [38.42],
    [38.42],
    [38.45],
    [38.44],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.45],
    [38.43],
    [38.43],
    [38.43],
    [38.410000000000004],
    [38.42],
    [38.4],
    [38.42],
    [38.39],
    [38.39],
    [38.4],
    [38.39],
    [38.39],
    [38.42],
    [38.42],
    [38.42],
    [38.43],
    [38.46],
    [38.46],
    [38.47],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.51],
    [38.52],
    [38.52],
    [38.53],
    [38.53],
    [38.52],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.54],
    [38.56],
    [38.57],
    [38.57],
    [38.6],
    [38.61],
    [38.61],
    [38.63],
    [38.64],
    [38.64],
    [38.67],
    [38.68],
    [38.71],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.75],
    [38.78],
    [38.78],
    [38.79],
    [38.81],
    [38.82],
    [38.82],
    [38.86],
    [38.86],
    [38.85],
    [38.86],
    [38.86],
    [38.88],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.86],
    [38.87],
    [38.87],
    [38.86],
    [38.86],
    [38.86],
    [38.86],
    [38.87],
    [38.85],
    [38.86],
    [38.85],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.88],
    [38.86],
    [38.87],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.87],
    [38.86],
    [38.85],
    [38.85],
    [38.84],
    [38.84],
    [38.84],
    [38.85],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.87],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.95],
    [38.97],
    [38.97],
    [38.99],
    [39.01],
    [39.02],
    [39.02],
    [39.04],
    [39.04],
    [39.03],
    [39.06],
    [39.07],
    [39.09],
    [39.1],
    [39.1],
    [39.11],
    [39.13, EVENTS.FEVER_DETECTED],
    [39.15],
    [39.160000000000004],
    [39.18],
    [39.18],
    [39.2],
    [39.21],
    [39.22],
    [39.24],
    [39.24],
    [39.230000000000004],
    [39.25],
    [39.230000000000004],
    [39.230000000000004],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.25],
    [39.230000000000004],
    [39.24],
    [39.22],
    [39.22],
    [39.22],
    [39.230000000000004],
    [39.21],
    [39.230000000000004],
    [39.25],
    [39.25],
    [39.230000000000004],
    [39.25],
    [39.25],
    [39.25],
    [39.28],
    [39.26],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.26],
    [39.25],
    [39.26],
    [39.24],
    [39.230000000000004],
    [39.22],
    [39.21],
    [39.19],
    [39.18],
    [39.17],
    [39.15],
    [39.14],
    [39.15],
    [39.13],
    [39.11],
    [39.12],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.06],
    [39.08],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.03],
    [39.04],
    [39.01],
    [39.03],
    [39.01],
    [39.03],
    [39.02],
    [39.0],
    [39.01],
    [39.01],
    [39.0],
    [39.0],
    [38.99],
    [39.01],
    [38.97],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.99],
    [38.97],
    [38.96],
    [38.95],
    [38.95],
    [38.94],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.87],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.86],
    [38.87],
    [38.87],
    [38.85],
    [38.86],
    [38.86],
    [38.88],
    [38.88],
    [38.9],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.87],
    [38.89],
    [38.88],
    [38.88],
    [38.87],
    [38.86],
    [38.87],
    [38.85],
    [38.86],
    [38.85],
    [38.86],
    [38.87],
    [38.87],
    [38.88],
    [38.89],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.93],
    [38.92],
    [38.94],
    [38.95],
    [38.95],
    [38.96],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.03],
    [39.04],
    [39.03],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.13],
    [39.13],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.18],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.22],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.26],
    [39.26],
    [39.28],
    [39.28],
    [39.29],
    [39.300000000000004],
    [39.31],
    [39.300000000000004],
    [39.31],
    [39.32],
    [39.31],
    [39.31],
    [39.300000000000004],
    [39.300000000000004],
    [39.28],
    [39.26],
    [39.27],
    [39.25],
    [39.22],
    [39.24],
    [39.24],
    [39.230000000000004],
    [39.21],
    [39.21],
    [39.22],
    [39.21],
    [39.21],
    [39.24],
    [39.230000000000004],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.24],
    [39.25],
    [39.24],
    [39.24],
    [39.25],
    [39.24],
    [39.230000000000004],
    [39.25],
    [39.24],
    [39.24],
    [39.230000000000004],
    [39.25],
    [39.26],
    [39.25],
    [39.26],
    [39.26],
    [39.28],
    [39.28],
    [39.28],
    [39.300000000000004],
    [39.29],
    [39.31],
    [39.300000000000004],
    [39.32],
    [39.32],
    [39.32],
    [39.33],
    [39.33],
    [39.33],
    [39.35],
    [39.35],
    [39.36],
    [39.37],
    [39.38],
    [39.37],
    [39.39],
    [39.37],
    [39.39],
    [39.39],
    [39.4],
    [39.410000000000004],
    [39.4],
    [39.42],
    [39.42],
    [39.43],
    [39.45],
    [39.43],
    [39.45],
    [39.43],
    [39.45],
    [39.46],
    [39.480000000000004],
    [39.480000000000004],
    [39.480000000000004],
    [39.5],
    [39.480000000000004],
    [39.5],
    [39.5],
    [39.51],
    [39.51],
    [39.52],
    [39.52],
    [39.53],
    [39.52],
    [39.53],
    [39.53],
    [39.52],
    [39.52],
    [39.5],
    [39.5],
    [39.52],
    [39.52],
    [39.5],
    [39.51],
    [39.51],
    [39.5],
    [39.5],
    [39.5],
    [39.5],
    [39.49],
    [39.5],
    [39.5],
    [39.5],
    [39.49],
    [39.5],
    [39.5],
    [39.5],
    [39.51],
    [39.51],
    [39.52],
    [39.52],
    [39.52],
    [39.53],
    [39.53],
    [39.53],
    [39.53],
    [39.54],
    [39.53],
    [39.53],
    [39.53],
    [39.53],
    [39.53],
    [39.52],
    [39.52],
    [39.52],
    [39.5],
    [39.49],
    [39.49],
    [39.480000000000004],
    [39.480000000000004],
    [39.46],
    [39.46],
    [39.46],
    [39.45],
    [39.46],
    [39.45],
    [39.44],
    [39.42],
    [39.43],
    [39.43],
    [39.42],
    [39.42],
    [39.42],
    [39.410000000000004],
    [39.39],
    [39.38],
    [39.36],
    [39.37],
    [39.35],
    [39.35],
    [39.36],
    [39.35],
    [39.34],
    [39.35],
    [39.34],
    [39.34],
    [39.34],
    [39.32],
    [39.300000000000004],
    [39.31],
    [39.29],
    [39.29],
    [39.26],
    [39.26],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.24],
    [39.24],
    [39.22],
    [39.22],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.2],
    [39.19],
    [39.19],
    [39.19],
    [39.18],
    [39.18],
    [39.18],
    [39.19],
    [39.18],
    [39.18],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.17],
    [39.19],
    [39.18],
    [39.17],
    [39.17],
    [39.18],
    [39.19],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.18],
    [39.17],
    [39.2],
    [39.19],
    [39.2],
    [39.2],
    [39.2],
    [39.2],
    [39.21],
    [39.21],
    [39.2],
    [39.18],
    [39.19],
    [39.19],
    [39.19],
    [39.18],
    [39.19],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.14],
    [39.15],
    [39.13],
    [39.13],
    [39.11],
    [39.1],
    [39.08],
    [39.08],
    [39.08],
    [39.07],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.03],
    [39.04],
    [39.04],
    [39.03],
    [39.03],
    [39.01],
    [39.0],
    [38.99],
    [38.99],
    [38.97],
    [38.96],
    [38.96],
    [38.95],
    [38.93],
    [38.94],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.95],
    [38.94],
    [39.0],
    [38.99],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.96],
    [38.93],
    [38.95],
    [38.93],
    [38.94],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.95],
    [38.96],
    [38.97],
    [38.96],
    [38.980000000000004],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [38.99],
    [39.02],
    [39.02],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.11],
    [39.1],
    [39.1],
    [39.08],
    [39.08],
    [39.050000000000004],
    [39.06],
    [39.050000000000004],
    [39.04],
    [39.02],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.95],
    [38.94],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.9],
    [38.89],
    [38.87],
    [38.88],
    [38.88],
    [38.88],
    [38.87],
    [38.86],
    [38.86],
    [38.85, EVENTS.FEVER_OVER],
    [38.85],
    [38.85],
    [38.86],
    [38.84],
    [38.86],
    [38.86],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.83],
    [38.82],
    [38.83],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.84],
    [38.82],
    [38.85],
    [38.86],
    [38.87],
    [38.89],
    [38.88],
    [38.9],
    [38.89],
    [38.92],
    [38.94],
    [38.93],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.99],
    [39.02],
    [39.02],
    [39.04],
    [39.07],
    [39.06],
    [39.07],
    [39.06],
    [39.1],
    [39.1],
    [39.09],
    [39.11],
    [39.1],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.12],
    [39.14],
    [39.13],
    [39.1],
    [39.08],
    [39.08],
    [39.07],
    [39.06],
    [39.06],
    [39.04],
    [39.04],
    [39.04],
    [39.03],
    [39.04],
    [39.02],
    [39.02],
    [39.0],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.96],
    [38.94],
    [38.95],
    [38.94],
    [38.94],
    [38.96],
    [38.93],
    [38.96],
    [38.980000000000004],
    [38.97],
    [39.0],
    [39.01],
    [39.02],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.06],
    [39.08],
    [39.09],
    [39.09],
    [39.1],
    [39.12, EVENTS.FEVER_DETECTED],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.18],
    [39.18],
    [39.2],
    [39.18],
    [39.19],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.24],
    [39.24],
    [39.21],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.26],
    [39.26],
    [39.25],
    [39.26],
    [39.25],
    [39.25],
    [39.26],
    [39.26],
    [39.28],
    [39.28],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.31],
    [39.32],
    [39.29],
    [39.24],
    [39.24],
    [39.22],
    [39.2],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.13],
    [39.14],
    [39.1],
    [39.08],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.06],
    [39.04],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.09],
    [39.1],
    [39.1],
    [39.12],
    [39.12],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.14],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.12],
    [39.12],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.08],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.04],
    [39.04],
    [39.03],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [38.96],
    [38.97],
    [38.95],
    [38.92],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.88],
    [38.88],
    [38.86],
    [38.85, EVENTS.FEVER_OVER],
    [38.85],
    [38.83],
    [38.82],
    [38.82],
    [38.81],
    [38.78],
    [38.77],
    [38.76],
    [38.74],
    [38.730000000000004],
    [38.730000000000004],
    [38.71],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.64],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.65],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.660000000000004],
    [38.67],
    [38.69],
    [38.68],
    [38.68],
    [38.7],
    [38.69],
    [38.69],
    [38.7],
    [38.69],
    [38.71],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.75],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.79],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.78],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.79],
    [38.78],
    [38.77],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.82],
    [38.83],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.88],
    [38.87],
    [38.89],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.94],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.93],
    [38.94],
    [38.94],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.88],
    [38.83],
    [38.82],
    [38.81],
    [38.78],
    [38.77],
    [38.75],
    [38.730000000000004],
    [38.7],
    [38.69],
    [38.65],
    [38.660000000000004],
    [38.64],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.59],
    [38.6],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.6],
    [38.62],
    [38.62],
    [38.6],
    [38.63],
    [38.65],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.68],
    [38.67],
    [38.69],
    [38.69],
    [38.71],
    [38.7],
    [38.71],
    [38.7],
    [38.69],
    [38.68],
    [38.68],
    [38.65],
    [38.64],
    [38.64],
    [38.62],
    [38.6],
    [38.6],
    [38.58],
    [38.57],
    [38.57],
    [38.54],
    [38.550000000000004],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.57],
    [38.57],
    [38.59],
    [38.59],
    [38.58],
    [38.6],
    [38.59],
    [38.6],
    [38.6],
    [38.59],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.62],
    [38.64],
    [38.64],
    [38.64],
    [38.63],
    [38.63],
    [38.65],
    [38.65],
    [38.64],
    [38.64],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.69],
    [38.68],
    [38.68],
    [38.69],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.74],
    [38.74],
    [38.72],
    [38.71],
    [38.71],
    [38.69],
    [38.67],
    [38.69],
    [38.67],
    [38.67],
    [38.64],
    [38.64],
    [38.63],
    [38.65],
    [38.63],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.61],
    [38.61],
    [38.62],
    [38.62],
    [38.61],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.59],
    [38.59],
    [38.6],
    [38.59],
    [38.59],
    [38.57],
    [38.57],
    [38.57],
    [38.550000000000004],
    [38.57],
    [38.56],
    [38.56],
    [38.57],
    [38.550000000000004],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.56],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.56],
    [38.53],
    [38.53],
    [38.52],
    [38.52],
    [38.51],
    [38.51],
    [38.51],
    [38.51],
    [38.5],
    [38.5],
    [38.52],
    [38.5],
    [38.53],
    [38.51],
    [38.52],
    [38.52],
    [38.51],
    [38.52],
    [38.51],
    [38.52],
    [38.51],
    [38.51],
    [38.5],
    [38.5],
    [38.5],
    [38.49],
    [38.480000000000004],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.43],
    [38.42],
    [38.43],
    [38.4],
    [38.42],
    [38.39],
    [38.410000000000004],
    [38.4],
    [38.4],
    [38.4],
    [38.410000000000004],
    [38.42],
    [38.39],
    [38.4],
    [38.38],
    [38.39],
    [38.38],
    [38.36],
    [38.36],
    [38.37],
    [38.37],
    [38.38],
    [38.38],
    [38.39],
    [38.39],
    [38.4],
    [38.42],
    [38.42],
    [38.42],
    [38.410000000000004],
    [38.43],
    [38.44],
    [38.43],
    [38.46],
    [38.46],
    [38.46],
    [38.47],
    [38.47],
    [38.46],
    [38.49],
    [38.49],
    [38.5],
    [38.5],
    [38.51],
    [38.51],
    [38.53],
    [38.52],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.59],
    [38.58],
    [38.59],
    [38.6],
    [38.61],
    [38.62],
    [38.63],
    [38.64],
    [38.63],
    [38.64],
    [38.63],
    [38.63],
    [38.62],
    [38.62],
    [38.64],
    [38.62],
    [38.62],
    [38.64],
    [38.62],
    [38.62],
    [38.63],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.67],
    [38.65],
    [38.67],
    [38.67],
    [38.68],
    [38.69],
    [38.69],
    [38.69],
    [38.7],
    [38.72],
    [38.71],
    [38.72],
    [38.74],
    [38.730000000000004],
    [38.730000000000004],
    [38.72],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.74],
    [38.76],
    [38.76],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.77],
    [38.75],
    [38.75],
    [38.76],
    [38.77],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.75],
    [38.76],
    [38.77],
    [38.75],
    [38.78],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.79],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.77],
    [38.77],
    [38.76],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.79],
    [38.78],
    [38.77],
    [38.75],
    [38.77],
    [38.74],
    [38.75],
    [38.730000000000004],
    [38.730000000000004],
    [38.7],
    [38.7],
    [38.69],
    [38.68],
    [38.68],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.65],
    [38.63],
    [38.64],
    [38.62],
    [38.62],
    [38.62],
    [38.62],
    [38.63],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.61],
    [38.6],
    [38.6],
    [38.62],
    [38.62],
    [38.64],
    [38.61],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.67],
    [38.68],
    [38.69],
    [38.71],
    [38.7],
    [38.69],
    [38.68],
    [38.7],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.72],
    [38.74],
    [38.76],
    [38.75],
    [38.75],
    [38.76],
    [38.76],
    [38.77],
    [38.77],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.81],
    [38.82],
    [38.83],
    [38.83],
    [38.85],
    [38.85],
    [38.87],
    [38.87],
    [38.89],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.94],
    [38.93],
    [38.96],
    [38.97],
    [38.99],
    [38.980000000000004],
    [38.97],
    [39.0],
    [39.0],
    [39.02],
    [39.02],
    [39.04],
    [39.06],
    [39.08],
    [39.07],
    [39.08],
    [39.08],
    [39.08],
    [39.1],
    [39.09],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.12],
    [39.11],
    [39.11],
    [39.1],
    [39.1],
    [39.09],
    [39.07],
    [39.08],
    [39.07],
    [39.08],
    [39.07],
    [39.08],
    [39.08],
    [39.08],
    [39.1],
    [39.08],
    [39.08],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.12, EVENTS.FEVER_DETECTED],
    [39.12],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.160000000000004],
    [39.15],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.2],
    [39.19],
    [39.2],
    [39.21],
    [39.21],
    [39.21],
    [39.24],
    [39.230000000000004],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.25],
    [39.26],
    [39.25],
    [39.26],
    [39.25],
    [39.24],
    [39.230000000000004],
    [39.22],
    [39.21],
    [39.22],
    [39.21],
    [39.2],
    [39.19],
    [39.19],
    [39.18],
    [39.2],
    [39.17],
    [39.19],
    [39.17],
    [39.19],
    [39.18],
    [39.17],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.08],
    [39.07],
    [39.06],
    [39.04],
    [39.04],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [38.99],
    [39.0],
    [38.97],
    [38.97],
    [38.96],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.96],
    [38.95],
    [38.96],
    [38.95],
    [38.94],
    [38.94],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.89],
    [38.89],
    [38.89],
    [38.85],
    [38.83],
    [38.82],
    [38.800000000000004],
    [38.800000000000004],
    [38.81],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.81],
    [38.82],
    [38.82],
    [38.83],
    [38.84],
    [38.82],
    [38.85],
    [38.86],
    [38.88],
    [38.89],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.95],
    [38.95],
    [38.96],
    [38.97],
    [39.01],
    [39.01],
    [39.01],
    [39.04],
    [39.06],
    [39.06],
    [39.08],
    [39.1],
    [39.1],
    [39.09],
    [39.11],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.18],
    [39.19],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.13],
    [39.11],
    [39.1],
    [39.1],
    [39.09],
    [39.08],
    [39.09],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.11],
    [39.12],
    [39.13],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.2],
    [39.19],
    [39.19],
    [39.2],
    [39.2],
    [39.2],
    [39.2],
    [39.2],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.22],
    [39.22],
    [39.2],
    [39.2],
    [39.18],
    [39.19],
    [39.17],
    [39.160000000000004],
    [39.13],
    [39.14],
    [39.12],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.08],
    [39.09],
    [39.09],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.06],
    [39.07],
    [39.06],
    [39.06],
    [39.07],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.06],
    [39.04],
    [39.06],
    [39.06],
    [39.06],
    [39.07],
    [39.06],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.06],
    [39.06],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.07],
    [39.06],
    [39.08],
    [39.07],
    [39.09],
    [39.09],
    [39.08],
    [39.09],
    [39.1],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.09],
    [39.09],
    [39.07],
    [39.1],
    [39.08],
    [39.09],
    [39.09],
    [39.09],
    [39.08],
    [39.09],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.08],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.09],
    [39.11],
    [39.12],
    [39.1],
    [39.1],
    [39.11],
    [39.13],
    [39.14],
    [39.15],
    [39.15],
    [39.17],
    [39.19],
    [39.22],
    [39.22],
    [39.230000000000004],
    [39.24],
    [39.24],
    [39.26],
    [39.28],
    [39.29],
    [39.31],
    [39.32],
    [39.32],
    [39.34],
    [39.35],
    [39.37],
    [39.38],
    [39.36],
    [39.39],
    [39.39],
    [39.4],
    [39.42],
    [39.42],
    [39.42],
    [39.42],
    [39.43],
    [39.46],
    [39.44],
    [39.44],
    [39.46],
    [39.45],
    [39.43],
    [39.44],
    [39.44],
    [39.43],
    [39.42],
    [39.410000000000004],
    [39.410000000000004],
    [39.39],
    [39.39],
    [39.4],
    [39.39],
    [39.39],
    [39.38],
    [39.37],
    [39.38],
    [39.38],
    [39.38],
    [39.37],
    [39.36],
    [39.34],
    [39.35],
    [39.34],
    [39.32],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.28],
    [39.28],
    [39.26],
    [39.24],
    [39.230000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.21],
    [39.21],
    [39.21],
    [39.18],
    [39.18],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.14],
    [39.15],
    [39.12],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.12],
    [39.11],
    [39.12],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.13],
    [39.11],
    [39.13],
    [39.13],
    [39.13],
    [39.13],
    [39.12],
    [39.12],
    [39.1],
    [39.08],
    [39.08],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.04],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.01],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [38.99],
    [38.99],
    [39.0],
    [39.01],
    [39.02],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.09],
    [39.09],
    [39.1],
    [39.1],
    [39.13],
    [39.13],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.18],
    [39.21],
    [39.19],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.24],
    [39.22],
    [39.230000000000004],
    [39.25],
    [39.25],
    [39.24],
    [39.24],
    [39.25],
    [39.26],
    [39.25],
    [39.25],
    [39.28],
    [39.27],
    [39.29],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.31],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.31],
    [39.300000000000004],
    [39.31],
    [39.28],
    [39.28],
    [39.28],
    [39.25],
    [39.26],
    [39.27],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.26],
    [39.25],
    [39.26],
    [39.27],
    [39.28],
    [39.26],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.27],
    [39.31],
    [39.32],
    [39.31],
    [39.32],
    [39.32],
    [39.32],
    [39.34],
    [39.32],
    [39.35],
    [39.34],
    [39.34],
    [39.35],
    [39.35],
    [39.36],
    [39.37],
    [39.38],
    [39.38],
    [39.37],
    [39.39],
    [39.38],
    [39.4],
    [39.39],
    [39.4],
    [39.42],
    [39.42],
    [39.410000000000004],
    [39.42],
    [39.4],
    [39.4],
    [39.38],
    [39.37],
    [39.35],
    [39.35],
    [39.35],
    [39.33],
    [39.34],
    [39.33],
    [39.34],
    [39.32],
    [39.31],
    [39.300000000000004],
    [39.28],
    [39.27],
    [39.28],
    [39.26],
    [39.25],
    [39.25],
    [39.24],
    [39.24],
    [39.21],
    [39.24],
    [39.22],
    [39.22],
    [39.22],
    [39.21],
    [39.230000000000004],
    [39.21],
    [39.21],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.21],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.26],
    [39.25],
    [39.25],
    [39.26],
    [39.25],
    [39.25],
    [39.25],
    [39.230000000000004],
    [39.26],
    [39.25],
    [39.25],
    [39.25],
    [39.27],
    [39.25],
    [39.25],
    [39.27],
    [39.25],
    [39.25],
    [39.26],
    [39.28],
    [39.27],
    [39.26],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.32],
    [39.28],
    [39.29],
    [39.29],
    [39.28],
    [39.300000000000004],
    [39.31],
    [39.300000000000004],
    [39.29],
    [39.31],
    [39.31],
    [39.300000000000004],
    [39.32],
    [39.32],
    [39.31],
    [39.32],
    [39.32],
    [39.31],
    [39.31],
    [39.31],
    [39.31],
    [39.300000000000004],
    [39.300000000000004],
    [39.28],
    [39.28],
    [39.28],
    [39.26],
    [39.26],
    [39.24],
    [39.24],
    [39.21],
    [39.21],
    [39.2],
    [39.2],
    [39.17],
    [39.17],
    [39.14],
    [39.11],
    [39.1],
    [39.1],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.06],
    [39.04],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.0],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.95],
    [38.95],
    [38.95],
    [38.94],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.89],
    [38.87],
    [38.86],
    [38.88],
    [38.87],
    [38.85],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.86],
    [38.85],
    [38.86],
    [38.86],
    [38.86],
    [38.88],
    [38.87],
    [38.89],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.94],
    [38.95],
    [38.95],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.02],
    [39.03],
    [39.03],
    [39.04],
    [39.04],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.04],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.11],
    [39.09],
    [39.09],
    [39.1],
    [39.08],
    [39.07],
    [39.06],
    [39.04],
    [39.04],
    [39.02],
    [39.0],
    [39.01],
    [39.03],
    [39.02],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.01],
    [39.0],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.0],
    [39.01],
    [39.01],
    [39.02],
    [39.03],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.06],
    [39.06],
    [39.06],
    [39.07],
    [39.08],
    [39.09],
    [39.09],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.09],
    [39.07],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.0],
    [39.0],
    [38.96],
    [38.96],
    [38.95],
    [38.92],
    [38.92],
    [38.9],
    [38.88],
    [38.85, EVENTS.FEVER_OVER],
    [38.82],
    [38.79],
    [38.76],
    [38.76],
    [38.75],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.74],
    [38.75],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.78],
    [38.81],
    [38.82],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.83],
    [38.83],
    [38.82],
    [38.82],
    [38.81],
    [38.81],
    [38.79],
    [38.81],
    [38.800000000000004],
    [38.81],
    [38.800000000000004],
    [38.77],
    [38.78],
    [38.78],
    [38.76],
    [38.78],
    [38.75],
    [38.75],
    [38.77],
    [38.76],
    [38.75],
    [38.75],
    [38.75],
    [38.730000000000004],
    [38.75],
    [38.74],
    [38.75],
    [38.72],
    [38.72],
    [38.71],
    [38.72],
    [38.72],
    [38.730000000000004],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.69],
    [38.7],
    [38.7],
    [38.67],
    [38.69],
    [38.68],
    [38.68],
    [38.7],
    [38.68],
    [38.69],
    [38.68],
    [38.67],
    [38.67],
    [38.7],
    [38.7],
    [38.69],
    [38.7],
    [38.69],
    [38.71],
    [38.7],
    [38.71],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.7],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.64],
    [38.61],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.58],
    [38.58],
    [38.57],
    [38.58],
    [38.6],
    [38.6],
    [38.58],
    [38.57],
    [38.58],
    [38.57],
    [38.58],
    [38.59],
    [38.57],
    [38.58],
    [38.58],
    [38.57],
    [38.58],
    [38.58],
    [38.6],
    [38.59],
    [38.6],
    [38.61],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.62],
    [38.64],
    [38.62],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.65],
    [38.63],
    [38.62],
    [38.63],
    [38.62],
    [38.61],
    [38.64],
    [38.62],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.64],
    [38.660000000000004],
    [38.65],
    [38.660000000000004],
    [38.67],
    [38.68],
    [38.67],
    [38.67],
    [38.68],
    [38.69],
    [38.69],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.7],
    [38.7],
    [38.71],
    [38.68],
    [38.67],
    [38.68],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.65],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.65],
    [38.65],
    [38.65],
    [38.65],
    [38.63],
    [38.65],
    [38.64],
    [38.64],
    [38.63],
    [38.64],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.69],
    [38.67],
    [38.68],
    [38.68],
    [38.69],
    [38.7],
    [38.7],
    [38.69],
    [38.7],
    [38.68],
    [38.67],
    [38.67],
    [38.68],
    [38.68],
    [38.67],
    [38.68],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.69],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.65],
    [38.65],
    [38.660000000000004],
    [38.64],
    [38.65],
    [38.63],
    [38.64],
    [38.64],
    [38.63],
    [38.63],
    [38.62],
    [38.61],
    [38.6],
    [38.6],
    [38.58],
    [38.58],
    [38.57],
    [38.56],
    [38.57],
    [38.56],
    [38.53],
    [38.53],
    [38.52],
    [38.51],
    [38.51],
    [38.52],
    [38.49],
    [38.5],
    [38.51],
    [38.51],
    [38.5],
    [38.5],
    [38.51],
    [38.5],
    [38.52],
    [38.51],
    [38.52],
    [38.51],
    [38.51],
    [38.52],
    [38.5],
    [38.5],
    [38.47],
    [38.480000000000004],
    [38.46],
    [38.49],
    [38.480000000000004],
    [38.49],
    [38.5],
    [38.480000000000004],
    [38.5],
    [38.51],
    [38.52],
    [38.53],
    [38.550000000000004],
    [38.57],
    [38.57],
    [38.57],
    [38.58],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.64],
    [38.65],
    [38.62],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.64],
    [38.65],
    [38.64],
    [38.660000000000004],
    [38.65],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.68],
    [38.67],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.75],
    [38.74],
    [38.74],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.76],
    [38.75],
    [38.77],
    [38.76],
    [38.78],
    [38.76],
    [38.77],
    [38.76],
    [38.76],
    [38.76],
    [38.76],
    [38.75],
    [38.77],
    [38.76],
    [38.76],
    [38.75],
    [38.75],
    [38.75],
    [38.74],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.730000000000004],
    [38.730000000000004],
    [38.75],
    [38.74],
    [38.74],
    [38.75],
    [38.76],
    [38.76],
    [38.75],
    [38.76],
    [38.78],
    [38.76],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.82],
    [38.83],
    [38.83],
    [38.82],
    [38.84],
    [38.85],
    [38.85],
    [38.86],
    [38.88],
    [38.86],
    [38.88],
    [38.9],
    [38.9],
    [38.9],
    [38.93],
    [38.93],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [39.0],
    [39.0],
    [39.02],
    [39.04],
    [39.050000000000004],
    [39.07],
    [39.08],
    [39.1],
    [39.1],
    [39.11],
    [39.12, EVENTS.FEVER_DETECTED],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.18],
    [39.19],
    [39.18],
    [39.19],
    [39.18],
    [39.18],
    [39.2],
    [39.18],
    [39.17],
    [39.18],
    [39.18],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.14],
    [39.13],
    [39.13],
    [39.13],
    [39.12],
    [39.11],
    [39.1],
    [39.08],
    [39.07],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.02],
    [39.0],
    [39.0],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.95],
    [38.96],
    [38.94],
    [38.92],
    [38.93],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.88],
    [38.89],
    [38.87],
    [38.89],
    [38.85, EVENTS.FEVER_OVER],
    [38.85],
    [38.83],
    [38.82],
    [38.85],
    [38.85],
    [38.83],
    [38.83],
    [38.84],
    [38.84],
    [38.84],
    [38.83],
    [38.83],
    [38.85],
    [38.83],
    [38.84],
    [38.84],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.86],
    [38.86],
    [38.89],
    [38.86],
    [38.88],
    [38.89],
    [38.9],
    [38.9],
    [38.89],
    [38.9],
    [38.9],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.94],
    [38.94],
    [38.96],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [39.02],
    [39.02],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.09],
    [39.08],
    [39.1],
    [39.13, EVENTS.FEVER_DETECTED],
    [39.14],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.19],
    [39.17],
    [39.18],
    [39.19],
    [39.22],
    [39.22],
    [39.22],
    [39.25],
    [39.25],
    [39.25],
    [39.28],
    [39.26],
    [39.28],
    [39.29],
    [39.29],
    [39.31],
    [39.29],
    [39.32],
    [39.32],
    [39.33],
    [39.35],
    [39.35],
    [39.35],
    [39.39],
    [39.39],
    [39.39],
    [39.39],
    [39.4],
    [39.410000000000004],
    [39.410000000000004],
    [39.4],
    [39.39],
    [39.410000000000004],
    [39.4],
    [39.39],
    [39.410000000000004],
    [39.4],
    [39.4],
    [39.36],
    [39.36],
    [39.38],
    [39.38],
    [39.37],
    [39.35],
    [39.36],
    [39.34],
    [39.35],
    [39.32],
    [39.32],
    [39.29],
    [39.28],
    [39.27],
    [39.27],
    [39.25],
    [39.24],
    [39.25],
    [39.22],
    [39.22],
    [39.22],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.2],
    [39.21],
    [39.21],
    [39.2],
    [39.22],
    [39.21],
    [39.2],
    [39.21],
    [39.2],
    [39.21],
    [39.19],
    [39.19],
    [39.18],
    [39.2],
    [39.19],
    [39.19],
    [39.19],
    [39.19],
    [39.18],
    [39.17],
    [39.17],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.12],
    [39.12],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.08],
    [39.09],
    [39.09],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.12],
    [39.11],
    [39.12],
    [39.13],
    [39.15],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.17],
    [39.18],
    [39.18],
    [39.19],
    [39.2],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.22],
    [39.24],
    [39.25],
    [39.25],
    [39.26],
    [39.26],
    [39.27],
    [39.28],
    [39.26],
    [39.27],
    [39.27],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.29],
    [39.29],
    [39.29],
    [39.29],
    [39.300000000000004],
    [39.300000000000004],
    [39.32],
    [39.31],
    [39.33],
    [39.33],
    [39.33],
    [39.32],
    [39.33],
    [39.33],
    [39.35],
    [39.33],
    [39.33],
    [39.35],
    [39.35],
    [39.37],
    [39.35],
    [39.35],
    [39.35],
    [39.37],
    [39.35],
    [39.38],
    [39.36],
    [39.39],
    [39.39],
    [39.4],
    [39.4],
    [39.39],
    [39.42],
    [39.42],
    [39.4],
    [39.42],
    [39.42],
    [39.42],
    [39.42],
    [39.42],
    [39.42],
    [39.4],
    [39.42],
    [39.4],
    [39.410000000000004],
    [39.410000000000004],
    [39.42],
    [39.42],
    [39.43],
    [39.45],
    [39.44],
    [39.45],
    [39.46],
    [39.46],
    [39.46],
    [39.5],
    [39.5],
    [39.5],
    [39.49],
    [39.5],
    [39.5],
    [39.480000000000004],
    [39.480000000000004],
    [39.46],
    [39.46],
    [39.45],
    [39.43],
    [39.45],
    [39.43],
    [39.410000000000004],
    [39.37],
    [39.37],
    [39.34],
    [39.32],
    [39.31],
    [39.32],
    [39.300000000000004],
    [39.29],
    [39.29],
    [39.26],
    [39.24],
    [39.230000000000004],
    [39.22],
    [39.21],
    [39.19],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.12],
    [39.12],
    [39.1],
    [39.1],
    [39.08],
    [39.08],
    [39.08],
    [39.08],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.09],
    [39.1],
    [39.11],
    [39.1],
    [39.11],
    [39.11],
    [39.12],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.11],
    [39.09],
    [39.1],
    [39.11],
    [39.12],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.13],
    [39.12],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.12],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.11],
    [39.1],
    [39.12],
    [39.11],
    [39.13],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.15],
    [39.14],
    [39.12],
    [39.13],
    [39.13],
    [39.1],
    [39.09],
    [39.09],
    [39.06],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.08],
    [39.07],
    [39.08],
    [39.06],
    [39.06],
    [39.06],
    [39.07],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.04],
    [39.02],
    [39.04],
    [39.03],
    [39.01],
    [39.02],
    [39.01],
    [39.02],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.99],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.97],
    [39.0],
    [39.01],
    [39.01],
    [39.01],
    [39.03],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.08],
    [39.07],
    [39.08],
    [39.1],
    [39.1],
    [39.12],
    [39.12],
    [39.14],
    [39.14],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.19],
    [39.19],
    [39.21],
    [39.19],
    [39.17],
    [39.14],
    [39.13],
    [39.13],
    [39.12],
    [39.13],
    [39.13],
    [39.12],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.17],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.19],
    [39.19],
    [39.2],
    [39.2],
    [39.21],
    [39.2],
    [39.21],
    [39.21],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.27],
    [39.28],
    [39.28],
    [39.27],
    [39.28],
    [39.28],
    [39.29],
    [39.28],
    [39.29],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.31],
    [39.31],
    [39.32],
    [39.32],
    [39.32],
    [39.34],
    [39.32],
    [39.32],
    [39.34],
    [39.33],
    [39.33],
    [39.34],
    [39.34],
    [39.35],
    [39.34],
    [39.33],
    [39.32],
    [39.300000000000004],
    [39.29],
    [39.29],
    [39.28],
    [39.28],
    [39.27],
    [39.27],
    [39.26],
    [39.25],
    [39.25],
    [39.24],
    [39.24],
    [39.25],
    [39.25],
    [39.230000000000004],
    [39.230000000000004],
    [39.25],
    [39.24],
    [39.24],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.22],
    [39.2],
    [39.2],
    [39.18],
    [39.19],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.14],
    [39.13],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.13],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.2],
    [39.19],
    [39.2],
    [39.2],
    [39.2],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.24],
    [39.24],
    [39.24],
    [39.25],
    [39.24],
    [39.24],
    [39.25],
    [39.230000000000004],
    [39.21],
    [39.21],
    [39.2],
    [39.21],
    [39.21],
    [39.19],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.12],
    [39.12],
    [39.12],
    [39.13],
    [39.13],
    [39.13],
    [39.12],
    [39.13],
    [39.15],
    [39.13],
    [39.14],
    [39.14],
    [39.13],
    [39.1],
    [39.12],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.09],
    [39.1],
    [39.08],
    [39.07],
    [39.09],
    [39.09],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.09],
    [39.09],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.04],
    [39.04],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.06],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.09],
    [39.07],
    [39.06],
    [39.07],
    [39.06],
    [39.07],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.04],
    [39.01],
    [38.99],
    [38.99],
    [38.980000000000004],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.95],
    [38.94],
    [38.92],
    [38.92],
    [38.89],
    [38.92],
    [38.89],
    [38.87],
    [38.86],
    [38.86, EVENTS.FEVER_OVER],
    [38.85],
    [38.84],
    [38.84],
    [38.84],
    [38.85],
    [38.83],
    [38.84],
    [38.83],
    [38.85],
    [38.84],
    [38.84],
    [38.85],
    [38.82],
    [38.82],
    [38.81],
    [38.84],
    [38.82],
    [38.82],
    [38.81],
    [38.83],
    [38.82],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.82],
    [38.83],
    [38.82],
    [38.85],
    [38.85],
    [38.86],
    [38.87],
    [38.87],
    [38.85],
    [38.87],
    [38.87],
    [38.85],
    [38.85],
    [38.85],
    [38.83],
    [38.83],
    [38.82],
    [38.83],
    [38.82],
    [38.85],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.88],
    [38.88],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.94],
    [38.95],
    [38.96],
    [38.97],
    [38.97],
    [38.97],
    [38.97],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [38.980000000000004],
    [38.99],
    [38.99],
    [38.97],
    [38.97],
    [38.95],
    [38.95],
    [38.92],
    [38.92],
    [38.89],
    [38.89],
    [38.88],
    [38.87],
    [38.87],
    [38.85],
    [38.85],
    [38.83],
    [38.83],
    [38.83],
    [38.84],
    [38.83],
    [38.83],
    [38.82],
    [38.85],
    [38.82],
    [38.83],
    [38.85],
    [38.83],
    [38.85],
    [38.83],
    [38.82],
    [38.82],
    [38.800000000000004],
    [38.81],
    [38.81],
    [38.78],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.83],
    [38.81],
    [38.82],
    [38.84],
    [38.85],
    [38.83],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.83],
    [38.82],
    [38.800000000000004],
    [38.800000000000004],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.75],
    [38.75],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.7],
    [38.69],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.74],
    [38.74],
    [38.75],
    [38.76],
    [38.76],
    [38.77],
    [38.77],
    [38.81],
    [38.800000000000004],
    [38.800000000000004],
    [38.81],
    [38.82],
    [38.82],
    [38.83],
    [38.82],
    [38.85],
    [38.83],
    [38.84],
    [38.85],
    [38.84],
    [38.85],
    [38.85],
    [38.83],
    [38.83],
    [38.83],
    [38.85],
    [38.84],
    [38.84],
    [38.84],
    [38.84],
    [38.85],
    [38.85],
    [38.83],
    [38.82],
    [38.81],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.78],
    [38.77],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.76],
    [38.74],
    [38.74],
    [38.71],
    [38.68],
    [38.67],
    [38.65],
    [38.63],
    [38.62],
    [38.62],
    [38.63],
    [38.62],
    [38.63],
    [38.64],
    [38.62],
    [38.64],
    [38.63],
    [38.64],
    [38.65],
    [38.64],
    [38.64],
    [38.62],
    [38.63],
    [38.62],
    [38.62],
    [38.6],
    [38.61],
    [38.61],
    [38.61],
    [38.62],
    [38.61],
    [38.63],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.65],
    [38.64],
    [38.65],
    [38.65],
    [38.64],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.68],
    [38.67],
    [38.69],
    [38.68],
    [38.68],
    [38.7],
    [38.69],
    [38.7],
    [38.69],
    [38.71],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.72],
    [38.72],
    [38.71],
    [38.72],
    [38.71],
    [38.68],
    [38.67],
    [38.65],
    [38.660000000000004],
    [38.64],
    [38.63],
    [38.62],
    [38.62],
    [38.62],
    [38.62],
    [38.6],
    [38.6],
    [38.59],
    [38.57],
    [38.59],
    [38.58],
    [38.59],
    [38.58],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.59],
    [38.57],
    [38.57],
    [38.58],
    [38.6],
    [38.6],
    [38.59],
    [38.58],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.61],
    [38.61],
    [38.59],
    [38.58],
    [38.58],
    [38.59],
    [38.6],
    [38.58],
    [38.57],
    [38.56],
    [38.53],
    [38.53],
    [38.52],
    [38.51],
    [38.5],
    [38.5],
    [38.47],
    [38.49],
    [38.47],
    [38.47],
    [38.46],
    [38.47],
    [38.47],
    [38.47],
    [38.480000000000004],
    [38.49],
    [38.480000000000004],
    [38.47],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.5],
    [38.51],
    [38.480000000000004],
    [38.480000000000004],
    [38.46],
    [38.46],
    [38.46],
    [38.47],
    [38.46],
    [38.46],
    [38.480000000000004],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.49],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.46],
    [38.47],
    [38.47],
    [38.46],
    [38.45],
    [38.46],
    [38.44],
    [38.45],
    [38.46],
    [38.46],
    [38.47],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.47],
    [38.47],
    [38.480000000000004],
    [38.480000000000004],
    [38.480000000000004],
    [38.49],
    [38.47],
    [38.47],
    [38.480000000000004],
    [38.47],
    [38.480000000000004],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.44],
    [38.45],
    [38.44],
    [38.44],
    [38.46],
    [38.45],
    [38.43],
    [38.45],
    [38.43],
    [38.44],
    [38.46],
    [38.46],
    [38.46],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.52],
    [38.51],
    [38.52],
    [38.54],
    [38.56],
    [38.57],
    [38.57],
    [38.59],
    [38.58],
    [38.61],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.67],
    [38.69],
    [38.67],
    [38.68],
    [38.68],
    [38.7],
    [38.7],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.72],
    [38.71],
    [38.730000000000004],
    [38.74],
    [38.74],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.75],
    [38.76],
    [38.76],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.77],
    [38.78],
    [38.77],
    [38.78],
    [38.77],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.79],
    [38.800000000000004],
    [38.800000000000004],
    [38.79],
    [38.82],
    [38.800000000000004],
    [38.79],
    [38.800000000000004],
    [38.81],
    [38.82],
    [38.83],
    [38.82],
    [38.82],
    [38.84],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.87],
    [38.87],
    [38.88],
    [38.9],
    [38.910000000000004],
    [38.93],
    [38.95],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.99],
    [38.97],
    [38.97],
    [39.0],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.97],
    [38.96],
    [38.95],
    [38.94],
    [38.94],
    [38.94],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.88],
    [38.85],
    [38.83],
    [38.800000000000004],
    [38.78],
    [38.76],
    [38.76],
    [38.75],
    [38.75],
    [38.75],
    [38.730000000000004],
    [38.730000000000004],
    [38.72],
    [38.74],
    [38.75],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.72],
    [38.72],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.78],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.82],
    [38.81],
    [38.84],
    [38.83],
    [38.85],
    [38.86],
    [38.86],
    [38.86],
    [38.88],
    [38.910000000000004],
    [38.910000000000004],
    [38.93],
    [38.93],
    [38.93],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.99],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.94],
    [38.95],
    [38.92],
    [38.93],
    [38.92],
    [38.94],
    [38.93],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.03],
    [39.02],
    [39.02],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.07],
    [39.09],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.12],
    [39.12, EVENTS.FEVER_DETECTED],
    [39.14],
    [39.15],
    [39.15],
    [39.15],
    [39.15],
    [39.17],
    [39.17],
    [39.18],
    [39.19],
    [39.2],
    [39.21],
    [39.21],
    [39.22],
    [39.22],
    [39.21],
    [39.24],
    [39.24],
    [39.25],
    [39.25],
    [39.26],
    [39.29],
    [39.28],
    [39.300000000000004],
    [39.28],
    [39.28],
    [39.27],
    [39.26],
    [39.26],
    [39.25],
    [39.25],
    [39.22],
    [39.21],
    [39.21],
    [39.2],
    [39.18],
    [39.19],
    [39.19],
    [39.2],
    [39.19],
    [39.18],
    [39.19],
    [39.2],
    [39.21],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.22],
    [39.22],
    [39.24],
    [39.22],
    [39.21],
    [39.21],
    [39.22],
    [39.22],
    [39.21],
    [39.22],
    [39.21],
    [39.18],
    [39.19],
    [39.18],
    [39.18],
    [39.18],
    [39.18],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.14],
    [39.15],
    [39.15],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.12],
    [39.11],
    [39.11],
    [39.11],
    [39.11],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.07],
    [39.06],
    [39.04],
    [39.050000000000004],
    [39.02],
    [39.0],
    [38.980000000000004],
    [38.96],
    [38.94],
    [38.94],
    [38.89],
    [38.89],
    [38.87],
    [38.88],
    [38.86],
    [38.84, EVENTS.FEVER_OVER],
    [38.84],
    [38.83],
    [38.84],
    [38.86],
    [38.85],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [39.0],
    [38.99],
    [39.0],
    [39.01],
    [39.03],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.08],
    [39.09],
    [39.09],
    [39.09],
    [39.11],
    [39.11],
    [39.12],
    [39.13, EVENTS.FEVER_DETECTED],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.18],
    [39.19],
    [39.18],
    [39.19],
    [39.2],
    [39.2],
    [39.21],
    [39.230000000000004],
    [39.22],
    [39.22],
    [39.25],
    [39.25],
    [39.28],
    [39.28],
    [39.28],
    [39.29],
    [39.31],
    [39.32],
    [39.32],
    [39.35],
    [39.34],
    [39.36],
    [39.37],
    [39.39],
    [39.39],
    [39.39],
    [39.410000000000004],
    [39.410000000000004],
    [39.42],
    [39.42],
    [39.42],
    [39.43],
    [39.43],
    [39.45],
    [39.45],
    [39.47],
    [39.46],
    [39.480000000000004],
    [39.480000000000004],
    [39.49],
    [39.49],
    [39.5],
    [39.52],
    [39.51],
    [39.52],
    [39.51],
    [39.53],
    [39.53],
    [39.52],
    [39.53],
    [39.52],
    [39.5],
    [39.480000000000004],
    [39.5],
    [39.47],
    [39.46],
    [39.47],
    [39.46],
    [39.47],
    [39.480000000000004],
    [39.46],
    [39.47],
    [39.47],
    [39.47],
    [39.46],
    [39.47],
    [39.46],
    [39.46],
    [39.45],
    [39.44],
    [39.43],
    [39.43],
    [39.42],
    [39.42],
    [39.39],
    [39.38],
    [39.34],
    [39.34],
    [39.32],
    [39.32],
    [39.29],
    [39.29],
    [39.26],
    [39.28],
    [39.26],
    [39.26],
    [39.27],
    [39.25],
    [39.25],
    [39.24],
    [39.24],
    [39.22],
    [39.2],
    [39.22],
    [39.21],
    [39.2],
    [39.21],
    [39.2],
    [39.18],
    [39.2],
    [39.2],
    [39.21],
    [39.19],
    [39.2],
    [39.2],
    [39.2],
    [39.19],
    [39.2],
    [39.2],
    [39.19],
    [39.2],
    [39.21],
    [39.22],
    [39.21],
    [39.24],
    [39.230000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.22],
    [39.24],
    [39.230000000000004],
    [39.25],
    [39.27],
    [39.27],
    [39.28],
    [39.28],
    [39.29],
    [39.28],
    [39.300000000000004],
    [39.31],
    [39.31],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.29],
    [39.300000000000004],
    [39.28],
    [39.28],
    [39.28],
    [39.25],
    [39.27],
    [39.27],
    [39.25],
    [39.230000000000004],
    [39.21],
    [39.2],
    [39.18],
    [39.18],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.12],
    [39.1],
    [39.11],
    [39.11],
    [39.12],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.09],
    [39.1],
    [39.09],
    [39.09],
    [39.08],
    [39.1],
    [39.1],
    [39.07],
    [39.07],
    [39.1],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.06],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.02],
    [39.03],
    [39.02],
    [39.03],
    [39.02],
    [39.03],
    [39.04],
    [39.04],
    [39.03],
    [39.03],
    [39.06],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.08],
    [39.1],
    [39.1],
    [39.12],
    [39.14],
    [39.13],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.19],
    [39.19],
    [39.2],
    [39.19],
    [39.2],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.230000000000004],
    [39.24],
    [39.22],
    [39.21],
    [39.230000000000004],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.21],
    [39.21],
    [39.19],
    [39.2],
    [39.17],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.13],
    [39.13],
    [39.12],
    [39.11],
    [39.12],
    [39.11],
    [39.11],
    [39.11],
    [39.09],
    [39.09],
    [39.07],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.01],
    [39.0],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.07],
    [39.08],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.13],
    [39.12],
    [39.12],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.18],
    [39.19],
    [39.2],
    [39.2],
    [39.230000000000004],
    [39.24],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.26],
    [39.25],
    [39.25],
    [39.26],
    [39.25],
    [39.27],
    [39.27],
    [39.27],
    [39.28],
    [39.28],
    [39.28],
    [39.29],
    [39.28],
    [39.28],
    [39.28],
    [39.26],
    [39.27],
    [39.28],
    [39.26],
    [39.25],
    [39.25],
    [39.25],
    [39.22],
    [39.21],
    [39.19],
    [39.18],
    [39.160000000000004],
    [39.17],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.13],
    [39.13],
    [39.1],
    [39.1],
    [39.12],
    [39.13],
    [39.13],
    [39.13],
    [39.13],
    [39.14],
    [39.13],
    [39.13],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.19],
    [39.17],
    [39.21],
    [39.2],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.25],
    [39.26],
    [39.28],
    [39.26],
    [39.28],
    [39.28],
    [39.28],
    [39.25],
    [39.28],
    [39.28],
    [39.28],
    [39.27],
    [39.25],
    [39.24],
    [39.19],
    [39.18],
    [39.17],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.12],
    [39.12],
    [39.1],
    [39.11],
    [39.11],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.12],
    [39.12],
    [39.11],
    [39.12],
    [39.14],
    [39.12],
    [39.12],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.12],
    [39.11],
    [39.1],
    [39.09],
    [39.1],
    [39.08],
    [39.07],
    [39.08],
    [39.07],
    [39.050000000000004],
    [39.07],
    [39.050000000000004],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.07],
    [39.050000000000004],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.01],
    [39.02],
    [39.01],
    [38.99],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.95],
    [38.95],
    [38.94],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.88],
    [38.87],
    [38.86],
    [38.85],
    [38.85, EVENTS.FEVER_OVER],
    [38.83],
    [38.82],
    [38.85],
    [38.83],
    [38.84],
    [38.83],
    [38.82],
    [38.82],
    [38.84],
    [38.82],
    [38.83],
    [38.82],
    [38.81],
    [38.82],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.83],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.800000000000004],
    [38.81],
    [38.81],
    [38.79],
    [38.81],
    [38.800000000000004],
    [38.81],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.76],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.77],
    [38.75],
    [38.76],
    [38.78],
    [38.78],
    [38.79],
    [38.78],
    [38.79],
    [38.81],
    [38.82],
    [38.82],
    [38.83],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.93],
    [38.93],
    [38.94],
    [38.93],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.93],
    [38.93],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.88],
    [38.85],
    [38.85],
    [38.82],
    [38.81],
    [38.79],
    [38.800000000000004],
    [38.800000000000004],
    [38.78],
    [38.800000000000004],
    [38.79],
    [38.79],
    [38.82],
    [38.82],
    [38.84],
    [38.83],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.89],
    [38.89],
    [38.89],
    [38.92],
    [38.93],
    [38.93],
    [38.94],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.97],
    [38.96],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.06],
    [39.04],
    [39.03],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.04],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.01],
    [39.02],
    [39.01],
    [39.0],
    [39.02],
    [39.01],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [39.02],
    [39.02],
    [39.02],
    [39.04],
    [39.04],
    [39.02],
    [39.01],
    [39.0],
    [38.99],
    [38.96],
    [38.96],
    [38.93],
    [38.93],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.86],
    [38.85],
    [38.84],
    [38.82],
    [38.82],
    [38.83],
    [38.83],
    [38.82],
    [38.82],
    [38.81],
    [38.81],
    [38.78],
    [38.78],
    [38.74],
    [38.74],
    [38.71],
    [38.730000000000004],
    [38.730000000000004],
    [38.71],
    [38.69],
    [38.7],
    [38.68],
    [38.68],
    [38.68],
    [38.68],
    [38.67],
    [38.67],
    [38.7],
    [38.69],
    [38.68],
    [38.7],
    [38.7],
    [38.69],
    [38.7],
    [38.7],
    [38.71],
    [38.69],
    [38.71],
    [38.7],
    [38.69],
    [38.7],
    [38.7],
    [38.7],
    [38.71],
    [38.69],
    [38.7],
    [38.69],
    [38.7],
    [38.69],
    [38.68],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.68],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.72],
    [38.75],
    [38.72],
    [38.72],
    [38.71],
    [38.7],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.64],
    [38.63],
    [38.62],
    [38.63],
    [38.63],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.58],
    [38.6],
    [38.6],
    [38.59],
    [38.58],
    [38.59],
    [38.59],
    [38.59],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.58],
    [38.58],
    [38.57],
    [38.57],
    [38.57],
    [38.58],
    [38.57],
    [38.56],
    [38.57],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.54],
    [38.54],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.49],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.480000000000004],
    [38.47],
    [38.46],
    [38.46],
    [38.45],
    [38.46],
    [38.45],
    [38.44],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.47],
    [38.47],
    [38.46],
    [38.46],
    [38.47],
    [38.45],
    [38.47],
    [38.47],
    [38.47],
    [38.46],
    [38.46],
    [38.46],
    [38.49],
    [38.480000000000004],
    [38.49],
    [38.47],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.5],
    [38.5],
    [38.49],
    [38.5],
    [38.51],
    [38.5],
    [38.5],
    [38.5],
    [38.51],
    [38.51],
    [38.51],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.550000000000004],
    [38.58],
    [38.57],
    [38.59],
    [38.61],
    [38.61],
    [38.62],
    [38.63],
    [38.64],
    [38.62],
    [38.62],
    [38.62],
    [38.61],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.57],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.56],
    [38.53],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.53],
    [38.53],
    [38.53],
    [38.51],
    [38.51],
    [38.49],
    [38.49],
    [38.49],
    [38.49],
    [38.49],
    [38.480000000000004],
    [38.47],
    [38.480000000000004],
    [38.5],
    [38.52],
    [38.51],
    [38.51],
    [38.52],
    [38.53],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.57],
    [38.56],
    [38.57],
    [38.58],
    [38.58],
    [38.57],
    [38.58],
    [38.6],
    [38.61],
    [38.62],
    [38.61],
    [38.62],
    [38.63],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.67],
    [38.68],
    [38.68],
    [38.68],
    [38.69],
    [38.71],
    [38.7],
    [38.69],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.730000000000004],
    [38.72],
    [38.72],
    [38.730000000000004],
    [38.72],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.78],
    [38.76],
    [38.75],
    [38.75],
    [38.76],
    [38.76],
    [38.75],
    [38.77],
    [38.77],
    [38.75],
    [38.76],
    [38.78],
    [38.75],
    [38.75],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.77],
    [38.78],
    [38.77],
    [38.78],
    [38.76],
    [38.78],
    [38.77],
    [38.76],
    [38.76],
    [38.76],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.75],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.71],
    [38.69],
    [38.71],
    [38.7],
    [38.7],
    [38.7],
    [38.69],
    [38.69],
    [38.69],
    [38.68],
    [38.69],
    [38.69],
    [38.69],
    [38.69],
    [38.68],
    [38.71],
    [38.7],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.77],
    [38.78],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.83],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.86],
    [38.89],
    [38.89],
    [38.87],
    [38.89],
    [38.87],
    [38.89],
    [38.89],
    [38.87],
    [38.89],
    [38.87],
    [38.87],
    [38.86],
    [38.86],
    [38.84],
    [38.82],
    [38.82],
    [38.800000000000004],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.800000000000004],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.77],
    [38.76],
    [38.75],
    [38.76],
    [38.77],
    [38.75],
    [38.74],
    [38.74],
    [38.75],
    [38.74],
    [38.74],
    [38.730000000000004],
    [38.75],
    [38.75],
    [38.74],
    [38.75],
    [38.76],
    [38.78],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.82],
    [38.81],
    [38.83],
    [38.85],
    [38.85],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.87],
    [38.88],
    [38.86],
    [38.86],
    [38.86],
    [38.86],
    [38.87],
    [38.87],
    [38.86],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.93],
    [38.93],
    [38.95],
    [38.93],
    [38.95],
    [38.96],
    [38.95],
    [38.97],
    [38.97],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.02],
    [39.03],
    [39.06],
    [39.07],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.12],
    [39.12],
    [39.1],
    [39.11],
    [39.1],
    [39.09],
    [39.07],
    [39.09],
    [39.1],
    [39.1],
    [39.08],
    [39.09],
    [39.08],
    [39.07],
    [39.08],
    [39.06],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.06],
    [39.06],
    [39.04],
    [39.04],
    [39.01],
    [39.0],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.96],
    [38.95],
    [38.94],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [39.0],
    [38.99],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.08],
    [39.1],
    [39.1],
    [39.11],
    [39.12],
    [39.15, EVENTS.CALVING_DUE], // Due alarm
    [39.14],
    [39.14],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.11],
    [39.12],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.12],
    [39.12],
    [39.12],
    [39.13],
    [39.15],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.19],
    [39.19],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.14],
    [39.12],
    [39.12],
    [39.11],
    [39.12],
    [39.11],
    [39.1],
    [39.1], // End of first
    [39.12], // Calving session
    [39.12],
    [39.13],
    [39.15],
    [39.16],
    [39.17],
    [39.170000000000004],
    [39.19],
    [39.2],
    [39.2],
    [39.21],
    [39.22],
    [39.230000000000004],
    [39.23],
    [39.24],
    [39.25],
    [39.24],
    [39.25],
    [39.26],
    [39.26],
    [39.27],
    [39.29],
    [39.31],
    [39.330000000000004],
    [39.35],
    [39.36],
    [39.36],
    [39.37],
    [39.38],
    [39.4],
    [39.41],
    [39.41],
    [39.42],
    [39.43],
    [39.45],
    [39.46],
    [39.46],
    [39.460000000000004],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.39],
    [39.35],
    [39.32],
    [39.29],
    [39.29],
    [39.27],
    [39.28],
    [39.28],
    [39.26],
    [39.25],
    [39.25],
    [39.24],
    [39.26],
    [39.27],
    [39.28],
    [39.27],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.24],
    [39.24],
    [39.24],
    [39.21],
    [39.22],
    [39.2],
    [39.21],
    [39.19],
    [39.17],
    [39.18],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.28],
    [39.28],
    [39.28],
    [39.300000000000004],
    [39.31],
    [39.32],
    [39.31],
    [39.32],
    [39.300000000000004],
    [39.34],
    [39.33],
    [39.35],
    [39.410000000000004],
    [39.46],
    [39.46],
    [39.46],
    [39.52],
    [39.52],
    [39.51],
    [39.5],
    [39.49],
    [39.5],
    [39.480000000000004],
    [39.5],
    [39.5],
    [39.53],
    [39.550000000000004],
    [39.54],
    [39.53],
    [39.550000000000004],
    [39.53],
    [39.56],
    [39.54],
    [39.54],
    [39.54],
    [39.53],
    [39.550000000000004],
    [39.57],
    [39.6],
    [39.6],
    [39.6],
    [39.57],
    [39.57],
    [39.54],
    [39.53],
    [39.56],
    [39.57],
    [39.58],
    [39.6],
    [39.61],
    [39.61],
    [39.65],
    [39.65],
    [39.64],
    [39.64],
    [39.61],
    [39.6],
    [39.58],
    [39.58],
    [39.53],
    [39.51],
    [39.46],
    [39.44],
    [39.43],
    [39.42],
    [39.4],
    [39.35],
    [39.35],
    [39.33],
    [39.32],
    [39.31],
    [39.300000000000004],
    [39.28],
    [39.21],
    [39.1],
    [39.09],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.96],
    [38.92],
    [38.910000000000004],
    [38.87],
    [38.85],
    [38.85],
    [38.86],
    [38.87],
    [38.88],
    [38.88],
    [38.89],
    [38.87],
    [38.88],
    [38.85],
    [38.85],
    [38.85],
    [38.79],
    [38.81],
    [38.81],
    [38.79],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.74],
    [38.72],
    [38.69],
    [39.02],
    [39.03],
    [39.02],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.06],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.13],
    [39.13],
    [39.14],
    [39.25],
    [39.26],
    [39.24],
    [39.17],
    [39.14],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.15],
    [39.14],
    [39.15],
    [39.1],
    [39.09],
    [39.07],
    [39.03],
    [39.04],
    [39.03],
    [39.01],
    [39.01],
    [39.0],
    [39.0],
    [39.02],
    [39.01],
    [39.01],
    [39.02],
    [39.01],
    [39.02],
    [39.0],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.95],
    [38.95],
    [38.93],
    [38.93],
    [38.94],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.94],
    [38.93],
    [38.95],
    [38.97],
    [38.96],
    [38.97],
    [39.02],
    [38.980000000000004],
    [39.0],
    [39.0],
    [38.96],
    [38.96],
    [38.94],
    [38.89],
    [38.9],
    [38.89],
    [38.85],
    [38.85],
    [38.84],
    [38.84],
    [38.84],
    [38.84],
    [38.83],
    [38.85],
    [38.84],
    [38.84],
    [38.84],
    [38.85],
    [38.82],
    [38.83],
    [38.81],
    [38.800000000000004],
    [38.77],
    [38.75],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.71],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.62],
    [38.62],
    [38.61],
    [38.61],
    [38.62],
    [38.6],
    [38.61],
    [38.6],
    [38.58],
    [38.58],
    [38.550000000000004],
    [38.550000000000004],
    [38.54],
    [38.53],
    [38.53],
    [38.5],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.480000000000004],
    [38.44],
    [38.44],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.410000000000004],
    [38.39],
    [38.39],
    [38.39],
    [38.37],
    [38.36],
    [38.36],
    [38.39],
    [38.39],
    [38.39],
    [38.410000000000004],
    [38.42],
    [38.410000000000004],
    [38.410000000000004],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.42],
    [38.44],
    [38.43],
    [38.43],
    [38.44],
    [38.45],
    [38.46],
    [38.47],
    [38.480000000000004],
    [38.5],
    [38.51],
    [38.52],
    [38.52],
    [38.53],
    [38.53],
    [38.52],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.57],
    [38.57],
    [38.59],
    [38.61],
    [38.660000000000004],
    [38.660000000000004],
    [38.68],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.77],
    [38.78],
    [38.78],
    [38.81],
    [38.84],
    [38.85],
    [38.89],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.980000000000004],
    [39.0],
    [38.99],
    [39.01],
    [39.03],
    [39.06],
    [39.07],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.04],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.07],
    [39.07],
    [39.09],
    [39.1],
    [39.12],
    [39.12],
    [39.17],
    [39.15],
    [39.17],
    [39.17],
    [39.19],
    [39.19],
    [39.2],
    [39.2],
    [39.19],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.11],
    [39.1],
    [39.11],
    [39.1],
    [39.12],
    [39.12],
    [39.11],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.15],
    [39.13],
    [39.11],
    [39.11],
    [39.1],
    [39.09],
    [39.09],
    [39.06],
    [39.04],
    [39.02],
    [39.01],
    [39.0],
    [39.0],
    [38.96],
    [38.96],
    [38.96],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.95],
    [38.96],
    [38.95],
    [38.95],
    [38.95],
    [38.95],
    [38.94],
    [38.95],
    [38.95],
    [38.96],
    [38.95],
    [38.95],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.96],
    [38.95],
    [38.95],
    [38.92],
    [38.92],
    [38.92],
    [38.95],
    [38.95],
    [38.96],
    [38.94],
    [38.94],
    [38.93],
    [38.93],
    [38.93],
    [38.94],
    [38.96],
    [38.94],
    [38.94],
    [38.94],
    [38.94],
    [38.92],
    [38.94],
    [38.92],
    [38.96],
    [38.95],
    [38.96],
    [39.0],
    [39.0],
    [39.01],
    [39.02],
    [39.01],
    [39.01],
    [38.96],
    [38.94],
    [38.93],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.87],
    [38.87],
    [38.88],
    [38.88],
    [38.83],
    [38.82],
    [38.800000000000004],
    [38.79],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.79],
    [38.78],
    [38.76],
    [38.75],
    [38.74],
    [38.69],
    [38.69],
    [38.69],
    [38.65],
    [38.62],
    [38.61],
    [38.62],
    [38.6],
    [38.57],
    [38.57],
    [38.54],
    [38.54],
    [38.53],
    [38.51],
    [38.51],
    [38.51],
    [38.5],
    [38.5],
    [38.51],
    [38.5],
    [38.49],
    [38.480000000000004],
    [38.46],
    [38.44],
    [38.42],
    [38.39],
    [38.39],
    [38.37],
    [38.36],
    [38.36],
    [38.39],
    [38.39],
    [38.42],
    [38.410000000000004],
    [38.39],
    [38.31],
    [38.300000000000004],
    [38.26],
    [38.26],
    [38.25],
    [38.17],
    [38.160000000000004],
    [38.15],
    [38.14],
    [38.07],
    [38.04],
    [38.03],
    [38.02],
    [37.97],
    [37.96],
    [37.96],
    [37.92],
    [37.92],
    [37.92],
    [37.92],
    [37.9],
    [37.910000000000004],
    [37.89],
    [37.910000000000004],
    [37.89],
    [37.89],
    [37.89],
    [37.86],
    [37.83],
    [37.82],
    [37.800000000000004],
    [37.800000000000004],
    [37.81],
    [37.82],
    [37.82],
    [37.83],
    [37.85],
    [37.89],
    [37.89],
    [37.89],
    [37.910000000000004],
    [37.910000000000004],
    [37.910000000000004],
    [37.87],
    [37.84],
    [37.82],
    [37.85],
    [37.85],
    [37.89],
    [37.86],
    [37.85],
    [37.85],
    [37.85],
    [37.86],
    [37.85],
    [37.84],
    [37.84],
    [37.84],
    [37.83],
    [37.82],
    [37.85],
    [37.84],
    [37.84],
    [37.85],
    [37.86],
    [37.87],
    [37.85],
    [37.85],
    [37.88],
    [37.89],
    [37.89],
    [37.89],
    [37.89],
    [37.89],
    [37.89],
    [37.89],
    [37.88],
    [37.89],
    [37.9],
    [37.96],
    [37.980000000000004],
    [37.97],
    [38.03],
    [38.04],
    [38.06],
    [38.07],
    [38.07],
    [38.06],
    [38.07],
    [38.07],
    [38.07],
    [38.07],
    [38.08],
    [38.12],
    [38.14],
    [38.14],
    [38.17],
    [38.17],
    [38.17],
    [38.17],
    [38.17],
    [38.1],
    [38.11],
    [38.09],
    [38.050000000000004],
    [38.050000000000004],
    [38.04],
    [38.04],
    [38.04],
    [38.03],
    [38.050000000000004],
    [38.050000000000004],
    [38.1],
    [38.12],
    [38.14],
    [38.19],
    [38.19],
    [38.18],
    [38.2],
    [38.2],
    [38.2],
    [38.2],
    [38.2],
    [38.19],
    [38.18],
    [38.18],
    [38.14],
    [38.12],
    [38.050000000000004],
    [38.03],
    [38.03],
    [38.02],
    [38.02],
    [38.01],
    [38.01],
    [37.99],
    [38.0],
    [38.01],
    [38.0],
    [38.01],
    [38.02],
    [38.02],
    [38.04],
    [38.0],
    [37.99],
    [37.97],
    [37.95],
    [37.92],
    [37.910000000000004],
    [37.9],
    [37.85],
    [37.84],
    [37.87],
    [37.83],
    [37.85],
    [37.81],
    [37.81],
    [37.800000000000004],
    [37.81],
    [37.82],
    [37.81],
    [37.84],
    [37.88],
    [37.87],
    [37.88],
    [37.89],
    [37.89],
    [37.89],
    [37.89],
    [37.89],
    [37.89],
    [37.88],
    [37.89],
    [37.87],
    [37.89],
    [37.89],
    [37.88],
    [37.95],
    [37.94],
    [37.96],
    [38.0],
    [38.0],
    [38.01],
    [38.01],
    [38.0],
    [38.01],
    [38.0],
    [38.0],
    [38.0],
    [37.99],
    [37.97],
    [37.99],
    [37.97],
    [37.97],
    [37.980000000000004],
    [37.980000000000004],
    [37.96],
    [37.97],
    [37.95],
    [37.95],
    [37.94],
    [37.9],
    [37.910000000000004],
    [37.92],
    [37.910000000000004],
    [37.910000000000004],
    [37.92],
    [37.92],
    [37.93],
    [37.93],
    [37.94],
    [37.94],
    [37.96],
    [37.97],
    [37.96],
    [37.96],
    [37.96],
    [37.95],
    [37.910000000000004],
    [37.910000000000004],
    [37.88],
    [37.86],
    [37.85],
    [37.85],
    [37.84],
    [37.85],
    [37.85],
    [37.83],
    [37.82],
    [37.82],
    [37.82],
    [37.79],
    [37.78],
    [37.78],
    [37.74],
    [37.75],
    [37.75],
    [37.74],
    [37.75],
    [37.730000000000004],
    [37.74],
    [37.75],
    [37.75],
    [37.74],
    [37.75],
    [37.76],
    [37.78],
    [37.800000000000004],
    [37.82],
    [37.82],
    [37.85],
    [37.85],
    [37.85],
    [37.85],
    [37.84],
    [37.82],
    [37.82],
    [37.82],
    [37.82],
    [37.82],
    [37.92],
    [37.93],
    [37.95],
    [37.95],
    [37.95],
    [37.96],
    [37.96],
    [37.95],
    [37.95],
    [37.94],
    [37.94],
    [37.93],
    [37.96],
    [38.0],
    [37.99],
    [38.03],
    [38.03],
    [38.050000000000004],
    [38.050000000000004],
    [38.04],
    [38.050000000000004],
    [38.04],
    [38.0],
    [38.0],
    [38.0],
    [37.980000000000004],
    [37.97],
    [37.97],
    [37.96],
    [37.980000000000004],
    [37.99],
    [38.0],
    [38.02],
    [38.01],
    [38.03],
    [38.04],
    [38.04],
    [38.07],
    [38.050000000000004],
    [38.06],
    [38.050000000000004],
    [38.04],
    [38.06],
    [38.03],
    [38.04],
    [38.03],
    [38.02],
    [38.0],
    [38.01],
    [38.02],
    [38.02],
    [38.02],
    [38.0],
    [37.96],
    [37.96],
    [37.96],
    [37.95],
    [37.95],
    [37.93],
    [37.94],
    [37.95],
    [37.96],
    [37.980000000000004],
    [37.980000000000004],
    [37.99],
    [38.0],
    [38.0],
    [38.0],
    [38.02],
    [38.02],
    [38.02],
    [38.03],
    [38.03],
    [38.02],
    [38.02],
    [38.01],
    [38.02],
    [38.0],
    [38.01],
    [38.0],
    [38.01],
    [38.01],
    [37.96],
    [37.97],
    [37.96],
    [37.96],
    [37.97],
    [37.99],
    [38.0],
    [38.0],
    [38.0],
    [38.0],
    [38.0],
    [37.99],
    [37.99],
    [37.96],
    [37.92],
    [37.92],
    [37.92],
    [37.93],
    [37.93],
    [37.93],
    [37.93],
    [37.93],
    [37.93],
    [37.94],
    [37.93],
    [37.93],
    [37.92],
    [37.92],
    [37.93],
    [37.92],
    [37.92],
    [37.910000000000004],
    [37.92],
    [37.9],
    [37.89],
    [37.89],
    [37.9],
    [37.92],
    [37.93],
    [37.94],
    [37.93],
    [37.93],
    [37.92],
    [37.9],
    [37.89],
    [37.9],
    [37.9],
    [37.89],
    [37.910000000000004],
    [37.94],
    [37.94],
    [37.95],
    [37.93],
    [37.93],
    [37.94],
    [37.95],
    [37.95],
    [37.97],
    [37.96],
    [37.99],
    [37.99],
    [37.97],
    [37.97],
    [38.02],
    [37.99],
    [37.96],
    [37.96],
    [37.95],
    [37.92],
    [37.9],
    [37.9],
    [37.89],
    [37.88],
    [37.88],
    [37.88],
    [37.88],
    [37.85],
    [37.85],
    [37.85],
    [37.85],
    [37.84],
    [37.87],
    [37.85],
    [37.85],
    [37.85],
    [37.85],
    [37.85],
    [37.85],
    [37.85],
    [37.87],
    [37.910000000000004],
    [37.9],
    [37.910000000000004],
    [37.9],
    [37.92],
    [37.9],
    [37.9],
    [37.87],
    [37.84],
    [37.83],
    [37.81],
    [37.75],
    [37.76],
    [37.74],
    [37.69],
    [37.71],
    [37.7],
    [37.7],
    [37.67],
    [37.67],
    [37.660000000000004],
    [37.64],
    [37.65],
    [37.65],
    [37.62],
    [37.62],
    [37.63],
    [37.63],
    [37.62],
    [37.64],
    [37.64],
    [37.63],
    [37.65],
    [37.67],
    [37.67],
    [37.67],
    [37.67],
    [37.67],
    [37.67],
    [37.67],
    [37.660000000000004],
    [37.660000000000004],
    [37.660000000000004],
    [37.67],
    [37.68],
    [37.69],
    [37.71],
    [37.72],
    [37.74],
    [37.730000000000004],
    [37.730000000000004],
    [37.75],
    [37.72],
    [37.730000000000004],
    [37.730000000000004],
    [37.730000000000004],
    [37.730000000000004],
    [37.75],
    [37.72],
    [37.7],
    [37.7],
    [37.7],
    [37.71],
    [37.72],
    [37.74],
    [37.77],
    [37.77],
    [37.77],
    [37.77],
    [37.77],
    [37.78],
    [37.78],
    [37.78],
    [37.77],
    [37.77],
    [37.76],
    [37.76],
    [37.75],
    [37.75],
    [37.7],
    [37.7],
    [37.69],
    [37.69],
    [37.67],
    [37.67],
    [37.660000000000004],
    [37.67],
    [37.68],
    [37.67],
    [37.67],
    [37.660000000000004],
    [37.67],
    [37.67],
    [37.67],
    [37.660000000000004],
    [37.68],
    [37.67],
    [37.71],
    [37.72],
    [37.75],
    [37.77],
    [37.78],
    [37.78],
    [37.82],
    [37.82],
    [37.82],
    [37.85],
    [37.85],
    [37.85],
    [37.86],
    [37.87],
    [37.89],
    [37.87],
    [37.85, EVENTS.CALVING_DETECTED], // Calving-detected
    [37.42],
    [36.39],
    [34.33],
    [34.33],
    [34.33],
    [34.33],
    [34.33],
    [32.71],
    [32.03],
    [31.36],
    [28.26],
    [27.78],
    [27.28],
    [26.37],
    [25.97],
    [25.57],
    [25.2],
    [24.02],
    [23.79],
    [23.55],
    [22.91],
    [22.78],
    [22.68],
    [24.19],
    [25.09],
    [25.84],
    [26.16],
    [25.560000000000002],
    [25.02],
    [23.080000000000002],
    [22.67],
    [22.28],
    [21.87],
    [20.7],
    [20.900000000000002],
    [21.1],
    [20.71],
    [20.580000000000002],
    [20.490000000000002],
    [20.03],
    [19.7],
    [19.31],
    [19.02],
    [18.150000000000002],
    [17.98],
    [17.81],
    [17.28],
    [17.17],
    [17.080000000000002],
    [16.64],
    [16.6],
    [16.54],
    [16.3],
    [16.25],
    [16.18],
    [16.0],
    [15.98],
    [15.96],
    [15.81],
    [15.8],
    [15.790000000000001],
    [15.790000000000001],
    [15.790000000000001],
    [15.790000000000001],
    [15.780000000000001],
    [15.780000000000001],
    [15.780000000000001],
    [15.780000000000001],
    [15.77],
    [15.77],
    [15.780000000000001],
    [15.780000000000001],
    [15.75],
    [15.75],
    [15.74],
    [15.72],
    [15.72],
    [15.71],
    [15.71],
    [15.71],
    [15.64],
    [15.65],
    [15.63],
    [15.65],
    [15.67],
    [15.69],
    [15.71],
    [15.72],
    [15.72],
    [15.74],
    [15.73],
    [15.72],
    [15.67],
    [15.67],
    [15.66],
    [15.610000000000001],
    [15.6],
    [15.82],
    [16.0],
    [16.15],
    [16.28],
    [16.55],
    [16.6],
    [16.63],
    [16.79],
    [16.84],
    [16.86],
    [17.48],
    [17.67],
    [17.8],
    [18.13],
    [18.17],
    [18.21],
    [18.34],
    [18.36],
    [18.38],
    [18.47],
    [18.490000000000002],
    [18.490000000000002],
    [18.56],
    [18.56],
    [18.59],
    [18.650000000000002],
    [18.66],
    [18.67],
    [18.71],
    [18.740000000000002],
    [18.75],
    [18.8],
    [18.8],
    [18.82],
    [18.88],
    [18.89],
    [18.95],
    [18.94],
    [18.97],
    [19.03],
    [19.03],
    [19.04],
    [19.09],
    [19.1],
    [19.1],
    [19.16],
    [19.17],
    [19.17],
    [19.19],
    [19.240000000000002],
    [19.25],
    [19.26],
    [19.32],
    [19.330000000000002],
    [19.32],
    [19.39],
    [19.400000000000002],
    [19.41],
    [19.42],
    [19.46],
    [19.48],
    [19.5],
    [19.56],
    [19.57],
    [19.580000000000002],
    [19.650000000000002],
    [19.67],
    [19.7],
    [19.73],
    [19.740000000000002],
    [19.73],
    [19.77],
    [19.75],
    [19.76],
    [19.78],
    [19.79],
    [19.81],
    [19.82],
    [19.84],
    [19.85],
    [19.84],
    [19.86],
    [19.86],
    [19.86],
    [19.86],
    [19.88],
    [19.87],
    [19.89],
    [19.89],
    [19.91],
    [19.91],
    [19.900000000000002],
    [19.93],
    [19.95],
    [19.95],
    [19.98],
    [19.98],
    [20.01],
    [20.0],
    [20.06],
    [20.07],
    [20.05],
    [20.1],
    [20.1],
    [20.11],
    [20.09],
    [20.1],
    [20.080000000000002],
    [20.09],
    [20.07],
    [20.07],
    [20.07],
    [20.07],
    [20.06],
    [20.07],
    [20.06],
    [20.07],
    [20.06],
    [20.05],
    [20.06],
    [20.07],
    [20.080000000000002],
    [20.07],
    [20.07],
    [20.09],
    [20.09],
    [20.1],
    [20.1],
    [20.12],
    [20.13],
    [20.12],
    [20.13],
    [20.14],
    [20.14],
    [20.16],
    [20.17],
    [20.17],
    [20.17],
    [20.19],
    [20.150000000000002],
    [20.16],
    [20.14],
    [20.14],
    [20.12],
    [20.14],
    [20.12],
    [20.12],
    [20.14],
    [20.12],
    [20.14],
    [20.14],
    [20.14],
    [20.150000000000002],
    [20.17],
    [20.17],
    [20.17],
    [20.18],
    [20.2],
    [20.21],
    [20.22],
    [20.23],
    [20.25],
    [20.25],
    [20.28],
    [20.28],
    [20.28],
    [20.3],
    [20.29],
    [20.29],
    [20.32],
    [20.32],
    [20.31],
    [20.31],
    [20.31],
    [20.3],
    [20.28],
    [20.240000000000002],
    [20.23],
    [20.21],
    [20.14],
    [20.14],
    [20.13],
    [20.1],
    [20.06],
    [20.06],
    [20.04],
    [19.990000000000002],
    [19.96],
    [19.95],
    [19.89],
    [19.89],
    [19.89],
    [19.81],
    [19.78],
    [19.78],
    [19.73],
    [19.73],
    [19.71],
    [19.71],
    [19.650000000000002],
    [19.64],
    [19.64],
    [19.59],
    [19.57],
    [19.57],
    [19.53],
    [19.5],
    [19.5],
    [19.5],
    [19.44],
    [19.44],
    [19.44],
    [19.400000000000002],
    [19.39],
    [19.37],
    [19.36],
    [19.32],
    [19.32],
    [19.3],
    [19.3],
    [19.28],
    [19.28],
    [19.27],
    [19.23],
    [19.23],
    [19.22],
    [19.21],
    [19.19],
    [19.18],
    [19.17],
    [19.16],
    [19.14],
    [19.12],
    [19.12],
    [19.09],
    [19.09],
    [19.07],
    [19.06],
    [19.07],
    [19.07],
    [19.03],
    [19.03],
    [19.03],
    [19.01],
    [19.0],
    [19.0],
    [19.0],
    [18.97],
    [18.96],
    [18.97],
    [18.97],
    [18.94],
    [18.93],
    [18.93],
    [18.91],
    [18.900000000000002],
    [18.900000000000002],
    [18.89],
    [18.87],
    [18.86],
    [18.85],
    [18.84],
    [18.84],
    [18.830000000000002],
    [18.830000000000002],
    [18.81],
    [18.8],
    [18.82],
    [18.82],
    [18.8],
    [18.81],
    [18.79],
    [18.78],
    [18.78],
    [18.78],
    [18.8],
    [18.79],
    [18.82],
    [18.82],
    [18.830000000000002],
    [18.82],
    [18.84],
    [18.85],
    [18.86],
    [18.87],
    [18.87],
    [18.88],
    [18.88],
    [18.88],
    [18.88],
    [18.88],
    [18.88],
    [18.88],
    [18.88],
    [18.87],
    [18.88],
    [18.86],
    [18.86],
    [18.87],
    [18.85],
    [18.830000000000002],
    [18.84],
    [18.85],
    [18.84],
    [18.82],
    [18.82],
    [18.82],
    [18.82],
    [18.82],
    [18.81],
    [18.78],
    [18.79],
    [18.79],
    [18.79],
    [18.77],
    [18.77],
    [18.76],
    [18.75],
    [18.75],
    [18.77],
    [18.75],
    [18.76],
    [18.76],
    [18.76],
    [18.75],
    [18.75],
    [18.75],
    [18.75],
    [18.75],
    [18.75],
    [18.75],
    [18.75],
    [18.75],
    [18.76],
    [19.13],
    [19.2],
    [19.28],
    [19.31],
    [19.37],
    [19.39],
    [19.38],
    [19.46],
    [19.46],
    [19.48],
    [19.490000000000002],
    [19.54],
    [19.57],
    [19.580000000000002],
    [19.69],
    [19.71],
    [19.740000000000002],
    [19.78],
    [19.82],
    [19.82],
    [19.84],
    [19.92],
    [19.92],
    [19.95],
    [20.0],
    [20.02],
    [20.03],
    [20.04],
    [20.1],
    [20.13],
    [20.14],
    [20.18],
    [20.2],
    [20.21],
    [20.25],
    [20.27],
    [20.28],
    [20.330000000000002],
    [20.34],
    [20.35],
    [20.39],
    [20.39],
    [20.400000000000002],
    [21.01],
    [21.17],
    [21.64],
    [21.72],
    [21.78],
    [21.96],
    [21.98],
    [22.0],
    [22.04],
    [22.06],
    [22.07],
    [22.06],
    [22.05],
    [22.04],
    [22.03],
    [22.02],
    [22.02],
    [22.02],
    [22.01],
    [21.98],
    [21.990000000000002],
    [22.0],
    [21.96],
    [21.98],
    [21.97],
    [21.97],
    [21.95],
    [21.96],
    [21.95],
    [21.94],
    [21.94],
    [21.92],
    [21.94],
    [21.92],
    [21.92],
    [21.93],
    [21.92],
    [21.91],
    [21.92],
    [21.92],
    [21.91],
    [21.91],
    [21.92],
    [21.92],
    [21.91],
    [21.92],
    [21.92],
    [21.93],
    [21.92],
    [21.92],
    [21.94],
    [21.93],
    [21.92],
    [21.94],
    [21.92],
    [21.93],
    [21.92],
    [21.92],
    [21.93],
    [21.92],
    [21.94],
    [21.92],
    [21.93],
    [21.93],
    [21.94],
    [21.96],
    [21.96],
    [21.95],
    [21.95],
    [21.96],
    [21.94],
    [21.95],
    [21.95],
    [21.96],
    [21.95],
    [21.96],
    [21.98],
    [21.97],
    [21.98],
    [21.97],
    [21.97],
    [21.990000000000002],
    [22.0],
    [22.0],
    [22.0],
    [22.0],
    [21.990000000000002],
    [22.0],
    [21.96],
    [21.97],
    [21.96],
    [21.88],
    [21.87],
    [21.85],
    [21.8],
    [21.78],
    [21.77],
    [21.72],
    [21.71],
    [21.68],
    [21.61],
    [21.6],
    [21.52],
    [21.51],
    [21.5],
    [21.42],
    [21.400000000000002],
    [21.39],
    [21.31],
    [21.28],
    [21.28],
    [21.240000000000002],
    [21.21],
    [21.17],
    [21.17],
    [21.150000000000002],
    [21.150000000000002],
    [21.1],
    [21.12],
    [21.1],
    [21.07],
    [21.04],
    [21.04],
    [21.02],
    [21.0],
    [21.0],
    [20.990000000000002],
    [20.93],
    [20.92],
    [20.900000000000002],
    [20.86],
    [20.82],
    [20.82],
    [20.77],
    [20.76],
    [20.740000000000002],
    [20.72],
    [20.66],
    [20.64],
    [20.64],
    [20.580000000000002],
    [20.580000000000002],
    [20.56],
    [20.52],
    [20.490000000000002],
    [20.48],
    [20.48],
    [20.42],
    [20.42],
    [20.400000000000002],
    [20.34],
    [20.330000000000002],
    [20.330000000000002],
    [20.29],
    [20.28],
    [20.25],
    [20.240000000000002],
    [20.18],
    [20.19],
    [20.19],
    [20.14],
    [20.13],
    [20.11],
    [20.07],
    [20.06],
    [20.07],
    [19.96],
    [19.96],
    [19.96],
    [19.92],
    [19.91],
    [19.91],
    [19.84],
    [19.85],
    [19.85],
    [19.81],
    [19.8],
    [19.78],
    [19.75],
    [19.75],
    [19.740000000000002],
    [19.72],
    [19.69],
    [19.68],
    [19.67],
    [19.64],
    [19.63],
    [19.63],
    [19.59],
    [19.580000000000002],
    [19.55],
    [19.54],
    [19.54],
    [19.52],
    [19.5],
    [19.490000000000002],
    [19.5],
    [19.46],
    [19.45],
    [19.45],
    [19.44],
    [19.41],
    [19.42],
    [19.37],
    [19.39],
    [19.39],
    [19.36],
    [19.36],
    [19.35],
    [19.330000000000002],
    [19.32],
    [19.32],
    [19.32],
    [19.28],
    [19.28],
    [19.27],
    [19.25],
    [19.23],
    [19.25],
    [19.21],
    [19.21],
    [19.2],
    [19.2],
    [19.18],
    [19.17],
    [19.17],
    [19.14],
    [19.14],
    [19.14],
    [19.1],
    [19.1],
    [19.1],
    [19.09],
    [19.07],
    [19.080000000000002],
    [19.07],
    [19.09],
    [19.07],
    [19.07],
    [19.04],
    [19.05],
    [19.03],
    [19.03],
    [19.01],
    [19.02],
    [19.0],
    [19.0],
    [19.0],
    [18.98],
    [18.96],
    [18.95],
    [18.94],
    [18.94],
    [18.92],
    [18.92],
    [18.92],
    [18.92],
    [18.900000000000002],
    [18.91],
    [18.89],
    [18.87],
    [18.88],
    [18.87],
    [18.85],
    [18.85],
    [18.84],
    [18.82],
    [18.82],
    [18.82],
    [18.81],
    [18.82],
    [18.82],
    [18.79],
    [18.8],
    [18.79],
    [18.77],
    [18.78],
    [18.78],
    [18.77],
    [18.77],
    [18.76],
    [18.77],
    [18.75],
    [18.740000000000002],
    [18.73],
    [18.71],
    [18.71],
    [18.71],
    [18.69],
    [18.7],
    [18.7],
    [18.69],
    [18.68],
    [18.67],
    [18.66],
    [18.67],
    [18.67],
    [18.650000000000002],
    [18.650000000000002],
    [18.650000000000002],
    [18.64],
    [18.63],
    [18.64],
    [18.61],
    [18.62],
    [18.6],
    [18.59],
    [18.6],
    [18.6],
    [18.580000000000002],
    [18.57],
    [18.580000000000002],
    [18.57],
    [18.57],
    [18.56],
    [18.55],
    [18.56],
    [18.56],
    [18.56],
    [18.56],
    [18.55],
    [18.53],
    [18.53],
    [18.52],
    [18.53],
    [18.53],
    [18.53],
    [18.53],
    [18.53],
    [18.53],
    [18.52],
    [18.5],
    [18.51],
    [18.5],
    [18.5],
    [18.51],
    [18.5],
    [18.51],
    [18.51],
    [18.52],
    [18.51],
    [18.52],
    [18.5],
    [18.52],
    [18.51],
    [18.52],
    [18.51],
    [18.52],
    [18.53],
    [18.53],
    [18.55],
    [18.53],
    [18.53],
    [18.53],
    [18.55],
    [18.59],
    [18.6],
    [18.62],
    [18.64],
    [18.7],
    [18.71],
    [18.81],
    [18.830000000000002],
    [18.85],
    [18.95],
    [18.96],
    [18.95],
    [19.01],
    [19.03],
    [19.05],
    [19.06],
    [19.14],
    [19.16],
    [19.17],
    [19.240000000000002],
    [19.25],
    [19.28],
    [19.35],
    [19.36],
    [19.39],
    [19.43],
    [19.45],
    [19.47],
    [19.52],
    [19.53],
    [19.55],
    [19.57],
    [19.63],
    [19.63],
    [19.650000000000002],
    [19.69],
    [19.71],
    [19.72],
];

import { take, put, call, spawn, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
    CHANGED_FARM_NAME,
    START_CHANGE_FARM_NAME_REQUEST,
    COMPLETE_CHANGE_FARM_NAME_REQUEST,
    FAIL_CHANGE_FARM_NAME_REQUEST,
    RESET_FARM_NAME
} from "../actions/actionTypes";
import axios from "axios";

export function* changeFarmNameRequest() {

    const { name } = yield take(CHANGED_FARM_NAME);
    const currentFarm = yield select(({ farms }) => farms.current );
    yield put({ type: START_CHANGE_FARM_NAME_REQUEST });
    yield call(delay, 2000);
    try {
        const updatedFarm = { ...currentFarm, name };
        yield call(axios.put, "/farms", updatedFarm);
        yield put({ type: COMPLETE_CHANGE_FARM_NAME_REQUEST, farm: updatedFarm });
        yield call(delay, 2000);
        yield put({ type: RESET_FARM_NAME });
    } catch (error) {
        yield put({ type: FAIL_CHANGE_FARM_NAME_REQUEST, error });
        yield call(delay, 2000);
        yield put({ type: RESET_FARM_NAME });
    } finally {
        yield spawn(changeFarmNameRequest);
    }
}
import { SET_PRIVILIGES } from "../actions/actionTypes";

export default function (state = {}, action) {
    switch (action.type) {
    case SET_PRIVILIGES:
        return action.priviliges.reduce((acc, p) => {
            return {
                ...acc,
                [p.name]: p
            };
        }, state);
    default: return state;
    }
}

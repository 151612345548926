import {
    COMPLETE_APP_STARTUP,
    SET_FARM_WARNING_COUNTS,
    COMPLETE_CHANGE_FARM_NAME_REQUEST,
} from "../actions/actionTypes";

export default function (state = {}, action) {
    if (action.type === COMPLETE_APP_STARTUP) {
        return {
            all: action.farms,
            current: action.farm,
        };
    } else if (action.type === SET_FARM_WARNING_COUNTS) {
        return {
            ...state,
            all: state.all.reduce((acc, f) => {
                return [
                    ...acc,
                    { ...f, warningCount: action.farmWarnings[f.id] },
                ];
            }, []),
        };
    } else if (action.type === COMPLETE_CHANGE_FARM_NAME_REQUEST) {
        return {
            ...state,
            all: state.all.reduce((acc, f) => {
                if (f.id == action.farm.id) return [...acc, action.farm];
                return [...acc, f];
            }, []),
            current: action.farm,
        };
    } else {
        return state;
    }
}

import React, { useRef } from "react";
import "./Metrics.css";
import cn from "classnames";

import { detectFotmat, formats, metricTexts } from "./utility";
import PieMetric from "./PieMetric";

export default function Metrics({ title, info, metrics }) {
    const metricsRef = useRef();
    const metricsFiltered = Object.keys(metrics)
        .filter((k) => metrics[k] !== 0)
        .reduce((acc, k) => {
            return { ...acc, [k]: metrics[k] };
        }, {});
    if (Object.keys(metricsFiltered).length > 0) metrics = metricsFiltered;
    const format = detectFotmat(metrics);
    const sortedKeys = Object.keys(metrics).sort(
        (a, b) => metrics[b] - metrics[a],
    );
    const seperators = formats[format].seperators(metrics);
    const usePie = sortedKeys.length > 1 ? true : false;

    let table;
    switch (format) {
        case "twoRowsThreeMetrics":
        default:
            table = (
                <div
                    ref={metricsRef}
                    className={cn("metrics", "two-rows-three-metrics")}>
                    <div style={{ gridColumnEnd: seperators.v[0] }}>
                        <strong>{`${metrics[sortedKeys[0]]} `}</strong>
                        <span>{` ${metricTexts[sortedKeys[0]]}`}</span>
                    </div>
                    <div style={{ gridColumnStart: seperators.v[0] }}>
                        <strong>{`${metrics[sortedKeys[1]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[1]]}`}</span>
                    </div>
                    <div style={{ gridColumnStart: seperators.v[0] }}>
                        <strong>{`${metrics[sortedKeys[2]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[2]]}`}</span>
                    </div>
                </div>
            );
            break;
        case "threeRowsThreeMetrics":
            table = (
                <div
                    ref={metricsRef}
                    className={cn("metrics", "three-rows-three-metrics")}>
                    <div style={{ gridRowEnd: seperators.h[0] }}>
                        <strong>{`${metrics[sortedKeys[0]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[0]]}`}</span>
                    </div>
                    <div
                        style={{
                            gridRowStart: seperators.h[0],
                            gridColumnEnd: seperators.v[0],
                        }}>
                        <strong>{`${metrics[sortedKeys[1]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[1]]}`}</span>
                    </div>
                    <div
                        style={{
                            gridRowStart: seperators.h[0],
                            gridColumnStart: seperators.v[0],
                        }}>
                        <strong>{`${metrics[sortedKeys[2]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[2]]}`}</span>
                    </div>
                </div>
            );
            break;
        case "fourRowsFourMetrics":
            table = (
                <div
                    ref={metricsRef}
                    className={cn("metrics", "four-rows-four-metrics")}>
                    <div style={{ gridColumnEnd: seperators.v[0] }}>
                        <strong>{`${metrics[sortedKeys[0]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[0]]}`}</span>
                    </div>
                    <div
                        style={{
                            gridColumnStart: seperators.v[0],
                            gridRowEnd: seperators.h[0],
                        }}>
                        <strong>{`${metrics[sortedKeys[1]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[1]]}`}</span>
                    </div>
                    <div
                        style={{
                            gridColumnStart: seperators.v[0],
                            gridRowStart: seperators.h[0],
                            gridRowEnd: seperators.h[1],
                        }}>
                        <strong>{`${metrics[sortedKeys[2]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[2]]}`}</span>
                    </div>
                    <div
                        style={{
                            gridColumnStart: seperators.v[0],
                            gridRowStart: seperators.h[1],
                        }}>
                        <strong>{`${metrics[sortedKeys[3]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[3]]}`}</span>
                    </div>
                </div>
            );
            break;
        case "oneRowTwoMetrics":
            table = (
                <div
                    ref={metricsRef}
                    className={cn("metrics", "one-row-two-metrics")}>
                    <div style={{ gridColumnEnd: seperators.v[0] }}>
                        <strong>{`${metrics[sortedKeys[0]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[0]]}`}</span>
                    </div>
                    <div style={{ gridColumnStart: seperators.v[0] }}>
                        <strong>{`${metrics[sortedKeys[1]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[1]]}`}</span>
                    </div>
                </div>
            );
            break;
        case "oneRowOneMetric":
            table = (
                <div
                    ref={metricsRef}
                    className={cn("metrics", "one-row-one-metric")}>
                    <div>
                        <strong>{`${metrics[sortedKeys[0]]}`}</strong>
                        <span>{` ${metricTexts[sortedKeys[0]]}`}</span>
                    </div>
                </div>
            );
            break;
    }

    return (
        <>
            <div className="metric-container">
                <div className="title">{title}</div>
                <div className="info">
                    {info ? (
                        <>
                            <strong>{info.emphasise}</strong>
                            <span>{info.text}</span>
                        </>
                    ) : null}
                </div>
                {usePie ? (
                    <PieMetric
                        metrics={metrics}
                        colors={getFormatColors(format)}
                    />
                ) : (
                    table
                )}
            </div>
        </>
    );
}

function getFormatColors(format) {
    switch (format) {
        case "twoRowsThreeMetrics":
            return ["#CA0566", "#FF7D46", "#FFE458"];
        case "threeRowsThreeMetrics":
            return ["#582261", "#CA0566", "#FF7D46"];
        case "fourRowsFourMetrics":
            return ["#582261", "#CA0566", "#FF7D46", "#FFE458"];
        case "oneRowTwoMetrics":
            return ["#CA0566", "#582261"];
        case "oneRowOneMetric":
            return ["#582261"];
    }
}

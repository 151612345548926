import { COMPLETE_FETCH_EVENTS_REQUEST } from "../actions/actionTypes";

const defaultState = { earliest: Number.MAX_SAFE_INTEGER, latest: Number.MIN_SAFE_INTEGER };

export default function (state = defaultState, action) {
    if (action.type === COMPLETE_FETCH_EVENTS_REQUEST) {
        return action.data.events.reduce((tempState, event) => ({
            earliest: Math.min(event.timestamp, tempState.earliest),
            latest: Math.max(event.timestamp, tempState.latest)
        }), {
            earliest: Math.min(state.earliest, action.params.since || Number.MAX_SAFE_INTEGER),
            latest: Math.max(state.latest, action.params.until || Number.MIN_SAFE_INTEGER)
        });
    }
    return state;
}

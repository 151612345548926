import React, { useEffect, useState } from "react";
import cn from "classnames";
import { connect } from "react-redux";

import i18n from "../../i18n";
import { SENSOR, REPEATER } from "../../constants";
import { BASESTATION } from "../../constants/schema";
import TooltipCommon from "../TooltipCommon";
import { last4 } from "../SensorLabel";

import "./SystemPreview.css";

const SystemPreview = ({
    sensors,
    basestations,
    TsensAvailabilityCounts,
    earTagAvailabilityCounts,
    internetStatus,
    repeaterData,
    basestationData,
}) => {
    const [, setPeriodicRefresh] = useState([]);
    useEffect(() => {
        const interval = setInterval(() => {
            setPeriodicRefresh([]);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    });
    return (
        <aside className={cn("system-preview")} open>
            <div className="wicow-server single">
                <label>wiCow server</label>
            </div>
            <div className="internet single">
                <label className={cn({ offline: !internetStatus })}>
                    Internet
                </label>
            </div>
            {basestations.map((b) => {
                return (
                    <div key={b.id} className="basestation single">
                        <TooltipCommon
                            key={b.id}
                            position={
                                window.screen.width < 641 ? "left" : "right"
                            }
                            content={
                                <BaseStationContent
                                    data={basestationData[b.id]}
                                    tagValues={b.tagValues}
                                    tags={b.tags}
                                />
                            }>
                            <label className={cn(b.tagValues)}>
                                Base Station
                            </label>
                        </TooltipCommon>
                    </div>
                );
            })}
            <div className="repeaters">
                {sensors.repeater
                    ? sensors.repeater.map((r) => {
                          return (
                              <div key={r.deviceId} className="repeater">
                                  <TooltipCommon
                                      key={r.deviceId}
                                      content={
                                          <RepeaterContent
                                              data={repeaterData[r.id]}
                                              tagValues={r.tagValues}
                                              tags={r.tags}
                                              isSupervisor={false}
                                          />
                                      }>
                                      <label className={cn(r.tagValues)}>
                                          {last4(r.deviceId)}
                                      </label>
                                  </TooltipCommon>
                              </div>
                          );
                      })
                    : null}
                {sensors.supervisor
                    ? sensors.supervisor.map((s) => {
                          return (
                              <div
                                  key={s.deviceId}
                                  className="repeater supervisor">
                                  <TooltipCommon
                                      key={s.deviceId}
                                      content={
                                          <RepeaterContent
                                              data={repeaterData[s.id]}
                                              tagValues={s.tagValues}
                                              tags={s.tags}
                                              isSupervisor={true}
                                          />
                                      }>
                                      <label className={cn(s.tagValues)}>
                                          {last4(s.deviceId)}
                                      </label>
                                  </TooltipCommon>
                              </div>
                          );
                      })
                    : null}
            </div>
            {sensors.Tsens ? (
                <div className="tsens-summary">
                    <span>
                        <strong>{sensors.Tsens.length}</strong> × Tsens
                    </span>
                    {TsensAvailabilityCounts.available ? (
                        <span>
                            <strong>{TsensAvailabilityCounts.available}</strong>
                            {i18n.t("available")}
                        </span>
                    ) : null}
                    {TsensAvailabilityCounts.inUse ? (
                        <span>
                            <strong>{TsensAvailabilityCounts.inUse}</strong>
                            {i18n.t("inUse")}
                        </span>
                    ) : null}
                    {TsensAvailabilityCounts.unavailable ? (
                        <span>
                            <strong>
                                {TsensAvailabilityCounts.unavailable}
                            </strong>
                            {i18n.t("unavailable")}
                        </span>
                    ) : null}
                    {TsensAvailabilityCounts.transfer ? (
                        <span>
                            <strong>{TsensAvailabilityCounts.transfer}</strong>
                            {i18n.t("transfer")}
                        </span>
                    ) : null}
                </div>
            ) : null}
            {sensors.earTag ? (
                <div className="eartag-summary">
                    <span>
                        <strong>{sensors.earTag.length}</strong> × eartag
                    </span>
                    {earTagAvailabilityCounts.available ? (
                        <span>
                            <strong>
                                {earTagAvailabilityCounts.available}
                            </strong>
                            {i18n.t("available")}
                        </span>
                    ) : null}
                    {earTagAvailabilityCounts.inUse ? (
                        <span>
                            <strong>{earTagAvailabilityCounts.inUse}</strong>
                            {i18n.t("inUse")}
                        </span>
                    ) : null}
                    {earTagAvailabilityCounts.unavailable ? (
                        <span>
                            <strong>
                                {earTagAvailabilityCounts.unavailable}
                            </strong>
                            {i18n.t("unavailable")}
                        </span>
                    ) : null}
                    {earTagAvailabilityCounts.transfer ? (
                        <span>
                            <strong>{earTagAvailabilityCounts.transfer}</strong>
                            {i18n.t("transfer")}
                        </span>
                    ) : null}
                </div>
            ) : null}
        </aside>
    );
};

function mapStateToProps({
    sensors,
    tags,
    basestations,
    repeaterData,
    basestationData,
}) {
    const activeSensors = Object.values(sensors).filter((s) => {
        if (!tags.sensor[s.id]) return false;
        return tags.sensor[s.id].find((t) => t.value !== SENSOR.EXCLUDED);
    });
    const ss = activeSensors.reduce((acc, s) => {
        if (!acc[s.type]) acc[s.type] = [];
        const tagValues = tags.sensor[s.id].map((t) => t.value);
        return {
            ...acc,
            [s.type]: [
                ...acc[s.type],
                {
                    ...s,
                    tagValues,
                    availability: detectAvailability(tagValues),
                    tags: tags.sensor[s.id],
                },
            ],
        };
    }, {});
    return {
        sensors: ss,
        TsensAvailabilityCounts: ss.Tsens
            ? ss.Tsens.reduce((acc, s) => {
                  return {
                      ...acc,
                      available: ss[s.type].filter(
                          (s) => s.availability === "available",
                      ).length,
                      inUse: ss[s.type].filter(
                          (s) => s.availability === "inUse",
                      ).length,
                      unavailable: ss[s.type].filter(
                          (s) => s.availability === "problem",
                      ).length,
                      transfer: ss[s.type].filter(
                          (s) => s.availability === "transfer",
                      ).length,
                  };
              }, {})
            : null,
        earTagAvailabilityCounts: ss.earTag
            ? ss.earTag.reduce((acc, s) => {
                  return {
                      ...acc,
                      available: ss[s.type].filter(
                          (s) => s.availability === "available",
                      ).length,
                      inUse: ss[s.type].filter(
                          (s) => s.availability === "inUse",
                      ).length,
                      unavailable: ss[s.type].filter(
                          (s) => s.availability === "problem",
                      ).length,
                      transfer: ss[s.type].filter(
                          (s) => s.availability === "transfer",
                      ).length,
                  };
              }, {})
            : null,
        basestations: Object.values(basestations).reduce((acc, b) => {
            return [
                ...acc,
                {
                    ...b,
                    tagValues: tags.basestation[b.id].map((t) => t.value),
                    tags: tags.basestation[b.id],
                },
            ];
        }, []),
        internetStatus: Object.values(tags.basestation).reduce((acc, bt) => {
            if (bt.find((t) => t.value === BASESTATION.OFFLINE))
                return acc || false;
            return true;
        }, false),
        repeaterData,
        basestationData,
    };
}

function detectAvailability(tagValues) {
    if (tagValues.includes(SENSOR.IDLE)) return "available";
    if (tagValues.includes(SENSOR.ACTIVE)) return "inUse";
    if (tagValues.includes(SENSOR.NETWORK_PROBLEM)) return "problem";
    if (tagValues.includes(SENSOR.TRANSFER)) return "transfer";
    return "unknown";
}

function RepeaterContent({ data, tagValues, tags, isSupervisor }) {
    let status,
        statusClass,
        param = null;
    if (tagValues.includes(REPEATER.IDLE)) {
        statusClass = "ready";
        status = i18n.t(statusClass);
    } else if (tagValues.includes(REPEATER.ONLINE)) {
        statusClass = "online";
        status = i18n.t(statusClass);
        param = tags.find((t) => t.value === REPEATER.ONLINE).params
            .onlineSince;
    } else if (tagValues.includes(REPEATER.OFFLINE)) {
        statusClass = "offline";
        status = i18n.t(statusClass);
        param = tags.find((t) => t.value === REPEATER.OFFLINE).params
            .offlineSince;
    }

    if (!data) return <span>{i18n.t("loading")}..</span>;
    return (
        <React.Fragment>
            <div className="sensor-data">
                {data.temperature ? (
                    <label className="temperature">
                        {data.temperature} &#176;C
                    </label>
                ) : null}
                {data.rssi ? (
                    <label className="rssi"> {data.rssi} dB</label>
                ) : null}
            </div>
            <label className={cn("status", statusClass)}>
                <strong>{status}</strong> {calculateTime(param)}
            </label>
            <div className="last-packet">
                <span>{i18n.t("lastPacket")}</span>
                {calculateLastTime(data.lastPacketTime)}
            </div>
            {isSupervisor ? null : (
                <div className="daily-traffic">
                    <span> {i18n.t("dailyTraffic")} </span>
                    <span>
                        <strong>{parseInt(data.dailyTraffic * 100)}</strong> %
                    </span>
                </div>
            )}
        </React.Fragment>
    );
}
function BaseStationContent({ tagValues, tags, data }) {
    let status,
        statusClass,
        param = null;
    if (tagValues.includes(BASESTATION.IDLE)) {
        statusClass = "ready";
        status = i18n.t(statusClass);
    } else if (tagValues.includes(BASESTATION.ONLINE)) {
        statusClass = "online";
        status = i18n.t(statusClass);
        param = tags.find((t) => t.value === BASESTATION.ONLINE).params
            .onlineSince;
    } else if (tagValues.includes(BASESTATION.OFFLINE)) {
        statusClass = "offline";
        status = i18n.t(statusClass);
        param = tags.find((t) => t.value === BASESTATION.OFFLINE).params
            .offlineSince;
    }
    if (!data) return <span>{i18n.t("loading")}..</span>;
    return (
        <React.Fragment>
            <div className="basestation-data">
                <label className="temperature">
                    {data.temperature} &#176;C
                </label>
            </div>
            <label className={cn("status", statusClass)}>
                <strong>{status}</strong> {calculateTime(param)}
            </label>
            <div className="last-packet">
                <span>{i18n.t("lastPacket")}</span>
                {calculateLastTime(data.lastPacketTime)}
            </div>
        </React.Fragment>
    );
}
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SystemPreview);

function calculateTime(timestamp) {
    if (!timestamp) return null;
    const currentDate = new Date();
    const seconds = (currentDate.getTime() - timestamp) / 1000;
    if (seconds < 60) {
        return (
            <span>
                <strong>
                    {i18n.t("for")} {parseInt(seconds)}
                </strong>
                {`${i18n.t("day", { count: seconds })}`}
            </span>
        );
    } else if (seconds < 3600) {
        return (
            <span>
                <strong>
                    {i18n.t("for")} {parseInt(seconds / 60)}
                </strong>
                {`${i18n.t("minute", { count: parseInt(seconds / 60) })}`}
            </span>
        );
    } else if (seconds < 86400) {
        return (
            <span>
                <strong>
                    {i18n.t("for")} {parseInt(seconds / 3600)}
                </strong>
                {`${i18n.t("hour", { count: parseInt(seconds / 3600) })}`}
            </span>
        );
    } else {
        return (
            <span>
                <strong>
                    {i18n.t("for")} {parseInt(seconds / 86400)}
                </strong>
                {`${i18n.t("day", { count: parseInt(seconds / 86400) })}`}
            </span>
        );
    }
}

function calculateLastTime(timestamp) {
    if (timestamp === 0) return <span>0</span>;
    const currentDate = new Date();
    const seconds = (currentDate.getTime() - timestamp) / 1000;
    if (seconds < 60) {
        return (
            <span>
                <strong>{parseInt(seconds)}</strong>
                {`${i18n.t("second", { count: seconds })} ${i18n.t("ago")}`}
            </span>
        );
    } else if (seconds < 3600) {
        return (
            <span>
                <strong>{parseInt(seconds / 60)}</strong>
                {`${i18n.t("minute", {
                    count: parseInt(seconds / 60),
                })} ${i18n.t("ago")}`}
            </span>
        );
    } else if (seconds < 86400) {
        return (
            <span>
                <strong>{parseInt(seconds / 3600)}</strong>
                {`${i18n.t("hour", {
                    count: parseInt(seconds / 3600),
                })} ${i18n.t("ago")}`}
            </span>
        );
    } else {
        return (
            <span>
                <strong>{parseInt(seconds / 86400)}</strong>
                {`${i18n.t("day", {
                    count: parseInt(seconds / 86400),
                })} ${i18n.t("ago")}`}
            </span>
        );
    }
}

import React, { useEffect, useMemo, useRef, useState } from "react";

import { DONE } from "../../constants/lifecycle";
import { data as connectCow } from "../../containers";
import "../../containers/Lifecycle/Lifecycle.css";
import { useMobile } from "../isMobile";
import Time from "../Time";
import Tooltip from "../Tooltip";

import "./DataGraph.css";
import EventBadges from "./EventBadges";

function DataGraph({
    temperature,
    lifecycle,
    isScaled,
    cowEvents,
    language,
    tooltipClickedVisible,
    setTooltipClickedVisible,
    useAutomation = false,
    endTime,
    cowPreviewVisibleMobile = false,
}) {
    const tooltipRef = useRef(null);
    const temperatureRef = useRef(null);
    const { isMobileDevice, isLandscapeView } = useMobile();
    temperatureRef.current = temperature;

    const [TsensPoint, setTsensPoint] = useState({
        x: -4,
        y: -4,
        value: null,
        timestamp: null,
    });
    const [earTagPoint, setEartagPoint] = useState({
        x: -4,
        y: -4,
        value: null,
        timestamp: null,
    });

    const [TsensTooltipHoverVisible, setTsensTooltipHoverVisible] = useState(
        null,
    );
    const [earTagTooltipHoverVisible, setEarTagTooltipHoverVisible] = useState(
        null,
    );
    const [hoverTextLength, sethoverTextLength] = useState(null);

    const [tooltipClickedX, setTooltipClickedX] = useState(null);
    const [tooltipClickedY, setTooltipClickedY] = useState(null);
    const [tooltipClickedSensor, setTooltipClickedSensor] = useState("Tsens");
    const tooltipClickedSensorRef = useRef(null);
    const [tooltipPosition, setTooltipPosition] = useState(null);

    const [activeTemperature, setActiveTemperature] = useState({
        timestamp: [],
        data: [],
        environment: [],
        activity: [],
    });
    const [TsensTemperature, setTsensTemperature] = useState({
        timestamp: [],
        data: [],
        environment: [],
        activity: [],
    });
    const [earTagTemperature, setEarTagTemperature] = useState({
        timestamp: [],
        data: [],
        environment: [],
        activity: [],
    });
    const [isFullscreen, setFullscreen] = useState(false);

    //for calculating dragging difference
    const [lastPoint, setLastPoint] = useState(null);
    const [startPoint, setStartPoint] = useState(null);
    const [checkPoint, setCheckPoint] = useState(null);
    const lastPointRef = useRef(null);
    const checkPointRef = useRef(null);
    const startPointRef = useRef(null);
    useEffect(() => {
        lastPointRef.current = lastPoint;
        startPointRef.current = startPoint;
        checkPointRef.current = checkPoint;
        tooltipClickedSensorRef.current = tooltipClickedSensor;
    });
    useEffect(() => {
        const fullscreen = document.querySelector("main.dashboard.fullscreen");
        if (fullscreen && isLandscapeView) {
            setFullscreen(true);
        } else {
            setFullscreen(false);
            setTooltipClickedVisible(false);
        }
    }, [isLandscapeView, setTooltipClickedVisible]);
    const graphCoordinateY = {
        min: isScaled ? "0" : "30",
        length: isScaled ? "60" : "15",
        translateY: isScaled ? "-60" : "-75",
        thermalPathOffset: isScaled ? "0" : "30",
    };

    useMemo(() => {
        if (Object.entries(temperature).length === 0) return;
        if (temperature.Tsens) setTsensTemperature(temperature.Tsens);
        if (temperature.earTag) setEarTagTemperature(temperature.earTag);
        const TsensPriority = Object.keys(temperature).find((sensorType) => {
            return sensorType === "Tsens";
        });
        if (!TsensPriority) {
            setActiveTemperature(temperature.earTag);
        } else {
            setActiveTemperature(temperature.Tsens);
        }
        if (temperature.earTag && !temperature.Tsens) {
            setTooltipClickedSensor("earTag");
        } else {
            setTooltipClickedSensor("Tsens");
        }
    }, [temperature]);

    useEffect(() => {
        document.addEventListener("touchstart", onClickOutside, true);
        return () => {
            document.removeEventListener("touchstart", onClickOutside, true);
        };
    });

    function temperaturePath(dataPoints) {
        if (dataPoints.data.length === 0) return null;
        return (
            `M${dataPoints.data.findIndex((t) => t)} ${dataPoints.data.find(
                (t) => t,
            )}` +
            dataPoints.data
                .map((data, index) =>
                    data ? `L${index} ${data.toFixed(1)}` : null,
                )
                .join(" ")
        );
    }

    function environmentTempPath(dataPoints) {
        if (dataPoints.environment.length === 0) return null;
        return (
            `M${dataPoints.environment.findIndex(
                (t) => t,
            )} ${dataPoints.environment.find((t) => t)}` +
            dataPoints.environment
                .map((data, index) =>
                    data ? `L${index} ${data.toFixed(1)}` : null,
                )
                .join(" ")
        );
    }

    function activityPath(dataPoints) {
        if (dataPoints.activity.length === 0) return null;
        return (
            `M${dataPoints.activity.findIndex((t) => t)} ${(
                dataPoints.activity.find((t) => t) / 15 -
                50
            ).toFixed(1)}` +
            dataPoints.activity
                .map((data, index) =>
                    data ? `L${index} ${(data / 15 - 50).toFixed(1)}` : null,
                )
                .join(" ")
        );
    }

    function onClickOutside(event) {
        const tooltipClicked = document.querySelector("#tooltip-clicked");
        const dataGraph = document.querySelector(".data-graph");
        if (tooltipClicked && !tooltipClicked.contains(event.target)) {
            setTooltipClickedVisible(false);
        }
        if (
            !dataGraph.contains(event.target) &&
            (earTagTooltipHoverVisible || TsensTooltipHoverVisible)
        ) {
            setEarTagTooltipHoverVisible(null);
            setTsensTooltipHoverVisible(null);
        }
    }

    function onMouseMove(event, temperature) {
        const dataGraph = event.target.closest(".data-graph");
        const points = getTooltipPoint(event, temperature);
        if (points.earTag) {
            setEarTagTooltipHoverVisible("visible");
            setEartagPoint(points.earTag);
        }
        if (points.Tsens) {
            setTsensTooltipHoverVisible("visible");
            setTsensPoint(points.Tsens);
        }
        const hoverText = dataGraph.querySelector("svg#tooltip text");
        if (hoverText) sethoverTextLength(hoverText.getBBox().width / 2);
    }

    function onPointClicked(event, temperature) {
        if (!isFullscreen) return;
        const tooltipClicked = event.target.closest("#tooltip-clicked");
        const svg = document.querySelector(".data-graph svg#data");
        if (tooltipClicked) {
            return setStartPoint(
                calculateSvgToDom(
                    event.touches[0].clientX,
                    event.touches[0].clientY,
                    svg,
                ),
            );
        }
        const points = getTooltipPoint(event, temperature);
        const content = document.querySelector("foreignObject div.content");
        const contentWidth = content ? content.offsetWidth : 160;
        if (points.earTag) {
            setEartagPoint(points.earTag);
            setLastPoint(
                calculateSvgToDom(points.earTag.x, points.earTag.y, svg),
            );
            setTooltipClickedX(points.earTag.x);
            setTooltipClickedY(points.earTag.y);
            if (points.earTag.x - contentWidth < 0) {
                setTooltipPosition("right");
            } else {
                setTooltipPosition("left");
            }
        }
        if (points.Tsens) {
            setTsensPoint(points.Tsens);
            setLastPoint(
                calculateSvgToDom(points.Tsens.x, points.Tsens.y, svg),
            );
            setTooltipClickedX(points.Tsens.x);
            setTooltipClickedY(points.Tsens.y);
            if (points.Tsens.x - contentWidth < 0) {
                setTooltipPosition("right");
            } else {
                setTooltipPosition("left");
            }
        }
        setTooltipClickedVisible(true);
    }

    function onTooltipStart() {
        tooltipRef.current.addEventListener(
            "touchmove",
            onTooltipMouseMove.current,
        );
    }
    function onTooltipEnd() {
        const tooltipArrowWidth = 10;
        const graph = document.querySelector("div.data-graph");
        const content = document.querySelector("foreignObject div.content");
        const temperature = document.querySelector("data.temperature");
        const graphWidth = isMobileDevice
            ? graph.offsetWidth - temperature.offsetWidth
            : graph.offsetWidth;
        if (
            tooltipPosition === "left" &&
            tooltipClickedX - content.offsetWidth < 0
        ) {
            setTooltipPosition("right");
        }
        if (
            tooltipPosition === "right" &&
            tooltipClickedX + tooltipArrowWidth + content.offsetWidth >
                graphWidth
        ) {
            setTooltipPosition("left");
        }
        tooltipRef.current.removeEventListener(
            "touchmove",
            onTooltipMouseMove.current,
        );
        setLastPoint(checkPointRef.current);
    }
    const onTooltipMouseMove = useRef((event) => {
        if (!lastPointRef.current) return;
        const svg = document.querySelector(".data-graph svg#data");
        const movementPoint = calculateSvgToDom(
            event.touches[0].clientX,
            event.touches[0].clientY,
            svg,
        );
        //copy movementPoint for not creating new dataPoint
        const currentPoint = movementPoint;
        const diff = lastPointRef.current.x - startPointRef.current.x;
        currentPoint.x = movementPoint.x + diff;
        if (tooltipClickedSensorRef.current == "Tsens") {
            currentPoint.y =
                temperatureRef.current.Tsens.data[parseInt(movementPoint.x)];
        } else {
            currentPoint.y =
                temperatureRef.current.earTag.data[parseInt(movementPoint.x)];
        }
        const svgPoint = calculateDomToSvg(currentPoint, svg);
        if (currentPoint.y) {
            setTooltipClickedX(svgPoint.x);
            setTooltipClickedY(svgPoint.y);
            const sensorDataPoints = getPointsFromData(
                currentPoint,
                temperatureRef.current,
                svg,
            );
            if (sensorDataPoints.earTag)
                setEartagPoint(sensorDataPoints.earTag);
            if (sensorDataPoints.Tsens) setTsensPoint(sensorDataPoints.Tsens);
        }
        setCheckPoint(currentPoint);
    });
    if (Object.entries(temperature).length === 0 || lifecycle === "pending") {
        return <div className={`data-graph lifecycle ${lifecycle}`} />;
    }
    return (
        <div
            className={`data-graph lifecycle ${lifecycle}`}
            onTouchStart={
                isFullscreen
                    ? (event) =>
                          onPointClicked(event, {
                              earTag: earTagTemperature,
                              Tsens: TsensTemperature,
                          })
                    : (event) =>
                          onMouseMove(event, {
                              earTag: earTagTemperature,
                              Tsens: TsensTemperature,
                          })
            }>
            <svg
                id="data"
                viewBox={`0, ${graphCoordinateY.min}, 720, ${graphCoordinateY.length}`}
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none">
                <g
                    id="graph"
                    transform={`scale(-1) translate(-720,${graphCoordinateY.translateY})`}>
                    <path
                        className="thermal-gradient"
                        d={thermalGradientPath(
                            TsensTemperature,
                            graphCoordinateY.thermalPathOffset,
                        )}
                    />
                    <path
                        className="thermal-gradient"
                        d={thermalGradientPath(
                            earTagTemperature,
                            graphCoordinateY.thermalPathOffset,
                        )}
                    />
                    <path
                        className="data-line"
                        d={temperaturePath(TsensTemperature)}
                    />
                    <path
                        className="data-line"
                        d={temperaturePath(earTagTemperature)}
                    />
                    {!useAutomation ? (
                        <>
                            <path
                                className="data-line activity"
                                d={activityPath(TsensTemperature)}
                            />
                            <path
                                className="data-line environment"
                                d={environmentTempPath(TsensTemperature)}
                            />
                            <path
                                className="data-line environment"
                                d={environmentTempPath(earTagTemperature)}
                            />
                        </>
                    ) : null}
                </g>
                {thermalGradient()}
            </svg>
            {lifecycle === DONE && !useAutomation ? (
                <EventBadges
                    cowEvents={cowEvents}
                    TsensTemperature={TsensTemperature}
                    earTagTemperature={earTagTemperature}
                />
            ) : null}
            {cowPreviewVisibleMobile ? null : (
                <TemperatureLabel
                    temperature={activeTemperature.data.find((t) => t)}
                    lastTimestamp={activeTemperature.timestamp.find((t) => t)}
                    language={language}
                />
            )}
            <svg id="tooltip" xmlns="http://www.w3.org/2000/svg">
                {TsensPoint.value ? (
                    <g className={`Tsens ${TsensTooltipHoverVisible}`}>
                        <text
                            className="hover-text"
                            x={TsensPoint.x - hoverTextLength}
                            y={TsensPoint.y - 10}>
                            {TsensPoint.value + " °C"}
                        </text>
                        <circle
                            className="circle"
                            cx={TsensPoint.x}
                            cy={TsensPoint.y}
                            r="4"
                        />
                    </g>
                ) : null}
                {earTagPoint.value ? (
                    <g className={`earTag ${earTagTooltipHoverVisible}`}>
                        <text
                            className="hover-text"
                            x={earTagPoint.x - hoverTextLength}
                            y={earTagPoint.y - 10}>
                            {earTagPoint.value + " °C"}
                        </text>
                        <circle
                            className="circle"
                            cx={earTagPoint.x}
                            cy={earTagPoint.y}
                            r="4"
                        />
                    </g>
                ) : null}
                {tooltipClickedVisible ? (
                    <Tooltip
                        refHandler={tooltipRef}
                        coordinateX={parseInt(tooltipClickedX)}
                        coordinateY={parseInt(tooltipClickedY)}
                        width={160}
                        height={
                            document.querySelector("div.data-graph")
                                .clientHeight
                        }
                        visible={tooltipClickedVisible}
                        onTooltipStart={onTooltipStart}
                        onTooltipEnd={onTooltipEnd}
                        tooltipPosition={tooltipPosition}>
                        {TsensPoint.value ? (
                            <React.Fragment>
                                <div className="sensor Tsens">
                                    <span>{TsensPoint.value} °C</span>
                                </div>
                                {TsensPoint.environmentTemp ? (
                                    <div className="sensor environment">
                                        <span>
                                            {TsensPoint.environmentTemp} °C
                                        </span>
                                    </div>
                                ) : null}
                                {TsensPoint.activityValue ? (
                                    <div className="sensor activity">
                                        <span>
                                            {(
                                                TsensPoint.activityValue / 1000
                                            ).toFixed(2) + " m/s²"}
                                        </span>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ) : null}
                        {earTagPoint.value ? (
                            <React.Fragment>
                                <div className="sensor earTag">
                                    <span>{earTagPoint.value} °C</span>
                                </div>
                                {earTagPoint.environmentTemp ? (
                                    <div className="sensor environment">
                                        <span>
                                            {TsensPoint.environmentTemp} °C
                                        </span>
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ) : null}
                        <Time
                            time={
                                TsensPoint.value
                                    ? TsensPoint.timestamp
                                    : earTagPoint.timestamp
                            }
                            locale={localization(language)}
                            options={{
                                weekday: "short",
                                hour: "2-digit",
                                minute: "numeric",
                                second: "2-digit",
                            }}
                        />
                    </Tooltip>
                ) : null}
            </svg>
            <TimeLabel offset={4} language={language} endTime={endTime} />
            <TimeLabel offset={3} language={language} endTime={endTime} />
            <TimeLabel offset={2} language={language} endTime={endTime} />
            <TimeLabel offset={1} language={language} endTime={endTime} />
            <TimeLabel offset={0} language={language} endTime={endTime} />
        </div>
    );
}

export default connectCow(DataGraph);

function thermalGradientPath(dataPoints, thermalPathOffset) {
    if (!dataPoints.data || dataPoints.data.length === 0) return null;
    //* Discrete
    // return (
    //     "M" +
    //     dataPoints.data
    //         .map((temperature, index) => `${index} ${temperature || 0}`)
    //         .join("L") +
    //     `h1V${thermalPathOffset}H0Z`
    // );

    //* Continuous
    return (
        `M${dataPoints.data.findIndex((t) => t)} ${dataPoints.data.find(
            (t) => t,
        )}` +
        dataPoints.data
            .map((data, index) =>
                data ? `L${index} ${data.toFixed(1)}` : null,
            )
            .join(" ") +
        `h1V${thermalPathOffset}H${dataPoints.data.findIndex((t) => t)}Z`
    );
}

function thermalGradient() {
    return (
        <defs>
            <linearGradient
                id="thermal-gradient"
                x1="0"
                y1="45"
                x2="0"
                y2="30"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFE458" />
                <stop offset="0.4" stopColor="#C40000" />
                <stop offset="0.7" stopColor="#4D007C" />
                <stop offset="1" stopColor="#00154C" />
            </linearGradient>
        </defs>
    );
}

function TimeLabel({ offset, language, endTime }) {
    const nearestHour = Math.round(endTime / 3600000) * 3600000;
    const time = new Date(nearestHour - offset * 43200000).getTime();
    return (
        <Time
            time={time}
            locale={localization(language)}
            options={{ weekday: "short", hour: "2-digit", minute: "numeric" }}
        />
    );
}

export function TemperatureLabel({ temperature, lastTimestamp, language }) {
    if (!temperature) return null;
    const rangeClass =
        temperature > 40 ? "high" : temperature > 35 ? "mid" : "low";
    return (
        <data className={`temperature ${rangeClass}`} value={temperature}>
            <span className="temperature-sign">
                {temperature ? temperature.toFixed(1) : "00.0"}
            </span>
            <Time
                time={lastTimestamp}
                locale={localization(language)}
                options={{
                    weekday: "short",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                }}
            />
        </data>
    );
}

function getTooltipPoint(event, temperature) {
    const svg = document.querySelector(".data-graph svg#data");
    const dataPoint = calculateSvgToDom(
        event.touches[0].clientX,
        event.touches[0].clientY,
        svg,
    );
    return getPointsFromData(dataPoint, temperature, svg);
}

function calculateSvgToDom(x, y, svgElement) {
    const g = svgElement.querySelector("#graph");
    const point = svgElement.createSVGPoint();
    point.x = x;
    point.y = y;
    return point.matrixTransform(g.getScreenCTM().inverse());
}
function localization(lang) {
    switch (lang) {
        default:
        case "en":
            return "en-GB";
        case "de":
            return "de-DE";
        case "tr":
            return "tr-TR";
        case "it":
            return "it-IT";
        case "da":
            return "da-DK";
    }
}
function calculateDomToSvg(point, svgElement) {
    const g = svgElement.querySelector("#graph");
    const newPoint = point.matrixTransform(g.getScreenCTM());
    const tooltipSvg = document.querySelector(".data-graph svg#tooltip");
    return newPoint.matrixTransform(tooltipSvg.getScreenCTM().inverse());
}

function getPointsFromData(point, data, svgElement) {
    return Object.keys(data).reduce((acc, sensorType) => {
        const newSensorPoint = point;
        const environmentTemp = data[sensorType].environment
            ? data[sensorType].environment[parseInt(point.x)]
            : null;
        const activityValue = data[sensorType].activity
            ? data[sensorType].activity[parseInt(point.x)]
            : null;
        const y = data[sensorType].data[parseInt(point.x)];
        newSensorPoint.y = y ? y : null;
        if (!newSensorPoint.y) return acc;
        const tooltipPoint = calculateDomToSvg(newSensorPoint, svgElement);
        return {
            ...acc,
            [sensorType]: {
                x: tooltipPoint.x ? tooltipPoint.x : null,
                y: tooltipPoint.y ? tooltipPoint.y : null,
                environmentTemp: environmentTemp
                    ? environmentTemp.toFixed(1)
                    : null,
                activityValue: activityValue ? activityValue.toFixed(1) : null,
                value: newSensorPoint.y.toFixed(1)
                    ? newSensorPoint.y.toFixed(1)
                    : 0,
                timestamp:
                    data[sensorType].timestamp[newSensorPoint.x.toFixed()],
            },
        };
    }, {});
}

import { connect } from "react-redux";

import { objectSearchEngine } from "../components/common";
import { COW } from "../constants";

function mapStateToProps({
    cows,
    cowListFilter,
    tags,
    sessions,
    sensors,
    oldItems,
}) {
    let cowsLocal = cows;
    if (oldItems.cowIds.length > 0) {
        cowsLocal = Object.values(cows).reduce((acc, c) => {
            if (oldItems.cowIds.includes(c.id)) return acc;
            return { ...acc, [c.id]: c };
        }, {});
    }
    return {
        cows: sortCows(
            searchFullText(
                filterCowList(
                    cowListFilter.queries,
                    cowsLocal,
                    tags,
                    sessions,
                    sensors,
                ),
                cowsLocal,
                cowListFilter.search,
            ),
            cowListFilter.sort,
            cowListFilter.sortOrder,
            tags.cow,
        ),
        cowTags: tags.cow,
    };
}

export default connect(mapStateToProps);

export function filterCowList(queries, cows, tags, sessions, sensors) {
    if (queries.length === 0) return Object.keys(cows);
    let filteredCows = Object.keys(cows);
    const currentTime = new Date();
    queries.map((query) => {
        filteredCows = filteredCows.filter((id) => {
            const sensorTypeTags = getCowSensors(
                id,
                sessions,
                sensors,
                tags.session,
            ).map((type) => {
                return { value: type.toLowerCase(), params: {} };
            });
            return filterByTag(
                query,
                [...tags.cow[id], ...sensorTypeTags],
                cows[id],
                currentTime,
            );
        });
    });
    return filteredCows;
}

function getCowSensors(cowId, sessions, sensors, sessionTags) {
    return Object.keys(sessions)
        .filter(
            (key) =>
                sessions[key].cow == cowId &&
                sessionTags[key] &&
                sessionTags[key].find(
                    (tag) => tag.value == "active" || tag.value == "pending",
                ),
        )
        .reduce(
            (acc, sessionId) => [
                ...acc,
                sensors[sessions[sessionId].sensor].type,
            ],
            [],
        );
}

function filterByTag(operand, resourceTags, cow, currentTime) {
    const tagParam = operand.find((tag) => tag.type == "tagParam");
    if (tagParam) {
        const tagFilter = operand.find((tag) => tag != tagParam);
        const isTagExist = resourceTags
            .map((tag) => tag.value)
            .includes(tagFilter.tag);
        if (!isTagExist) return false;
        return filterByTagParam(tagParam, cow, currentTime);
    }
    return resourceTags.map((tag) => tag.value).includes(operand[0].tag);
}

function filterByTagParam(param, cow, currentTime) {
    const oneDay = 86400000;
    const oneYear = 31536000000;
    switch (param.kind) {
        case "inRange":
            if (param.parent == "Pregnant") {
                if (!cow.expectedDelivery) return false;
                const dayCount = parseInt(
                    (cow.expectedDelivery - currentTime.getTime()) / oneDay,
                );
                return (
                    dayCount >= parseInt(param.props.start) &&
                    dayCount <= parseInt(param.props.end)
                );
            } else if (param.parent == "Birthdate") {
                const yearCount = parseFloat(
                    (currentTime.getTime() - cow.birthdate) / oneYear,
                );
                return (
                    yearCount >= parseFloat(param.props.start) &&
                    yearCount <= parseFloat(param.props.end)
                );
            } else if (param.parent == "Lactation") {
                const lactationCount = parseInt(cow.lactationCount);
                return (
                    lactationCount >= parseInt(param.props.start) &&
                    lactationCount <= parseInt(param.props.end)
                );
            }
            break;
        case "higher":
            if (param.parent == "Pregnant") {
                if (!cow.expectedDelivery) return false;
                const dayCount = parseInt(
                    (cow.expectedDelivery - currentTime.getTime()) / oneDay,
                );
                return dayCount > parseInt(param.props.value);
            } else if (param.parent == "Birthdate") {
                const yearCount = parseFloat(
                    (currentTime.getTime() - cow.birthdate) / oneYear,
                );
                return yearCount > parseFloat(param.props.value);
            } else if (param.parent == "Lactation") {
                const lactationCount = parseInt(cow.lactationCount);
                return lactationCount >= parseInt(param.props.value);
            }
            break;
        case "smaller":
            if (param.parent == "Pregnant") {
                if (!cow.expectedDelivery) return false;
                const dayCount = parseInt(
                    (cow.expectedDelivery - currentTime.getTime()) / oneDay,
                );
                return dayCount < parseInt(param.props.value);
            } else if (param.parent == "Birthdate") {
                const yearCount = parseFloat(
                    (currentTime.getTime() - cow.birthdate) / oneYear,
                );
                return yearCount < parseFloat(param.props.value);
            } else if (param.parent == "Lactation") {
                const lactationCount = parseInt(cow.lactationCount);
                return lactationCount <= parseInt(param.props.value);
            }
            break;
    }
    return false;
}

export function sortCows(cows, sort, sortOrder, cowTags) {
    const sortByNumber =
        sortOrder === "ascending" ? (a, b) => a - b : (a, b) => b - a;
    const collator = new Intl.Collator(undefined, { numeric: true });
    const sortByString =
        sortOrder === "ascending"
            ? collator.compare
            : (a, b) => collator.compare(b, a);
    const sortByPregnancy =
        sortOrder === "ascending"
            ? (a, b) => comparePregnancyAsc(a, b, cowTags)
            : (a, b) => comparePregnancyDesc(a, b, cowTags);
    if (!cows[0]) return [...new Set(cows)];
    //detect sortable data type
    let sortByType;
    if (sort === COW.PREGNANT) {
        sortByType = sortByPregnancy;
        return [...new Set(cows)].sort((a, b) => sortByType(a, b, cowTags));
    } else {
        sortByType =
            typeof cows[0][sort] === "string" ? sortByString : sortByNumber;
        return [...new Set(cows)].sort((a, b) => sortByType(a[sort], b[sort]));
    }
}

function searchFullText(ids, cows, text) {
    if (!text) return ids.map((id) => cows[id]);
    const cowsToSearch = ids.map((id) => cows[id]);
    const search = objectSearchEngine(cowsToSearch, [
        "name",
        "farmNumber",
        "identityNumber",
    ]);
    return search(text);
}

function comparePregnancyAsc(a, b, cowTags) {
    const deliveryA = cowTags[a.id].find((t) => t.value === COW.PREGNANT).params
        .expectedDelivery;
    const deliveryB = cowTags[b.id].find((t) => t.value === COW.PREGNANT).params
        .expectedDelivery;
    return deliveryA - deliveryB;
}

function comparePregnancyDesc(a, b, cowTags) {
    const deliveryA = cowTags[a.id].find((t) => t.value === COW.PREGNANT).params
        .expectedDelivery;
    const deliveryB = cowTags[b.id].find((t) => t.value === COW.PREGNANT).params
        .expectedDelivery;
    return deliveryB - deliveryA;
}

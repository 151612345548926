import {
    take,
    put,
    spawn,
    select,
    call
} from "redux-saga/effects";
import axios from "axios";

import {
    CHANGED_NOTIFICATIONS_CONFIG,
    START_NOTIFICATIONS_REQUEST,
    COMPLETE_NOTIFICATIONS_REQUEST,
    FAIL_NOTIFICATIONS_REQUEST,
    CHANGED_EMAIL_CONFIG,
    START_EMAIL_REQUEST,
    COMPLETE_EMAIL_REQUEST,
    FAIL_EMAIL_REQUEST,
    CHANGED_VACATION_MODE_CONFIG,
    START_VACATION_MODE_REQUEST,
    COMPLETE_VACATION_MODE_REQUEST,
    FAIL_VACATION_MODE_REQUEST,
    CHANGED_CALL_CONFIG,
    START_CALL_REQUEST,
    COMPLETE_CALL_REQUEST,
    FAIL_CALL_REQUEST
} from "../actions/actionTypes";

const callEffect = call;
const configTypes = {
    calvingDueIn24Hours: "DUE_NOTICE_TYPES",
    imminentCalving: "CALVING_NOTICE_TYPES",
    calvingOverdue: "OVERDUE_NOTICE_TYPES",
    feverHypothermia: "FEVER_NOTICE_TYPES"
};


export function* vacationModeRequest() {
    yield take(CHANGED_VACATION_MODE_CONFIG);
    yield put({ type: START_VACATION_MODE_REQUEST });
    try {
        const vacationMode = yield select(state => state.notificationSettings.vacationMode);
        const config = yield select(({ configs }) => configs.find(c => c.name == "VACATION_MODE"));
        yield callEffect(axios.put, "/users/config", [{ ...config, value: !vacationMode ? "on": "off" }] );
        yield put({ type: COMPLETE_VACATION_MODE_REQUEST, vacationMode: !vacationMode });
    } catch (error) {
        yield put({ type: FAIL_VACATION_MODE_REQUEST, error });
    } finally {
        yield spawn(vacationModeRequest);
    }
}

export function* notificationsRequest() {
    const { noticeEventType, call, notification, email } = yield take(CHANGED_NOTIFICATIONS_CONFIG);
    yield put({ type: START_NOTIFICATIONS_REQUEST, noticeEventType, noticeType: "notification" });
    try {
        const notificationOptions = { call, notification, email };
        const value = Object.keys(notificationOptions).filter(key => notificationOptions[key]).join(",");
        const config = yield select(({ configs }) => configs.find(c => c.name == configTypes[noticeEventType]));
        yield callEffect(axios.put, "/users/config", [{ ...config, value }] );
        yield put({ type: COMPLETE_NOTIFICATIONS_REQUEST, noticeEventType, noticeType: "notification" });
    } catch (error) {
        yield put({ type: FAIL_NOTIFICATIONS_REQUEST, error });
    } finally {
        yield spawn(notificationsRequest);
    }
}

export function* emailRequest() {
    const { noticeEventType, call, notification, email } = yield take(CHANGED_EMAIL_CONFIG);
    yield put({ type: START_EMAIL_REQUEST, noticeEventType, noticeType: "email" });
    try {
        const notificationOptions = { call, notification, email };
        const value = Object.keys(notificationOptions).filter(key => notificationOptions[key]).join(",");
        const config = yield select(({ configs }) => configs.find(c => c.name == configTypes[noticeEventType]));
        yield callEffect(axios.put, "/users/config", [{ ...config, value }] );
        yield put({ type: COMPLETE_EMAIL_REQUEST, noticeEventType, noticeType: "email" });
    } catch (error) {
        yield put({ type: FAIL_EMAIL_REQUEST, error });
    } finally {
        yield spawn(emailRequest);
    }
}

export function* callRequest() {
    const { noticeEventType, call, notification, email } = yield take(CHANGED_CALL_CONFIG);

    yield put({ type: START_CALL_REQUEST, noticeEventType, noticeType: "call" });
    try {
        const notificationOptions = { call, notification, email };
        const value = Object.keys(notificationOptions).filter(key => notificationOptions[key]).join(",");
        const config = yield select(({ configs }) => configs.find(c => c.name == configTypes[noticeEventType]));
        yield callEffect(axios.put, "/users/config", [{ ...config, value }] );
        yield put({ type: COMPLETE_CALL_REQUEST, noticeEventType, noticeType: "call" });

    } catch (error) {
        yield put({ type: FAIL_CALL_REQUEST, error });
    } finally {
        yield spawn(callRequest);
    }
}

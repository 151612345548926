import { take, put, call, spawn, race, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import axios from "axios";

import {
    TURN_SWITCH,
    START_TURN_SWITCH,
    COMPLETE_TURN_SWITCH,
    FAIL_TURN_SWITCH,
    RESET_TURN_SWITCH,
    TOGGLE_PREVIEW_AUTOMATION,
    START_AUTOMATION_DATA_REQUEST,
    COMPLETE_AUTOMATION_DATA_REQUEST,
    FAIL_AUTOMATION_DATA_REQUEST,
    CACHED_AUTOMATION_DATA,
} from "../actions/actionTypes";

export function* automationData() {
    yield race([take(TOGGLE_PREVIEW_AUTOMATION), call(delay, 60000)]);
    const [automation, data] = yield select(({ preview, automation }) => [
        preview && preview.kind === "automation" && preview.id,
        preview && automation[preview.id] && automation[preview.id].data,
    ]);
    if (automation) {
        yield spawn(automationDataRequest, automation, data);
    }
    if (data) {
        yield spawn(cachedAutomationData, automation, data);
    }
    yield spawn(automationData);
}

/**
 * Returns cached automation data
 * @param {String} automation ID
 * @param {Object} data Previous data
 * @yields CACHED_AUTOMATION_DATA
 */
function* cachedAutomationData(automation, data) {
    yield put({
        type: CACHED_AUTOMATION_DATA,
        id: automation,
        data,
    });
}

/**
 * Concatenates previous automation data with new data
 * @param {Object} cache Cache data
 * @param {Object} response Requested data
 * @returns {Object} Returns merged data
 */
function mergeData(cache, response) {
    const prev = cache;
    const current = response.data;
    return {
        ...current,
        since: prev.since,
        min: current.min < prev.min ? current.min : prev.min,
        max: current.max > prev.max ? current.max : prev.max,
        temperature: [...current.temperature, ...prev.temperature],
        timestamp: [...current.timestamp, ...prev.timestamp],
    };
}

function* automationDataRequest(automation, cache) {
    yield put({ type: START_AUTOMATION_DATA_REQUEST, id: automation });

    const config = {};
    try {
        const automationData = yield select(
            (state) => state.automation[automation],
        );
        config.params = {
            since:
                cache && cache.data.until > 0
                    ? cache.data.until
                    : automationData.data.since > 0
                    ? automation.data.since
                    : 0,
            until:
                automationData.data.until > 0 ? automationData.data.until : 0,
        };
    } catch (e) {}

    try {
        const response = yield call(
            axios.get,
            `/data/automation/${automation}`,
            config,
        );
        yield put({
            type: COMPLETE_AUTOMATION_DATA_REQUEST,
            id: automation,
            data: cache ? mergeData(cache, response) : response.data,
        });
    } catch (error) {
        yield put({
            type: FAIL_AUTOMATION_DATA_REQUEST,
            id: automation,
            error,
        });
    }
}

export function* turnSwitch() {
    const { switchOnOff, id, deviceId } = yield take(TURN_SWITCH);
    yield put({ type: START_TURN_SWITCH, id });
    try {
        const { data } = yield call(axios.post, "/automation", {
            switchStatus: switchOnOff,
            pairingId: id,
            deviceId,
        });
        yield put({ type: COMPLETE_TURN_SWITCH, switch: switchOnOff, id });
    } catch (error) {
        yield put({ type: FAIL_TURN_SWITCH, error, id });
    } finally {
        yield put({ type: RESET_TURN_SWITCH, id });
        yield call(delay, 2000);
        yield put({ type: COMPLETE_TURN_SWITCH, switch: "off", id });
        yield spawn(turnSwitch);
    }
}

const DUE_RELATED = ["due", "long-due", "imminent-due"];

const PREGNANCY_TRACKING_RELATED = [
    ...DUE_RELATED,
    "pregnancy-tracking",
    "calving",
];

const PREGNANT_RELATED = [
    ...PREGNANCY_TRACKING_RELATED,
    "pregnant",
    "pregnancy-tracking-recomended",
    "overdue",
    "dry",
];

const ESTROUS_TRACKING_RELATED = ["estrous-tracking", "heat", "sensor-fallen"];

const INSEMINATED_RELATED = ["inseminated", "pregnancy-check"];

const ESTROUS_RELATED = [
    ...INSEMINATED_RELATED,
    ...ESTROUS_TRACKING_RELATED,
    "estrous",
    "estrous-tracking-recomended",
];

const TEMPERATURE_TRACKING_RELATED = [
    "temperature-tracking",
    "hypothermia",
    "fever",
    "increased-fever",
    "sensor-fallen",
];

const TRACK_RELATED = [
    ...PREGNANCY_TRACKING_RELATED,
    ...ESTROUS_TRACKING_RELATED,
    ...TEMPERATURE_TRACKING_RELATED,
    "tracked",
    "stale",
    "pending",
];

export default function tagCow(currentTags, eventType) {
    const trackedCondition = [
        "fever-detected",
        "fever-increase-detected",
        "fever-over",
        "hypothermia-detected",
        "calving-due",
        "calving-detected",
    ];

    if (!currentTags.includes("untracked") && eventType === "cow-died") {
        throw new Error(
            `Can't process the ${eventType} event without 'untracked'`,
        );
    }

    if (
        !currentTags.includes("untracked") &&
        eventType === "first-session-created"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'untracked'`,
        );
    }

    if (
        !currentTags.includes("tracked") &&
        trackedCondition.includes(eventType)
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'tracked'`,
        );
    }

    if (
        (currentTags.includes("calving") || currentTags.includes("delivery")) &&
        eventType === "pregnancy-detected"
    ) {
        throw new Error(
            `Can't process ${eventType} event since 'delivery' exist`,
        );
    }

    if (
        currentTags.includes("hypothermia") &&
        (eventType === "fever-detected" ||
            eventType === "fever-increase-detected" ||
            eventType === "fever-over")
    ) {
        throw new Error(
            `Can't process the ${eventType} event since 'hypothermia' exist`,
        );
    }

    if (
        currentTags.includes("fever") &&
        (eventType === "hypothermia-detected" ||
            eventType === "hypothermia-over")
    ) {
        throw new Error(
            `Can't process the ${eventType} event since 'fever' exist`,
        );
    }

    if (
        !currentTags.includes("pregnant") &&
        (eventType === "calving-due" ||
            eventType === "calving-overdue" ||
            eventType === "calving-detected" ||
            eventType === "calving-completed")
    ) {
        throw new Error(
            `Can't process the ${eventType} event since 'pregnant' not exist`,
        );
    }

    if (currentTags.includes("due") && eventType === "calving-completed") {
        throw new Error(
            `Can't process the ${eventType} event since 'due' is exist`,
        );
    }

    if (!currentTags.includes("pending") && eventType === "tracking-started") {
        throw new Error(
            `Can't process the ${eventType} event without 'pending'`,
        );
    }

    if (!currentTags.includes("due") && eventType === "calving-due-false") {
        throw new Error(`Can't process the ${eventType} event without 'due'`);
    }

    if (
        !currentTags.includes("calving") &&
        eventType === "calving-completed-false"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'calving'`,
        );
    }

    if (
        (currentTags.includes("pending") || currentTags.includes("tracked")) &&
        eventType === "cow-died"
    ) {
        throw new Error(
            `Can't process the ${eventType} event with 'pending' or 'tracked'`,
        );
    }

    if (!currentTags.includes("pregnant") && eventType === "calving-overdue") {
        throw new Error(
            `Can't process the ${eventType} event without 'pregnant'`,
        );
    }

    if (
        !currentTags.includes("pregnant") &&
        eventType === "pregnancy-tracking-started"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'pregnant'`,
        );
    }

    if (
        !currentTags.includes("estrous") &&
        eventType === "estrous-tracking-started"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'estrous'`,
        );
    }

    if (
        !currentTags.includes("tracked") &&
        eventType === "stale-data-detected"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'tracked'`,
        );
    }

    if (!currentTags.includes("stale") && eventType === "fresh-data-detected") {
        throw new Error(`Can't process the ${eventType} event without 'stale'`);
    }

    if (
        !(
            currentTags.includes("estrous-tracking") ||
            currentTags.includes("temperature-tracking")
        ) &&
        eventType === "sensor-fall-detected"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'estrous-tracking' or 'temperature-tracking'`,
        );
    }

    if (
        !currentTags.includes("due") &&
        (eventType === "long-due-detected" ||
            eventType === "imminent-due-detected")
    ) {
        throw new Error(`Can't process the ${eventType} event without 'due'`);
    }

    if (
        currentTags.includes("pregnant") &&
        eventType === "estrous-cycle-detected"
    ) {
        throw new Error(`Can't process the ${eventType} event with 'pregnant'`);
    }

    if (
        currentTags.includes("pregnant") &&
        eventType === "potential-estrous-detected"
    ) {
        throw new Error(`Can't process the ${eventType} event with 'pregnant'`);
    }

    if (
        !currentTags.includes("estrous-tracking") &&
        eventType === "heat-detected"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'estrous-tracking'`,
        );
    }

    if (!currentTags.includes("heat") && eventType === "heat-over") {
        throw new Error(`Can't process the ${eventType} event without 'heat'`);
    }

    if (
        !currentTags.includes("estrous") &&
        eventType === "insemination-reported"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'estrous'`,
        );
    }

    if (
        !currentTags.includes("pregnant") &&
        eventType === "potential-calving-detected"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'pregnant'`,
        );
    }

    if (
        !currentTags.includes("inseminated") &&
        eventType === "non-pregnancy-reported"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'inseminated'`,
        );
    }

    if (
        !currentTags.includes("sensor-fallen") &&
        eventType === "sensor-fall-resolved"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'sensor-fallen'`,
        );
    }

    if (!currentTags.includes("pregnant") && eventType === "dry-cow-reported") {
        throw new Error(
            `Can't process the ${eventType} event without 'pregnant'`,
        );
    }

    if (!currentTags.includes("dry") && eventType === "dry-cow-canceled") {
        throw new Error(`Can't process the ${eventType} event without 'dry'`);
    }

    switch (eventType) {
        case "fever-detected":
            return updateCowTags(currentTags, ["fever"]);
        case "fever-increase-detected":
            return updateCowTags(currentTags, ["fever"]);
        case "fever-over":
            return updateCowTags(currentTags, [], ["fever"]);
        case "hypothermia-detected":
            return updateCowTags(currentTags, ["hypothermia"], ["fever"]);
        case "hypothermia-over":
            return updateCowTags(currentTags, [], ["hypothermia"]);
        case "calving-due":
            return updateCowTags(currentTags, ["due"]);
        case "calving-due-false":
            return updateCowTags(currentTags, [], DUE_RELATED);
        case "calving-detected":
            return updateCowTags(currentTags, ["calving", "due", "overdue"]);
        case "calving-completed":
            return updateCowTags(
                currentTags,
                ["post-partum"],
                PREGNANT_RELATED,
            );
        case "calving-completed-false":
            return updateCowTags(currentTags, [], ["calving"]);
        case "first-session-created":
            return updateCowTags(currentTags, ["pending"], ["untracked"]);
        case "last-session-completed":
            return updateCowTags(currentTags, ["untracked"], TRACK_RELATED);
        case "pregnancy-detected":
            return updateCowTags(
                currentTags,
                ["pregnant"],
                [...ESTROUS_RELATED, "post-partum"],
            );
        case "pregnancy-over":
            return updateCowTags(
                currentTags,
                ["post-partum"],
                PREGNANT_RELATED,
            );
        case "tracking-started":
            return updateCowTags(currentTags, ["tracked"], ["pending"]);
        case "new-cow-acquired":
            return updateCowTags([], ["untracked"]);
        case "cow-died":
            return updateCowTags([], ["dead"]);
        case "cow-edited":
            return currentTags;
        case "calving-overdue":
            return updateCowTags(currentTags, ["overdue"]);
        case "pregnancy-tracking-started":
            return updateCowTags(
                currentTags,
                ["pregnancy-tracking"],
                ["pregnancy-tracking-recomended"],
            );
        case "estrous-tracking-started":
            return updateCowTags(
                currentTags,
                ["estrous-tracking"],
                ["estrous-tracking-recomended"],
            );
        case "temperature-tracking-started":
            return updateCowTags(currentTags, ["temperature-tracking"]);
        case "stale-data-detected":
            return updateCowTags(currentTags, ["stale"]);
        case "fresh-data-detected":
            return updateCowTags(currentTags, [], ["stale"]);
        case "sensor-fall-detected":
            return updateCowTags(currentTags, ["sensor-fallen"]);
        case "long-due-detected":
            return updateCowTags(currentTags, ["long-due"]);
        case "imminent-due-detected":
            return updateCowTags(currentTags, ["imminent-due"]);
        case "estrous-cycle-detected":
            return updateCowTags(currentTags, ["estrous"], ["post-partum"]);
        case "potential-estrous-detected":
            return updateCowTags(currentTags, ["estrous-tracking-recomended"]);
        case "heat-detected":
            return updateCowTags(currentTags, ["heat"]);
        case "heat-over":
            return updateCowTags(currentTags, [], ["heat"]);
        case "insemination-reported":
            return updateCowTags(currentTags, ["inseminated"]);
        case "potential-calving-detected":
            return updateCowTags(currentTags, [
                "pregnancy-tracking-recomended",
            ]);
        case "non-pregnancy-reported":
            return updateCowTags(
                currentTags,
                [],
                ["inseminated", "pregnancy-check"],
            );
        case "pregnancy-tracking-completed":
            return updateCowTags(currentTags, [], PREGNANCY_TRACKING_RELATED);
        case "estrous-tracking-completed":
            return updateCowTags(currentTags, [], ESTROUS_TRACKING_RELATED);
        case "temperature-tracking-completed":
            return updateCowTags(currentTags, [], TEMPERATURE_TRACKING_RELATED);
        case "sensor-fall-resolved":
            return updateCowTags(currentTags, [], ["sensor-fallen"]);
        case "dry-cow-reported":
            return updateCowTags(currentTags, ["dry"], []);
        case "dry-cow-canceled":
            return updateCowTags(currentTags, [], ["dry"]);
        case "improper-event":
        default:
            return null;
    }
}

function updateCowTags(currentTags, newTags, removableTags = []) {
    const substractedTags = currentTags.filter(
        (tag) => !removableTags.includes(tag),
    );
    const tags = [...new Set([...substractedTags, ...newTags])];
    return tags;
}

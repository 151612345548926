import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import cn from "classnames";

import SearchBox from "../SearchBox";
import { objectSearchEngine } from "../common";
import "./PairingBox.css";
import CowName from "../CowName";
import { last4 } from "../SensorLabel";
import { SENSOR } from "../../constants";
import i18n from "../../i18n";
import MobileInput from "../MobileInput";
import Input from "../Input";
import Button from "../Button";
import { processRequiredInfo } from "../../store/actions";
import { useMobile } from "../isMobile";
import { Cow } from "../Events/Event/titles";

export function PairingBox({
    cowSearch,
    sensorSearch,
    cow,
    sensor,
    onCow,
    onSensor,
    onTracking,
    trackingTypes,
    animate,
    estrousEnabled,
    requiredInfo,
    focused,
    processRequiredInfo,
    pregnancyEnabled,
}) {
    const { isMobileView } = useMobile();
    const [isInfoNeed, setIsInfoNeed] = useState(cow && requiredInfo);
    const ready = cow && sensor && trackingTypes.length > 0;
    const pairingBoxRef = useRef(null);
    const [requiredDate, setRequiredDate] = useState(new Date());
    useEffect(() => {
        if (animate) pairingBoxRef.current.classList.add("start");
    }, [animate]);
    return (
        <>
            <div
                ref={pairingBoxRef}
                className={cn("pairing-box", { ready, animate })}>
                <SearchBox
                    className="cow"
                    type={"cow"}
                    engine={cowSearch}
                    render={cowResult}
                    selectedRender={cowResult}
                    renderSearch={cowResultSearch}
                    onSelect={onCow}
                    selected={cow}
                    focused={focused}
                />
                <SearchBox
                    className="sensor"
                    type={"sensor"}
                    engine={sensorSearch}
                    render={sensorResult}
                    selectedRender={sensorSelectedResult}
                    renderSearch={sensorResultSearch}
                    onSelect={onSensor}
                    selected={sensor}
                    focused={focused}
                />

                <div className="tracking">
                    <div
                        className={cn("pregnancy", {
                            checked: trackingTypes.includes("pregnancy"),
                            disabled:
                                !pregnancyEnabled ||
                                (sensor && sensor.type === "earTag"),
                        })}
                        onClick={() => (cow ? onTracking("pregnancy") : null)}
                    />
                    {estrousEnabled ? (
                        <div
                            className={cn("estrous", {
                                checked: trackingTypes.includes("estrous"),
                            })}
                            onClick={() => (cow ? onTracking("estrous") : null)}
                        />
                    ) : null}
                    <div
                        className={cn("health", {
                            checked: trackingTypes.includes("health"),
                        })}
                        onClick={() => (cow ? onTracking("health") : null)}
                    />
                </div>
            </div>
            {isInfoNeed ? (
                <div className="required-info">
                    {isMobileView ? (
                        <MobileInput
                            classNames="date"
                            label={
                                requiredInfo == "lastBreedingDate"
                                    ? i18n.t("lastBreedingDate")
                                    : i18n.t("lastCalvingDate")
                            }
                            isOpen={false}
                            onSelect={(value) => setRequiredDate(value)}
                            value={requiredDate}
                        />
                    ) : (
                        <Input
                            type="date"
                            classNames="date"
                            label={
                                requiredInfo === "lastBreedingDate"
                                    ? i18n.t("lastBreedingDate")
                                    : i18n.t("lastCalvingDate")
                            }
                            onChanged={(value) => setRequiredDate(value)}
                        />
                    )}
                    <Button
                        type="action"
                        text={i18n.t("send")}
                        onClicked={() =>
                            processRequiredInfo(cow, requiredInfo, requiredDate)
                        }
                    />
                    {requiredInfo === "lastBreedingDate" ? (
                        <h5>
                            <Trans i18nKey="lastBreedingDateUpdateExp">
                                This will confirm that <Cow id={cow.id} /> is in
                                pregnancy. We need last breeding date to give
                                you better predictions, if you dont know the
                                date, you can click
                                <span
                                    onClick={() => setIsInfoNeed(false)}
                                    className="clickable">
                                    here
                                </span>
                            </Trans>
                        </h5>
                    ) : (
                        <h5>
                            <Trans i18nKey="lastCalvingDateUpdateExp">
                                This will confirm that <Cow id={cow.id} /> is in
                                her regular menstrual cycle. We need last
                                calving date to give you better predictions, if
                                you dont know the date, you can click
                                <span
                                    onClick={() => setIsInfoNeed(false)}
                                    className="clickable">
                                    here
                                </span>
                            </Trans>
                        </h5>
                    )}
                </div>
            ) : null}
        </>
    );
}

function mapStateToProps(
    { cows, sensors, tags, oldItems, priviliges },
    { selectedSensors },
) {
    const availableSensors = Object.values(sensors)
        .reduce((acc, s) => {
            if (oldItems.sensorIds.includes(s.id)) return acc;
            if (oldItems.sensorIds.includes(s.id)) return acc;
            if (s.type !== "Tsens" && s.type !== "earTag") return acc;
            const tagValues = tags.sensor[s.id].map((t) => t.value);
            if (tagValues.includes(SENSOR.EXCLUDED)) return acc;
            if (
                selectedSensors.find((ss) => {
                    return ss.id === s.id;
                })
            ) {
                return [
                    ...acc,
                    {
                        ...s,
                        tags: tags.sensor[s.id],
                        tagValues,
                        temporarySelected: true,
                    },
                ];
            }
            return [...acc, { ...s, tags: tags.sensor[s.id], tagValues }];
        }, [])
        .sort(function (a, b) {
            if (b.tagValues.includes(SENSOR.IDLE)) {
                return 1;
            } else {
                return -1;
            }
        });
    const availableCows = Object.values(cows).filter((c) => {
        if (oldItems.cowIds.includes(c.id)) return false;
        return true;
    });
    return {
        cowSearch: objectSearchEngine(availableCows, [
            "name",
            "farmNumber",
            "identityNumber",
        ]),
        sensorSearch: objectSearchEngine(availableSensors, [
            "type",
            "deviceId",
        ]),
        estrousEnabled: priviliges.ESTROUS_DETECTION_ENABLED.value,
        pregnancyEnabled: priviliges.PREGNANCY_DETECTION_ENABLED.value,
    };
}

const mapDispatchToProps = { processRequiredInfo };

export default connect(mapStateToProps, mapDispatchToProps)(PairingBox);

function cowResult({ name, farmNumber }) {
    return (
        <React.Fragment>
            <strong>{farmNumber}</strong>&nbsp;<CowName>{name}</CowName>
        </React.Fragment>
    );
}

function cowResultSearch({ farmNumber, name }) {
    try {
        const [, first, rest, generation] = name.match(
            /([a-zÄ±])([a-zÄ±]+)\s*(\d*)/iu,
        );
        return `${farmNumber} ${first}${rest.toLocaleLowerCase()}${
            generation ? " " : ""
        }${generation}`;
    } catch (e) {
        return "";
    }
}

function sensorResult({ type, deviceId, tagValues, temporarySelected }) {
    let info = null;

    if (temporarySelected) {
        info = i18n.t("selected");
    } else {
        if (tagValues.includes(SENSOR.IDLE)) {
            info = i18n.t("available");
        }
        if (
            tagValues.includes(SENSOR.ACTIVE) ||
            tagValues.includes(SENSOR.ASSIGNED)
        ) {
            info = i18n.t("inUse");
        }
        if (tagValues.includes(SENSOR.NETWORK_PROBLEM)) {
            info = i18n.t("problem");
        }
    }
    return (
        <span
            className={cn("label", tagValues, {
                ["temporary-selected"]: temporarySelected,
            })}>
            <span className="sensor">
                {type}&nbsp;
                <strong>{deviceId ? last4(deviceId) : null}</strong>
            </span>
            <span className="info"> {info}</span>
        </span>
    );
}

function sensorSelectedResult({ type, deviceId }) {
    return (
        <React.Fragment>
            {type}&nbsp;
            <strong>{deviceId ? last4(deviceId) : null}</strong>
        </React.Fragment>
    );
}

function sensorResultSearch({ type, deviceId }) {
    return `${type} ${last4(deviceId)}`;
}

import * as EVENTS from "../utilities";

import { sensors, cows } from "./initialItems";

import { handleEvent } from "../routes/events";

const eventSeeder = (server) => {
    const db = server.schema.db;

    const mockEvents = JSON.parse(window.localStorage.getItem("mockEvents"));

    if (mockEvents) {
        sensors.forEach((sensor, index) => {
            EVENTS.newSensorAcquiredEvent(
                db,
                sensor,
                "1",
                mockEvents[0].timestamp - (sensors.length - index) * 1000,
            );
        });
        cows.forEach((cow, index) => {
            EVENTS.newCowAcquiredEvent(
                db,
                cow,
                "1",
                mockEvents[0].timestamp - (cows.length - index) * 1000,
            );
        });

        const initTime = new Date().getTime();
        mockEvents
            .sort((a, b) => a.timestamp - b.timestamp)
            .filter((e) => e.timestamp < initTime)
            .map((e) => handleEvent(db, e));
    } else {
        sensors.forEach((sensor, index) => {
            EVENTS.newSensorAcquiredEvent(
                db,
                sensor,
                "1",
                new Date().getTime() - (96000 - index * 1000),
            );
        });
        cows.forEach((cow, index) => {
            EVENTS.newCowAcquiredEvent(
                db,
                cow,
                "1",
                new Date().getTime() - (96000 - index * 1000),
            );
        });
        EVENTS.pairingListCreatedEvent(
            db,
            [
                { cow: "1", sensor: "1" },
                { cow: "2", sensor: "2" },
                { cow: "3", sensor: "3" },
            ],
            "1",
            true,
            new Date().getTime() - 720000,
        );
        EVENTS.eventDetector(db, true);
    }

    EVENTS.eventDetector(db);
    // EVENTS.sensorActivatedEvent(db, "1");
    // EVENTS.stableDataDetectedEvent(db, "1", "1");
    // EVENTS.pairingListCreatedEvent(db, [{ cow: "2", sensor: "2" }], "1");
    // EVENTS.sensorActivatedEvent(db, "2");
    // EVENTS.stableDataDetectedEvent(db, "2", "2");
    // EVENTS.calvingDetectedEvent(db, "1");
};

export default function seeds(server) {
    server.loadFixtures();
    eventSeeder(server);
}

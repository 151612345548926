import React, { useRef, useContext } from "react";
import "./HealthReport.css";
import cn from "classnames";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { useOnClickOutside } from "../outsideClick";
import { setHealthRange } from "../../store/actions";
import MetricRange from "../MetricRange";
import { RouterContext } from "../../containers/Contexts";

import ReportLine from "./ReportLine";

function HealthReport({ cows, ui, setHealthRange, estrousEnabled, farm }) {
    const { t } = useTranslation();
    const navigate = useContext(RouterContext);
    const healthReportRef = useRef();
    useOnClickOutside(healthReportRef, navigate.back);
    return (
        <main className="health-report" ref={healthReportRef}>
            <span className="close-button" onClick={navigate.back} />
            <h1 className="header">{t("feverReport")}</h1>
            <div className="body">
                <MetricRange
                    useForHealthReport={true}
                    trackStarted={farm.contractDate}
                    lastMonthCount={3}
                    onChanged={setHealthRange}
                    loading={ui === "pending"}
                />
                {ui === "available" ? (
                    cows.length === 0 ? (
                        <p className="error">
                            {t("thereAreNoFeverAlarmDuringThisPeriod")}
                        </p>
                    ) : (
                        <ul className={cn("report-list")}>
                            <li className="columns">
                                <span>{t("cow")}</span>
                                <span>{t("prePartum")}</span>
                                <span>{t("postPartum")}</span>
                                <span>
                                    {estrousEnabled
                                        ? t("estrous")
                                        : t("health")}
                                </span>
                            </li>
                            {cows.map((c) => (
                                <ReportLine
                                    key={c.id}
                                    cow={c}
                                    estrousEnabled={estrousEnabled}
                                />
                            ))}
                        </ul>
                    )
                ) : ui === "fail" ? (
                    <p>{t("error")}</p>
                ) : (
                    <p>{t("loading")}</p>
                )}
            </div>
        </main>
    );
}
function mapStateToProps({
    cows,
    healthReport,
    priviliges,
    farms: { current },
}) {
    function compare(a, b) {
        return healthReport.sortOrder === "ascending" ? a - b : b - a;
    }
    const sortedCows = Object.keys(healthReport.cows).sort((a, b) => {
        const aTotal = Object.values(healthReport.cows[a]).reduce(
            (acc, i) => acc + i,
            0,
        );
        const bTotal = Object.values(healthReport.cows[b]).reduce(
            (acc, i) => acc + i,
            0,
        );
        return compare(aTotal, bTotal);
    });
    const result = sortedCows.reduce((acc, k) => {
        return [
            ...acc,
            {
                id: k,
                farmNumber: cows[k].farmNumber,
                name: cows[k].name,
                healthReport: healthReport.cows[k],
            },
        ];
    }, []);
    return {
        ui: healthReport.ui,
        cows: result,
        estrousEnabled: priviliges.ESTROUS_DETECTION_ENABLED.value,
        farm: current,
    };
}

const mapDispatchToProps = { setHealthRange };

export default connect(mapStateToProps, mapDispatchToProps)(HealthReport);

import React, {
    useReducer,
    useState,
    useMemo,
    useRef,
    useContext,
} from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import CowList from "../CowList";
import CowFilter from "../CowFilter";
import Button from "../Button";
import { submitCowsFromCowList, addNewCow } from "../../store/actions";
import { RESET_COWS_FROM_COWLIST } from "../../store/actions/actionTypes";
import Toast from "../Toast";
import { RouterContext } from "../../containers/Contexts";

import "./CowListView.css";

export function CowListView({
    onButtonClicked,
    cows,
    resetBeforeSelections,
    status,
    dialogType,
    disabled,
    addNewCow,
    cowListEditEnabled,
    pairingEnabled,
}) {
    const { t } = useTranslation();
    const navigate = useContext(RouterContext);
    const cowListRef = useRef();
    const [toast, setToast] = useState(false);

    useMemo(() => {
        resetBeforeSelections();
    }, [resetBeforeSelections]);
    const [selectedCows, dispatch] = useReducer(reducer, []);
    const [renderSelections, updateRenderSelections] = useState(false);

    function openPairingList() {
        onButtonClicked(selectedCows);
        navigate.forward("/pairing");
    }
    function onCowClicked(cowId, event) {
        const sliderPosition = event.target.closest("div[data-slider-overlay]");
        if (
            sliderPosition.dataset.sliderOverlay === "right" ||
            sliderPosition.dataset.sliderOverlay === "left"
        ) {
            return null;
        }
        const contextOptionsMenu = event.target.closest("nav.cow-options-menu");
        if (contextOptionsMenu) {
            return null;
        }
        if (selectedCows.includes(cowId)) {
            dispatch({ type: "remove-cow", cowId });
        } else {
            dispatch({ type: "add-cow", cowId });
        }
        if (selectedCows.length === 0) updateRenderSelections(false);
    }
    function addCow() {
        if (cowListEditEnabled === false) {
            setToast(true);
            return;
        }
        addNewCow();
        navigate.forward("/cows/add");
    }
    const RenderButton =
        selectedCows.length > 0 ? (
            <Button
                text={t("sendTheSelectedCowsToThePairingList", {
                    count: selectedCows.length,
                })}
                type="action"
                behaviour="instant"
                onClicked={openPairingList}
                privilige={pairingEnabled}
            />
        ) : null;
    return (
        <main
            className={cn("cow-list", { "dialog-is-open": dialogType })}
            ref={cowListRef}>
            <div className="mobile-header">
                <h6 className="farm-name">{t("cowList")}</h6>
                <span className="close-button" onClick={navigate.back} />
            </div>
            <i className="add-cow" onClick={addCow} />
            {toast ? <Toast onFinish={() => setToast(false)} /> : null}
            <CowFilter />
            <CowList
                status={status}
                onCowClicked={onCowClicked}
                selectedCows={selectedCows.map((cowId) => cows[cowId])}
                renderSelections={renderSelections}
                cowListRef={cowListRef.current}
            />
            <div className="footer">
                {selectedCows.length > 0 ? (
                    <h6 className="info">
                        <a
                            onClick={() =>
                                updateRenderSelections(!renderSelections)
                            }>
                            {renderSelections
                                ? t("returnToList")
                                : t("seeAllSelectedCows")}
                        </a>
                    </h6>
                ) : null}
                {RenderButton}
            </div>
        </main>
    );
}

function mapStateToProps({
    cows,
    cowListFilter,
    pairing: { cowsFromCowList },
    cowModal,
    yourProfile,
    dialog,
    navigation: { routes },
    priviliges,
}) {
    return {
        ui: cowListFilter.ui,
        beforeSelectedCows: cowsFromCowList,
        cows,
        status: cowModal.status,
        userId: yourProfile.ownerUserId,
        dialogType: dialog.type,
        disabled:
            routes.includes("/cows/add") ||
            routes.includes("/cows/edit") ||
            dialog.type,
        cowListEditEnabled: priviliges.COW_LIST_EDIT_ENABLED.value,
        pairingEnabled: priviliges.PAIRING_ENABLED.value,
    };
}

const mapDispatchToProps = {
    onButtonClicked: (selectedCows) => submitCowsFromCowList(selectedCows),
    resetBeforeSelections: () => {
        return { type: RESET_COWS_FROM_COWLIST };
    },
    addNewCow,
};

export default connect(mapStateToProps, mapDispatchToProps)(CowListView);

function reducer(initialState, { type, cowId }) {
    switch (type) {
        case "add-cow":
            return [...initialState, cowId];
        case "remove-cow":
            return initialState.filter((id) => id !== cowId);
        default:
            return initialState;
    }
}

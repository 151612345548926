import { EVENTS } from "../../constants";
export const standaloneEvents = {
    cow: [
        EVENTS.FEVER_INCREASE_DETECTED,
        EVENTS.HYPOTHERMIA_DETECTED,
        EVENTS.HYPOTHERMIA_OVER,
        EVENTS.CALVING_OVERDUE,
        EVENTS.CALVING_CONFIRM_WARNINGS,
        EVENTS.HEAT_DETECTED,
        EVENTS.HEAT_OVER,
        EVENTS.SENSOR_FALL_DETECTED,
        EVENTS.LONG_DUE_DETECTED,
        EVENTS.IMMINENT_DUE_DETECTED,
        EVENTS.DRY_COW_REPORTED,
        EVENTS.PREGNANCY_TRACKING_STARTED,
        EVENTS.DRY_COW_CANCELED,
    ],
    sensor: [
        EVENTS.LOW_BATTERY_DETECTED,
        EVENTS.SENSOR_RESET,
        EVENTS.SENSOR_TRANSFER_OVER,
        EVENTS.SENSOR_TRANSFER_STARTED,
    ],
    basestation: [
        EVENTS.ONLINE_BASESTATION_DETECTED,
        EVENTS.OFFLINE_BASESTATION_DETECTED,
    ],
    repeater: [
        EVENTS.ONLINE_REPEATER_DETECTED,
        EVENTS.OFFLINE_REPEATER_DETECTED,
    ],
};

import React, { useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Metrics from "../Metrics";
import MetricRange from "../MetricRange";
import { setMetricRange } from "../../store/actions";
import { useOnClickOutside } from "../outsideClick";
import { RouterContext } from "../../containers/Contexts";

import "./FarmMetrics.css";

function FarmMetrics({ metrics, farm, setMetricRange }) {
    const navigate = useContext(RouterContext);
    const farmMetricsRef = useRef();
    useOnClickOutside(farmMetricsRef, navigate.back);
    const { t } = useTranslation();

    let calvingReport = (
        <>
            <div className="calf-gender">
                <Metrics {...metrics.calfGender} usePie />
            </div>
            <div className="birth-health">
                <h3>{t("HealthAtBirthExplanation")}</h3>
                <Metrics {...metrics.birthHealth} />
            </div>
            <div className="calving-experience">
                <h3>{t("calvingExperienceExplanation")}</h3>
                <Metrics {...metrics.calvingExperience} />
            </div>
            <div className="gestation-period">
                <h3>{t("gestationPeriodExplanation")}</h3>
                <Metrics {...metrics.gestationPeriod} />
            </div>
            <div className="detection-accuracy">
                <h3>
                    <span>{t("detectionAccuracyExplanation1")}</span>
                    <span>{t("detectionAccuracyExplanation2")}</span>
                    <span>{t("detectionAccuracyExplanation3")}</span>
                    <span>{t("detectionAccuracyExplanation4")}</span>
                </h3>
                <Metrics {...metrics.detectionAccuracy} />
            </div>
            <div className="tracking-sessions">
                <h3>
                    <span>{t("trackingSessionsExplanation1")}</span>
                    <span>{t("trackingSessionsExplanation2")}</span>
                    <span>{t("trackingSessionsExplanation3")}</span>
                    <span>{t("trackingSessionsExplanation4")}</span>
                </h3>
                <Metrics {...metrics.trackingSessions} />
            </div>
        </>
    );
    if (metrics.reportError === "NoTimeslot") {
        calvingReport = (
            <p className="error">{t("thereAreNoTrackingDuringThisPeriod")}</p>
        );
    }
    const healthReport = (
        <>
            <div className="health-score">
                <h3>
                    <span>{t("healthScoreExplanation1")}</span>
                </h3>
                <Metrics {...metrics.healthScore} />
            </div>
            <div className="fever-results">
                <h3>
                    <span>{t("feverResultsExplanation1")}</span>
                </h3>
                <Metrics {...metrics.feverResults} />
            </div>
        </>
    );

    return (
        <main className="farm-metrics" ref={farmMetricsRef}>
            <h1 className="header">{t("farmMetrics")}</h1>
            <span className="close-button" onClick={navigate.back} />
            <div className="body">
                <MetricRange
                    trackStarted={farm.contractDate}
                    onChanged={setMetricRange}
                    loading={metrics.ui === "pending"}
                />
                {metrics.ui === "available" ? (
                    <>
                        {metrics.reportError === "NoTimeslot"
                            ? null
                            : healthReport}
                        {calvingReport}
                    </>
                ) : metrics.ui === "fail" ? (
                    <p>{t("error")}</p>
                ) : (
                    <p>{t("loading")}</p>
                )}
            </div>
        </main>
    );
}
function mapStateToProps({ metrics, farms: { current } }) {
    return { metrics, farm: current };
}

const mapDispatchToProps = { setMetricRange };

export default connect(mapStateToProps, mapDispatchToProps)(FarmMetrics);

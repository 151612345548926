import { EVENTS, COW, SESSION, SENSOR, READSTATE, REPEATER } from "./schema";
import * as LIFECYCLE from "./lifecycle";
import { COW_UI } from "./uiTags";
import TAG_PARAMS from "./tagParams";
const SUPPORTED_LANGUAGES = [
    "en",
    "de",
    "tr",
    "it",
    "da",
    "pt",
    "es",
    "bg",
    "ru",
    "fr",
];
export {
    EVENTS,
    SESSION,
    COW,
    SENSOR,
    LIFECYCLE,
    READSTATE,
    COW_UI,
    TAG_PARAMS,
    REPEATER,
    SUPPORTED_LANGUAGES,
};

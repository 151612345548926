import React, { useReducer, useEffect, useRef } from "react";

import { useMutationObserver } from "../hooks";
import "./Slider.css";

export function Slider({
    children,
    position = "rest",
    limitLeft,
    limitRight,
    dragLeftBorder,
    dragRightBorder,
    ...rest
}) {
    const [state, dispatch] = useReducer(dragReducer, {
        position,
        limitLeft,
        limitRight,
        dragLeftBorder,
        dragRightBorder,
    });

    useEffect(() => dispatch({ type: "set-position", position }), [position]);

    const { Overlay, Left, Right } = children.reduce(
        (out, child) => ({
            ...out,
            [child.key]: {
                ...child,
                props: { ...child.props, state, dispatch },
            },
        }),
        {},
    );
    const render = state.delta > 0 ? [Left, Overlay] : [Right, Overlay];
    return (
        <div data-slider-container {...rest}>
            {render}
        </div>
    );
}

export function Overlay({
    children,
    state,
    dispatch,
    onMoved,
    cow,
    autoClose,
    ...rest
}) {
    const overlayRef = useRef();
    useEffect(() => {
        if (autoClose) dispatch({ type: "click" });
    }, [autoClose, dispatch]);

    useMutationObserver(
        overlayRef.current,
        { attributes: true },
        handleMutations,
    );

    function handleMutations(mutations) {
        const element = mutations.find(
            (m) => m.attributeName === "data-slider-overlay",
        );
        if (!element) return;
        const position = overlayRef.current.getAttribute("data-slider-overlay");
        if (position === "left" || position === "right" || position === "rest")
            dispatch({ type: "external", position: position });
    }

    function slideHandler(action) {
        if (action.type === "move") onMoved(cow);
        dispatch(action);
    }
    const style =
        state.position === "drag-left" || state.position === "drag-right"
            ? { transform: `translateX(${state.delta}px)` }
            : {};
    return (
        <div
            ref={overlayRef}
            data-slider-overlay={state.position}
            style={style}
            onTouchStart={(event) =>
                slideHandler({ type: "start", touch: event.touches[0] })
            }
            onTouchMove={(event) =>
                slideHandler({
                    type: "move",
                    touch: event.touches[0],
                })
            }
            onTouchEnd={(event) => slideHandler({ type: "end" })}
            onClick={(event) => slideHandler({ type: "click" })}
            {...rest}>
            {children}
        </div>
    );
}

function dragReducer(state, action) {
    let delta = 0;
    switch (`${state.position}/${action.type}`) {
        case "rest/start":
            return {
                position: "drag-right",
                limitLeft: state.limitLeft,
                limitRight: state.limitRight,
                dragLeftBorder: state.dragLeftBorder,
                dragRightBorder: state.dragRightBorder,
                startX: action.touch.screenX,
                delta: 0,
                id: action.touch.identifier,
            };
        case "drag-right/move":
            delta =
                action.touch.screenX - state.startX < state.limitRight
                    ? state.limitRight
                    : action.touch.screenX - state.startX;
            return {
                ...state,
                delta,
                position: delta > 0 ? "drag-left" : "drag-right",
            };
        case "drag-left/move":
            delta =
                action.touch.screenX - state.startX > state.limitLeft
                    ? state.limitLeft
                    : action.touch.screenX - state.startX;

            return {
                ...state,
                delta,
                position: delta > 0 ? "drag-left" : "drag-right",
            };

        case "drag-left/end":
            return {
                ...state,
                position: state.delta > state.dragLeftBorder ? "left" : "rest",
            };

        case "drag-right/end":
            return {
                ...state,
                position:
                    state.delta < state.dragRightBorder ? "right" : "rest",
            };

        case "right/start":
            return {
                ...state,
                startX: action.touch.screenX,
                position: "right",
            };
        case "right/move":
            delta = action.touch.screenX - state.startX;
            return {
                ...state,
                position: delta > state.dragLeftBorder ? "rest" : "right",
            };
        case "left/start":
            return {
                ...state,
                startX: action.touch.screenX,
                position: "left",
            };
        case "left/move":
            delta = action.touch.screenX - state.startX;
            return {
                ...state,
                position: delta < state.dragRightBorder ? "rest" : "left",
            };
        case "left/click":
        case "right/click":
            return { ...state, position: "rest" };

        case "rest/set-position":
            return { ...state, position: action.position };
        case "left/external":
        case "right/external":
        case "rest/external":
            return { ...state, delta: action.position === "left" ? 1 : -1 };
        default:
            return state;
    }
}

export function Left({ children, state, dispatch, ...rest }) {
    return (
        <div
            data-slider-left
            onTouchStart={(event) =>
                dispatch({
                    type: "start",
                    touch: event.touches[0],
                })
            }
            onTouchMove={(event) =>
                dispatch({
                    type: "move",
                    touch: event.touches[0],
                })
            }
            {...rest}>
            {children}
        </div>
    );
}

export function Right({ children, state, dispatch, ...rest }) {
    return (
        <div
            data-slider-right
            onTouchStart={(event) =>
                dispatch({
                    type: "start",
                    touch: event.touches[0],
                })
            }
            onTouchMove={(event) =>
                dispatch({
                    type: "move",
                    touch: event.touches[0],
                })
            }
            {...rest}>
            {children}
        </div>
    );
}

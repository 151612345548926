export default function tagSession(currentTags, eventType) {
    if (!currentTags.includes("pending") && eventType === "session-canceled") {
        throw new Error(
            `Can't process the ${eventType} event without 'pending'`,
        );
    }

    if (
        !currentTags.includes("pending") &&
        !currentTags.includes("active") &&
        eventType === "stable-data-detected"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'pending' or 'active'`,
        );
    }

    if (!currentTags.includes("active") && eventType === "session-completed") {
        throw new Error(
            `Can't process the ${eventType} event without 'active'`,
        );
    }

    if (
        !currentTags.includes("active") &&
        !currentTags.includes("archived") &&
        eventType === "session-trashed"
    ) {
        throw new Error(
            `Can't process the ${eventType} event without 'active' or 'archived'`,
        );
    }

    if (!currentTags.includes("active") && eventType === "session-suspended") {
        throw new Error(
            `Can't process the ${eventType} event without 'active'`,
        );
    }

    if (
        currentTags.includes("pregnancy") &&
        eventType === "auto-estrous-tracking"
    ) {
        throw new Error(
            `Can't process the ${eventType} event with 'pregnancy'`,
        );
    }

    switch (eventType) {
        case "session-created":
            return updateSessionTags([], ["pending"]);
        case "stable-data-detected":
            return updateSessionTags(
                currentTags,
                ["active"],
                ["pending", "suspended"],
            );
        case "session-completed":
            return updateSessionTags(currentTags, ["archived"], ["active"]);
        case "session-canceled":
            return updateSessionTags(currentTags, [], ["pending"]);
        case "session-trashed":
            return updateSessionTags(
                currentTags,
                ["trashed"],
                ["active", "archived"],
            );
        case "estrous-tracking-started":
            return updateSessionTags(currentTags, ["estrous"], ["pregnancy"]);
        case "pregnancy-tracking-started":
            return updateSessionTags(currentTags, ["pregnancy"], ["estrous"]);
        case "temperature-tracking-started":
            return updateSessionTags(currentTags, ["health"]);
        case "session-suspended":
            return updateSessionTags(currentTags, ["suspended"]);
        case "auto-estrous-tracking":
            return updateSessionTags(currentTags, ["estrous"]);
        case "improper-event":
        default:
            return null;
    }
}

function updateSessionTags(currentTags, newTags, removableTags = []) {
    const substractedTags = currentTags.filter(
        (tag) => !removableTags.includes(tag),
    );
    const tags = [...new Set([...substractedTags, ...newTags])];
    return tags;
}

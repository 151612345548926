import {
    START_CHANGE_FARM_NAME_REQUEST,
    COMPLETE_CHANGE_FARM_NAME_REQUEST,
    FAIL_CHANGE_FARM_NAME_REQUEST,
    RESET_FARM_NAME
} from "../../actions/actionTypes";

export default function (state = "initial", action) {
    switch (action.type) {
    case START_CHANGE_FARM_NAME_REQUEST:
        return "pending";
    case COMPLETE_CHANGE_FARM_NAME_REQUEST:
        return "done";
    case RESET_FARM_NAME:
        return "initial";
    case FAIL_CHANGE_FARM_NAME_REQUEST:
        return "failed";
    default: return state;
    }
}
import { SET_COW_TYPES } from "../../store/actions/actionTypes";
import { toList } from "./utilities";

export default function( state = {}, action ) {
    switch ( action.type ){
    case SET_COW_TYPES:
        return { ...state, ...toList(action.data) };
    default: return state;
    }
}

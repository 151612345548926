import { INITIAL, PENDING, DONE, FAILED, NONE } from "../../constants/lifecycle";

export default function(start, complete, fail, none) {
    return function reducer( state = INITIAL, { type } ) {
        switch ( state + type ) {
        case INITIAL + start: return PENDING;
        case FAILED + start: return PENDING;
        case DONE + start: return PENDING;
        case PENDING + complete: return DONE;
        case PENDING + fail: return FAILED;
        case state + none: return NONE;
        default: return state;
        }
    };
}

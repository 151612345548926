import React, { useState, useRef, useContext } from "react";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";

import Button from "../Button";
import Input from "../Input";
import {
    setLanguageRequest,
    updateInformation,
    registerUser,
} from "../../store/actions";
import "./YourProfile.css";
import Time from "../Time";
import { USER } from "../../constants/schema";
import { RouterContext } from "../../containers/Contexts";

import { messages } from "./errorMessages";

export function YourProfile({
    language,
    setLanguageRequest,
    ownerUser,
    updateInformation,
    status,
    callContract,
    isRegistering,
    registerUser,
}) {
    const { t } = useTranslation();
    const navigate = useContext(RouterContext);
    const yourProfileRef = useRef();
    const num = /^[0-9+]+$/;
    const char = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    function onLangButtonClicked(language) {
        setLanguageRequest(language);
    }

    const [state, setState] = useState(
        ownerUser
            ? ownerUser
            : {
                  name: null,
                  surname: null,
                  email: null,
                  phoneNumber: null,
              },
    );
    const [validations, setValidations] = useState({
        name: null,
        surname: null,
        email: null,
        phoneNumber: null,
    });
    const [startWarning, setStartWarning] = useState(false);

    function onInputChanged(key, value) {
        setState({ ...state, [key]: value });
        const err = validateInputs(key, value);

        const el = document.getElementById(key);
        if (err && startWarning) {
            setValidations({ ...validations, [key]: err });
            el.classList.add("validate");
        } else {
            setValidations({ ...validations, [key]: null });
            el.classList.remove("validate");
        }
    }
    function validateInputs(key, value) {
        switch (key) {
            case "name":
                if (!value || value === "")
                    return { text: messages[1], required: true };
                return null;
            case "surname":
                if (!value || value === "")
                    return { text: messages[2], required: true };
                return null;
            case "email":
                if (value && !char.test(value))
                    return { text: messages[3], required: true };
                return null;
            case "phoneNumber":
                if (value && value.length > 15)
                    return { text: messages[4], required: true };
                if (value && !num.test(value))
                    return { text: messages[5], required: true };
                return null;
            default:
                return null;
        }
    }

    function updateMyInformation() {
        setStartWarning(true);
        const validations = Object.keys(state).reduce((acc, key) => {
            const err = validateInputs(key, state[key]);
            return { ...acc, [key]: err };
        }, {});
        if (
            Object.values(validations)
                .filter((v) => v && v.required)
                .every((v) => !v)
        ) {
            if (isRegistering) {
                registerUser(
                    state.id,
                    state.name,
                    state.surname,
                    state.email,
                    state.phoneNumber,
                );
            } else {
                updateInformation(state);
            }
        } else {
            Object.keys(validations).map((v) => {
                const el = document.getElementById(v);
                if (validations[v]) {
                    el.classList.add("validate");
                }
                return v;
            });
            setValidations(validations);
        }
    }
    function closeYourProfile() {
        navigate.back();
    }
    function keyPress() {
        const numberInput = document.querySelector(
            "div.input-group.phone-number input",
        );
        numberInput.onkeydown = function plusRule1(e) {
            e.stopPropagation();
            if (
                numberInput.value.length === 1 &&
                numberInput.value.indexOf("+") === 0 &&
                (e.keyCode === 46 || e.keyCode === 8)
            ) {
                e.preventDefault();
            }
        };
        numberInput.onkeyup = function plusRule2(e) {
            e.stopPropagation();
            if (
                numberInput.value.indexOf("+") !== 0 ||
                numberInput.value === "" ||
                !numberInput.value
            ) {
                numberInput.value = "+";
            }
        };
    }
    const isValNotExist = Object.values(validations).every((v) => !v);

    return (
        <main
            className={cn("your-profile", {
                new: !ownerUser.name || !ownerUser.surname,
            })}
            ref={yourProfileRef}>
            <h1 className="header">{t("yourProfile")}</h1>
            <span className="close-button" onClick={closeYourProfile} />
            <div className="language">
                <Button
                    onClicked={() => onLangButtonClicked("en")}
                    className={cn("languageEn", {
                        selected: language === "en",
                    })}
                    text={"EN"}
                    type="action"
                    behaviour="instant"
                />
                <Button
                    onClicked={() => onLangButtonClicked("de")}
                    className={cn("languageDe", {
                        selected: language === "de",
                    })}
                    text={"DE"}
                    type="action"
                    behaviour="instant"
                />
                <Button
                    onClicked={() => onLangButtonClicked("tr")}
                    className={cn("languageTr", {
                        selected: language === "tr",
                    })}
                    text={"TR"}
                    type="action"
                    behaviour="instant"
                />
                <Button
                    onClicked={() => onLangButtonClicked("it")}
                    className={cn("languageIt", {
                        selected: language === "it",
                    })}
                    text={"IT"}
                    type="action"
                    behaviour="instant"
                />
                <Button
                    onClicked={() => onLangButtonClicked("da")}
                    className={cn("languageDa", {
                        selected: language === "da",
                    })}
                    text={"DA"}
                    type="action"
                    behaviour="instant"
                />
                <Button
                    onClicked={() => onLangButtonClicked("pt")}
                    className={cn("languagePt", {
                        selected: language === "pt",
                    })}
                    text={"PT"}
                    type="action"
                    behaviour="instant"
                />
                <Button
                    onClicked={() => onLangButtonClicked("es")}
                    className={cn("languageEs", {
                        selected: language === "es",
                    })}
                    text={"ES"}
                    type="action"
                    behaviour="instant"
                />
                <Button
                    onClicked={() => onLangButtonClicked("bg")}
                    className={cn("languageBg", {
                        selected: language === "bg",
                    })}
                    text={"BG"}
                    type="action"
                    behaviour="instant"
                />
                <Button
                    onClicked={() => onLangButtonClicked("ru")}
                    className={cn("languageRu", {
                        selected: language === "ru",
                    })}
                    text={"RU"}
                    type="action"
                    behaviour="instant"
                />
                <Button
                    onClicked={() => onLangButtonClicked("fr")}
                    className={cn("languageFr", {
                        selected: language === "fr",
                    })}
                    text={"FR"}
                    type="action"
                    behaviour="instant"
                />
                <span className="lang">{t("language")}</span>
            </div>
            <div className="name-surname">
                <Input
                    id="name"
                    classNames="first-name"
                    label={t("firstName")}
                    onChanged={(value) => onInputChanged("name", value)}
                    validationMessage={validations.name}
                    value={state.name}
                />
                <Input
                    id="surname"
                    classNames="last-name"
                    label={t("lastName")}
                    onChanged={(value) => onInputChanged("surname", value)}
                    validationMessage={validations.surname}
                    value={state.surname}
                />
            </div>
            <Input
                id="email"
                classNames="email-address"
                label={t("eMailAddress")}
                inputType="email"
                onChanged={(value) => onInputChanged("email", value)}
                validationMessage={validations.email}
                value={state.email}
            />
            <div className="number-input">
                <Input
                    id="phoneNumber"
                    classNames="phone-number"
                    label={t("phoneNumber")}
                    onChanged={(value) => onInputChanged("phoneNumber", value)}
                    onKeyPress={keyPress}
                    validationMessage={validations.phoneNumber}
                    defaultValue="+"
                    value={state.phoneNumber}
                />
            </div>
            {callContract && callContract.value === "on" ? (
                <Trans i18nKey="callContract">
                    <label className="call-contract">
                        You will be called from &nbsp;
                        <strong>+49 1573 5994856</strong> between &nbsp;
                        <Time
                            time={callContract.startTime * 1000}
                            options={{
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                            }}
                        />
                        &nbsp; and &nbsp;
                        <Time
                            time={callContract.endTime * 1000}
                            options={{
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                            }}
                        />
                    </label>
                </Trans>
            ) : null}
            {!ownerUser.name || !ownerUser.surname ? (
                <label className="warning-new-user">
                    {t("warningNewUser")}
                </label>
            ) : null}
            <Button
                className={cn("update")}
                text={
                    status === "initial"
                        ? !startWarning || isValNotExist
                            ? t("update")
                            : t("pleaseFixErrors")
                        : status === "updated"
                        ? t("updated")
                        : status === "updating"
                        ? t("updating")
                        : t("pleaseFixErrors")
                }
                onClicked={updateMyInformation}
                disabled={
                    (startWarning && !isValNotExist) || status === "updated"
                }
                type="action"
                behaviour="instant"
            />
        </main>
    );
}
function mapStateToProps({ yourProfile, language, users, configs, tags }) {
    const callContract =
        configs.length > 0
            ? configs.find((c) => c.name === "CALL_CONTRACT")
            : null;
    return {
        ui: yourProfile.ui,
        ownerUser: users[yourProfile.ownerUserId],
        language: language.lang,
        status: yourProfile.status,
        callContract,
        isRegistering:
            tags.user[yourProfile.ownerUserId] &&
            tags.user[yourProfile.ownerUserId]
                .map((t) => t.value)
                .includes(USER.INVITED),
    };
}

const mapDispatchToProps = {
    setLanguageRequest,
    updateInformation,
    registerUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(YourProfile);

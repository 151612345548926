import React, { useState } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from "react-day-picker/moment";

import i18n from "../../i18n";

import "moment/locale/de";
import "moment/locale/tr";
import "moment/locale/da";
import "moment/locale/it";

import "react-day-picker/lib/style.css";
import "./DatePicker.css";

const DatePicker = ({ onDateChanged, disableFuture, value }) => {
    const currentYear = new Date().getFullYear();
    const fromMonth = new Date(1970, 0);
    const toMonth = new Date(currentYear + 10, 11);

    const [selectedMonth, setSelectedMonth] = useState(new Date());

    function YearMonthForm({ date, localeUtils, locale, onChange }) {
        const months = localeUtils.getMonths(locale);
        const years = [];
        for (
            let i = fromMonth.getFullYear();
            i <= toMonth.getFullYear();
            i += 1
        ) {
            years.push(i);
        }
        const handleChange = (e) => {
            const { year, month } = e.target.form;
            onChange(new Date(year.value, month.value));
        };
        return (
            <form className="DayPicker-Caption">
                <select
                    name="month"
                    onChange={handleChange}
                    value={date.getMonth()}>
                    {months.map((month, i) => (
                        <option key={month} value={i}>
                            {month}
                        </option>
                    ))}
                </select>
                <select
                    name="year"
                    onChange={handleChange}
                    value={date.getFullYear()}>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </form>
        );
    }

    function handleYearMonthChange(month) {
        setSelectedMonth(month);
    }

    function handleDayChange(date) {
        onDateChanged(date);
    }

    const dayPickerProps = {
        localeUtils: MomentLocaleUtils,
        locale: i18n.languages[0],
        month: selectedMonth,
        fromMonth: fromMonth,
        toMonth: toMonth,
        captionElement: ({ date }) => {
            return (
                <YearMonthForm
                    date={date}
                    localeUtils={MomentLocaleUtils}
                    locale={i18n.languages[0]}
                    onChange={handleYearMonthChange}
                />
            );
        },
        disabledDays: (day) => day > new Date(),
    };
    return (
        <DayPickerInput
            // keepFocus={false}
            formatDate={formatDate}
            parseDate={parseDate}
            format="LL"
            placeholder=""
            dayPickerProps={dayPickerProps}
            onDayChange={handleDayChange}
            value={value ? formatDate(value, "LL", i18n.languages[0]) : null}
        />
    );
};

export default DatePicker;

import React, { useState } from "react";
import pt from "prop-types";

import Toast from "../Toast";

import "./Button.css";

const Button = ({
    className,
    onClicked,
    text,
    type,
    behaviour,
    disabled,
    privilige,
}) => {
    const [toast, setToast] = useState(false);
    function onClickLocal(event) {
        if (privilige === false) {
            setToast(true);
            return;
        }
        onClicked(event);
    }
    return (
        <React.Fragment>
            <button
                className={`button ${type} ${className}`}
                onClick={onClickLocal}
                disabled={disabled}>
                {text}
            </button>
            {toast ? <Toast onFinish={() => setToast(false)} /> : null}
        </React.Fragment>
    );
};

Button.propTypes = {
    onClicked: pt.func,
    className: pt.string,
    disabled: pt.bool,
    text: pt.string,
    type: pt.oneOf(["common", "action"]),
    behaviour: pt.oneOf(["instant", "delayed"]),
};
Button.defaultProps = {
    type: "common",
    behaviour: "instant",
};
export default Button;

import React, { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import cn from "classnames";

import i18n from "../../i18n";

export default function PieMetric({ metrics, colors }) {
    const [selected, setSelected] = useState(null);
    const data = Object.keys(metrics)
        .sort((a, b) => metrics[b] - metrics[a])
        .reduce((acc, k, i) => {
            return [
                ...acc,
                {
                    key: i18n.t(k),
                    value: metrics[k],
                    color: colors[i],
                },
            ];
        }, []);
    const additionalProps = {
        radius: PieChart.defaultProps.radius - 14,
        segmentsStyle: { transition: "stroke .3s", cursor: "pointer" },
        segmentsShift: (index) => (index === selected ? 6 : 1),
        onClick: (_, index) => setSelected(index === selected ? null : index),
        labelPosition: 112,
    };
    return (
        <div className="pie">
            <div className="chart">
                <PieChart
                    {...additionalProps}
                    labelStyle={(index) => ({
                        fill: "#ffffff",
                        fontSize: ".6em",
                        color: "000",
                        fontFamily: "sans-serif",
                    })}
                    animate={true}
                    label={({ dataEntry }) => {
                        return Math.round(dataEntry.percentage) + "%";
                    }}
                    data={data}
                />
            </div>
            <div className="legend">
                <Legend data={data} selectedIndex={selected} colors={colors} />
            </div>
        </div>
    );
}

function Legend({ data, selectedIndex, colors }) {
    return (
        <>
            <ul>
                {data.map((d, i) => {
                    return (
                        <li
                            key={d.key}
                            className={cn(`C-${colors[i].substring(1)}`, {
                                lowlight:
                                    selectedIndex !== null &&
                                    selectedIndex !== i,
                            })}>
                            {`${d.key} ${d.value}`}
                        </li>
                    );
                })}
            </ul>
        </>
    );
}

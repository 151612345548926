import React from "react";
import cn from "classnames";
import "./Switch.css";

const Switch = ({ classNames, status, switchValue, onClicked, check }) => {
    return (
        <div className={cn("switch-group", classNames)}>
            <div className={cn("switch", classNames)}>
                <input
                    type="checkbox"
                    className={cn(
                        "switch-checkbox",
                        { classNames },
                        check,
                        status,
                    )}
                    defaultChecked={switchValue}
                    onClick={onClicked}
                />
                <span className="onoffswitch-slider">
                    <span className="onswitch-inner" />
                    <span className="offswitch-inner" />
                </span>
            </div>
        </div>
    );
};

export default Switch;

import React from "react";
import cn from "classnames";

import { automation as connect } from "../../containers";

import "./ClimateSens.css";

function ClimateSens({ placePairing, preview, onToggle }) {
    const previewIsActive =
        preview &&
        preview.kind === "automation" &&
        placePairing.id === preview.id;
    return (
        <article
            className={cn("climate-sens", { preview, active: previewIsActive })}
            onClick={() => onToggle(placePairing.id)}>
            <h5>{placePairing.placeName}</h5>
            <div className="image" />
        </article>
    );
}

export default connect(ClimateSens);

import {
    TURN_SWITCH,
    START_TURN_SWITCH,
    COMPLETE_TURN_SWITCH,
    FAIL_TURN_SWITCH,
    UNKNOWN_TURN_SWITCH,
    RESET_TURN_SWITCH,
    COMPLETE_APP_STARTUP,
    COMPLETE_AUTOMATION_DATA_REQUEST,
    FAIL_AUTOMATION_DATA_REQUEST,
    START_AUTOMATION_DATA_REQUEST,
    CACHED_AUTOMATION_DATA,
} from "../actions/actionTypes";
import { PENDING, DONE, FAILED } from "../../constants/lifecycle";
export default function (state = {}, action) {
    switch (action.type) {
        case TURN_SWITCH:
            return {
                ...state,
                [action.id]: { ...state[action.id], status: "turning" },
            };
        case START_TURN_SWITCH:
            return {
                ...state,
                [action.id]: { ...state[action.id], status: "pending" },
            };
        case COMPLETE_TURN_SWITCH:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    switchStatus: action.switch,
                    status: "done",
                },
            };
        case FAIL_TURN_SWITCH:
            return {
                ...state,
                [action.id]: { ...state[action.id], status: "fail" },
            };
        case UNKNOWN_TURN_SWITCH:
            return {
                ...state,
                [action.id]: { ...state[action.id], status: "unknown" },
            };
        case RESET_TURN_SWITCH:
            return {
                ...state,
                [action.id]: { ...state[action.id], status: "initial" },
            };
        case COMPLETE_APP_STARTUP:
            if (action.farm.places) {
                return action.farm.places.reduce((acc, p) => {
                    return {
                        ...acc,
                        [p.pairingId]: {
                            id: p.pairingId,
                            placeName: p.placeName,
                            deviceId: p.deviceId,
                            deviceTypesId: p.deviceTypesId,
                            switchStatus: "off",
                            status: "initial",
                        },
                    };
                }, {});
            } else {
                return state;
            }
        case START_AUTOMATION_DATA_REQUEST:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    lifecycle: PENDING,
                },
            };
        case CACHED_AUTOMATION_DATA:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    data: action.data,
                    lifecycle: DONE,
                },
            };
        case COMPLETE_AUTOMATION_DATA_REQUEST:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    data: action.data,
                    lifecycle: DONE,
                },
            };
        case FAIL_AUTOMATION_DATA_REQUEST:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    lifecycle: FAILED,
                },
            };
        default:
            return state;
    }
}

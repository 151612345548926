import { TOGGLE_PREVIEW } from "../../../store/actions/actionTypes";

import { advanceOnClick, stepNamespace, media, reCapture } from ".";

const step = stepNamespace("cow-preview");

export default function cowPreview() {
    return [
        {
            ...step("open"),
            ...advanceOnClick("article.cow"),
        },
        {
            ...step("explain"),
            attachTo: { element: "aside.cow-preview", on: "bottom" },
            scrollTo: { behavior: "smooth", block: "bottom" },
            revert(tour) {
                tour.options.dispatch({ type: TOGGLE_PREVIEW });
            },
            when: {
                "before-show"() {
                    reCapture(this);
                },
            },
        },
        {
            ...step("highlight-events"),
            attachTo: { element: "section.events" },
            showOn: media("(min-width: 52rem)"),
        },
        {
            ...step("slide-events"),
            attachTo: { element: "aside.cow-preview", on: "bottom" },
            showOn: media("(pointer: coarse) and (max-width: 35rem)"),
            when: {
                show() {
                    document.querySelector(
                        "aside.cow-preview [data-cow-slider-left]",
                    ).dataset.cowSliderLeft = "rest-to-right";
                    document.querySelector(
                        "aside.cow-preview [data-cow-slider-overlay]",
                    ).dataset.cowSliderOverlay = "rest-to-right";
                    document.querySelector(
                        "aside.cow-preview [data-cow-slider-right]",
                    ).dataset.cowSliderRight = "rest-to-right";
                },
            },
        },
        {
            ...step("slide-actions"),
            attachTo: { element: "aside.cow-preview", on: "bottom" },
            showOn: media("(pointer: coarse) and (max-width: 35rem)"),
            when: {
                show() {
                    document.querySelector(
                        "aside.cow-preview [data-cow-slider-left]",
                    ).dataset.cowSliderLeft = "rest-to-left";
                    document.querySelector(
                        "aside.cow-preview [data-cow-slider-overlay]",
                    ).dataset.cowSliderOverlay = "rest-to-left";
                    document.querySelector(
                        "aside.cow-preview [data-cow-slider-right]",
                    ).dataset.cowSliderRight = "rest-to-left";
                },
                hide() {
                    document.querySelector(
                        "aside.cow-preview [data-cow-slider-left]",
                    ).dataset.cowSliderLeft = "rest";
                    document.querySelector(
                        "aside.cow-preview [data-cow-slider-overlay]",
                    ).dataset.cowSliderOverlay = "rest";
                    document.querySelector(
                        "aside.cow-preview [data-cow-slider-right]",
                    ).dataset.cowSliderRight = "rest";
                },
            },
        },
        {
            ...step("context-actions"),
            attachTo: { element: "aside.cow-preview", on: "bottom" },
            showOn: media("(pointer: fine)"),
        },
        {
            ...step("popup"),
            attachTo: { element: "aside.cow-preview", on: "bottom" },
            showOn: media("(pointer: fine)"),
        },
        {
            ...step("pairing-end"),
            attachTo: {
                element: "aside.cow-preview .main-header .sessions",
                on: "bottom",
            },
        },
        {
            ...step("close"),
            ...advanceOnClick("article.cow", { on: "top" }),
        },
    ];
}

import { put, take, call, select } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { togglePreview, submitCowsFromCowList, setPotentialCalvingsList } from "../actions";

export function* initCommandChannel(){
    const channel = yield call(commandChannel);
    try {
        while (true) {
            // take(END) will cause the saga to terminate by jumping to the finally block
            const { farm, action } = yield take(channel);
            const currentFarmId = yield select(state => state.farms.current.id);
            if (currentFarmId != farm){
                //Send loding done to React Native
                yield window.ReactNativeWebView.postMessage(JSON.stringify({
                    type: "loading",
                    value: true
                }));
                const url = yield new URL(window.location.href);
                yield url.searchParams.set("farm", farm);
                yield window.localStorage.setItem("action", JSON.stringify(action));
                yield window.location.href = url.href;
            } else {
                yield put(action);
            }
        }
    } finally {
        console.log("channel terminated");
    }
}

function commandChannel(){
    window.notificationCommands = {};
    return eventChannel(emitter => {
        window.notificationCommands.openCowPreview = (notificationCommand) => {
            emitter({ action: togglePreview("cow", notificationCommand.cow), farm: notificationCommand.farm });
        };
        return () => { };
    });
}
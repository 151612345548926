import { take, put, call, spawn, select, race } from "redux-saga/effects";
import { delay } from "redux-saga";
import axios from "axios";

import {
    TOGGLE_PREVIEW,
    START_COW_DATA_REQUEST,
    COMPLETE_COW_DATA_REQUEST,
    FAIL_COW_DATA_REQUEST,
    OLD_COW_DATA_REQUEST,
    CACHED_COW_DATA,
} from "../actions/actionTypes";
import { EVENTS } from "../../constants";

export function* cowData() {
    yield race([take(TOGGLE_PREVIEW), call(delay, 240000)]);
    const [cow, data] = yield select(({ preview, data }) => [
        preview && preview.kind === "cow" && preview.id,
        preview && data[preview.id],
    ]);
    const now = yield call(Date.now);

    if (cow) {
        if (!data || data.until < now) {
            yield spawn(cowDataRequest, cow, data);
        }
        // If previous data stored
        if (data) {
            yield spawn(cachedCowDataRequest, cow, data);
        }
    }
    yield spawn(cowData);
}

export function* oldCowData() {
    const { start, end } = yield take(OLD_COW_DATA_REQUEST);
    const cow = yield select(
        ({ preview }) => preview && preview.kind === "cow" && preview.id,
    );
    if (cow) {
        yield put({ type: START_COW_DATA_REQUEST, cow });
        const [cowEventView, events] = yield select((state) => [
            state.eventView.eventIds.cow[cow],
            state.events,
        ]);
        const hasSessionCreated = cowEventView
            .filter(
                (id) =>
                    events[id].timestamp > start && events[id].timestamp < end,
            )
            .find((id) => events[id].type === EVENTS.SESSION_CREATED);
        const config = {};
        config.params = {
            since: start,
            until: end,
            types: ["temperature"].join("+"),
        };
        try {
            const response = yield call(axios.get, `/data/cow/${cow}`, config);
            yield put({
                type: COMPLETE_COW_DATA_REQUEST,
                sessions: response.data.sessions,
                since: response.data.since,
                until: response.data.until,
                period: response.data.period,
                cow,
                disableOld: !!hasSessionCreated,
            });
        } catch (error) {
            yield put({
                type: FAIL_COW_DATA_REQUEST,
                error,
                cow,
            });
        }
    }
    yield spawn(oldCowData);
}

/**
 * Returns cached cow data
 * @param {String} cow Cow ID
 * @param {Object} cache Previous data
 * @yields CACHED_COW_DATA
 */
function* cachedCowDataRequest(cow, cache) {
    yield put({
        type: CACHED_COW_DATA,
        sessions: cache.sessions,
        since: cache.since,
        until: cache.until,
        period: cache.period,
        cow: cow,
        disableOld: cache.disableOld,
    });
}

/**
 * Concatenates previous temperatures with new temperature data
 * @param {Array} cache Cache data
 * @param {Array} response Requested data
 * @returns {Array} Returns an array of object
 */
function mergeSessions(cache, response) {
    return response.data.sessions.reduce((acc, session) => {
        const prev = cache.sessions[session.id];

        // Update session if temperature or activity data is not empty
        if (
            session.temperature.data.length !== 0 ||
            session.activity.data.length !== 0
        ) {
            return [
                ...acc.filter((s) => s.id !== session.id), // Get other sessions
                {
                    ...session,
                    temperature: {
                        max:
                            session.temperature.max > prev.temperature.max
                                ? session.temperature.max
                                : prev.temperature.max,
                        min:
                            session.temperature.min < prev.temperature.min
                                ? session.temperature.min
                                : prev.temperature.min,
                        data: [
                            // 'session.temperature.data' need to be first because of descending data
                            ...session.temperature.data,
                            ...prev.temperature.data,
                        ],
                    },
                    activity: {
                        max:
                            session.activity.max > prev.activity.max
                                ? session.activity.max
                                : prev.activity.max,
                        min:
                            session.activity.min < prev.activity.min
                                ? session.activity.min
                                : prev.activity.min,
                        data: [...session.activity.data, ...prev.activity.data],
                    },
                },
            ];
        } else {
            // No need to update, return previous state
            return [...acc, prev];
        }
    }, []);
}

function* cowDataRequest(cow, cache) {
    yield put({ type: START_COW_DATA_REQUEST, cow });
    const twoDaysAgo = yield Date.now() - 172800000;
    const [cowEventView, events] = yield select((state) => [
        state.eventView.eventIds.cow[cow],
        state.events,
    ]);
    const hasSessionCreated =
        cowEventView &&
        cowEventView
            .filter((id) => events[id].timestamp > twoDaysAgo)
            .find((id) => events[id].type === EVENTS.SESSION_CREATED);
    const config = {};
    try {
        const cowData = yield select((state) => state.data[cow]);
        config.params = {
            since: cache && cache.until > 0 ? cache.until : cowData.since, // No need to request previous data.
            until: cowData.until,
            types: ["temperature"].join("+"),
        };
    } catch (e) {}

    try {
        const response = yield call(axios.get, `/data/cow/${cow}`, config);
        yield put({
            type: COMPLETE_COW_DATA_REQUEST,
            sessions:
                cache && !!Object.keys(cache.sessions).length // Returns true if session count more than 0
                    ? mergeSessions(cache, response)
                    : response.data.sessions,
            since: cache && cache.since > 0 ? cache.since : response.data.since,
            until: response.data.until,
            period: response.data.period,
            cow,
            disableOld: !!hasSessionCreated,
        });
    } catch (error) {
        console.log(error);
        yield put({
            type: FAIL_COW_DATA_REQUEST,
            error,
            cow,
        });
    }
}

/* eslint-disable no-case-declarations */
import {
    START_HEALTH_REPORT,
    COMPLETE_HEALTH_REPORT,
    FAIL_HEALTH_REPORT,
    RESET_HEALTH_REPORT
} from "../actions/actionTypes";

const initState = {
    ui: "available",
    sort: "prePartum",
    sortOrder: "descending",
    cows: {}
};

export default function (state = initState, action) {
    switch (action.type) {
    case COMPLETE_HEALTH_REPORT:
        const cows = action.cows;
        const lastLactations = Object.keys(cows).reduce((acc, k) => {
            const lastLac = Math.max(...Object.keys(cows[k]));
            return {
                ...acc,
                [k]: cows[k][lastLac]
            };
        }, {});
        return {
            ...state,
            ui: "available",
            cows: { ...lastLactations }
        };
    case START_HEALTH_REPORT:
        return { ...state, ui: "pending" };
    case FAIL_HEALTH_REPORT:
        return { ...state, ui: "fail" };
    case RESET_HEALTH_REPORT:
        return { ...state, ui: "available" };
    }
    return state;
}

import i18n from "../../i18n";

export const filterTags = {
    Pregnant: {
        id: "Pregnant",
        type: "tag",
        tag: "pregnant",
        sortBy: "expectedDelivery",
        props: { value: i18n.t("pregnant") },
        isParamRequired: false,
        params: [
            {
                type: "tagParam",
                parent: "Pregnant",
                kind: "inRange",
                props: { start: "0", end: "20", following: "days left" },
            }, // day will be updated later dynamically later
            {
                type: "tagParam",
                parent: "Pregnant",
                kind: "higher",
                props: { value: "0", following: "days left" },
            },
            {
                type: "tagParam",
                parent: "Pregnant",
                kind: "smaller",
                props: { value: "20", following: "days left" },
            },
        ],
    },
    PostPartum: {
        id: "PostPartum",
        type: "tag",
        tag: "post-partum",
        props: { value: i18n.t("post-partum") },
        isParamRequired: false,
    },
};

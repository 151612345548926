import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { sendNewSmartNote } from "../../store/actions";

import { VARIABLES } from "./constants";
import { HealthComplication, VariableSingle } from "./common";

import "./NewSmartNote.css";

export function FeverOver({
    id,
    toogleSmartNote,
    sendNewSmartNote,
    about,
    parentEventTagItems,
    defaults,
    isResult,
}) {
    const { t } = useTranslation();
    const smartNoteRef = useRef(null);
    useEffect(() => {
        document.addEventListener("click", onClickOutside, true);
        return () => {
            document.removeEventListener("click", onClickOutside, true);
        };
    });
    function onClickOutside(event) {
        if (
            smartNoteRef.current &&
            !smartNoteRef.current.contains(event.target)
        ) {
            toogleSmartNote(false);
        }
    }
    const [state, setState] = useState(defaults);

    return (
        <div className="smart-note fever-over" ref={smartNoteRef}>
            <div className={cn("note", { result: isResult })}>
                <VariableSingle
                    value={
                        state.feverReason
                            ? state.feverReason
                            : VARIABLES.feverReason.placeholder
                    }
                    label={`${t("smartNote:headers:feverReason")}:`}
                    selected={!!state.feverReason}
                    options={Object.values(VARIABLES.feverReason.options)}
                    onSelected={(option) =>
                        setState({
                            ...state,
                            feverReason: option.value,
                        })
                    }
                />
                <VariableSingle
                    value={
                        state.feverTreatment
                            ? state.feverTreatment
                            : VARIABLES.feverTreatment.placeholder
                    }
                    label={`${t("smartNote:headers:feverTreatment")}:`}
                    selected={!!state.feverTreatment}
                    options={Object.values(VARIABLES.feverTreatment.options)}
                    onSelected={(option) =>
                        setState({
                            ...state,
                            feverTreatment: option.value,
                        })
                    }
                />
            </div>
            {!isResult ? (
                <i
                    className="envelope completed"
                    onClick={() =>
                        sendNewSmartNote(about, parentEventTagItems, state)
                    }
                />
            ) : null}
        </div>
    );
}

function mapStateToProps(_, { defaults }) {
    const isResult = !!defaults;
    if (!isResult) {
        defaults = {
            feverReason: VARIABLES.feverReason.default,
            feverTreatment: VARIABLES.feverTreatment.default,
        };
    }
    return { defaults, isResult };
}

const mapDispatchToProps = { sendNewSmartNote };

export default connect(mapStateToProps, mapDispatchToProps)(FeverOver);

export function parseMatch(match) {
    return match.split("&").reduce((acc, string) => {
        const splitted = string.split("::");
        return { ...acc, [splitted[0]]: splitted[1] };
    }, {});
}

export function detectLanguageTextChanges(options) {
    const healthComplicationOptionCounters = {};
    return options.reduce((acc, option) => {
        switch (option.parent) {
            case "healthComplication":
                if (!healthComplicationOptionCounters[option.order]) {
                    healthComplicationOptionCounters[option.order] = 1;
                } else {
                    healthComplicationOptionCounters[option.order]++;
                }
                if (healthComplicationOptionCounters[option.order] > 1) {
                    const affectedLanguageTexts = option.affect.reduce(
                        (acc, text) => {
                            return { [text]: "plural" };
                        },
                        {},
                    );
                    return { ...acc, ...affectedLanguageTexts };
                }
                break;
            case "heatFallReason":
                const affectedLanguageTexts = option.affect.reduce(
                    (acc, text) => {
                        return { [text]: "grammer" };
                    },
                    {},
                );
                return { ...acc, ...affectedLanguageTexts };
            default:
                return acc;
        }
        return acc;
    }, {});
}

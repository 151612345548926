import {
    START_INVITE_NEW_USER_REQUEST,
    COMPLETE_INVITE_NEW_USER_REQUEST,
    FAIL_INVITE_NEW_USER_REQUEST,
    RESET_INVITE_NEW_USER
} from "../../actions/actionTypes";

export default function (state = "initial", action) {
    switch (action.type) {
    case START_INVITE_NEW_USER_REQUEST:
        return "pending";
    case COMPLETE_INVITE_NEW_USER_REQUEST:
        return "done";
    case RESET_INVITE_NEW_USER:
        return "initial";
    case FAIL_INVITE_NEW_USER_REQUEST:
        return "failed";
    default: return state;
    }
}
import {
    take, put, call, spawn, select
} from "redux-saga/effects";
import {
    FINISH_SESSION,
    START_FINISH_SESSION_REQUEST,
    COMPLETE_FINISH_SESSION_REQUEST,
    FAIL_FINISH_SESSION_REQUEST,
} from "../actions/actionTypes";
import { EVENTS, SESSION } from "../../constants/schema";
import axios from "axios";

export function* finishSession() {
    const { id } = yield take(FINISH_SESSION);
    yield spawn(finishSession);

    try {
        const tags = yield select(state => state.tags.session[id]);
        const tagValues = yield tags.map(t => t.value);
        let event = EVENTS.SESSION_COMPLETED;
        if (tagValues.includes(SESSION.PENDING)){
            event = EVENTS.SESSION_CANCELED;
        }
        const { id: session, cow, sensor } = yield select(state => state.sessions[id]);
        const finishSessionEvent = { event, payload: { session, cow, sensor, usingButton: true } };
        yield put({ type: START_FINISH_SESSION_REQUEST, id });
        const { data } = yield call(axios.post, "/v2/events", finishSessionEvent);
        yield put({ type: COMPLETE_FINISH_SESSION_REQUEST, data });
    } catch (error) {
        yield put({ type: FAIL_FINISH_SESSION_REQUEST, error });
    }
}

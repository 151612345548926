import { combineReducers } from "redux";

import { default as list } from "./pairingList";
import { default as cowsFromCowList } from "./selectedCows";
import { default as ui } from "./pairingUI";
import { default as requiredInfo } from "./requiredInfo";
import { default as external } from "./externalPairings";


export default combineReducers({
    list,
    ui,
    cowsFromCowList,
    requiredInfo,
    external
});
export { list, ui };

import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import { Trans } from "react-i18next";

import EventGroup from "../EventGroup";
import connect from "../../containers/Events";
import "./Events.css";
import * as LIFECYCLE from "../../constants/lifecycle";
import Time from "../Time";

const Events = ({ eventLinks, events }) => {
    const [group, setGroup] = useState({ id: null, from: null });
    const groupRef = useRef();
    const groupStateRef = useRef();
    groupStateRef.current = group;
    useEffect(() => {
        document.addEventListener("click", onClickOutside, true);
        return () => {
            document.removeEventListener("click", onClickOutside, true);
        };
    }, []);

    function onClickOutside(event) {
        if (
            groupRef.current &&
            !groupRef.current.contains(event.target) &&
            groupStateRef.current.from === "preview"
        ) {
            setGroup({ id: null, from: null });
        }
    }
    function onClicked(groupId) {
        if (groupStateRef.current.id) {
            setGroup({ id: null, from: null });
        } else {
            setGroup({ id: groupId, from: "local" });
        }
    }
    return (
        <ol className={cn("events", { examine: !!group.id })} ref={groupRef}>
            {events.map(([key, value]) => (
                <EventGroup
                    id={key}
                    key={key}
                    eventIds={value}
                    eventLinks={eventLinks}
                    onClicked={onClicked}
                    isFocused={group.id === key}
                />
            ))}
            <EventLoader />
        </ol>
    );
};

export default connect(Events);

const EventLoader = connect(eventLoader);

function eventLoader({ lifecycle_view, onOlder, eventView }) {
    const clickHandler =
        lifecycle_view === LIFECYCLE.PENDING ? () => {} : onOlder;
    const options = {
        day: "numeric",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
    };
    return (
        <li className={cn("older", lifecycle_view)} onClick={clickHandler}>
            {lifecycle_view === LIFECYCLE.PENDING ? (
                <Trans i18nKey="loadingEventsSince">
                    Loading events since
                    <Time
                        time={eventView.earliest - 172800000}
                        options={options}
                    />
                </Trans>
            ) : (
                <Trans i18nKey="loadingEventsSince">
                    Click to see events since
                    <Time
                        time={eventView.earliest - 172800000}
                        options={options}
                    />
                </Trans>
            )}
        </li>
    );
}

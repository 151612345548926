import React, { useState } from "react";
import cn from "classnames";

import connect from "../../containers/CowList";
import { useLazyList } from "../hooks";

import CowLine from "./CowLine";

import "./CowList.css";

export { CowLine } from "./CowLine";

export function CowList({
    cows,
    selectedCows,
    onCowClicked,
    renderSelections,
    cowTags,
    cowListRef,
}) {
    if (renderSelections && selectedCows.length > 0) cows = selectedCows;
    const [cowsToRender, rootRef] = useLazyList(cows, 20);
    const [slidedCowId, setSlidedCowId] = useState(0);
    const [showOptionsEvent, setShowOptionsEvent] = useState(null);

    function onMoved(cow) {
        setSlidedCowId(cow.id);
    }

    return (
        <ul className="cow-list" ref={rootRef}>
            {cowsToRender.map((cow, index) => {
                if (!cowTags[cow.id]) return;
                return (
                    <React.Fragment key={cow.id}>
                        <CowLine
                            key={cow.id}
                            cow={cow}
                            classNames={cn({ first: index == 0 })}
                            onClicked={(event) => onCowClicked(cow.id, event)}
                            selected={selectedCows.find((c) => c.id == cow.id)}
                            onMoved={onMoved}
                            autoClose={cow.id != slidedCowId}
                            cowListRef={cowListRef}
                            showOptionsEvent={showOptionsEvent}
                            setShowOptionsEvent={setShowOptionsEvent}
                        />
                    </React.Fragment>
                );
            })}
        </ul>
    );
}

export default connect(CowList);

import { connect } from "react-redux";
import { showDemoModal } from "../store/actions";

function mapStateToProps({ loginForm: { modal, lifecycle, errorType } }) {
    return { state: modal, lifecycle, errorType };
}

const mapDispatchToProps = { showDemoModal };

export default connect(mapStateToProps, mapDispatchToProps);

import axios from "axios";
import { take, put, call, spawn, select } from "redux-saga/effects";

import {
    SEND_SMART_NOTE,
    START_SEND_SMART_NOTE,
    SEND_NEW_SMART_NOTE,
    COMPLETE_SEND_SMART_NOTE,
    FAIL_SEND_SMART_NOTE,
} from "../actions/actionTypes";
import { EVENTS } from "../../constants";
import { packageFuntions } from "../../components/SmartNote/constants";

export function* sendNewSmartNote() {
    const { about, parentEventTagItems, pack } = yield take(
        SEND_NEW_SMART_NOTE,
    );
    yield put({ type: START_SEND_SMART_NOTE });
    const smartNote = {
        event: EVENTS.SMART_NOTE_SUBMITTED,
        payload: {
            package: pack,
            about,
            parentEventTagItems,
            eventVersion: "2",
        },
    };
    console.log(smartNote);
    try {
        const { data } = yield call(axios.post, "/v2/events", smartNote);
        yield put({ type: COMPLETE_SEND_SMART_NOTE, data });
    } catch (error) {
        yield put({ type: FAIL_SEND_SMART_NOTE, error });
    } finally {
        yield spawn(sendNewSmartNote);
    }
}

export function* sendSmartNote() {
    const { about, parentEventTagItems } = yield take(SEND_SMART_NOTE);
    yield put({ type: START_SEND_SMART_NOTE });
    const { variables, options, text } = yield select(
        (state) => state.smartNote,
    );
    const opts = getEventualOptions(variables, options);
    const parsed = parse(variables, opts);
    const pack = packageFuntions[about](parsed.string, parsed.options);
    const smartNote = {
        event: EVENTS.SMART_NOTE_SUBMITTED,
        payload: {
            note: text,
            package: pack,
            about,
            parentEventTagItems,
            options: opts,
            eventVersion: "1",
        },
    };
    try {
        const { data } = yield call(axios.post, "/v2/events", smartNote);
        yield put({ type: COMPLETE_SEND_SMART_NOTE, data });
    } catch (error) {
        yield put({ type: FAIL_SEND_SMART_NOTE, error });
    } finally {
        yield spawn(sendSmartNote);
    }
}

function getEventualOptions(variables, options) {
    const optionsInOrder = options.reduce((acc, opt) => {
        if (!acc[opt.order]) acc[opt.order] = [];
        return { ...acc, [opt.order]: [...acc[opt.order], opt] };
    }, {});
    const optionOrders = options.map((opt) => opt.order);
    const opts = variables.reduce((acc, v) => {
        if (!optionOrders.includes(v.order)) {
            return { ...acc, [v.order]: [v.options[v.default]] };
        }
        return { ...acc, [v.order]: optionsInOrder[v.order] };
    }, []);
    return opts;
}

function parse(variables, options) {
    const optionValues = Object.values(options).reduce((acc, opt) => {
        const parent = variables.find((v) => v.id == opt[0].parent);
        if (parent.type == "checkbox") {
            // checkbox
            opt = opt.filter((o) => o.id != parent.default);
            return [...acc, { [parent.id]: opt.map((o) => o.value) }];
        }
        return [...acc, { [opt[0].parent]: opt[0].value }];
    }, []);
    const optionString = Object.values(options)
        .map((opt) => opt[0].parent)
        .join();
    return { string: optionString, options: optionValues };
}

import { delay } from "redux-saga";
import { take, put, call, spawn, select, race } from "redux-saga/effects";
import {
    START_FETCH_EVENTS_REQUEST,
    COMPLETE_FETCH_EVENTS_REQUEST,
    FAIL_FETCH_EVENTS_REQUEST,
    COMPLETE_APP_STARTUP,
    FETCH_OLDER_EVENTS,
    START_FETCH_EVENTS_VIEW_REQUEST,
    COMPLETE_FETCH_EVENTS_VIEW_REQUEST,
    FAIL_FETCH_EVENTS_VIEW_REQUEST,
    FETCH_EVENTS_MANUALLY,
} from "../actions/actionTypes";
import axios from "axios";

export function* fetchEvents() {
    yield spawn(fetchEventsRequest); // prime the request
    yield take(COMPLETE_APP_STARTUP); // wait until app is ready

    yield spawn(fetchEventsForView, { type: "view" }); // show last 20 events for initially

    yield spawn(fetchOlderEvents); // start old events catcher
    yield spawn(fetchEventsTimer); // start update timer
}

function* fetchEventsForView(params = {}) {
    yield put({ type: START_FETCH_EVENTS_VIEW_REQUEST });
    try {
        const response = yield call(axios.get, "/v2/events", { params });

        const currentSessions = yield select((state) => state.sessions);
        const currentSensors = yield select((state) => state.sensors);
        yield put({
            type: COMPLETE_FETCH_EVENTS_VIEW_REQUEST,
            data: response.data,
            params,
            currentSessions,
            currentSensors,
        });
    } catch (error) {
        yield put({
            type: FAIL_FETCH_EVENTS_VIEW_REQUEST,
            error,
        });
    }
}

function* fetchOlderEvents() {
    yield take(FETCH_OLDER_EVENTS); // wait until UI triggered action

    const eventWindow = yield select((state) => state.eventView);
    const fortyEightHours = 172800000;
    yield spawn(fetchEventsForView, {
        until: eventWindow.earliest,
        since: eventWindow.earliest - fortyEightHours,
        toLastSessionId: eventWindow.toLastSessionId,
        type: "view",
    });
    yield spawn(fetchOlderEvents); // restart self
}

function* fetchEventsTimer() {
    yield race([take(FETCH_EVENTS_MANUALLY), call(delay, 60000)]);

    const eventWindow = yield select((state) => state.eventWindow);

    yield spawn(fetchEventsRequest, { since: eventWindow.latest }, true);
    yield spawn(fetchEventsTimer); // restart self
}

export function* fetchEventsRequest(params = {}, isTimer = false) {
    yield put({ type: START_FETCH_EVENTS_REQUEST });

    try {
        const response = yield call(axios.get, "/v2/events", { params });

        yield put({
            type: COMPLETE_FETCH_EVENTS_REQUEST,
            data: response.data,
            params,
            isTimer,
        });
    } catch (error) {
        yield put({
            type: FAIL_FETCH_EVENTS_REQUEST,
            error,
        });
    }
}

import React, { useMemo } from "react";
import pt from "prop-types";

import i18n from "../../i18n";
function Time({ time, locale, options, useFunc = false }) {
    const format = useMemo(
        () =>
            new Intl.DateTimeFormat(locale ? locale : i18n.language, {
                ...options,
                hourCycle: "h23",
            }).format,
        [locale, options],
    );
    let date;
    let dateString;
    try {
        date = new Date(time);
        dateString = format(date);
    } catch (e) {
        dateString = "--";
    }
    if (useFunc) return dateString;
    return (
        <time key={time} dateTime={date}>
            {dateString}
        </time>
    );
}

Time.propTypes = {
    time: function ({ time }, propName, componentName) {
        if (typeof time !== "number" || time < 1000000000000) {
            return new Error(
                `Invalid prop ${propName} supplied to ` +
                    `${componentName}. Validation failed. ` +
                    "You probably supplied a time in seconds " +
                    "instead of miliseconds.",
            );
        }
    },
    locale: pt.string,
    options: pt.shape({
        day: pt.oneOf(["numeric", "2-digit"]),
        month: pt.oneOf(["numeric", "2-digit", "narrow", "short", "long"]),
        year: pt.oneOf(["numeric", "2-digit"]),
        hour: pt.oneOf(["numeric", "2-digit"]),
        minute: pt.oneOf(["numeric", "2-digit"]),
        second: pt.oneOf(["numeric", "2-digit"]),
        weekday: pt.oneOf(["narrow", "short", "long"]),
        era: pt.oneOf(["narrow", "short", "long"]),
        timeZoneName: pt.oneOf(["short", "long"]),
    }),
    useFunc: pt.bool,
};

Time.defaultProps = {
    /*
        See other options:
        https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
    */
    options: {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "numeric",
        second: "2-digit",
    },
    locale: i18n.language,
};
export default Time;

import { LIFECYCLE } from "../../constants";

export function toList(objects) {
    return objects.reduce(
        (list, object) => Object.assign(list, { [object.id]: object })
        , {});
}

export function pending() {
    return { lifecycle: LIFECYCLE.PENDING };
}

export function done(objects) {
    const list = toList(objects);
    return {
        lifecycle: LIFECYCLE.DONE,
        list
    };
}

export function failed(error) {
    return {
        lifecycle: LIFECYCLE.FAILED,
        error
    };
}

export function initial() {
    return { lifecycle: LIFECYCLE.INITIAL };
}

import { SET_CONFIGS } from "../../actions/actionTypes";

export default function (state = false, action) {
    switch (action.type) {
    case SET_CONFIGS:
        // @TODO temporary, fix this
        return true;
        // eslint-disable-next-line no-case-declarations
        // const config = action.configs.find(c => c.name == "CALL_PRIVILIGES_ENABLED");
        // if (config.value === "on") return true;
        // else return false;
    default: return state;
    }
}
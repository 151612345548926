import React, { useContext } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useGoogleLogout } from "react-google-login";

import Button from "../Button";
import { logout, startTour } from "../../store/actions";
import { RouterContext } from "../../containers/Contexts";
import "./NavBar.css";

export function MainMenu({ currentFarm, onLogout, startTour, disabled }) {
    const { t } = useTranslation();
    const navigate = useContext(RouterContext);

    const { signOut, loaded } = useGoogleLogout({
        clientId:
            "750389719857-bo51argqi7dc6qrr44hr9fm8l8iisg3i.apps.googleusercontent.com",
        onFailure: (error) => console.log("ERROR", error),
        onLogoutSuccess: (v) => console.log("SUCCESS", v),
    });

    function onLogOutLocal() {
        signOut();
        onLogout();
    }

    function ListItem({
        ListClassNames,
        onClicked,
        header,
        headerClassNames,
        buttonClassNames,
        explanation,
        onButtonClicked,
    }) {
        return (
            <li className={ListClassNames} onClick={onClicked}>
                <h1 className={headerClassNames}>{header}</h1>
                {buttonClassNames === "logout" ? (
                    <Button
                        onClicked={onButtonClicked}
                        className="logout"
                        text={t("confirm")}
                        type="action"
                        behaviour="instant"
                    />
                ) : !buttonClassNames && !explanation ? null : (
                    <span
                        className={buttonClassNames}
                        onClick={onButtonClicked}>
                        {explanation}
                    </span>
                )}
            </li>
        );
    }
    function onListItemClicked(action) {
        document.querySelector("button.logout").classList.remove("visible");
        if (action) action();
    }
    function onLogOutConfirm() {
        const buttonLogout = document.querySelector("button.logout");
        if (document.querySelector("button.logout.visible")) {
            buttonLogout.classList.remove("visible");
        } else {
            buttonLogout.classList.add("visible");
        }
    }

    return (
        <nav className="main-menu">
            <ul>
                <li className="farm-name-close">
                    <h1 className="farm-name">{currentFarm.name}</h1>
                    <span className="close-button" onClick={navigate.back} />
                </li>
                <li
                    className={cn("cow-list")}
                    onClick={() => navigate.forward("/cows")}>
                    <h1>{t("cowList")}</h1>
                    <span className="cow-list-menu-explanation">
                        {t("cowListMenuExplanation")}
                    </span>
                </li>
                <li
                    className={cn("sensor-management")}
                    onClick={() => navigate.forward("/sensors")}>
                    <h1>{t("deviceManagement")}</h1>
                    <span className="sensor-management-menu-explanation">
                        {t("deviceManagementMenuExplanation")}
                    </span>
                </li>
                <li
                    className={cn("switch-farm")}
                    onClick={() => navigate.forward("/switchFarm")}>
                    <h1>{t("switchFarm")}</h1>
                    <span className="switch-farm-menu-explanation">
                        {t("switchFarmMenuExplanation")}
                    </span>
                </li>
                <li
                    className={cn("farm-metrics")}
                    onClick={() => navigate.forward("/farmMetrics")}>
                    <h1>{t("farmMetrics")}</h1>
                    <span className="farm-metrics-explanation">
                        {t("farmMetricsExplanation")}
                    </span>
                </li>
                <li
                    className={cn("health-report")}
                    onClick={() => navigate.forward("/healthReport")}>
                    <h1>{t("feverReport")}</h1>
                </li>
                <li
                    className={cn("your-profile")}
                    onClick={() => navigate.forward("/profile")}>
                    <h1>{t("yourProfile")}</h1>
                    <span className="your-profile-menu-explanation">
                        {t("yourProfileMenuExplanation")}
                    </span>
                </li>
                <li
                    className={cn("farm-settings")}
                    onClick={() => navigate.forward("/settings/farm")}>
                    <h1>{t("farmSettings")}</h1>
                </li>
                <li
                    className={cn("notification-settings")}
                    onClick={() => navigate.forward("/settings/notification")}>
                    <h1>{t("notificationSettings")}</h1>
                    <span className="notification-settings-menu-explanation">
                        {t("notificationSettingsMenuExplanation")}
                    </span>
                </li>
                <li
                    className={cn("dashboard-settings")}
                    onClick={() => navigate.forward("/settings/dashboard")}>
                    <h1>{t("dashboardSettings")}</h1>
                    <span className="dashboard-settings-menu-explanation">
                        {t("dashboardSettingsMenuExplanation")}
                    </span>
                </li>
                <li className="guided-tour" onClick={startTour}>
                    <h1>{t("guidedTour")}</h1>
                    <span className="guided-tour-menu-explanation">
                        {t("guidedTourMenuExplanation")}
                    </span>
                </li>
                {/* <li className="help-and-support" onClick={() => null}>
                    <h1>{t("helpAndSupport")}</h1>
                    <span className="help-and-support-menu-explanation">{t("helpAndSupportMenuExplanation")}</span>
                </li> */}
                <li className="log-out-confirm" onClick={onLogOutConfirm}>
                    <h1>{t("logOut")}</h1>
                    <Button
                        onClicked={onLogout}
                        className="logout"
                        text={t("confirm")}
                        type="action"
                        behaviour="instant"
                    />
                </li>
            </ul>
        </nav>
    );
}

function mapStateToProps({ farms, navigation: { routes } }) {
    return { currentFarm: farms.current, disabled: routes.length > 2 };
}

const mapDispatchToProps = { onLogout: logout, startTour };

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);

import React, { useState } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import { toggleFullscreen } from "../../store/actions";
import DataGraph from "../DataGraph";
import SensorLabel from "../SensorLabel";

import "./ClimateSensPreview.css";

function ClimateSensPreview({
    placePairing: { id, deviceId, placeName },
    preview,
    toggleFullscreen,
}) {
    const [tooltipClickedVisible, setTooltipClickedVisible] = useState(false);
    function setFullscreen() {
        toggleFullscreen(
            preview && preview.isFullscreen ? !preview.isFullscreen : true,
        );
    }
    return (
        <aside className={cn("climate-sens-preview")}>
            <header>
                <h3>{placeName}</h3>
                <h4>
                    <SensorLabel {...{ type: "", deviceId }} />
                </h4>
                <span
                    className={cn("fullscreen-button", {
                        off: preview && !preview.isFullscreen,
                    })}
                    onClick={() => setFullscreen()}
                />
            </header>
            <DataGraph
                id={id}
                tooltipClickedVisible={tooltipClickedVisible}
                setTooltipClickedVisible={setTooltipClickedVisible}
                useAutomation
            />
        </aside>
    );
}

function mapStateToProps({ automation, preview }, { id }) {
    return { placePairing: automation[id], preview };
}

const mapDispatchToProps = { toggleFullscreen };

export default connect(mapStateToProps, mapDispatchToProps)(ClimateSensPreview);

import { connect } from "react-redux";

import { EVENTS } from "../constants";
import { fetchOlderEvents } from "../store/actions";

const mapStateToProps = ({
    events_lifecycle,
    events_lifecycle_view,
    eventWindow,
    eventLinks,
    eventView,
    preview,
    events,
}) => {
    if (eventView.eventIds.length === 0)
        return {
            events: [],
            lifecycle: events_lifecycle,
            lifecycle_view: events_lifecycle_view,
            eventWindow,
            eventView,
            eventLinks,
        };
    const sortedCowViewEvents = Object.keys(eventView.eventIds.cow).reduce(
        (acc, cowId) => {
            return {
                ...acc,
                [cowId]: eventView.eventIds.cow[cowId].sort((a, b) => b - a),
            };
        },
        {},
    );
    const filteredSingleEvents = Object.keys(eventView.eventIds.single)
        .map((id) => parseInt(id))
        .sort((a, b) => b - a)
        .reduce((acc, id) => {
            return { ...acc, [id]: [id] };
        }, {});

    const eventsSortedByDate = Object.entries({
        ...sortedCowViewEvents,
        ...filteredSingleEvents,
    }).sort(([, value1], [, value2]) => value2[0] - value1[0]);
    return {
        lifecycle: events_lifecycle,
        lifecycle_view: events_lifecycle_view,
        eventView,
        events: eventsSortedByDate,
        eventLinks,
        preview,
    };
};

const mapDispatchToProps = { onOlder: fetchOlderEvents };

export default connect(mapStateToProps, mapDispatchToProps);

export default {
    cows: [
        {
            id: "c1",
            farmNumber: 11,
            name: "Sarıkız",
            identityNumber: "TR9304-23495",
            breed: "Holstein",
            birthdate: 1492819200000
        },
        {
            id: "c2",
            farmNumber: 12,
            name: "Boncuk",
            identityNumber: "DE3095-23859085",
            breed: "Jersey",
            birthdate: 1462147200000
        },
        {
            id: "c3",
            farmNumber: 13,
            name: "Milka"
        },
        {
            id: "c4",
            farmNumber: 14,
            name: "Zeytin"
        },
        {
            id: "c5",
            farmNumber: 15,
            name: "Kaymak"
        },
        {
            id: "c6",
            farmNumber: 16,
            name: "Sütlaç"
        }
    ],
    events: [
        {
            id: "e1",
            timestamp: 1538558023000,
            type: "tsens-installed",
            payload: {
                tsens: "t1",
                cow: "c6",
                user: "u1",
                tags: ["tracked"]
            },
            readState: "marked"
        },
        {
            id: "e2",
            timestamp: 1538559023000,
            type: "tsens-installed",
            payload: {
                tsens: "t1",
                cow: "c3",
                user: "u1",
                tags: ["tracked"]
            },
            readState: "read"
        },
        {
            id: "e3",
            timestamp: 1538570023000,
            type: "fever-detected",
            payload: {
                cow: "c3",
                temperature: "37",
                tags: ["tracked", "fever"]
            },
            readState: "unread"
        },
        {
            id: "e4",
            timestamp: 1538560023000,
            type: "labor-detected",
            payload: {
                cow: "c6",
                tags: ["tracked", "labor"]
            },
            readState: "unread"
        },
        {
            id: "e5",
            timestamp: 1538560023000,
            type: "note-submitted",
            payload: {
                user: "u1",
                note: "Test note. Nothing wrong"
            },
            readState: "unread"
        }
    ],
    users: [
        {
            id: "u1",
            name: "Cengiz Köse"
        }
    ],
    tsenses: [
        {
            id: "t1",
            farmNumber: 345
        }
    ]
};

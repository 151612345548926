import { connect } from "react-redux";

import { togglePreview } from "../store/actions";

export default function connectPreview(kind) {
    function mapStateToPropsWithKind({ preview }, { id }) {
        return {
            preview: preview && preview.id === id && preview.kind === kind,
        };
    }
    function mapStateToProps({ preview }) {
        return { preview };
    }
    function mapDispatchToPropsWithKind(dispatch, { id }) {
        return { onToggle: () => dispatch(togglePreview(kind, id)) };
    }
    const optionsForKind = {
        getDisplayName(name) {
            return `Preview[${kind}]⇢${name}`;
        },
    };
    const options = {
        getDisplayName(name) {
            return `Preview⇢${name}`;
        },
    };
    if (kind) {
        return connect(
            mapStateToPropsWithKind,
            mapDispatchToPropsWithKind,
            null,
            optionsForKind,
        );
    } else {
        return connect(mapStateToProps, null, null, options);
    }
}

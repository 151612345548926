import { mirageServer, store } from "../../..";
import * as eventFunctions from "../../../server/utilities";
import { FETCH_EVENTS_MANUALLY } from "../../../store/actions/actionTypes";

/// This module binds the event functions from the server/utilities
/// module to the mirage server instance exported from the root.
/// Convenience for triggering mock events directly from the tour.

// Filter and map all exports
export default Object.entries(eventFunctions).reduce(
    filterMapEventFunction,
    {},
);

function filterMapEventFunction(boundEvents, [key, fn]) {
    // Reject non-function exports, just to be safe.
    if (typeof fn !== "function") {
        return boundEvents;
    }

    // See if the export name ends with "Event"
    const match = key.match(/(\w+)Event$/);

    if (match) {
        boundEvents[match[1]] = (...args) => {
            // invoke the event trigger function with the same arguments
            try {
                fn(mirageServer.db, ...args);
            } catch (e) {
                console.error(e); // eslint-disable-line no-console
            }

            // trigger a fetch to get the new event
            store.dispatch({ type: FETCH_EVENTS_MANUALLY });

            // return a promise that resolves when the fetch is done
            // or rejects when it fails or times out at 10 seconds
            return new Promise((resolve, reject) => {
                // Initialise a variable for the timeout timer handle.
                // can't assign it now, because the timer callback needs
                // the unsubscribe callback tht comes next.
                // It's a circular dependency.
                var timeout;

                const unsubscribe = store.subscribe(() => {
                    // this looks at the event lifecycle state and
                    // resolves/rejects/waits based on its value
                    // When settled, we also remove the store subscription
                    // and clear the timeout timer.
                    const { events_lifecycle } = store.getState();

                    switch (events_lifecycle) {
                        case "done":
                            unsubscribe();
                            clearTimeout(timeout);
                            resolve();
                            break;
                        case "failed":
                            unsubscribe();
                            clearTimeout(timeout);
                            reject("failed");
                            break;
                    }
                });
                // This needs a timeout as well, just in case.
                // Otherwise we may leave the store subscription hanging,
                // which may turn into a memory leak.
                timeout = setTimeout(() => {
                    unsubscribe();
                    reject("timeout");
                }, 10000);
            });
        };
    }
    return boundEvents;
}

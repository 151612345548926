/* eslint-disable no-case-declarations */
import React, { useState, useMemo } from "react";
import cn from "classnames";

import Input from "../../Input";
import Time from "../../Time";

import "./TimePicker.css";

const TimePicker = ({ onClicked, defaultTime }) => {
    const [timestamp, setTimestamp] = useState(defaultTime.getTime());
    const pickedTime = new Date(timestamp);
    function onClickedLocal(type, change) {
        let newTime;
        switch (type) {
            case "hour":
                newTime = pickedTime.getTime() + change * 3600000; // 1hour
                setTimestamp(newTime);
                onClicked(newTime);
                break;
            case "minute":
                newTime = pickedTime.getTime() + change * 60000; // 1min
                setTimestamp(newTime);
                onClicked(newTime);
                break;
            case "day":
                newTime = pickedTime.getTime() + change * 86400000; // 1day
                setTimestamp(newTime);
                onClicked(newTime);
                break;
        }
    }

    function onDirectSettedLocal(type, value) {
        let newTime;
        if (type == "hour") {
            newTime = pickedTime.setHours(value); // 1hour
            setTimestamp(newTime);
            onClicked(newTime);
            const minuteEl = document.querySelector(
                ".time-picker .time-part.second .body span",
            );
            if (minuteEl) minuteEl.click();
        } else if (type == "minute") {
            newTime = pickedTime.setMinutes(value); // 1min
            setTimestamp(newTime);
            onClicked(newTime);
        }
    }

    return (
        <div className="time">
            <div className="date-picker">
                <div
                    className="before"
                    onClick={() => onClickedLocal("day", -1)}
                />
                <Time
                    time={pickedTime.getTime()}
                    options={{ day: "numeric", month: "short" }}
                />
                <div
                    className="after"
                    onClick={() => onClickedLocal("day", 1)}
                />
            </div>
            <div className="time-picker">
                <TimePart
                    className="first"
                    type="hour"
                    value={("0" + pickedTime.getHours()).slice(-2)}
                    onClicked={(change) => onClickedLocal("hour", change)}
                    onDirectSet={onDirectSettedLocal}
                />
                <TimePart
                    className="second"
                    type="minute"
                    value={("0" + pickedTime.getMinutes()).slice(-2)}
                    onClicked={(change) => onClickedLocal("minute", change)}
                    onDirectSet={onDirectSettedLocal}
                />
            </div>
        </div>
    );
};
export default TimePicker;

const TimePart = ({ type, value, onClicked, className, onDirectSet }) => {
    const [isInput, setIsInput] = useState(false);

    function onChanged({ target }, type) {
        if (!isPositiveInteger(target.value)) {
            setTimeout(() => {
                onDirectSet(type, 0);
                setIsInput(false);
            }, 1000);
            return;
        }
        switch (type) {
            case "hour":
                if (target.value.length <= 2) {
                    if (target.value > 23) {
                        target.value = 23;
                    }
                }
                break;
            case "minute":
                if (target.value.length <= 2) {
                    if (target.value > 59) {
                        target.value = 59;
                    }
                }
                break;
        }
        setTimeout(() => {
            onDirectSet(type, target.value);
            setIsInput(false);
        }, 1000);
    }
    return (
        <div className={cn("time-part", className)}>
            <div className="before" onClick={() => onClicked(1)} />
            <div className="body">
                {isInput ? (
                    <input
                        className="time-input"
                        autoFocus
                        defaultValue={value}
                        maxLength="2"
                        onFocus={({ target }) => target.select()}
                        onChange={(event) => onChanged(event, type)}
                    />
                ) : (
                    <span
                        onClick={() => {
                            setIsInput(true);
                        }}>
                        {value}
                    </span>
                )}
            </div>
            <div className="after" onClick={() => onClicked(-1)} />
        </div>
    );
};

function isPositiveInteger(n) {
    return n % (!isNaN(parseFloat(n)) && ~~n >= 0) === 0;
}

import {
    START_POTENTIAL_LIST,
    COMPLETE_POTENTIAL_LIST,
    FAIL_POTENTIAL_LIST,
} from "../actions/actionTypes";
import { INITIAL, PENDING, DONE, FAILED } from "../../constants/lifecycle";
import { COW } from "../../constants";

const initial = {
    id: null,
    lifecycle: INITIAL,
    list: [],
    listEndTime: null,
};

const reducer = (state = initial, action) => {
    switch (action.type) {
        case START_POTENTIAL_LIST:
            return { ...state, lifecycle: PENDING };
        case COMPLETE_POTENTIAL_LIST:
            // eslint-disable-next-line no-case-declarations
            const list = action.list.sort((a, b) => {
                const aTag = action.tags.cow[a.cowId].find(
                    (t) => t.value === COW.PREGNANT,
                );
                const bTag = action.tags.cow[b.cowId].find(
                    (t) => t.value === COW.PREGNANT,
                );
                if (aTag && bTag) {
                    return (
                        aTag.params.expectedDelivery -
                        bTag.params.expectedDelivery
                    );
                }
                return 0;
            });
            return {
                ...state,
                lifecycle: DONE,
                listEndTime: action.listEndTime,
                list: list.map((l) => {
                    if (l.sessionStartTime) {
                        return { ...l, suggestion: false };
                    } else {
                        return {
                            ...l,
                            sensorId: action.avSensors.pop(),
                            suggestion: true,
                        };
                    }
                }),
                id: action.id,
            };
        case FAIL_POTENTIAL_LIST:
            return { lifecycle: FAILED };
        default:
            return state;
    }
};
export default reducer;

import { take, put, call, spawn } from "redux-saga/effects";
import {
    SUBMIT_PAIRING_LIST,
    START_PAIRING_LIST_REQUEST,
    COMPLETE_PAIRING_LIST_REQUEST,
    FAIL_PAIRING_LIST_REQUEST,

    PROCESS_REQUIRED_INFO,
    START_PROCESS_REQUIRED_INFO,
    COMPLETE_PROCESS_REQUIRED_INFO,
    FAIL_PROCESS_REQUIRED_INFO
} from "../actions/actionTypes";
import { EVENTS } from "../../constants/schema";
import axios from "axios";


export function* pairingRequest() {
    const { list } = yield take(SUBMIT_PAIRING_LIST);

    yield put({ type: START_PAIRING_LIST_REQUEST });

    const pairingListEvent = {
        event: EVENTS.PAIRING_LIST_CREATED,
        payload: { list },
    };

    try {
        const { data } = yield call(axios.post, "/v2/events", pairingListEvent);
        yield put({ type: COMPLETE_PAIRING_LIST_REQUEST, data });
    } catch (error) {
        yield put({ type: FAIL_PAIRING_LIST_REQUEST, error });
    } finally {
        yield spawn(pairingRequest);
    }
}

export function *processRequiredInfo(){
    const { cow, required, date } = yield take(PROCESS_REQUIRED_INFO);
    let action = {};
    if (required == "lastBreedingDate"){
        action = {
            event: EVENTS.PREGNANCY_DETECTED,
            payload: {
                cow: cow.id,
                lastBreedingDate: date.getTime()
            },
        };
    } else {
        action = {
            event: EVENTS.PREGNANCY_OVER,
            payload: {
                cow: cow.id,
                lastCalvingDate: date.getTime()
            },
        };
    }
    yield put({ type: START_PROCESS_REQUIRED_INFO });

    try {
        const { data } = yield call(axios.post, "/v2/events", action);
        yield put({ type: COMPLETE_PROCESS_REQUIRED_INFO, data });
    } catch (error) {
        yield put({ type: FAIL_PROCESS_REQUIRED_INFO, error });
    } finally {
        yield spawn(processRequiredInfo);
    }
}
import { connect } from "react-redux";

import { COW, SENSOR } from "../constants";
import { INITIAL } from "../constants/lifecycle";
import i18n from "../i18n";

function mapStateToProps(
    { data, sensors, tags, eventView, events, automation },
    { id, useAutomation },
) {
    if (useAutomation) {
        return mapStateToPropsAutomation({ automation }, { id, useAutomation });
    }
    if (!data[id] || data[id].sessions.length === 0) {
        return { temperature: {}, lifecycle: "pending" };
    }
    const temperature = dataBySensorType(
        data,
        sensors,
        id,
        tags,
        "temperature",
    );
    const thresholds = Object.values(temperature).reduce(
        (acc, d) => {
            const temporary = { ...acc };
            if (temporary.min > d.thresholds.min)
                temporary.min = d.thresholds.min;
            if (temporary.max < d.thresholds.max)
                temporary.max = d.thresholds.max;
            return temporary;
        },
        { min: 50, max: 0 },
    );

    const now = Date.now();
    const lastTwoDays = now - 172800000;
    const since = data[id] ? data[id].since : lastTwoDays;
    const until = data[id] ? data[id].until : now;
    const cowEvents = eventView.eventIds.cow[id]
        ? eventView.eventIds.cow[id]
              .filter(
                  (id) =>
                      events[id].timestamp > since &&
                      events[id].timestamp < until,
              )
              .map((id) => events[id])
        : [];
    return {
        cowEvents,
        temperature: temperature,
        lifecycle: data[id].lifecycle,
        isScaled: true,
        language: i18n.language,
        endTime: data[id].until,
    };
}

function dataBySensorType(data, sensors, id, tags, dataType) {
    return Object.keys(data[id].sessions).reduce((temperature, sessionId) => {
        const sensorType = sensors[data[id].sessions[sessionId].sensor].type;
        if (!temperature[sensorType])
            temperature = { ...temperature, [sensorType]: {} };
        return {
            ...temperature,
            [sensorType]: {
                timestamp: [
                    ...reduceData(
                        data[id].sessions[sessionId][dataType].data,
                        data[id].period,
                        "timestamp",
                        data[id].until,
                    ),
                ],
                data: [
                    ...reduceData(
                        data[id].sessions[sessionId][dataType].data,
                        data[id].period,
                        "data",
                        data[id].until,
                    ),
                ],
                environment: [
                    ...reduceData(
                        data[id].sessions[sessionId][dataType].data,
                        data[id].period,
                        "environment",
                        data[id].until,
                    ),
                ],
                activity: [
                    ...reduceData(
                        data[id].sessions[sessionId].activity.data,
                        data[id].period,
                        "data",
                        data[id].until,
                    ),
                ],
                state: tags.session[sessionId],
                thresholds: {
                    min: data[id].sessions[sessionId][dataType].min,
                    max: data[id].sessions[sessionId][dataType].max,
                    activityMin: data[id].sessions[sessionId].activity.min,
                    activityMax: data[id].sessions[sessionId].activity.max,
                },
                sensorsId: data[id].sessions[sessionId].sensor,
            },
        };
    }, {});
}

function reduceData(dataPoints, period, pointType = null, endTime) {
    let nullArray = [];
    if (!dataPoints[0]) {
        return nullArray.fill([null, null, null]).slice(0, 720);
    }
    const deltaTime = endTime - dataPoints[0][0];
    const nullDatapointCount = Math.floor(deltaTime / period);
    if (nullDatapointCount > 0) {
        nullArray = Array(nullDatapointCount);
    } else {
        nullArray = [];
    }
    const dataPointsWithNull = nullArray
        .fill([null, null, null])
        .concat(dataPoints)
        .slice(0, 720);
    if (pointType) return getOnly(dataPointsWithNull, pointType);
    return dataPointsWithNull;
}

function getOnly(dataPoints, pointType) {
    const pointIndex = {
        timestamp: 0,
        data: 1,
        environment: 2,
        activity: 3,
    };
    return dataPoints.reduce((acc, point) => {
        return [...acc, point[pointIndex[pointType]]];
    }, []);
}

function mapStateToPropsAutomation({ automation }, { id }) {
    if (!automation[id].data) return { temperature: {}, lifecycle: "pending" };
    return {
        temperature: {
            Tsens: {
                timestamp: automation[id].data.timestamp,
                data: automation[id].data.temperature,
            },
        },
        lifecycle: automation[id].lifecycle,
        isScaled: true,
        language: i18n.language,
    };
}

export default connect(mapStateToProps);

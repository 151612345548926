import {
    START_COW_DATA_REQUEST,
    COMPLETE_COW_DATA_REQUEST,
    FAIL_COW_DATA_REQUEST,
    CACHED_COW_DATA,
} from "../actions/actionTypes";
import { INITIAL, PENDING, DONE, FAILED } from "../../constants/lifecycle";

export default function (state = {}, action) {
    const sessionsData = {
        sessions: {},
        since: 0,
        until: 0,
        period: 0,
        disableOld: false,
        lifecycle: INITIAL,
    };

    switch (action.type) {
        case START_COW_DATA_REQUEST:
            return {
                ...state,
                [action.cow]: {
                    ...sessionsData,
                    lifecycle: PENDING,
                },
            };
        case CACHED_COW_DATA:
            return {
                ...state,
                [action.cow]: {
                    // "categorizeBySession" function not needed. 'Cause' sessions have already categorized.
                    sessions: action.sessions,
                    since: action.since,
                    until: action.until,
                    period: action.period,
                    lifecycle: action.since > 0 ? DONE : PENDING, // To avoiding blank loading
                    disableOld: action.disableOld,
                },
            };
        case COMPLETE_COW_DATA_REQUEST:
            return {
                ...state,
                [action.cow]: {
                    sessions: categorizeBySession(action.sessions),
                    since: action.since,
                    until: action.until,
                    period: action.period,
                    lifecycle: DONE,
                    disableOld: action.disableOld,
                },
            };
        case FAIL_COW_DATA_REQUEST:
            return {
                ...state,
                [action.cow]: { ...sessionsData, lifecycle: FAILED },
            };
        default:
            return state;
    }
}

function categorizeBySession(sessions) {
    return sessions.reduce((acc, session) => {
        return { ...acc, [session.id]: session };
    }, {});
}

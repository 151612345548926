import { take, select, call, put, spawn, fork } from "redux-saga/effects";
import {
    FINISH_DEMO,
    REGISTER_DEMO_ACCOUNT,
    FAIL_REGISTER_DEMO_ACCOUNT,
    COMPLETE_REGISTER_DEMO_ACCOUNT,
    RESET_DEMO_MODAL,
} from "../actions/actionTypes";
import i18n from "../../i18n";
import axios from "axios";

const demoFarmLStorage = {
    longTermToken: "LTT",
    farms:
        '[{"id":"1","name":"Local Farm","location":"","lastVisit":1590060631000,"totalCows":2,"trackedCows":1}]',
    language: i18n.language,
    shortcutButton: "cowList",
    autoSortingBy: "priority",
    ownerUserId: "1",
};

export function* startDemo() {
    yield fork(restartDemo);
    const { user } = yield take(COMPLETE_REGISTER_DEMO_ACCOUNT);
    const backup = yield JSON.stringify(window.localStorage);
    yield window.localStorage.clear();
    yield window.localStorage.setItem("backup", backup);
    yield setAllItem(demoFarmLStorage);
    yield window.localStorage.setItem("user", JSON.stringify(user));
    window.location.href = "/?demo=''";
}

function* restartDemo() {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const mockEvents = window.localStorage.getItem("mockEvents");
    const LTT = window.localStorage.getItem("longTermToken");
    if (user && mockEvents && !LTT) {
        window.localStorage.setItem("longTermToken", "LTT");
        window.location.href = "/?demo=''";
    }
}

export function* finishDemo() {
    yield take(FINISH_DEMO);
    const state = yield select();
    const backup = yield JSON.parse(window.localStorage.getItem("backup"));
    yield window.localStorage.clear();

    yield window.localStorage.setItem("snapshot", JSON.stringify(state));
    yield setAllItem(backup);

    window.location.href = "/";
}

export function* registerDemoAccount() {
    const { name, surname, email, phone } = yield take(REGISTER_DEMO_ACCOUNT);
    try {
        const response = yield call(axios.post, "/demo/register", {
            name,
            surname,
            email,
            phone,
        });
        if (response && response.status === 201) {
            const user = {
                id: 1,
                name: name,
                surname: surname,
                email: email,
                phoneNumber: phone,
                apiUserTypesId: 1,
                isFarmAdmin: true,
                tags: ["registered"],
            };
            yield put({ type: COMPLETE_REGISTER_DEMO_ACCOUNT, user });
        } else {
            throw new Error(response);
        }
    } catch (error) {
        yield put({ type: FAIL_REGISTER_DEMO_ACCOUNT, error });
        yield call(delay, 5000);
        yield put({ type: RESET_DEMO_MODAL });
    }

    yield spawn(registerDemoAccount);
}

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

function* setAllItem(lStorage) {
    yield Object.keys(lStorage).map((key) => {
        window.localStorage.setItem(key, lStorage[key]);
    });
}

import i18n from "../i18n";
import { COW } from "../constants";

export function objectSearchEngine(collection, keys, emptyString = false) {
    return function objectSearch(
        term,
        multipleKeySearch = () => "",
        showAll = false,
    ) {
        if (showAll) return Object.values(collection);
        const cleanTerm = term
            .replace(/\\?([-/\\^$*+?.()|[\]{}])/g, "\\$1")
            .replace(/\s/g, "");
        if (emptyString) if (cleanTerm === "") return [];
        const regexp = RegExp(`.*${cleanTerm}.*`, "i");
        const notDotRegEx = RegExp("^[^.]*$", "i");
        return Object.values(collection).filter((item) => {
            return keys.some((key) => {
                if (notDotRegEx.test(key)) {
                    return (
                        (!!item[key] && regexp.test(item[key])) ||
                        regexp.test(multipleKeySearch(item).replace(/\s/g, ""))
                    );
                } else {
                    const toSearch = key
                        .split(".")
                        .reduce((o, i) => o[i], item);
                    return (
                        (!!toSearch && regexp.test(toSearch)) ||
                        regexp.test(
                            multipleKeySearch(toSearch).replace(/\s/g, ""),
                        )
                    );
                }
            });
        });
    };
}

export function getBrowserType() {
    var aKeys = ["MSIE", "Firefox", "Safari", "Chrome", "Opera"],
        sUsrAg = navigator.userAgent,
        nIdx = aKeys.length - 1;
    for (nIdx; nIdx > -1 && sUsrAg.indexOf(aKeys[nIdx]) === -1; nIdx--);
    return aKeys[nIdx];
}

export function tagParams(tag, param) {
    switch (tag) {
        case COW.PREGNANT:
            const count = parseInt(((param - Date.now()) / 86400000).toFixed());
            if (count < -15) return null;
            return param ? `${i18n.t("dayLeft", count, { count })}` : null;
        case COW.POST_PARTUM:
            const dayPassed = parseInt(
                ((Date.now() - param) / 86400000).toFixed(),
            );
            return param
                ? `${i18n.t("dayPassed", dayPassed, { dayPassed })}`
                : null;
        case COW.FEVER:
            return param ? ` ${param}` : null;
        case COW.ESTROUS:
            const days = parseInt(((Date.now() - param) / 86400000).toFixed());
            return param ? ` ${i18n.t("daysOfMilk", days, { days })}` : null;
        default:
            return null;
    }
}

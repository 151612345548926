import { connect } from "react-redux";

import { SESSION } from "../constants/schema";

export function sensorsForCow() {
    const mapStateToProps = (
        { sessions, sensors, tags, preview, oldItems },
        { id },
    ) => {
        const cowSensors = Object.keys(sessions)
            .filter((sid) => sessions[sid].cow === id)
            .filter((sid) => {
                if (oldItems.sessionIds.includes(sid)) return false;
                const ct = tags.session[sid].map((t) => t.value);
                return (
                    ct.includes(SESSION.ACTIVE) || ct.includes(SESSION.PENDING)
                );
            })
            .reduce((acc, session) => {
                return [
                    ...acc,
                    {
                        ...sensors[sessions[session].sensor],
                        tags: tags.sensor[sessions[session].sensor],
                        session,
                    },
                ];
            }, []);
        return { sensors: cowSensors, preview };
    };
    return connect(mapStateToProps);
}

import { COW } from "./schema";

const hidden = [
    COW.UNTRACKED,
    COW.TEMPERATURE_TRACKING,
    COW.PREGNANCY_TRACKING,
    COW.TRACKED,
];

export const COW_UI = Object.keys(COW)
    .filter((tagKey) => !hidden.includes(COW[tagKey]))
    .reduce((acc, tagKey) => {
        return { ...acc, [tagKey]: COW[tagKey] };
    }, {});

import React, { useReducer, useEffect } from "react";
import "./PreviewSlider.css";

export function PreviewSlider({
    children,
    position = "rest",
    limitLeft,
    limitRight,
    dragLeftBorder,
    dragRightBorder,
    ...rest
}) {
    const [state, dispatch] = useReducer(dragReducer, {
        position,
        limitLeft,
        limitRight,
        dragLeftBorder,
        dragRightBorder,
    });

    useEffect(() => dispatch({ type: "set-position", position }), [position]);

    const { Overlay, Left } = children.reduce(
        (out, child) => ({
            ...out,
            [child.key]: {
                ...child,
                props: { ...child.props, state, dispatch },
            },
        }),
        {},
    );
    const render = [Left, Overlay];
    return (
        <div data-automation-slider-container {...rest}>
            {render}
        </div>
    );
}

export function Overlay({ children, state, dispatch, ...rest }) {
    function slideHandler(action) {
        dispatch(action);
    }
    const style =
        state.position === "drag-left" || state.position === "drag-right"
            ? { transform: `translateX(${state.delta}px)` }
            : {};
    return (
        <div
            data-automation-slider-overlay={state.position}
            style={style}
            onTouchStart={(event) =>
                slideHandler({ type: "start", touch: event.touches[0] })
            }
            onTouchMove={(event) =>
                slideHandler({
                    type: "move",
                    touch: event.touches[0],
                })
            }
            onTouchEnd={(event) => slideHandler({ type: "end" })}
            onClick={(event) => slideHandler({ type: "click" })}
            {...rest}>
            {children}
        </div>
    );
}

function dragReducer(state, action) {
    let delta = 0;
    switch (`${state.position}/${action.type}`) {
        case "right-to-rest/start":
        case "left-to-rest/start":
        case "back-left-to-rest/start":
        case "back-right-to-rest/start":
        case "rest/start":
            return {
                position: "drag-right",
                limitLeft: state.limitLeft,
                limitRight: state.limitRight,
                dragLeftBorder: state.dragLeftBorder,
                dragRightBorder: state.dragRightBorder,
                startX: action.touch.screenX,
                delta: 0,
                id: action.touch.identifier,
            };
        case "drag-right/move":
            delta =
                action.touch.screenX - state.startX < state.limitRight
                    ? state.limitRight
                    : action.touch.screenX - state.startX;
            return {
                ...state,
                delta,
                position: delta > 0 ? "drag-left" : "drag-right",
            };
        case "drag-left/move":
            delta =
                action.touch.screenX - state.startX > state.limitLeft
                    ? state.limitLeft
                    : action.touch.screenX - state.startX;

            return {
                ...state,
                delta,
                position: delta > 0 ? "drag-left" : "drag-right",
            };

        case "drag-left/end":
            return {
                ...state,
                position:
                    state.delta > state.dragLeftBorder
                        ? "rest-to-left"
                        : "back-left-to-rest",
            };

        case "drag-right/end":
            return {
                ...state,
                position:
                    state.delta < state.dragRightBorder
                        ? "rest-to-right"
                        : "back-right-to-rest",
            };
        case "rest-to-left/start":
        case "rest-to-right/start":
        case "back-rest-to-right/start":
        case "back-rest-to-left/start":
            return {
                ...state,
                delta: 0,
                startX: action.touch.screenX,
                position: "slide-right",
            };
        case "slide-right/move":
            delta =
                action.touch.screenX - state.startX < state.limitRight
                    ? state.limitRight
                    : action.touch.screenX - state.startX;
            return {
                ...state,
                delta,
                position: delta > 0 ? "slide-left" : "slide-right",
            };
        case "slide-left/move":
            delta =
                action.touch.screenX - state.startX > state.limitLeft
                    ? state.limitLeft
                    : action.touch.screenX - state.startX;

            return {
                ...state,
                delta,
                position: delta > 0 ? "slide-left" : "slide-right",
            };
        case "slide-left/end":
            return {
                ...state,
                position:
                    state.delta > state.dragLeftBorder
                        ? "left-to-rest"
                        : "back-rest-to-left",
            };

        case "slide-right/end":
            return {
                ...state,
                position:
                    state.delta < state.dragRightBorder
                        ? "right-to-rest"
                        : "back-rest-to-right",
            };
        case "rest/set-position":
            return { ...state, position: action.position };
        default:
            return state;
    }
}

export function Left({ children, state, dispatch, ...rest }) {
    const position =
        state.position === "slide-left" || state.position === "slide-right";
    const style = position ? { transform: `translateX(${state.delta}px)` } : {};
    return (
        <div
            data-automation-slider-left={state.position}
            style={style}
            onTouchStart={(event) =>
                dispatch({
                    type: "start",
                    touch: event.touches[0],
                })
            }
            onTouchMove={(event) =>
                dispatch({
                    type: "move",
                    touch: event.touches[0],
                })
            }
            onTouchEnd={(event) => dispatch({ type: "end" })}
            {...rest}>
            {children}
        </div>
    );
}

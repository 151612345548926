import { UPDATE_RESOURCE } from "../actions/actionTypes";
import { toList } from "./utilities";

export default function (resource) {
    return function (state = {}, action) {
        if (action.type === UPDATE_RESOURCE && action.resource === resource) {
            return { ...state, ...toList(action.data) };
        }

        if (action.data && action.data[resource]) {
            return { ...state, ...toList(action.data[resource]) };
        }
        return state;
    };
}

import React from "react";
import cn from "classnames";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { togglePreview } from "../../store/actions";
import { SENSOR, REPEATER } from "../../constants";
import { BASESTATION } from "../../constants/schema";

import "./System.css";

const System = ({ onToggle, preview, warning }) => {
    const { t } = useTranslation();
    const previewIsActive = preview && preview.kind === "system";
    return (
        <article
            className={cn("system", {
                preview,
                warning: warning,
                active: previewIsActive,
            })}
            onClick={onToggle}>
            <h5>{t("system")}</h5>
            <div className="image" />
        </article>
    );
};

function mapStateToProps({ preview, sensors, tags }) {
    const sensorsAsGroup = findSystemSensors(sensors, tags);
    return {
        preview,
        warning: detectWarning(sensorsAsGroup, tags.basestation),
    };
}

const mapDispatchToProps = { onToggle: () => togglePreview("system", null) };

export default connect(mapStateToProps, mapDispatchToProps)(System);

function detectWarning(sensorsAsGroup, basestationTags) {
    let offlineSensor = null;
    if (sensorsAsGroup.repeater) {
        offlineSensor = sensorsAsGroup.repeater.find((r) =>
            r.tagValues.includes(REPEATER.OFFLINE),
        );
        if (offlineSensor) return true;
    }
    if (sensorsAsGroup.supervisor) {
        offlineSensor = sensorsAsGroup.supervisor.find((s) =>
            s.tagValues.includes(REPEATER.OFFLINE),
        );
        if (offlineSensor) return true;
    }
    if (!Object.values(basestationTags)[0]) return false;
    const offlineBasestation = Object.values(basestationTags)[0].find(
        (bt) => bt.value === BASESTATION.OFFLINE,
    );
    if (offlineBasestation) return true;
    return false;
}

function findSystemSensors(sensors, tags) {
    const activeSensors = Object.values(sensors).filter((s) => {
        if (!tags.sensor[s.id]) return false;
        return tags.sensor[s.id].find((t) => t.value !== SENSOR.EXCLUDED);
    });
    const ss = activeSensors.reduce((acc, s) => {
        if (!acc[s.type]) acc[s.type] = [];
        const tagValues = tags.sensor[s.id].map((t) => t.value);
        return {
            ...acc,
            [s.type]: [
                ...acc[s.type],
                { ...s, tagValues, tags: tags.sensor[s.id] },
            ],
        };
    }, {});
    return ss;
}

import React, { useContext } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import { RouterContext } from "../../containers/Contexts";

export function ShortcutButton({
    ui,
    uiSwitchFarm,
    uiNotifications,
    shortcutButton,
}) {
    const navigate = useContext(RouterContext);
    function cowList() {
        window.scrollTo(0, 0);
        navigate.forward("/cows");
    }
    function switchFarm() {
        window.scrollTo(0, 0);
        navigate.forward("/switchFarm");
    }
    function notificationSettings() {
        window.scrollTo(0, 0);
        navigate.forward("/settings/notification");
    }
    return (
        <i
            className={cn(
                "shortcut-button",
                shortcutButton == "cowList"
                    ? { "cow-list": true, available: ui == "AVAILABLE" }
                    : shortcutButton == "switchFarm"
                    ? {
                          "switch-farm": true,
                          available: uiSwitchFarm == "AVAILABLE",
                      }
                    : {
                          notifications: true,
                          available: uiNotifications == "AVAILABLE",
                      },
            )}
            onClick={
                shortcutButton == "cowList"
                    ? cowList
                    : shortcutButton == "switchFarm"
                    ? switchFarm
                    : notificationSettings
            }
        />
    );
}
export function mapStateToProps({
    cowListFilter,
    yourProfile,
    dashboardSettings,
    switchFarm,
    notificationSettings,
}) {
    return {
        ui: cowListFilter.ui,
        uiSwitchFarm: switchFarm.ui,
        uiNotifications: notificationSettings.ui,
        shortcutButton: dashboardSettings.shortcutButton,
        uiYourProfile: yourProfile.ui,
    };
}

export default connect(mapStateToProps, null)(ShortcutButton);

import { togglePreview } from "../../../store/actions";
import {
    TOGGLE_PREVIEW,
    HIDE_PAIRING_UI,
} from "../../../store/actions/actionTypes";

import {
    advanceOnClick,
    stepNamespace,
    waitForState,
    media,
    buttons,
    delay,
} from ".";

const step = stepNamespace("pairing-list");

export default function pairingList() {
    return [
        {
            ...step("open"),
            ...advanceOnClick("button.new-pairing-list"),
            revert(tour) {
                tour.options.dispatch({
                    type: TOGGLE_PREVIEW,
                    kind: "cow",
                    id: "2",
                });
            },
        },
        {
            ...step("intro"),
            beforeShowPromise: delay(800),
            attachTo: { element: "aside.pairing" },
            revert(tour) {
                tour.options.dispatch({ type: HIDE_PAIRING_UI });
            },
        },
        {
            ...step("focus-cow"),
            ...advanceOnClick("aside.pairing .search-box.cow input", {
                event: "focus",
            }),
        },
        {
            ...step("search-cow"),
            ...advanceOnClick(
                "aside.pairing .search-box.cow ul.search-results",
            ),
            ...forceStyle(
                "aside.pairing .search-box.cow ul.search-results",
                "display",
                "block",
            ),
        },
        {
            ...step("focus-sensor"),
            ...advanceOnClick(
                "aside.pairing .search-box.sensor input",
                { event: "focus" },
                false,
            ),
        },
        {
            ...step("search-sensor"),
            ...advanceOnClick(
                "aside.pairing .search-box.sensor ul.search-results li:first-of-type",
            ),
            attachTo: {
                element: "aside.pairing .search-box.sensor ul.search-results",
                on: "bottom",
            },
            ...forceStyle(
                "aside.pairing .search-box.sensor ul.search-results",
                "display",
                "block",
            ),
        },
        {
            ...step("green"),
            attachTo: {
                element: "aside.pairing .pairing-box.ready",
                on: "bottom",
            },
            buttons: [buttons.exit, buttons.next],
        },
        {
            ...step("more"),
            attachTo: {
                element: "aside.pairing .pairing-box:last-child",
                on: "bottom",
            },
        },
        {
            ...step("submit"),
            ...advanceOnClick("aside.pairing footer button.submit"),
        },
        {
            ...step("box"),
            ...advanceOnClick("article.pairing-list", {}, false),
            ...waitForState(({ pairing }) => pairing.ui === "AVAILABLE"),
        },
        {
            ...step("preview"),
            ...waitForState(({ preview }) => preview.kind === "list"),
            attachTo: { element: "aside.pairing-list-preview", on: "bottom" },
            buttons: [buttons.exit, buttons.next],
        },
        {
            ...step("pdf"),
            showOn: media("(pointer: fine)"),
            attachTo: {
                element: "aside.pairing-list-preview .download-pdf",
                on: "bottom",
            },
        },
        {
            ...step("ending"),
            attachTo: { element: "article.pairing-list", on: "bottom" },
            when: {
                hide() {
                    this.tour.options.dispatch(togglePreview());
                },
            },
        },
        {
            ...step("canceling"),
            attachTo: { element: "article.cow.pending", on: "bottom" },
        },
    ];
}

function forceStyle(selector, style, value) {
    return {
        when: {
            "before-show"() {
                this.results = document.querySelector(selector);
                this.results.style[style] = value;
            },
            hide() {
                this.results.style[style] = undefined;
            },
        },
    };
}

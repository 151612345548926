
import { Model } from "miragejs";

export default {
    farm: Model,
    event: Model,
    cow: Model,
    sensor: Model,
    session: Model,
    pairingList: Model
};
import { UPDATE_COWS_FROM_COWLIST, RESET_COWS_FROM_COWLIST } from "../../actions/actionTypes";

const initialState = [];

export default function reduce( cowsFromCowlist = initialState, action) {
    switch (action.type) {
    case UPDATE_COWS_FROM_COWLIST: return [ ...action.cows ];
    case RESET_COWS_FROM_COWLIST: return [];
    default: return cowsFromCowlist;
    }
}

import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { sendNewSmartNote } from "../../store/actions";
import Time from "../Time";

import { VARIABLES } from "./constants";
import { TimeVariable, VariableSingle } from "./common";
import "./NewSmartNote.css";

export function HeatConfirm({
    id,
    toogleSmartNote,
    sendNewSmartNote,
    about,
    parentEventTagItems,
    defaults,
    isResult,
}) {
    const { t } = useTranslation();
    const smartNoteRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", onClickOutside, true);
        return () => {
            document.removeEventListener("click", onClickOutside, true);
        };
    });

    function onClickOutside(event) {
        if (
            smartNoteRef.current &&
            !smartNoteRef.current.contains(event.target)
        ) {
            toogleSmartNote(false);
        }
    }

    const [state, setState] = useState(defaults);

    return (
        <div className="smart-note confirm-heat" ref={smartNoteRef}>
            <div className={cn("note", { result: isResult })}>
                <InseminationTime
                    value={Time({
                        time: state.inseminationTime,
                        options: {
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                        },
                    })}
                    label={`${t("smartNote:headers:inseminationTime")}:`}
                    date={new Date(state.inseminationTime)}
                    setTime={(value) =>
                        setState({ ...state, inseminationTime: value })
                    }
                />
                <VariableSingle
                    value={
                        state.isInseminated
                            ? state.isInseminated
                            : VARIABLES.isInseminated.default
                    }
                    label={`${t("smartNote:headers:isInseminated")}:`}
                    selected={!!state.isInseminated}
                    options={Object.values(VARIABLES.isInseminated.options)}
                    onSelected={(option) =>
                        setState({ ...state, isInseminated: option.value })
                    }
                />
            </div>
            {!isResult ? (
                <i
                    className={cn("envelope completed")}
                    onClick={() => {
                        sendNewSmartNote(about, parentEventTagItems, state);
                    }}
                />
            ) : null}
        </div>
    );
}

function mapStateToProps({ events }, { parentEventTagItems, defaults }) {
    const isResult = defaults;
    if (!isResult) {
        defaults = {
            cow: parentEventTagItems.cow,
            inseminationTime: new Date().getTime(),
            isInseminated: VARIABLES.isInseminated.default,
        };
    }
    return { defaults, isResult };
}

const mapDispatchToProps = { sendNewSmartNote };

export default connect(mapStateToProps, mapDispatchToProps)(HeatConfirm);

function InseminationTime({ label, value, date, setTime }) {
    return (
        <div className="variable-container">
            <h4>{label}</h4>
            <TimeVariable value={value} date={date} setTime={setTime} />
        </div>
    );
}

import { combineReducers } from "redux";

import { default as variables } from "./variables";
import { default as options } from "./options";
import { default as current } from "./about";
import { default as text } from "./text";
import { default as variableOptions } from "./variableOptions";

export default combineReducers({
    current,
    variables,
    options,
    text,
    variableOptions
});

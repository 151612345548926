import { connect } from "react-redux";

import {
    inputLoginEmail,
    submitLoginEmail,
    tryAnotherLoginEmail,
    googleLogin,
} from "../store/actions";

function mapStateToProps({ loginForm: { email } }) {
    return { email };
}

const mapDispatchToProps = {
    inputEmail: inputLoginEmail,
    submitEmail: submitLoginEmail,
    tryAnotherLoginEmail: tryAnotherLoginEmail,
    googleLogin,
};

export default connect(mapStateToProps, mapDispatchToProps);

import {
    put,
    take,
    spawn,
    call,
    select
} from "redux-saga/effects";
import {
    SET_LANGUAGE_REQUEST,
    SET_LANGUAGE,
    SET_CONFIGS
} from "../actions/actionTypes";
import axios from "axios";

export function *language(){
    const { configs } = yield take(SET_CONFIGS);
    const langConfig = configs.find(config => config.name == "LANGUAGE");
    if (langConfig){
        const lang = langConfig.value;
        yield put({
            type: SET_LANGUAGE,
            lang
        });
        const oldLang = yield localStorage.getItem("language", lang);
        yield localStorage.setItem("language", lang);
        yield spawn(setLanguage);
        if (oldLang != lang) window.location.reload();
    }
    yield spawn(language);
}

function *setLanguage(){
    const { lang } = yield take(SET_LANGUAGE_REQUEST);
    const configs = yield select(state => state.configs);
    try {
        yield call(axios.put, "/users/config", [{ ...configs.find(c => c.name == "LANGUAGE"), value: lang }]);
    } catch (error) {
        console.warn("Error on updating config");
    }
    yield localStorage.setItem("language", lang);
    window.location.reload();
    yield spawn(setLanguage);
}

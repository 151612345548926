import i18n from "../../i18n";
export const messages = {
    1: i18n.t("farmNumberIsRequired"),
    2: i18n.t("thisFarmNumberIsAlreadyAssigned"),
    3: i18n.t("farmNumbersCanContainAtMost5Characters"),
    4: i18n.t("thisNameIsAlreadyGiven"),
    5: i18n.t("identityNumberIsRequired"),
    6: i18n.t("thisIdentityNumberIsAlreadyAssigned"),
    7: i18n.t("dateOfBirthIsRequired"),
    8: i18n.t("birthDateShouldBeAValidDate"),
};

import {
    COMPLETE_POST_STARTUP,
    SHOW_PAIRING_UI,
    HIDE_PAIRING_UI,
    SUBMIT_PAIRING_LIST,
    COMPLETE_PAIRING_LIST_REQUEST,
    FAIL_PAIRING_LIST_REQUEST,
} from "../../actions/actionTypes";

export default function (state = "UNAVAILABLE", action) {
    switch (action.type) {
    case COMPLETE_POST_STARTUP: {
        return "AVAILABLE";
    }
    case SHOW_PAIRING_UI: {
        if (state === "AVAILABLE") { return "VISIBLE"; } else { break; }
    }
    case HIDE_PAIRING_UI: {
        if (state === "VISIBLE") { return "AVAILABLE"; } else { break; }
    }
    case SUBMIT_PAIRING_LIST: {
        return "DISABLED";
    }
    case COMPLETE_PAIRING_LIST_REQUEST: {
        return "AVAILABLE";
    }
    case FAIL_PAIRING_LIST_REQUEST: {
        return "ERROR";
    }
    default: return state;
    }
    return state;
}

// Generates a reducer that uses another reducer
// to process a state based on specified key in the action
export default function (key, reducer, types) {
    return function (state = {}, action) {
        if (action[key] && types.includes(action.type)) {
            return {
                ...state,
                [action[key]]: reducer(state[action[key]], action)
            };
        }
        return state;
    };
}

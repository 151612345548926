import {
    START_ADD_COW,
    COMPLETE_ADD_COW,
    FAIL_ADD_COW,
    EDIT_COW,
    START_REMOVE_COW,
    COMPLETE_REMOVE_COW,
    FAIL_REMOVE_COW,
    RESET_REMOVE_COW,
    ADD_NEW_COW
} from "../actions/actionTypes";

export default function (state = { cow: null, status: "initial", error: null }, action) {
    switch (action.type) {
    case START_REMOVE_COW:
    case START_ADD_COW:
        return { ...state, status: "pending" };
    case COMPLETE_REMOVE_COW:
    case COMPLETE_ADD_COW:
        return { ...state, status: "done" };
    case FAIL_REMOVE_COW:
    case FAIL_ADD_COW:
        return { ...state, status: "failed", error: action.error };
    case RESET_REMOVE_COW:
        return { ...state, status: "initial" };
    case EDIT_COW:
        return { ...state, cow: action.cow };
    case ADD_NEW_COW:
        return { ...state, cow: null };
    default: return state;
    }
}

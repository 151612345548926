import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import Toast from "../Toast";
import { RouterContext } from "../../containers/Contexts";

import ActivationLoader from "./ActivationLoader";

import "./NewPairingListButton.css";

export function NewPairingListButton({ uiState, pairingEnabled }) {
    const navigate = useContext(RouterContext);
    const [toast, setToast] = useState(false);
    function onClickLocal() {
        if (!pairingEnabled) {
            setToast(true);
            return;
        }
        window.scrollTo(0, 0);
        navigate.forward("/pairing");
    }
    if (!pairingEnabled) {
        return (
            <>
                <button
                    className={cn("new-pairing-list", {
                        disabled: !pairingEnabled,
                    })}
                    onClick={onClickLocal}
                />
                {toast ? <Toast onFinish={() => setToast(false)} /> : null}
            </>
        );
    } else if (uiState === "AVAILABLE") {
        return (
            <button
                className="new-pairing-list"
                onClick={() => {
                    window.scrollTo(0, 0);
                    navigate.forward("/pairing");
                }}
            />
        );
    } else if (uiState === "UNAVAILABLE") {
        return (
            <div className="loader">
                <ActivationLoader className="new-pairing-list" />
            </div>
        );
    }
    return null;
}

function mapStateToProps(state) {
    return {
        uiState: state.pairing.ui,
        pairingEnabled: state.priviliges.PAIRING_ENABLED
            ? state.priviliges.PAIRING_ENABLED.value
            : false,
    };
}

export default connect(mapStateToProps)(NewPairingListButton);

import { ADD_PAIRING, DROP_PAIRING, UPDATE_PAIRING_AVAILABILITY } from "../../actions/actionTypes";

const initialState = {
    available: {
        cows: [],
        sensors: []
    },
    paired: []
};

export default function reduce({ available: { cows, sensors }, paired } = initialState, action) {
    switch (action.type) {
    case ADD_PAIRING: {
        const { cow, sensor } = action;
        if (!cows.includes(cow)) { break; }
        if (!sensors.includes(sensor)) { break; }
        return {
            available: {
                cows: cows,
                sensors: drop(sensors, sensor)
            },
            paired: [ ...paired, { cow, sensor } ]
        };
    }
    case DROP_PAIRING: {
        const { cow, sensor } = action;
        return {
            available: {
                cows: cows,
                sensors: [ ...sensors, sensor ],
            },
            paired: paired.filter(({ cow: c, sensor: s }) => sensor !== s || cow !== c)
        };
    }
    case UPDATE_PAIRING_AVAILABILITY: {
        const { cows, sensors } = action;
        let newState = {
            available: { cows, sensors },
            paired: []
        };
        for (const { cow, sensor } of paired) {
            newState = reduce(newState, { type: ADD_PAIRING, cow, sensor });
        }
        return newState;
    }
    default: return { available: { cows, sensors }, paired };
    }
}

function drop(array, element) {
    return array.filter((item) => item !== element);
}

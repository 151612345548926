import { default as ConfirmCalving } from "./ConfirmCalving";
import { default as AbortCalving } from "./AbortCalving";
import { default as SensorFallHeat } from "./SensorFallHeat";
import { default as SensorFallHealth } from "./SensorFallHealth";
import { default as FeverOver } from "./FeverOver";
import { default as HeatConfirm } from "./HeatConfirm";

export default {
    "calving-detected::confirm": ConfirmCalving,
    "calving-detected::abort": AbortCalving,
    "sensor-fall-detected::heat": SensorFallHeat,
    "sensor-fall-detected::health": SensorFallHealth,
    "fever-over::treatment": FeverOver,
    "heat-detected::confirm": HeatConfirm,
};

import {
    SET_DIALOG,
    CLEAR_DIALOG,
    FAIL_REMOVE_COW
} from "../actions/actionTypes";

export default function (state = { type: null, confirm: null, cancel: null, text: null }, action) {
    switch (action.type) {
    case SET_DIALOG:
        return { ...state, ...action.dialog };
    case CLEAR_DIALOG:
        return { type: null, confirm: null, cancel: null };
    case FAIL_REMOVE_COW:
        return { type: "error", text: "Failed to remove the cow. Please make sure cow is not paired with any sensors" };
    default: return state;
    }
}

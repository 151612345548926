import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import { EVENTS, READSTATE } from "../constants/schema";
import events from "./events";
import eventsUpdate from "./eventsUpdate";
import temperature from "./temperature";

const mock = new MockAdapter(axios, { delayResponse: 2000 });

let nextEvent = 10;
// const singleUseToken = "2309rfu2ugf032ufei023f1uh3gf023u4thu302";
const longTermToken = "u24gur032ufh0139uf2h12irfh34h1349trh4gf";

window.useAxiosMock = () => {
    window.localStorage.setItem("AxiosMock", true);
    mock
        .onPost("/login").reply(200)
        .onGet("/login").replyOnce(200, { token: longTermToken })
        .onGet("/events").replyOnce(200, events)
        .onGet("/events").replyOnce(200, eventsUpdate)
        .onGet("/events").reply(200, { cows: [], events: [], tsenses: [], users: [] })

        .onPut("/mark-read").replyOnce(500, "I am a mock server dammit!")
        .onPut("/mark-read").reply(config => [200, config.data])

        .onPost("/events").replyOnce(500, "I am a mock server dammit!")
        .onPost("/events").reply(config => [
            200,
            {
                event: {
                    id: `e${nextEvent++}`,
                    type: EVENTS.NOTE_SUBMITTED,
                    timestamp: Date.now(),
                    readState: READSTATE.READ,
                    payload: {
                        user: "u1",
                        ...JSON.parse(config.data).payload //pass in the same event
                    }
                }
            }
        ])
        .onDelete("/login").replyOnce(204)
        .onGet("/temperature/c1").replyOnce(500, "I am a mock server dammit!")
        .onGet("/temperature/c2").replyOnce(200, temperature)
        .onGet("/temperature/c2").reply(200, { timestamp: Date.now(), period: 720000, temperature: [] });
};

window.bypassAxiosMock = () => {
    window.localStorage.removeItem("AxiosMock");
    mock.reset();
    mock.onAny().passThrough();
};

if (window.localStorage.getItem("AxiosMock")) { window.useAxiosMock(); } else { window.bypassAxiosMock(); }

import { EVENTS } from "../../../../constants";
export default [
    [26.29, EVENTS.SENSOR_ACTIVATED],
    [26.39, EVENTS.STABLE_DATA_DETECTED],
    [27.32],
    [28.52],
    [29.67],
    [30.78],
    [31.75],
    [32.62],
    [33.39],
    [34.05],
    [34.62],
    [35.15],
    [35.61],
    [36.0],
    [36.38],
    [36.67],
    [36.95],
    [37.18],
    [37.39],
    [37.58],
    [37.74],
    [38.02],
    [38.14],
    [38.21],
    [38.300000000000004],
    [38.39],
    [38.45],
    [38.49],
    [38.54],
    [38.6],
    [38.64],
    [38.68],
    [38.69],
    [38.74],
    [38.75],
    [38.800000000000004],
    [38.83],
    [38.86],
    [38.89],
    [38.9],
    [38.910000000000004],
    [38.94],
    [38.96],
    [38.96],
    [39.0],
    [39.01],
    [39.06],
    [39.08],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.13],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.22],
    [39.25],
    [39.26],
    [39.26],
    [39.28],
    [39.31],
    [39.34],
    [39.35],
    [39.38],
    [39.39],
    [39.4],
    [39.410000000000004],
    [39.410000000000004],
    [39.43],
    [39.44],
    [39.43],
    [39.44],
    [39.46],
    [39.46],
    [39.46],
    [39.44],
    [39.44],
    [39.44],
    [39.42],
    [39.44],
    [39.42],
    [39.43],
    [39.42],
    [39.42],
    [39.410000000000004],
    [39.42],
    [39.42],
    [39.42],
    [39.410000000000004],
    [39.4],
    [39.4],
    [39.410000000000004],
    [39.4],
    [39.39],
    [39.37],
    [39.37],
    [39.37],
    [39.38],
    [39.38],
    [39.36],
    [39.36],
    [39.36],
    [39.36],
    [39.35],
    [39.35],
    [39.34],
    [39.33],
    [39.34],
    [39.35],
    [39.34],
    [39.33],
    [39.34],
    [39.34],
    [39.32],
    [39.33],
    [39.33],
    [39.31],
    [39.32],
    [39.32],
    [39.35],
    [39.33],
    [39.35],
    [39.34],
    [39.35],
    [39.34],
    [39.34],
    [39.33],
    [39.33],
    [39.34],
    [39.33],
    [39.33],
    [39.32],
    [39.32],
    [39.32],
    [39.33],
    [39.32],
    [39.32],
    [39.32],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.29],
    [39.27],
    [39.29],
    [39.29],
    [39.28],
    [39.29],
    [39.28],
    [39.29],
    [39.29],
    [39.29],
    [39.29],
    [39.300000000000004],
    [39.29],
    [39.28],
    [39.42],
    [39.39],
    [39.39],
    [39.410000000000004],
    [39.4],
    [39.42],
    [39.410000000000004],
    [39.42],
    [39.410000000000004],
    [39.42],
    [39.410000000000004],
    [39.44],
    [39.42],
    [39.42],
    [39.46],
    [39.45],
    [39.44],
    [39.45],
    [39.45],
    [39.43],
    [39.42],
    [39.45],
    [39.46],
    [39.45],
    [39.44],
    [39.43],
    [39.46],
    [39.45],
    [39.45],
    [39.46],
    [39.43],
    [39.45],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.42],
    [39.42],
    [39.42],
    [39.43],
    [39.43],
    [39.42],
    [39.43],
    [39.43],
    [39.42],
    [39.42],
    [39.42],
    [39.4],
    [39.4],
    [39.39],
    [39.38],
    [39.36],
    [39.35],
    [39.35],
    [39.32],
    [39.32],
    [39.32],
    [39.31],
    [39.29],
    [39.28],
    [39.28],
    [39.26],
    [39.25],
    [39.24],
    [39.21],
    [39.22],
    [39.2],
    [39.2],
    [39.17],
    [39.17],
    [39.15],
    [39.13],
    [39.12],
    [39.1],
    [39.1],
    [39.08],
    [39.07],
    [39.08],
    [39.050000000000004],
    [39.06],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.01],
    [39.03],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [38.99],
    [38.97],
    [39.0],
    [39.0],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.01],
    [39.03],
    [39.02],
    [39.02],
    [39.02],
    [39.03],
    [39.04],
    [39.04],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.07],
    [39.06],
    [39.09],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.12],
    [39.12],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.18],
    [39.17],
    [39.18],
    [39.18],
    [39.18],
    [39.2],
    [39.2],
    [39.21],
    [39.21],
    [39.19],
    [39.21],
    [39.19],
    [39.2],
    [39.2],
    [39.21],
    [39.2],
    [39.2],
    [39.19],
    [39.19],
    [39.18],
    [39.19],
    [39.2],
    [39.18],
    [39.17],
    [39.18],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.14],
    [39.11],
    [39.09],
    [39.06],
    [39.04],
    [39.02],
    [39.0],
    [38.980000000000004],
    [39.0],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.96],
    [38.94],
    [38.92],
    [38.94],
    [38.94],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.87],
    [38.9],
    [38.88],
    [38.89],
    [38.88],
    [38.87],
    [38.86],
    [38.86],
    [38.85],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.83],
    [38.83],
    [38.84],
    [38.83],
    [38.84],
    [38.84],
    [38.82],
    [38.84],
    [38.82],
    [38.82],
    [38.83],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.82],
    [38.79],
    [38.79],
    [38.79],
    [38.800000000000004],
    [38.800000000000004],
    [38.77],
    [38.79],
    [38.800000000000004],
    [38.800000000000004],
    [38.800000000000004],
    [38.82],
    [38.81],
    [38.82],
    [38.83],
    [38.83],
    [38.82],
    [38.83],
    [38.83],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.88],
    [38.86],
    [38.87],
    [38.86],
    [38.85],
    [38.86],
    [38.86],
    [38.86],
    [38.86],
    [38.85],
    [38.84],
    [38.84],
    [38.85],
    [38.84],
    [38.84],
    [38.85],
    [38.83],
    [38.84],
    [38.85],
    [38.83],
    [38.83],
    [38.84],
    [38.83],
    [38.82],
    [38.82],
    [38.83],
    [38.84],
    [38.84],
    [38.85],
    [38.84],
    [38.84],
    [38.86],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.86],
    [38.85],
    [38.86],
    [38.86],
    [38.86],
    [38.87],
    [38.86],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.87],
    [38.86],
    [38.85],
    [38.88],
    [38.89],
    [38.87],
    [38.86],
    [38.87],
    [38.87],
    [38.87],
    [38.87],
    [38.85],
    [38.85],
    [38.84],
    [38.83],
    [38.81],
    [38.82],
    [38.81],
    [38.79],
    [38.78],
    [38.78],
    [38.77],
    [38.77],
    [38.78],
    [38.76],
    [38.77],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.75],
    [38.76],
    [38.75],
    [38.76],
    [38.78],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.800000000000004],
    [38.79],
    [38.800000000000004],
    [38.81],
    [38.82],
    [38.79],
    [38.79],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.77],
    [38.77],
    [38.76],
    [38.76],
    [38.75],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.72],
    [38.730000000000004],
    [38.74],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.71],
    [38.72],
    [38.72],
    [38.72],
    [38.71],
    [38.71],
    [38.72],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.74],
    [38.76],
    [38.76],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.84],
    [38.85],
    [38.85],
    [38.86],
    [38.87],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.94],
    [38.92],
    [38.93],
    [38.95],
    [38.93],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.980000000000004],
    [38.97],
    [38.99],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.01],
    [39.01],
    [39.01],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [38.99],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.95],
    [38.94],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.86],
    [38.85],
    [38.86],
    [38.85],
    [38.82],
    [38.83],
    [38.81],
    [38.81],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.77],
    [38.79],
    [38.78],
    [38.79],
    [38.82],
    [38.82],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.87],
    [38.85],
    [38.86],
    [38.86],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.86],
    [38.87],
    [38.85],
    [38.87],
    [38.89],
    [38.89],
    [38.87],
    [38.89],
    [38.89],
    [38.86],
    [38.88],
    [38.89],
    [38.87],
    [38.86],
    [38.86],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.82],
    [38.83],
    [38.82],
    [38.81],
    [38.78],
    [38.800000000000004],
    [38.78],
    [38.78],
    [38.76],
    [38.77],
    [38.76],
    [38.76],
    [38.76],
    [38.78],
    [38.76],
    [38.78],
    [38.76],
    [38.77],
    [38.77],
    [38.78],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.76],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.78],
    [38.78],
    [38.78],
    [38.76],
    [38.76],
    [38.75],
    [38.75],
    [38.72],
    [38.71],
    [38.7],
    [38.67],
    [38.660000000000004],
    [38.65],
    [38.63],
    [38.63],
    [38.6],
    [38.6],
    [38.59],
    [38.6],
    [38.57],
    [38.57],
    [38.58],
    [38.57],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.57],
    [38.58],
    [38.58],
    [38.59],
    [38.58],
    [38.6],
    [38.6],
    [38.59],
    [38.6],
    [38.61],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.660000000000004],
    [38.660000000000004],
    [38.68],
    [38.67],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.65],
    [38.67],
    [38.660000000000004],
    [38.660000000000004],
    [38.65],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.64],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.67],
    [38.65],
    [38.64],
    [38.67],
    [38.65],
    [38.64],
    [38.65],
    [38.67],
    [38.67],
    [38.67],
    [38.68],
    [38.69],
    [38.69],
    [38.7],
    [38.7],
    [38.71],
    [38.71],
    [38.7],
    [38.7],
    [38.7],
    [38.7],
    [38.71],
    [38.7],
    [38.71],
    [38.7],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.7],
    [38.72],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.75],
    [38.75],
    [38.730000000000004],
    [38.74],
    [38.74],
    [38.74],
    [38.730000000000004],
    [38.75],
    [38.71],
    [38.72],
    [38.72],
    [38.72],
    [38.730000000000004],
    [38.71],
    [38.730000000000004],
    [38.71],
    [38.72],
    [38.71],
    [38.71],
    [38.69],
    [38.7],
    [38.69],
    [38.68],
    [38.7],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.67],
    [38.7],
    [38.69],
    [38.71],
    [38.69],
    [38.7],
    [38.7],
    [38.68],
    [38.67],
    [38.7],
    [38.68],
    [38.68],
    [38.67],
    [38.67],
    [38.69],
    [38.7],
    [38.71],
    [38.71],
    [38.72],
    [38.72],
    [38.74],
    [38.730000000000004],
    [38.74],
    [38.730000000000004],
    [38.75],
    [38.75],
    [38.730000000000004],
    [38.730000000000004],
    [38.74],
    [38.74],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.71],
    [38.67],
    [38.69],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.68],
    [38.67],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.68],
    [38.67],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.67],
    [38.65],
    [38.65],
    [38.63],
    [38.63],
    [38.61],
    [38.6],
    [38.57],
    [38.550000000000004],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.51],
    [38.51],
    [38.51],
    [38.53],
    [38.52],
    [38.53],
    [38.54],
    [38.54],
    [38.54],
    [38.54],
    [38.550000000000004],
    [38.550000000000004],
    [38.57],
    [38.56],
    [38.57],
    [38.56],
    [38.58],
    [38.58],
    [38.57],
    [38.58],
    [38.58],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.61],
    [38.6],
    [38.62],
    [38.61],
    [38.62],
    [38.62],
    [38.63],
    [38.62],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.67],
    [38.7],
    [38.69],
    [38.71],
    [38.74],
    [38.75],
    [38.72],
    [38.77],
    [38.75],
    [38.77],
    [38.78],
    [38.79],
    [38.79],
    [38.81],
    [38.82],
    [38.82],
    [38.81],
    [38.83],
    [38.83],
    [38.83],
    [38.85],
    [38.83],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.87],
    [38.88],
    [38.87],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.9],
    [38.89],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.87],
    [38.85],
    [38.87],
    [38.86],
    [38.85],
    [38.85],
    [38.87],
    [38.88],
    [38.86],
    [38.86],
    [38.88],
    [38.89],
    [38.9],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.94],
    [38.92],
    [38.92],
    [38.96],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.980000000000004],
    [39.0],
    [39.01],
    [39.02],
    [39.01],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.1],
    [39.1],
    [39.08],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.11],
    [39.09],
    [39.1],
    [39.1],
    [39.08],
    [39.09],
    [39.07],
    [39.07],
    [39.06],
    [39.06],
    [39.06],
    [39.03],
    [39.02],
    [39.01],
    [39.01],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.99],
    [38.99],
    [38.97],
    [38.97],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.92],
    [38.92],
    [38.94],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.95],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.95],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.97],
    [38.97],
    [38.96],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.02],
    [39.01],
    [39.0],
    [39.01],
    [39.01],
    [39.0],
    [39.0],
    [38.99],
    [38.99],
    [39.0],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.980000000000004],
    [38.99],
    [38.96],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.94],
    [38.92],
    [38.92],
    [38.89],
    [38.89],
    [38.84],
    [38.82],
    [38.78],
    [38.78],
    [38.75],
    [38.74],
    [38.74],
    [38.71],
    [38.69],
    [38.69],
    [38.68],
    [38.65],
    [38.64],
    [38.61],
    [38.59],
    [38.58],
    [38.56],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.5],
    [38.52],
    [38.49],
    [38.480000000000004],
    [38.46],
    [38.47],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.45],
    [38.45],
    [38.44],
    [38.44],
    [38.44],
    [38.45],
    [38.43],
    [38.42],
    [38.44],
    [38.45],
    [38.43],
    [38.44],
    [38.44],
    [38.43],
    [38.45],
    [38.46],
    [38.46],
    [38.45],
    [38.46],
    [38.46],
    [38.480000000000004],
    [38.47],
    [38.47],
    [38.49],
    [38.49],
    [38.5],
    [38.51],
    [38.5],
    [38.5],
    [38.52],
    [38.51],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.56],
    [38.57],
    [38.59],
    [38.6],
    [38.63],
    [38.64],
    [38.65],
    [38.67],
    [38.69],
    [38.7],
    [38.72],
    [38.75],
    [38.75],
    [38.77],
    [38.76],
    [38.79],
    [38.81],
    [38.82],
    [38.82],
    [38.85],
    [38.85],
    [38.87],
    [38.89],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.93],
    [38.96],
    [38.94],
    [38.97],
    [38.96],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.04],
    [39.03],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.09],
    [39.09],
    [39.08],
    [39.1],
    [39.08],
    [39.09],
    [39.1],
    [39.08],
    [39.09],
    [39.1],
    [39.08],
    [39.1],
    [39.09],
    [39.11],
    [39.1],
    [39.1],
    [39.11],
    [39.12],
    [39.11],
    [39.14],
    [39.13],
    [39.12],
    [39.13],
    [39.14],
    [39.14],
    [39.15],
    [39.15],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.160000000000004],
    [39.18],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.11],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.08],
    [39.08],
    [39.08],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.08],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.13],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.19],
    [39.18],
    [39.19],
    [39.19],
    [39.17],
    [39.17],
    [39.15],
    [39.13],
    [39.12],
    [39.13],
    [39.08],
    [39.07],
    [39.08],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.02],
    [39.01],
    [38.99],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.97],
    [38.96],
    [38.94],
    [38.95],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.88],
    [38.87],
    [38.89],
    [38.88],
    [38.87],
    [38.87],
    [38.88],
    [38.88],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.9],
    [38.9],
    [38.89],
    [38.89],
    [38.9],
    [38.89],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.94],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.97],
    [38.99],
    [38.980000000000004],
    [38.97],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.97],
    [38.99],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.09],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.01],
    [39.02],
    [39.0],
    [38.99],
    [38.99],
    [38.980000000000004],
    [38.96],
    [38.95],
    [38.95],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.93],
    [38.93],
    [38.94],
    [38.96],
    [38.97],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.95],
    [38.95],
    [38.93],
    [38.93],
    [38.94],
    [38.94],
    [38.92],
    [38.95],
    [38.93],
    [38.94],
    [38.94],
    [38.95],
    [38.97],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.99],
    [38.980000000000004],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.01],
    [39.03],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.06],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.09],
    [39.08],
    [39.1],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.08],
    [39.08],
    [39.06],
    [39.07],
    [39.050000000000004],
    [39.03],
    [39.0],
    [38.96],
    [38.94],
    [38.93],
    [38.910000000000004],
    [38.89],
    [38.88],
    [38.87],
    [38.85],
    [38.84],
    [38.82],
    [38.82],
    [38.82],
    [38.81],
    [38.81],
    [38.800000000000004],
    [38.78],
    [38.77],
    [38.78],
    [38.75],
    [38.75],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.71],
    [38.72],
    [38.71],
    [38.72],
    [38.71],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.730000000000004],
    [38.730000000000004],
    [38.730000000000004],
    [38.72],
    [38.730000000000004],
    [38.74],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.77],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.78],
    [38.76],
    [38.75],
    [38.75],
    [38.74],
    [38.72],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.67],
    [38.68],
    [38.68],
    [38.71],
    [38.67],
    [38.69],
    [38.67],
    [38.69],
    [38.69],
    [38.67],
    [38.68],
    [38.7],
    [38.7],
    [38.69],
    [38.71],
    [38.72],
    [38.71],
    [38.730000000000004],
    [38.730000000000004],
    [38.730000000000004],
    [38.74],
    [38.74],
    [38.76],
    [38.75],
    [38.75],
    [38.77],
    [38.78],
    [38.79],
    [38.78],
    [38.78],
    [38.82],
    [38.800000000000004],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.82],
    [38.84],
    [38.84],
    [38.86],
    [38.85],
    [38.88],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.92],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.93],
    [38.94],
    [38.95],
    [38.93],
    [38.93],
    [38.93],
    [38.92],
    [38.92],
    [38.9],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.84],
    [38.84],
    [38.85],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.81],
    [38.81],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.76],
    [38.74],
    [38.71],
    [38.72],
    [38.71],
    [38.69],
    [38.69],
    [38.67],
    [38.660000000000004],
    [38.65],
    [38.63],
    [38.62],
    [38.62],
    [38.61],
    [38.6],
    [38.6],
    [38.59],
    [38.57],
    [38.56],
    [38.57],
    [38.56],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.53],
    [38.51],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.57],
    [38.56],
    [38.56],
    [38.54],
    [38.550000000000004],
    [38.56],
    [38.56],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.56],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.550000000000004],
    [38.56],
    [38.57],
    [38.58],
    [38.59],
    [38.6],
    [38.61],
    [38.63],
    [38.63],
    [38.64],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.63],
    [38.61],
    [38.63],
    [38.63],
    [38.63],
    [38.64],
    [38.63],
    [38.64],
    [38.64],
    [38.64],
    [38.64],
    [38.660000000000004],
    [38.67],
    [38.68],
    [38.68],
    [38.68],
    [38.68],
    [38.7],
    [38.7],
    [38.71],
    [38.71],
    [38.7],
    [38.7],
    [38.69],
    [38.69],
    [38.68],
    [38.68],
    [38.65],
    [38.64],
    [38.64],
    [38.62],
    [38.62],
    [38.6],
    [38.59],
    [38.59],
    [38.57],
    [38.56],
    [38.56],
    [38.56],
    [38.550000000000004],
    [38.56],
    [38.54],
    [38.54],
    [38.52],
    [38.52],
    [38.550000000000004],
    [38.56],
    [38.550000000000004],
    [38.58],
    [38.58],
    [38.57],
    [38.57],
    [38.61],
    [38.61],
    [38.62],
    [38.62],
    [38.64],
    [38.63],
    [38.62],
    [38.62],
    [38.6],
    [38.59],
    [38.59],
    [38.59],
    [38.58],
    [38.57],
    [38.57],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.53],
    [38.53],
    [38.54],
    [38.54],
    [38.53],
    [38.53],
    [38.550000000000004],
    [38.57],
    [38.56],
    [38.57],
    [38.59],
    [38.59],
    [38.6],
    [38.61],
    [38.62],
    [38.62],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.67],
    [38.67],
    [38.69],
    [38.71],
    [38.69],
    [38.7],
    [38.71],
    [38.730000000000004],
    [38.74],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.75],
    [38.75],
    [38.74],
    [38.74],
    [38.75],
    [38.74],
    [38.75],
    [38.75],
    [38.77],
    [38.78],
    [38.800000000000004],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.83],
    [38.82],
    [38.82],
    [38.85],
    [38.84],
    [38.85],
    [38.87],
    [38.87],
    [38.88],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.96],
    [38.95],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.99],
    [38.99],
    [39.03],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.07],
    [39.07],
    [39.04],
    [39.06],
    [39.06],
    [39.04],
    [39.03],
    [39.02],
    [39.0],
    [38.980000000000004],
    [38.95],
    [38.95],
    [38.94],
    [38.9],
    [38.89],
    [38.86],
    [38.84],
    [38.83],
    [38.83],
    [38.82],
    [38.800000000000004],
    [38.800000000000004],
    [38.81],
    [38.82],
    [38.78],
    [38.81],
    [38.81],
    [38.81],
    [38.83],
    [38.85],
    [38.84],
    [38.87],
    [38.85],
    [38.87],
    [38.88],
    [38.89],
    [38.9],
    [38.92],
    [38.92],
    [38.92],
    [38.95],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.980000000000004],
    [39.0],
    [39.01],
    [39.02],
    [39.03],
    [39.03],
    [39.04],
    [39.06],
    [39.07],
    [39.07],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.03],
    [39.01],
    [39.0],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.94],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.910000000000004],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.95],
    [38.95],
    [38.97],
    [38.96],
    [38.99],
    [38.99],
    [39.0],
    [39.02],
    [39.02],
    [39.02],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.07],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.11],
    [39.12],
    [39.13],
    [39.11],
    [39.12],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.15],
    [39.15],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.19],
    [39.18],
    [39.19],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.230000000000004],
    [39.21],
    [39.21],
    [39.18],
    [39.17],
    [39.13],
    [39.14],
    [39.09],
    [39.09],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.01],
    [39.0],
    [39.0],
    [38.96],
    [38.97],
    [38.95],
    [38.94],
    [38.9],
    [38.89],
    [38.87],
    [38.84],
    [38.82],
    [38.83],
    [38.82],
    [38.79],
    [38.79],
    [38.79],
    [38.79],
    [38.81],
    [38.82],
    [38.85],
    [38.85],
    [38.87],
    [38.89],
    [38.9],
    [38.92],
    [38.94],
    [38.95],
    [38.93],
    [38.94],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.87],
    [38.86],
    [38.86],
    [38.87],
    [38.88],
    [38.87],
    [38.87],
    [38.88],
    [38.88],
    [38.88],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.94],
    [38.93],
    [38.93],
    [38.94],
    [38.95],
    [38.93],
    [38.95],
    [38.94],
    [38.94],
    [38.92],
    [38.93],
    [38.94],
    [38.910000000000004],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.89],
    [38.9],
    [38.9],
    [38.89],
    [38.9],
    [38.87],
    [38.88],
    [38.87],
    [38.86],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.87],
    [38.85],
    [38.85],
    [38.85],
    [38.83],
    [38.85],
    [38.85],
    [38.82],
    [38.85],
    [38.83],
    [38.82],
    [38.800000000000004],
    [38.800000000000004],
    [38.800000000000004],
    [38.78],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.800000000000004],
    [38.800000000000004],
    [38.800000000000004],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.82],
    [38.82],
    [38.82],
    [38.83],
    [38.82],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.84],
    [38.82],
    [38.83],
    [38.81],
    [38.81],
    [38.81],
    [38.82],
    [38.81],
    [38.79],
    [38.78],
    [38.79],
    [38.77],
    [38.76],
    [38.75],
    [38.76],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.79],
    [38.800000000000004],
    [38.82],
    [38.81],
    [38.82],
    [38.83],
    [38.83],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.86],
    [38.85],
    [38.87],
    [38.88],
    [38.87],
    [38.87],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.92],
    [38.93],
    [38.95],
    [38.96],
    [38.97],
    [38.97],
    [38.96],
    [38.95],
    [38.93],
    [38.910000000000004],
    [38.89],
    [38.87],
    [38.83],
    [38.82],
    [38.79],
    [38.78],
    [38.75],
    [38.74],
    [38.71],
    [38.71],
    [38.69],
    [38.68],
    [38.660000000000004],
    [38.64],
    [38.64],
    [38.62],
    [38.62],
    [38.61],
    [38.6],
    [38.6],
    [38.6],
    [38.6],
    [38.59],
    [38.57],
    [38.56],
    [38.54],
    [38.550000000000004],
    [38.53],
    [38.54],
    [38.52],
    [38.54],
    [38.53],
    [38.53],
    [38.52],
    [38.51],
    [38.51],
    [38.5],
    [38.49],
    [38.5],
    [38.47],
    [38.46],
    [38.44],
    [38.46],
    [38.45],
    [38.45],
    [38.42],
    [38.42],
    [38.42],
    [38.43],
    [38.42],
    [38.42],
    [38.43],
    [38.43],
    [38.44],
    [38.45],
    [38.46],
    [38.45],
    [38.45],
    [38.45],
    [38.46],
    [38.45],
    [38.44],
    [38.45],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.46],
    [38.47],
    [38.480000000000004],
    [38.46],
    [38.480000000000004],
    [38.47],
    [38.480000000000004],
    [38.49],
    [38.51],
    [38.53],
    [38.53],
    [38.56],
    [38.550000000000004],
    [38.56],
    [38.57],
    [38.58],
    [38.58],
    [38.6],
    [38.6],
    [38.61],
    [38.63],
    [38.64],
    [38.65],
    [38.67],
    [38.69],
    [38.71],
    [38.7],
    [38.72],
    [38.71],
    [38.75],
    [38.76],
    [38.77],
    [38.78],
    [38.79],
    [38.81],
    [38.81],
    [38.800000000000004],
    [38.79],
    [38.800000000000004],
    [38.79],
    [38.78],
    [38.78],
    [38.77],
    [38.75],
    [38.74],
    [38.74],
    [38.730000000000004],
    [38.74],
    [38.74],
    [38.730000000000004],
    [38.730000000000004],
    [38.74],
    [38.730000000000004],
    [38.74],
    [38.730000000000004],
    [38.72],
    [38.730000000000004],
    [38.730000000000004],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.69],
    [38.7],
    [38.7],
    [38.67],
    [38.69],
    [38.69],
    [38.71],
    [38.7],
    [38.72],
    [38.71],
    [38.72],
    [38.74],
    [38.730000000000004],
    [38.75],
    [38.75],
    [38.75],
    [38.76],
    [38.77],
    [38.78],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.84],
    [38.86],
    [38.84],
    [38.85],
    [38.86],
    [38.88],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.95],
    [38.94],
    [38.94],
    [38.97],
    [38.980000000000004],
    [38.99],
    [38.99],
    [38.97],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.980000000000004],
    [39.0],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.99],
    [38.980000000000004],
    [38.97],
    [38.97],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.99],
    [39.0],
    [39.01],
    [39.02],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.02],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.01],
    [39.02],
    [39.0],
    [38.980000000000004],
    [39.0],
    [39.0],
    [38.97],
    [38.96],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.94],
    [38.93],
    [38.94],
    [38.94],
    [38.94],
    [38.93],
    [38.93],
    [38.94],
    [38.93],
    [38.93],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.89],
    [38.88],
    [38.88],
    [38.86],
    [38.87],
    [38.85],
    [38.86],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.84],
    [38.85],
    [38.84],
    [38.85],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.83],
    [38.81],
    [38.800000000000004],
    [38.74],
    [38.71],
    [38.67],
    [38.65],
    [38.61],
    [38.6],
    [38.58],
    [38.57],
    [38.550000000000004],
    [38.54],
    [38.53],
    [38.5],
    [38.51],
    [38.52],
    [38.51],
    [38.5],
    [38.5],
    [38.5],
    [38.54],
    [38.54],
    [38.54],
    [38.57],
    [38.56],
    [38.57],
    [38.58],
    [38.57],
    [38.59],
    [38.6],
    [38.6],
    [38.62],
    [38.64],
    [38.64],
    [38.64],
    [38.65],
    [38.660000000000004],
    [38.65],
    [38.660000000000004],
    [38.69],
    [38.69],
    [38.71],
    [38.71],
    [38.72],
    [38.74],
    [38.74],
    [38.75],
    [38.78],
    [38.77],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.81],
    [38.81],
    [38.82],
    [38.82],
    [38.85],
    [38.84],
    [38.86],
    [38.86],
    [38.87],
    [38.88],
    [38.88],
    [38.86],
    [38.86],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.89],
    [38.9],
    [38.93],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.94],
    [38.92],
    [38.93],
    [38.92],
    [38.93],
    [38.95],
    [38.96],
    [38.980000000000004],
    [39.0],
    [39.02],
    [39.06],
    [39.07],
    [39.08],
    [39.1],
    [39.11],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.18],
    [39.2],
    [39.21],
    [39.21],
    [39.22],
    [39.25],
    [39.27],
    [39.26],
    [39.28],
    [39.28],
    [39.29],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.32],
    [39.33],
    [39.33],
    [39.32],
    [39.33],
    [39.35],
    [39.34],
    [39.35],
    [39.35],
    [39.35],
    [39.34],
    [39.35],
    [39.35],
    [39.37],
    [39.36],
    [39.37],
    [39.39],
    [39.39],
    [39.39],
    [39.4],
    [39.4],
    [39.44],
    [39.45],
    [39.46],
    [39.46],
    [39.5],
    [39.5],
    [39.52],
    [39.5],
    [39.53],
    [39.54],
    [39.54],
    [39.53],
    [39.52],
    [39.5],
    [39.5],
    [39.47],
    [39.480000000000004],
    [39.47],
    [39.46],
    [39.46],
    [39.45],
    [39.45],
    [39.42],
    [39.44],
    [39.44],
    [39.42],
    [39.42],
    [39.42],
    [39.39],
    [39.39],
    [39.37],
    [39.35],
    [39.35],
    [39.33],
    [39.33],
    [39.34],
    [39.32],
    [39.32],
    [39.33],
    [39.32],
    [39.32],
    [39.32],
    [39.32],
    [39.31],
    [39.32],
    [39.32],
    [39.32],
    [39.32],
    [39.32],
    [39.32],
    [39.32],
    [39.31],
    [39.31],
    [39.28],
    [39.27],
    [39.24],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.19],
    [39.18],
    [39.17],
    [39.18],
    [39.160000000000004],
    [39.17],
    [39.15],
    [39.15],
    [39.15],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.13],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.19],
    [39.19],
    [39.18],
    [39.19],
    [39.19],
    [39.18],
    [39.19],
    [39.2],
    [39.21],
    [39.2],
    [39.17],
    [39.19],
    [39.18],
    [39.19],
    [39.22],
    [39.21],
    [39.22],
    [39.25],
    [39.24],
    [39.25],
    [39.25],
    [39.26],
    [39.27],
    [39.27],
    [39.26],
    [39.27],
    [39.27],
    [39.28],
    [39.28],
    [39.29],
    [39.29],
    [39.29],
    [39.28],
    [39.28],
    [39.28],
    [39.27],
    [39.28],
    [39.25],
    [39.27],
    [39.25],
    [39.26],
    [39.26],
    [39.26],
    [39.25],
    [39.26],
    [39.25],
    [39.28],
    [39.26],
    [39.28],
    [39.27],
    [39.28],
    [39.26],
    [39.28],
    [39.25],
    [39.27],
    [39.26],
    [39.27],
    [39.27],
    [39.27],
    [39.26],
    [39.25],
    [39.25],
    [39.24],
    [39.22],
    [39.21],
    [39.19],
    [39.160000000000004],
    [39.1],
    [39.07],
    [39.050000000000004],
    [39.03],
    [39.02],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.95],
    [38.97],
    [38.96],
    [38.95],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.93],
    [38.94],
    [38.93],
    [38.95],
    [38.96],
    [38.97],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [38.99],
    [38.99],
    [39.0],
    [38.980000000000004],
    [38.980000000000004],
    [38.99],
    [39.0],
    [38.980000000000004],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [38.980000000000004],
    [38.99],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.99],
    [39.01],
    [39.0],
    [39.01],
    [39.0],
    [38.99],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.06],
    [39.050000000000004],
    [39.050000000000004],
    [39.07],
    [39.08],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.1],
    [39.09],
    [39.08],
    [39.1],
    [39.09],
    [39.09],
    [39.08],
    [39.08],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.11],
    [39.1],
    [39.11],
    [39.12],
    [39.12],
    [39.12],
    [39.13],
    [39.13],
    [39.14],
    [39.15],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.2],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.24],
    [39.26],
    [39.26],
    [39.26],
    [39.27],
    [39.28],
    [39.28],
    [39.28],
    [39.31],
    [39.33],
    [39.32],
    [39.33],
    [39.34],
    [39.36],
    [39.38],
    [39.37],
    [39.38],
    [39.38],
    [39.410000000000004],
    [39.410000000000004],
    [39.410000000000004],
    [39.42],
    [39.410000000000004],
    [39.42],
    [39.42],
    [39.44],
    [39.42],
    [39.45],
    [39.44],
    [39.43],
    [39.46],
    [39.45],
    [39.44],
    [39.46],
    [39.480000000000004],
    [39.480000000000004],
    [39.49],
    [39.49],
    [39.49],
    [39.51],
    [39.52],
    [39.53],
    [39.52],
    [39.52],
    [39.53],
    [39.53],
    [39.52],
    [39.53],
    [39.53],
    [39.53],
    [39.54],
    [39.56],
    [39.56],
    [39.54],
    [39.54],
    [39.54],
    [39.54],
    [39.54],
    [39.56],
    [39.57],
    [39.57],
    [39.56],
    [39.56],
    [39.57],
    [39.57],
    [39.56],
    [39.57],
    [39.57],
    [39.58],
    [39.59],
    [39.59],
    [39.59],
    [39.6],
    [39.59],
    [39.58],
    [39.61],
    [39.6],
    [39.6],
    [39.62],
    [39.62],
    [39.64],
    [39.64],
    [39.64],
    [39.64],
    [39.64],
    [39.660000000000004],
    [39.65],
    [39.660000000000004],
    [39.67],
    [39.68],
    [39.7],
    [39.71],
    [39.71],
    [39.71],
    [39.7],
    [39.72],
    [39.72],
    [39.730000000000004],
    [39.72],
    [39.74],
    [39.72],
    [39.74],
    [39.75],
    [39.75],
    [39.75],
    [39.77],
    [39.77],
    [39.78],
    [39.78],
    [39.77],
    [39.800000000000004, EVENTS.FEVER_DETECTED],
    [39.78],
    [39.800000000000004],
    [39.79],
    [39.800000000000004],
    [39.82],
    [39.83],
    [39.82],
    [39.82],
    [39.82],
    [39.82],
    [39.82],
    [39.82],
    [39.82],
    [39.81],
    [39.81],
    [39.800000000000004],
    [39.78],
    [39.78],
    [39.79],
    [39.78],
    [39.78],
    [39.78],
    [39.79],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.79],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.79],
    [39.77],
    [39.78],
    [39.78],
    [39.78],
    [39.79],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.77],
    [39.76],
    [39.76],
    [39.78],
    [39.76],
    [39.77],
    [39.76],
    [39.76],
    [39.75],
    [39.75],
    [39.72],
    [39.71],
    [39.71],
    [39.68],
    [39.67],
    [39.65],
    [39.64],
    [39.61],
    [39.62],
    [39.59],
    [39.57],
    [39.550000000000004],
    [39.51],
    [39.5],
    [39.47],
    [39.46],
    [39.42],
    [39.39],
    [39.35],
    [39.34],
    [39.33],
    [39.31],
    [39.300000000000004, EVENTS.FEVER_OVER],
    [39.27],
    [39.28],
    [39.26],
    [39.24],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.2],
    [39.19],
    [39.17],
    [39.17],
    [39.17],
    [39.15],
    [39.15],
    [39.15],
    [39.14],
    [39.12],
    [39.1],
    [39.11],
    [39.12],
    [39.11],
    [39.11],
    [39.1],
    [39.13],
    [39.12],
    [39.11],
    [39.14],
    [39.14],
    [39.13],
    [39.15],
    [39.15],
    [39.160000000000004],
    [39.18],
    [39.2],
    [39.21],
    [39.230000000000004],
    [39.21],
    [39.230000000000004],
    [39.25],
    [39.25],
    [39.25],
    [39.27],
    [39.26],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.32],
    [39.32],
    [39.300000000000004],
    [39.29],
    [39.300000000000004],
    [39.28],
    [39.29],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.28],
    [39.26],
    [39.26],
    [39.26],
    [39.26],
    [39.27],
    [39.28],
    [39.29],
    [39.28],
    [39.25],
    [39.230000000000004],
    [39.21],
    [39.18],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.12],
    [39.12],
    [39.13],
    [39.14],
    [39.13],
    [39.15],
    [39.14],
    [39.17],
    [39.18],
    [39.19],
    [39.18],
    [39.19],
    [39.21],
    [39.230000000000004],
    [39.25],
    [39.26],
    [39.27],
    [39.26],
    [39.26],
    [39.28],
    [39.28],
    [39.28],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.31],
    [39.32],
    [39.32],
    [39.33],
    [39.35],
    [39.35],
    [39.35],
    [39.36],
    [39.38],
    [39.38],
    [39.410000000000004],
    [39.42],
    [39.46],
    [39.45],
    [39.47],
    [39.480000000000004],
    [39.47],
    [39.46],
    [39.46],
    [39.44],
    [39.43],
    [39.42],
    [39.410000000000004],
    [39.39],
    [39.38],
    [39.37],
    [39.35],
    [39.36],
    [39.37],
    [39.36],
    [39.35],
    [39.35],
    [39.36],
    [39.35],
    [39.37],
    [39.37],
    [39.37],
    [39.36],
    [39.36],
    [39.35],
    [39.35],
    [39.35],
    [39.32],
    [39.32],
    [39.28],
    [39.28],
    [39.26],
    [39.25],
    [39.22],
    [39.21],
    [39.18],
    [39.17],
    [39.15],
    [39.14],
    [39.12],
    [39.11],
    [39.09],
    [39.1],
    [39.1],
    [39.08],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.09],
    [39.08],
    [39.09],
    [39.07],
    [39.08],
    [39.08],
    [39.07],
    [39.06],
    [39.06],
    [39.06],
    [39.03],
    [39.0],
    [38.980000000000004],
    [38.96],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.94],
    [38.94],
    [38.93],
    [38.94],
    [38.96],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.02],
    [39.03],
    [39.04],
    [39.06],
    [39.07],
    [39.07],
    [39.1],
    [39.12],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.17],
    [39.18],
    [39.21],
    [39.21],
    [39.2],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.24],
    [39.24],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.26],
    [39.25],
    [39.26],
    [39.28],
    [39.28],
    [39.28],
    [39.29],
    [39.300000000000004],
    [39.29],
    [39.29],
    [39.29],
    [39.28],
    [39.28],
    [39.28],
    [39.27],
    [39.27],
    [39.26],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.22],
    [39.25],
    [39.230000000000004],
    [39.21],
    [39.22],
    [39.2],
    [39.22],
    [39.21],
    [39.21],
    [39.21],
    [39.19],
    [39.2],
    [39.21],
    [39.21],
    [39.17],
    [39.17],
    [39.14],
    [39.15],
    [39.12],
    [39.1],
    [39.1],
    [39.09],
    [39.08],
    [39.09],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.08],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.09],
    [39.1],
    [39.07],
    [39.1],
    [39.09],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.12],
    [39.12],
    [39.1],
    [39.11],
    [39.11],
    [39.12],
    [39.1],
    [39.12],
    [39.1],
    [39.1],
    [39.08],
    [39.07],
    [39.050000000000004],
    [39.03],
    [39.01],
    [39.02],
    [39.0],
    [38.99],
    [38.99],
    [38.980000000000004],
    [38.95],
    [38.97],
    [38.96],
    [38.95],
    [38.95],
    [38.94],
    [38.93],
    [38.93],
    [38.95],
    [38.93],
    [38.94],
    [38.95],
    [38.94],
    [38.95],
    [38.94],
    [38.95],
    [38.96],
    [38.95],
    [38.96],
    [38.95],
    [38.94],
    [38.95],
    [38.96],
    [38.95],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.01],
    [39.01],
    [38.99],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [39.01],
    [39.0],
    [39.03],
    [39.03],
    [39.04],
    [39.02],
    [39.04],
    [39.06],
    [39.04],
    [39.06],
    [39.06],
    [39.06],
    [39.07],
    [39.08],
    [39.07],
    [39.1],
    [39.1],
    [39.11],
    [39.1],
    [39.12],
    [39.14],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.14],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.19],
    [39.21],
    [39.22],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.28],
    [39.28],
    [39.29],
    [39.28],
    [39.27],
    [39.28],
    [39.28],
    [39.28],
    [39.27],
    [39.27],
    [39.27],
    [39.28],
    [39.25],
    [39.24],
    [39.25],
    [39.230000000000004],
    [39.25],
    [39.24],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.24],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.21],
    [39.22],
    [39.2],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.22],
    [39.21],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.22],
    [39.22],
    [39.230000000000004],
    [39.25],
    [39.25],
    [39.25],
    [39.27],
    [39.26],
    [39.28],
    [39.28],
    [39.29],
    [39.31],
    [39.32],
    [39.32],
    [39.32],
    [39.33],
    [39.33],
    [39.35],
    [39.35],
    [39.35],
    [39.35],
    [39.35],
    [39.34],
    [39.300000000000004],
    [39.31],
    [39.29],
    [39.28],
    [39.28],
    [39.28],
    [39.27],
    [39.27],
    [39.26],
    [39.26],
    [39.26],
    [39.26],
    [39.27],
    [39.27],
    [39.27],
    [39.28],
    [39.27],
    [39.28],
    [39.28],
    [39.29],
    [39.300000000000004],
    [39.31],
    [39.31],
    [39.29],
    [39.32],
    [39.32],
    [39.31],
    [39.32],
    [39.300000000000004],
    [39.32],
    [39.31],
    [39.32],
    [39.31],
    [39.300000000000004],
    [39.33],
    [39.31],
    [39.34],
    [39.34],
    [39.35],
    [39.35],
    [39.36],
    [39.38],
    [39.39],
    [39.4],
    [39.4],
    [39.42],
    [39.44],
    [39.44],
    [39.44],
    [39.45],
    [39.45],
    [39.45],
    [39.44],
    [39.43],
    [39.43],
    [39.46],
    [39.44],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.44],
    [39.44],
    [39.45],
    [39.46],
    [39.46],
    [39.45],
    [39.44],
    [39.46],
    [39.46],
    [39.44],
    [39.44],
    [39.44],
    [39.45],
    [39.45],
    [39.46],
    [39.47],
    [39.46],
    [39.46],
    [39.480000000000004],
    [39.5],
    [39.480000000000004],
    [39.49],
    [39.5],
    [39.49],
    [39.49],
    [39.480000000000004],
    [39.5],
    [39.5],
    [39.53],
    [39.53],
    [39.52],
    [39.53],
    [39.53],
    [39.54],
    [39.54],
    [39.550000000000004],
    [39.56],
    [39.550000000000004],
    [39.53],
    [39.53],
    [39.53],
    [39.54],
    [39.54],
    [39.54],
    [39.54],
    [39.54],
    [39.53],
    [39.53],
    [39.53],
    [39.53],
    [39.53],
    [39.51],
    [39.5],
    [39.52],
    [39.52],
    [39.52],
    [39.51],
    [39.52],
    [39.5],
    [39.5],
    [39.51],
    [39.51],
    [39.52],
    [39.52],
    [39.52],
    [39.51],
    [39.52],
    [39.53],
    [39.54],
    [39.54],
    [39.54],
    [39.53],
    [39.54],
    [39.54],
    [39.550000000000004],
    [39.550000000000004],
    [39.57],
    [39.58],
    [39.58],
    [39.59],
    [39.6],
    [39.6],
    [39.6],
    [39.59],
    [39.6],
    [39.61],
    [39.6],
    [39.6],
    [39.58],
    [39.57],
    [39.56],
    [39.56],
    [39.57],
    [39.56],
    [39.54],
    [39.550000000000004],
    [39.53],
    [39.54],
    [39.53],
    [39.52],
    [39.5],
    [39.5],
    [39.5],
    [39.5],
    [39.49],
    [39.49],
    [39.49],
    [39.480000000000004],
    [39.46],
    [39.46],
    [39.45],
    [39.46],
    [39.46],
    [39.46],
    [39.45],
    [39.45],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.45],
    [39.45],
    [39.44],
    [39.44],
    [39.45],
    [39.45],
    [39.45],
    [39.45],
    [39.46],
    [39.47],
    [39.480000000000004],
    [39.47],
    [39.47],
    [39.480000000000004],
    [39.49],
    [39.47],
    [39.5],
    [39.5],
    [39.5],
    [39.5],
    [39.49],
    [39.49],
    [39.5],
    [39.5],
    [39.480000000000004],
    [39.480000000000004],
    [39.480000000000004],
    [39.480000000000004],
    [39.480000000000004],
    [39.5],
    [39.47],
    [39.49],
    [39.47],
    [39.47],
    [39.49],
    [39.480000000000004],
    [39.480000000000004],
    [39.49],
    [39.49],
    [39.5],
    [39.49],
    [39.49],
    [39.480000000000004],
    [39.480000000000004],
    [39.480000000000004],
    [39.49],
    [39.5],
    [39.49],
    [39.5],
    [39.5],
    [39.5],
    [39.5],
    [39.5],
    [39.5],
    [39.51],
    [39.52],
    [39.5],
    [39.51],
    [39.52],
    [39.52],
    [39.51],
    [39.53],
    [39.53],
    [39.51],
    [39.52],
    [39.53],
    [39.53],
    [39.53],
    [39.52],
    [39.52],
    [39.51],
    [39.51],
    [39.52],
    [39.51],
    [39.53],
    [39.52],
    [39.52],
    [39.52],
    [39.53],
    [39.53],
    [39.53],
    [39.550000000000004],
    [39.54],
    [39.550000000000004],
    [39.54],
    [39.550000000000004],
    [39.57],
    [39.550000000000004],
    [39.550000000000004],
    [39.57],
    [39.54],
    [39.54],
    [39.53],
    [39.53],
    [39.52],
    [39.53],
    [39.53],
    [39.53],
    [39.53],
    [39.550000000000004],
    [39.53],
    [39.53],
    [39.53],
    [39.53],
    [39.53],
    [39.54],
    [39.550000000000004],
    [39.56],
    [39.57],
    [39.56],
    [39.57],
    [39.550000000000004],
    [39.57],
    [39.57],
    [39.57],
    [39.59],
    [39.57],
    [39.58],
    [39.57],
    [39.59],
    [39.57],
    [39.59],
    [39.59],
    [39.6],
    [39.59],
    [39.6],
    [39.6],
    [39.62],
    [39.6],
    [39.61],
    [39.62],
    [39.63],
    [39.62],
    [39.62],
    [39.62],
    [39.64],
    [39.64],
    [39.64],
    [39.64],
    [39.64],
    [39.65],
    [39.67],
    [39.660000000000004],
    [39.67],
    [39.67],
    [39.7],
    [39.7],
    [39.7],
    [39.71],
    [39.72],
    [39.71],
    [39.71],
    [39.71],
    [39.74],
    [39.730000000000004],
    [39.75],
    [39.74],
    [39.74],
    [39.75],
    [39.75],
    [39.75],
    [39.74],
    [39.77],
    [39.76, EVENTS.FEVER_DETECTED],
    [39.76],
    [39.77],
    [39.77],
    [39.77],
    [39.77],
    [39.76],
    [39.77],
    [39.76],
    [39.76],
    [39.77],
    [39.76],
    [39.730000000000004],
    [39.72],
    [39.72],
    [39.7],
    [39.68],
    [39.67],
    [39.64],
    [39.65],
    [39.62],
    [39.62],
    [39.61],
    [39.6],
    [39.57],
    [39.57],
    [39.57],
    [39.56],
    [39.57],
    [39.56],
    [39.56],
    [39.57],
    [39.56],
    [39.56],
    [39.54],
    [39.57],
    [39.56],
    [39.550000000000004],
    [39.56],
    [39.550000000000004],
    [39.550000000000004],
    [39.54],
    [39.56],
    [39.57],
    [39.57],
    [39.57],
    [39.57],
    [39.54],
    [39.550000000000004],
    [39.550000000000004],
    [39.53],
    [39.53],
    [39.52],
    [39.49],
    [39.49],
    [39.46],
    [39.46],
    [39.44],
    [39.42],
    [39.4],
    [39.38],
    [39.35],
    [39.33],
    [39.29],
    [39.28],
    [39.26],
    [39.26],
    [39.24],
    [39.24],
    [39.2, EVENTS.FEVER_OVER],
    [39.18],
    [39.17],
    [39.15],
    [39.15],
    [39.13],
    [39.14],
    [39.11],
    [39.12],
    [39.11],
    [39.12],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.09],
    [39.1],
    [39.09],
    [39.1],
    [39.08],
    [39.1],
    [39.1],
    [39.08],
    [39.08],
    [39.09],
    [39.07],
    [39.07],
    [39.06],
    [39.06],
    [39.04],
    [39.03],
    [39.02],
    [39.0],
    [39.0],
    [39.01],
    [39.03],
    [39.01],
    [39.0],
    [39.02],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.01],
    [39.02],
    [39.01],
    [39.02],
    [39.02],
    [39.03],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.03],
    [39.04],
    [39.03],
    [39.02],
    [39.02],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [38.99],
    [38.99],
    [38.980000000000004],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.96],
    [38.97],
    [38.99],
    [38.97],
    [38.96],
    [38.97],
    [38.99],
    [38.980000000000004],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.97],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.02],
    [39.01],
    [39.0],
    [39.01],
    [39.01],
    [39.0],
    [39.01],
    [39.01],
    [39.01],
    [39.01],
    [39.02],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.08],
    [39.1],
    [39.11],
    [39.11],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.15],
    [39.17],
    [39.14],
    [39.14],
    [39.13],
    [39.12],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.14],
    [39.160000000000004],
    [39.160000000000004],
    [39.18],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.19],
    [39.18],
    [39.18],
    [39.19],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.14],
    [39.1],
    [39.1],
    [39.08],
    [39.07],
    [39.04],
    [39.02],
    [38.99],
    [39.0],
    [38.980000000000004],
    [38.980000000000004],
    [38.95],
    [38.92],
    [38.9],
    [38.89],
    [38.87],
    [38.86],
    [38.85],
    [38.85],
    [38.82],
    [38.82],
    [38.82],
    [38.800000000000004],
    [38.81],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.79],
    [38.77],
    [38.77],
    [38.76],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.77],
    [38.79],
    [38.78],
    [38.81],
    [38.82],
    [38.79],
    [38.800000000000004],
    [38.81],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.84],
    [38.82],
    [38.82],
    [38.85],
    [38.87],
    [38.88],
    [38.87],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.9],
    [38.9],
    [38.9],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.95],
    [38.94],
    [38.95],
    [38.94],
    [38.96],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.99],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.96],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.02],
    [39.03],
    [39.050000000000004],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.07],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.07],
    [39.06],
    [39.06],
    [39.07],
    [39.07],
    [39.08],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.12],
    [39.11],
    [39.11],
    [39.11],
    [39.12],
    [39.13],
    [39.13],
    [39.12],
    [39.12],
    [39.13],
    [39.13],
    [39.14],
    [39.13],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.11],
    [39.11],
    [39.1],
    [39.1],
    [39.07],
    [39.09],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.03],
    [39.04],
    [39.03],
    [39.03],
    [39.02],
    [39.0],
    [39.0],
    [39.02],
    [39.01],
    [39.03],
    [39.03],
    [39.04],
    [39.04],
    [39.03],
    [39.02],
    [39.02],
    [39.01],
    [39.0],
    [39.0],
    [39.0],
    [38.980000000000004],
    [38.96],
    [38.97],
    [38.95],
    [38.93],
    [38.910000000000004],
    [38.92],
    [38.89],
    [38.89],
    [38.89],
    [38.87],
    [38.86],
    [38.86],
    [38.87],
    [38.86],
    [38.87],
    [38.87],
    [38.86],
    [38.88],
    [38.88],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.93],
    [38.93],
    [38.92],
    [38.92],
    [38.94],
    [38.95],
    [38.94],
    [38.95],
    [38.96],
    [38.97],
    [38.97],
    [38.99],
    [39.0],
    [39.0],
    [39.02],
    [39.02],
    [39.02],
    [39.03],
    [39.03],
    [39.04],
    [39.06],
    [39.050000000000004],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.06],
    [39.06],
    [39.06],
    [39.03],
    [39.02],
    [39.01],
    [38.99],
    [39.0],
    [38.97],
    [38.97],
    [38.96],
    [38.96],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.9],
    [38.88],
    [38.88],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.92],
    [38.910000000000004],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.9],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.94],
    [38.94],
    [38.92],
    [38.95],
    [38.95],
    [38.95],
    [38.96],
    [38.97],
    [38.97],
    [38.97],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.03],
    [39.04],
    [39.06],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.03],
    [39.02],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.0],
    [39.01],
    [39.0],
    [39.03],
    [39.0],
    [39.01],
    [39.01],
    [38.99],
    [38.97],
    [38.97],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.95],
    [38.93],
    [38.93],
    [38.92],
    [38.92],
    [38.93],
    [38.94],
    [38.94],
    [38.94],
    [38.94],
    [38.92],
    [38.92],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.89],
    [38.88],
    [38.87],
    [38.87],
    [38.89],
    [38.87],
    [38.87],
    [38.87],
    [38.87],
    [38.87],
    [38.87],
    [38.86],
    [38.87],
    [38.86],
    [38.88],
    [38.88],
    [38.88],
    [38.86],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.85],
    [38.87],
    [38.86],
    [38.85],
    [38.88],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.92],
    [38.92],
    [38.94],
    [38.94],
    [38.96],
    [38.96],
    [38.95],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.95],
    [38.96],
    [38.980000000000004],
    [38.980000000000004],
    [38.980000000000004],
    [39.0],
    [38.980000000000004],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.97],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.99],
    [38.980000000000004],
    [39.0],
    [39.01],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.97],
    [38.95],
    [38.94],
    [38.92],
    [38.93],
    [38.9],
    [38.9],
    [38.89],
    [38.87],
    [38.85],
    [38.84],
    [38.83],
    [38.84],
    [38.83],
    [38.84],
    [38.83],
    [38.83],
    [38.84],
    [38.83],
    [38.83],
    [38.82],
    [38.81],
    [38.82],
    [38.82],
    [38.81],
    [38.800000000000004],
    [38.800000000000004],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.79],
    [38.79],
    [38.79],
    [38.79],
    [38.78],
    [38.77],
    [38.78],
    [38.76],
    [38.78],
    [38.77],
    [38.78],
    [38.77],
    [38.76],
    [38.77],
    [38.77],
    [38.77],
    [38.75],
    [38.75],
    [38.75],
    [38.730000000000004],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.72],
    [38.730000000000004],
    [38.74],
    [38.74],
    [38.74],
    [38.76],
    [38.75],
    [38.75],
    [38.76],
    [38.76],
    [38.76],
    [38.75],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.82],
    [38.82],
    [38.84],
    [38.85],
    [38.88],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.93],
    [38.92],
    [38.92],
    [38.93],
    [38.93],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.93],
    [38.92],
    [38.93],
    [38.92],
    [38.93],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.910000000000004],
    [38.92],
    [38.94],
    [38.93],
    [38.95],
    [38.96],
    [38.96],
    [38.96],
    [38.980000000000004],
    [38.97],
    [38.980000000000004],
    [38.980000000000004],
    [38.97],
    [38.99],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.980000000000004],
    [38.96],
    [38.99],
    [38.97],
    [38.99],
    [38.980000000000004],
    [39.0],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [38.99],
    [38.980000000000004],
    [38.97],
    [38.96],
    [38.94],
    [38.94],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.89],
    [38.89],
    [38.89],
    [38.9],
    [38.89],
    [38.89],
    [38.86],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.89],
    [38.88],
    [38.88],
    [38.87],
    [38.86],
    [38.88],
    [38.88],
    [38.85],
    [38.87],
    [38.85],
    [38.86],
    [38.85],
    [38.86],
    [38.86],
    [38.88],
    [38.86],
    [38.87],
    [38.86],
    [38.87],
    [38.87],
    [38.88],
    [38.88],
    [38.87],
    [38.88],
    [38.87],
    [38.86],
    [38.910000000000004],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.89],
    [38.87],
    [38.88],
    [38.85],
    [38.85],
    [38.84],
    [38.83],
    [38.84],
    [38.83],
    [38.82],
    [38.83],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.82],
    [38.81],
    [38.78],
    [38.79],
    [38.800000000000004],
    [38.79],
    [38.79],
    [38.81],
    [38.78],
    [38.800000000000004],
    [38.78],
    [38.78],
    [38.79],
    [38.81],
    [38.81],
    [38.81],
    [38.81],
    [38.79],
    [38.78],
    [38.79],
    [38.78],
    [38.78],
    [38.78],
    [38.76],
    [38.76],
    [38.75],
    [38.730000000000004],
    [38.730000000000004],
    [38.74],
    [38.74],
    [38.72],
    [38.72],
    [38.71],
    [38.71],
    [38.7],
    [38.71],
    [38.69],
    [38.69],
    [38.68],
    [38.67],
    [38.69],
    [38.7],
    [38.71],
    [38.7],
    [38.72],
    [38.730000000000004],
    [38.74],
    [38.730000000000004],
    [38.74],
    [38.75],
    [38.76],
    [38.77],
    [38.77],
    [38.77],
    [38.77],
    [38.78],
    [38.81],
    [38.81],
    [38.82],
    [38.81],
    [38.83],
    [38.84],
    [38.85],
    [38.85],
    [38.85],
    [38.84],
    [38.82],
    [38.81],
    [38.79],
    [38.75],
    [38.75],
    [38.730000000000004],
    [38.71],
    [38.71],
    [38.68],
    [38.67],
    [38.64],
    [38.62],
    [38.62],
    [38.61],
    [38.59],
    [38.550000000000004],
    [38.550000000000004],
    [38.53],
    [38.53],
    [38.5],
    [38.53],
    [38.5],
    [38.5],
    [38.480000000000004],
    [38.47],
    [38.46],
    [38.46],
    [38.44],
    [38.44],
    [38.43],
    [38.4],
    [38.4],
    [38.38],
    [38.4],
    [38.38],
    [38.39],
    [38.38],
    [38.38],
    [38.38],
    [38.38],
    [38.39],
    [38.39],
    [38.38],
    [38.39],
    [38.38],
    [38.37],
    [38.39],
    [38.39],
    [38.4],
    [38.4],
    [38.42],
    [38.42],
    [38.410000000000004],
    [38.42],
    [38.42],
    [38.45],
    [38.44],
    [38.47],
    [38.47],
    [38.46],
    [38.480000000000004],
    [38.5],
    [38.5],
    [38.51],
    [38.53],
    [38.550000000000004],
    [38.56],
    [38.59],
    [38.6],
    [38.61],
    [38.61],
    [38.64],
    [38.64],
    [38.67],
    [38.68],
    [38.71],
    [38.71],
    [38.71],
    [38.72],
    [38.74],
    [38.74],
    [38.76],
    [38.75],
    [38.77],
    [38.76],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.800000000000004],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.78],
    [38.75],
    [38.76],
    [38.76],
    [38.76],
    [38.77],
    [38.75],
    [38.76],
    [38.77],
    [38.77],
    [38.77],
    [38.78],
    [38.79],
    [38.79],
    [38.81],
    [38.81],
    [38.81],
    [38.82],
    [38.84],
    [38.83],
    [38.85],
    [38.84],
    [38.85],
    [38.89],
    [38.910000000000004],
    [38.96],
    [38.96],
    [38.97],
    [38.96],
    [38.97],
    [38.96],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [38.99],
    [39.01],
    [39.02],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.07],
    [39.09],
    [39.07],
    [39.08],
    [39.08],
    [39.08],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.11],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.14],
    [39.15],
    [39.15],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.18],
    [39.17],
    [39.18],
    [39.17],
    [39.19],
    [39.2],
    [39.19],
    [39.21],
    [39.22],
    [39.22],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.28],
    [39.27],
    [39.26],
    [39.29],
    [39.31],
    [39.31],
    [39.31],
    [39.32],
    [39.33],
    [39.35],
    [39.36],
    [39.36],
    [39.39],
    [39.38],
    [39.39],
    [39.39],
    [39.4],
    [39.410000000000004],
    [39.39],
    [39.4],
    [39.410000000000004],
    [39.42],
    [39.410000000000004],
    [39.42],
    [39.42],
    [39.44],
    [39.43],
    [39.410000000000004],
    [39.44],
    [39.43],
    [39.42],
    [39.43],
    [39.43],
    [39.44],
    [39.44],
    [39.43],
    [39.45],
    [39.44],
    [39.45],
    [39.45],
    [39.46],
    [39.47],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.47],
    [39.47],
    [39.480000000000004],
    [39.480000000000004],
    [39.47],
    [39.49],
    [39.480000000000004],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.46],
    [39.45],
    [39.45],
    [39.42],
    [39.43],
    [39.42],
    [39.42],
    [39.4],
    [39.42],
    [39.4],
    [39.39],
    [39.4],
    [39.39],
    [39.39],
    [39.38],
    [39.37],
    [39.37],
    [39.36],
    [39.36],
    [39.34],
    [39.34],
    [39.35],
    [39.34],
    [39.35],
    [39.35],
    [39.35],
    [39.34],
    [39.35],
    [39.34],
    [39.34],
    [39.34],
    [39.34],
    [39.33],
    [39.35],
    [39.33],
    [39.34],
    [39.35],
    [39.36],
    [39.35],
    [39.36],
    [39.37],
    [39.35],
    [39.39],
    [39.37],
    [39.38],
    [39.39],
    [39.39],
    [39.410000000000004],
    [39.4],
    [39.42],
    [39.43],
    [39.44],
    [39.43],
    [39.46],
    [39.45],
    [39.46],
    [39.480000000000004],
    [39.480000000000004],
    [39.5],
    [39.51],
    [39.52],
    [39.53],
    [39.54],
    [39.550000000000004],
    [39.56],
    [39.57],
    [39.58],
    [39.58],
    [39.6],
    [39.6],
    [39.6],
    [39.6],
    [39.61],
    [39.6],
    [39.6],
    [39.61],
    [39.61],
    [39.6],
    [39.6],
    [39.59],
    [39.6],
    [39.62],
    [39.61],
    [39.62],
    [39.6],
    [39.62],
    [39.61],
    [39.61],
    [39.63],
    [39.63],
    [39.64],
    [39.64],
    [39.64],
    [39.63],
    [39.660000000000004],
    [39.660000000000004],
    [39.69],
    [39.69],
    [39.71],
    [39.71],
    [39.72],
    [39.71],
    [39.730000000000004],
    [39.730000000000004],
    [39.74],
    [39.72],
    [39.72],
    [39.730000000000004],
    [39.74],
    [39.74],
    [39.730000000000004],
    [39.72],
    [39.730000000000004],
    [39.72],
    [39.730000000000004],
    [39.75, EVENTS.FEVER_DETECTED],
    [39.75],
    [39.75],
    [39.75],
    [39.75],
    [39.75],
    [39.78],
    [39.77],
    [39.77],
    [39.78],
    [39.77],
    [39.78],
    [39.78],
    [39.76],
    [39.77],
    [39.77],
    [39.76],
    [39.75],
    [39.75],
    [39.75],
    [39.75],
    [39.75],
    [39.75],
    [39.75],
    [39.730000000000004],
    [39.74],
    [39.730000000000004],
    [39.71],
    [39.74],
    [39.74],
    [39.72],
    [39.730000000000004],
    [39.74],
    [39.730000000000004],
    [39.72],
    [39.72],
    [39.72],
    [39.730000000000004],
    [39.71],
    [39.72],
    [39.74],
    [39.730000000000004],
    [39.74],
    [39.74],
    [39.74],
    [39.75],
    [39.77],
    [39.78],
    [39.78],
    [39.78],
    [39.79],
    [39.78],
    [39.79],
    [39.78],
    [39.79],
    [39.78],
    [39.78],
    [39.78],
    [39.78],
    [39.75],
    [39.75],
    [39.72],
    [39.71],
    [39.69],
    [39.68],
    [39.67],
    [39.67],
    [39.64],
    [39.61],
    [39.6],
    [39.59],
    [39.58],
    [39.57],
    [39.57],
    [39.56],
    [39.54],
    [39.53],
    [39.53],
    [39.51],
    [39.5],
    [39.5],
    [39.47],
    [39.47],
    [39.44],
    [39.43],
    [39.42],
    [39.42],
    [39.42],
    [39.410000000000004],
    [39.4],
    [39.39],
    [39.38],
    [39.39],
    [39.37],
    [39.36],
    [39.37],
    [39.37],
    [39.38],
    [39.38],
    [39.37],
    [39.35],
    [39.35],
    [39.34],
    [39.35],
    [39.35],
    [39.35],
    [39.34],
    [39.35],
    [39.35],
    [39.36],
    [39.37],
    [39.37],
    [39.36],
    [39.39],
    [39.38],
    [39.38],
    [39.39],
    [39.4],
    [39.39],
    [39.4],
    [39.42],
    [39.410000000000004],
    [39.42],
    [39.42],
    [39.42],
    [39.44],
    [39.46],
    [39.46],
    [39.47],
    [39.480000000000004],
    [39.5],
    [39.5],
    [39.53],
    [39.52],
    [39.52],
    [39.53],
    [39.53],
    [39.53],
    [39.550000000000004],
    [39.54],
    [39.56],
    [39.56],
    [39.550000000000004],
    [39.57],
    [39.58],
    [39.57],
    [39.56],
    [39.58],
    [39.6],
    [39.59],
    [39.6],
    [39.6],
    [39.62],
    [39.61],
    [39.61],
    [39.62],
    [39.61],
    [39.63],
    [39.64],
    [39.63],
    [39.64],
    [39.64],
    [39.64],
    [39.660000000000004],
    [39.660000000000004],
    [39.67],
    [39.67],
    [39.67],
    [39.68],
    [39.67],
    [39.67],
    [39.67],
    [39.68],
    [39.67],
    [39.67],
    [39.69],
    [39.67],
    [39.69],
    [39.71],
    [39.71],
    [39.71],
    [39.730000000000004],
    [39.730000000000004],
    [39.72],
    [39.75],
    [39.75],
    [39.75],
    [39.77],
    [39.77],
    [39.78],
    [39.78],
    [39.79],
    [39.78],
    [39.78],
    [39.78],
    [39.76],
    [39.78],
    [39.77],
    [39.75],
    [39.76],
    [39.76],
    [39.76],
    [39.76],
    [39.75],
    [39.74],
    [39.730000000000004],
    [39.75],
    [39.75],
    [39.75],
    [39.75],
    [39.75],
    [39.75],
    [39.730000000000004],
    [39.75],
    [39.74],
    [39.75],
    [39.74],
    [39.74],
    [39.72],
    [39.71],
    [39.71],
    [39.71],
    [39.67],
    [39.68],
    [39.67],
    [39.64],
    [39.62],
    [39.6],
    [39.59],
    [39.57],
    [39.56],
    [39.53],
    [39.53],
    [39.51],
    [39.49],
    [39.46],
    [39.44],
    [39.44],
    [39.410000000000004],
    [39.39],
    [39.4],
    [39.36],
    [39.35],
    [39.36],
    [39.35],
    [39.33],
    [39.32],
    [39.32],
    [39.32],
    [39.31],
    [39.31],
    [39.300000000000004],
    [39.28],
    [39.27],
    [39.27],
    [39.25],
    [39.26],
    [39.26],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.24],
    [39.26],
    [39.25],
    [39.26],
    [39.25],
    [39.26],
    [39.25],
    [39.27],
    [39.27],
    [39.26],
    [39.28],
    [39.28],
    [39.28],
    [39.27],
    [39.29],
    [39.28],
    [39.29],
    [39.28],
    [39.28],
    [39.29],
    [39.28],
    [39.28],
    [39.28],
    [39.300000000000004],
    [39.300000000000004],
    [39.29],
    [39.29],
    [39.29],
    [39.29],
    [39.300000000000004],
    [39.31],
    [39.29],
    [39.300000000000004],
    [39.32],
    [39.32],
    [39.32],
    [39.32],
    [39.33],
    [39.34],
    [39.35],
    [39.37],
    [39.36],
    [39.37],
    [39.38],
    [39.39],
    [39.38],
    [39.410000000000004],
    [39.4],
    [39.410000000000004],
    [39.42],
    [39.42],
    [39.43],
    [39.42],
    [39.42],
    [39.44],
    [39.43],
    [39.44],
    [39.45],
    [39.46],
    [39.46],
    [39.47],
    [39.49],
    [39.49],
    [39.5],
    [39.5],
    [39.480000000000004],
    [39.480000000000004],
    [39.46],
    [39.46],
    [39.45],
    [39.45],
    [39.44],
    [39.45],
    [39.42],
    [39.39],
    [39.38],
    [39.35],
    [39.34],
    [39.33],
    [39.31],
    [39.29],
    [39.27],
    [39.26],
    [39.25],
    [39.230000000000004],
    [39.21, EVENTS.FEVER_OVER],
    [39.21],
    [39.21],
    [39.2],
    [39.2],
    [39.17],
    [39.18],
    [39.18],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.15],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.14],
    [39.13],
    [39.13],
    [39.12],
    [39.11],
    [39.12],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.09],
    [39.07],
    [39.07],
    [39.06],
    [39.050000000000004],
    [39.03],
    [39.01],
    [39.01],
    [39.0],
    [39.0],
    [38.99],
    [38.97],
    [38.99],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.96],
    [38.96],
    [38.96],
    [38.97],
    [38.97],
    [38.97],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.03],
    [39.050000000000004],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.06],
    [39.07],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.12],
    [39.13],
    [39.14],
    [39.14],
    [39.15],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.19],
    [39.18],
    [39.2],
    [39.230000000000004],
    [39.21],
    [39.230000000000004],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.24],
    [39.25],
    [39.26],
    [39.26],
    [39.25],
    [39.26],
    [39.27],
    [39.28],
    [39.27],
    [39.28],
    [39.28],
    [39.300000000000004],
    [39.31],
    [39.31],
    [39.31],
    [39.31],
    [39.31],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.32],
    [39.31],
    [39.31],
    [39.32],
    [39.32],
    [39.32],
    [39.32],
    [39.31],
    [39.32],
    [39.34],
    [39.34],
    [39.32],
    [39.31],
    [39.300000000000004],
    [39.300000000000004],
    [39.300000000000004],
    [39.28],
    [39.28],
    [39.28],
    [39.27],
    [39.25],
    [39.24],
    [39.22],
    [39.21],
    [39.2],
    [39.19],
    [39.17],
    [39.2],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.18],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.19],
    [39.2],
    [39.19],
    [39.21],
    [39.21],
    [39.230000000000004],
    [39.22],
    [39.230000000000004],
    [39.230000000000004],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.24],
    [39.24],
    [39.25],
    [39.25],
    [39.27],
    [39.26],
    [39.28],
    [39.27],
    [39.26],
    [39.26],
    [39.26],
    [39.26],
    [39.26],
    [39.26],
    [39.27],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.24],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.2],
    [39.18],
    [39.18],
    [39.18],
    [39.18],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.14],
    [39.13],
    [39.11],
    [39.1],
    [39.11],
    [39.1],
    [39.1],
    [39.1],
    [39.08],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.03],
    [39.050000000000004],
    [39.04],
    [39.02],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.06],
    [39.06],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.07],
    [39.06],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.03],
    [39.04],
    [39.01],
    [39.050000000000004],
    [39.06],
    [39.050000000000004],
    [39.04],
    [39.03],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.03],
    [39.04],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.04],
    [39.04],
    [39.03],
    [39.04],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.07],
    [39.06],
    [39.06],
    [39.08],
    [39.06],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.09],
    [39.08],
    [39.09],
    [39.1],
    [39.09],
    [39.1],
    [39.11],
    [39.14],
    [39.15],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.18],
    [39.19],
    [39.19],
    [39.2],
    [39.21],
    [39.2],
    [39.19],
    [39.21],
    [39.21],
    [39.19],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.21],
    [39.19],
    [39.2],
    [39.21],
    [39.2],
    [39.2],
    [39.22],
    [39.230000000000004],
    [39.24],
    [39.230000000000004],
    [39.25],
    [39.25],
    [39.25],
    [39.26],
    [39.28],
    [39.28],
    [39.29],
    [39.300000000000004],
    [39.31],
    [39.32],
    [39.33],
    [39.34],
    [39.34],
    [39.35],
    [39.35],
    [39.36],
    [39.37],
    [39.36],
    [39.37],
    [39.37],
    [39.39],
    [39.38],
    [39.37],
    [39.39],
    [39.38],
    [39.38],
    [39.39],
    [39.38],
    [39.39],
    [39.39],
    [39.38],
    [39.4],
    [39.39],
    [39.39],
    [39.39],
    [39.42],
    [39.410000000000004],
    [39.410000000000004],
    [39.42],
    [39.4],
    [39.410000000000004],
    [39.39],
    [39.39],
    [39.39],
    [39.38],
    [39.39],
    [39.38],
    [39.39],
    [39.39],
    [39.42],
    [39.42],
    [39.43],
    [39.42],
    [39.44],
    [39.43],
    [39.42],
    [39.410000000000004],
    [39.38],
    [39.35],
    [39.35],
    [39.32],
    [39.32],
    [39.29],
    [39.300000000000004],
    [39.28],
    [39.28],
    [39.27],
    [39.26],
    [39.25],
    [39.25],
    [39.24],
    [39.22],
    [39.24],
    [39.21],
    [39.2],
    [39.18],
    [39.18],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.15],
    [39.14],
    [39.15],
    [39.14],
    [39.14],
    [39.160000000000004],
    [39.15],
    [39.14],
    [39.15],
    [39.12],
    [39.13],
    [39.1],
    [39.1],
    [39.050000000000004],
    [39.04],
    [39.050000000000004],
    [39.04],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.03],
    [39.04],
    [39.03],
    [39.06],
    [39.04],
    [39.050000000000004],
    [39.07],
    [39.07],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.11],
    [39.11],
    [39.1],
    [39.12],
    [39.13],
    [39.11],
    [39.12],
    [39.11],
    [39.12],
    [39.13],
    [39.14],
    [39.14],
    [39.13],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.15],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.15],
    [39.15],
    [39.14],
    [39.14],
    [39.14],
    [39.15],
    [39.15],
    [39.15],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.19],
    [39.19],
    [39.19],
    [39.19],
    [39.2],
    [39.2],
    [39.19],
    [39.18],
    [39.17],
    [39.19],
    [39.19],
    [39.18],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.15],
    [39.15],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.160000000000004],
    [39.160000000000004],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.17],
    [39.18],
    [39.18],
    [39.19],
    [39.18],
    [39.2],
    [39.2],
    [39.21],
    [39.21],
    [39.21],
    [39.24],
    [39.22],
    [39.230000000000004],
    [39.25],
    [39.24],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.25],
    [39.24],
    [39.24],
    [39.230000000000004],
    [39.230000000000004],
    [39.22],
    [39.21],
    [39.21],
    [39.2],
    [39.2],
    [39.19],
    [39.18],
    [39.160000000000004],
    [39.160000000000004],
    [39.14],
    [39.14],
    [39.13],
    [39.11],
    [39.1],
    [39.11],
    [39.1],
    [39.08],
    [39.07],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.07],
    [39.07],
    [39.06],
    [39.08],
    [39.07],
    [39.07],
    [39.07],
    [39.08],
    [39.09],
    [39.09],
    [39.06],
    [39.08],
    [39.08],
    [39.07],
    [39.07],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.03],
    [39.03],
    [39.01],
    [39.0],
    [39.01],
    [38.980000000000004],
    [38.980000000000004],
    [38.95],
    [38.93],
    [38.92],
    [38.92],
    [38.910000000000004],
    [38.92],
    [38.93],
    [38.93],
    [38.92],
    [38.9],
    [38.910000000000004],
    [38.89],
    [38.89],
    [38.89],
    [38.87],
    [38.88],
    [38.87],
    [38.87],
    [38.89],
    [38.87],
    [38.87],
    [38.87],
    [38.89],
    [38.89],
    [38.88],
    [38.89],
    [38.910000000000004],
    [38.9],
    [38.9],
    [38.910000000000004],
    [38.92],
    [38.93],
    [38.93],
    [38.94],
    [38.94],
    [38.95],
    [38.96],
    [38.97],
    [38.980000000000004],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [38.99],
    [38.99],
    [39.0],
    [39.0],
    [39.0],
    [39.01],
    [39.0],
    [39.0],
    [38.99],
    [39.0],
    [39.02],
    [39.02],
    [39.01],
    [39.03],
    [39.0],
    [39.0],
    [39.01],
    [39.01],
    [39.02],
    [39.01],
    [39.03],
    [39.01],
    [39.02],
    [39.03],
    [39.03],
    [39.02],
    [39.02],
    [39.06],
    [39.050000000000004],
    [39.03],
    [39.050000000000004],
    [39.050000000000004],
    [39.050000000000004],
    [39.07],
    [39.06],
    [39.07],
    [39.07],
    [39.08],
    [39.07],
    [39.08],
    [39.08],
    [39.08],
    [39.09],
    [39.1],
    [39.09],
    [39.08],
    [39.1],
    [39.08],
    [39.09],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [39.1],
    [38.96],
    [37.980000000000004],
    [36.84, EVENTS.SENSOR_FALL_DETECTED],
    [35.85],
    [35.050000000000004],
    [34.38],
    [34.38],
    [34.38],
    [34.38],
    [34.38],
    [34.38],
    [34.38],
    [34.38],
    [34.38],
    [33.79],
    [33.32],
    [32.95],
    [32.6],
    [32.28],
    [31.98],
    [31.71],
    [31.5],
    [31.25],
    [31.02],
    [30.89],
    [30.78],
    [30.67],
    [30.580000000000002],
    [30.5],
    [30.42],
    [30.34],
    [30.25],
    [30.150000000000002],
    [30.0],
    [29.94],
    [29.87],
    [29.79],
    [29.73],
    [29.7],
    [29.61],
    [29.53],
    [29.41],
    [29.39],
    [34.33],
    [34.29],
    [31.17],
    [28.37],
    [26.32],
    [28.16],
    [33.53],
    [38.57],
    [42.44],
    [44.79],
    [43.04],
    [40.14],
    [37.62],
    [35.59],
    [34.13],
    [32.99],
    [32.05],
    [31.25],
    [30.580000000000002],
    [29.98],
    [29.45],
    [28.96],
    [28.51],
    [28.1],
    [27.73],
    [27.400000000000002],
    [27.080000000000002],
    [26.79],
    [26.51],
    [26.04],
    [25.84],
    [25.62],
    [25.45],
    [25.27],
    [25.11],
    [24.98],
    [24.830000000000002],
    [24.71],
    [24.57],
    [24.47],
    [24.39],
    [24.28],
    [24.19],
    [24.1],
    [24.05],
    [23.97],
    [23.89],
    [23.85],
    [23.79],
    [23.740000000000002],
    [23.73],
    [23.66],
    [23.62],
    [23.580000000000002],
    [23.55],
    [23.52],
    [23.48],
    [23.45],
    [23.42],
    [23.400000000000002],
    [23.39],
    [23.35],
    [23.330000000000002],
    [23.32],
    [23.31],
    [23.28],
    [23.25],
    [23.25],
    [23.240000000000002],
    [23.21],
    [23.21],
    [23.19],
    [23.17],
    [23.17],
    [23.16],
    [23.14],
    [23.14],
    [23.13],
    [23.11],
    [23.1],
    [23.1],
    [23.080000000000002],
    [23.07],
    [23.07],
    [23.05],
    [23.04],
    [23.03],
    [23.02],
    [23.02],
    [23.0],
    [23.0],
    [23.0],
    [23.0],
    [22.98],
    [22.97],
    [22.96],
    [22.95],
    [22.94],
    [22.93],
    [22.92],
    [22.92],
    [22.92],
    [22.91],
];

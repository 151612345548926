import {
    SET_CONFIGS,
    SET_AUTO_SORTING
} from "../../actions/actionTypes";

export default function (state = "initial", action) {
    switch (action.type) {
    /*case SET_CONFIGS:
        const config = action.configs.find(c => c.name == "DO_NOT_DISTURB_PHONE");
        if (config.value === "on") return true;
        else return false;*/
    case SET_AUTO_SORTING:
        return state = action.button;
    default: return state;
    }
}
import { NAVIGATE_FORWARD, NAVIGATE_BACK, FORCE_NAVIGATE_TO } from "../actions/actionTypes";

const forwardT = {
    from: { opacity: 0, ...mobileTransformCss("translate3d(100%,0,0)"), position: "absolute" },
    enter: { opacity: 1, ...mobileTransformCss("translate3d(0%,0,0)") },
    leave: { opacity: 0, ...mobileTransformCss("translate3d(-100%,0,0)") },
};

const backwardT = {
    from: { opacity: 0, ...mobileTransformCss("translate3d(-100%,0,0)"), position: "absolute" },
    enter: { opacity: 1, ...mobileTransformCss("translate3d(0%,0,0)") },
    leave: { opacity: 0, ...mobileTransformCss("translate3d(100%,0,0)") },
};

const initial = {
    routes: ["/"],
    config: forwardT
};

export default function (state = initial, action) {
    switch ( action.type ){
    case NAVIGATE_FORWARD: return { routes: state.routes.length == 0 ? ["/", action.to] : [...state.routes, action.to], config: forwardT };
    case NAVIGATE_BACK: return { routes: state.routes.filter((v, i) => i != state.routes.length-1), config: backwardT };
    case FORCE_NAVIGATE_TO:
        if (action.to == state.routes[state.routes.length-1]) return state;
        // eslint-disable-next-line no-case-declarations
        const i = state.routes.findIndex((el) => el == action.to);
        if (i != -1 && i+1 != state.routes.length ){
            return {
                ...state,
                routes: state.routes.slice(0, -1 * (i - 1))
            };
        }
        return {
            routes: state.routes.length == 0 ?
                ["/", action.to]
                : [ ...state.routes, action.to]
        };
    default: return state;
    }
}


function mobileTransformCss(translate){
    return {
        "MsTransform": translate,
        "WebkitTransform": translate,
        "MozTransform": translate,
        "transform": translate
    };
}


import React, { useContext, useState, useRef } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import Button from "../Button";
import { RouterContext } from "../../containers/Contexts";
import { submitPairingsFromExternal } from "../../store/actions";
import Time from "../Time";
import i18n from "../../i18n";
import { useOnClickOutside } from "../outsideClick";

import Line from "./Line";

import "./PotentialList.css";

function PotentialList({
    lifecycle,
    list,
    listStartTime,
    listEndTime,
    submitPairingsFromExternal,
}) {
    const [pairings, setPairings] = useState([]);
    const navigate = useContext(RouterContext);
    const potentialCalvingsRef = useRef();
    useOnClickOutside(potentialCalvingsRef, navigate.back);

    function onLineClicked(add, pair) {
        if (add) {
            setPairings([...pairings, pair]);
        } else {
            setPairings(pairings.filter((p) => p.cow !== pair.cow));
        }
    }

    const addedCows = pairings.map((p) => p.cow);
    const options = { year: "numeric", month: "short", day: "numeric" };
    const enabled = pairings.length > 0;
    function submitPairings(pairings) {
        submitPairingsFromExternal(pairings);
        navigate.forward("/pairing");
    }
    return (
        <main className="potential-calvings" ref={potentialCalvingsRef}>
            <div className="header">
                <div>
                    <h1>{i18n.t("potentialCalvings")}</h1>
                    <h5>
                        <Time time={listStartTime} options={options} /> &nbsp;
                        {listEndTime > 0 ? (
                            <>
                                &#8208;&nbsp;
                                <Time
                                    time={listEndTime}
                                    options={options}
                                    useFunc
                                />
                            </>
                        ) : null}
                    </h5>
                </div>
                <div className="close-button" onClick={() => navigate.back()} />
            </div>
            <div className="potential-list">
                {lifecycle === "pending" ? (
                    <p>{i18n.t("loading")}</p>
                ) : (
                    <ul>
                        {list.map((l) => {
                            const paired = !l.suggestion;
                            const isAdded = addedCows.includes(l.cowId);
                            const unavailable = listEndTime > 0;
                            return (
                                <Line
                                    key={l.cowId}
                                    onClicked={(add, pair) =>
                                        !paired && !unavailable
                                            ? onLineClicked(add, pair)
                                            : null
                                    }
                                    {...{ paired, ...l, isAdded, unavailable }}
                                />
                            );
                        })}
                    </ul>
                )}
            </div>
            <div className="footer">
                {listEndTime === 0 ? (
                    <Button
                        className={cn({ disabled: !enabled })}
                        type="action"
                        text={
                            enabled
                                ? i18n.t("sendToPairingList")
                                : i18n.t("plaseSelectCows")
                        }
                        onClicked={() => submitPairings(pairings)}
                        disabled={!enabled}
                    />
                ) : (
                    <p>{i18n.t("pleaseTryToPairFromLatestList")}</p>
                )}
            </div>
        </main>
    );
}

function mapStateToProps({ potentialList, events }) {
    const listStartTime = events[potentialList.id]
        ? events[potentialList.id].timestamp
        : 0;
    return { ...potentialList, listStartTime };
}

const mapDispatchToProps = { submitPairingsFromExternal };

export default connect(mapStateToProps, mapDispatchToProps)(PotentialList);

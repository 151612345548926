import React, { useState, useEffect } from "react";
import PT from "prop-types";
import cn from "classnames";

export function SearchResults({
    classNames,
    results,
    highlight,
    onSelect,
    render,
    isCategorizedResults = false,
}) {
    const [hoverState, setHoverState] = useState(0);
    useEffect(() => {
        if (isCategorizedResults) {
            document.addEventListener("keydown", onKeyDown, true);
            return () => {
                document.removeEventListener("keydown", onKeyDown, true);
            };
        }
    });
    function onMouseOver({ target }) {
        setHoverState(parseInt(target.closest("li").getAttribute("data-key")));
    }
    function onKeyDown({ keyCode }) {
        if (keyCode == 40) {
            // arrow down
            if (hoverState === results.length - 1) {
                setHoverState(0);
                return;
            }
            setHoverState(hoverState + 1);
        }
        if (keyCode == 38) {
            // arrow up
            if (hoverState === 0) {
                setHoverState(results.length - 1);
                return;
            }
            setHoverState(hoverState - 1);
        }
        if (keyCode == 13) {
            // enter
            document
                .querySelectorAll("ul.filter-results li")
                [hoverState].click();
            setHoverState(0);
        }
    }
    let resultsDOM = null;
    if (isCategorizedResults) {
        resultsDOM = results.map((result, index) => {
            return (
                <li
                    onClick={(event) => onSelect(result, event)}
                    onMouseOver={onMouseOver}
                    className={cn({ hover: results[hoverState] === result })}
                    key={result.toJSON}
                    data-key={index}>
                    <SearchResult highlight={highlight}>
                        {render(result)}
                    </SearchResult>
                </li>
            );
        });
    } else {
        resultsDOM = results.map((result) => (
            <li onClick={() => onSelect(result)} key={JSON.stringify(result)}>
                <SearchResult key={result} highlight={highlight}>
                    {render(result)}
                </SearchResult>
            </li>
        ));
    }
    return (
        <ul className={classNames ? classNames.join(" ") : "search-results"}>
            {resultsDOM}
        </ul>
    );
}

SearchResults.propTypes = {
    results: PT.array,
    highlight: PT.string,
    render: PT.func,
    onSelect: PT.func,
    isCategorizedResults: PT.bool,
    classNames: PT.array,
};

SearchResults.defaultProps = { render: (x) => x };

// TODO: dummy component, replace with real counterpart
function SearchResult({ children }) {
    return children;
}

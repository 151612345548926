export default function tagBasestation (currentTags, eventType) {
    switch(eventType){
        case "online-basestation-detected":
            return updateBasestationTags(currentTags, ["online"], ["offline", "idle"]);
        case "offline-basestation-detected":
            return updateBasestationTags(currentTags, ["offline"], ["online"]);
        case "improper-event":
        default:
            return null;
    }
}

function updateBasestationTags (currentTags, newTags, removableTags = []) {
    const substractedTags = currentTags.filter((tag) => !removableTags.includes(tag));
    const tags = [...new Set([...substractedTags, ...newTags])];
    return tags;
}
import { EVENTS } from "../../../constants";
import * as Event from "../utilities";

export function handleEvent(db, event) {
    switch (event.event ? event.event : event.type) {
        case EVENTS.PAIRING_LIST_CREATED:
            return Event.pairingListCreatedEvent(
                db,
                event.payload.list,
                1,
                false,
                event.timestamp,
            );
        case EVENTS.SMART_NOTE_SUBMITTED:
            return Event.smartNoteSubmittedEvent(
                db,
                event.payload,
                "1",
                event.timestamp,
            );
        case EVENTS.SESSION_COMPLETED:
            return Event.sessionCompletedEvent(
                db,
                event.payload.session,
                event.payload.sensor,
                1,
                event.timestamp,
            );
        case EVENTS.STABLE_DATA_DETECTED:
            return Event.stableDataDetectedEvent(
                db,
                event.payload.session,
                event.payload.sensor,
                event.timestamp,
            );
        case EVENTS.NOTE_SUBMITTED:
            return Event.noteSubmittedEvent(
                db,
                event.payload,
                1,
                event.timestamp,
            );
        case EVENTS.SESSION_TRASHED:
            return Event.sessionCompletedEvent(
                db,
                event.payload.session,
                event.payload.sensor,
                event.payload.user,
                event.timestamp,
            );
        case EVENTS.SESSION_CANCELED:
            return Event.sessionCanceledEvent(
                db,
                event.payload.session,
                event.payload.sensor,
                1,
                event.timestamp,
            );
        case EVENTS.NEW_COW_ACQUIRED:
            return Event.newCowAcquiredEvent(
                db,
                event.payload,
                1,
                event.timestamp,
            );
        case EVENTS.NEW_SENSOR_ACQUIRED:
            return Event.newSensorAcquiredEvent(
                db,
                event.payload.sensor,
                1,
                event.timestamp,
            );
        case EVENTS.COW_DIED:
            return Event.cowDiedEvent(db, event.payload.cow, event.timestamp);
        case EVENTS.NO_COMMUNICATION:
            return Event.noCommunicationEvent(
                db,
                event.payload.sensor,
                event.timestamp,
            );
        case EVENTS.COW_EDITED:
            return Event.cowEditedEvent(
                db,
                event.payload.cow,
                event.payload,
                1,
                event.timestamp,
            );
        case EVENTS.NEW_USER_ADDED:
            return Event.newUserAddedEvent(db, event.payload.email);
        case EVENTS.USER_DELETED:
            return Event.userDeletedEvent(db, event.payload.user);
        case EVENTS.USER_REGISTERED:
            return Event.userRegisteredEvent();
        case EVENTS.CALLED_USER_ACTIVATED:
            return Event.calledUserActivatedEvent();
        case EVENTS.PREGNANCY_OVER:
            return Event.pregnancyOverEvent(
                db,
                event.payload.cow,
                event.timestamp,
            );
        case EVENTS.PREGNANCY_DETECTED:
            return Event.pregnancyDetectedEvent(
                db,
                event.payload.cow,
                event.timestamp,
            );
        case EVENTS.FEVER_DETECTED:
            return Event.feverDetectedEvent(
                db,
                event.payload.cow,
                event.payload.temperature,
                event.timestamp,
            );
        case EVENTS.FEVER_OVER:
            return Event.feverOverEvent(
                db,
                event.payload.cow,
                event.payload.temperature,
                event.timestamp,
            );
        case EVENTS.ESTROUS_CYCLE_DETECTED:
            return Event.estrousCycleDetectedEvent(
                db,
                event.payload.cow,
                event.timestamp,
            );
        case EVENTS.POTENTIAL_CALVINGS_DETECTED:
            return Event.potentialCalvingsDetectedEvent(
                db,
                event.payload.list,
                event.timestamp,
            );
        case EVENTS.CALVING_DUE:
            return Event.calvingDueEvent(
                db,
                event.payload.cow,
                event.payload.estimate,
                event.timestamp,
            );
        case EVENTS.CALVING_DUE_FALSE:
            return Event.calvingDueFalseEvent(
                db,
                event.payload.cow,
                event.timestamp,
            );
        case EVENTS.CALVING_DETECTED:
            return Event.calvingDetectedEvent(
                db,
                event.payload.cow,
                event.timestamp,
            );
        case EVENTS.CALVING_COMPLETED:
            return Event.calvingCompletedEvent(
                db,
                event.payload.cow,
                1,
                event.timestamp,
            );
        case EVENTS.SENSOR_ACTIVATED:
            return Event.sensorActivatedEvent(
                db,
                event.payload.sensor,
                event.timestamp,
            );
        case EVENTS.UNSTABLE_DATA_DETECTED:
            return Event.unstableDataDetectedEvent(
                db,
                event.payload.sensor,
                event.timestamp,
            );
        case EVENTS.FEVER_INCREASE_DETECTED:
        case EVENTS.HYPOTHERMIA_DETECTED:
        case EVENTS.HYPOTHERMIA_OVER:
        case EVENTS.CALVING_OVERDUE:
        case EVENTS.LOW_BATTERY_DETECTED:
        case EVENTS.SENSOR_RESET:
        case EVENTS.ONLINE_BASESTATION_DETECTED:
        case EVENTS.OFFLINE_BASESTATION_DETECTED:
        case EVENTS.ONLINE_REPEATER_DETECTED:
        case EVENTS.OFFLINE_REPEATER_DETECTED:
        case EVENTS.CALL_FAILED:
        case EVENTS.CALVING_CONFIRM_WARNINGS:
        case EVENTS.HEAT_DETECTED:
        case EVENTS.HEAT_OVER:
        case EVENTS.SENSOR_FALL_DETECTED:
        case EVENTS.LONG_DUE_DETECTED:
        case EVENTS.IMMINENT_DUE_DETECTED:
        case EVENTS.SENSOR_TRANSFER_OVER:
        case EVENTS.SENSOR_TRANSFER_STARTED:
        case EVENTS.COW_LIST_UPDATED:
        case EVENTS.REQUEST_FOR_COW_LIST:
        case EVENTS.DRY_COW_REPORTED:
        case EVENTS.PREGNANCY_TRACKING_STARTED:
        case EVENTS.DRY_COW_CANCELED:
            return Event.processStandaloneEvent(db, event, 1);
        default:
            console.log(event);
            throw new Error("Unknown event!");
    }
}

import {
    ADD_PAIRING,
    DROP_PAIRING,
    UPDATE_PAIRING_AVAILABILITY,
    SHOW_PAIRING_UI,
    HIDE_PAIRING_UI,
    SUBMIT_PAIRING_LIST,
    SUBMIT_COWS_FROM_COWLIST,
    PROCESS_REQUIRED_INFO,
    SUBMIT_PAIRINGS_FROM_EXTERNAL,
    RESET_PAIRINGS_FROM_EXTERNAL
} from "./actionTypes";

export function addPairing(cow, sensor) {
    return { type: ADD_PAIRING, cow, sensor };
}

export function dropPairing(cow, sensor) {
    return { type: DROP_PAIRING, cow, sensor };
}

export function updatePairingAvailability(cows, sensors) {
    return { type: UPDATE_PAIRING_AVAILABILITY, cows, sensors };
}

export function showPairingUI() {
    return { type: SHOW_PAIRING_UI };
}

export function hidePairingUI() {
    return { type: HIDE_PAIRING_UI };
}

export function submitPairingList(list) {
    return { type: SUBMIT_PAIRING_LIST, list };
}

export function submitCowsFromCowList(cows) {
    return { type: SUBMIT_COWS_FROM_COWLIST, cows };
}

export function processRequiredInfo(cow, required, date){
    return { type: PROCESS_REQUIRED_INFO, cow, required, date };
}

export function submitPairingsFromExternal(list) {
    return { type: SUBMIT_PAIRINGS_FROM_EXTERNAL, list };
}
export function resetPairingsFromExternal() {
    return { type: RESET_PAIRINGS_FROM_EXTERNAL };
}


import i18n from "../../../i18n";
const currentLanguage = i18n.language;

const farms = {
    en: [{
        "id": "1",
        "name": "Local Farm",
        "location": "",
        "lastVisit": 1587135881000,
        "totalCows": 2,
        "trackedCows": 1
    }],
    de: [{
        "id": "1",
        "name": "Lokaler Hof",
        "location": "",
        "lastVisit": 1587135881000,
        "totalCows": 2,
        "trackedCows": 1
    }],
    tr: [{
        "id": "1",
        "name": "Yerel Çiftlik",
        "location": "",
        "lastVisit": 1587135881000,
        "totalCows": 2,
        "trackedCows": 1
    }],
    it: [{
        "id": "1",
        "name": "Fattoria Locale",
        "location": "",
        "lastVisit": 1587135881000,
        "totalCows": 2,
        "trackedCows": 1
    }],
    da: [{
        "id": "1",
        "name": "Lokal Gård",
        "location": "",
        "lastVisit": 1587135881000,
        "totalCows": 2,
        "trackedCows": 1
    }]
};

export default farms[currentLanguage];
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import Event from "../Events/Event";
import { EVENTS } from "../../constants/schema";
import "./EventGroup.css";

const importantEvents = [
    EVENTS.CALVING_DETECTED,
    EVENTS.CALVING_DUE,
    EVENTS.HEAT_DETECTED,
    EVENTS.SENSOR_FALL_DETECTED,
];

function EventGroup({
    id,
    eventIds,
    eventLinks,
    events,
    isSlided,
    setIsSlided,
    onClicked = null,
    isFocused,
}) {
    const [isFocusedLocal, setIsFocusedLocal] = useState(isFocused);
    const focused = onClicked ? isFocused : isFocusedLocal;
    useEffect(() => {
        if (isSlided) {
            if (focused) {
                setIsFocusedLocal(!focused);
            }
            setTimeout(() => setIsSlided(false), 200);
        }
    }, [isSlided, focused, setIsSlided]);
    const eventRef = useRef();

    function insideClick(event) {
        event.stopPropagation();
        const isActionButton =
            event.target.closest("div.action-buttons") ||
            event.target.closest("div.smart-note") ||
            event.target.closest("button.mark-read.unread");
        if (!isActionButton) {
            if (onClicked) {
                if (focused) {
                    onClicked(null);
                } else {
                    onClicked(id);
                }
            } else {
                setIsFocusedLocal(!focused);
            }
        }
    }
    const eventGroups = [];
    if (eventIds) {
        let isFeverOverExist = false;
        eventIds.map((id, index, currentArray) => {
            const eventType = events[id].type;
            const isImportantEvent = importantEvents.includes(eventType);

            if (!isFeverOverExist) {
                isFeverOverExist =
                    eventType === EVENTS.FEVER_OVER &&
                    !!currentArray.find(
                        (e, i) =>
                            i < index && events[e].type === EVENTS.FEVER_OVER,
                    );
            }

            if (index > 6 && !focused) return null;
            eventGroups.push(
                <Event
                    key={id}
                    id={id}
                    element="li"
                    linkedEvent={eventLinks[id]}
                    eventViewType={
                        index === 0 || focused
                            ? "open"
                            : isImportantEvent
                            ? "halfOpen"
                            : index <= 5
                            ? "close"
                            : "dashed"
                    }
                    focused={focused}
                    isFeverOverExist={isFeverOverExist}
                />,
            );
        });
    }
    return (
        <div
            id={`g${id}`}
            className={cn("cow-events", { focused })}
            ref={eventRef}
            onClick={insideClick}>
            {eventGroups}
        </div>
    );
}

function mapStateToProps({ events }) {
    return { events };
}

export default connect(mapStateToProps)(EventGroup);

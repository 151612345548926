import { take, put, call, spawn } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
    REMOVE_USER,
    START_REMOVE_USER_REQUEST,
    COMPLETE_REMOVE_USER_REQUEST,
    FAIL_REMOVE_USER_REQUEST,
    RESET_REMOVE_USER,
    REDIRECT_CALL,
    START_REDIRECT_CALL,
    COMPLETE_REDIRECT_CALL,
    FAIL_REDIRECT_CALL,
    RESET_REDIRECT_CALL,
} from "../actions/actionTypes";
import { EVENTS } from "../../constants/schema";
import axios from "axios";

export function* removeUserRequest() {

    const { userId } = yield take(REMOVE_USER);
    yield put({ type: START_REMOVE_USER_REQUEST });
    try {
        const finishEvent = { event: EVENTS.USER_DELETED, payload: { user: userId } };
        const { data } = yield call(axios.post, "/v2/events", finishEvent);
        yield put({ type: COMPLETE_REMOVE_USER_REQUEST, data });
        yield call(delay, 2000);
        yield put({ type: RESET_REMOVE_USER });
    } catch (error) {
        yield put({ type: FAIL_REMOVE_USER_REQUEST, error });
    } finally {
        yield spawn(removeUserRequest);
    }
}

export function* redirectCall(){
    const { userId } = yield take(REDIRECT_CALL);
    yield put({ type: START_REDIRECT_CALL });
    try {
        const finishEvent = { event: "called-user-activated", payload: { user: userId } };
        const { data } = yield call(axios.post, "/v2/events", finishEvent);
        yield put({ type: COMPLETE_REDIRECT_CALL, data });
        yield call(delay, 2000);
        yield put({ type: RESET_REDIRECT_CALL });
    } catch (error) {
        yield put({ type: FAIL_REDIRECT_CALL, error });
    } finally {
        yield spawn(redirectCall);
    }
}


import { connect } from "react-redux";

import { SESSION, EVENTS, COW } from "../constants/schema";

const mapStateToProps = (
    {
        cows,
        tags,
        sessions,
        events,
        eventView,
        oldItems,
        automation,
        climateSens,
    },
    { filter = "all", sort = "farmNumber" },
) => {
    function activeOrRecentlyArchived(id, tags) {
        if (tags.includes(SESSION.ACTIVE) || tags.includes(SESSION.PENDING)) {
            return true;
        }
        if (tags.includes(SESSION.TRASHED)) {
            return false;
        }
        if (tags.includes(SESSION.ARCHIVED)) {
            // get timestamp of the event that archived the session
            const archivedAt = Object.values(events).reduce(
                (current, event) => {
                    if (
                        event.type === EVENTS.SESSION_COMPLETED &&
                        event.payload.session === id &&
                        event.timestamp > current
                    ) {
                        return event.timestamp;
                    } else {
                        return current;
                    }
                },
                0,
            );

            // check and return true if timestamp is in the last 48 hours
            const fortyEightHours = 172800000;
            return archivedAt + fortyEightHours > Date.now();
        }
        return false;
    }

    function checkPriority(tagsBefore, tagsAfter) {
        const diff = tagsAfter.filter((t) => !tagsBefore.includes(t));
        if (diff.includes(COW.CALVING)) {
            return 1;
        } else if (
            diff.includes(COW.DUE) &&
            !tagsBefore.includes(COW.CALVING)
        ) {
            return 1;
        } else if (
            diff.includes(COW.FEVER) &&
            !tagsBefore.includes(COW.DUE) &&
            !tagsBefore.includes(COW.CALVING)
        ) {
            return 1;
        } else if (
            diff.includes(COW.HYPOTHERMIA) &&
            !tagsBefore.includes(COW.FEVER) &&
            !tagsBefore.includes(COW.DUE) &&
            !tagsBefore.includes(COW.CALVING)
        ) {
            return 1;
        } else if (
            diff.includes(COW.HEAT) &&
            !tagsBefore.includes(COW.HYPOTHERMIA) &&
            !tagsBefore.includes(COW.FEVER) &&
            !tagsBefore.includes(COW.DUE) &&
            !tagsBefore.includes(COW.CALVING)
        ) {
            return 1;
        } else if (
            diff.includes(COW.SENSOR_FALLEN) &&
            !tagsBefore.includes(COW.HEAT) &&
            !tagsBefore.includes(COW.HYPOTHERMIA) &&
            !tagsBefore.includes(COW.FEVER) &&
            !tagsBefore.includes(COW.DUE) &&
            !tagsBefore.includes(COW.CALVING)
        ) {
            return 1;
        } else if (diff.includes(COW.TRACKED)) {
            return 1;
        } else {
            return -1;
        }
    }

    const sorts = {
        farmNumber(cowIds) {
            return cowIds.sort((a, b) => {
                return cows[a].farmNumber - cows[b].farmNumber;
            });
        },
        priority(cowIds) {
            return cowIds
                .sort((a, b) => {
                    return cows[b].farmNumber - cows[a].farmNumber;
                })
                .sort((a, b) => {
                    return checkPriority(
                        tags.cow[a].map((t) => t.value),
                        tags.cow[b].map((t) => t.value),
                    );
                });
        },
        events(cowIds) {
            if (Object.keys(eventView.eventIds.cow).length === 0) return cowIds;
            return cowIds.sort((a, b) => {
                return (
                    events[eventView.eventIds.cow[b][0]].timestamp -
                    events[eventView.eventIds.cow[a][0]].timestamp
                );
            });
        },
        tracking(cowIds) {
            if (Object.keys(eventView.eventIds.cow).length === 0) return cowIds;
            return cowIds.sort((a, b) => {
                return (
                    events[
                        eventView.eventIds.cow[b][
                            eventView.eventIds.cow[b].length - 1
                        ]
                    ].timestamp -
                    events[
                        eventView.eventIds.cow[a][
                            eventView.eventIds.cow[a].length - 1
                        ]
                    ].timestamp
                );
            });
        },
    };

    const filters = {
        all() {
            return Object.keys(cows);
        },
        tagged() {
            return Object.values(cows)
                .filter((cow) => tags.cow[cow.id])
                .map((cow) => cow.id);
        },
        inSession() {
            return Object.values(sessions)
                .filter(({ id }) => {
                    const tagValues = tags.session[id].map((tag) => tag.value);
                    return (
                        tagValues &&
                        tagValues[0] &&
                        tagValues[0] !== SESSION.TRASHED
                    );
                })
                .map(({ cow }) => cow)
                .reduce(
                    (ids, id) => (ids.includes(id) ? ids : [...ids, id]),
                    [],
                );
        },
        inSessionPlus48h() {
            return Object.values(sessions)
                .filter(({ id }) => {
                    if (oldItems.sessionIds.includes(id)) return false;
                    const tagValues = tags.session[id].map((tag) => tag.value);
                    return tagValues && activeOrRecentlyArchived(id, tagValues);
                })
                .map(({ cow }) => cow)
                .reduce(
                    (ids, id) => (ids.includes(id) ? ids : [...ids, id]),
                    [],
                );
        },
    };
    const sortedCows = sorts[sort](filters[filter]());
    const cowsInDashboard = Object.values(cows).filter((cow) =>
        sortedCows.includes(cow.id),
    );
    return {
        cows: sortedCows,
        farmNumberDigits: cowsInDashboard
            .map((cow) => cow.farmNumber.toString().length)
            .reduce((a, b) => Math.max(a, b), 1),
        automation,
        climateSens,
    };
};

export default connect(mapStateToProps);

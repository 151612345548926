import { put } from "redux-saga/effects";
import { SET_LAST_EMAIL } from "../actions/actionTypes";

export function *lastEmail() {
    const lastEmail = yield localStorage.getItem("lastEmail");
    if (lastEmail){
        yield put({
            type: SET_LAST_EMAIL,
            lastEmail
        });
    }

}

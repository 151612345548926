import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const names = {
    Tsens: "Tsens",
    earTag: "earTag",
    climateSens: "climateSens",
    automationDevice: "automationDevice",
};

export default function SensorLabel({ type, deviceId, onClick }) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            {type ? names[type] || "Sensor" : null}
            {type && deviceId ? " " : ""}
            <strong onClick={onClick} title={t("finishSensorSession")}>
                {deviceId ? last4(deviceId) : null}
            </strong>
        </React.Fragment>
    );
}

export function last4(deviceId) {
    return deviceId.match(/([0-9A-F]{4})$/)[1];
}

SensorLabel.propTypes = {
    type: PropTypes.oneOf(["Tsens", "earTag"]),
    deviceId: PropTypes.string,
    onClick: PropTypes.func,
};

import React from "react";
import { Trans } from "react-i18next";

import { Cow } from "./titles";

export function ConfirmCalving({ cow }) {
    return (
        <>
            <Trans i18nKey="smartNote:smartNoteMessages:confirm">
                <Cow id={cow} /> completed calving.
            </Trans>
        </>
    );
}

export function AbortCalving({ cow }) {
    return (
        <Trans i18nKey="smartNote:smartNoteMessages:abort">
            <Cow id={cow} /> has not calved yet.
        </Trans>
    );
}

export function SensorFallHeat({ cow, isExamined, isDropped, isInseminated }) {
    if (isInseminated) {
        return (
            <Trans i18nKey="smartNote:smartNoteMessages:heatInseminated">
                <Cow id={cow} /> has been inseminated.
            </Trans>
        );
    }

    if (isExamined) {
        return (
            <Trans i18nKey="smartNote:smartNoteMessages:heatExamined">
                <Cow id={cow} /> has been examined.
            </Trans>
        );
    }

    if (isDropped) {
        return (
            <Trans i18nKey="smartNote:smartNoteMessages:heatDropped">
                <Cow id={cow} /> Tsens dropped from.
            </Trans>
        );
    }
}

export function SensorFallHealth({ isPlaced }) {
    if (isPlaced) {
        return (
            <Trans i18nKey="smartNote:smartNoteMessages:healthWillPlaced">
                Tsens will be placed again.
            </Trans>
        );
    } else {
        return (
            <Trans i18nKey="smartNote:smartNoteMessages:healthWontPlaced">
                Health tracking is completed.
            </Trans>
        );
    }
}

export function FeverOverTreatment({ cow }) {
    return (
        <>
            <Trans i18nKey="smartNote:smartNoteMessages:feverOverTreatment">
                Fever note is taken for <Cow id={cow} />.
            </Trans>
        </>
    );
}

export function HeatConfirm({ cow, isInseminated }) {
    if (isInseminated === "yes") {
        return (
            <>
                <Trans i18nKey="smartNote:smartNoteMessages:heatInseminated">
                    <Cow id={cow} /> is inseminated.
                </Trans>
            </>
        );
    } else {
        return (
            <>
                <Trans i18nKey="smartNote:smartNoteMessages:heatNotInseminated">
                    <Cow id={cow} /> is not inseminated.
                </Trans>
            </>
        );
    }
}

import React, { useState } from "react";
import cn from "classnames";
import DatePicker from "react-mobile-datepicker";

import Input from "./Input";
import Time from "./Time";
const monthMap = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
};

const dateConfig = {
    year: {
        format: "YYYY",
        caption: "Year",
        step: 1,
    },
    month: {
        format: (value) => monthMap[value.getMonth() + 1],
        caption: "Mon",
        step: 1,
    },
    date: {
        format: "DD",
        caption: "Day",
        step: 1,
    },
};

const hourConfig = {
    hour: {
        format: "hh",
        caption: "Hour",
        step: 1,
    },
    minute: {
        format: "mm",
        caption: "Min",
        step: 1,
    },
};

export default function MobileInput({
    isOpen,
    onSelect,
    label,
    value,
    hoursEnabled = true,
}) {
    const [open, setOpen] = useState(isOpen || false);
    const render = Time({
        time: value,
        useFunc: true,
        options: hoursEnabled
            ? {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
              }
            : {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
              },
    });
    return (
        <>
            <Input
                classNames={cn("mobile-datepicker")}
                type="input"
                label={label}
                readonly={true}
                onFocused={() => setOpen(true)}
                value={render}
            />
            <DatePicker
                theme="dark"
                dateConfig={
                    hoursEnabled ? { ...dateConfig, ...hourConfig } : dateConfig
                }
                value={value}
                isOpen={open}
                onSelect={(value) => {
                    setOpen(false);
                    onSelect(value);
                }}
                onCancel={() => setOpen(false)}
                confirmText={"Ok"}
                cancelText={"Cancel"}
                headerFormat={hoursEnabled ? "DD/MM/YYYY hh:mm" : "DD/MM/YYY"}
            />
        </>
    );
}

import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { pairingLists as connect } from "../../containers";
import PairingList from "../PairingList";

import "./PairingLists.css";

export function PairingLists({ lists, preview, onToggle }) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            {lists.map((id) => {
                const previewIsActive =
                    preview && preview.kind === "list" && id === preview.id;
                return (
                    <article
                        key={id}
                        className={cn("pairing-list", {
                            preview,
                            active: previewIsActive,
                        })}
                        title={t("clickToDownloadAPdfList")}
                        onClick={() => onToggle(id)}>
                        <PairingList id={id} />
                    </article>
                );
            })}
        </React.Fragment>
    );
}

export default connect(PairingLists);

/* eslint-disable react/display-name */
import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { useTransition, animated } from "react-spring";

import { NAVIGATE_FORWARD, NAVIGATE_BACK } from "../store/actions/actionTypes";
import ROUTES from "../constants/routes";
import Dashboard from "../components/Dashboard";
import LoginModal from "../components/LoginModal";
import PairingModal from "../components/PairingModal";
import CowListView from "../components/CowListView";
import YourProfile from "../components/YourProfile";
import MainMenu from "../components/NavBar/MainMenu";
import CowModal from "../components/CowModal";
import NetworkError from "../components/NetworkError";
import ErrorBoundary from "../components/ErrorBoundary";
import NotificationSettings from "../components/NotificationSettings";
import DashboardSettings from "../components/DashboardSettings";
import SwitchFarm from "../components/SwitchFarm";
import SensorManagement from "../components/SensorManagement";
import FarmSettings from "../components/FarmSettings";
import Dialog from "../components/Dialog";
import FarmMetrics from "../components/FarmMetrics";
import HealthReport from "../components/HealthReport";
import { setDialog } from "../store/actions";
import PotentialList from "../components/PotentialList";

import { RouterContext } from "./Contexts";

const pages = {
    [ROUTES.LOGIN]: () => <LoginModal />,
    [ROUTES.PAIRING_MODAL]: () => <PairingModal />,
    [ROUTES.COWS]: () => <CowListView />,
    [ROUTES.PROFILE]: () => <YourProfile />,
    [ROUTES.ADD_COW]: () => <CowModal />,
    [ROUTES.EDIT_COW]: () => <CowModal />,
    [ROUTES.NOTIFICATION_SETTINGS]: () => <NotificationSettings />,
    [ROUTES.DASHBOARD_SETTINGS]: () => <DashboardSettings />,
    [ROUTES.FARM_SETTINGS]: () => <FarmSettings />,
    [ROUTES.SWITCH_FARM]: () => <SwitchFarm />,
    [ROUTES.SENSORS]: () => <SensorManagement />,
    [ROUTES.MAIN_MENU]: () => <MainMenu />,
    "/": () => (
        <React.Fragment>
            <LoginModal />
            <Dashboard />
        </React.Fragment>
    ),
    [ROUTES.FARM_METRICS]: () => <FarmMetrics />,
    [ROUTES.HEALTH_REPORT]: () => <HealthReport />,
    [ROUTES.POTENTIAL_LIST]: () => <PotentialList />,
};
pages.displayName = "pages";

const App = ({ routes, config, exitAppConfirm, tour, ShepherdTour }) => {
    const [route, navigate, isExit] = useNavigation(routes);
    if (tour) {
        if (!ShepherdTour.isActive()) ShepherdTour.start();
    }
    if (isExit) {
        exitAppConfirm();
    }
    const transitions = useTransition(route, (route) => route, config);

    return transitions.map(({ item: route, props, key }) => {
        const Page = pages[route];
        return (
            <RouterContext.Provider key={key} value={navigate}>
                <animated.div key={key} style={props}>
                    <ErrorBoundary>
                        <Page />
                        <NetworkError />
                        <Dialog />
                    </ErrorBoundary>
                </animated.div>
            </RouterContext.Provider>
        );
    });
};

function mapStateToProps({ navigation: { routes, config } }) {
    return {
        routes,
        config,
    };
}

const mapDispatchToProps = {
    exitAppConfirm: () =>
        setDialog({
            type: "confirm",
            confirm: () =>
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({ type: "exit" }),
                ),
            text: "Çıkmak istediğinize emin misiniz?",
        }),
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

function useNavigation(routes) {
    const dispatch = useDispatch();
    const navigate = {};
    navigate.forward = useCallback(
        (next) => {
            dispatch({ type: NAVIGATE_FORWARD, to: next });
        },
        [dispatch],
    );
    navigate.back = useCallback(() => {
        dispatch({ type: NAVIGATE_BACK });
    }, [dispatch]);
    window.navigate = navigate;
    return [
        routes.length === 0 ? "/" : routes[routes.length - 1],
        navigate,
        routes.length === 0 ? true : false,
    ];
}

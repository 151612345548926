import React from "react";
import PropTypes from "prop-types";

export default function CowName({ children }) {
    try {
        return (
            <React.Fragment>
                {children.charAt(0).toLocaleUpperCase()}
                {children.substr(1).toLocaleLowerCase()}
            </React.Fragment>
        );
    } catch (e) {
        return children;
    }
}

CowName.propTypes = { children: PropTypes.string.isRequired };

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import Events from "../Events";
import NoteForm from "../NoteForm";
import Cows from "../Cows";
import NavBar from "../NavBar";
import hasLifecycle from "../../containers/Lifecycle";
import "./Dashboard.css";

function Dashboard({
    dashboardSettings: { autoSortingBy, disabled },
    preview,
}) {
    const [isFullscreen, setFullscreen] = useState(false);
    useEffect(() => {
        //Send loding done to React Native
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: "loading",
                value: false,
            }),
        );
    }, []);
    useEffect(() => {
        if (preview && preview.kind && preview.isFullscreen) {
            setFullscreen(true);
        } else {
            setFullscreen(false);
        }
    }, [preview]);
    return (
        <React.Fragment>
            <NavBar classNames={cn({ navigating: disabled })} />
            <main
                className={cn("dashboard", {
                    navigating: disabled,
                    fullscreen: isFullscreen,
                })}>
                <Cows filter="inSessionPlus48h" sort={autoSortingBy} />
                <section className="note-form">
                    <NoteForm />
                </section>
                <section className="events">
                    <Events />
                </section>
            </main>
        </React.Fragment>
    );
}
function mapStateToProps({ dashboardSettings, start_lifecycle, preview }) {
    return { dashboardSettings, lifecycle: start_lifecycle, preview };
}

export default connect(mapStateToProps)(hasLifecycle(Dashboard));

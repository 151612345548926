import React from "react";
import cn from "classnames";

import { automation as connect } from "../../containers";

import "./Automation.css";

const Automation = ({ onToggle, preview, placePairing: { id, placeName } }) => {
    const previewIsActive =
        preview && preview.kind === "automation" && id === preview.id;
    return (
        <article
            className={cn("automation", { preview, active: previewIsActive })}
            onClick={() => onToggle(id)}>
            <h5>{placeName}</h5>
            <div className="image" />
        </article>
    );
};

export default connect(Automation);

import {
    take, put, call, all, spawn, fork
} from "redux-saga/effects";
import { delay } from "redux-saga";
import { resourceRequest, pairingRequest } from "./";
import {
    START_POST_STARTUP,
    COMPLETE_POST_STARTUP,
    COMPLETE_APP_STARTUP,
    SET_COW_TYPES,
    SET_REPEATER_DATA,
    SET_BASESTATION_DATA
} from "../actions/actionTypes";
import axios from "axios";
import { UPDATE_RESOURCE } from "../actions/actionTypes";

export function *postStartup() {
    yield take(COMPLETE_APP_STARTUP);
    yield call(delay, 1000);
    yield put({ type: START_POST_STARTUP });
    yield all([
        call(resourceRequest, "cows"),
        fork(postSensorRequest),
        call(resourceRequest, "cowTypes", {
            type: SET_COW_TYPES,
            data: {}
        }),
        call(resourceRequest, "users")
    ]);
    yield fork(pairingRequest);
    yield put({ type: COMPLETE_POST_STARTUP });
}

function *postSensorRequest(){
    while (true) {
        try {
            const { data } = yield call(axios.get, "/sensors");
            for (const [resource, value] of Object.entries(data)){
                yield put({ type: UPDATE_RESOURCE, resource, data: value });
            }

            const repeaters = data.sensors.filter(s => s.type == "supervisor" || s.type == "repeater");
            yield spawn(periodicRepeaterData, repeaters);
            yield spawn(periodicBasestationData, data.basestations);
            break;
        } catch (e) {
            yield call(delay, 250);
        }
    }
}

function *periodicRepeaterData(repeaters){
    while (true) {
        for (const repeater of repeaters) {
            try {
                const { data } = yield call(axios.get, `/data/repeater/${repeater.id}`);
                yield put({
                    type: SET_REPEATER_DATA,
                    id: repeater.id,
                    data
                });
            } catch (e) {
                yield put({
                    type: SET_REPEATER_DATA,
                    id: repeater.id,
                    data: {}
                });
            }
            yield call(delay, 1000);
        }
        yield call(delay, 60000);
    }
}

function *periodicBasestationData(basestations){
    while (true) {
        for (const basestation of basestations) {
            try {
                const { data } = yield call(axios.get, `/data/basestation/${basestation.id}`);
                yield put({
                    type: SET_BASESTATION_DATA,
                    id: basestation.id,
                    data
                });
            } catch (e) {
                yield put({
                    type: SET_BASESTATION_DATA,
                    id: basestation.id,
                    data: {}
                });
            }
            yield call(delay, 1000);
        }
        yield call(delay, 60000);
    }
}

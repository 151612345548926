import { take, put, spawn, call } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
    SUBMIT_COWS_FROM_COWLIST,
    UPDATE_COWS_FROM_COWLIST,
    NAVIGATE_BACK,
    SHOW_PAIRING_UI
} from "../actions/actionTypes";
import { navigateForward } from "../actions";
import ROUTES from "../../constants/routes";

export function *updateCowList() {
    const { cows } = yield take(SUBMIT_COWS_FROM_COWLIST);
    yield put({ type: UPDATE_COWS_FROM_COWLIST, cows });
    yield call(delay, 100);
    yield put(navigateForward(ROUTES.PAIRING_MODAL));
    yield spawn(updateCowList);
}
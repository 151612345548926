import { take, put, call, spawn } from "redux-saga/effects";
import { delay } from "redux-saga";
import {
    UPDATE_INFORMATION,
    START_UPDATE_INFORMATION_REQUEST,
    COMPLETE_UPDATE_INFORMATION_REQUEST,
    FAIL_UPDATE_INFORMATION_REQUEST,
    RESET_YOUR_PROFILE,
    REGISTER_USER,
    START_REGISTER_USER,
    COMPLETE_REGISTER_USER,
    FAIL_REGISTER_USER
} from "../actions/actionTypes";
import { EVENTS } from "../../constants/schema";
import axios from "axios";

export function* updateInformation() {

    const { user } = yield take(UPDATE_INFORMATION);
    yield put({ type: START_UPDATE_INFORMATION_REQUEST });
    try {

        const { data } = yield call(axios.put, "/users/update", user);
        yield put({ type: COMPLETE_UPDATE_INFORMATION_REQUEST, data: { users: [ data ] } });
        yield call(delay, 2000);
        yield put({ type: RESET_YOUR_PROFILE });
    } catch (error) {
        yield put({ type: FAIL_UPDATE_INFORMATION_REQUEST, error });
    } finally {
        yield spawn(updateInformation);
    }
}

export function* registerUserSaga(){
    const {
        usersId,
        name,
        surname,
        email,
        phoneNumber
    } = yield take(REGISTER_USER);
    yield put({ type: START_REGISTER_USER });
    try {
        const userRegistered = {
            event: EVENTS.USER_REGISTERED,
            payload: {
                user: usersId,
                name,
                surname,
                email,
                phoneNumber
            }
        };
        const { data } = yield call(axios.post, "/v2/events", userRegistered);
        yield put({ type: COMPLETE_REGISTER_USER, data });
        yield call(delay, 2000);
        yield put({ type: RESET_YOUR_PROFILE });
    } catch (error) {
        yield put({ type: FAIL_REGISTER_USER, error });
    } finally {
        yield spawn(registerUserSaga);
    }
}


import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

function ReportLine({ cow, estrousEnabled }) {
    return (
        <>
            <li className={cn("report-item")}>
                <h3>
                    <strong>{cow.farmNumber}</strong>
                    <span>{cow.name}</span>
                </h3>
                <div className="pre-partum">
                    <FeverLabel count={cow.healthReport.prePartum} />
                </div>
                <div className="post-partum">
                    <FeverLabel count={cow.healthReport.postPartum} />
                </div>
                <div className="estrous">
                    <FeverLabel
                        count={
                            estrousEnabled
                                ? cow.healthReport.estrous
                                : cow.healthReport.health
                        }
                    />
                </div>
            </li>
        </>
    );
}

function FeverLabel({ count }) {
    const { t } = useTranslation();
    if (!count) return <></>;
    return (
        <label>
            {t("fever")} <strong>{count}</strong>
        </label>
    );
}

export default ReportLine;

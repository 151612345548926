import {
    SHOW_LOGIN_FORM,
    INPUT_LOGIN_EMAIL,
    SUBMIT_LOGIN_EMAIL,
    COMPLETE_LOGIN_LINK_REQUEST,
    FAIL_LOGIN_LINK_REQUEST,
    FAIL_APP_STARTUP,
    SET_LAST_EMAIL,
    RETRY_LOGIN_EMAIL,
    TRY_ANOTHER_LOGIN_EMAIL

} from "../actions/actionTypes";
import { LIFECYCLE } from "../../constants";
import {
    HIDDEN,
    FORM,
    MESSAGE,
    ERROR,
    RETRY
} from "../../components/LoginModal";

const initial = {
    lifecycle: LIFECYCLE.INITIAL,
    modal: HIDDEN,
    email: "",
    errorType: ""
};

export default function( state = initial, action ) {
    switch ( action.type ) {
    case SHOW_LOGIN_FORM: return { ...state, modal: FORM };
    case INPUT_LOGIN_EMAIL: return { ...state, email: action.email };
    case SET_LAST_EMAIL: return { ...state, email: action.lastEmail };
    case SUBMIT_LOGIN_EMAIL: return { ...state, lifecycle: LIFECYCLE.PENDING };
    case COMPLETE_LOGIN_LINK_REQUEST: return {
        lifecycle: LIFECYCLE.DONE,
        modal: MESSAGE,
        email: "",
        errorType: ""
    };
    case RETRY_LOGIN_EMAIL: return {
        ...state,
        lifecycle: LIFECYCLE.INITIAL,
        modal: RETRY
    };
    case TRY_ANOTHER_LOGIN_EMAIL: return { ...state };
    case FAIL_LOGIN_LINK_REQUEST: return { ...state, lifecycle: LIFECYCLE.FAILED, modal: ERROR, errorType: action.error.response.status };
    case FAIL_APP_STARTUP: if (action.error.response.status === 401) {
        return { ...state, lifecycle: LIFECYCLE.INITIAL, modal: ERROR };
    } else {
        return state;
    }
    default: return state;
    }
}

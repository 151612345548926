import { Server } from "miragejs";
import fixtures from "./fixtures";
import routes from "./routes";
import models from "./models";
import seeds from "./seeds";
import factories from "./factories";

import {
    routes as demoRoutes, 
    models as demoModels, 
    seeds as demoSeeds, 
    factories as demoFactories, 
    fixtures as demoFixtures
} from "./demo"


const config = environment => {
    if (environment === "demo") {
        const config = {
            environment,
            routes: demoRoutes,
            models: demoModels,
            seeds: demoSeeds,
            factories: demoFactories
        }

        if (Object.keys(demoFixtures).length) {
            config.fixtures = demoFixtures
        }

        return config;
    }
    
    const config = {
        environment,
        routes,
        models,
        seeds,
        factories
    };

    if (Object.keys(fixtures).length) {
        config.fixtures = fixtures;
    }

    return config;
};

export function makeServer({ environment = "development" } = {}) {
    return new Server(config(environment));
}
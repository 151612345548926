import i18n from "../../i18n";
const currentLanguage = i18n.language;

const cowNames = {
    en: ["Maria", "Bella"],
    de: ["Diane", "Janka"],
    tr: ["Sarıkız", "Karakiz"],
    it: ["Velvet", "Susan"],
    da: ["Laura", "Clara"],
    pt: ["Maria", "Bella"],
};
const names = cowNames[currentLanguage] || ["Maria", "Bella"];
export const cows = [
    {
        identityNumber: "TR010000001",
        farmNumber: "0001",
        lastBreedingDate: 1572598800000,
        lactationCount: "0",
        name: names[0],
        birthdate: 1509526800000,
        breed: "Holstein",
        lastCalvingDate: 0,
        expectedDelivery: 1596790800000,
    },
    {
        identityNumber: "TR010000002",
        farmNumber: "0002",
        lastBreedingDate: 1572598800000,
        lactationCount: "0",
        name: names[1],
        birthdate: 1509699600000,
        breed: "Holstein",
        lastCalvingDate: 0,
        expectedDelivery: 1596790800000,
    },
];
export const sensors = [
    {
        id: "1",
        deviceId: "11101201110163",
        type: "Tsens",
        tags: ["active", "stable"],
    },
    {
        id: "2",
        deviceId: "11101109190135",
        type: "Tsens",
        tags: ["idle"],
    },
    {
        id: "3",
        deviceId: "113112040D0193",
        type: "supervisor",
        tags: ["idle"],
    },
    {
        id: "4",
        deviceId: "113113031D0264",
        type: "repeater",
        tags: ["online"],
    },
    {
        id: "5",
        deviceId: "11151401110441",
        type: "earTag",
        tags: ["idle"],
    },
];

import React from "react";
import { Trans } from "react-i18next";

import { EVENTS } from "../../../constants/schema";
import * as connect from "../../../containers";
import CowName from "../../CowName";
import SensorLabel from "../../SensorLabel";
import { parseMatch, detectLanguageTextChanges } from "../../SmartNote/common";
import { LANGUAGE_TEXTS, VARIABLES } from "../../SmartNote/constants";
import i18n from "../../../i18n";
import Time from "../../Time";

import {
    ConfirmCalving,
    AbortCalving,
    SensorFallHeat,
    SensorFallHealth,
    FeverOverTreatment,
    HeatConfirm,
} from "./smartNotes";

const CowNumberAndName = ({ cow: { farmNumber, name } }) => (
    <React.Fragment>
        #<strong>{farmNumber}</strong> <CowName>{name}</CowName>
    </React.Fragment>
);
export const Cow = connect.cow(CowNumberAndName);

const UserName = ({ user }) => <strong>{user ? user.name : null}</strong>;
export const User = connect.user(UserName);

const SensorName = ({ sensor }) => <SensorLabel {...sensor} />;
export const Sensor = connect.sensor(SensorName);

export const components = {};

export const FeverDetected = ({ payload: { cow, temperature } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="feverDetected" temperature={temperature}>
                <Cow id={cow} /> has a <strong>fever</strong> of
                {{ temperature }}°C
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.FEVER_DETECTED] = FeverDetected;

export const FeverOver = ({ payload: { cow, temperature } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="feverOver" temperature={temperature}>
                <Cow id={cow} /> is better now with {{ temperature }}°C
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.FEVER_OVER] = FeverOver;

export const HypothermiaDetected = ({ payload: { cow, temperature } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="hypothermiaDetected" temperature={temperature}>
                <Cow id={cow} /> has <strong>hypothermia</strong> with
                {{ temperature }}°C
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.HYPOTHERMIA_DETECTED] = HypothermiaDetected;

export const HypothermiaOver = ({ payload: { cow, temperature } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="hypothermiaOver" temperature={temperature}>
                <Cow id={cow} /> is better now with {{ temperature }}°C
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.HYPOTHERMIA_OVER] = HypothermiaOver;

export const LaborDetected = ({ payload: { cow } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="laborDetected">
                <Cow id={cow} /> is <strong>due calving</strong> in 24 hours!
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.LABOR_DETECTED] = LaborDetected;

export const DeliveryDetected = ({ payload: { cow } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="deliveryDetected">
                <Cow id={cow} /> seems to be <strong>calving now!</strong>
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.DELIVERY_DETECTED] = DeliveryDetected;

export const DeliveryCompleted = ({ payload: { cow, user } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="deliveryCompleted">
                <User id={user} /> noted that <Cow id={cow} /> has completed
                calving.
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.DELIVERY_COMPLETED] = DeliveryCompleted;

export const NoteSubmitted = ({ payload: { note, user } }) => {
    return (
        <Trans i18nKey="noteSubmitted" note={note}>
            <User id={user} />: {{ note }}
        </Trans>
    );
};
components[EVENTS.NOTE_SUBMITTED] = NoteSubmitted;

/* STAGE 2 */
export const CalvingDue = ({ payload: { cow }, isHalfOpen }) => {
    if (isHalfOpen) {
        return <Trans i18nKey="calvingDueHalf">Calving within 24 hours.</Trans>;
    }
    return (
        <React.Fragment>
            <Trans i18nKey="calvingDue">
                Alert: Imminent calving in <Cow id={cow} />. The cow is going to
                calve within 24 hours.
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.CALVING_DUE] = CalvingDue;

export const CalvingOverdue = ({ payload: { cow, dueTimestamp } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="calvingOverdue">
                Late calving: <Cow id={cow} /> hasn&apos;t been calving within
                24 hours
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.CALVING_OVERDUE] = CalvingOverdue;

export const CalvingDueFalse = ({ payload: { cow } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="calvingDueFalse">
                False alert: <Cow id={cow} /> won&apos;t be calving soon.
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.CALVING_DUE_FALSE] = CalvingDueFalse;

export const CalvingDetected = ({ payload: { cow }, isHalfOpen }) => {
    if (isHalfOpen) {
        return (
            <Trans i18nKey="calvingDetectedHalf">Calving is detected.</Trans>
        );
    }
    return (
        <React.Fragment>
            <Trans i18nKey="calvingDetected">
                Alert: The onset of calving is detected in <Cow id={cow} />.
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.CALVING_DETECTED] = CalvingDetected;

export const CalvingCompleted = ({ payload: { cow, user } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="calvingCompleted">
                <User id={user} /> confirmed that <Cow id={cow} /> completed
                calving.
            </Trans>
        </React.Fragment>
    );
};
// components[EVENTS.CALVING_COMPLETED] = CalvingCompleted;

export const FeverIncreaseDetected = ({ payload: { cow, temperature } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="feverIncreaseDetected" temperature={temperature}>
                <Cow id={cow} /> has a <strong>fever</strong> of
                {{ temperature }}°C
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.FEVER_INCREASE_DETECTED] = FeverIncreaseDetected;

export const SessionCreated = ({ payload: { sensor, cow, user } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="sessionCreated">
                <User id={user} /> assigned <Sensor id={sensor} /> to
                <Cow id={cow} />
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.SESSION_CREATED] = SessionCreated;

export const SessionCanceled = ({ payload: { sensor, cow, user } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="sessionCanceled">
                <User id={user} /> canceled pair <Sensor id={sensor} /> -
                <Cow id={cow} />
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.SESSION_CANCELED] = SessionCanceled;

export const SessionCompleted = ({ payload: { sensor, cow, user } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="sessionCompleted">
                <User id={user} /> completed pair <Sensor id={sensor} /> -
                <Cow id={cow} /> succesfully.
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.SESSION_COMPLETED] = SessionCompleted;

export const PotantialCalvingsDetected = ({ payload: { cows } }) => {
    const count = cows.length;
    return (
        <React.Fragment>
            <Trans i18nKey="potantialCalvingsDetected" count={count}>
                {{ count }} cow can be paired this week.
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.POTENTIAL_CALVINGS_DETECTED] = PotantialCalvingsDetected;

export const callFailed = ({ payload: { user } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="callFailed">
                <User id={user} /> can not be reached from phone.
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.CALL_FAILED] = callFailed;

export const calledUserActivated = ({ payload: { user, assignedBy } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="calledUserActivated">
                <User id={assignedBy} /> aramaları <User id={user} />
                kullanıcısına yönlendirdi.
            </Trans>
        </React.Fragment>
    );
};

components[EVENTS.CALLED_USER_ACTIVATED] = calledUserActivated;

export const heatDetected = ({ payload: { cow } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="heatDetected">
                Heat detected for <Cow id={cow} />
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.HEAT_DETECTED] = heatDetected;

export const heatOver = ({ payload: { cow } }) => {
    return (
        <React.Fragment>
            <Trans i18nKey="heatOver">
                Heat seems to be finished for <Cow id={cow} />
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.HEAT_OVER] = heatOver;

export const sensorFallDetected = ({ payload: { cow }, isHalfOpen }) => {
    if (isHalfOpen) {
        return <Trans i18nKey="sensorFallDetectedHalf">Sensor fell.</Trans>;
    } else {
        return (
            <React.Fragment>
                <Trans i18nKey="sensorFallDetected">
                    Sensor fall detected for <Cow id={cow} />
                </Trans>
            </React.Fragment>
        );
    }
};
components[EVENTS.SENSOR_FALL_DETECTED] = sensorFallDetected;

export const PotantialPostpartumsDetected = ({ payload: { cows } }) => {
    const count = cows.length;
    return (
        <React.Fragment>
            <Trans i18nKey="potentialPostPartums" count={count}>
                {{ count }} cow can be paired for post partum.
            </Trans>
        </React.Fragment>
    );
};
components[
    EVENTS.POTENTIAL_POSTPARTUM_COWS_DETECTED
] = PotantialPostpartumsDetected;

export const PotantialEstrousesDetected = ({ payload: { cows } }) => {
    const count = cows.length;
    return (
        <React.Fragment>
            <Trans i18nKey="potentialEstrouses" count={count}>
                {{ count }} cow can be paired for estrous.
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.POTENTIAL_ESTROUSES_DETECTED] = PotantialEstrousesDetected;

export const PotantialDryCowsDetected = ({ payload: { cows } }) => {
    const count = cows.length;
    return (
        <React.Fragment>
            <Trans i18nKey="potentialDryCows" count={count}>
                {{ count }} cow can be paired for dry period.
            </Trans>
        </React.Fragment>
    );
};
components[EVENTS.POTENTIAL_DRY_COWS_DETECTED] = PotantialDryCowsDetected;

const smartNoteVersions = {
    null: replaceTagItems,
    1: replaceItems,
    2: replaceItems,
};

export const SmartNoteSubmitted = ({ payload, parentEventTagItems }) => {
    if (payload.eventVersion == "2") {
        return NewSmartNoteSubmitted({ payload, parentEventTagItems });
    }
    let placementFunction = null;
    if (payload.eventVersion) {
        placementFunction = smartNoteVersions[payload.eventVersion];
    } else {
        placementFunction = smartNoteVersions.null;
    }
    let note;
    switch (payload.about) {
        case EVENTS.CALVING_DETECTED + "::confirm":
        case EVENTS.CALVING_DETECTED + "::abort":
        case EVENTS.SENSOR_FALL_DETECTED + "::heat":
        case EVENTS.SENSOR_FALL_DETECTED + "::health":
            note = (
                <React.Fragment>
                    {placementFunction(
                        payload.about,
                        payload.note,
                        payload.parentEventPayload,
                        payload.options,
                    )}
                </React.Fragment>
            );
            break;
    }
    return (
        <React.Fragment>
            <blockquote>
                <User id={payload.user} />: {note}
            </blockquote>
        </React.Fragment>
    );
};
components[EVENTS.SMART_NOTE_SUBMITTED] = SmartNoteSubmitted;

function replaceTagItems(about, note, resourceItemsfromPayload) {
    const resourceItems = { cow: <Cow id={resourceItemsfromPayload.cow} /> };
    const regex = /\$\$\{(.+?)\}/;
    const splitted = note.split(regex);
    const variables = splitted.reduce((acc, match, index) => {
        if (index % 2 != 0) {
            return [...acc, { ...resourceItems[match] }];
        } else {
            if (match.length == 0) return acc;
            acc.push(match);
        }
        return acc;
    }, []);
    return variables;
}

function replaceItems(about, note, resourceItemsfromPayload, options) {
    const resourceItems = {
        cow: (
            <Cow
                key={resourceItemsfromPayload.cow}
                id={resourceItemsfromPayload.cow}
            />
        ),
    };
    const pureOptions = Object.values(options).reduce((acc, o) => {
        return [...acc, ...o];
    }, []);
    const affects = detectLanguageTextChanges(pureOptions);
    const variableTexts = generateVariableTexts(Object.values(options));
    const regex = /\$\$\{(.+?)\}/;
    const splitted = note.split(regex);
    let variableCounter = 0;
    return splitted.reduce((acc, match, index) => {
        if (index % 2 === 0) {
            if (match.length == 0) return acc;
            acc.push(match);
        } else {
            const m = parseMatch(match);
            if (m.LANGUAGE) {
                if (affects[m.LANGUAGE]) {
                    acc.push(
                        LANGUAGE_TEXTS[about][m.LANGUAGE][affects[m.LANGUAGE]],
                    );
                } else {
                    acc.push(LANGUAGE_TEXTS[about][m.LANGUAGE].default);
                }
            } else if (m.VARIABLE) {
                const a = about.replace("::", "-");
                const decoded = i18n.t(`${a}-variable-${m.VARIABLE}`);
                let variable =
                    VARIABLES[i18n.t(`smartNote:variableOrders:${decoded}`)];
                if (!variable) variable = VARIABLES[m.VARIABLE];
                if (variable.type == "payloadVariable") {
                    acc.push(resourceItems[variable.id]);
                } else {
                    const text = variableTexts[variableCounter];
                    acc.push(text);
                }
                variableCounter++;
            }
        }
        return acc;
    }, []);
}
function generateVariableTexts(options) {
    return options.reduce((acc, opt) => {
        switch (VARIABLES[opt[0].parent].type) {
            case "checkbox":
                return [
                    ...acc,
                    opt
                        .map((o) => i18n.t(`smartNote:variables:${o.id}`))
                        .join(", "),
                ];
            case "timePicker":
                // eslint-disable-next-line no-case-declarations
                const time = new Date(opt[0].value);
                return [
                    ...acc,
                    Time({
                        time: time,
                        options: {
                            day: "numeric",
                            month: "long",
                            hour: "numeric",
                            minute: "numeric",
                        },
                    }),
                ];
            case "multiple":
            default:
                return [...acc, i18n.t(`smartNote:variables:${opt[0].id}`)];
        }
    }, []);
}

function NewSmartNoteSubmitted({ payload, parentEventTagItems }) {
    let note;
    switch (payload.about) {
        case EVENTS.CALVING_DETECTED + "::confirm":
            note = (
                <>
                    <ConfirmCalving
                        {...payload.package}
                        parentEventTagItems={parentEventTagItems}
                    />
                </>
            );
            break;
        case EVENTS.CALVING_DETECTED + "::abort":
            note = (
                <>
                    <AbortCalving
                        {...payload.package}
                        {...payload.parentEventPayload}
                    />
                </>
            );
            break;
        case EVENTS.SENSOR_FALL_DETECTED + "::heat":
            note = (
                <>
                    <SensorFallHeat
                        {...payload.package}
                        {...payload.parentEventPayload}
                    />
                </>
            );
            break;
        case EVENTS.SENSOR_FALL_DETECTED + "::health":
            note = (
                <>
                    <SensorFallHealth
                        {...payload.package}
                        {...payload.parentEventPayload}
                    />
                </>
            );
            break;
        case EVENTS.FEVER_OVER + "::treatment":
            note = (
                <>
                    <FeverOverTreatment
                        {...payload.package}
                        {...payload.parentEventPayload}
                    />
                </>
            );
            break;
        case EVENTS.HEAT_DETECTED + "::confirm":
            note = (
                <>
                    <HeatConfirm
                        {...payload.package}
                        {...payload.parentEventPayload}
                    />
                </>
            );
    }
    return (
        <React.Fragment>
            <blockquote>
                <User id={payload.user} />: {note}
            </blockquote>
        </React.Fragment>
    );
}

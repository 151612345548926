import React, { useState, useRef, useEffect } from "react";
import PT from "prop-types";
import cn from "classnames";

import { SENSOR } from "../constants";

import { SearchResults } from "./SearchResults";
import { useDebounce } from "./hooks";

export default function SearchBox({
    type,
    engine,
    onSelect,
    className,
    render,
    selectedRender,
    renderSearch,
    selected: selectedProp,
    focused,
}) {
    const [search, setSearch] = useState(
        (selectedProp && selectedProp._search) || "",
    );
    const [results, setResults] = useState([]);

    const debouncedSearch = useDebounce(search, 300);
    useEffect(() => {
        if (debouncedSearch) setResults(engine(debouncedSearch, renderSearch));
    }, [debouncedSearch, engine, renderSearch]);

    useEffect(() => {
        if (type === "cow" && focused === "cow") inputEl.current.focus();
        if (type === "sensor" && focused === "sensor") inputEl.current.focus();
    }, [focused, selectedProp, type]);
    const inputEl = useRef(null);
    const spanEl = useRef(null);

    function inputChange({ target: { value } }) {
        setSearch(value);
    }

    function onFocused() {
        setResults(engine(debouncedSearch, renderSearch, true));
    }

    const [selected, setSelected] = useState(selectedProp);

    useEffect(() => setSelected(selectedProp), [selectedProp]);

    function resultSelect(selected, type) {
        if (type == "cow") {
            onSelect(selected);
            setResults([]);
            if (render) {
                setSelected(selected);
                inputEl.current.hidden = true;
            } else {
                setSearch(selected);
            }
        } else {
            if (
                selected.tagValues &&
                selected.tagValues.includes(SENSOR.IDLE)
            ) {
                if (!selected.temporarySelected) {
                    onSelect(selected);
                    setResults([]);
                    if (render) {
                        setSelected(selected);
                        inputEl.current.hidden = true;
                    } else {
                        setSearch(selected);
                    }
                }
            }
        }
    }

    function restoreSearch() {
        setSelected(null);
        onSelect(null);
        setSearch(spanEl.current.innerText);
        inputEl.current.value = spanEl.current.innerText;
        inputEl.current.hidden = false;
        inputEl.current.focus();
    }
    return (
        <div
            className={cn("search-box", className, {
                results: !!results.length,
            })}>
            {selected ? (
                <span ref={spanEl} className="selected" onClick={restoreSearch}>
                    {selectedRender(selected)}
                </span>
            ) : null}
            <input
                ref={inputEl}
                hidden={!!selected}
                value={search}
                type="text"
                onChange={inputChange}
                onFocus={onFocused}
            />
            {results && results.length > 0 ? (
                <SearchResults
                    highlight={search}
                    results={results}
                    onSelect={(selected) => resultSelect(selected, type)}
                    render={render}
                />
            ) : null}
        </div>
    );
}

SearchBox.propTypes = {
    type: PT.string,
    engine: PT.func.isRequired,
    onSelect: PT.func,
    className: PT.string,
    render: PT.func,
    renderSearch: PT.func,
    selected: PT.any,
    selectedRender: PT.any,
    focused: PT.any,
};

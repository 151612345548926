import { useEffect } from "react";

const EnterPress = (ref, callback, extraCondition) => {
    useEffect(() => {
        document.addEventListener("keypress", handleEnter);

        return () => {
            document.removeEventListener("keypress", handleEnter);
        };
    });
    const handleEnter = (event) => {
        if (
            ref.current &&
            ref.current.contains(event.target) &&
            extraCondition &&
            event.keyCode === 13
        ) {
            callback();
        }
    };
    return;
};

export default EnterPress;

import React, { useEffect, useRef } from "react";

import i18n from "../../i18n";
import "./Toast.css";

export default function Toast({ onFinish, text, permanent }) {
    const toastRef = useRef();

    function onFinisLocal() {
        if (onFinish) onFinish();
    }
    useEffect(() => {
        const ref = toastRef.current;
        ref.classList.add("slide-in");
        if (!permanent) {
            setTimeout(() => {
                if (ref) {
                    ref.classList.remove("slide-in");
                    ref.classList.add("slide-out");
                    ref.addEventListener("animationend", onFinisLocal);
                }
            }, 3000);
        }
        return () => {
            ref.removeEventListener("animationend", onFinisLocal);
        };
    }, [onFinisLocal, permanent]);
    return (
        <div ref={toastRef} className="toast">
            <span>
                {text ? text : i18n.t("youAreNotAllowedToDoThisAction")}
            </span>
        </div>
    );
}

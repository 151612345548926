import {
    START_REMOVE_USER_REQUEST,
    COMPLETE_REMOVE_USER_REQUEST,
    FAIL_REMOVE_USER_REQUEST,
    RESET_REMOVE_USER
} from "../../actions/actionTypes";

export default function (state = "initial", action) {
    switch (action.type) {
    case START_REMOVE_USER_REQUEST:
        return "pending";
    case COMPLETE_REMOVE_USER_REQUEST:
        return "done";
    case RESET_REMOVE_USER:
        return "initial";
    case FAIL_REMOVE_USER_REQUEST:
        return "failed";
    default: return state;
    }
}
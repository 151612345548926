import { connect } from "react-redux";

import {
    addQuery,
    dropQuery,
    selectSort,
    setSearchText,
} from "../store/actions";
import { filterTags } from "../components/CowFilter/constant";

export function mapStateToProps({ cowListFilter }) {
    return {
        filterTags: Object.values(filterTags),
        queries: cowListFilter.queries,
        sortOrder: cowListFilter.sortOrder,
        selectedSort: cowListFilter.sort,
    };
}

const mapDispatchToProps = { addQuery, dropQuery, onSort, setSearchText };

function onSort(sortBy, previousSort, previousOrder) {
    if (sortBy === previousSort) {
        previousOrder =
            previousOrder === "descending" ? "ascending" : "descending";
    } else {
        previousOrder = "ascending";
    }
    return selectSort(sortBy, previousOrder);
}

export default connect(mapStateToProps, mapDispatchToProps);

import {
    START_PROCESS_REQUIRED_INFO,
    COMPLETE_PROCESS_REQUIRED_INFO,
    FAIL_PROCESS_REQUIRED_INFO,
} from "../../actions/actionTypes";

export default function (state = "initial", action) {
    switch (action.type) {
    case COMPLETE_PROCESS_REQUIRED_INFO:
        return "initial";
    case FAIL_PROCESS_REQUIRED_INFO:
        return "failed";
    case START_PROCESS_REQUIRED_INFO:
        return "pending";
    default: return state;
    }
}
